import * as Sentry from "@sentry/react";
import { Suspense, useEffect, useState } from "react";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, useLazyLoadQuery } from "react-relay";
import { RelayEnvironmentProvider } from "react-relay";
import { Router } from "react-router";

import { FlexIntegration } from "./components/FlexIntegration";
import { LoadingIndicator } from "./components/Loading";
import { UpdateWatcher } from "./components/UpdateWatcher";
import ErrorBoundary from "@/components/ErrorBoundary";
import { TriggerSurvey } from "@/components/TriggerSurvey";

import { CountryContext } from "./context/Country";
import { CurrentCallProvider } from "./context/CurrentCall";
import { FlagContextProvider } from "./context/Flag";
import { NavigationContext } from "./context/Navigation";
import { PermissionsContext } from "./context/Permissions";
import { SupportUserIdContext } from "./context/SupportUserId";
import { LiveMetricsProvider } from "@/context/LiveMetrics";
import { WebSocketProvider } from "@/context/WebSocket";

import { Analytics } from "./external/analytics";
import { Flags } from "./external/flags";

import { useCountry } from "./hooks/useCountry";
import useCountryScopedEnvironment from "./hooks/useCountryScopedEnvironment";

import { NavBar } from "./layout/NavBar";
import { SuggestLoginModal } from "./layout/SuggestLoginModal";

import Routes from "./pages/Routes";

import { history } from "./utils/environment";

import { AppPermissionsQuery } from "./__generated__/AppPermissionsQuery.graphql";

const supportAppPermissionsQuery = graphql`
    query AppPermissionsQuery {
        supportAppUser {
            id
            permissions
            countries
        }
    }
`;

function PermissionedApp() {
    const { country, updateCountries } = useCountry();
    const {
        supportAppUser: { id, countries, permissions },
    } = useLazyLoadQuery<AppPermissionsQuery>(
        supportAppPermissionsQuery,
        {},
        {
            // Permissions should always be fetched from the server so set the cacheConfig and
            // the fetchPolicy to never rely on local data. Although permissions could change,
            // it won't be frequent and permissions are enforced on the backend so we won't
            // bother refetching permissions.
            networkCacheConfig: { force: true },
            fetchPolicy: "network-only",
        }
    );

    const [pagesBehind, setPagesBehind] = useState(0);
    const [pagesAhead, setPagesAhead] = useState(0);

    useEffect(() => {
        Analytics.identify(id);

        Sentry.setUser({
            id: id,
            email: id,
        });
    }, [id]);

    useEffect(() => {
        Analytics.setCountry(country, () => {
            const syncFlags = async () => {
                await Flags.sync(id);
            };
            syncFlags().catch((error) => {
                Sentry.captureException(error);
            });
        });
    }, [id, country]);

    useEffect(() => {
        Analytics.addContext({ countries, permissions });
    }, [countries, permissions]);

    useEffect(() => {
        updateCountries(countries);
    }, [countries, updateCountries]);

    return (
        <PermissionsContext.Provider value={[...permissions]}>
            <SupportUserIdContext.Provider value={id}>
                <FlagContextProvider>
                    <WebSocketProvider channel={`rep_${id.split("@")[0]}`}>
                        <LiveMetricsProvider>
                            <CurrentCallProvider>
                                <FlexIntegration />
                                <TriggerSurvey />
                                <NavigationContext.Provider
                                    value={{
                                        pagesAhead,
                                        pagesBehind,
                                        setPagesBehind,
                                        setPagesAhead,
                                        queryString: window.location.search,
                                    }}
                                >
                                    <Container
                                        fluid
                                        id="app"
                                        className="h-screen flex flex-col"
                                    >
                                        <Row className="h-full">
                                            <Col className="p-0 flex flex-col">
                                                <Router history={history}>
                                                    <ErrorBoundary>
                                                        <UpdateWatcher />
                                                    </ErrorBoundary>
                                                    <NavBar />
                                                    <ErrorBoundary>
                                                        <Routes
                                                            country={country}
                                                        />
                                                    </ErrorBoundary>
                                                </Router>
                                            </Col>
                                        </Row>
                                    </Container>
                                </NavigationContext.Provider>
                            </CurrentCallProvider>
                        </LiveMetricsProvider>
                    </WebSocketProvider>
                </FlagContextProvider>
            </SupportUserIdContext.Provider>
        </PermissionsContext.Provider>
    );
}

function App() {
    const { country, updateCountry, countries, updateCountries, environment } =
        useCountryScopedEnvironment();

    return (
        <ErrorBoundary>
            <SuggestLoginModal>
                <CountryContext.Provider
                    value={{
                        country,
                        countries,
                        updateCountry,
                        updateCountries,
                    }}
                >
                    <RelayEnvironmentProvider environment={environment}>
                        <Suspense fallback={<LoadingIndicator />}>
                            <PermissionedApp />
                        </Suspense>
                    </RelayEnvironmentProvider>
                </CountryContext.Provider>
            </SuggestLoginModal>
        </ErrorBoundary>
    );
}

export default App;
