import { useCallback, useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { NetworkError } from "@/external/relay";

import { useUpdateCurrentCallQueryResponse } from "@/hooks/__generated__/useUpdateCurrentCallQuery.graphql";
import { useTrackEvent } from "@/hooks/useTrackEvent";
import { useUpdateCurrentCall } from "@/hooks/useUpdateCurrentCall";

type LoadCallerButtonProps = {
    onCurrentCallFound: (number: string | null) => void;
    onCurrentCallMissing: () => void;
};

const LoadCallerButton = ({
    onCurrentCallFound,
    onCurrentCallMissing,
}: LoadCallerButtonProps) => {
    const { t } = useTranslation();

    const [isLoading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const trackEvent = useTrackEvent();

    const onSuccess = (data: useUpdateCurrentCallQueryResponse | undefined) => {
        if (data && data.currentPhoneCall) {
            trackEvent("load call");

            onCurrentCallFound(data.currentPhoneCall.theirTelnum);
        } else {
            trackEvent("call missing");

            onCurrentCallMissing();
        }
    };

    const onError = (error: Error) => {
        if (error instanceof NetworkError) {
            setShowToast(true);
        } else {
            return Promise.reject(error);
        }
    };
    const onQueryEnded = () => {
        setLoading(false);
    };

    const updateCurrentCall = useUpdateCurrentCall({
        onSuccess,
        onError,
        onQueryEnded,
    });

    const onClick = useCallback(() => {
        if (isLoading) return;

        setLoading(true);
        updateCurrentCall();
    }, [isLoading, updateCurrentCall]);
    return (
        <>
            <Toast
                className="absolute top-[20px] right-[20px]"
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={2000}
                autohide
            >
                <Toast.Body>{t("load-caller-button--error")}</Toast.Body>
            </Toast>
            <Button
                className="ml-2"
                onClick={onClick}
                disabled={isLoading}
                variant="outline-warning"
                data-testid="load-current-caller"
            >
                &#128222;
            </Button>
        </>
    );
};

export default LoadCallerButton;
