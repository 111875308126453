/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserInterface = "AGENT_PORTAL" | "API" | "AUTOMATIC" | "BUSINESS_PORTAL" | "CUSTOMER_APP_IOS" | "DISTRIBUTOR_APP" | "FRONTAPP" | "INTEGRATIONS_RECONCILIATION_PORTAL" | "KIOSK_NFC" | "KIOSK_QR" | "MERCHANT_QR" | "SLACK_TOOL" | "SMARTPHONE_APP" | "USSD" | "%future added value";
export type FailedMerchantTransferEntry_tx = {
    readonly id: string;
    readonly whenEntered: string;
    readonly sendAmount: string;
    readonly receiveAmount: string;
    readonly isCancelled: boolean;
    readonly merchantId: string | null;
    readonly merchantName: string;
    readonly scamInvestigationFields: {
        readonly deviceId: string | null;
        readonly deviceModel: string | null;
        readonly deviceName: string | null;
        readonly userInterface: UserInterface | null;
    } | null;
    readonly " $refType": "FailedMerchantTransferEntry_tx";
};
export type FailedMerchantTransferEntry_tx$data = FailedMerchantTransferEntry_tx;
export type FailedMerchantTransferEntry_tx$key = {
    readonly " $data"?: FailedMerchantTransferEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FailedMerchantTransferEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FailedMerchantTransferEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenEntered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiveAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "merchantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "merchantName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamInvestigationFields",
      "kind": "LinkedField",
      "name": "scamInvestigationFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userInterface",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FailedMerchantTransferEntry",
  "abstractKey": null
};
(node as any).hash = '87e4f4dcc3c2de76e3d104ee3f61e23c';
export default node;
