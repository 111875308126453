/*
    NOTE: this is just for demo purposes - it is icky. We should do something
    nicer in production
 */
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import * as React from "react";
import {
    ButtonGroup,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
    Table,
} from "react-bootstrap";

import { Button } from "@/components/Button";

import { WebSocketContext, WebSocketProvider } from "@/context/WebSocket";

import {
    RepAnsweredCall,
    RepHangUpCall,
    RepLoggedIn,
    RepLoggedOut,
    RepMissedCall,
    RepTransferredCall,
    WsEventType,
    useWebSocket,
} from "@/hooks/useWebSocket";

import { LIVE_EVENTS_URL } from "@/utils/environment";
import { buildUrlString } from "@/utils/url";

const sendEvent = (body: any, channel: string) => {
    fetch(buildUrlString(LIVE_EVENTS_URL, "publish", channel), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    })
        .then((data) => {
            // eslint-disable-next-line no-console
            console.log("SUCCESS:", data);
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error("ERROR:", error);
        });
};

const publishEvent = (
    channel = "rep_foo",
    content: { type: string; callerMobile?: string; phoneCallId?: string }
) => {
    const now = new Date();
    const body = {
        ...content,
        repName: channel.replace("rep_", ""),
        time: now.toISOString(),
    };
    sendEvent(body, channel);
};

const publishRepHangUp = (callerMobile: string, channel = "rep_foo") => {
    publishEvent(channel, {
        callerMobile: callerMobile,
        type: "repHangUpCall",
    });
};
const publishRepAnsweredCall = (phoneCallId: string, channel = "rep_foo") => {
    publishEvent(channel, {
        phoneCallId: phoneCallId,
        type: "repAnsweredCall",
    });
};

const publishRepLoggedIn = (channel = "rep_foo") => {
    publishEvent(channel, { type: "repLoggedIn" });
};

const publishRepLoggedOut = (channel = "rep_foo") => {
    publishEvent(channel, { type: "repLoggedOut" });
};

const publishRepMissedCall = (channel = "rep_foo") => {
    publishEvent(channel, { type: "repMissedCall" });
};

const publishRepTransferred = (channel = "rep_foo") => {
    publishEvent(channel, { type: "repTransferredCall" });
};

const WebsocketConnection = () => {
    const ws = useContext(WebSocketContext);

    const forceReconnect = () => {
        ws?.close(3000);
    };
    return (
        <Button variant="outline-primary" onClick={forceReconnect}>
            Reconnect
        </Button>
    );
};

const LiveEvents = () => {
    const [callerMobile, setCallerMobile] = useState("+221761110010");
    const [phoneCallId, setPhoneCallId] = useState(
        "PC_WVWse7GOVARA5w1q3l8YLsFPxDzJRyP3"
    );

    return (
        <div>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Phone call ID</Form.Label>
                        <InputGroup>
                            <Form.Control
                                placeholder="PC_..."
                                onChange={(e) =>
                                    setPhoneCallId(e.currentTarget.value)
                                }
                                value={phoneCallId}
                            />
                            <Button
                                variant="outline-primary"
                                className="rounded-tl-none rounded-bl-none"
                                onClick={() =>
                                    publishRepAnsweredCall(phoneCallId)
                                }
                            >
                                Rep Answered Call
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Caller mobile</Form.Label>
                        <InputGroup>
                            <Form.Control
                                placeholder="+221..."
                                onChange={(e) =>
                                    setCallerMobile(e.currentTarget.value)
                                }
                                value={callerMobile}
                            />
                            <Button
                                variant="outline-primary"
                                className="rounded-tl-none rounded-bl-none"
                                onClick={() => publishRepHangUp(callerMobile)}
                            >
                                Rep Hang Up Call
                            </Button>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>

            <ButtonGroup>
                <Button
                    variant="outline-primary"
                    onClick={() => publishRepLoggedIn()}
                >
                    Rep Logged In
                </Button>
                <Button
                    variant="outline-primary"
                    onClick={() => publishRepLoggedOut()}
                >
                    Rep Logged out
                </Button>
                <Button
                    variant="outline-primary"
                    onClick={() => publishRepMissedCall()}
                >
                    Rep Missed Call
                </Button>
                <Button
                    variant="outline-primary"
                    onClick={() => publishRepTransferred()}
                >
                    Rep Transferred Call
                </Button>
            </ButtonGroup>
        </div>
    );
};

const Subscribed = ({ channel }: { channel: string }) => {
    const [messageList, setMessageList] = useState<Array<string>>([]);

    const lastRepAnsweredCall = useWebSocket<RepAnsweredCall>(
        WsEventType.repAnsweredCall
    );
    const lastRepHangUpCall = useWebSocket<RepHangUpCall>(
        WsEventType.repHangUpCall
    );
    const lastRepLoggedInCall = useWebSocket<RepLoggedIn>(
        WsEventType.repLoggedIn
    );
    const lastRepLoggedOutCall = useWebSocket<RepLoggedOut>(
        WsEventType.repLoggedOut
    );
    const lastRepMissedCall = useWebSocket<RepMissedCall>(
        WsEventType.repMissedCall
    );
    const lastRepTransferredCall = useWebSocket<RepTransferredCall>(
        WsEventType.repTransferredCall
    );

    const addMessage = (message: any) => {
        setMessageList((msgs: string[]) => [
            JSON.stringify(message, null, 4),
            ...msgs.slice(0, 9),
        ]);
    };

    useEffect(() => {
        addMessage(lastRepAnsweredCall);
    }, [lastRepAnsweredCall]);

    useEffect(() => {
        addMessage(lastRepHangUpCall);
    }, [lastRepHangUpCall]);

    useEffect(() => {
        addMessage(lastRepLoggedInCall);
    }, [lastRepLoggedInCall]);

    useEffect(() => {
        addMessage(lastRepLoggedOutCall);
    }, [lastRepLoggedOutCall]);

    useEffect(() => {
        addMessage(lastRepMissedCall);
    }, [lastRepMissedCall]);

    useEffect(() => {
        addMessage(lastRepTransferredCall);
    }, [lastRepTransferredCall]);

    useEffect(() => {
        setMessageList([]);
    }, [channel]);

    return (
        <>
            <Table striped>
                <tbody>
                    {messageList.map((msg: string, index: number) => (
                        <tr key={index}>
                            <td>{msg}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export const PubSubDemo = (): React.ReactElement => {
    const [channel, setChannel] = useState<string>("rep_foo");
    const [status, setStatus] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(
                buildUrlString(LIVE_EVENTS_URL, "/nchan_stub_status"),
                { credentials: "include" }
            );
            return await response.text();
        };
        fetchData().then((resp) => {
            setStatus(resp);
        });
    }, []);

    return (
        <Container className="mb-5">
            <Row>
                <Col>
                    <p className="text-lg font-bold mb-3">Channel:</p>
                    <Form.Control
                        placeholder="rep_..."
                        onChange={(e) => setChannel(e.currentTarget.value)}
                        value={channel}
                    />

                    {channel === "rep_foo" && (
                        <>
                            <p className="text-lg font-bold mb-3 mt-5">
                                Trigger events:
                            </p>
                            <LiveEvents />
                        </>
                    )}
                    <WebSocketProvider channel={channel}>
                        <>
                            <p className="text-lg font-bold mb-3 mt-5">
                                Manage websocket connection:
                            </p>
                            <WebsocketConnection />

                            <p className="text-lg font-bold mb-3 mt-5">
                                Last 10 Events:
                            </p>
                            <Subscribed channel={channel}></Subscribed>
                        </>
                    </WebSocketProvider>
                </Col>
                <Col xs={3}>
                    <p className="text-lg font-bold mb-3 mt-5">
                        <a href="https://nchan.io/#nchan_stub_status-stats">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                target="_blank"
                            />
                        </a>{" "}
                        nchan_stub_status:
                    </p>
                    {status.split("\n").map((item: string, index: number) => {
                        if (item) return <div key={index}>{item}</div>;
                    })}
                </Col>
            </Row>
        </Container>
    );
};
