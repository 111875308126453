import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "../../types/MutatorModal";
import { Action } from "@/types/actions";

import { SendWoyofalCodeMutationResponse } from "./__generated__/SendWoyofalCodeMutation.graphql";
import { SendWoyofalCode_wallet } from "./__generated__/SendWoyofalCode_wallet.graphql";

const mutation = graphql`
    mutation SendWoyofalCodeMutation($mobile: String!, $woyofalCode: String!) {
        sendWoyofalCode(mobile: $mobile, woyofalCode: $woyofalCode) {
            result
        }
    }
`;

type SendWoyofalCodeInputParams = {
    wallet: SendWoyofalCode_wallet;
    woyofalCode: string;
};

const _SendWoyofalCodeMutator = (
    props: SendWoyofalCodeInputParams & RelayModalProps
) => {
    const { onHide, wallet, woyofalCode } = props;
    const { mobile } = wallet;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: SendWoyofalCodeMutationResponse): string => {
            return response.sendWoyofalCode?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: { name: Action.SendWoyofalCode, data: { mobile } },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { mobile, woyofalCode });
    }, [mutator, mobile, woyofalCode]);

    return (
        <MutatorModal
            {...mutator}
            title={t("woyofal-code-send", {
                code: woyofalCode,
                name: wallet.name,
                mobile: wallet.mobile,
            })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-confirm")}
                onSubmit={handleSubmit}
                onDone={onHide}
            />
        </MutatorModal>
    );
};

export const SendWoyofalCodeMutator = createFragmentContainer(
    _SendWoyofalCodeMutator,
    {
        wallet: graphql`
            fragment SendWoyofalCode_wallet on Wallet {
                id
                mobile
                name
            }
        `,
    }
);
