import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { CallUserMutator } from "@/pages/mobile.[mobile].personal/CallUser";

export const CallUserAction = (props: { name: string; mobile: string }) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            name={t("call-user--action")}
            requiredPermissions={["supportapp_call_user"]}
        >
            <CallUserMutator name={props.name} customerMobile={props.mobile} />
        </ActionButton>
    );
};
