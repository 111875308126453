import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Checkbox, useCheckbox } from "../../components/Checkbox";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { DenseCheckbox } from "@/components/Checkbox";
import { CurrencyField, useCurrencyField } from "@/components/CurrencyField";

import {
    ScamReports,
    ScamReportsHistoryTable,
} from "@/pages/mobile.[mobile]/ScamReportsHistoryTable";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { C, M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";

import { ReportAgentScamMutationResponse } from "./__generated__/ReportAgentScamMutation.graphql";

const mutation = graphql`
    mutation ReportAgentScamMutation(
        $transactionId: ID!
        $amountStolen: Money!
        $customerSharedPinCode: Boolean!
        $customerSharedSmsCode: Boolean!
        $additionalInfo: String!
    ) {
        reportAgentScam(
            transactionId: $transactionId
            amount: $amountStolen
            customerSharedPinCode: $customerSharedPinCode
            customerSharedSmsCode: $customerSharedSmsCode
            additionalInfo: $additionalInfo
        ) {
            result
        }
    }
`;

type ReportAgentScamInputParams = {
    transactionId?: string;
    transactionCurrency: C;
    amountStolen?: M;
    additionalInfo?: string;
    scamReports?: ScamReports;
};

const _ReportAgentScamMutator = (
    props: ReportAgentScamInputParams & RelayModalProps
) => {
    const {
        onHide,
        transactionId,
        amountStolen,
        additionalInfo,
        scamReports,
        transactionCurrency,
    } = props;
    const onMutationSuccess = useCallback(
        (response: ReportAgentScamMutationResponse) => {
            return response.reportAgentScam?.result || "";
        },
        []
    );

    const { t } = useTranslation();

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportAgentScam,
            data: { transactionId, amountStolen },
        },
        ...props,
    });

    const transactionIdField = useTextField();
    const amountField = useCurrencyField();
    const customerSharedSmsCodeCheckbox = useCheckbox();
    const customerSharedPinCodeCheckbox = useCheckbox();
    const additionalInfoField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            transactionId: transactionIdField.value.trim(),
            amountStolen: amountField.value?.serialize(),
            customerSharedSmsCode: customerSharedSmsCodeCheckbox.checked,
            customerSharedPinCode: customerSharedPinCodeCheckbox.checked,
            additionalInfo: additionalInfoField.value,
        });
    }, [
        mutator,
        transactionIdField.value,
        amountField.value,
        customerSharedSmsCodeCheckbox.checked,
        customerSharedPinCodeCheckbox.checked,
        additionalInfoField.value,
    ]);

    const transactionIdValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!transactionIdField.value) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-agent-transaction-id"),
            });
        }
        return validations;
    }, [t, transactionIdField.value]);

    const amountValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!amountField.value || !amountField.value?.isPositive()) {
            validations.push({
                type: "error",
                display: "always",
                message: t("report-agent-scam--missing-amount-stolen"),
            });
        }
        return validations;
    }, [t, amountField.value]);

    const isValid = useMemo(() => {
        return !hasError([amountValidations, transactionIdValidations]);
    }, [amountValidations, transactionIdValidations]);

    return (
        <MutatorModal {...mutator} title={t("report-agent-scam--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-report")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...transactionIdField}
                    label={t("label-transaction-id")}
                    name="transactionId"
                    validations={transactionIdValidations}
                    defaultValue={transactionId}
                />

                <CurrencyField
                    {...amountField}
                    label={t("label-amount-stolen")}
                    name="amountStolen"
                    validations={amountValidations}
                    defaultValue={amountStolen}
                    currency={amountStolen?.currency || transactionCurrency}
                    autoFocus
                />

                <DenseCheckbox>
                    <Checkbox
                        {...customerSharedPinCodeCheckbox}
                        label={t("report-agent-scam--shared-pin-code")}
                        name="customerSharedPinCode"
                        validations={[]}
                    />
                </DenseCheckbox>

                <DenseCheckbox>
                    <Checkbox
                        {...customerSharedSmsCodeCheckbox}
                        label={t("report-agent-scam--shared-sms-code")}
                        name="customerSharedSmsCode"
                        validations={[]}
                    />
                </DenseCheckbox>

                <TextField
                    {...additionalInfoField}
                    label={t("label-additional-info")}
                    name="additionalInfo"
                    validations={[]}
                    defaultValue={additionalInfo}
                    as="textarea"
                    autoFocus
                />
            </Form>
            <p>{t("report-agent-scam-history--title")}</p>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t("report-agent-scam-history--reported-on")}</th>
                        <th>{t("report-agent-scam-history--status")}</th>
                        <th>
                            {t("report-agent-scam-history--transaction-id")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ScamReportsHistoryTable tickets={scamReports} />
                </tbody>
            </table>
        </MutatorModal>
    );
};
export const ReportAgentScamMutator = createFragmentContainer(
    _ReportAgentScamMutator,
    {}
);
