import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { ProfileLink } from "@/components/ProfileLink";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { RefundMerchantSaleMutator } from "@/pages/mobile.[mobile].merchant/RefundMerchantSale";
import HistoryEntry, {
    HistoryEntryProps,
} from "@/pages/mobile.[mobile]/HistoryEntry";

import { CAN_REFUND_MERCHANT_SALE } from "@/types/Permissions";

import { MerchantSaleEntry_tx } from "./__generated__/MerchantSaleEntry_tx.graphql";

function _MerchantSaleEntry(
    props: { tx: MerchantSaleEntry_tx } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const entry = props.tx;
    const profileLink =
        entry.maybeTerminatedSenderMobile && entry.maybeSenderName ? (
            <ProfileLink
                name={entry.maybeSenderName}
                mobile={entry.maybeTerminatedSenderMobile}
                opaqueId={entry.maybeTransferId || undefined}
            />
        ) : (
            <></>
        );
    const sender =
        entry.maybeTerminatedSenderMobile || entry.qrText == null
            ? profileLink
            : entry.qrText;

    let saleType = "";

    if (entry.actionSource) {
        const source =
            entry.actionSource === "SCAN_QR" ? "USER_SCAN" : entry.actionSource;

        saleType = ` : ${source}`;
    } else {
        saleType = " : MERCHANT_SCAN : " + entry.merchantUName;
    }

    const info = entry.maybeTransferId + saleType;

    const hasSupportAppRefundMerchantSalePermission = usePermissions(
        CAN_REFUND_MERCHANT_SALE
    );

    const buttons =
        entry.isRefunded || !entry.isRemote ? null : (
            <Fragment>
                <HistoryActionButton
                    name={t("refund--action")}
                    disabled={!hasSupportAppRefundMerchantSalePermission}
                >
                    <RefundMerchantSaleMutator transfer={entry} />
                </HistoryActionButton>
            </Fragment>
        );

    return (
        <HistoryEntry
            {...props}
            entry={entry}
            title={
                <Fragment>
                    <TxTitle>
                        {t("transfer-received-entry--title")} {sender}
                    </TxTitle>
                    {info}
                </Fragment>
            }
            buttons={buttons}
        />
    );
}

export default createFragmentContainer(_MerchantSaleEntry, {
    tx: graphql`
        fragment MerchantSaleEntry_tx on MerchantSaleEntry {
            # transferId, senderName and SenderMobile exist on other
            # types returned from history and in most cases aren't nullable.
            # We need to alias here, otherwise we get a gql error.
            maybeTransferId: transferId
            maybeSenderName: senderName
            maybeSenderMobile: senderMobile
            maybeTerminatedSenderMobile
            qrText
            merchantUName
            actionSource
            isRefunded
            isRemote
            ...HistoryEntry_entry
            ...RefundMerchantSale_transfer
        }
    `,
});
