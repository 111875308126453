import React, { useEffect, useRef } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Switch, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

import ErrorBoundary from "@/components/ErrorBoundary";
import { LoadingIndicator } from "@/components/Loading";

import { Analytics } from "@/external/analytics";
import { WrongBackendError } from "@/external/relay";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { SentryRoute } from "@/pages/Routes";
import AgentTab from "@/pages/mobile.[mobile].agent";
import MerchantTab from "@/pages/mobile.[mobile].merchant";
import NotesTab from "@/pages/mobile.[mobile].notes";
import PersonalTab from "@/pages/mobile.[mobile].personal";

import { getTabFromWindowLocation } from "@/utils/navigation";

import { ProfileByMobileQueryResponse } from "./__generated__/ProfileByMobileQuery.graphql";

import { NotesTabTitle } from "../mobile.[mobile].notes/NotesTabTitle";
import { NoUserPanel } from "./NoUserPanel";
import { UserInfoHeader } from "./UserInfoHeader";

export type ProfileViewPayload = {
    walletData?: ProfileByMobileQueryResponse["wallet"];
    unregisteredMobile?: ProfileByMobileQueryResponse["unregisteredMobile"];
    caller?: ProfileByMobileQueryResponse["caller"];
    supportCallerMetrics?: ProfileByMobileQueryResponse["supportCallerMetrics"];
    supportNotes?: ProfileByMobileQueryResponse["supportNotes"];
    isAgent?: boolean;
    isMerchant?: boolean;
};

type ProfileViewProps = {
    mobile: string;
    searchOpaqueId: string | null;
    payload?: ProfileViewPayload | null;
    error: Error | null;
    retry: (() => void) | null;
};

export const ProfileView = (props: ProfileViewProps) => {
    const { mobile, error, payload } = props;

    const { t } = useTranslation();
    const { path, url } = useRouteMatch();
    const trackEvent = useTrackEvent();
    const lastMobile = useRef<string>();

    useEffect(() => {
        if (lastMobile.current !== mobile) {
            Analytics.addContext({ profileMobile: mobile });
            lastMobile.current = mobile;
        }
    }, [mobile, trackEvent]);

    if (error) {
        if (error instanceof WrongBackendError) throw error;
        if (
            error.message === "Failed to fetch" ||
            error.message === "NetworkError when attempting to fetch resource."
        ) {
            return (
                <div>
                    {t("profile-view--error-network")}{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://frontplugin.wave-internal.com/backend_proxy/"
                        className="text-blue-600"
                    >
                        https://frontplugin.wave-internal.com/backend_proxy/
                    </a>
                </div>
            );
        }

        return (
            <div>{t("profile-view--error", { error: error.toString() })}</div>
        );
    }

    if (!payload) return <LoadingIndicator />;

    const {
        walletData,
        unregisteredMobile,
        caller,
        supportCallerMetrics,
        supportNotes,
        isAgent,
        isMerchant,
    } = payload;

    if (!walletData) {
        if (unregisteredMobile) {
            return (
                <div className="profile-view no-user">
                    <NoUserPanel
                        unregisteredMobile={unregisteredMobile}
                        supportCallerMetrics={supportCallerMetrics}
                    />
                </div>
            );
        } else {
            return (
                <>
                    <h2>{t("profile-view--error-outage", { mobile })}</h2>
                </>
            );
        }
    }

    const name =
        payload.walletData?.ownerName ??
        payload.walletData?.user?.agentUser?.name ??
        payload.walletData?.user?.merchant?.name;

    const tab = getTabFromWindowLocation() || "personal";

    return (
        <div className="profile-view" data-testid="profile-view">
            <title>
                {name}: {mobile}
            </title>
            <div className="user-info-header">
                <UserInfoHeader
                    walletData={walletData}
                    caller={caller}
                    supportCallerMetrics={supportCallerMetrics}
                />
            </div>
            <div className="profile-tabs">
                <Tab.Container id="profile_tabs">
                    <Nav className="nav nav-tabs">
                        <Nav.Link
                            as="span"
                            className={`nav-item nav-link ${
                                tab === "personal" ? "active" : ""
                            }`}
                        >
                            <Link
                                to={`${url}/personal`}
                                onClick={() =>
                                    trackEvent(`select tab personal`)
                                }
                                data-testid="personalTab"
                            >
                                {t("profile-view--tab-personal")}
                            </Link>
                        </Nav.Link>
                        {isAgent && (
                            <Nav.Link
                                as="span"
                                className={`nav-item nav-link ${
                                    tab === "agent" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to={`${url}/agent`}
                                    onClick={() =>
                                        trackEvent(`select tab agent`)
                                    }
                                    data-testid="agentTab"
                                >
                                    {t("profile-view--tab-agent")}
                                </Link>
                            </Nav.Link>
                        )}
                        {isMerchant && (
                            <Nav.Link
                                as="span"
                                className={`nav-item nav-link ${
                                    tab === "merchant" ? "active" : ""
                                }`}
                            >
                                <Link
                                    to={`${url}/merchant`}
                                    onClick={() =>
                                        trackEvent(`select tab merchant`)
                                    }
                                    data-testid="merchantTab"
                                >
                                    {t("profile-view--tab-merchant")}
                                </Link>
                            </Nav.Link>
                        )}

                        <Nav.Link
                            as="span"
                            className={`nav-item nav-link ${
                                tab === "notes" ? "active" : ""
                            }`}
                        >
                            <Link
                                to={`${url}/notes`}
                                onClick={() => trackEvent(`select tab notes`)}
                                data-testid="notesTab"
                            >
                                <NotesTabTitle
                                    supportNotes={supportNotes?.list}
                                />
                            </Link>
                        </Nav.Link>
                    </Nav>
                    <Tab.Content>
                        <Switch>
                            {/**
                             * TODO (antonio): Maybe instead of having a
                             * default route here to support paths like
                             * /mobile/:mobile, we can instead redirect that
                             * path to /mobile/:mobile/personal.
                             */}
                            <SentryRoute exact path={path}>
                                <ErrorBoundary key="personal">
                                    <PersonalTab mobile={mobile} />
                                </ErrorBoundary>
                            </SentryRoute>

                            <SentryRoute path={`${path}/personal`}>
                                <ErrorBoundary key="personal">
                                    <PersonalTab mobile={mobile} />
                                </ErrorBoundary>
                            </SentryRoute>
                            <SentryRoute path={`${path}/agent`}>
                                <ErrorBoundary key="agent">
                                    <AgentTab
                                        mobile={mobile}
                                        searchOpaqueId={props.searchOpaqueId}
                                    />
                                </ErrorBoundary>
                            </SentryRoute>
                            <SentryRoute path={`${path}/merchant`}>
                                <ErrorBoundary key="merchant">
                                    <MerchantTab mobile={mobile} />
                                </ErrorBoundary>
                            </SentryRoute>
                            <SentryRoute path={`${path}/notes`}>
                                <ErrorBoundary key="notes">
                                    <NotesTab mobile={mobile} />
                                </ErrorBoundary>
                            </SentryRoute>
                        </Switch>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};
