/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ChangeRemittanceRecipient_transfer = {
    readonly opaqueId: string;
    readonly senderName: string;
    readonly senderMobile: string;
    readonly amount: string;
    readonly " $refType": "ChangeRemittanceRecipient_transfer";
};
export type ChangeRemittanceRecipient_transfer$data = ChangeRemittanceRecipient_transfer;
export type ChangeRemittanceRecipient_transfer$key = {
    readonly " $data"?: ChangeRemittanceRecipient_transfer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ChangeRemittanceRecipient_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeRemittanceRecipient_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "opaqueId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "type": "RemittanceTransferReceivedEntry",
  "abstractKey": null
};
(node as any).hash = 'abfe681f8ea17e21d149e29e02c7ae18';
export default node;
