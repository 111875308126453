import { useTranslation } from "react-i18next";

import { HistoryActionButton } from "@/components/HistoryActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { FreezeTransactionMutator } from "@/pages/mobile.[mobile].personal/FreezeTransaction";

import { CAN_FREEZE_TRANSACTION } from "@/types/Permissions";

interface FreezeFundsHistoryActionButtonProps {
    transferOrLedgerTransactionId: string;
    recipientMobile: string;
    receiveAmount: string;
}

export const FreezeTransactionHistoryActionButton = ({
    transferOrLedgerTransactionId,
    recipientMobile,
    receiveAmount,
}: FreezeFundsHistoryActionButtonProps) => {
    const { t } = useTranslation();
    const canFreezeTransaction = usePermissions(CAN_FREEZE_TRANSACTION);

    return canFreezeTransaction ? (
        <HistoryActionButton name={t("freeze-transaction--action")}>
            <FreezeTransactionMutator
                transferOrLedgerTransactionId={transferOrLedgerTransactionId}
                recipientMobile={recipientMobile}
                receiveAmount={receiveAmount}
            />
        </HistoryActionButton>
    ) : null;
};
