import { useContext } from "react";

import { CurrentCall, CurrentCallContext } from "@/context/CurrentCall";

export const useCurrentCall = (): CurrentCall => {
    const context = useContext(CurrentCallContext);
    if (context === undefined) {
        throw new Error(
            "useCurrentCall must be used within a CurrentCallProvider"
        );
    }
    return context;
};
