/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TransferReceivedEntry_tx = {
    readonly transferId: string;
    readonly senderName: string;
    readonly senderMobile: string;
    readonly maybeTerminatedSenderMobile: string;
    readonly paymentBatchId: string | null;
    readonly recipientMobile: string;
    readonly receiveAmount: string;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "ChangeRecipient_transfer" | "RefundP2P_transfer">;
    readonly " $refType": "TransferReceivedEntry_tx";
};
export type TransferReceivedEntry_tx$data = TransferReceivedEntry_tx;
export type TransferReceivedEntry_tx$key = {
    readonly " $data"?: TransferReceivedEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TransferReceivedEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransferReceivedEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedSenderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentBatchId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiveAmount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeRecipient_transfer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefundP2P_transfer"
    }
  ],
  "type": "TransferReceivedEntry",
  "abstractKey": null
};
(node as any).hash = 'c329832a25db0a9f2758a50686fe333f';
export default node;
