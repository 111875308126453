import * as React from "react";
import { ReactNode } from "react";
import { ButtonGroup, DropdownButton } from "react-bootstrap";

import { Amount } from "@/components/Amount";
import { ScamInvestigationFields } from "@/components/ScamInvestigationFields";
import { Timestamp } from "@/components/Timestamp";

import { M } from "@/utils/currency";

import { AgentHistoryEntry_entry } from "./__generated__/AgentHistoryEntry_entry.graphql";

// In some cases, we might pass a Fragment of `null` children as the
// `buttons` props.
//
// Instead of displaying an empty dropdown, we don't display any button
// at all.
//
const isButtonEmptyFragment = (rawButtons: React.ReactNode) => {
    let buttonIsEmptyFragment = false;
    if (
        rawButtons !== null &&
        typeof rawButtons === "object" &&
        Object.prototype.hasOwnProperty.call(rawButtons, "props")
    ) {
        // We can narrow the type of `buttons` to be a ReactElement as it
        // is an object and has a `props` property.
        const buttons = rawButtons as React.ReactElement;

        // `children` can be an Array, a single element, or null
        if (Array.isArray(buttons.props.children)) {
            buttonIsEmptyFragment = buttons.props.children.every(
                (child: ReactNode) => child === null
            );
        } else {
            // `buttons` can be a component without `children`, in which
            // case this value will be `undefined` and the following check
            // will yield `false`.
            buttonIsEmptyFragment = buttons.props.children === null;
        }
    }
    return buttonIsEmptyFragment;
};

interface Props {
    entry: AgentHistoryEntry_entry;
    buttons?: React.ReactNode;
}

export const HistoryEntryMiddleRow = ({ entry, buttons }: Props) => (
    <>
        <div
            className={`hidden group-hover:block group-hover:float-right group-hover:text-gray-400 ${
                entry.isPending ? "opacity-50" : ""
            }`}
            data-testid="tx-balance"
        >
            bal: <Amount amount={M.fromSerialized(entry.balance)} />
        </div>
        <i className={`${entry.isPending ? "opacity-50" : ""}`}>
            <Timestamp value={new Date(entry.whenEntered)} />
        </i>
        {entry.scamInvestigationFields && (
            <ScamInvestigationFields
                scamInvestigationFields={entry.scamInvestigationFields}
                opaqueId={entry.transferId || entry.agentTransactionId}
            />
        )}
        {buttons && !isButtonEmptyFragment(buttons) && (
            <div className="absolute top-3 right-2">
                <ButtonGroup size="sm">
                    <DropdownButton
                        variant="outline-info"
                        className="transaction-row-button float-right"
                        title=""
                        id={`dropdown-transfer-${entry.id}`}
                        data-testid="transfer-actions-dropdown"
                    >
                        {buttons}
                    </DropdownButton>
                </ButtonGroup>
            </div>
        )}
    </>
);
