import * as React from "react";
import { useState } from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { TicketLink } from "@/components/TicketLink";
import { TxInfo } from "@/components/TxInfo";

import { M } from "@/utils/currency";
import { formatDate } from "@/utils/time";

import { AgentHistoryAtxEntry_tx$key } from "./__generated__/AgentHistoryAtxEntry_tx.graphql";
import { AgentHistoryEntry_entry$key } from "./__generated__/AgentHistoryEntry_entry.graphql";

import { AgentAtxEntryAgentScamButton } from "./AgentAtxEntryAgentScamButton";
import { AgentAtxEntrySubLine } from "./AgentAtxEntrySubLine";
import { AgentAtxEntryTitle } from "./AgentAtxEntryTitle";
import { historyEntryFragment, txFragment } from "./AgentHistory.fragments";
import * as HistoryEntry from "./HistoryEntry";

interface Props {
    entry: AgentHistoryEntry_entry$key;
    tx: AgentHistoryAtxEntry_tx$key;
    isFocussed: boolean;
}

const AgentAtxEffectiveVolumeEntry = (props: Props) => {
    const [isExpanded, setExpanded] = useState(props.isFocussed);
    const { t } = useTranslation();

    const tx = useFragment(txFragment, props.tx);
    const entry = useFragment(historyEntryFragment, props.entry);

    const { id, isPending, amount } = entry;
    const { agentUserName, composingEntries: ascEntries } = tx;
    const descEntries = [...ascEntries].reverse();

    const buttons = tx.isDeposit
        ? [<AgentAtxEntryAgentScamButton tx={tx} key={tx.agentTransactionId} />]
        : null;

    return (
        <HistoryEntry.Container id={id} isFocussed={props.isFocussed}>
            <HistoryEntry.AccordionSummaryContainer
                onToggle={() => setExpanded(!isExpanded)}
                subTxCount={descEntries.length}
                isOpen={isExpanded}
            >
                <HistoryEntry.TopRow
                    isPending={isPending}
                    amount={M.fromSerialized(amount)}
                >
                    <AgentAtxEntryTitle tx={tx} />
                    {tx.isCancelled && (
                        <TxInfo>
                            <Badge variant="danger">
                                {t("agent-atx-entry--cancelled")}
                            </Badge>
                        </TxInfo>
                    )}
                    <AgentAtxEntrySubLine tx={tx} hideId={true} />
                    {tx.relatedTicket && (
                        <TicketLink
                            status={tx.relatedTicket.status}
                            slackMessageUrl={tx.relatedTicket.slackMessageUrl}
                        />
                    )}
                </HistoryEntry.TopRow>
                <HistoryEntry.MiddleRow entry={entry} buttons={buttons} />
            </HistoryEntry.AccordionSummaryContainer>
            <HistoryEntry.AccordionDetailsContainer isExpanded={isExpanded}>
                {descEntries.map((e) => (
                    <HistoryEntry.SubtransactionContainer
                        key={e.agentTransactionId}
                    >
                        <HistoryEntry.TopRow
                            amount={M.fromSerialized(e.amount)}
                            isPending={e.isPending}
                        >
                            {`${
                                e.isDeposit
                                    ? t("agent-atx-entry--deposit")
                                    : t("agent-atx-entry--withdraw")
                            } (${e.agentTransactionId})`}
                        </HistoryEntry.TopRow>
                        <div>
                            <i>
                                {formatDate(new Date(e.whenEntered))}
                                {!!agentUserName && ` by ${agentUserName}`}
                            </i>
                        </div>
                    </HistoryEntry.SubtransactionContainer>
                ))}
            </HistoryEntry.AccordionDetailsContainer>
        </HistoryEntry.Container>
    );
};

export default AgentAtxEffectiveVolumeEntry;
