import { ReactNode, createContext, useMemo } from "react";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PayloadError } from "relay-runtime";

import { SubmitButton } from "./SubmitButton";

export type OperationResultType = "success" | "error" | "none";
export type OperationResult = {
    type: OperationResultType;
    message: string;
};
export type FormContextType = {
    isDisabled: boolean;
};

const DefaultFormContext: FormContextType = {
    isDisabled: false,
} as const;

export const FormContext = createContext<FormContextType>(DefaultFormContext);
export type FormProps = {
    children?: ReactNode;
    isWorking: boolean;
    result: string | null;
    hideFieldsAfterSubmit?: boolean;
    isValid: boolean;
    submitText: string;
    onSubmit: () => void;
    onDone?: () => void;
    errors?: readonly PayloadError[] | null;
};

/**
 * A hacky function that extracts links from a string and displays them at the
 * end of the message.
 *
 * @param message An error message containing links
 * @returns React element displaying links as <a> elements
 */
function formatLinksInErrorMessage(message: string) {
    const urlRegex =
        /https:\/\/frontplugin\.wave-internal\.com[\w#!:.?+=&%@!\-/]*/gm;
    const links = message.match(urlRegex);

    return (
        <span>
            {message.replace(urlRegex, "")}
            {links?.map((link) => (
                <a href={link} key={link} target={"_blank"} rel="noreferrer">
                    {link}
                </a>
            ))}
        </span>
    );
}

/**
 * Form component, used in conjunction with `MutatorModal`. Provides a wrapper around
 * other components, and adds a few features:
 * 1. Provides a "smart" Submit button that changes its text from *submitText* to "Done"
 * when the mutation completes successfully, and is unclickable if the mutation is
 * in progress, or the form is invalid.
 * 2. Displays the mutation result for both successes and failures, allowing the user to
 * resubmit the mutation in case of a failure.
 * 3. Provides `isDisabled` in a context object so the child components could grey-out
 * themselves when necessary.
 *
 * @param props `isWorking` and Relay-induced data (`result` and `errors`) should come
 * from `MutatorModal`
 *
 * @example
 * const mutator = useMutator(...);
 * <Form {...mutator}
    isValid={isValid}
    submitText="Submit"
    onSubmit={handleSubmit}
    onDone={onHide}>
    <!-- child elements -->
 </Form>
 *
 * `
 */
export const Form = ({
    result,
    onDone,
    children,
    isValid,
    isWorking,
    submitText,
    onSubmit,
    errors,
    hideFieldsAfterSubmit,
}: FormProps) => {
    const { t } = useTranslation();
    const isDisabled = useMemo(() => {
        if (isWorking || (result && (!errors || errors.length == 0)))
            return true;
        return false;
    }, [isWorking, result, errors]);

    const resultPanel = useMemo(() => {
        if (result && (!errors || errors.length == 0)) {
            return (
                <Alert variant="success" className="flex flex-row items-center">
                    <span className="flex-grow">{result}</span>
                    {onDone && (
                        <Button
                            onClick={onDone}
                            className="float-right"
                            data-testid="form-done-button"
                        >
                            {t("form--done")}
                        </Button>
                    )}
                    <div className="clearfix" />
                </Alert>
            );
        }

        if (errors && errors.length > 0) {
            const error = errors[errors.length - 1];

            return (
                <>
                    <SubmitButton isValid={isValid} isWorking={isWorking}>
                        {submitText}
                    </SubmitButton>
                    <Alert
                        variant="danger"
                        className="flex flex-row items-center"
                    >
                        <span className="flex-grow">
                            {formatLinksInErrorMessage(error.message)}
                        </span>
                        <div className="clearfix" />
                    </Alert>
                </>
            );
        }

        return (
            <SubmitButton isValid={isValid} isWorking={isWorking}>
                {submitText}
            </SubmitButton>
        );
    }, [t, result, onDone, isValid, isWorking, submitText, errors]);

    const contextValue: FormContextType =
        useMemo<FormContextType>((): FormContextType => {
            return { isDisabled };
        }, [isDisabled]);
    return (
        <FormContext.Provider value={contextValue}>
            <form
                autoComplete="off"
                onSubmit={(e) => {
                    e.preventDefault();
                    isValid && onSubmit();
                }}
            >
                {result && hideFieldsAfterSubmit ? null : children}
                {resultPanel}
            </form>
        </FormContext.Provider>
    );
};
