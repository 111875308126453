import { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const useInterval = (callback: () => void, delay: number) => {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return () => {
            /*noop*/
        };
    }, [delay]);
};

/**
 * Component that upon loading, and then periodically (every 10 minutes)
 * fetches `/circle_sha1.txt` to determine if the app has been updated on the backend.
 * If the fetch result becomes different at some point, the component displays a
 * warning prompting the user to reload the app.
 */
export const UpdateWatcher = () => {
    const { t } = useTranslation();

    const interval_ms = 10 * 60 * 1000;
    const [show, setShow] = useState(false);
    const [initialHash, setInitialHash] = useState<string | null>(null);
    const retrieve = useCallback(async (): Promise<string | null> => {
        return await fetch("/circle_sha1.txt", {
            method: "GET",
            redirect: "manual",
            cache: "no-cache",
            headers: {
                "upgrade-insecure-requests": "1",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch SHA1");
                }
                return response.text();
            })
            .then((sha1) => {
                return sha1;
            })
            .catch((error) => {
                console.error(`SHA1 not retrieved: ${error}`); // eslint-disable-line no-console
                return null;
            });
    }, []);

    const determineNeedRefresh = useCallback(async () => {
        if (!initialHash) {
            // no `return` here on purpose - we want to err on the side of caution
            // and reload if we fail to get the initial SHA1 but retrieve it successfully
            // on a timer timeout
        }
        const result = await retrieve();
        if (result && result != initialHash) {
            setShow(true);
        }
    }, [retrieve, initialHash]);

    // Due to peculiar behaviour of setTimeout / setInterval, we have to use the below
    // hook rather than do the check inside useEffect or a callback
    useInterval(() => determineNeedRefresh(), interval_ms);
    useEffect(() => {
        retrieve().then((result) => {
            if (result) {
                setInitialHash(result);
            }
        });
    }, [retrieve, setInitialHash]);

    return (
        <div>
            {show && (
                <Alert variant={"warning"} className={"mb-0"}>
                    {t("update-watcher--description")}
                    <Button
                        className={"ml-2"}
                        variant="outline-warning"
                        onClick={() => location.reload()}
                    >
                        &#8635;
                    </Button>
                </Alert>
            )}
        </div>
    );
};
