import React from "react";
import { useTranslation } from "react-i18next";

import { notEmpty } from "@/utils/value";

const SEVEN_DAYS_MILLIS = 7 * 24 * 60 * 60 * 1000;

type SupportNote = {
    readonly noteId: string;
    readonly whenCreated: string;
};

type SupportNotes = ReadonlyArray<SupportNote | null> | undefined;

function isFromThisWeek(note: SupportNote | null | undefined) {
    if (!note) {
        return false;
    }
    const date = new Date(note.whenCreated);
    const sevenDaysAgo = new Date(Date.now() - SEVEN_DAYS_MILLIS);
    return date >= sevenDaysAgo;
}

export const NotesTabTitle = (props: {
    supportNotes: SupportNotes;
}): React.ReactElement => {
    const { t } = useTranslation();
    const supportNotes = props.supportNotes;
    const notesCount = supportNotes?.length || 0;
    const notesThisWeek =
        supportNotes?.filter(notEmpty)?.filter(isFromThisWeek) ?? [];
    const hasNoteThisWeek = notesThisWeek?.length > 0;

    const notesBadge =
        notesCount > 0 && hasNoteThisWeek ? (
            <div className="inline-block">
                <span
                    data-testid="notesCount"
                    className="badge rounded-pill text-xs leading-none text-bold text-white align-top bg-orange-400"
                >
                    {notesThisWeek.length}
                </span>
            </div>
        ) : null;

    return (
        <span data-testid="notesTitle">
            {t("notes-tab--title")} {notesBadge}
        </span>
    );
};
