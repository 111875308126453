import * as Sentry from "@sentry/react";

import { M } from "@/utils/currency";

export const Amount = ({ amount }: { amount: M }) => {
    if (!(amount instanceof M)) {
        Sentry.captureException(new Error(`Bad amount ${amount}`));
    }
    return <span className="amount">{amount.toString()}</span>;
};
