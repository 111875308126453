import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { SendRequestToBeAnAgentLinkMutator } from "@/pages/mobile.[mobile].personal/SendRequestToBeAnAgentLink";

export const SendRequestToBeAnAgentLinkAction = (props: { mobile: string }) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("send-request-agent-link--action")}
            requiredPermissions={[
                "supportapp_send_request_to_be_an_agent_link",
            ]}
        >
            <SendRequestToBeAnAgentLinkMutator mobile={props.mobile} />
        </ActionButton>
    );
};
