/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SwitchVoiceRouteGSMMutationVariables = {};
export type SwitchVoiceRouteGSMMutationResponse = {
    readonly switchGsm: {
        readonly result: string | null;
    } | null;
};
export type SwitchVoiceRouteGSMMutation = {
    readonly response: SwitchVoiceRouteGSMMutationResponse;
    readonly variables: SwitchVoiceRouteGSMMutationVariables;
};



/*
mutation SwitchVoiceRouteGSMMutation {
  switchGsm {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SwitchGSM",
    "kind": "LinkedField",
    "name": "switchGsm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SwitchVoiceRouteGSMMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SwitchVoiceRouteGSMMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "04233925a42b679a7daba177ee219598",
    "id": null,
    "metadata": {},
    "name": "SwitchVoiceRouteGSMMutation",
    "operationKind": "mutation",
    "text": "mutation SwitchVoiceRouteGSMMutation {\n  switchGsm {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '70db537b04700ab3a2c4b2beee5030af';
export default node;
