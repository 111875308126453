import { Fragment } from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { AgentProfileLink } from "@/components/AgentProfileLink";
import { HistoryActionButton } from "@/components/HistoryActionButton";
import { ProfileLink } from "@/components/ProfileLink";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import HistoryEntry from "@/pages/mobile.[mobile]/HistoryEntry";
import { ReportCustomerScamMutator } from "@/pages/mobile.[mobile]/ReportCustomerScam";

import { M } from "@/utils/currency";

import { MerchantAtxEntry_tx } from "./__generated__/MerchantAtxEntry_tx.graphql";

function _MerchantAtxEntry({ tx }: { tx: MerchantAtxEntry_tx }) {
    const { t } = useTranslation();
    const canReportScam = usePermissions("supportapp_report_customer_scam");
    const canReportScamWithDetails = usePermissions(
        "supportapp_report_customer_scam_with_details"
    );

    const title = (
        <TxTitle>
            {tx.isDeposit
                ? t("merchant-atx-entry--title-deposit")
                : t("merchant-atx-entry--title-withdraw")}

            {tx.cashierMobile !== null ? (
                <ProfileLink mobile={tx.cashierMobile} name={tx.cashierName} />
            ) : (
                tx.cashierName
            )}
        </TxTitle>
    );

    const link = (
        <AgentProfileLink
            agentId={tx.agentId || tx.subagentId || ""}
            name={tx.agentName || tx.subagentName || ""}
            opaqueId={tx.agentTransactionId}
            agentUfid={tx.agentUfid || tx.subagentUfid || ""}
        />
    );

    const historyActionButton =
        tx.cashierMobile && !tx.isDeposit ? (
            <HistoryActionButton
                name="Report Customer Scam"
                disabled={!canReportScam}
            >
                <ReportCustomerScamMutator
                    transactionId={tx.agentTransactionId}
                    mobile={tx.cashierMobile}
                    amountStolen={M.fromSerialized(tx.amount)}
                    scamReports={tx.scamReports}
                    hideAdditionalInfoFields={!canReportScamWithDetails}
                />
            </HistoryActionButton>
        ) : undefined;

    return (
        <HistoryEntry
            reversedTransfers={{}}
            activeDeviceBlocks={[]}
            entry={tx}
            title={
                <Fragment>
                    {title}
                    {tx.isCancelled && (
                        <TxInfo>
                            <Badge variant="danger">
                                {t("agent-atx-entry--cancelled")}
                            </Badge>
                        </TxInfo>
                    )}
                    <TxInfo>
                        {t("merchant-atx-entry--description", {
                            transaction: tx.agentTransactionId,
                        })}{" "}
                        {link}
                    </TxInfo>
                </Fragment>
            }
            buttons={historyActionButton}
        />
    );
}
export default createFragmentContainer(_MerchantAtxEntry, {
    tx: graphql`
        fragment MerchantAtxEntry_tx on AgentTransactionEntry {
            agentTransactionId
            isDeposit
            amount
            cashierName: counterpartyNameOnly
            cashierMobile: maybeTerminatedCustomerMobile
            agentId
            agentUfid
            agentName
            subagentId
            subagentName
            subagentUfid
            type
            isCancelled
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            ...HistoryEntry_entry
        }
    `,
});
