/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BillFieldInput = {
    name: string;
    value?: string | null | undefined;
};
export type PurchaseAirtimeMutationVariables = {
    bill_amount?: string | null | undefined;
    client_id: string;
    bill_fields: Array<BillFieldInput | null>;
    sender_u_id: string;
};
export type PurchaseAirtimeMutationResponse = {
    readonly supportPurchaseAirtime: {
        readonly response: {
            readonly __typename: "AsyncPending";
            readonly clientId: string | null;
        } | {
            readonly __typename: "PayBill";
            readonly payment: {
                readonly whenCreated: string;
            } | null;
            readonly userFacingError: {
                readonly code: string;
                readonly message: string;
            } | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
    } | null;
};
export type PurchaseAirtimeMutation = {
    readonly response: PurchaseAirtimeMutationResponse;
    readonly variables: PurchaseAirtimeMutationVariables;
};



/*
mutation PurchaseAirtimeMutation(
  $bill_amount: Money
  $client_id: String!
  $bill_fields: [BillFieldInput]!
  $sender_u_id: ID!
) {
  supportPurchaseAirtime(billAmount: $bill_amount, clientId: $client_id, fields: $bill_fields, senderUId: $sender_u_id) {
    response {
      __typename
      ... on AsyncPending {
        clientId
      }
      ... on PayBill {
        payment {
          whenCreated
          id
        }
        userFacingError {
          code
          message
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bill_amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bill_fields"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "client_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sender_u_id"
},
v4 = [
  {
    "kind": "Variable",
    "name": "billAmount",
    "variableName": "bill_amount"
  },
  {
    "kind": "Variable",
    "name": "clientId",
    "variableName": "client_id"
  },
  {
    "kind": "Variable",
    "name": "fields",
    "variableName": "bill_fields"
  },
  {
    "kind": "Variable",
    "name": "senderUId",
    "variableName": "sender_u_id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientId",
      "storageKey": null
    }
  ],
  "type": "AsyncPending",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenCreated",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PayBillUserFacingError",
  "kind": "LinkedField",
  "name": "userFacingError",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseAirtimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SupportPurchaseAirtime",
        "kind": "LinkedField",
        "name": "supportPurchaseAirtime",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "response",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillTransfer",
                    "kind": "LinkedField",
                    "name": "payment",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "type": "PayBill",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PurchaseAirtimeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SupportPurchaseAirtime",
        "kind": "LinkedField",
        "name": "supportPurchaseAirtime",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "response",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillTransfer",
                    "kind": "LinkedField",
                    "name": "payment",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "type": "PayBill",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e4a9a73707b94fd21dbe38f55e7a9a0",
    "id": null,
    "metadata": {},
    "name": "PurchaseAirtimeMutation",
    "operationKind": "mutation",
    "text": "mutation PurchaseAirtimeMutation(\n  $bill_amount: Money\n  $client_id: String!\n  $bill_fields: [BillFieldInput]!\n  $sender_u_id: ID!\n) {\n  supportPurchaseAirtime(billAmount: $bill_amount, clientId: $client_id, fields: $bill_fields, senderUId: $sender_u_id) {\n    response {\n      __typename\n      ... on AsyncPending {\n        clientId\n      }\n      ... on PayBill {\n        payment {\n          whenCreated\n          id\n        }\n        userFacingError {\n          code\n          message\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5046ef3621c82bb69dc3e7b55388b9f9';
export default node;
