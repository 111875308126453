import _ from "lodash";
import {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { ReactNode } from "react";
import { FormControl, InputGroup } from "react-bootstrap";

import { FieldProps } from "@/types/FormFieldProps";

import { FormContext } from "./Form";
import { FormGroup } from "./FormGroup";

type UseCheckboxResult = {
    checked: boolean;
    setChecked: (checked: boolean) => void;
};

export const useCheckbox = (initialState = false) => {
    const [checked, setChecked] = useState<boolean>(initialState);
    return { checked, setChecked };
};

export type CheckboxProps = FieldProps<boolean> & UseCheckboxResult;
/**
 * Standard Checkbox
 *
 * - The internal state is a *boolean*.
 * - `defaultValue` attribute can be used to override the initial field checked
 * - `validations` specify the validation results (see `types/FormValidation.ts`) for the field
 *
 * @example
 *
 * const checkbox = useCheckbox();
 * ...
 * return <Checkbox {...checkbox} label="Label" ... />;
 */
export const Checkbox = (props: CheckboxProps) => {
    const {
        autoFocus,
        label,
        checked,
        setChecked,
        defaultValue,
        name,
        validations,
        disabled,
    } = props;
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setChecked(e.target.checked);
        },
        [setChecked]
    );

    useEffect(() => {
        if (!_.isNil(defaultValue)) {
            setChecked(defaultValue ?? false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const context = useContext(FormContext);
    return (
        <FormGroup label={label} name={name} validations={validations}>
            <InputGroup>
                <FormControl
                    disabled={context.isDisabled || disabled}
                    type="checkbox"
                    autoFocus={autoFocus}
                    checked={checked}
                    onChange={handleChange}
                />
            </InputGroup>
        </FormGroup>
    );
};

export const DenseCheckbox = (props: { children: ReactNode }) => {
    return <div className="dense-checkbox">{props.children}</div>;
};
