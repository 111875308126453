import { useTranslation } from "react-i18next";

import { M } from "@/utils/currency";

import { Amount } from "./Amount";

export const BalanceInfo = ({
    balance,
    amountPending,
}: {
    balance?: M;
    amountPending?: M;
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="balance-info">
                <span className="italic">{t("balance-info--balance")}</span>
                <div className="float-right italic">
                    {balance ? <Amount amount={balance} /> : <>??.???</>}
                </div>
            </div>
            {amountPending && !amountPending?.scalar.isZero() && (
                <div className="balance-info amount-pending">
                    <span className="italic">{t("balance-info--pending")}</span>
                    <div className="float-right italic">
                        <Amount amount={amountPending} />
                    </div>
                </div>
            )}
        </>
    );
};
