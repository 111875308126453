/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnblockFromUsingQrCards_user = {
    readonly id: string;
    readonly qrCardBlock: {
        readonly whenBlocked: string;
        readonly blockedBy: string;
        readonly blockReason: string;
        readonly whenExpires: string | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"DirectDepositBlockHistory_user">;
    readonly " $refType": "UnblockFromUsingQrCards_user";
};
export type UnblockFromUsingQrCards_user$data = UnblockFromUsingQrCards_user;
export type UnblockFromUsingQrCards_user$key = {
    readonly " $data"?: UnblockFromUsingQrCards_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UnblockFromUsingQrCards_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnblockFromUsingQrCards_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "qrCardBlock",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenBlocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenExpires",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DirectDepositBlockHistory_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '7fa1899620c4c89c84aea386361734dc';
export default node;
