/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentHistorySummary_summary = {
    readonly date: string;
    readonly whenDayEnded: string;
    readonly dailyAward: string;
    readonly numSignups: number;
    readonly absoluteTransactionVolume: string;
    readonly effectiveTransactionVolume: string;
    readonly totalCommission: string;
    readonly " $refType": "AgentHistorySummary_summary";
};
export type AgentHistorySummary_summary$data = AgentHistorySummary_summary;
export type AgentHistorySummary_summary$key = {
    readonly " $data"?: AgentHistorySummary_summary$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentHistorySummary_summary">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentHistorySummary_summary",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenDayEnded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dailyAward",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numSignups",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "absoluteTransactionVolume",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "effectiveTransactionVolume",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCommission",
      "storageKey": null
    }
  ],
  "type": "AgentDailySummary",
  "abstractKey": null
};
(node as any).hash = '241af0aea9d4f25304b4af8077295370';
export default node;
