/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SendAgentAppLinkMutationVariables = {
    mobile: string;
};
export type SendAgentAppLinkMutationResponse = {
    readonly sendAgentAppLink: {
        readonly result: string | null;
    } | null;
};
export type SendAgentAppLinkMutation = {
    readonly response: SendAgentAppLinkMutationResponse;
    readonly variables: SendAgentAppLinkMutationVariables;
};



/*
mutation SendAgentAppLinkMutation(
  $mobile: String!
) {
  sendAgentAppLink(mobile: $mobile) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      }
    ],
    "concreteType": "SendAgentAppLink",
    "kind": "LinkedField",
    "name": "sendAgentAppLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendAgentAppLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendAgentAppLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "58b11b706e8eeb4d8e585022fd92c574",
    "id": null,
    "metadata": {},
    "name": "SendAgentAppLinkMutation",
    "operationKind": "mutation",
    "text": "mutation SendAgentAppLinkMutation(\n  $mobile: String!\n) {\n  sendAgentAppLink(mobile: $mobile) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'da3198127bcafb979a686539399452ee';
export default node;
