import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useLazyLoadQuery } from "react-relay";

import { Amount } from "@/components/Amount";
import { LoadingIndicator } from "@/components/Loading";
import { ProfileLink } from "@/components/ProfileLink";

import { usePermissions } from "@/hooks/usePermissions";

import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";

import { M } from "@/utils/currency";

import { UserHistoryAroundTransferQuery } from "./__generated__/UserHistoryAroundTransferQuery.graphql";

import PersonalHistoryEntry from "./PersonalHistoryEntry";

const userHistoryQuery = graphql`
    query UserHistoryAroundTransferQuery(
        $mobile: String!
        $around: ID!
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $first: Int!
        $last: Int!
    ) {
        wallet(mobile: $mobile) {
            mobile
            balance @include(if: $canReadBalance)
            supportHistoryConnection(
                first: $first
                last: $last
                around: $around
            ) @include(if: $canReadHistory) {
                edges {
                    node {
                        __typename
                        id
                        whenEntered
                        ...PersonalHistoryEntry_entry
                        ... on HistoryEntry {
                            isPending
                            isCancelled
                            summary
                            amount
                            balance @include(if: $canReadBalance)
                            ... on TransferSentEntry {
                                transferId
                            }
                            ... on TransferReceivedEntry {
                                transferId
                            }
                            ... on MobileTransferEntry {
                                transferId
                            }
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                }
            }
        }
    }
`;

type UserHistoryAroundTransferProps = {
    mobile: string;
    name: string;
    transferId: string;
    first?: number;
    last?: number;
    className?: string;
    listClassName?: string;
    listWrapperClassName?: string;
};

export function UserHistoryAroundTransfer({
    mobile,
    name,
    transferId,
    first = 1,
    last = 5,
    className,
    listClassName,
    listWrapperClassName,
}: UserHistoryAroundTransferProps) {
    const { t } = useTranslation();
    const listRef = useRef<HTMLDivElement>(null);

    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);

    const { wallet } = useLazyLoadQuery<UserHistoryAroundTransferQuery>(
        userHistoryQuery,
        {
            mobile,
            around: transferId,
            canReadHistory,
            canReadBalance,
            first,
            last,
        }
    );

    useEffect(() => {
        // Scroll to the bottom of the transaction list on load
        (listRef.current?.firstChild as Element)?.scrollIntoView();
    }, [listRef]);

    return (
        <div className={className}>
            {wallet ? (
                <>
                    <p className="mt-4 mb-2 text-lg">
                        {t("user-history-around-transfer--title", {
                            name,
                        })}
                    </p>
                    <div>
                        {wallet.balance ? (
                            <p className="flex justify-between border-t border-b p-2 italic">
                                <span>
                                    {t(
                                        "user-history-around-transfer--balance",
                                        {
                                            name,
                                        }
                                    )}
                                </span>
                                <Amount
                                    amount={M.fromSerialized(wallet.balance)}
                                />
                            </p>
                        ) : null}
                        <div className={listWrapperClassName}>
                            <div
                                className={`${listClassName} flex flex-col-reverse`}
                                ref={listRef}
                            >
                                {/* Note: the list is displayed in reverse order */}
                                {Array.from(
                                    wallet.supportHistoryConnection?.edges ?? []
                                ).map((edge) => {
                                    const node = edge?.node;
                                    if (!(node?.amount && node?.balance))
                                        return;
                                    return (
                                        <div
                                            key={node.id}
                                            className={`${
                                                node.transferId === transferId
                                                    ? "bg-yellow-100"
                                                    : ""
                                            } ${
                                                node.isPending
                                                    ? "opacity-50"
                                                    : ""
                                            }`}
                                        >
                                            <PersonalHistoryEntry
                                                entry={node}
                                                reversedTransfers={{}}
                                                activeDeviceBlocks={[]}
                                                hideButtons={true}
                                            />
                                        </div>
                                    );
                                })}
                                {wallet.supportHistoryConnection?.pageInfo
                                    .hasNextPage ? (
                                    <div className="text-center p-2 bg-orange-100">
                                        <p>
                                            {t(
                                                "user-history-around-transfer--more"
                                            )}
                                        </p>
                                        <ProfileLink
                                            mobile={mobile}
                                            name={name}
                                            opaqueId={transferId}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <LoadingIndicator />
            )}
        </div>
    );
}
