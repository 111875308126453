import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { UnlinkLegalEntityMutator } from "@/pages/mobile.[mobile].personal/UnlinkLegalEntity";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const UnlinkLegalEntityAction = (props: {
    wallet: PersonalTabHeader_wallet;
}) => {
    const wallet = props.wallet;
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("unlink-entity--action")}
            disabled={(wallet.legalEntity?.wallets?.length ?? 0) <= 1}
            requiredPermissions={["supportapp_unlink_legal_entity"]}
        >
            {wallet.legalEntity && wallet.legalEntity.wallets.length > 1 && (
                <UnlinkLegalEntityMutator walletId={wallet.id} />
            )}
        </ActionButton>
    );
};
