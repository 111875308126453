import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { ShowWoyofalCodesMutator } from "@/pages/mobile.[mobile].personal/ShowWoyofalCodes";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const ShowWoyofalCodesAction = (props: {
    wallet: PersonalTabHeader_wallet;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            name={t("woyofal-code--action")}
            requiredPermissions={["supportapp_show_woyofal_code"]}
        >
            <ShowWoyofalCodesMutator wallet={props.wallet} />
        </ActionButton>
    );
};
