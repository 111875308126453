import { useTranslation } from "react-i18next";

import {
    ActionButton,
    VOIPVerificationLevel,
} from "../../components/ActionButton";

import { ShowSMSCodeMutator } from "@/pages/mobile.[mobile].personal/ShowSMSCode";

export const ShowSmsCodeAction = (props: { mobile: string }) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            name={t("show-sms-code--action")}
            requiredPermissions={["supportapp_show_sms_code"]}
            data-testid={"show-sms-code-btn"}
            VOIPVerification={VOIPVerificationLevel.FORBIDDEN}
        >
            <ShowSMSCodeMutator mobile={props.mobile} />
        </ActionButton>
    );
};
