import { ReactNode, useCallback, useMemo, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { useRelayEnvironment } from "react-relay";
import { GraphQLTaggedNode, PayloadError, commitMutation } from "relay-runtime";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { RelayModalProps } from "@/types/MutatorModal";

type TrackActionInfoType<T> = {
    name: string;
    /**
     * Static data to add to the analytics tracking event.
     */
    data: { [key: string]: any };
    /**
     * Function to extract fields from the response data to add to the analytics
     * tracking event.
     */
    extractResponseData?: (response: T) => { [key: string]: any };
};

type UseMutatorProps<RelayResultType, ParsedResultType> = {
    onMutationSuccess: (result: RelayResultType) => ParsedResultType;
    trackActionInfo: TrackActionInfoType<RelayResultType>; // for tracking to amplitude
} & RelayModalProps;

type UseMutatorReturnType = {
    isWorking: boolean;
    errors: readonly PayloadError[] | readonly Error[] | null;
} & RelayModalProps;

/**
 * Hook for the `MutatorModal` component state.  Requires the following parameters passed in a dict:
 *
 * `onMutationSuccess`: a function that should take `ABCDMutationResponse` and return a parsed result.
 * This parsed result is typically a nullable string (in particular, if you use the bundled `Form` component),
 * but it is flexible (for an example see `ShowSMSCode.tsx`)
 *
 * `relay` Relay Prop - supplied by the `createFragmentContainer` HOC wrapping the component that uses this hook
 *
 * `show` boolean that determines whether we need to show the pop-up or not
 *
 * `onHide` function that is called when we close the dialog. May be useful in cases we need to fully refresh
 * the profile or navigate away upon completion of the mutatian.
 *
 * As `RelayModalProps` are typically a subset of the action unit's props, the `relay`, `onHide` and `show`parameters
 * are typically passed in via prop-spreading as below:
 *
 * @example
 const _OurAwesomeMutator = (props: OurAwesomeInputParams
                                & RelayModalProps) => {
 ...
 const mutator = useMutator({ onMutationSuccess, ...props });
 ...
 };

 * @returns dict that should be prop-spread into `<MutatorModal>` **and** `<Form>` (if applicable).
 * The returned `submit` function should be used to specify parameters to the mutation and submit it.
 */
export function useMutator<RelayResultType, VariablesType, ParsedResultType>(
    props: UseMutatorProps<RelayResultType, ParsedResultType>
) {
    const [isWorking, setWorking] = useState(false);
    const [result, setResult] = useState<ParsedResultType | null>(null);
    const [errors, setErrors] = useState<readonly PayloadError[] | null>(null);
    const { relay, onMutationSuccess, trackActionInfo } = props;
    const trackEvent = useTrackEvent();
    const relayEnvironment = useRelayEnvironment();

    const submit = useCallback(
        (mutation: GraphQLTaggedNode, variables: VariablesType) => {
            setWorking(true);
            commitMutation(relay?.environment ?? relayEnvironment, {
                mutation,
                variables,
                onCompleted: (response, payloadErrors) => {
                    setErrors(payloadErrors || null);
                    const propsToTrack = JSON.parse(
                        JSON.stringify(trackActionInfo.data)
                    );
                    if (payloadErrors && payloadErrors.length > 0)
                        propsToTrack["errors"] = payloadErrors;
                    if (trackActionInfo.extractResponseData) {
                        Object.assign(
                            propsToTrack,
                            trackActionInfo.extractResponseData(
                                response as RelayResultType
                            )
                        );
                    }
                    trackEvent(trackActionInfo.name, propsToTrack);
                    if (response) {
                        setResult(
                            onMutationSuccess(response as RelayResultType)
                        );
                    }
                    setWorking(false);
                },
                onError: (error) => {
                    trackEvent(trackActionInfo.name, {
                        ...trackActionInfo.data,
                        error: error,
                    });
                    setErrors([error]);
                    setWorking(false);
                },
            });
        },
        [
            relay,
            relayEnvironment,
            trackActionInfo,
            trackEvent,
            onMutationSuccess,
        ]
    );

    const { show, onHide } = props;
    return useMemo(
        () => ({ isWorking, result, submit, errors, show, onHide }),
        [isWorking, result, submit, errors, show, onHide]
    );
}

export type MutatorModalProps = {
    children: ReactNode;
    title: string | ReactNode;
    /** The <MutatorModal> is usually wrapping a <Form> component which already
     * handles and displays errors from the request, and might lead to
     * duplicated information (displaying errors twice).
     * In the few cases where that is not the case, we pass the `showErrors`
     * boolean to the <MutatorModal> components so that errors are still
     * displayed when they occur.
     */
    showErrors?: boolean;
} & RelayModalProps &
    UseMutatorReturnType;

/**
 * The outermost component returned by the action unit to provide a pop-up with Relay
 * functionality.
 *
 * Its state is initialised using the `useMutator` hook with the subsequent prop-spreading.
 * If you use the `Form` component, the result of the hook should be spread to it as well
 * (this is for `isWorking` and relay-induced data).
 *
 * The child components are what will be displayed inside the pop-up.
 *
 * @example
 *
 *
 const mutator = useMutator(...);
 ...
 <MutatorModal {...mutator} title="Our Awesome Dialog Title">
 <Form {...mutator} isValid={isValid} ...
 </Form></MutatorModal>
 *
 */
export function MutatorModal(props: MutatorModalProps) {
    return (
        <Modal show={props.show} onHide={props.onHide} size={props.size}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
                {props.showErrors && props.errors ? (
                    <Alert variant="danger">
                        <ul>
                            {props.errors.map((error, index) => (
                                <li key={index}>{error.message}</li>
                            ))}
                        </ul>
                    </Alert>
                ) : null}
            </Modal.Body>
        </Modal>
    );
}
