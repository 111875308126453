import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { BlockUnblockUserMutator } from "@/pages/mobile.[mobile]/BlockUnblockUser";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const BlockUnblockUserLegalEntityAction = (props: {
    wallet: PersonalTabHeader_wallet;
}) => {
    const wallet = props.wallet;
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("block-user--block-by-entity--action")}
            disabled={!!wallet.activeLegalEntityBlock}
            requiredPermissions={["supportapp_block_user"]}
        >
            <BlockUnblockUserMutator
                unregisteredMobile={null}
                wallet={wallet}
                unblock={false}
                byLegalEntity={true}
            />
        </ActionButton>
    );
};
