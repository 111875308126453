import * as Sentry from "@sentry/react";
import _ from "lodash";
import {
    GraphQLTaggedNode,
    Environment as ReactRelayEnvironment,
    RelayRefetchProp,
    commitMutation,
} from "react-relay";
import {
    Network,
    OperationType,
    PayloadError,
    RecordSource,
    Environment as RelayEnvironment,
    RequestParameters,
    Store,
    Variables,
} from "relay-runtime";

import i18n from "../locales/i18n";

import { COUNTRIES, Country } from "../types/countries";

import { IS_PROD, WAVE_ROOT_URL } from "../utils/environment";
import { buildUrlString } from "@/utils/url";

// A list of error messages as reported by different browsers in case a `fetch` could
// not be completed because of a network error
const NETWORK_ERROR_MESSAGES = [
    "Failed to fetch", // Chrome error message
    "NetworkError when attempting to fetch resource.", // Firefox error message
    "Could not connect to the server.", // Safari's error message
    "Load failed", // Mobile Safari error message
];

export const isNetworkError = (error: any) => {
    return (
        error instanceof TypeError &&
        NETWORK_ERROR_MESSAGES.includes(error.message)
    );
};

class HttpError extends Error {
    constructor(readonly status: number, message?: string) {
        super(message);
        this.name = "HttpError";
    }
}
export class HttpBadGatewayError extends HttpError {
    constructor(message?: string) {
        super(502, message);
        this.name = "HttpBadGatewayError";
    }
}

export class WrongBackendError extends HttpError {
    readonly replayBackendCountry: Country;

    constructor(
        readonly status: number,
        readonly replayBackend: string,
        message?: string
    ) {
        super(status, message);

        const replayBackendCountry = COUNTRIES[replayBackend.toUpperCase()];

        this.name = "WrongBackendError";
        this.replayBackend = replayBackend;
        this.replayBackendCountry = replayBackendCountry;
        this.message = i18n.t("error--wrong-backend", {
            country: replayBackendCountry.name,
        });
    }
}

export class NetworkError extends Error {
    constructor(message?: string) {
        super(message || i18n.t("error--network"));
        this.name = "NetworkError";
    }
}

const makeFetchQuery =
    (country: string | null = null) =>
    (request: RequestParameters, variables: Variables) => {
        const gqlTransaction = Sentry.startTransaction({
            op: `graphql.${request.operationKind}`,
            name: `graphql.${request.name}`,
        });

        const decimalSpanId = BigInt(
            `0x${gqlTransaction?.spanId ?? 0}`
        ).toString(10);

        const traceId = gqlTransaction?.traceId ?? 0;

        gqlTransaction.transaction?.setTag("transaction", gqlTransaction?.name);
        gqlTransaction.transaction?.setTag(
            "graphql.operation.name",
            request.name
        );
        gqlTransaction.transaction?.setTag("trace_id", traceId);

        const countryBackend = IS_PROD ? `${country}` : "";

        Sentry.addBreadcrumb({
            type: "info",
            category: "graphql",
            level: "info",
            message: `Executing GraphQL ${request.operationKind} '${request.name}'`,
        });

        return fetch(
            buildUrlString(
                WAVE_ROOT_URL,
                countryBackend,
                "/a/frontapp_graphql"
            ),
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Wave-Client-Name": "SUPPORT_WEB_APP",
                    "X-Cloud-Trace-Context": `${traceId}/${decimalSpanId};o=${
                        gqlTransaction?.sampled ? 1 : 0
                    }`,
                    // This header informs Google IAP that a request was made from javascript
                    // With this set the load balancer will return a 401 response if the
                    // user needs to re-authenticate rather than a 302 redirect.
                    "x-requested-with": "XMLHttpRequest",
                },
                body: JSON.stringify({
                    query: request.text,
                    operationName: request.name,
                    variables,
                    country,
                }),
            }
        )
            .then(
                (response) => {
                    gqlTransaction.setHttpStatus(response.status);

                    if (response.ok) {
                        Sentry.addBreadcrumb({
                            type: "info",
                            category: "graphql",
                            level: "info",
                            message: `GraphQL ${request.operationKind} '${request.name}' successful`,
                        });
                        return response.json();
                    } else {
                        return response.text().then((responseText: string) => {
                            Sentry.addBreadcrumb({
                                type: "info",
                                category: "graphql",
                                level: "error",
                                message: `GraphQL ${request.operationKind} '${request.name}' failed`,
                                data: {
                                    httpStatus: response.status,
                                    responseText,
                                },
                            });
                            if (response.status == 502) {
                                return Promise.reject(
                                    new HttpBadGatewayError(
                                        i18n.t("error--timeout")
                                    )
                                );
                            }
                            // TODO (alexy) Handle 401 responses from IAP and indicate to user
                            // that they should refresh the page.
                            // Or maybe we can use a popup window to refresh the login?

                            // if this is the new country-split wrong backend error,
                            // throw our WrongBackendError
                            const replayBackend =
                                response.headers.get("replay-backend");
                            if (response.status === 409 && replayBackend) {
                                return Promise.reject(
                                    new WrongBackendError(
                                        response.status,
                                        replayBackend
                                    )
                                );
                            }

                            return Promise.reject(
                                new HttpError(
                                    response.status,
                                    i18n.t("error--server")
                                )
                            );
                        });
                    }
                },
                (error: Error) => {
                    return Promise.reject(
                        isNetworkError(error)
                            ? new NetworkError(error.message)
                            : error
                    );
                }
            )
            .finally(() => gqlTransaction.finish());
    };

export const makeEnvironment = (country: string | null = null) =>
    new RelayEnvironment({
        network: Network.create(makeFetchQuery(country)),
        store: new Store(new RecordSource()),
    });

export function mutate<Mutation extends OperationType>(
    relayEnvironment: ReactRelayEnvironment,
    mutation: GraphQLTaggedNode,
    variables: Mutation["variables"]
): Promise<[Mutation["response"], readonly PayloadError[] | null | undefined]> {
    return new Promise((resolve, reject) => {
        commitMutation(relayEnvironment, {
            mutation,
            variables,
            onError: reject,
            onCompleted: (response, payloadErrors) =>
                resolve([response, payloadErrors]),
        });
    });
}

export function asyncRefetch(
    relay: RelayRefetchProp,
    refetchVariables: Variables,
    renderVariables: Variables
): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        relay.refetch(refetchVariables, renderVariables, (error) => {
            error ? reject(error) : resolve();
        });
    });
}
