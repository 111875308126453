import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";

import { FieldProps } from "@/types/FormFieldProps";

import { FormContext } from "./Form";
import { FormGroup } from "./FormGroup";

/**
 * Struct for the typeahead item.
 *
 * `value` is the internal option value
 * `displayName` is the text displayed in the option
 * `customOption` is only present on custom options that can be created if allowNew is enabled on the Typeahead
 */
export type TypeaheadItem = {
    value: string | number;
    displayName: string;
    customOption?: boolean;
};
export type TypeaheadItems = TypeaheadItem[];

type UseTypeaheadFieldResult = {
    typeaheadItem: TypeaheadItem | null;
    setTypeaheadItem: (item: TypeaheadItem) => void;
};

export type TypeaheadFieldProps = FieldProps<string | number> &
    UseTypeaheadFieldResult & {
        values: TypeaheadItems;
        placeholder: string;
        allowNew: boolean;
        addOptionOther?: boolean;
    };

export const useTypeaheadField = () => {
    const [typeaheadItem, setTypeaheadItem] = useState<TypeaheadItem | null>(
        null
    );
    return useMemo(() => {
        return { typeaheadItem, setTypeaheadItem };
    }, [typeaheadItem, setTypeaheadItem]);
};

export const TypeaheadField = (props: TypeaheadFieldProps) => {
    const {
        label,
        setTypeaheadItem,
        name,
        validations,
        values,
        placeholder,
        allowNew,
        addOptionOther,
    } = props;
    const { t } = useTranslation();
    const handleChange = useCallback(
        (selected: TypeaheadItem[]) => {
            const s = { ...selected[0] };
            if (s.customOption) {
                s.value = s.displayName;
            }
            setTypeaheadItem(s);
        },
        [setTypeaheadItem]
    );

    const context = useContext(FormContext);

    // TODO (SUP-508) show option other always
    useEffect(() => {
        if (addOptionOther) {
            if (!values.find((v) => v.value === "other")) {
                values.unshift({ value: "other", displayName: t("Other") });
            }
        }
    }, [addOptionOther, t, values]);

    return (
        <FormGroup label={label} name={name} validations={validations}>
            <InputGroup className="w-full">
                <Typeahead
                    id={"typeahead"}
                    labelKey={"displayName"}
                    onChange={(selected) => handleChange(selected)}
                    options={values}
                    placeholder={placeholder}
                    disabled={context.isDisabled}
                    allowNew={allowNew}
                />
            </InputGroup>
        </FormGroup>
    );
};
