import md5 from "md5";

import {
    FLEX_MESSAGE_ACTION_SUPPORT_INIT,
    FLEX_MESSAGE_TYPE,
    FLEX_MESSAGE_TYPE_KEY,
} from "@/types/flex-comm-layer";
import type {
    FlexMessageData,
    SupportMessageData,
} from "@/types/flex-comm-layer";

type Listener = (message: FlexMessageData) => void;
type RegisteredListener = {
    unregister: () => void;
};

export class Flex {
    private static url = "";
    private static listeners: Map<string, Listener> = new Map();
    private static recentlySentMessages: Set<string> = new Set();

    private static isInitialized = false;

    static get isEmbedded() {
        return window.self !== window.top;
    }

    /**
     * Initialises Flex:
     * - set up the Flex URL
     * - toggles the embedded flag
     * - sends a message to Flex
     * - adds an event listener for messages from Flex
     */
    static init() {
        if (this.isInitialized) {
            return;
        }

        this.url =
            process.env.NODE_ENV !== "production"
                ? "http://localhost:3000"
                : "https://flex.twilio.com";

        // We need to send a message to Flex so it can set up the source and origin
        // to send messages back to the support app.
        this.send({
            action: FLEX_MESSAGE_ACTION_SUPPORT_INIT,
            hello: "flex",
            tabId: new URLSearchParams(window.location.search).get("id"),
        });

        window.addEventListener("message", (event) => {
            if (event.origin !== this.url) {
                return;
            }

            // Other tools might be sending messages (like `webpack` or
            // `react-dev-tools`), as such we manually set `type: "flex"` when
            // sending messages between Flex and the support app.
            //
            // We strip the type from the `message` we pass to the listeners.
            const { [FLEX_MESSAGE_TYPE_KEY]: type, ...message } = event.data;

            if (type !== FLEX_MESSAGE_TYPE) {
                return;
            }

            this.listeners.forEach((listener) => listener(message));
        });

        this.isInitialized = true;
    }

    /**
     * Sends a message to Flex with a specified payload
     *
     * @param data Any payload to be sent to Flex
     * @returns void
     */
    static send(data: SupportMessageData) {
        if (!this.isEmbedded) {
            // eslint-disable-next-line no-console
            console.warn(
                "Cannot send messages to Flex as the support app is not currently embedded"
            );
            return;
        }

        // To detect multiple duplicated messages, we store a hash of the data in a Set
        // and check against it.
        const hash = md5(JSON.stringify(data));
        if (this.recentlySentMessages.has(hash)) {
            // eslint-disable-next-line no-console
            console.error(
                "Multiple duplicate messages to Flex detected.",
                data
            );

            // TODO(antonio): report these issues to Sentry!
            return;
        }

        // Hashes are removed from the Set after 200ms.
        this.recentlySentMessages.add(hash);
        setTimeout(() => {
            this.recentlySentMessages.delete(hash);
        }, 200);

        // Other tools might be sending messages (like `webpack` or
        // `react-dev-tools`), as such we manually set `type: "flex"` when
        // sending messages between Flex and the support app.
        window.parent.postMessage(
            {
                ...data,
                // We add the message type information after destructuring the data
                // so that this takes precedence.
                [FLEX_MESSAGE_TYPE_KEY]: FLEX_MESSAGE_TYPE,
            },
            this.url
        );
    }

    /**
     * Registers a listener function that will be called with any receiving
     * messages from Flex.
     *
     * @param listener A function that will be called when receiving messages
     * from Flex
     * @return Object with `unregister` method to remove the listener
     */
    static register(listener: Listener): RegisteredListener {
        const key = md5(listener.toString());
        const registeredListener: RegisteredListener = {
            unregister: () => {
                this.listeners.delete(key);
            },
        };

        if (this.listeners.has(key)) {
            // eslint-disable-next-line no-console
            console.error(
                "Multiple duplicate listeners registered to Flex detected.",
                listener
            );
        } else {
            this.listeners.set(key, listener);
        }

        return registeredListener;
    }

    /**
     * Resets the Flex communication client
     */
    static reset() {
        this.isInitialized = false;
        this.url = "";
        this.listeners = new Map();
        this.recentlySentMessages = new Set();
    }
}
