import { useTranslation } from "react-i18next";

import {
    ActionButton,
    VOIPVerificationLevel,
} from "../../components/ActionButton";

import { PurchaseAirtimeMutator } from "@/pages/mobile.[mobile].personal/PurchaseAirtime";

export const PurchaseAirtimeAction = (props: { mobile: string }) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("purchase-airtime--action")}
            requiredPermissions={["supportapp_manual_airtime"]}
            VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
        >
            <PurchaseAirtimeMutator mobile={props.mobile} />
        </ActionButton>
    );
};
