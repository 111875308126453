import _ from "lodash";
import { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

// I initially thought I could get similar functionality from `useMemo`, but
// https://reactjs.org/docs/hooks-reference.html#usememo mentiones that it's only meant
// to be a performance optimization, not a semantic guarantee.
export function useIdempotencyKey(deps: ReadonlyArray<any>) {
    const depsRef = useRef(deps);
    const idempotencyKeyRef = useRef(uuidv4());
    if (!_.isEqual(deps, depsRef.current)) {
        depsRef.current = deps;
        idempotencyKeyRef.current = uuidv4();
    }
    return idempotencyKeyRef.current;
}
