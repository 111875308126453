/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RemittanceTransferRefundMutationVariables = {
    remittanceTransferId: string;
};
export type RemittanceTransferRefundMutationResponse = {
    readonly remittanceTransferRefund: {
        readonly isRefunded: boolean | null;
        readonly transfer: {
            readonly senderName: string | null;
            readonly country: string | null;
            readonly senderMobile: string;
            readonly receiveAmount: string;
        } | null;
    } | null;
};
export type RemittanceTransferRefundMutation = {
    readonly response: RemittanceTransferRefundMutationResponse;
    readonly variables: RemittanceTransferRefundMutationVariables;
};



/*
mutation RemittanceTransferRefundMutation(
  $remittanceTransferId: ID!
) {
  remittanceTransferRefund(remittanceTransferId: $remittanceTransferId) {
    isRefunded
    transfer {
      senderName
      country
      senderMobile
      receiveAmount
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "remittanceTransferId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "remittanceTransferId",
        "variableName": "remittanceTransferId"
      }
    ],
    "concreteType": "RefundRemittanceTransfer",
    "kind": "LinkedField",
    "name": "remittanceTransferRefund",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRefunded",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RemittanceTransfer",
        "kind": "LinkedField",
        "name": "transfer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "senderName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "senderMobile",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receiveAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemittanceTransferRefundMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemittanceTransferRefundMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "58586420af21bdc56bec9e7b3bb63fd0",
    "id": null,
    "metadata": {},
    "name": "RemittanceTransferRefundMutation",
    "operationKind": "mutation",
    "text": "mutation RemittanceTransferRefundMutation(\n  $remittanceTransferId: ID!\n) {\n  remittanceTransferRefund(remittanceTransferId: $remittanceTransferId) {\n    isRefunded\n    transfer {\n      senderName\n      country\n      senderMobile\n      receiveAmount\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '08b4c9425fae1942335fb1db2b49b49a';
export default node;
