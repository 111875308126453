import { createContext } from "react";

type NavigationContextType = {
    pagesBehind: number;
    setPagesBehind: (numPages: number) => void;
    pagesAhead: number;
    setPagesAhead: (numPages: number) => void;
    queryString: string;
};

const DefaultNavigationContext: NavigationContextType = {
    pagesBehind: 0,
    setPagesBehind: (_numPages: number) => {
        /*noop*/
    },
    pagesAhead: 0,
    setPagesAhead: (_numPages: number) => {
        /*noop*/
    },
    queryString: "",
} as const;

export const NavigationContext = createContext<NavigationContextType>(
    DefaultNavigationContext
);
