import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { CalculateLimits } from "@/pages/mobile.[mobile].personal/CalculateLimits";

import { Action } from "@/types/actions";

export const CalculateLimitsAction = (props: { mobile: string }) => {
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();
    const onShow = () => {
        trackEvent(Action.CalculateLimits);
    };

    return (
        <ActionButton
            as="menuitem"
            name={t("calculate-limits--action")}
            onShow={onShow}
            data-testid="calculate-limits"
        >
            <CalculateLimits mobile={props.mobile} />
        </ActionButton>
    );
};
