import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { SendCustomerAppLinkMutator } from "@/pages/mobile.[mobile]/SendCustomerAppLink";

export const SendCustomerAppLinkAction = (props: {
    name: string;
    mobile: string;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("send-customer-app-link--action")}
            requiredPermissions={["supportapp_send_customer_app_link"]}
        >
            <SendCustomerAppLinkMutator
                name={props.name}
                mobile={props.mobile}
            />
        </ActionButton>
    );
};
