import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { ProfileSearchField } from "../../components/ProfileSearchField";
import { TextField } from "@/components/TextField";

import { usePermissions } from "@/hooks/usePermissions";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";
import {
    Profile as IProfile,
    MerchantInfo,
    UnregisteredUser,
} from "@/types/ProfileSearcher";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";

import { MoveBalanceMutationResponse } from "./__generated__/MoveBalanceMutation.graphql";

type Profile = Exclude<Exclude<IProfile, UnregisteredUser>, MerchantInfo>;

const mutation = graphql`
    mutation MoveBalanceMutation(
        $mobileFrom: String!
        $mobileTo: String!
        $reason: String
        $canReadHistory: Boolean!
        $canReadBalance: Boolean!
    ) {
        moveBalance(
            mobileFrom: $mobileFrom
            mobileTo: $mobileTo
            reason: $reason
        ) {
            wallet {
                ...PersonalTabHeader_wallet
                    @arguments(canReadBalance: $canReadBalance)
                ...History_wallet
                    @include(if: $canReadHistory)
                    @arguments(last: 5)
            }
        }
    }
`;

type MoveBalanceInputParams = {
    name: string;
    mobile: string;
    balance: M;
};

const _MoveBalanceMutator = (
    props: RelayModalProps & MoveBalanceInputParams
) => {
    const { onHide, name, mobile, balance } = props;
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);

    const [recipient, setRecipient] = useState<Profile | null>(null);
    const [reason, setReason] = useState("");

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: MoveBalanceMutationResponse): string => {
            if (!response.moveBalance) {
                return t("move-balance--error");
            }

            return t("move-balance--success", {
                balance,
                sender: { name, mobile },
                recipient: {
                    name: recipient?.wallet?.name,
                    mobile: recipient?.mobile,
                },
            });
        },
        [t, recipient, mobile, balance, name]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.MoveBalance,
            data: {
                mobile_from: mobile,
                mobile_to: recipient?.mobile,
                balance,
            },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobileFrom: mobile,
            mobileTo: recipient?.mobile,
            reason,
            canReadHistory,
            canReadBalance,
        });
    }, [mutator, mobile, recipient, reason, canReadHistory, canReadBalance]);

    const recipientValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!recipient) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-recipient"),
                display: "none",
            });
        } else {
            if (recipient?.mobile === mobile) {
                validations.push({
                    type: "error",
                    message: t("move-balance-validation--same-account"),
                    display: "always",
                });
            } else {
                validations.push({
                    type: "info",
                    message: t("move-balance--confirm", {
                        balance,
                        sender: { name, mobile },
                        recipient: {
                            name: recipient?.wallet?.name,
                            mobile: recipient?.mobile,
                        },
                    }),
                    display: "always",
                });
            }
        }

        return validations;
    }, [t, recipient, mobile, balance, name]);

    const isValid = useMemo(() => {
        return !hasError([recipientValidations]);
    }, [recipientValidations]);

    return (
        <MutatorModal {...mutator} title={t("move-balance--title", { name })}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-move")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <ProfileSearchField
                    allowWallets
                    allowUnregistered={false}
                    allowMerchants={false}
                    allowAgents={false}
                    allowTerminated
                    autoFocus
                    label={t("label-recipient")}
                    name="recipient"
                    validations={recipientValidations}
                    onChange={(profile) => {
                        if (profile) setRecipient(profile as Profile);
                    }}
                />
                <TextField
                    label={t("label-reason")}
                    name="reason"
                    validations={[]}
                    value={reason}
                    setValue={setReason}
                />
            </Form>
        </MutatorModal>
    );
};

export const MoveBalanceMutator = createFragmentContainer(
    _MoveBalanceMutator,
    {}
);
