import * as React from "react";

interface Props {
    onToggle: () => void;
    subTxCount: number;
    children: React.ReactNode;
    isOpen: boolean;
}

export const HistoryEntryAccordionSummaryContainer = (props: Props) => (
    <div className={"history-entry-accordion-summary"}>
        <button
            className={
                "history-entry-accordion-toggle-button text-white " +
                (props.isOpen ? "open" : "closed")
            }
            onClick={props.onToggle}
        >
            <span>{props.subTxCount}</span>
            <Chevron />
        </button>
        <div>{props.children}</div>
    </div>
);

const Chevron = () => (
    <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3991_19698)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.16278 9.21073C1.86615 9.51026 1.40745 9.51026 1.11082 9.21073C0.781157 8.87783 0.781157 8.31022 1.11082 7.97732L5.43029 3.61557C5.72692 3.31604 6.18562 3.31604 6.48225 3.61557C6.81191 3.94847 6.81191 4.51608 6.48225 4.84898L2.16278 9.21073Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8893 7.97705C11.2189 8.30994 11.2189 8.87756 10.8893 9.21045C10.5927 9.50998 10.1339 9.50998 9.83732 9.21045L5.51784 4.84869C5.18818 4.5158 5.18818 3.94818 5.51784 3.61529C5.81447 3.31576 6.27318 3.31576 6.5698 3.61529L10.8893 7.97705Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_3991_19698">
                <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(12 12.5) rotate(-180)"
                />
            </clipPath>
        </defs>
    </svg>
);
