import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { BlockFromAgentDepositsMutator } from "@/pages/mobile.[mobile].personal/BlockFromAgentDeposits";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const BlockFromAgentDepositsAction = (props: {
    user: Exclude<PersonalTabHeader_wallet["user"], null>;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton as="menuitem" name={t("block-agent-deposits--action")}>
            <BlockFromAgentDepositsMutator user={props.user} />
        </ActionButton>
    );
};
