import {
    faChevronDown,
    faChevronRight,
    faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

dayjs.extend(relativeTime);
/*
NOTE(sophie):
There is also a more general Banner component now (2022-11-03) which duplicates some of the
functionality. Ideally, in the future, the BlockBanner would use the more universal Banner
internally (and the functionality would be narrowed down better).
*/
type BlockBannerProps = {
    block: {
        whenBlocked: string;
        whenExpires: string | null;
        blockReason: string;
        blockedBy: string;
    } | null;
    title: string;
    icon: ReactNode;
    blockType: "full" | "partial" | "device";
    actions: Array<ReactNode>;
    details?: { [key: string]: string | ReactNode };
};
export const BlockBanner = (props: BlockBannerProps) => {
    const { blockType, block, title, icon, actions, details } = props;
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();

    const toggleExpanded = () => setExpanded(!expanded);

    const [blockReason, setBlockReason] = useState<JSX.Element | undefined>(
        undefined
    );
    useEffect(() => {
        if (!block) {
            return setBlockReason(undefined);
        }
        const match = block.blockReason.match(/(ATX|T)_.{10}/);
        if (match) {
            const transactionId = match[0];
            const [beginning, ...rest] = block.blockReason.split(transactionId);
            return setBlockReason(
                <>
                    {beginning}
                    <a
                        href={`?entry=${transactionId}`}
                        className="text-blue-600"
                    >
                        {transactionId}
                    </a>
                    {rest.join(transactionId)}
                </>
            );
        }
        setBlockReason(<>{block.blockReason}</>);
    }, [block]);

    return (
        <div
            /* Note (sebastian):
            If there's no block, we only hide this BlockBanner (rather than return null).
            Why? When a user removes a block via an unblock mutation, we want the modal to stay
            visible until the user dismisses it.

            But the modal is inside of this component: the BlockBanner contains the actions array,
            which contains the buttons, which contain the Mutator components, which contain the modals.

            Therefore we just hide, rather than not show.
            */
            className={`min-w-[60ch] w-full relative p-2 cursor-pointer text-left inline-flex m-1 rounded transition-all ${
                blockType === "full" ? "text-white bg-red-800" : ""
            } ${blockType === "partial" ? "text-black bg-amber-500" : ""} ${
                blockType === "device" ? "text-black bg-orange-500" : ""
            } ${expanded ? "expanded" : "collapsed"} ${
                !block ? "hidden" : ""
            } `}
            onClick={toggleExpanded}
        >
            <div className={`pl-2`}>
                {expanded ? (
                    <FontAwesomeIcon className="w-4" icon={faChevronDown} />
                ) : (
                    <FontAwesomeIcon className="w-4" icon={faChevronRight} />
                )}
            </div>
            <div
                className={
                    "flex-grow-0 flex-shrink-0 opacity-70 px-2 transition-all"
                }
            >
                {icon}
            </div>
            <div className={"flex-grow-1 flex-shrink-0"}>
                {expanded && (
                    <div className={"text-sm"}>
                        {dayjs(block?.whenBlocked).fromNow()}{" "}
                        <span className="opacity-50">by</span>{" "}
                        {block?.blockedBy}
                    </div>
                )}
                <div className={`font-bold`}>
                    {title}
                    {block?.whenExpires &&
                        t("block-notice--when-expires", {
                            date: dayjs(block?.whenExpires).fromNow(),
                        })}
                </div>
                {expanded && (
                    <div
                        className="text-sm cursor-text"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {blockReason && (
                            <>
                                <span>
                                    <span className="opacity-50">Reason:</span>{" "}
                                    {blockReason}
                                    <a
                                        href={`https://translate.google.com/?sl=en&tl=fr&text=${encodeURIComponent(
                                            block?.blockReason || ""
                                        )}&op=translate`}
                                        rel={"noreferrer"}
                                        target={"_blank"}
                                        className="m-2 align-middle opacity-70 text-base text-blue-600 hover:opacity-90 hover:text-black"
                                        title={t("google-translate")}
                                    >
                                        <FontAwesomeIcon icon={faLanguage} />
                                    </a>
                                </span>
                                <br />
                            </>
                        )}
                        <span>
                            <span className="opacity-50">Since:</span>{" "}
                            {dayjs(block?.whenBlocked).format(
                                "MMM D, YYYY HH:mm"
                            )}
                        </span>
                        <br />
                        {block?.whenExpires && (
                            <>
                                <span>
                                    <span className="opacity-50">Until:</span>{" "}
                                    {dayjs(block?.whenExpires).format(
                                        "MMM D, YYYY HH:mm"
                                    )}
                                </span>
                                <br />
                            </>
                        )}
                        {Object.entries(details || {})
                            .filter(([_k, v]) => !!v)
                            .map(([k, v], di) => (
                                <Fragment key={di}>
                                    <span>
                                        <span className="opacity-50">{k}:</span>{" "}
                                        {v}
                                    </span>
                                    <br />
                                </Fragment>
                            ))}
                    </div>
                )}
            </div>
            <div
                className={`flex items-end pl-4 self-end gap-2 banner-badge-actions`}
                onClick={(e) => e.stopPropagation()}
            >
                {actions.map((a, ai) => (
                    <Fragment key={ai}>{a}</Fragment>
                ))}
            </div>
        </div>
    );
};
