/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ForgetQrMutationVariables = {
    userId: string;
};
export type ForgetQrMutationResponse = {
    readonly forgetQr: {
        readonly qrText: string | null;
    } | null;
};
export type ForgetQrMutation = {
    readonly response: ForgetQrMutationResponse;
    readonly variables: ForgetQrMutationVariables;
};



/*
mutation ForgetQrMutation(
  $userId: ID!
) {
  forgetQr(userId: $userId) {
    qrText
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "ForgetQr",
    "kind": "LinkedField",
    "name": "forgetQr",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qrText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgetQrMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgetQrMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "77c737366bf89c7efe96f4837ceacb43",
    "id": null,
    "metadata": {},
    "name": "ForgetQrMutation",
    "operationKind": "mutation",
    "text": "mutation ForgetQrMutation(\n  $userId: ID!\n) {\n  forgetQr(userId: $userId) {\n    qrText\n  }\n}\n"
  }
};
})();
(node as any).hash = '5b5a02f21e903d97a48cb1ee636b5c15';
export default node;
