import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { CancelAllAtxMutator } from "@/pages/mobile.[mobile].personal/CancelAllAtx";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const CancellAllAtxAction = (props: {
    wallet: PersonalTabHeader_wallet;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("cancel-all-atx--action")}
            requiredPermissions={["supportapp_cancel_all_atx"]}
        >
            <CancelAllAtxMutator wallet={props.wallet} />
        </ActionButton>
    );
};
