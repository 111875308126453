import * as React from "react";

const historyEntryClass = (isFocussed: boolean) =>
    `group relative py-1 pr-12 pl-2 border-b border-gray-200 ${
        isFocussed && "bg-yellow-100"
    }`;

interface Props {
    children: React.ReactNode;
    id: string;
    isFocussed: boolean;
}

export const HistoryEntryContainer = ({ children, id, isFocussed }: Props) => (
    <div
        className={historyEntryClass(isFocussed)}
        id={`tx_div${id}`}
        data-testid="tx-cell"
    >
        <div data-testid="tx-info">{children}</div>
        <div className="clearfix" />
    </div>
);
