/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SendWithdrawalCodeMutationVariables = {
    mobile: string;
    withdrawalCode: string;
};
export type SendWithdrawalCodeMutationResponse = {
    readonly sendWithdrawalCode: {
        readonly result: string | null;
    } | null;
};
export type SendWithdrawalCodeMutation = {
    readonly response: SendWithdrawalCodeMutationResponse;
    readonly variables: SendWithdrawalCodeMutationVariables;
};



/*
mutation SendWithdrawalCodeMutation(
  $mobile: String!
  $withdrawalCode: String!
) {
  sendWithdrawalCode(mobile: $mobile, withdrawalCode: $withdrawalCode) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withdrawalCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "withdrawalCode",
        "variableName": "withdrawalCode"
      }
    ],
    "concreteType": "SendWithdrawalCode",
    "kind": "LinkedField",
    "name": "sendWithdrawalCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendWithdrawalCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendWithdrawalCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7c6384dc1c7489c613d46288ed51258a",
    "id": null,
    "metadata": {},
    "name": "SendWithdrawalCodeMutation",
    "operationKind": "mutation",
    "text": "mutation SendWithdrawalCodeMutation(\n  $mobile: String!\n  $withdrawalCode: String!\n) {\n  sendWithdrawalCode(mobile: $mobile, withdrawalCode: $withdrawalCode) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'eb10fb0950b44c5800e70969bbc404fb';
export default node;
