import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Amount } from "@/components/Amount";
import { MerchantProfileLink } from "@/components/MerchantProfileLink";
import { ScamInvestigationFields } from "@/components/ScamInvestigationFields";
import { Timestamp } from "@/components/Timestamp";

import { HistoryEntryProps } from "@/pages/mobile.[mobile]/HistoryEntry";

import { M } from "@/utils/currency";

import { FailedMerchantTransferEntry_tx } from "./__generated__/FailedMerchantTransferEntry_tx.graphql";

function _FailedMerchantTransferEntry(
    props: {
        tx: FailedMerchantTransferEntry_tx;
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx } = props;

    return (
        <div
            className={`group relative py-1 pr-12 pl-2 border-b border-gray-200`}
            id={`tx_div${tx.id}`}
            data-testid="tx-cell"
        >
            <div data-testid="tx-info">
                <div
                    className={`float-right italic opacity-50`}
                    data-testid="tx-amount"
                >
                    <Amount amount={M.fromSerialized(tx.sendAmount).negate()} />
                </div>
                {t("purchase-entry--title")}{" "}
                {tx.merchantId ? (
                    <MerchantProfileLink
                        name={tx.merchantName}
                        merchantId={tx.merchantId}
                        opaqueId={tx.id}
                    />
                ) : (
                    tx.merchantName
                )}{" "}
                {t("failed-merchant-transfer--cancelled")}
                <div>{tx.id}</div>
                <i className="opacity-50">
                    <Timestamp value={new Date(tx.whenEntered)} />
                </i>
                {tx.scamInvestigationFields && (
                    <ScamInvestigationFields
                        scamInvestigationFields={tx.scamInvestigationFields}
                    />
                )}
            </div>
        </div>
    );
}

export default createFragmentContainer(_FailedMerchantTransferEntry, {
    tx: graphql`
        fragment FailedMerchantTransferEntry_tx on FailedMerchantTransferEntry {
            id
            whenEntered
            sendAmount
            receiveAmount
            isCancelled
            merchantId
            merchantName
            scamInvestigationFields {
                deviceId
                deviceModel
                deviceName
                userInterface
            }
        }
    `,
});
