import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Analytics } from "@/external/analytics";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import i18n, { languages } from "@/locales/i18n";

type LanguageButtonDropdownItemProps = {
    lang: string;
    setLanguage: Dispatch<SetStateAction<string | null>>;
};

export const _LanguageButtonDropdownItem = ({
    lang,
    setLanguage,
}: LanguageButtonDropdownItemProps) => {
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();

    function handleChangeLanguage() {
        Analytics.addContext({ language: lang });
        trackEvent("change language");
        setLanguage(lang);
        window.localStorage.setItem("lng", lang);
        i18n.changeLanguage(lang);
    }

    return (
        <Dropdown.Item
            onClick={handleChangeLanguage}
            data-testid="change-language"
        >
            {t(`lang-${lang}`)}
        </Dropdown.Item>
    );
};

export const LanguageButton = () => {
    const lng = window.localStorage.getItem("lng");
    const [language, setLanguage] = useState(lng);

    useEffect(() => {
        Analytics.addContext({ language });
    }, [language]);

    return (
        <DropdownButton
            title={language?.toUpperCase()}
            className="ml-2"
            variant="outline-info"
        >
            {languages.map((lang) => (
                <_LanguageButtonDropdownItem
                    key={lang}
                    lang={lang}
                    setLanguage={setLanguage}
                />
            ))}
        </DropdownButton>
    );
};
