import { useTranslation } from "react-i18next";

import { NavigationLink } from "./NavigationLink";

export const ProfileLink = ({
    name,
    mobile,
    opaqueId,
}: {
    mobile: string;
    name: string;
    opaqueId?: string;
}) => {
    const { t } = useTranslation();

    if (!mobile) {
        return <>{name || t("profile-link--unknown")}</>;
    }
    const text = name && !mobile.includes(name) ? `${name}: ${mobile}` : mobile;
    return (
        <NavigationLink mobile={mobile} opaqueId={opaqueId}>
            {text}
        </NavigationLink>
    );
};
