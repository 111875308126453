import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { M } from "@/utils/currency";

import { PaymentCardTransferEntry_tx } from "./__generated__/PaymentCardTransferEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ReportCustomerScamMutator } from "../mobile.[mobile]/ReportCustomerScam";
import { ReportPaymentCardTransferProblemMutator } from "./ReportPaymentCardTransferProblem";

const ReportPaymentCardTransferButton = ({
    tx,
}: {
    tx: PaymentCardTransferEntry_tx;
}) => {
    const { t } = useTranslation();
    return (
        <HistoryActionButton
            name={t("report-payment-card-transfer--action")}
            disabled={
                !usePermissions(
                    "supportapp_report_payment_card_transfer_problem"
                )
            }
        >
            <ReportPaymentCardTransferProblemMutator
                transactionId={tx.transferId}
            />
        </HistoryActionButton>
    );
};

const ReportCustomerScamButton = ({
    tx,
}: {
    tx: PaymentCardTransferEntry_tx;
}) => {
    const { t } = useTranslation();
    const canReportScam = usePermissions("supportapp_report_customer_scam");
    const canReportScamWithDetails = usePermissions(
        "supportapp_report_customer_scam_with_details"
    );
    return (
        <HistoryActionButton
            name={t("report-customer-scam--action")}
            disabled={!canReportScam}
        >
            <ReportCustomerScamMutator
                transactionId={tx.transferId}
                mobile={tx.mobile}
                amountStolen={M.fromSerialized(tx.amount).negate()}
                hideAdditionalInfoFields={!canReportScamWithDetails}
            />
        </HistoryActionButton>
    );
};

function _PaymentCardTransferEntry(
    props: { tx: PaymentCardTransferEntry_tx } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx } = props;

    const info = <TxInfo>{tx.description}</TxInfo>;
    const buttons = (
        <>
            <ReportCustomerScamButton tx={tx} />
            <ReportPaymentCardTransferButton tx={tx} />
        </>
    );

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <>
                    <TxTitle>
                        {t("payment-card-transfer-entry--payment")} {tx.summary}
                    </TxTitle>
                    {info}
                </>
            }
            buttons={buttons}
        />
    );
}

export default createFragmentContainer(_PaymentCardTransferEntry, {
    tx: graphql`
        fragment PaymentCardTransferEntry_tx on PaymentCardTransferEntry {
            amount
            summary
            description
            transferId
            mobile
            baseReceiptFields {
                formatType
                label
                value
            }
            ...HistoryEntry_entry
        }
    `,
});
