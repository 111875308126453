import { Button as BSButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const LoadingIcon = (props: { textColor?: "dark" | "light" }) => {
    const { t } = useTranslation();
    const textColor = props.textColor || "dark";
    return (
        <div className="align-middle">
            <div
                className={`spinner-border spinner-border-sm text-${textColor}`}
                role="status"
            />
            <span className="sr-only">{t("loading")}</span>
        </div>
    );
};

export const LoadingIndicator = () => {
    const { t } = useTranslation();
    return (
        <div className="my-2 mx-auto w-fit">
            <BSButton disabled>
                <LoadingIcon textColor="light" /> {t("loading")}
            </BSButton>
        </div>
    );
};
