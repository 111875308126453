export const CAN_READ_BALANCE = "supportapp_show_balance";
export const CAN_READ_HISTORY = "supportapp_show_history";
export const CAN_SEE_GENERAL_LIVE_METRICS =
    "supportapp_show_general_live_metrics";
export const CAN_REFUND_P2P_TX = "supportapp_refund";
export const CAN_REFUND_MERCHANT_SALE = "supportapp_refund_merchant_sale";
export const CAN_REFUND_BULK_TRANSFER = "supportapp_refund_bulk_transfer";
export const CAN_DELETE_NOTES = "supportapp_delete_notes";
export const CAN_EDIT_NOTES = "supportapp_edit_notes";
export const CAN_SEE_SCAM_INVESTIGATIONS_INFO =
    "supportapp_show_scam_investigation_info";
export const CAN_FREEZE_TRANSACTION = "supportapp_freeze_transaction";
export const CAN_UNFREEZE_TRANSACTION = "supportapp_unfreeze_transaction";
export const CAN_REVERSE_FROZEN_TRANSACTION =
    "supportapp_reverse_frozen_transaction";
export const CAN_PERFORM_RISKY_PIN_RESET = "supportapp_risky_pin_reset";
