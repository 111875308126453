/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RefundPurchaseEntry_transfer = {
    readonly id: string;
    readonly transferId: string;
    readonly amount: string;
    readonly merchantName: string;
    readonly " $refType": "RefundPurchaseEntry_transfer";
};
export type RefundPurchaseEntry_transfer$data = RefundPurchaseEntry_transfer;
export type RefundPurchaseEntry_transfer$key = {
    readonly " $data"?: RefundPurchaseEntry_transfer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RefundPurchaseEntry_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefundPurchaseEntry_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "merchantName",
      "storageKey": null
    }
  ],
  "type": "PurchaseEntry",
  "abstractKey": null
};
(node as any).hash = '407afeb86993d71747de12d01d02c805';
export default node;
