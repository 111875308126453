import * as React from "react";
import { ReactNode } from "react";
import { ButtonGroup, DropdownButton } from "react-bootstrap";
import { createFragmentContainer, graphql } from "react-relay";

import { Amount } from "@/components/Amount";
import { ScamInvestigationFields } from "@/components/ScamInvestigationFields";
import { Timestamp } from "@/components/Timestamp";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { M } from "@/utils/currency";

export type TxProps = {
    // provided by React Relay
    entry: { [key: string]: any };

    // if title is provided, use that as the first line of the entry.
    title?: React.ReactNode;
    // otherwise, if titleCallable is provided, we will call it with props.entry and use that as the first line of the entry
    // Note: titleCallable is not used if title is null.
    // (We need this in case you want to use items from the HistoryEntry fragment in the title, like the description,
    // because of Relay masking)
    titleCallable?: (arg0: { [key: string]: any }) => React.ReactNode;

    buttons?: React.ReactNode;
};

/**
 * Each item in history (agent/personal) ultimately boils down to a HistoryEntry.
 *
 * In props, specify:
 *  - the actual entry itself, which will become a HistoryEntry_entry fragment as part of Fragment Container
 *  - title: a node (or several nodes) to be inserted into the title section of the row
 *  - buttons: items in the dropdown menu for this history item. Leave this null if you don't want a dropdown menu.
 */
function _HistoryEntry(props: TxProps & HistoryEntryProps) {
    // Called by all the individual history row renderers (UserAtxEntry, etc.)
    let title: ReactNode = null;
    if (props.title) {
        title = props.title;
    } else if (props.titleCallable) {
        title = props.titleCallable(props.entry);
    }

    const amount = M.fromSerialized(props.entry.amount);
    const balance = M.fromSerialized(props.entry.balance);
    const timestamp = new Date(props.entry.whenEntered);

    // In some cases, we might pass a Fragment of `null` children as the
    // `buttons` props.
    //
    // Instead of displaying an empty dropdown, we don't display any button
    // at all.
    //
    let buttonIsEmptyFragment = false;
    if (
        props.buttons !== null &&
        typeof props.buttons === "object" &&
        Object.prototype.hasOwnProperty.call(props.buttons, "props")
    ) {
        // We can narrow the type of `buttons` to be a ReactElement as it
        // is an object and has a `props` property.
        const buttons = props.buttons as React.ReactElement;

        // `props.children` can be an Array, a single element, or null
        if (Array.isArray(buttons.props.children)) {
            buttonIsEmptyFragment = buttons.props.children.every(
                (child: ReactNode) => child === null
            );
        } else {
            // `buttons` can be a component without `children`, in which
            // case this value will be `undefined` and the following check
            // will yield `false`.
            buttonIsEmptyFragment = buttons.props.children === null;
        }
    }

    const buttons = props.buttons && !buttonIsEmptyFragment && (
        <div className="absolute top-3 right-2">
            <ButtonGroup size="sm">
                <DropdownButton
                    variant="outline-info"
                    className="transaction-row-button float-right"
                    title=""
                    id={`dropdown-transfer-${props.entry.id}`}
                    data-testid="transfer-actions-dropdown"
                >
                    {props.buttons}
                </DropdownButton>
            </ButtonGroup>
        </div>
    );

    const activeBlock = props.activeDeviceBlocks.find(
        ({ deviceId }) =>
            deviceId === props.entry.scamInvestigationFields?.deviceId
    );

    return (
        <div
            className={`group relative py-1 ${
                props.hideButtons ? "pr-2" : "pr-12"
            } pl-2 border-b border-gray-200`}
            id={`tx_div${props.entry.id}`}
            data-testid="tx-cell"
        >
            <div data-testid="tx-info">
                {/* Row 1: Title + Amount */}
                <div
                    className={`float-right italic ${
                        props.entry.isPending ? "opacity-50" : ""
                    }`}
                    data-testid="tx-amount"
                >
                    <Amount amount={amount} />
                </div>
                {title}

                {/* Row 2: Date + Balance */}
                <div
                    className={`hidden group-hover:block group-hover:float-right group-hover:text-gray-400 ${
                        props.entry.isPending ? "opacity-50" : ""
                    }`}
                    data-testid="tx-balance"
                >
                    bal: <Amount amount={balance} />
                </div>
                <i className={`${props.entry.isPending ? "opacity-50" : ""}`}>
                    <Timestamp value={timestamp} />
                </i>
                {props.entry.scamInvestigationFields && (
                    <ScamInvestigationFields
                        scamInvestigationFields={
                            props.entry.scamInvestigationFields
                        }
                        opaqueId={
                            props.entry.transferId ||
                            props.entry.agentTransactionId
                        }
                        activeBlock={activeBlock}
                    />
                )}
                {props.hideButtons ? null : buttons}
            </div>
            <div className="clearfix" />
        </div>
    );
}

const HistoryEntry = createFragmentContainer(_HistoryEntry, {
    entry: graphql`
        fragment HistoryEntry_entry on HistoryEntry {
            id
            whenEntered
            ... on HistoryEntry {
                amount
                balance
                summary
                isPending
                isCancelled
            }
            ... on SupportHistoryEntry {
                scamInvestigationFields {
                    deviceId
                    deviceModel
                    deviceName
                    userInterface
                    agentMobile
                }
            }
            ... on AgentTransactionEntry {
                agentTransactionId
            }
            ... on TransferSentEntry {
                transferId
            }
        }
    `,
});
/** Helper for the top-level entries (AgentHistoryEntry, PersonalHistoryEntry) to
 * dispatch to the appropriate renderer based on the typename field of the fragment.
 *
 * Flow:
 * - The server returns us a list of things satisfying the HistoryEntry interface.
 * - We check the typename of each component. If it's in the given 'renderers'
 *   parameter then we tell that component to render it.
 * - Otherwise, we render a generic HistoryEntry.
 *
 * To add a new entry type you should:
 * - Create a new file by, e.g., copying UserAtxEntry. (Ideally, name your file after the concrete HistoryEntry type it
 *   renders; but if that's not an option because you need to render the same type of thing differently across
 *   different histories, then come up with a unique name)
 * - Import and add that name to the AgentHistoryEntry and/or PersonalHistoryEntry TX_TYPE_RENDERERS
 * - Reference your new fragment in AgentHistoryEntry and/or PersonalHistoryEntry's graphql fragment defs
 */

export type HistoryEntryProps = {
    hideButtons?: boolean;
    activeDeviceBlocks: Array<{ deviceId: string; blockReason: string }>;
    reversedTransfers: {
        [key: string]: Date;
    };
};

export function delegateHistoryEntryComponent<P>(
    typename: string,
    renderers: {
        [key: string]: React.ComponentType<{ tx: any } & P>;
    },
    props: HistoryEntryProps & { entry: { [key: string]: any } } & P
): JSX.Element {
    if (renderers[typename]) {
        // Delegate this item to the found renderer
        const Component = renderers[typename];
        return <Component tx={props.entry} {...props} typename={typename} />;
    } else {
        // We didn't find a renderer. Use a generic history entry
        return (
            <HistoryEntry
                {...props}
                entry={props.entry}
                titleCallable={(entry) => (
                    <React.Fragment>
                        <TxTitle>{entry.summary}</TxTitle>
                        <TxInfo>{typename}</TxInfo>
                    </React.Fragment>
                )}
            />
        );
    }
}

export default HistoryEntry;
