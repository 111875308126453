import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { ProfileSearchField } from "../../components/ProfileSearchField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import {
    Profile as IProfile,
    MerchantInfo,
    UnregisteredUser,
} from "@/types/ProfileSearcher";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";

import { ChangeRemittanceRecipientMutationResponse } from "./__generated__/ChangeRemittanceRecipientMutation.graphql";
import { ChangeRemittanceRecipient_transfer } from "./__generated__/ChangeRemittanceRecipient_transfer.graphql";

type Profile = Exclude<Exclude<IProfile, UnregisteredUser>, MerchantInfo>;

const mutation = graphql`
    mutation ChangeRemittanceRecipientMutation(
        $remittanceTransferId: ID!
        $newMobile: String!
    ) {
        changeRemittanceRecipient(
            remittanceTransferId: $remittanceTransferId
            newMobile: $newMobile
        ) {
            remittanceTransfer {
                opaqueId
            }
        }
    }
`;

type ChangeRemittanceRecipientInputParams = {
    transfer: ChangeRemittanceRecipient_transfer;
};

const _ChangeRemittanceRecipientMutator = (
    props: RelayModalProps & ChangeRemittanceRecipientInputParams
) => {
    const { onHide, transfer } = props;
    const amount = M.fromSerialized(transfer.amount);
    const [recipient, setRecipient] = useState<Profile | null>(null);
    const data = useMemo(() => ({ recipient }), [recipient]);

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (
            response: ChangeRemittanceRecipientMutationResponse
        ): string | null => {
            if (response) return t("change-remittance-recipient--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ChangeRemittanceRecipient,
            data: { transferId: transfer.opaqueId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            remittanceTransferId: transfer.opaqueId,
            newMobile: data.recipient?.mobile,
        });
    }, [data, mutator, transfer]);

    const recipientValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (data.recipient) {
            let message = "";

            if (!data.recipient.wallet) {
                message = t("change-remittance-recipient--first-time");
            } else {
                const name =
                    data.recipient.wallet.name || data.recipient.mobile;

                if (data.recipient.wallet.balance) {
                    const newBalance = amount
                        .add(data.recipient.wallet.balance)
                        .toString();
                    message = t("change-remittance-recipient--new-balance", {
                        name,
                        balance: newBalance,
                    });
                }
            }

            validations.push({
                type: "none",
                message,
                display: "always",
            });
        } else {
            validations.push({
                type: "error",
                message: t("form-validation--missing-mobile"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [data.recipient, amount, t]);

    const isValid = !hasError([recipientValidations]);

    return (
        <MutatorModal
            {...mutator}
            title={t("change-remittance-recipient--title", {
                amount: amount.toString(),
            })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("change-remittance-recipient--submit")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <ProfileSearchField
                    autoFocus
                    allowUnregistered
                    allowMerchants
                    allowWallets
                    allowTerminated
                    label={t("label-mobile")}
                    name="recipient"
                    allowAgents={false}
                    validations={recipientValidations}
                    onChange={(profile) => {
                        if (profile) setRecipient(profile as Profile);
                    }}
                />
            </Form>
        </MutatorModal>
    );
};

export const ChangeRemittanceRecipientMutator = createFragmentContainer(
    _ChangeRemittanceRecipientMutator,
    {
        transfer: graphql`
            fragment ChangeRemittanceRecipient_transfer on RemittanceTransferReceivedEntry {
                opaqueId
                senderName
                senderMobile
                amount
            }
        `,
    }
);
