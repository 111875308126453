/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LogoutAllUserSessions_user = {
    readonly id: string;
    readonly " $refType": "LogoutAllUserSessions_user";
};
export type LogoutAllUserSessions_user$data = LogoutAllUserSessions_user;
export type LogoutAllUserSessions_user$key = {
    readonly " $data"?: LogoutAllUserSessions_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LogoutAllUserSessions_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LogoutAllUserSessions_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '10bd432631f87bb6e5d5fb501c31de40';
export default node;
