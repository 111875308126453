import { Button } from "react-bootstrap";

import { useTrackEvent } from "@/hooks/useTrackEvent";

export const ReloadButton = () => {
    const trackEvent = useTrackEvent();
    return (
        <Button
            onClick={() => {
                // (Allie) do we really need to track this kind of event?
                trackEvent("reload");
                location.reload();
            }}
            className="ml-2"
            variant="outline-info"
            data-testid="reload-button"
        >
            &#8635;
        </Button>
    );
};
