/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UnfreezeTransactionMutationVariables = {
    transferOrLedgerTransactionId: string;
    currentUserMobile: string;
};
export type UnfreezeTransactionMutationResponse = {
    readonly unfreezeTransaction: {
        readonly success: boolean;
        readonly historyEntry: {
            readonly id: string;
            readonly isCancelled: boolean;
            readonly isPending: boolean;
        } | null;
    } | null;
};
export type UnfreezeTransactionMutation = {
    readonly response: UnfreezeTransactionMutationResponse;
    readonly variables: UnfreezeTransactionMutationVariables;
};



/*
mutation UnfreezeTransactionMutation(
  $transferOrLedgerTransactionId: ID!
  $currentUserMobile: String!
) {
  unfreezeTransaction(transferOrLedgerTransactionId: $transferOrLedgerTransactionId, currentUserMobile: $currentUserMobile) {
    success
    historyEntry {
      __typename
      id
      isCancelled
      isPending
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentUserMobile"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transferOrLedgerTransactionId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "currentUserMobile",
    "variableName": "currentUserMobile"
  },
  {
    "kind": "Variable",
    "name": "transferOrLedgerTransactionId",
    "variableName": "transferOrLedgerTransactionId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "success",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelled",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPending",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnfreezeTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnfreezeTransaction",
        "kind": "LinkedField",
        "name": "unfreezeTransaction",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "historyEntry",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnfreezeTransactionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnfreezeTransaction",
        "kind": "LinkedField",
        "name": "unfreezeTransaction",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "historyEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dab7d9e7b6a9751eb0f69eb07ab08521",
    "id": null,
    "metadata": {},
    "name": "UnfreezeTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation UnfreezeTransactionMutation(\n  $transferOrLedgerTransactionId: ID!\n  $currentUserMobile: String!\n) {\n  unfreezeTransaction(transferOrLedgerTransactionId: $transferOrLedgerTransactionId, currentUserMobile: $currentUserMobile) {\n    success\n    historyEntry {\n      __typename\n      id\n      isCancelled\n      isPending\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9541e5c19982347145749c72c0237683';
export default node;
