import _ from "lodash";
import {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { FormControl, InputGroup } from "react-bootstrap";

import { FieldProps } from "@/types/FormFieldProps";

import { FormContext } from "./Form";
import { FormGroup } from "./FormGroup";

type UseTextFieldResult = {
    value: string;
    setValue: (value: string) => void;
};

export const useTextField = (initialValue?: string) => {
    const [value, setValue] = useState<string>(initialValue || "");
    return { value, setValue };
};

export type TextFieldProps = FieldProps<string> &
    UseTextFieldResult & { as?: "input" | "textarea" };

/**
 * Standard TextField
 *
 * - It can be rendered as an `input` (default) or `textarea` (see `as` attribute)
 * - The internal state is a *string*.
 * - `defaultValue` attribute can be used to override the initial field value
 * - `validations` specify the validation results (see `types/FormValidation.ts`) for the field
 *
 * @example
 *
 * const textfield = useTextField();
 * ...
 * return <TextField {...textfield} label="Label" ... />;
 */
export const TextField = (props: TextFieldProps) => {
    const {
        autoFocus,
        label,
        value,
        setValue,
        defaultValue,
        name,
        validations,
        disabled = false,
        placeholder,
    } = props;
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(e.target.value);
        },
        [setValue]
    );

    // autoFocus is broken on animated modals - see https://github.com/wavemm/monorepo/pull/17574
    const formControlRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (autoFocus) {
            formControlRef?.current && formControlRef.current.focus();
        }
    }, [autoFocus]);

    useEffect(() => {
        if (!_.isNil(defaultValue)) {
            setValue(defaultValue ?? "");
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const context = useContext(FormContext);
    return (
        <FormGroup label={label} name={name} validations={validations}>
            <InputGroup>
                <FormControl
                    ref={formControlRef}
                    as={props.as}
                    disabled={context.isDisabled || disabled}
                    type="text"
                    autoFocus={autoFocus}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleChange}
                />
            </InputGroup>
        </FormGroup>
    );
};
