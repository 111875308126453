/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentIssueType = "AGENT_BOYCOTTING" | "AGENT_NEEDS_TRAINING" | "CHARGING_EXTRA_FEES" | "CHARGING_EXTRA_FEES_FOR_SIGNUP" | "CHARGING_EXTRA_FEES_FOR_TRANSACTIONS" | "CLOSED_DURING_BUSINESS_HOURS" | "CLOSED_PERMANENTLY" | "NEEDS_BANK_REBALANCE" | "NEEDS_BANNER" | "NEEDS_CASH" | "NEEDS_CASH_PICKUP" | "NEEDS_FLYERS" | "NEEDS_QR_CARDS" | "NEEDS_SHARED_AGENT" | "NOT_ENOUGH_CASH_FOR_WITHDRAWAL" | "NOT_ENOUGH_FLOAT_FOR_DEPOSIT" | "OTHER" | "OTHER_AGENT_REQUEST" | "OTHER_USER_COMPLAINT" | "REDIRECTING_TO_COMPETITION" | "REFUSING_TO_DO_SMALL_TRANSACTIONS" | "REQUEST_AGENT_REACTIVATION" | "REQUEST_AGENT_SUSPENSION" | "REQUEST_AGENT_TERMINATION" | "USER_COMPLAINT_LOW_LIQUIDITY" | "%future added value";
export type ReportUserComplaint_agentUser = {
    readonly agent: {
        readonly ufid: string;
        readonly name: string;
        readonly possibleIssueTypes: ReadonlyArray<{
            readonly type: AgentIssueType;
            readonly displayName: string;
            readonly displayGroupName: string | null;
        }>;
    };
    readonly " $refType": "ReportUserComplaint_agentUser";
};
export type ReportUserComplaint_agentUser$data = ReportUserComplaint_agentUser;
export type ReportUserComplaint_agentUser$key = {
    readonly " $data"?: ReportUserComplaint_agentUser$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ReportUserComplaint_agentUser">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportUserComplaint_agentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Agent",
      "kind": "LinkedField",
      "name": "agent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ufid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentIssueEntry",
          "kind": "LinkedField",
          "name": "possibleIssueTypes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayGroupName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AgentUser",
  "abstractKey": null
};
(node as any).hash = 'cd9d6103f6245ea01dac17c074db4a21';
export default node;
