import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment, useRelayEnvironment } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal } from "../../components/MutatorModal";
import { TextField } from "../../components/TextField";

import { mutate } from "@/external/relay";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";

import { UpdateBlockMutation } from "./__generated__/UpdateBlockMutation.graphql";
import { UpdateBlock_activeBlock$key } from "./__generated__/UpdateBlock_activeBlock.graphql";

const mutation = graphql`
    mutation UpdateBlockMutation($id: ID!, $blockReason: String) {
        updateBlock(id: $id, blockReason: $blockReason) {
            result
            block {
                blockReason
            }
        }
    }
`;

const fragment = graphql`
    fragment UpdateBlock_activeBlock on ActiveBlock {
        id
        blockReason
    }
`;

interface UpdateBlockProps {
    activeBlock: UpdateBlock_activeBlock$key;
}

export const UpdateBlockMutator = (
    props: UpdateBlockProps & RelayModalProps
) => {
    const { show, onHide, size, activeBlock } = props;

    const { t } = useTranslation();

    const environment = useRelayEnvironment();
    const data = useFragment(fragment, activeBlock);
    const [blockReason, setBlockReason] = useState(data.blockReason);
    const updateBlockRequest = useAsyncFunction(async () => {
        const [response, payloadErrors] = await mutate<UpdateBlockMutation>(
            environment,
            mutation,
            { id: data.id, blockReason }
        );

        if (payloadErrors) {
            return payloadErrors;
        }
        return response.updateBlock?.result;
    });
    const { error, result } = updateBlockRequest;
    const errors = Array.isArray(result) ? result : error ? [error] : null;
    const resultText = typeof result === "string" ? result : null;
    const blockReasonValidations: Validation[] = [];
    if (!blockReason) {
        blockReasonValidations.push({
            type: "error",
            message: t("form-validation--missing-reason"),
            display: "if-blurred",
        });
    }
    return (
        <MutatorModal
            title={t("update-block--title")}
            isWorking={updateBlockRequest.isLoading}
            show={show}
            onHide={onHide}
            size={size}
            errors={errors}
        >
            <Form
                onSubmit={updateBlockRequest.invoke}
                submitText={t("update-block--submit")}
                isWorking={updateBlockRequest.isLoading}
                errors={errors}
                isValid={blockReasonValidations.length === 0}
                result={resultText}
            >
                <TextField
                    value={blockReason}
                    setValue={setBlockReason}
                    label={t("label-new-reason")}
                    name="reason"
                    validations={blockReasonValidations}
                />
            </Form>
        </MutatorModal>
    );
};
