/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BlockSubject = "DEFAULT" | "DEVICE" | "LEGAL_ENTITY" | "MOBILE" | "%future added value";
export type AgentDepositsBlockHistory_user = {
    readonly id: string;
    readonly historyOfAgentDepositsUnblocks: ReadonlyArray<{
        readonly whenBlocked: string;
        readonly whenUnblocked: string | null;
        readonly blockedBy: string;
        readonly blockReason: string;
        readonly blockSubject: BlockSubject | null;
        readonly unblockReason: string | null;
        readonly unblockedBy: string | null;
    } | null>;
    readonly " $refType": "AgentDepositsBlockHistory_user";
};
export type AgentDepositsBlockHistory_user$data = AgentDepositsBlockHistory_user;
export type AgentDepositsBlockHistory_user$key = {
    readonly " $data"?: AgentDepositsBlockHistory_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentDepositsBlockHistory_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentDepositsBlockHistory_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "historyOfAgentDepositsUnblocks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenBlocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenUnblocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockSubject",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockedBy",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'da8bfb5948a6851aba18e9080f24ba66';
export default node;
