import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";
import { OperationEnum } from "@/types/misc";

import { ModifyAgentGamingUserWatchlistMutationResponse } from "./__generated__/ModifyAgentGamingUserWatchlistMutation.graphql";

const mutation = graphql`
    mutation ModifyAgentGamingUserWatchlistMutation(
        $mobile: String!
        $operation: AgentGamingListOperation!
        $comment: String
    ) {
        modifyAgentGamingUserWatchlist(
            mobile: $mobile
            operation: $operation
            comment: $comment
        ) {
            success
        }
    }
`;

type AgentGamingUserWatchlistParams = {
    name: string;
    mobile: string;
    operation: OperationEnum;
};

const _ModifyAgentGamingUserWatchlistMutator = (
    props: AgentGamingUserWatchlistParams & RelayModalProps
) => {
    const { onHide, name, mobile, operation } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ModifyAgentGamingUserWatchlistMutationResponse) => {
            if (
                response?.modifyAgentGamingUserWatchlist?.success &&
                operation === OperationEnum.ADD
            ) {
                return t("agent-gaming-user-watchlist--success-add");
            }
            if (
                response?.modifyAgentGamingUserWatchlist?.success &&
                operation === OperationEnum.REMOVE
            ) {
                return t("agent-gaming-user-watchlist--success-remove");
            }
            return t("agent-gaming-user-watchlist--error");
        },
        [t, operation]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name:
                operation === OperationEnum.ADD
                    ? Action.AddUserToAgentGamingWatchlist
                    : Action.RemoveUserFromAgentGamingWatchlist,
            data: { mobile },
        },
        ...props,
    });

    const commentField = useTextField();

    const handleSubmit = useCallback(() => {
        const body = {
            mobile,
            operation,
            comment: commentField.value,
        };
        mutator.submit(mutation, body);
    }, [mutator, commentField.value, mobile, operation]);

    return (
        <MutatorModal
            {...mutator}
            title={
                operation == OperationEnum.ADD
                    ? t("agent-gaming-user-watchlist--title-add", { name })
                    : t("agent-gaming-user-watchlist--title-remove", { name })
            }
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                {operation === OperationEnum.ADD && (
                    <TextField
                        {...commentField}
                        label={t("label-comment")}
                        name="comment"
                        defaultValue={""}
                        validations={[]}
                        autoFocus
                    />
                )}
                {operation === OperationEnum.REMOVE && (
                    <p>
                        {t("agent-gaming-user-watchlist--description-remove", {
                            id: mobile,
                        })}
                    </p>
                )}
            </Form>
        </MutatorModal>
    );
};
export const ModifyAgentGamingUserWatchlistMutator = createFragmentContainer(
    _ModifyAgentGamingUserWatchlistMutator,
    {}
);
