import { Badge, BadgeProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ActionButton } from "@/components/ActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { SendMerchantAppLinkMutator } from "@/pages/mobile.[mobile].merchant/SendMerchantAppLink";
import { AppType } from "@/pages/mobile.[mobile].merchant/__generated__/MerchantTabQuery.graphql";

// TODO I'd like to get this from the generated gql instead of copy/pasting here
type MerchantUser = {
    readonly businessAppVersion: {
        readonly appType: AppType;
        readonly appVersionCode: number | null;
        readonly isUpToDate: boolean;
        readonly string: string;
    } | null;
} | null;

export const BusinessAppStatus = ({
    merchantUser,
    name,
    mobile,
}: {
    merchantUser: MerchantUser;
    name: string;
    mobile: string;
}) => {
    const { t } = useTranslation();
    const businessAppVersion = merchantUser?.businessAppVersion;
    const isUpdateAppLinkAllowed = usePermissions(
        "supportapp_send_merchant_app_link"
    );

    let isUpdateAvailable = false;
    let text = "";
    let variant: BadgeProps["variant"] = "primary";
    if (!businessAppVersion) {
        text = t("customer-app-status--no-app");
        variant = "danger";
        if (isUpdateAppLinkAllowed) {
            isUpdateAvailable = true;
            text = `${text}${t("customer-app-status--send-app-link")}`;
        }
    } else if (businessAppVersion.appType == "BUSINESS_APP") {
        text = `ANDROID (${businessAppVersion.string})`;
    } else if (businessAppVersion.appType == "BUSINESS_APP_IOS") {
        text = `IPHONE (${businessAppVersion.string})`;
    }

    if (businessAppVersion && !businessAppVersion.isUpToDate) {
        variant = "warning";
        if (isUpdateAppLinkAllowed) {
            isUpdateAvailable = true;
            text = `${text}${t("customer-app-status--send-update")}`;
        }
    }

    if (isUpdateAppLinkAllowed && isUpdateAvailable) {
        return (
            <ActionButton name={text} as="badge" badgeVariant={variant}>
                <SendMerchantAppLinkMutator name={name} mobile={mobile} />
            </ActionButton>
        );
    }

    return (
        <Badge className="text-capitalize" variant={variant}>
            {text}
        </Badge>
    );
};
