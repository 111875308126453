import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { TicketLink } from "@/components/TicketLink";
import { TxInfo } from "@/components/TxInfo";

import { usePermissions } from "@/hooks/usePermissions";

import { M } from "@/utils/currency";

import { AgentHistoryAtxEntry_tx$key } from "./__generated__/AgentHistoryAtxEntry_tx.graphql";
import { AgentHistoryEntry_entry$key } from "./__generated__/AgentHistoryEntry_entry.graphql";

import { AgentAtxEntryAgentScamButton } from "./AgentAtxEntryAgentScamButton";
import { AgentAtxEntrySubLine } from "./AgentAtxEntrySubLine";
import { AgentAtxEntryTitle } from "./AgentAtxEntryTitle";
import { historyEntryFragment, txFragment } from "./AgentHistory.fragments";
import { CorrectAtx } from "./CorrectAtx";
import * as HistoryEntry from "./HistoryEntry";

interface Props {
    tx: AgentHistoryAtxEntry_tx$key;
    entry: AgentHistoryEntry_entry$key;
    isFocussed: boolean;
}
export default function _AgentAtxEntry(props: Props) {
    const { t } = useTranslation();

    const tx = useFragment(txFragment, props.tx);
    const entry = useFragment(historyEntryFragment, props.entry);

    const canCorrectAtx = usePermissions("supportapp_correct_atx");

    const correctAtxActionButton = canCorrectAtx ? (
        <HistoryActionButton key="correct-atx" name={t("correct-atx--action")}>
            <CorrectAtx
                transactionId={tx.agentTransactionId}
                agentId={tx.agentUfid}
                agentName={tx.agentName}
                customerMobile={tx.maybeTerminatedCustomerMobile}
                customerName={tx.customerName}
                isDeposit={tx.isDeposit}
                amount={M.fromSerialized(tx.amount).abs()}
            />
        </HistoryActionButton>
    ) : null;

    const buttons = [
        tx.isDeposit ? (
            <AgentAtxEntryAgentScamButton tx={tx} key={tx.agentTransactionId} />
        ) : null,
        correctAtxActionButton,
    ].filter(Boolean);

    return (
        <HistoryEntry.Container id={entry.id} isFocussed={props.isFocussed}>
            <HistoryEntry.TopRow
                isPending={entry.isPending}
                amount={M.fromSerialized(entry.amount)}
            >
                <AgentAtxEntryTitle tx={tx} />
                {tx.isCancelled && (
                    <TxInfo>
                        <Badge variant="danger">
                            {t("agent-atx-entry--cancelled")}
                        </Badge>
                    </TxInfo>
                )}
                <AgentAtxEntrySubLine tx={tx} />
                {tx.relatedTicket && (
                    <TicketLink
                        status={tx.relatedTicket.status}
                        slackMessageUrl={tx.relatedTicket.slackMessageUrl}
                    />
                )}
            </HistoryEntry.TopRow>
            <HistoryEntry.MiddleRow entry={entry} buttons={buttons} />
        </HistoryEntry.Container>
    );
}
