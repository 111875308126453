import { Modal as BSModal, ModalProps } from "react-bootstrap";

/**
 * The outermost component returned by the action unit to provide a pop-up.
 * The child components are what will be displayed inside the pop-up.
 *
 */
export const Modal = (props: ModalProps) => (
    <BSModal show={props.show} onHide={props.onHide} size={props.size}>
        <BSModal.Header closeButton>
            <BSModal.Title>{props.title}</BSModal.Title>
        </BSModal.Header>
        <BSModal.Body>{props.children}</BSModal.Body>
    </BSModal>
);
