import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "react-relay";

import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";

import { UnfreezeTransactionMutationResponse } from "@/pages/mobile.[mobile].personal/__generated__/UnfreezeTransactionMutation.graphql";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { getMobileFromWindowLocation } from "@/utils/navigation";

const mutation = graphql`
    mutation UnfreezeTransactionMutation(
        $transferOrLedgerTransactionId: ID!
        $currentUserMobile: String!
    ) {
        unfreezeTransaction(
            transferOrLedgerTransactionId: $transferOrLedgerTransactionId
            currentUserMobile: $currentUserMobile
        ) {
            success
            historyEntry {
                id
                isCancelled
                isPending
            }
        }
    }
`;

type UnfreezeTransactionInputParams = {
    transferOrLedgerTransactionId: string;
};

export const UnfreezeTransactionMutator = (
    props: RelayModalProps & UnfreezeTransactionInputParams
) => {
    const { onHide, transferOrLedgerTransactionId } = props;

    const currentUserMobile = getMobileFromWindowLocation();
    if (typeof currentUserMobile === "undefined") {
        throw new Error("Can only use this mutator when a profile is selected");
    }
    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        ({
            unfreezeTransaction,
        }: UnfreezeTransactionMutationResponse): string | null => {
            if (unfreezeTransaction?.success)
                return t("unfreeze-transaction--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.UnfreezeFunds,
            data: { transferOrLedgerTransactionId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            transferOrLedgerTransactionId,
            currentUserMobile,
        });
    }, [mutator, transferOrLedgerTransactionId, currentUserMobile]);

    return (
        <MutatorModal {...mutator} title={t("unfreeze-transaction--title")}>
            <Form
                {...mutator}
                isValid={true}
                submitText={t("unfreeze-transaction--submit")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            ></Form>
        </MutatorModal>
    );
};
