import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { ReportRefundingDisputeMutationResponse } from "./__generated__/ReportRefundingDisputeMutation.graphql";

const mutation = graphql`
    mutation ReportRefundingDisputeMutation(
        $transferId: ID!
        $comments: String!
    ) {
        reportRefundingDispute(transferId: $transferId, comments: $comments) {
            result
        }
    }
`;

type ReportRefundingDisputeInputParams = {
    transferId?: string;
    comments?: string;
};

const _ReportRefundingDisputeMutator = (
    props: ReportRefundingDisputeInputParams & RelayModalProps
) => {
    const { onHide, transferId, comments } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ReportRefundingDisputeMutationResponse) => {
            return response.reportRefundingDispute?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportRefundingDispute,
            data: { transferId },
        },
        ...props,
    });
    const transferIdField = useTextField();
    const commentsField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            transferId: transferIdField.value,
            comments: commentsField.value,
        });
    }, [mutator, transferIdField.value, commentsField.value]);

    const transferIdValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (transferIdField.value && !transferIdField.value.includes("T_")) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-transaction-id"),
            });
        }
        return validations;
    }, [t, transferIdField.value]);

    const isValid = useMemo(
        () => !hasError([transferIdValidations]),
        [transferIdValidations]
    );

    return (
        <MutatorModal {...mutator} title={t("report-refunding-dispute--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...transferIdField}
                    label={t("label-transaction-id")}
                    name="transferId"
                    validations={transferIdValidations}
                    defaultValue={transferId}
                />

                <TextField
                    {...commentsField}
                    label={t("label-comments")}
                    name="comments"
                    validations={[]}
                    defaultValue={comments}
                    as="textarea"
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};
export const ReportRefundingDisputeMutator = createFragmentContainer(
    _ReportRefundingDisputeMutator,
    {}
);
