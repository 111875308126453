import * as React from "react";
import { Fragment } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { RemittanceTransferReceivedEntry_tx } from "./__generated__/RemittanceTransferReceivedEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";

function _RemittanceTransferReversalEntry(
    props: {
        tx: RemittanceTransferReceivedEntry_tx;
    } & HistoryEntryProps
) {
    const { tx } = props;

    const info = <TxInfo>{tx.opaqueId} </TxInfo>;

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <Fragment>
                    <TxTitle>{tx.description}</TxTitle>
                    {info}
                </Fragment>
            }
        />
    );
}

export default createFragmentContainer(_RemittanceTransferReversalEntry, {
    tx: graphql`
        fragment RemittanceTransferReversalEntry_tx on RemittanceTransferReversalEntry {
            id
            opaqueId
            description
            amount
            isPending
            senderName
            senderMobile
            senderCountry
            recipientMobile
            receiveAmount
            ...HistoryEntry_entry
        }
    `,
});
