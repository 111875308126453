/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfileSearcher_agent = {
    readonly id: string;
    readonly name: string;
    readonly city: string | null;
    readonly subcity: string | null;
    readonly ufid: string;
    readonly isActive: boolean;
    readonly floatWallet: {
        readonly id: string;
        readonly balance: string;
    };
};
export type ProfileSearcher_agent$data = ProfileSearcher_agent;
export type ProfileSearcher_agent$key = {
    readonly " $data"?: ProfileSearcher_agent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfileSearcher_agent">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "ProfileSearcher_agent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subcity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ufid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Wallet",
      "kind": "LinkedField",
      "name": "floatWallet",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "balance",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})();
(node as any).hash = 'd94803d67c2380a922927d6e932c0f58';
export default node;
