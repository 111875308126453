import _ from "lodash";
import {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { FormControl, InputGroup } from "react-bootstrap";

import { FieldProps } from "@/types/FormFieldProps";

import { C, M } from "@/utils/currency";

import { FormContext } from "./Form";
import { FormGroup } from "./FormGroup";

type MOrNull = M | null;
type UseCurrencyFieldResult = {
    value: M | null;
    setValue: (value: MOrNull) => void;
};

export const useCurrencyField = () => {
    const [value, setValue] = useState<M | null>(null);
    return { value, setValue };
};

export type CurrencyFieldProps = FieldProps<M> &
    UseCurrencyFieldResult & { currency: C };

/**
 * Text field that accepts and enforces monetary amount inputs.
 * The user input is filtered to contain valid amounts, and the currency is displayed
 * to the left of the amount.
 *
 * - The internal state is a *nullable* `M`, initially `null`.
 * - The state is null iff the field empty
 * - `0` in the field results in `M(currency,0)` in the state
 * - `defaultValue` attribute can be used to override the initial field value
 * - `validations` specify the validation results (see `types/FormValidation.ts`) for the field
 *
 * @example
 *
 * const moneyfield = useCurrencyField();
 * ...
 * return <CurrencyField {...moneyfield} label="Amount" ... />;
 */
export const CurrencyField = (props: CurrencyFieldProps) => {
    const {
        disabled = false,
        autoFocus,
        label,
        value,
        setValue,
        defaultValue,
        name,
        validations,
        currency,
    } = props;

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue: M | null = null;
            if (e.target.value) {
                try {
                    newValue = new M(currency, e.target.value);
                } catch (error) {
                    return;
                }
            }
            setValue(newValue);
        },
        [setValue, currency]
    );

    useEffect(() => {
        if (!_.isNil(defaultValue)) {
            setValue(defaultValue ?? null);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const text = value?.scalar?.toNumber().toString() || "";
    const context = useContext(FormContext);
    return (
        <FormGroup label={label} name={name} validations={validations}>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text data-testid="currency">
                        {currency}
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    disabled={context.isDisabled || disabled}
                    type="text"
                    autoFocus={autoFocus}
                    value={text}
                    onChange={handleChange}
                />
            </InputGroup>
        </FormGroup>
    );
};
