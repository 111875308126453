import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { useTextField } from "../../components/TextField";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { UnblockUserFromDormancyMutationResponse } from "./__generated__/UnblockUserFromDormancyMutation.graphql";
import { UnblockUserFromDormancy_user } from "./__generated__/UnblockUserFromDormancy_user.graphql";

const mutation = graphql`
    mutation UnblockUserFromDormancyMutation($userId: ID!) {
        unblockUserFromDormancy(userId: $userId) {
            user {
                ...UnblockUserFromDormancy_user
            }
        }
    }
`;

type UnblockUserFromDormancyInputParams = {
    user: UnblockUserFromDormancy_user;
};

const _UnblockUserFromDormancyMutator = (
    props: UnblockUserFromDormancyInputParams & RelayModalProps
) => {
    const { onHide } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (_response: UnblockUserFromDormancyMutationResponse) => {
            return t("unblock-user-dormancy--success");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.UnblockUserFromDormancy,
            data: { userId: props.user.id },
        },
        ...props,
    });
    const reasonField = useTextField();

    const handleSubmit = () => {
        mutator.submit(mutation, {
            userId: props.user.id,
            reason: reasonField.value,
        });
    };

    return (
        <MutatorModal
            {...mutator}
            size="xl"
            title={t("unblock-user-dormancy--title")}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("unblock-user-dormancy--submit")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <p>{t("unblock-user-dormancy--description")}</p>
            </Form>
        </MutatorModal>
    );
};

export const UnblockUserFromDormancyMutator = createFragmentContainer(
    _UnblockUserFromDormancyMutator,
    {
        user: graphql`
            fragment UnblockUserFromDormancy_user on User {
                id
                dormancyBlock {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenExpires
                }
            }
        `,
    }
);
