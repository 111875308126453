import { faIdCard, faSimCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Timestamp } from "../../components/Timestamp";

import { notEmpty } from "@/utils/value";

import { AgentDepositsBlockHistory_user } from "./__generated__/AgentDepositsBlockHistory_user.graphql";

type AgentDepositsBlockHistoryParams = {
    user: AgentDepositsBlockHistory_user;
};

const _AgentDepositsBlockHistory = (
    params: AgentDepositsBlockHistoryParams
) => {
    const {
        user: { historyOfAgentDepositsUnblocks },
    } = params;

    const { t } = useTranslation();

    if (historyOfAgentDepositsUnblocks?.length > 0) {
        return (
            <div>
                <h3>{t("agent-deposits-block-history--title")}</h3>
                <br />
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr key="head">
                            <th>&nbsp;</th>
                            <th>
                                {t(
                                    "agent-deposits-block-history--when-blocked"
                                )}
                            </th>
                            <th>
                                {t("agent-deposits-block-history--why-blocked")}
                            </th>
                            <th>
                                {t(
                                    "agent-deposits-block-history--why-unblocked"
                                )}
                            </th>
                            <th>
                                {t(
                                    "agent-deposits-block-history--unblocked-by"
                                )}
                            </th>
                            <th>
                                {t(
                                    "agent-deposits-block-history--when-unblocked"
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyOfAgentDepositsUnblocks
                            .filter(notEmpty)
                            .map((block, i) => (
                                <tr key={i}>
                                    <td>
                                        {block?.blockSubject ==
                                            "LEGAL_ENTITY" && (
                                            <FontAwesomeIcon
                                                icon={faIdCard}
                                                title={t(
                                                    "agent-deposits-block-history--block-entity"
                                                )}
                                            />
                                        )}
                                        {block?.blockSubject == "MOBILE" && (
                                            <FontAwesomeIcon
                                                icon={faSimCard}
                                                title={t(
                                                    "agent-deposits-block-history--block-mobile"
                                                )}
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <Timestamp
                                            value={new Date(block.whenBlocked)}
                                            format={"MMM, DD YYYY HH:mm"}
                                        />
                                    </td>
                                    <td>{block.blockReason}</td>
                                    <td>
                                        {block.whenUnblocked
                                            ? block.unblockReason
                                            : "–"}
                                    </td>
                                    <td>
                                        {block.whenUnblocked
                                            ? block.unblockedBy
                                            : "–"}
                                    </td>
                                    <td>
                                        {block.whenUnblocked ? (
                                            <Timestamp
                                                value={
                                                    new Date(
                                                        block.whenUnblocked
                                                    )
                                                }
                                                format={"MMM, DD YYYY HH:mm"}
                                            />
                                        ) : (
                                            "–"
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
        );
    }
    return null;
};

export const AgentDepositsBlockHistory = createFragmentContainer(
    _AgentDepositsBlockHistory,
    {
        user: graphql`
            fragment AgentDepositsBlockHistory_user on User {
                id
                historyOfAgentDepositsUnblocks {
                    whenBlocked
                    whenUnblocked
                    blockedBy
                    blockReason
                    blockSubject
                    unblockReason
                    unblockedBy
                }
            }
        `,
    }
);
