import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { mutate } from "@/external/relay";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { RequestToUnblockFromAgentDepositsMutation } from "./__generated__/RequestToUnblockFromAgentDepositsMutation.graphql";
import { RequestToUnblockFromAgentDeposits_wallet } from "./__generated__/RequestToUnblockFromAgentDeposits_wallet.graphql";

const mutation = graphql`
    mutation RequestToUnblockFromAgentDepositsMutation(
        $mobile: String!
        $reason: String!
    ) {
        requestToUnblockFromAgentDeposits(mobile: $mobile, reason: $reason) {
            result
            wallet {
                user {
                    agentDepositsBlock {
                        whenBlocked
                        blockedBy
                        blockReason
                    }
                }
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
        }
    }
`;

type RequestToUnblockFromAgentDepositsInputParams = {
    wallet: RequestToUnblockFromAgentDeposits_wallet;
};

const _RequestToUnblockFromAgentDepositsMutator = (
    props: RequestToUnblockFromAgentDepositsInputParams & RelayModalProps
) => {
    const trackEvent = useTrackEvent();
    const { wallet } = props;

    const { t } = useTranslation();

    const reasonField = useTextField();
    const mobile = wallet.mobile;

    const submitText = t("request-unblock-deposits--submit");
    const modalHelpText = <p>{t("request-unblock-deposits--description")}</p>;

    const requestToUnblockFromAgentDepositsRequest = useAsyncFunction(
        async () => {
            if (!props.relay) {
                return;
            }
            const [response, payloadErrors] =
                await mutate<RequestToUnblockFromAgentDepositsMutation>(
                    props.relay.environment,
                    mutation,
                    {
                        mobile: mobile,
                        reason: reasonField.value,
                    }
                );
            if (payloadErrors) {
                return payloadErrors;
            }

            trackEvent(Action.RequestToUnblockFromAgentDeposits);

            return response?.requestToUnblockFromAgentDeposits?.result;
        }
    );

    const reasonValidations: Validation[] = [];
    if (!reasonField.value) {
        reasonValidations.push({
            type: "error",
            message: t("request-unblock-deposits--missing-reason"),
            display: "if-blurred",
        });
    }
    const isValid = !hasError([reasonValidations]);
    const requestToUnblockFromAgentDepositsResultText =
        typeof requestToUnblockFromAgentDepositsRequest.result === "string"
            ? requestToUnblockFromAgentDepositsRequest.result
            : null;
    const requestToUnblockFromAgentDepositsErrors = Array.isArray(
        requestToUnblockFromAgentDepositsRequest.result
    )
        ? requestToUnblockFromAgentDepositsRequest.result
        : requestToUnblockFromAgentDepositsRequest.error
        ? [requestToUnblockFromAgentDepositsRequest.error]
        : null;

    return (
        <MutatorModal
            title={submitText}
            isWorking={requestToUnblockFromAgentDepositsRequest.isLoading}
            show={props.show}
            onHide={props.onHide}
            errors={requestToUnblockFromAgentDepositsErrors}
        >
            {modalHelpText}
            <Form
                onSubmit={requestToUnblockFromAgentDepositsRequest.invoke}
                submitText={submitText}
                isWorking={requestToUnblockFromAgentDepositsRequest.isLoading}
                errors={requestToUnblockFromAgentDepositsErrors}
                isValid={isValid}
                result={requestToUnblockFromAgentDepositsResultText}
            >
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
        </MutatorModal>
    );
};

export const RequestToUnblockFromAgentDepositsMutator = createFragmentContainer(
    _RequestToUnblockFromAgentDepositsMutator,
    {
        wallet: graphql`
            fragment RequestToUnblockFromAgentDeposits_wallet on Wallet {
                id
                mobile
                activeMobileBlock {
                    whenBlocked
                }
                activeLegalEntityBlock {
                    whenBlocked
                }
                blockHistory {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenUnblocked
                    unblockedBy
                    unblockReason
                    blockSubject
                }
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
        `,
    }
);
