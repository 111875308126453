import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import {
    DropdownField,
    useDropdownField,
} from "../../components/DropdownField";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isValidMobile, mobileValidationErrorMessage } from "@/utils/mobile";

import { EscalateToSupportGroupLeadMutationResponse } from "./__generated__/EscalateToSupportGroupLeadMutation.graphql";

import {
    SupportGroupLeadEscalations,
    SupportGroupLeadEscalationsHistoryTable,
    SupportPartialRefundEscalations,
} from "./SupportGroupLeadEscalationsHistoryTable";

type EscalateToSupportGroupLeadInputParams = {
    mobile: string;
    comments?: string;
    supportGroupLeadEscalations?: SupportGroupLeadEscalations;
    supportPartialRefundEscalations?: SupportPartialRefundEscalations;
};

const mutation = graphql`
    mutation EscalateToSupportGroupLeadMutation(
        $mobile: String!
        $comments: String!
        $priority: Int!
    ) {
        escalateToSupportGroupLead(
            mobile: $mobile
            comments: $comments
            priority: $priority
        ) {
            result
        }
    }
`;

const _EscalateToSupportGroupLeadMutator = (
    props: EscalateToSupportGroupLeadInputParams & RelayModalProps
) => {
    const {
        onHide,
        mobile,
        comments,
        supportGroupLeadEscalations,
        supportPartialRefundEscalations,
    } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: EscalateToSupportGroupLeadMutationResponse) => {
            return response.escalateToSupportGroupLead?.result || "";
        },
        []
    );

    const defaultPriority = 3;
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.EscalateToSupportGroupLead,
            data: { mobile },
        },
        ...props,
    });

    const mobileField = useTextField();
    const priorityField = useDropdownField();
    const additionalInfoField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobile: mobileField.value,
            comments: additionalInfoField.value,
            priority: priorityField.dropdownItem?.value || defaultPriority,
        });
    }, [
        mutator,
        mobileField.value,
        additionalInfoField.value,
        priorityField.dropdownItem?.value,
    ]);

    const mobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (mobileField.value.includes("_terminated")) {
            if (!isValidMobile(mobileField.value.split("_terminated")[0])) {
                validations.push(mobileValidationErrorMessage as Validation);
            }
        } else if (!isValidMobile(mobileField.value)) {
            validations.push(mobileValidationErrorMessage as Validation);
        }

        return validations;
    }, [mobileField.value]);

    const isValid = useMemo(() => {
        return !hasError([mobileValidations]);
    }, [mobileValidations]);

    const priorities = [
        {
            value: 1,
            displayName: t("priority-urgent"),
        },
        {
            value: 2,
            displayName: t("priority-high"),
        },
        {
            value: 3,
            displayName: t("priority-normal"),
        },
        {
            value: 4,
            displayName: t("priority-low"),
        },
    ];

    const tickets = [];
    if (supportGroupLeadEscalations) {
        tickets.push(...supportGroupLeadEscalations);
    }
    if (supportPartialRefundEscalations) {
        tickets.push(...supportPartialRefundEscalations);
    }
    tickets.sort((a, b) =>
        new Date(a.whenOpened) < new Date(b.whenOpened) ? -1 : 1
    );

    return (
        <MutatorModal {...mutator} title={t("escalate-group-lead--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...mobileField}
                    label={t("label-customer-mobile")}
                    name="mobile"
                    validations={mobileValidations}
                    defaultValue={mobile}
                    autoFocus
                />

                <DropdownField
                    {...priorityField}
                    label={t("label-priority")}
                    name="priority"
                    validations={[]}
                    values={priorities}
                    defaultValue={defaultPriority}
                    placeholder={t("placeholder-priority")}
                />

                <TextField
                    {...additionalInfoField}
                    label={t("label-comments")}
                    name="comments"
                    validations={[]}
                    defaultValue={comments}
                    as="textarea"
                    autoFocus
                />
            </Form>
            <p>{t("escalate-group-lead-history--title")}</p>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t("escalate-group-lead-history--reported-on")}</th>
                        <th>{t("escalate-group-lead-history--status")}</th>
                        <th>{t("escalate-group-lead-history--clickup-id")}</th>
                    </tr>
                </thead>
                <tbody>
                    <SupportGroupLeadEscalationsHistoryTable
                        tickets={tickets}
                    />
                </tbody>
            </table>
        </MutatorModal>
    );
};
export const EscalateToSupportGroupLeadMutator = createFragmentContainer(
    _EscalateToSupportGroupLeadMutator,
    {}
);
