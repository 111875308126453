/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ReportPaymentCardTransferProblemMutationVariables = {
    transactionId: string;
    comment: string;
};
export type ReportPaymentCardTransferProblemMutationResponse = {
    readonly reportPaymentCardTransferProblem: {
        readonly result: string | null;
    } | null;
};
export type ReportPaymentCardTransferProblemMutation = {
    readonly response: ReportPaymentCardTransferProblemMutationResponse;
    readonly variables: ReportPaymentCardTransferProblemMutationVariables;
};



/*
mutation ReportPaymentCardTransferProblemMutation(
  $transactionId: ID!
  $comment: String!
) {
  reportPaymentCardTransferProblem(transactionId: $transactionId, comment: $comment) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "transactionId",
        "variableName": "transactionId"
      }
    ],
    "concreteType": "ReportPaymentCardTransferProblem",
    "kind": "LinkedField",
    "name": "reportPaymentCardTransferProblem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportPaymentCardTransferProblemMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportPaymentCardTransferProblemMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "df80cbe694f5c6ad002b1bffff5a8f63",
    "id": null,
    "metadata": {},
    "name": "ReportPaymentCardTransferProblemMutation",
    "operationKind": "mutation",
    "text": "mutation ReportPaymentCardTransferProblemMutation(\n  $transactionId: ID!\n  $comment: String!\n) {\n  reportPaymentCardTransferProblem(transactionId: $transactionId, comment: $comment) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '77644636ebf60eb83b4d78a4722131b3';
export default node;
