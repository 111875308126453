import * as React from "react";
import { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FieldProps } from "@/types/FormFieldProps";

import { FormContext } from "./Form";
import { FormGroup } from "./FormGroup";

/**
 * Struct for the dropdown item.
 *
 * `value` is the internal option value
 * `displayName` is the text displayed in the dropdown
 * `optGroup` (optional) the name of the optgroup.
 */
export type DropdownItem = {
    value: string | number;
    displayName: string;
    optGroup?: string | null;
    groupValue?: string | null;
};

export type DropdownItems = DropdownItem[];
type ItemsDict = { [key: string]: DropdownItem[] };
const itemsToDict = (items: DropdownItems): ItemsDict => {
    const dict: ItemsDict = { "": [] };
    for (const item of items) {
        const index = item.optGroup || "";
        if (!(index in dict)) {
            dict[index] = [];
        }
        dict[index].push(item);
    }
    return dict;
};

const hasOptGroups = (dict: ItemsDict) => {
    const keys = Object.keys(dict);
    return keys.length > 1 || (keys.length == 1 && !("" in dict));
};

type UseDropdownFieldResult = {
    dropdownItem: DropdownItem | null;
    setDropdownItem: (item: DropdownItem) => void;
};

export type DropdownFieldProps = FieldProps<string | number> &
    UseDropdownFieldResult & {
        values: DropdownItems;
        placeholder: string;
        keepListOrder?: boolean;
    };

export const useDropdownField = () => {
    const [dropdownItem, setDropdownItem] = useState<DropdownItem | null>(null);
    return useMemo(() => {
        return { dropdownItem, setDropdownItem };
    }, [dropdownItem, setDropdownItem]);
};

/**
 * Dropdown field, possibly with *optGroups*
 *
 * - The state is stored as an optional string, `null` by default.
 * - If an `optGroup` is specified for at least one option, optGroups are created. If, additionally,
 * an `optGroup` is **not** specified for some options, those options will end up at the bottom.
 *
 */
export const DropdownField = (props: DropdownFieldProps) => {
    const {
        autoFocus,
        label,
        setDropdownItem,
        name,
        validations,
        values,
        placeholder,
        defaultValue,
        keepListOrder,
    } = props;
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLSelectElement>) => {
            const item = values.find((i) => i.value == e.target.value);
            if (item) {
                setDropdownItem(item);
            }
        },
        [setDropdownItem, values]
    );

    const { t } = useTranslation();

    const context = useContext(FormContext);
    const itemsDict = useMemo(() => itemsToDict(values), [values]);
    const withOptGroups = useMemo(() => {
        return hasOptGroups(itemsDict);
    }, [itemsDict]);

    const items = useMemo(() => {
        function generateOptGroup(
            optgroup_name: string,
            items: DropdownItem[]
        ) {
            return (
                <optgroup key={optgroup_name} label={optgroup_name}>
                    {generateOptions(items)}
                </optgroup>
            );
        }
        function generateOptions(items: DropdownItem[]) {
            return items.map((item) => (
                <option key={item.value} value={item.value}>
                    {` ${item.displayName}`}
                </option>
            ));
        }

        if (!withOptGroups) {
            return generateOptions(itemsDict[""]);
        }

        let result: React.ReactNode = null;
        // Push the untitled optgroup to the end
        const items = keepListOrder
            ? Object.keys(itemsDict)
            : Object.keys(itemsDict).sort();

        items
            .filter((x) => x)
            .forEach((optgroup_name) => {
                result = (
                    <>
                        {result}
                        {generateOptGroup(
                            optgroup_name,
                            itemsDict[optgroup_name]
                        )}
                    </>
                );
            });
        if ("" in itemsDict) {
            result = (
                <>
                    {result}
                    {generateOptions(itemsDict[""])}
                </>
            );
        }
        return result;
    }, [withOptGroups, keepListOrder, itemsDict]);

    return (
        <FormGroup label={label} name={name} validations={validations}>
            <InputGroup className="w-full">
                <FormControl
                    as="select"
                    disabled={context.isDisabled}
                    type="text"
                    autoFocus={autoFocus}
                    defaultValue={defaultValue || ""}
                    onChange={handleChange}
                >
                    <option key="" value="" disabled>
                        {" "}
                        {placeholder || t("dropdown--select-option")}
                    </option>
                    {items}
                </FormControl>
            </InputGroup>
        </FormGroup>
    );
};
