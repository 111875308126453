import { Suspense, useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { graphql, useLazyLoadQuery } from "react-relay";

import { CurrencyField } from "@/components/CurrencyField";
import { Form } from "@/components/Form";
import { Modal } from "@/components/Modal";
import { useMutator } from "@/components/MutatorModal";
import { TextField } from "@/components/TextField";

import { usePermissions } from "@/hooks/usePermissions";

import { FreezeTransactionMutationResponse } from "@/pages/mobile.[mobile].personal/__generated__/FreezeTransactionMutation.graphql";
import { FreezeTransactionRecipientWalletQuery } from "@/pages/mobile.[mobile].personal/__generated__/FreezeTransactionRecipientWalletQuery.graphql";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { getMobileFromWindowLocation } from "@/utils/navigation";

const mutation = graphql`
    mutation FreezeTransactionMutation(
        $transferOrLedgerTransactionId: ID!
        $note: String
        $currentUserMobile: String
        $canReadHistory: Boolean!
        $canReadBalance: Boolean!
        $amount: Decimal
    ) {
        freezeTransaction(
            transferOrLedgerTransactionId: $transferOrLedgerTransactionId
            note: $note
            currentUserMobile: $currentUserMobile
            amount: $amount
        ) {
            success
            user {
                primaryWallet {
                    ...PersonalTabHeader_wallet
                        @arguments(canReadBalance: $canReadBalance)
                    ...History_wallet
                        @include(if: $canReadHistory)
                        @arguments(last: 10)
                }
            }
        }
    }
`;

const RecipientWalletQuery = graphql`
    query FreezeTransactionRecipientWalletQuery($mobile: String!) {
        wallet(mobile: $mobile) {
            balance
        }
    }
`;

type FreezeTransactionInputParams = {
    transferOrLedgerTransactionId: string;
    recipientMobile: string;
    receiveAmount: string;
};

export const FreezeTransactionMutatorInner = (
    props: RelayModalProps & FreezeTransactionInputParams
) => {
    const { onHide, transferOrLedgerTransactionId, recipientMobile } = props;

    const { t } = useTranslation();
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const currentUserMobile = getMobileFromWindowLocation();
    if (typeof currentUserMobile === "undefined") {
        throw new Error("Can only use this mutator when a profile is selected");
    }

    const { wallet } = useLazyLoadQuery<FreezeTransactionRecipientWalletQuery>(
        RecipientWalletQuery,
        { mobile: recipientMobile }
    );
    const receiveAmount = M.fromSerialized(props.receiveAmount);
    const walletBalance = wallet && M.fromSerialized(wallet.balance);
    const insufficientFunds =
        walletBalance && walletBalance.scalar.lt(receiveAmount.scalar);
    const [partialAmount, setPartialAmount] = useState(walletBalance);

    const partialAmountValidations: Validation[] =
        walletBalance && partialAmount?.scalar?.gt(walletBalance?.scalar)
            ? [
                  {
                      type: "error",
                      display: "always",
                      message: t(
                          "freeze-transaction--partial-amount-too-large",
                          { maximum: walletBalance }
                      ),
                  },
              ]
            : [];

    const [note, setNote] = useState("");

    const onMutationSuccess = useCallback(
        ({
            freezeTransaction,
        }: FreezeTransactionMutationResponse): string | null => {
            if (freezeTransaction?.success)
                return t("freeze-transaction--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.FreezeFunds,
            data: { transferOrLedgerTransactionId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            transferOrLedgerTransactionId,
            note,
            currentUserMobile,
            canReadHistory,
            canReadBalance,
            amount: insufficientFunds ? partialAmount?.scalar : null,
        });
    }, [
        mutator,
        transferOrLedgerTransactionId,
        note,
        currentUserMobile,
        canReadHistory,
        canReadBalance,
        insufficientFunds,
        partialAmount,
    ]);

    return (
        <Form
            {...mutator}
            isValid={partialAmountValidations.length === 0}
            submitText={t("freeze-transaction--submit")}
            onSubmit={onSubmitForm}
            onDone={onHide}
        >
            {insufficientFunds ? (
                <>
                    <p className="mb-2">
                        {t("freeze-transaction--partial-freeze-explanation")}
                    </p>
                    <CurrencyField
                        currency={receiveAmount.currency}
                        label={t("freeze-transaction--partial-freeze-amount")}
                        name="partial-amount"
                        validations={partialAmountValidations}
                        value={partialAmount}
                        setValue={setPartialAmount}
                    />
                </>
            ) : null}
            <TextField
                label={t("freeze-transaction--note")}
                name="note"
                validations={[]}
                value={note}
                setValue={setNote}
            />
        </Form>
    );
};

export const FreezeTransactionMutator = (
    props: RelayModalProps & FreezeTransactionInputParams
) => {
    const { t } = useTranslation();
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size={props.size}
            title={t("freeze-transaction--title")}
        >
            <Suspense
                fallback={
                    <Spinner animation="border" className="d-block mx-auto" />
                }
            >
                <FreezeTransactionMutatorInner {...props} />
            </Suspense>
        </Modal>
    );
};
