/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateAgentTask_agent = {
    readonly id: string;
    readonly ufid: string;
    readonly name: string;
    readonly territoryLeads: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    }>;
    readonly " $refType": "CreateAgentTask_agent";
};
export type CreateAgentTask_agent$data = CreateAgentTask_agent;
export type CreateAgentTask_agent$key = {
    readonly " $data"?: CreateAgentTask_agent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CreateAgentTask_agent">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateAgentTask_agent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ufid",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffMember",
      "kind": "LinkedField",
      "name": "territoryLeads",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})();
(node as any).hash = '449c1928f6e0c00f089b0b373c39aa24';
export default node;
