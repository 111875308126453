import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { EscalateToSupportGroupLeadMutator } from "@/pages/mobile.[mobile].personal/EscalateToSupportGroupLead";
import {
    SupportGroupLeadEscalations,
    SupportPartialRefundEscalations,
} from "@/pages/mobile.[mobile].personal/SupportGroupLeadEscalationsHistoryTable";

export const EscalateToSupportGroupLeadAction = (props: {
    mobile: string;
    supportGroupLeadEscalations?: SupportGroupLeadEscalations;
    supportPartialRefundEscalations?: SupportPartialRefundEscalations;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="button"
            name={t("escalate-group-lead--action")}
            requiredPermissions={["supportapp_escalate_to_support_group_lead"]}
        >
            <EscalateToSupportGroupLeadMutator
                mobile={props.mobile}
                supportGroupLeadEscalations={props.supportGroupLeadEscalations}
                supportPartialRefundEscalations={
                    props.supportPartialRefundEscalations
                }
            />
        </ActionButton>
    );
};
