import Decimal from "decimal.js";
import _ from "lodash";

import { COUNTRIES, CURRENCIES, CURRENCY_NAMES } from "@/types/countries";

export type C = keyof typeof CURRENCIES;

export class M {
    currency: C;

    scalar: Decimal;

    constructor(currency: C, scalar: Decimal | string | number) {
        this.currency = currency;
        this.scalar = new Decimal(scalar);
    }

    isZero(): boolean {
        return this.scalar.isZero();
    }

    isPositive(): boolean {
        return this.scalar.isPositive() && !this.scalar.isZero();
    }

    isNegative(): boolean {
        return this.scalar.isNegative() && !this.scalar.isZero();
    }

    toString(): string {
        const currency = CURRENCIES[this.currency];
        const prefix = "prefix" in currency ? currency.prefix : "";
        const suffix =
            "suffix" in currency
                ? currency.suffix
                : prefix === ""
                ? " " + currency.display
                : "";

        // TODO(ben) pull separators from locale, probably
        const decimalSeparator = ",";
        const groupingSeparator = ".";

        let places = 0;
        if (!this.scalar.floor().equals(this.scalar)) {
            // It's a non integer amount, so include 2 decimal places
            places = 2;
        }

        const sign = this.isNegative() ? "-" : "";
        const absRaw = this.scalar.abs().toFixed(places).toString();
        const dotPos = absRaw.indexOf(".");

        function groupIpart(rawIpart: string): string {
            const parts = rawIpart.split(decimalSeparator);
            parts[0] = parts[0].replace(
                /\B(?=(\d{3})+(?!\d))/g,
                groupingSeparator
            );
            return parts.join(".");
        }

        if (places) {
            const ipart = absRaw.slice(0, Math.max(0, dotPos));
            const fpart = absRaw.slice(Math.max(0, dotPos + 1));
            return sign + groupIpart(ipart) + decimalSeparator + fpart + suffix;
        }
        return prefix + sign + groupIpart(absRaw) + suffix;
    }

    add(m: M) {
        if (_.isNil(m)) {
            throw new TypeError("add() called with nil");
        }
        if (!(m instanceof M)) {
            throw new TypeError(`Type mismatch: ${m} + ${this.toString()}`);
        }
        if (m.currency !== this.currency) {
            throw new TypeError(
                `Currency mismatch: ${m.toString()} vs ${this.toString()}`
            );
        }
        return new M(this.currency, this.scalar.add(m.scalar));
    }

    subtract(m: M) {
        return this.add(m.negate());
    }

    multiply(factor: Decimal | number) {
        return new M(this.currency, this.scalar.mul(factor));
    }

    negate() {
        return new M(this.currency, this.scalar.neg());
    }

    abs() {
        return new M(this.currency, this.scalar.abs());
    }

    serialize(): string {
        return `${this.currency} ${this.scalar.toString()}`;
    }

    static fromSerialized(s: string): M {
        const [currency, amount] = s.split(" ");
        if (!Object.prototype.hasOwnProperty.call(CURRENCIES, currency)) {
            throw new TypeError(`${s}: currency ${currency} is not defined`);
        }
        return new M(currency as C, amount);
    }

    static deduceCurrencyFromMobile(mobile: string, fallback: C = "CFA"): C {
        let prefix = mobile.substring(0, 4);
        if (mobile[0] !== "+") {
            prefix = "+" + mobile.substring(0, 3);
        }
        const country = Object.values(COUNTRIES).find(
            (c) => c.localPrefix === prefix
        );
        const currency = Object.values(CURRENCY_NAMES).find(
            (c) => CURRENCIES[c] == country?.currency
        );
        return currency || fallback;
    }
}

export const formatCurrencyAmount = (amount?: string): string => {
    return M.fromSerialized(amount || "").toString();
};

/**
 * Returns the scalar value of an amount represented as a string with currency
 * @param amount A string representation of an amount (eg. "CFA 100")
 * @returns The scalar value of the amount
 */
export function scalarFromAmount(amount: string): number {
    const num = /([0-9]+)/.exec(amount);
    if (!num) {
        return NaN;
    }
    return Number(num[0]);
}
