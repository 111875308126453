/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DeleteSupportNoteMutationVariables = {
    noteId: string;
};
export type DeleteSupportNoteMutationResponse = {
    readonly deleteSupportNote: {
        readonly note: {
            readonly noteId: string;
        };
        readonly supportNotes: {
            readonly " $fragmentRefs": FragmentRefs<"NotesList_supportNotes">;
        };
    } | null;
};
export type DeleteSupportNoteMutation = {
    readonly response: DeleteSupportNoteMutationResponse;
    readonly variables: DeleteSupportNoteMutationVariables;
};



/*
mutation DeleteSupportNoteMutation(
  $noteId: ID!
) {
  deleteSupportNote(noteId: $noteId) {
    note {
      noteId
    }
    supportNotes {
      ...NotesList_supportNotes
      id
    }
  }
}

fragment NotesList_supportNotes on SupportNotes {
  list {
    noteId
    text
    mobile
    adminUsername
    whenCreated
    whenUpdated
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "noteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "noteId",
    "variableName": "noteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noteId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SupportNote",
  "kind": "LinkedField",
  "name": "note",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteSupportNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSupportNote",
        "kind": "LinkedField",
        "name": "deleteSupportNote",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportNotes",
            "kind": "LinkedField",
            "name": "supportNotes",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotesList_supportNotes"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteSupportNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSupportNote",
        "kind": "LinkedField",
        "name": "deleteSupportNote",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportNotes",
            "kind": "LinkedField",
            "name": "supportNotes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SupportNote",
                "kind": "LinkedField",
                "name": "list",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mobile",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminUsername",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenCreated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenUpdated",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f58545d850ca2ee5fa2dfc98949f5401",
    "id": null,
    "metadata": {},
    "name": "DeleteSupportNoteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteSupportNoteMutation(\n  $noteId: ID!\n) {\n  deleteSupportNote(noteId: $noteId) {\n    note {\n      noteId\n    }\n    supportNotes {\n      ...NotesList_supportNotes\n      id\n    }\n  }\n}\n\nfragment NotesList_supportNotes on SupportNotes {\n  list {\n    noteId\n    text\n    mobile\n    adminUsername\n    whenCreated\n    whenUpdated\n  }\n}\n"
  }
};
})();
(node as any).hash = '5e93eee1b7ec6860de8d72aa907f5c88';
export default node;
