export enum Action {
    AddAgentToAgentGamingWatchlist = "add agent to agent gaming watchlist",
    AddAgentToAgentGamingWhitelist = "add agent to agent gaming whitelist",
    AddUserToAgentGamingWatchlist = "add user to agent gaming watchlist",
    AddUserToAgentGamingWhitelist = "add user to agent gaming whitelist",
    AwardCredit = "award credit",
    AwardPayPromo = "award pay promo",
    BlockDevice = "block device",
    BlockFromAgentDeposits = "block from agent deposits",
    BlockRecipient = "block recipient",
    BlockUser = "block user",
    CalculateLimits = "calculate limits",
    CancelAllAtx = "cancel all atx",
    ChangeRecipient = "change recipient",
    ChangeRecipientRejected = "change recipient rejected",
    ChangeRemittanceRecipient = "change remittance recipient",
    ClearPinRateLimit = "clear pin rate limit",
    CorrectAtx = "correct agent transaction",
    CreateAgentTask = "create agent task",
    CreateAgentAssistant = "create agent assistant",
    Deposit = "deposit",
    DepositAndSend = "deposit and send",
    EscalatePartialRefund = "escalate partial refund",
    EscalateToMerchantSupport = "escalate to merchant support",
    EscalateToSupportGroupLead = "escalate to support group lead",
    ForgetQr = "forget qr",
    FreezeFunds = "freeze funds",
    FinalizeFrozenFunds = "finalize frozen funds",
    LogoutAllUserSessions = "logout all user sessions",
    ManualTransferSuccess = "manual transfer success",
    MoveBalance = "move balance",
    OpenAgentBankAccountSurvey = "open agent bank account survey",
    PurchaseAirtime = "purchase airtime",
    RebalanceDeposit = "rebalance deposit",
    RebalanceWithdraw = "rebalance withdraw",
    RecoverPin = "recover pin",
    Refund = "refund",
    PartialRefund = "partial refund",
    RefundRejected = "refund rejected",
    RemittanceRefund = "remittance refund",
    RemoveAgentFromAgentGamingWatchlist = "remove agent from agent gaming watchlist",
    RemoveAgentFromAgentGamingWhitelist = "remove agent from agent gaming whitelist",
    RemoveUserFromAgentGamingWatchlist = "remove user from agent gaming watchlist",
    RemoveUserFromAgentGamingWhitelist = "remove user from agent gaming whitelist",
    RenameWallet = "rename wallet",
    ReportAgentIssue = "report agent issue",
    ReportAgentScam = "report agent scam",
    ReportB2wOrW2bCustomerProblem = "report b2w or w2b customer problem",
    ReportBillPayProblem = "report bill pay problem",
    ReportCustomerScam = "report customer scam",
    ReportPaymentCardTransferProblem = "report payment card transfer problem",
    ReportPaymentCardProblem = "report payment card problem",
    ReportRefundingDispute = "report refunding dispute",
    ReportToModifyBlock = "report to modify block",
    ReportUserComplaint = "report user complaint",
    RequestToExplainComissionCuts = "request to explain commission cuts",
    RequestToBlockUser = "request to block user",
    RequestToUnblockFromAgentDeposits = "request to unblock from agent deposits",
    RequestToUnblockFromUsingMultipleDevices = "request to unblock from using multiple devices",
    RequestToUnblockUser = "request to unblock user",
    ResetPin = "reset pin",
    SendAgentAppLink = "send agent app link",
    SendBusinessProposalFormLink = "send business proposal form link",
    SendCustomerAppLink = "send customer app link",
    SendMerchantAppLink = "send merchant app link",
    SendRequestToBeAnAgentLink = "send request to be an agent link",
    SendUpdateAppLink = "send update app link",
    SendWithdrawalCode = "send withdrawal code",
    SendWoyofalCode = "send woyofal code",
    ShowAgentNetwork = "show agent's network",
    ShowSmsCode = "show sms code",
    ShowWithdrawalCode = "show withdrawal code",
    ShowWoyofalCodes = "show woyofal codes",
    SubmitNoActionSurvey = "submit no action survey",
    TerminateUser = "Terminate User",
    TransferCallBackoffice = "transfer call backoffice",
    TransferCallFraud = "transfer call fraud",
    UnblockDevice = "unblock device",
    UnblockFromAgentDeposits = "unblock from agent deposits",
    UnblockFromUsingMultipleDevices = "unblock from using multiple devices",
    UnblockFromUsingQrCards = "unblock from using qr cards",
    UnblockUser = "unblock user",
    UnblockUserFromDormancy = "unblock user from dormancy",
    UnfreezeFunds = "unfreeze funds",
    UnlinkFromLegalEntity = "unlink from legal entity",
    UpdateAgentOnAgentGamingWatchlist = "update agent on agent gaming watchlist",
    Withdraw = "withdraw",
}
