import { useCallback, useMemo } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { useCurrentCall } from "@/hooks/useCurrentCall";
import { useFlag } from "@/hooks/useFlag";
import { usePermissions } from "@/hooks/usePermissions";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_PERFORM_RISKY_PIN_RESET } from "@/types/Permissions";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isDigit } from "@/utils/mobile";

import { ResetPinMutationResponse } from "./__generated__/ResetPinMutation.graphql";
import { ResetPin_wallet } from "./__generated__/ResetPin_wallet.graphql";

const mutation = graphql`
    mutation ResetPinMutation($userId: ID!, $pin: String!) {
        resetPin(userId: $userId, pin: $pin) {
            hadPinBefore
        }
    }
`;

type ResetPinInputParams = {
    wallet: ResetPin_wallet;
};

const _ResetPinMutator = (props: RelayModalProps & ResetPinInputParams) => {
    const { onHide, wallet } = props;
    const { t } = useTranslation();
    const pinField = useTextField();
    const isCustomer = !(wallet.user == null);

    const { currentCall } = useCurrentCall();
    const isVoipCall = currentCall.callType != "none" && currentCall.isVoip;
    const allowVOIPVerification = useFlag(
        "tmp-allow-voip-verification"
    )?.isActive;
    const showRiskyPinResetWarning =
        useFlag("show-risky-pin-reset-warning")?.isActive ?? false;
    const canPerformRiskyPinReset = usePermissions(CAN_PERFORM_RISKY_PIN_RESET);
    const isPinResetRisky = props.wallet.user?.isPinResetRisky ?? false;

    const onMutationSuccess = useCallback(
        (response: ResetPinMutationResponse): string => {
            const hadPinBefore = response.resetPin?.hadPinBefore;
            return hadPinBefore ? t("reset-pin--success") : t("reset-pin--new");
        },
        [t]
    );
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ResetPin,
            data: {},
        },
        ...props,
    });

    const pinValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        const newPin = pinField.value;
        if (!newPin.split("").every(isDigit)) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-pin"),
                display: "always",
            });
        } else if (newPin.length !== 4) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-pin"),
                display: newPin.length > 4 ? "always" : "if-blurred",
            });
        }
        return validations;
    }, [t, pinField.value]);

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            userId: wallet.user?.id,
            pin: pinField.value,
        });
    }, [mutator, wallet, pinField]);

    const riskyPinResetWarning = (
        <Alert variant="warning">
            {t("risky-pin-reset--be-extra-careful")}
        </Alert>
    );

    let content;
    if (isPinResetRisky && !canPerformRiskyPinReset) {
        content = (
            <Alert variant="danger">
                {t("risky-pin-reset--insufficient-permissions")}
            </Alert>
        );
    } else if (isCustomer) {
        content = (
            <Form
                {...mutator}
                isValid={!hasError([pinValidations])}
                onSubmit={handleSubmit}
                onDone={onHide}
                submitText={t("reset-pin--submit")}
            >
                {showRiskyPinResetWarning && isPinResetRisky
                    ? riskyPinResetWarning
                    : null}
                {allowVOIPVerification && isVoipCall ? (
                    <Alert variant="warning">
                        <span className="whitespace-pre">
                            {t("voip-verification")}
                        </span>
                    </Alert>
                ) : null}
                <TextField
                    {...pinField}
                    label={t("label-new-pin")}
                    name="pin"
                    validations={pinValidations}
                    autoFocus
                />
            </Form>
        );
    } else {
        content = (
            <Alert variant="danger">{t("reset-pin--unregistered")}</Alert>
        );
    }

    return (
        <MutatorModal
            {...mutator}
            title={t("reset-pin--title", { name: wallet.name })}
        >
            {content}
        </MutatorModal>
    );
};

export const ResetPinMutator = createFragmentContainer(_ResetPinMutator, {
    wallet: graphql`
        fragment ResetPin_wallet on Wallet {
            name
            user {
                id
                isPinResetRisky
            }
        }
    `,
});
