/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BlockRecipientMutationVariables = {
    transferId: string;
};
export type BlockRecipientMutationResponse = {
    readonly blockRecipient: {
        readonly transfer: {
            readonly id: string;
            readonly recipientMobile: string;
        } | null;
    } | null;
};
export type BlockRecipientMutation = {
    readonly response: BlockRecipientMutationResponse;
    readonly variables: BlockRecipientMutationVariables;
};



/*
mutation BlockRecipientMutation(
  $transferId: ID!
) {
  blockRecipient(transferId: $transferId) {
    transfer {
      id
      recipientMobile
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transferId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "transferId",
        "variableName": "transferId"
      }
    ],
    "concreteType": "BlockRecipient",
    "kind": "LinkedField",
    "name": "blockRecipient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "P2PTransfer",
        "kind": "LinkedField",
        "name": "transfer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "recipientMobile",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlockRecipientMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlockRecipientMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5abc749d74ad3ddfc409a74810be9da4",
    "id": null,
    "metadata": {},
    "name": "BlockRecipientMutation",
    "operationKind": "mutation",
    "text": "mutation BlockRecipientMutation(\n  $transferId: ID!\n) {\n  blockRecipient(transferId: $transferId) {\n    transfer {\n      id\n      recipientMobile\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ccde36f6bd2bff020e23b3fdf2ae47c3';
export default node;
