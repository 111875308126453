/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PaymentCardTransferEntry_tx = {
    readonly amount: string;
    readonly summary: string | null;
    readonly description: string;
    readonly transferId: string;
    readonly mobile: string;
    readonly baseReceiptFields: ReadonlyArray<{
        readonly formatType: string | null;
        readonly label: string;
        readonly value: string;
    }> | null;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "PaymentCardTransferEntry_tx";
};
export type PaymentCardTransferEntry_tx$data = PaymentCardTransferEntry_tx;
export type PaymentCardTransferEntry_tx$key = {
    readonly " $data"?: PaymentCardTransferEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PaymentCardTransferEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentCardTransferEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiptField",
      "kind": "LinkedField",
      "name": "baseReceiptFields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formatType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "PaymentCardTransferEntry",
  "abstractKey": null
};
(node as any).hash = '0f558410be2921e718a1c97ea5e3b57a';
export default node;
