import { graphql } from "react-relay";

export const historyEntryFragment = graphql`
    fragment AgentHistoryEntry_entry on HistoryEntry {
        id
        whenEntered
        ... on HistoryEntry {
            amount
            balance
            summary
            isPending
            isCancelled
        }
        ... on SupportHistoryEntry {
            scamInvestigationFields {
                deviceId
                deviceModel
                deviceName
                userInterface
                agentMobile
            }
        }
        ... on AgentTransactionEntry {
            agentTransactionId
        }
        ... on TransferSentEntry {
            transferId
        }
    }
`;

export const delegateFragment = graphql`
    fragment AgentHistoryEntryDelegate_entry on HistoryEntry {
        __typename
        ...AgentHistoryEntry_entry
        ...AgentHistoryAtxEntry_tx
    }
`;

export const txFragment = graphql`
    fragment AgentHistoryAtxEntry_tx on AgentTransactionEntry {
        agentTransactionId
        currency
        isDeposit
        amount
        customerName
        customerMobile
        maybeTerminatedCustomerMobile
        agentId
        agentName
        agentUfid
        agentUserName
        subagentId
        subagentName
        subagentUfid
        type
        isBusinessWalletAtx
        merchantUfid
        merchantId
        walletAgentUfid
        walletAgentId
        isCancelled
        composingEntries {
            amount
            summary
            isPending
            isCancelled
            whenEntered
            ... on AgentTransactionEntry {
                agentTransactionId
                isDeposit
            }
        }
        relatedTicket {
            status
            slackMessageUrl
        }
        scamReports {
            whenOpened
            whenResolved
            status
            transactionIds
        }
        ...AgentHistoryEntry_entry
    }
`;
