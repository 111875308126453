import { NavigationLink } from "./NavigationLink";

export const MerchantProfileLink = (props: {
    merchantId: string;
    name: string;
    opaqueId?: string;
}) => {
    if (!props.merchantId) {
        return <>{props.name}</>;
    }
    return (
        <NavigationLink
            id={props.merchantId}
            tab="merchant"
            opaqueId={props.opaqueId}
        >
            {props.name}
        </NavigationLink>
    );
};
