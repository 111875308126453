import { useTranslation } from "react-i18next";

import { UserInfoHeader_walletData } from "@/pages/mobile.[mobile]/__generated__/UserInfoHeader_walletData.graphql";

import { formatCurrencyAmount } from "@/utils/currency";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

const getKycTier = (
    wallet: PersonalTabHeader_wallet | UserInfoHeader_walletData
) => {
    try {
        const s = wallet.kycTier || "";
        const tier = s[s.length - 1];

        if (tier === "0" && wallet.hasRejectedGovIds && wallet.user) {
            // Special case in UG for blocked KYC0 users
            return "0b";
        }
        return tier;
    } catch {
        return "";
    }
};

export const KycBadge = (props: {
    wallet: PersonalTabHeader_wallet | UserInfoHeader_walletData;
}) => {
    const { t } = useTranslation();

    const kycDescription = (max_bal: string, max_inflow: string): string => {
        return t("kyc-badge--title", {
            balance: formatCurrencyAmount(max_bal),
            inflow: formatCurrencyAmount(max_inflow),
        });
    };

    const wallet = props.wallet;
    const kycTier = getKycTier(wallet);
    const isKyc0UserWithRejectedGovIds = kycTier === "0b";

    return (
        <span
            className={`text-capitalize badge ${
                isKyc0UserWithRejectedGovIds ? "badge-danger" : "badge-primary"
            }`}
            title={
                wallet.balanceLimitInfoV1 && wallet.inflowLimitInfoV1
                    ? kycDescription(
                          wallet.balanceLimitInfoV1.max,
                          wallet.inflowLimitInfoV1.max
                      )
                    : ""
            }
        >
            {wallet.isTrader
                ? wallet.kybTier
                : t("kyc-badge--description", {
                      tier: kycTier,
                  })}
        </span>
    );
};
