/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TransferReceivedReversalEntry_tx = {
    readonly transferId: string;
    readonly description: string;
    readonly isPending: boolean;
    readonly isFreezingFunds: boolean;
    readonly ledgerTransactionId: string;
    readonly senderMobile: string;
    readonly sendAmount: string;
    readonly recipientMobile: string;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "TransferReceivedReversalEntry_tx";
};
export type TransferReceivedReversalEntry_tx$data = TransferReceivedReversalEntry_tx;
export type TransferReceivedReversalEntry_tx$key = {
    readonly " $data"?: TransferReceivedReversalEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TransferReceivedReversalEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransferReceivedReversalEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPending",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFreezingFunds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ledgerTransactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "TransferReceivedReversalEntry",
  "abstractKey": null
};
(node as any).hash = 'adbcc73c988ec215f91df1a5f634bb28';
export default node;
