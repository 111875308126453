import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { DropdownField, useDropdownField } from "@/components/DropdownField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { CreateAgentTask_agent } from "./__generated__/CreateAgentTask_agent.graphql";

const mutation = graphql`
    mutation CreateAgentTaskMutation($agentCustomTask: AgentCustomTaskInput!) {
        createAgentCustomTask(agentCustomTask: $agentCustomTask) {
            visitTask {
                assignedTo {
                    name
                }
                ... on AgentCustomTask {
                    description
                }
            }
        }
    }
`;

type CreateAgentTaskInputParams = {
    agent: CreateAgentTask_agent;
};

const _CreateAgentTask = (
    props: CreateAgentTaskInputParams & RelayModalProps
) => {
    const { onHide, agent } = props;
    const agentIdAndName = `${agent.ufid} - ${agent.name}`;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response) => {
            if (response.createAgentCustomTask.visitTask) {
                const description =
                    response.createAgentCustomTask.visitTask.description;
                const staffMemberName =
                    response.createAgentCustomTask.visitTask.assignedTo?.name;

                return t("create-agent-task--success", {
                    description,
                    agentName: agentIdAndName,
                    staffName: staffMemberName,
                });
            }
            return "";
        },
        [t, agentIdAndName]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.CreateAgentTask,
            data: { agentUfid: agent.ufid },
        },
        ...props,
    });

    const descriptionField = useTextField();
    const priorityField = useDropdownField();
    const assignToField = useDropdownField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            agentCustomTask: {
                agentId: agent.id,
                staffMemberId: assignToField.dropdownItem?.value,
                description: descriptionField.value,
                priority: priorityField.dropdownItem?.value,
            },
        });
    }, [
        mutator,
        agent.id,
        descriptionField.value,
        priorityField.dropdownItem,
        assignToField.dropdownItem,
    ]);

    const descriptionValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!descriptionField.value.trim()) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-description"),
                display: "always",
            });
        }

        return validations;
    }, [t, descriptionField.value]);

    const priorityValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!priorityField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-priority"),
                display: "none",
            });
        }

        return validations;
    }, [t, priorityField.dropdownItem]);

    const assignToValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!assignToField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-territory-lead"),
                display: "none",
            });
        }

        return validations;
    }, [t, assignToField.dropdownItem]);

    const isValid = useMemo(() => {
        return !(
            hasError([descriptionValidations]) ||
            hasError([priorityValidations]) ||
            hasError([assignToValidations])
        );
    }, [descriptionValidations, priorityValidations, assignToValidations]);

    const priorityTypes = useMemo(() => {
        return ["HIGH", "LOW", "MEDIUM", "URGENT"].map((type) => {
            return {
                value: type,
                displayName: t(`priority-${type.toLowerCase()}`),
                optGroup: "Priority",
            };
        });
    }, [t]);

    const territoryLeadOptions = useMemo(() => {
        return (
            agent.territoryLeads.map((staffMember) => {
                return {
                    value: staffMember.id,
                    displayName: staffMember.name,
                    optGroup: "Territory Leads",
                };
            }) || []
        );
    }, [agent.territoryLeads]);

    return (
        <MutatorModal
            {...mutator}
            title={t("create-agent-task--title", { name: agentIdAndName })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-create")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...descriptionField}
                    label={t("label-description")}
                    name="description"
                    validations={descriptionValidations}
                    defaultValue=""
                    autoFocus
                />
                <DropdownField
                    {...priorityField}
                    label={t("label-priority")}
                    name="mobile"
                    validations={priorityValidations}
                    values={priorityTypes}
                    placeholder={t("placeholder-priority")}
                />
                <DropdownField
                    {...assignToField}
                    label={t("label-assign-to")}
                    name="mobile"
                    validations={assignToValidations}
                    values={territoryLeadOptions}
                    placeholder={t("placeholder-assign-to")}
                />
            </Form>
        </MutatorModal>
    );
};
export const CreateAgentTaskMutator = createFragmentContainer(
    _CreateAgentTask,
    {
        agent: graphql`
            fragment CreateAgentTask_agent on Agent {
                id
                ufid
                name
                territoryLeads {
                    id
                    name
                }
            }
        `,
    }
);
