/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BlockSubject = "DEFAULT" | "DEVICE" | "LEGAL_ENTITY" | "MOBILE" | "%future added value";
export type BlockUnblockUser_wallet = {
    readonly id: string;
    readonly mobile: string;
    readonly activeMobileBlock: {
        readonly id: string;
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
    } | null;
    readonly activeLegalEntityBlock: {
        readonly id: string;
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
    } | null;
    readonly blockHistory: ReadonlyArray<{
        readonly whenBlocked: string;
        readonly blockedBy: string;
        readonly blockReason: string;
        readonly whenUnblocked: string | null;
        readonly unblockedBy: string | null;
        readonly unblockReason: string | null;
        readonly blockSubject: BlockSubject | null;
    }>;
    readonly " $refType": "BlockUnblockUser_wallet";
};
export type BlockUnblockUser_wallet$data = BlockUnblockUser_wallet;
export type BlockUnblockUser_wallet$key = {
    readonly " $data"?: BlockUnblockUser_wallet$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BlockUnblockUser_wallet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockSubject",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlockUnblockUser_wallet",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeMobileBlock",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeLegalEntityBlock",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "blockHistory",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v2/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenUnblocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockReason",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
})();
(node as any).hash = 'cfecc198d8c8886279f81ccb015cbeed';
export default node;
