/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AgentGamingListOperation = "ADD" | "REMOVE" | "%future added value";
export type ModifyAgentGamingUserWatchlistMutationVariables = {
    mobile: string;
    operation: AgentGamingListOperation;
    comment?: string | null | undefined;
};
export type ModifyAgentGamingUserWatchlistMutationResponse = {
    readonly modifyAgentGamingUserWatchlist: {
        readonly success: boolean;
    } | null;
};
export type ModifyAgentGamingUserWatchlistMutation = {
    readonly response: ModifyAgentGamingUserWatchlistMutationResponse;
    readonly variables: ModifyAgentGamingUserWatchlistMutationVariables;
};



/*
mutation ModifyAgentGamingUserWatchlistMutation(
  $mobile: String!
  $operation: AgentGamingListOperation!
  $comment: String
) {
  modifyAgentGamingUserWatchlist(mobile: $mobile, operation: $operation, comment: $comment) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "operation"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "operation",
        "variableName": "operation"
      }
    ],
    "concreteType": "ModifyAgentGamingUserWatchlist",
    "kind": "LinkedField",
    "name": "modifyAgentGamingUserWatchlist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModifyAgentGamingUserWatchlistMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModifyAgentGamingUserWatchlistMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9a654f1c295d55adc32ef7f4f4f09f6d",
    "id": null,
    "metadata": {},
    "name": "ModifyAgentGamingUserWatchlistMutation",
    "operationKind": "mutation",
    "text": "mutation ModifyAgentGamingUserWatchlistMutation(\n  $mobile: String!\n  $operation: AgentGamingListOperation!\n  $comment: String\n) {\n  modifyAgentGamingUserWatchlist(mobile: $mobile, operation: $operation, comment: $comment) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a17c14721fd1739574c153e9fa4ac7e8';
export default node;
