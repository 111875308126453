/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type useUpdateCurrentCallQueryVariables = {};
export type useUpdateCurrentCallQueryResponse = {
    readonly currentPhoneCall: {
        readonly id: string;
        readonly theirTelnum: string | null;
        readonly opaqueId: string;
        readonly whenStarted: string;
        readonly whenConnected: string | null;
        readonly callbackForPhoneCallRecord: {
            readonly opaqueId: string;
            readonly whenStarted: string;
        } | null;
        readonly transferForPhoneCallRecord: {
            readonly opaqueId: string;
            readonly repName: string | null;
        } | null;
        readonly theirRoute: string | null;
    } | null;
};
export type useUpdateCurrentCallQuery = {
    readonly response: useUpdateCurrentCallQueryResponse;
    readonly variables: useUpdateCurrentCallQueryVariables;
};



/*
query useUpdateCurrentCallQuery {
  currentPhoneCall {
    id
    theirTelnum
    opaqueId
    whenStarted
    whenConnected
    callbackForPhoneCallRecord {
      opaqueId
      whenStarted
      id
    }
    transferForPhoneCallRecord {
      opaqueId
      repName
      id
    }
    theirRoute
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "theirTelnum",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "opaqueId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenStarted",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenConnected",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "repName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "theirRoute",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateCurrentCallQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhoneCall",
        "kind": "LinkedField",
        "name": "currentPhoneCall",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneCall",
            "kind": "LinkedField",
            "name": "callbackForPhoneCallRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneCall",
            "kind": "LinkedField",
            "name": "transferForPhoneCallRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useUpdateCurrentCallQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PhoneCall",
        "kind": "LinkedField",
        "name": "currentPhoneCall",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneCall",
            "kind": "LinkedField",
            "name": "callbackForPhoneCallRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneCall",
            "kind": "LinkedField",
            "name": "transferForPhoneCallRecord",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58a347178f606b414e26c68332190e53",
    "id": null,
    "metadata": {},
    "name": "useUpdateCurrentCallQuery",
    "operationKind": "query",
    "text": "query useUpdateCurrentCallQuery {\n  currentPhoneCall {\n    id\n    theirTelnum\n    opaqueId\n    whenStarted\n    whenConnected\n    callbackForPhoneCallRecord {\n      opaqueId\n      whenStarted\n      id\n    }\n    transferForPhoneCallRecord {\n      opaqueId\n      repName\n      id\n    }\n    theirRoute\n  }\n}\n"
  }
};
})();
(node as any).hash = '650a5d57a235952bb24bf5f6dfe3c262';
export default node;
