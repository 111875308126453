/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserInterface = "AGENT_PORTAL" | "API" | "AUTOMATIC" | "BUSINESS_PORTAL" | "CUSTOMER_APP_IOS" | "DISTRIBUTOR_APP" | "FRONTAPP" | "INTEGRATIONS_RECONCILIATION_PORTAL" | "KIOSK_NFC" | "KIOSK_QR" | "MERCHANT_QR" | "SLACK_TOOL" | "SMARTPHONE_APP" | "USSD" | "%future added value";
export type AgentHistoryEntry_entry = {
    readonly id: string;
    readonly whenEntered: string;
    readonly amount: string;
    readonly balance: string;
    readonly summary: string | null;
    readonly isPending: boolean;
    readonly isCancelled: boolean;
    readonly scamInvestigationFields?: {
        readonly deviceId: string | null;
        readonly deviceModel: string | null;
        readonly deviceName: string | null;
        readonly userInterface: UserInterface | null;
        readonly agentMobile: string | null;
    } | null | undefined;
    readonly agentTransactionId?: string | undefined;
    readonly transferId?: string | undefined;
    readonly " $refType": "AgentHistoryEntry_entry";
};
export type AgentHistoryEntry_entry$data = AgentHistoryEntry_entry;
export type AgentHistoryEntry_entry$key = {
    readonly " $data"?: AgentHistoryEntry_entry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentHistoryEntry_entry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentHistoryEntry_entry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenEntered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPending",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScamInvestigationFields",
          "kind": "LinkedField",
          "name": "scamInvestigationFields",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceModel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deviceName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userInterface",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "agentMobile",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "SupportHistoryEntry",
      "abstractKey": "__isSupportHistoryEntry"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "agentTransactionId",
          "storageKey": null
        }
      ],
      "type": "AgentTransactionEntry",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transferId",
          "storageKey": null
        }
      ],
      "type": "TransferSentEntry",
      "abstractKey": null
    }
  ],
  "type": "HistoryEntry",
  "abstractKey": "__isHistoryEntry"
};
(node as any).hash = 'd3c23f42b283ed47e199465e6da372dd';
export default node;
