import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";

import { RefundMerchantSaleMutationResponse } from "./__generated__/RefundMerchantSaleMutation.graphql";
import { RefundMerchantSale_transfer } from "./__generated__/RefundMerchantSale_transfer.graphql";

const mutation = graphql`
    mutation RefundMerchantSaleMutation($transferId: ID!) {
        refundMerchantSale(transferId: $transferId) {
            transfer {
                id
            }
        }
    }
`;

type RefundMerchantSaleInputParams = {
    transfer: RefundMerchantSale_transfer;
};

const _RefundMerchantSaleMutator = (
    props: RelayModalProps & RefundMerchantSaleInputParams
) => {
    const { onHide, transfer } = props;
    const amount = M.fromSerialized(transfer.grossAmount);

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: RefundMerchantSaleMutationResponse): string | null => {
            if (response) return t("refund--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.Refund,
            data: { transferId: transfer.maybeTransferId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            transferId: transfer.maybeTransferId,
        });
    }, [mutator, transfer]);

    return (
        <MutatorModal
            {...mutator}
            title={t("refund--title", {
                amount,
                name: transfer.maybeSenderName,
            })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-refund")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <p>
                    {t("refund--description", {
                        amount,
                        name: transfer.maybeSenderName,
                        mobile: transfer.maybeSenderMobile,
                    })}
                </p>
            </Form>
        </MutatorModal>
    );
};
export const RefundMerchantSaleMutator = createFragmentContainer(
    _RefundMerchantSaleMutator,
    {
        transfer: graphql`
            fragment RefundMerchantSale_transfer on MerchantSaleEntry {
                id
                maybeTransferId: transferId
                maybeSenderName: senderName
                maybeSenderMobile: senderMobile
                grossAmount
            }
        `,
    }
);
