import { useCallback } from "react";
import { ButtonGroup, ButtonToolbar, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import { graphql } from "relay-runtime";

import { ActionButton } from "@/components/ActionButton";

import { Flex } from "@/external/flex";

import { EscalateToMerchantSupportMutator } from "@/pages/mobile.[mobile].merchant/EscalateToMerchantSupport";
import { AwardCreditMutator } from "@/pages/mobile.[mobile].personal/AwardCredit";
import { CallUserMutator } from "@/pages/mobile.[mobile].personal/CallUser";
import { SendRequestToBeAnAgentLinkMutator } from "@/pages/mobile.[mobile].personal/SendRequestToBeAnAgentLink";
import { ShowSMSCodeMutator } from "@/pages/mobile.[mobile].personal/ShowSMSCode";
import { BlockUnblockUserMutator } from "@/pages/mobile.[mobile]/BlockUnblockUser";
import { RequestToBlockUnblockUserMutator } from "@/pages/mobile.[mobile]/RequestToBlockUnblockUser";
import { SendBusinessProposalFormLinkMutator } from "@/pages/mobile.[mobile]/SendBusinessProposalFormLink";
import { SendCustomerAppLinkMutator } from "@/pages/mobile.[mobile]/SendCustomerAppLink";

import { M } from "@/utils/currency";

import { NoUserPanel_unregisteredMobile } from "./__generated__/NoUserPanel_unregisteredMobile.graphql";
import { ProfileByMobileQueryResponse } from "./__generated__/ProfileByMobileQuery.graphql";

import NotesTab from "../mobile.[mobile].notes";
import { UnregisteredMobileInfoHeader } from "./UnregisteredMobileInfoHeader";

type NoUserPanelProps = {
    unregisteredMobile: NoUserPanel_unregisteredMobile;
    supportCallerMetrics?: ProfileByMobileQueryResponse["supportCallerMetrics"];
};

function _NoUserPanel(props: NoUserPanelProps) {
    const reload = useCallback(() => {
        location.reload();
    }, []);
    const { t } = useTranslation();

    const { unregisteredMobile, supportCallerMetrics } = props;
    if (!unregisteredMobile) {
        return <>{t("no-user-panel--error-registered")}</>;
    }
    const { mobile, activeBlock } = unregisteredMobile;
    const currency = M.deduceCurrencyFromMobile(mobile);
    const hasActiveBlockUnblockRequestTickets =
        (activeBlock?.requestToModifyBlockTickets || []).filter(
            (t) => t?.status == "OPEN"
        ).length > 0;
    const hasActiveBlock = !!unregisteredMobile.activeBlock;

    return (
        <>
            <UnregisteredMobileInfoHeader
                mobile={unregisteredMobile.mobile}
                unregisteredMobile={unregisteredMobile}
                supportCallerMetrics={supportCallerMetrics}
            />
            <ButtonToolbar className="m-2">
                <ButtonGroup>
                    <ActionButton
                        name={
                            activeBlock
                                ? t("no-user-panel--action-unblock")
                                : t("no-user-panel--action-block")
                        }
                        requiredPermissions={[
                            activeBlock
                                ? "supportapp_unblock_user"
                                : "supportapp_block_user",
                        ]}
                    >
                        <BlockUnblockUserMutator
                            wallet={null}
                            unregisteredMobile={unregisteredMobile}
                            byLegalEntity={false}
                            unblock={false}
                        />
                    </ActionButton>
                    <ActionButton
                        name="Credit"
                        onHide={reload}
                        requiredPermissions={["supportapp_award_credit"]}
                    >
                        <AwardCreditMutator
                            mobile={mobile}
                            name={mobile}
                            balance={new M(currency, 0)}
                        />
                    </ActionButton>
                    <ActionButton
                        name={t("no-user-panel--action-sms")}
                        requiredPermissions={["supportapp_show_sms_code"]}
                    >
                        <ShowSMSCodeMutator mobile={mobile} />
                    </ActionButton>
                    <ActionButton
                        name={t("no-user-panel--action-call")}
                        requiredPermissions={["supportapp_call_user"]}
                        disabled={Flex.isEmbedded}
                    >
                        <CallUserMutator
                            name={mobile}
                            customerMobile={mobile}
                        />
                    </ActionButton>
                    <ActionButton
                        name={t("no-user-panel--action-send-app-link")}
                        requiredPermissions={[
                            "supportapp_send_customer_app_link",
                        ]}
                    >
                        <SendCustomerAppLinkMutator
                            name={mobile}
                            mobile={mobile}
                        />
                    </ActionButton>
                    <ActionButton
                        name={t("no-user-panel--action-request-agent")}
                        requiredPermissions={[
                            "supportapp_send_request_to_be_an_agent_link",
                        ]}
                    >
                        <SendRequestToBeAnAgentLinkMutator mobile={mobile} />
                    </ActionButton>
                    <ActionButton
                        name={t("no-user-panel--action-send-business-link")}
                        requiredPermissions={[
                            "supportapp_send_business_proposal_form_link",
                        ]}
                    >
                        <SendBusinessProposalFormLinkMutator
                            name={mobile}
                            mobile={mobile}
                        />
                    </ActionButton>
                    <DropdownButton
                        title={t("no-user-panel--escalate")}
                        id="user-dropdown-escalate"
                        variant="outline-dark"
                    >
                        <ActionButton
                            as="menuitem"
                            disabled={
                                hasActiveBlockUnblockRequestTickets ||
                                hasActiveBlock
                            }
                            name={`${t(
                                "no-user-panel--action-request-block"
                            )} ${
                                hasActiveBlockUnblockRequestTickets
                                    ? t(
                                          "no-user-panel--action-request-block--submitted"
                                      )
                                    : ""
                            }${
                                hasActiveBlock
                                    ? t(
                                          "no-user-panel--action-request-block--already-blocked"
                                      )
                                    : ""
                            }`}
                            requiredPermissions={[
                                "supportapp_request_to_block_wallet",
                            ]}
                        >
                            <RequestToBlockUnblockUserMutator
                                unregisteredMobile={unregisteredMobile}
                                wallet={null}
                            />
                        </ActionButton>
                        <ActionButton
                            as="menuitem"
                            disabled={hasActiveBlock}
                            name={t("no-user-panel--action-block-user")}
                            requiredPermissions={["supportapp_block_user"]}
                        >
                            <BlockUnblockUserMutator
                                unregisteredMobile={unregisteredMobile}
                                wallet={null}
                                unblock={false}
                                byLegalEntity={false}
                            />
                        </ActionButton>
                        <ActionButton
                            as="menuitem"
                            name={t("escalate-merchant-support--action")}
                            requiredPermissions={[
                                "supportapp_escalate_to_merchant_support",
                            ]}
                        >
                            <EscalateToMerchantSupportMutator
                                mobile={unregisteredMobile.mobile}
                                user={null}
                            />
                        </ActionButton>
                    </DropdownButton>
                </ButtonGroup>
            </ButtonToolbar>
            <NotesTab mobile={mobile} />
        </>
    );
}

export const NoUserPanel = createFragmentContainer(_NoUserPanel, {
    unregisteredMobile: graphql`
        fragment NoUserPanel_unregisteredMobile on UnregisteredMobile {
            ...BlockUnblockUser_unregisteredMobile
            ...RequestToBlockUnblockUser_unregisteredMobile
            ...UnregisteredMobileInfoHeader_unregisteredMobile
            ...UnregisteredMobileBlocks_blockData
            mobile
            activeBlock {
                blockReason
                blockedBy
                whenBlocked
                blockSubject
                requestToModifyBlockTickets {
                    status
                }
            }
            blockHistory {
                whenBlocked
                blockedBy
                blockReason
                whenUnblocked
                unblockedBy
                unblockReason
                blockSubject
            }
            tickets {
                whenOpened
                whenResolved
                status
                slackMessageUrl
                ticketType
                reference
            }
        }
    `,
});
