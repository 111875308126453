import { useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
    Environment,
    QueryRenderer,
    fetchQuery,
    graphql,
    useRelayEnvironment,
} from "react-relay";

import {
    CurrencyField,
    useCurrencyField,
} from "../../components/CurrencyField";
import { Form } from "../../components/Form";
import { MutatorModal } from "../../components/MutatorModal";
import { ProfileSearchField } from "../../components/ProfileSearchField";

import { AnalyticsData } from "@/external/analytics";
import { mutate } from "@/external/relay";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";
import { useIdempotencyKey } from "@/hooks/useIdempotencyKey";
import { usePermissions } from "@/hooks/usePermissions";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_HISTORY } from "@/types/Permissions";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";
import { sleep } from "@/utils/time";

import { PurchaseAirtimeHistoryQuery } from "./__generated__/PurchaseAirtimeHistoryQuery.graphql";
import { PurchaseAirtimeMutation } from "./__generated__/PurchaseAirtimeMutation.graphql";
import { PurchaseAirtimeQuery } from "./__generated__/PurchaseAirtimeQuery.graphql";

const mutation = graphql`
    mutation PurchaseAirtimeMutation(
        $bill_amount: Money
        $client_id: String!
        $bill_fields: [BillFieldInput]!
        $sender_u_id: ID!
    ) {
        supportPurchaseAirtime(
            billAmount: $bill_amount
            clientId: $client_id
            fields: $bill_fields
            senderUId: $sender_u_id
        ) {
            response {
                __typename
                ... on AsyncPending {
                    clientId
                }
                ... on PayBill {
                    payment {
                        whenCreated
                    }
                    userFacingError {
                        code
                        message
                    }
                }
            }
        }
    }
`;

const refetchHistoryQuery = graphql`
    query PurchaseAirtimeHistoryQuery($id: ID!) {
        user(id: $id) {
            primaryWallet {
                id
                balance
                ...History_wallet @arguments(last: 5)
            }
        }
    }
`;

async function purchaseAirtime(
    relayEnvironment: Environment,
    sender_u_id: string,
    mobile: string,
    amount: M,
    client_id: string,
    t: TFunction,
    trackEvent: (key: string, data?: AnalyticsData | undefined) => void
) {
    const attempt = () =>
        mutate<PurchaseAirtimeMutation>(relayEnvironment, mutation, {
            bill_amount: amount.serialize(),
            client_id: client_id,
            bill_fields: [{ name: "phone_number", value: mobile }],
            sender_u_id: sender_u_id,
        });

    let numAttemptsLeft = 5;
    while (numAttemptsLeft > 0) {
        const [{ supportPurchaseAirtime }, payloadErrors] = await attempt();

        if (payloadErrors) return payloadErrors;

        if (supportPurchaseAirtime?.response.__typename == "PayBill") {
            const ufe = supportPurchaseAirtime?.response.userFacingError;
            if (ufe) throw Error(`${ufe.code}: ${ufe.message}`);

            trackEvent(Action.PurchaseAirtime, {
                amount,
                senderUid: sender_u_id,
                clientId: client_id,
            });

            return t("purchase-airtime--success");
        }

        numAttemptsLeft--;
        if (numAttemptsLeft > 0) await sleep(1000);
    }

    throw Error(t("purchase-airtime--error"));
}

type PurchaseAirtimeInputparams = {
    wallet: Exclude<PurchaseAirtimeQuery["response"]["wallet"], null>;
};

const _PurchaseAirtimeMutator = (
    props: PurchaseAirtimeInputparams & RelayModalProps
) => {
    const trackEvent = useTrackEvent();
    const environment = useRelayEnvironment();
    const { wallet, onHide } = props;
    const balance = M.fromSerialized(wallet.balance);
    const canReadHistory = usePermissions(CAN_READ_HISTORY);

    const { t } = useTranslation();

    const amountField = useCurrencyField();
    const [recipientMobile, setRecipientMobile] = useState<string | undefined>(
        undefined
    );

    const client_id = useIdempotencyKey([
        amountField.value?.serialize(),
        recipientMobile,
    ]);

    const airtimeRequest = useAsyncFunction(async () => {
        if (!props.wallet.user || !recipientMobile || !amountField.value) {
            return;
        }
        try {
            return await purchaseAirtime(
                environment,
                props.wallet.user.id,
                recipientMobile,
                amountField.value,
                client_id,
                t,
                trackEvent
            );
        } finally {
            if (canReadHistory) {
                fetchQuery<PurchaseAirtimeHistoryQuery>(
                    environment,
                    refetchHistoryQuery,
                    { id: props.wallet.user.id }
                )
                    .toPromise()
                    .then(() => {
                        /*noop*/
                    }); // <-- get rid of editor warning
            }
        }
    });

    const amountValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!amountField.value) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-amount"),
                display: "none",
            });
        } else {
            if (!amountField.value?.isPositive()) {
                validations.push({
                    type: "error",
                    message: t("form-validation--negative-amount"),
                    display: "if-blurred",
                });
            } else if (amountField.value?.scalar.greaterThan(balance.scalar)) {
                validations.push({
                    type: "error",
                    message: t("form-validation--amount-exceeds-balance"),
                    display: "if-blurred",
                });
            } else {
                validations.push({
                    type: "none",
                    message: t("purchase-airtime--help", {
                        name: wallet.name,
                        balance: balance.subtract(amountField.value),
                    }),
                    display: "always",
                });
            }
        }
        // write the validaitons in here - check that amount is less than bal
        return validations;
    }, [t, balance, wallet.name, amountField.value]);

    const mobileValidations: Validation[] = recipientMobile
        ? []
        : [
              {
                  type: "error",
                  message: t("form-validation--missing-recipient"),
                  display: "none",
              },
          ];

    const isValid = !hasError([amountValidations, mobileValidations]);

    const airtimeResultText =
        typeof airtimeRequest.result === "string"
            ? airtimeRequest.result
            : null;
    const airtimeErrors = Array.isArray(airtimeRequest.result)
        ? airtimeRequest.result
        : airtimeRequest.error
        ? [airtimeRequest.error]
        : null;

    return (
        <MutatorModal
            title={t("purchase-airtime--title", { name: wallet.name })}
            isWorking={airtimeRequest.isLoading}
            errors={airtimeErrors}
            show={props.show}
            onHide={props.onHide}
        >
            <p>{t("purchase-airtime--current-balance", { balance })}</p>
            <Form
                result={airtimeResultText}
                errors={airtimeErrors}
                isWorking={airtimeRequest.isLoading}
                isValid={isValid}
                submitText={t("action-buy")}
                onSubmit={airtimeRequest.invoke}
                onDone={onHide}
            >
                <ProfileSearchField
                    initialQuery={props.wallet.mobile}
                    onChange={(profile) =>
                        setRecipientMobile(
                            profile?.kind === "Agent"
                                ? undefined
                                : profile?.mobile
                        )
                    }
                    label={t("label-airtime-recipient")}
                    allowWallets={true}
                    allowUnregistered={true}
                    allowAgents={false}
                    allowMerchants={false}
                    allowTerminated
                    name="mobile"
                    validations={mobileValidations}
                />
                <CurrencyField
                    {...amountField}
                    label={t("label-amount")}
                    currency={balance.currency}
                    name="amount"
                    validations={amountValidations}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};

const purchaseAirtimeQuery = graphql`
    query PurchaseAirtimeQuery($mobile: String!) {
        wallet(mobile: $mobile) {
            balance
            name
            mobile
            country
            user {
                id
            }
        }
    }
`;

export const PurchaseAirtimeMutator = ({
    mobile,
    show,
    onHide,
}: { mobile: string } & RelayModalProps) => {
    const environment = useRelayEnvironment();
    const { t } = useTranslation();
    return (
        <QueryRenderer<PurchaseAirtimeQuery>
            environment={environment}
            query={purchaseAirtimeQuery}
            render={({ error, props }) => {
                if (error) {
                    return <div>{error.message}</div>;
                }
                if (props && props.wallet) {
                    return (
                        <_PurchaseAirtimeMutator
                            wallet={props.wallet}
                            show={show}
                            onHide={onHide}
                        />
                    );
                }
                return <div>{t("loading")}</div>;
            }}
            variables={{ mobile }}
        />
    );
};
