/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateBlock_activeBlock = {
    readonly id: string;
    readonly blockReason: string;
    readonly " $refType": "UpdateBlock_activeBlock";
};
export type UpdateBlock_activeBlock$data = UpdateBlock_activeBlock;
export type UpdateBlock_activeBlock$key = {
    readonly " $data"?: UpdateBlock_activeBlock$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UpdateBlock_activeBlock">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateBlock_activeBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockReason",
      "storageKey": null
    }
  ],
  "type": "ActiveBlock",
  "abstractKey": null
};
(node as any).hash = '22a47df25b0cc543f7638ab736e48bff';
export default node;
