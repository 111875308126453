import { Fragment } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { ProfileLink } from "@/components/ProfileLink";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { PayoutTransferReverseMutator } from "@/pages/mobile.[mobile].merchant/SupportPayoutTransferReverse";
import { ChangePayoutRecipientMutator } from "@/pages/mobile.[mobile].personal/ChangePayoutRecipient";
import HistoryEntry, {
    HistoryEntryProps,
} from "@/pages/mobile.[mobile]/HistoryEntry";

import { CAN_REFUND_BULK_TRANSFER } from "@/types/Permissions";

import { PayoutTransferSentEntry_tx } from "./__generated__/PayoutTransferSentEntry_tx.graphql";

function _PayoutTransferSentEntry(
    props: { tx: PayoutTransferSentEntry_tx } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx, reversedTransfers } = props;
    const whenReversed = reversedTransfers[tx.tcid];
    const isReversed = !!whenReversed;
    const hasSupportAppRefundPermission = usePermissions(
        CAN_REFUND_BULK_TRANSFER
    );
    const hasSupportAppChangeRecipientPermission = usePermissions(
        "supportapp_change_recipient"
    );

    const showRefundOrChangeButtons = !tx.isReversal && !isReversed;

    const refundOrChangeRecipientButtons = () => {
        return (
            <Fragment>
                <HistoryActionButton
                    name={t("refund--action")}
                    disabled={!hasSupportAppRefundPermission}
                >
                    <PayoutTransferReverseMutator transfer={tx} />
                </HistoryActionButton>
                <HistoryActionButton
                    name={t("change-recipient--action")}
                    disabled={!hasSupportAppChangeRecipientPermission}
                >
                    <ChangePayoutRecipientMutator transfer={tx} />
                </HistoryActionButton>
            </Fragment>
        );
    };

    const info = <TxInfo>{tx.tcid}</TxInfo>;

    return (
        <>
            <HistoryEntry
                {...props}
                entry={tx}
                title={
                    <Fragment>
                        <TxTitle>
                            {tx.isReversal
                                ? t("payout-sent-reversed-entry--title")
                                : t("payout-sent-entry--title")}
                            <ProfileLink
                                name={tx.maybeRecipientName || ""}
                                mobile={tx.recipientMobile}
                                opaqueId={tx.tcid}
                            />
                        </TxTitle>
                        {info}
                    </Fragment>
                }
                buttons={
                    showRefundOrChangeButtons &&
                    refundOrChangeRecipientButtons()
                }
            />
        </>
    );
}

export default createFragmentContainer(_PayoutTransferSentEntry, {
    tx: graphql`
        fragment PayoutTransferSentEntry_tx on PayoutTransferEntry {
            tcid
            senderOpaqueId
            amount
            maybeRecipientName: recipientName
            recipientMobile
            isReversal
            ...HistoryEntry_entry
            ...SupportPayoutTransferReverse_transfer
            ...ChangePayoutRecipient_transfer
        }
    `,
});
