/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PayoutTransferSentEntry_tx = {
    readonly tcid: string;
    readonly senderOpaqueId: string | null;
    readonly amount: string;
    readonly maybeRecipientName: string | null;
    readonly recipientMobile: string;
    readonly isReversal: boolean;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "SupportPayoutTransferReverse_transfer" | "ChangePayoutRecipient_transfer">;
    readonly " $refType": "PayoutTransferSentEntry_tx";
};
export type PayoutTransferSentEntry_tx$data = PayoutTransferSentEntry_tx;
export type PayoutTransferSentEntry_tx$key = {
    readonly " $data"?: PayoutTransferSentEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PayoutTransferSentEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PayoutTransferSentEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tcid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderOpaqueId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": "maybeRecipientName",
      "args": null,
      "kind": "ScalarField",
      "name": "recipientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isReversal",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SupportPayoutTransferReverse_transfer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangePayoutRecipient_transfer"
    }
  ],
  "type": "PayoutTransferEntry",
  "abstractKey": null
};
(node as any).hash = '0931362b26432453b7d47bac5a32d7b4';
export default node;
