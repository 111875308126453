/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ReportBillPayProblemMutationVariables = {
    mobile: string;
    transactionId?: string | null | undefined;
    comments: string;
};
export type ReportBillPayProblemMutationResponse = {
    readonly reportBillPayProblem: {
        readonly result: string | null;
    } | null;
};
export type ReportBillPayProblemMutation = {
    readonly response: ReportBillPayProblemMutationResponse;
    readonly variables: ReportBillPayProblemMutationVariables;
};



/*
mutation ReportBillPayProblemMutation(
  $mobile: String!
  $transactionId: ID
  $comments: String!
) {
  reportBillPayProblem(mobile: $mobile, transactionId: $transactionId, comments: $comments) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comments"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comments",
        "variableName": "comments"
      },
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "transactionId",
        "variableName": "transactionId"
      }
    ],
    "concreteType": "ReportBillPayProblem",
    "kind": "LinkedField",
    "name": "reportBillPayProblem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportBillPayProblemMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportBillPayProblemMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "258a919f7cc2881ce455ecf4cc3c0cde",
    "id": null,
    "metadata": {},
    "name": "ReportBillPayProblemMutation",
    "operationKind": "mutation",
    "text": "mutation ReportBillPayProblemMutation(\n  $mobile: String!\n  $transactionId: ID\n  $comments: String!\n) {\n  reportBillPayProblem(mobile: $mobile, transactionId: $transactionId, comments: $comments) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd821e11d93634a24a511f7ec9d1e6199';
export default node;
