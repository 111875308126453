import { useEffect, useState } from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { SendUpdateAppLinkMutator } from "@/pages/mobile.[mobile].personal/SendUpdateAppLink";
import { UserInfoHeader_walletData } from "@/pages/mobile.[mobile]/__generated__/UserInfoHeader_walletData.graphql";

import {
    AppType,
    PersonalTabHeader_wallet,
} from "./__generated__/PersonalTabHeader_wallet.graphql";

export const CustomerAppStatusBadge = (props: {
    wallet: PersonalTabHeader_wallet | UserInfoHeader_walletData;
}) => {
    const [text, setText] = useState("");
    const [enabledText, setEnabledText] = useState("");
    const [variant, setVariant] = useState<BadgeProps["variant"]>("primary");
    const [isDisabled, setDisabled] = useState(true);
    const { wallet } = props;
    const { user } = wallet;

    const { t } = useTranslation();

    const updateAppLinkAllowed = usePermissions(
        "supportapp_send_update_app_link"
    );

    useEffect(() => {
        function appDescription(appType: AppType): string {
            if (appType == "CUSTOMER_APP_IOS") {
                return t("app-iphone");
            } else if (appType == "CUSTOMER_APP_ANDROID") {
                return t("app-android");
            } else {
                return t("app-unknown", { appType });
            }
        }
        if (!user) {
            setText(t("customer-app-status--no-account"));
            setEnabledText(t("customer-app-status--send-app-link"));
            setVariant("danger");
            setDisabled(false);
        } else if (!user.customerAppVersion && !user.hasQrCard) {
            setText(t("customer-app-status--no-app-no-qr"));
            setVariant("danger");
            setDisabled(true);
        } else if (!user.customerAppVersion) {
            setText(t("customer-app-status--no-app"));
            setEnabledText(t("customer-app-status--send-app-link"));
            setVariant("danger");
            setDisabled(false);
        } else if (user.customerAppVersion.isUpToDate) {
            setText(
                t("customer-app-status--user", {
                    description: appDescription(
                        user.customerAppVersion.appType
                    ).toUpperCase(),
                    version: user.customerAppVersion.string,
                })
            );
        } else {
            setText(
                t("customer-app-status--user", {
                    description: appDescription(
                        user.customerAppVersion.appType
                    ).toUpperCase(),
                    version: user.customerAppVersion.string,
                })
            );
            setEnabledText(t("customer-app-status--send-update"));
            setVariant("warning");
            setDisabled(false);
        }
    }, [t, user, setText, setEnabledText, setVariant, setDisabled]);

    if (isDisabled || !updateAppLinkAllowed) {
        return (
            <Badge className="text-capitalize" variant={variant}>
                {text}
            </Badge>
        );
    }

    return (
        <>
            <ActionButton
                name={text.concat(enabledText)}
                as="badge"
                badgeVariant={variant}
            >
                <SendUpdateAppLinkMutator wallet={wallet} />
            </ActionButton>
        </>
    );
};
