import { ReactNode, useRef } from "react";
import { Button as BSButton } from "react-bootstrap";
import { ButtonProps as BSButtonProps } from "react-bootstrap/Button";

type NewButtonProps = {
    children: ReactNode;
    onClick: (ev: any) => void;
};

/** Wrapper around react-bootstrap to stop the button from getting an ugly
 * glow around it when clicked.
 *
 * The glow is an important accessibility feature, so we don't want to turn
 * it off. The problem is that the button becomes "focused" when clicked and
 * the :focus style includes the glow. When you don't override the button's
 * :focus style, Chrome does some sorcery to make the glow only appear when
 * you tab to the button, not when you click it. :(
 *
 * As a hack, when the button is clicked, we give focus to an element
 * inside the button, which makes <tab> still do the right thing
 * afterwards, but doesn't cause the button to register as focused.
 *
 * TODO: Unfortunately, the blur is still visible while the button is being pressed/depressed.
 * If you have time and inspiration try to fix this using :active / :hover / :focus CSS accessors
 * or using other means.
 *
 */
export const Button = (props: NewButtonProps & BSButtonProps) => {
    const focusStealer = useRef<HTMLElement>(null);
    const oldOnClick = props.onClick;
    return (
        <BSButton
            {...props}
            onClick={(event) => {
                focusStealer.current?.focus();
                oldOnClick(event);
            }}
        >
            {props.children}
            <span ref={focusStealer} tabIndex={-1} />
        </BSButton>
    );
};
