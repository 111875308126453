/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AgentIssueType = "AGENT_BOYCOTTING" | "AGENT_NEEDS_TRAINING" | "CHARGING_EXTRA_FEES" | "CHARGING_EXTRA_FEES_FOR_SIGNUP" | "CHARGING_EXTRA_FEES_FOR_TRANSACTIONS" | "CLOSED_DURING_BUSINESS_HOURS" | "CLOSED_PERMANENTLY" | "NEEDS_BANK_REBALANCE" | "NEEDS_BANNER" | "NEEDS_CASH" | "NEEDS_CASH_PICKUP" | "NEEDS_FLYERS" | "NEEDS_QR_CARDS" | "NEEDS_SHARED_AGENT" | "NOT_ENOUGH_CASH_FOR_WITHDRAWAL" | "NOT_ENOUGH_FLOAT_FOR_DEPOSIT" | "OTHER" | "OTHER_AGENT_REQUEST" | "OTHER_USER_COMPLAINT" | "REDIRECTING_TO_COMPETITION" | "REFUSING_TO_DO_SMALL_TRANSACTIONS" | "REQUEST_AGENT_REACTIVATION" | "REQUEST_AGENT_SUSPENSION" | "REQUEST_AGENT_TERMINATION" | "USER_COMPLAINT_LOW_LIQUIDITY" | "%future added value";
export type AgentTrainingTopic = "COMMISSION_STRUCTURE" | "DE_CAPPING" | "GAMING" | "OTHER" | "REBALANCING_BETWEEN_AGENTS" | "REBALANCING_BY_BANK" | "SIGN_UP_WITHOUT_ID" | "TRANSFERS" | "TRANSFER_TO_SN" | "WITHDRAWAL_BY_CODE" | "%future added value";
export type ReportAgentIssueMutationVariables = {
    agentId: string;
    description?: string | null | undefined;
    issueType: AgentIssueType;
    reportingCustomerMobile?: string | null | undefined;
    trainingTopic?: AgentTrainingTopic | null | undefined;
    trainingTarget?: string | null | undefined;
};
export type ReportAgentIssueMutationResponse = {
    readonly reportAgentIssue: {
        readonly success: boolean;
    } | null;
};
export type ReportAgentIssueMutation = {
    readonly response: ReportAgentIssueMutationResponse;
    readonly variables: ReportAgentIssueMutationVariables;
};



/*
mutation ReportAgentIssueMutation(
  $agentId: ID!
  $description: String
  $issueType: AgentIssueType!
  $reportingCustomerMobile: String
  $trainingTopic: AgentTrainingTopic
  $trainingTarget: String
) {
  reportAgentIssue(agentId: $agentId, description: $description, issueType: $issueType, reportingCustomerMobile: $reportingCustomerMobile, trainingTopic: $trainingTopic, trainingTarget: $trainingTarget) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reportingCustomerMobile"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "trainingTarget"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "trainingTopic"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "agentId",
        "variableName": "agentId"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "issueType",
        "variableName": "issueType"
      },
      {
        "kind": "Variable",
        "name": "reportingCustomerMobile",
        "variableName": "reportingCustomerMobile"
      },
      {
        "kind": "Variable",
        "name": "trainingTarget",
        "variableName": "trainingTarget"
      },
      {
        "kind": "Variable",
        "name": "trainingTopic",
        "variableName": "trainingTopic"
      }
    ],
    "concreteType": "ReportAgentIssue",
    "kind": "LinkedField",
    "name": "reportAgentIssue",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportAgentIssueMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportAgentIssueMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "b166fe9eb14071e72ec56c6fe7e14411",
    "id": null,
    "metadata": {},
    "name": "ReportAgentIssueMutation",
    "operationKind": "mutation",
    "text": "mutation ReportAgentIssueMutation(\n  $agentId: ID!\n  $description: String\n  $issueType: AgentIssueType!\n  $reportingCustomerMobile: String\n  $trainingTopic: AgentTrainingTopic\n  $trainingTarget: String\n) {\n  reportAgentIssue(agentId: $agentId, description: $description, issueType: $issueType, reportingCustomerMobile: $reportingCustomerMobile, trainingTopic: $trainingTopic, trainingTarget: $trainingTarget) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cbd90193ab5713fe682d4e0a1d36820d';
export default node;
