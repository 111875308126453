import { useTranslation } from "react-i18next";

import { HistoryActionButton } from "@/components/HistoryActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { UnfreezeTransactionMutator } from "@/pages/mobile.[mobile].personal/UnfreezeTransaction";

import { CAN_UNFREEZE_TRANSACTION } from "@/types/Permissions";

interface UnfreezeFundsHistoryActionButtonProps {
    transferOrLedgerTransactionId: string;
}
export const UnfreezeTransactionHistoryActionButton = ({
    transferOrLedgerTransactionId,
}: UnfreezeFundsHistoryActionButtonProps) => {
    const { t } = useTranslation();
    const canUnfreezeTransaction = usePermissions(CAN_UNFREEZE_TRANSACTION);

    return canUnfreezeTransaction ? (
        <HistoryActionButton name={t("unfreeze-transaction--action")}>
            <UnfreezeTransactionMutator
                transferOrLedgerTransactionId={transferOrLedgerTransactionId}
            />
        </HistoryActionButton>
    ) : null;
};
