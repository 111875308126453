import React from "react";
import { useTranslation } from "react-i18next";

export const WeakPinBadge = () => {
    const { t } = useTranslation();

    return (
        <span
            className="text-uppercase badge badge-warning"
            title={t("weak-pin-badge--title")}
        >
            {t("weak-pin-badge--description")}
        </span>
    );
};
