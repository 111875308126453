/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PurchaseAirtimeQueryVariables = {
    mobile: string;
};
export type PurchaseAirtimeQueryResponse = {
    readonly wallet: {
        readonly balance: string;
        readonly name: string;
        readonly mobile: string;
        readonly country: string;
        readonly user: {
            readonly id: string;
        } | null;
    } | null;
};
export type PurchaseAirtimeQuery = {
    readonly response: PurchaseAirtimeQueryResponse;
    readonly variables: PurchaseAirtimeQueryVariables;
};



/*
query PurchaseAirtimeQuery(
  $mobile: String!
) {
  wallet(mobile: $mobile) {
    balance
    name
    mobile
    country
    user {
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "mobile",
    "variableName": "mobile"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseAirtimeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Wallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchaseAirtimeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Wallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0d02bee98a1354f377824f75bd3705d",
    "id": null,
    "metadata": {},
    "name": "PurchaseAirtimeQuery",
    "operationKind": "query",
    "text": "query PurchaseAirtimeQuery(\n  $mobile: String!\n) {\n  wallet(mobile: $mobile) {\n    balance\n    name\n    mobile\n    country\n    user {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fbecec28d2bfc31cc6925fc9878d683d';
export default node;
