import { useCallback, useContext, useEffect, useState } from "react";

import { WebSocketContext } from "@/context/WebSocket";

export enum WsEventType {
    repHangUpCall = "repHangUpCall",
    repAnsweredCall = "repAnsweredCall",
    repLoggedIn = "repLoggedIn",
    repLoggedOut = "repLoggedOut",
    repMissedCall = "repMissedCall",
    repTransferredCall = "repTransferredCall",
}

export type RepHangUpCall = {
    repName: string;
    type: WsEventType.repHangUpCall;
    time: Date;
    callerMobile: string;
};

export type RepAnsweredCall = {
    repName: string;
    type: WsEventType.repAnsweredCall;
    time: Date;
    phoneCallId: string;
};
export type RepLoggedIn = {
    repName: string;
    type: WsEventType.repLoggedIn;
    time: Date;
    callerMobile: string;
};
export type RepLoggedOut = {
    repName: string;
    type: WsEventType.repLoggedOut;
    time: Date;
    callerMobile: string;
};
export type RepMissedCall = {
    repName: string;
    type: WsEventType.repMissedCall;
    time: Date;
    callerMobile: string;
};
export type RepTransferredCall = {
    repName: string;
    type: WsEventType.repTransferredCall;
    time: Date;
    callerMobile: string;
};

export type WsEvent =
    | RepAnsweredCall
    | RepHangUpCall
    | RepLoggedIn
    | RepLoggedOut
    | RepMissedCall
    | RepTransferredCall;

export const useWebSocket = <T extends WsEvent>(
    eventType: WsEventType
): T | undefined => {
    const ws = useContext(WebSocketContext);

    const [lastWsEvent, setLastWsEvent] = useState<T>();

    const parseData = (msg: MessageEvent): WsEvent => {
        const msg_data = msg.data.split("\n");
        return JSON.parse(msg_data[msg_data.length - 1]);
    };
    const onMessage = useCallback(
        (event: MessageEvent) => {
            const parsedData = parseData(event);

            if (parsedData.type == eventType) {
                setLastWsEvent(parsedData as T);
            }
        },
        [eventType]
    );

    useEffect(() => {
        if (ws) {
            ws.addEventListener("message", onMessage);

            return () => {
                ws.removeEventListener("message", onMessage);
            };
        }
    }, [ws, onMessage]);

    return lastWsEvent;
};
