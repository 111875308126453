/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ClearPinRateLimitAction_user = {
    readonly devices: ReadonlyArray<{
        readonly hasPinRateLimitBlock: boolean;
    }> | null;
    readonly " $fragmentRefs": FragmentRefs<"ClearPinRateLimit_user">;
    readonly " $refType": "ClearPinRateLimitAction_user";
};
export type ClearPinRateLimitAction_user$data = ClearPinRateLimitAction_user;
export type ClearPinRateLimitAction_user$key = {
    readonly " $data"?: ClearPinRateLimitAction_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ClearPinRateLimitAction_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClearPinRateLimitAction_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserDevice",
      "kind": "LinkedField",
      "name": "devices",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPinRateLimitBlock",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClearPinRateLimit_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'f879209ccad30232eaf6c8d1cb55e1be';
export default node;
