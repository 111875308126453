/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RefundP2PRecipientWalletQueryVariables = {
    mobile: string;
    canReadBalance: boolean;
    transferId: string;
};
export type RefundP2PRecipientWalletQueryResponse = {
    readonly wallet: {
        readonly mobile: string;
        readonly balance?: string | undefined;
        readonly amountPending?: string | null | undefined;
        readonly hasOutgoingTransactions: boolean | null;
        readonly activeMobileBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
        } | null;
        readonly activeLegalEntityBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
        } | null;
    } | null;
};
export type RefundP2PRecipientWalletQuery = {
    readonly response: RefundP2PRecipientWalletQueryResponse;
    readonly variables: RefundP2PRecipientWalletQueryVariables;
};



/*
query RefundP2PRecipientWalletQuery(
  $mobile: String!
  $canReadBalance: Boolean!
  $transferId: String!
) {
  wallet(mobile: $mobile) {
    mobile
    balance @include(if: $canReadBalance)
    amountPending @include(if: $canReadBalance)
    hasOutgoingTransactions(transferId: $transferId)
    activeMobileBlock {
      id
      blockReason
      blockedBy
      whenBlocked
      whenExpires
    }
    activeLegalEntityBlock {
      id
      blockReason
      blockedBy
      whenBlocked
      whenExpires
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "canReadBalance"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transferId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "mobile",
    "variableName": "mobile"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "transferId",
      "variableName": "transferId"
    }
  ],
  "kind": "ScalarField",
  "name": "hasOutgoingTransactions",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "blockReason",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "blockedBy",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenBlocked",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenExpires",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ActiveBlock",
  "kind": "LinkedField",
  "name": "activeMobileBlock",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ActiveBlock",
  "kind": "LinkedField",
  "name": "activeLegalEntityBlock",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "condition": "canReadBalance",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountPending",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RefundP2PRecipientWalletQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Wallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RefundP2PRecipientWalletQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Wallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v6/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8801bdc6f90da47447fddaa8bd1b6ef7",
    "id": null,
    "metadata": {},
    "name": "RefundP2PRecipientWalletQuery",
    "operationKind": "query",
    "text": "query RefundP2PRecipientWalletQuery(\n  $mobile: String!\n  $canReadBalance: Boolean!\n  $transferId: String!\n) {\n  wallet(mobile: $mobile) {\n    mobile\n    balance @include(if: $canReadBalance)\n    amountPending @include(if: $canReadBalance)\n    hasOutgoingTransactions(transferId: $transferId)\n    activeMobileBlock {\n      id\n      blockReason\n      blockedBy\n      whenBlocked\n      whenExpires\n    }\n    activeLegalEntityBlock {\n      id\n      blockReason\n      blockedBy\n      whenBlocked\n      whenExpires\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bd057d9d8b3e9884a47d6c2b8ee70fb4';
export default node;
