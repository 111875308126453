/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentIssueType = "AGENT_BOYCOTTING" | "AGENT_NEEDS_TRAINING" | "CHARGING_EXTRA_FEES" | "CHARGING_EXTRA_FEES_FOR_SIGNUP" | "CHARGING_EXTRA_FEES_FOR_TRANSACTIONS" | "CLOSED_DURING_BUSINESS_HOURS" | "CLOSED_PERMANENTLY" | "NEEDS_BANK_REBALANCE" | "NEEDS_BANNER" | "NEEDS_CASH" | "NEEDS_CASH_PICKUP" | "NEEDS_FLYERS" | "NEEDS_QR_CARDS" | "NEEDS_SHARED_AGENT" | "NOT_ENOUGH_CASH_FOR_WITHDRAWAL" | "NOT_ENOUGH_FLOAT_FOR_DEPOSIT" | "OTHER" | "OTHER_AGENT_REQUEST" | "OTHER_USER_COMPLAINT" | "REDIRECTING_TO_COMPETITION" | "REFUSING_TO_DO_SMALL_TRANSACTIONS" | "REQUEST_AGENT_REACTIVATION" | "REQUEST_AGENT_SUSPENSION" | "REQUEST_AGENT_TERMINATION" | "USER_COMPLAINT_LOW_LIQUIDITY" | "%future added value";
export type AgentTrainingTopic = "COMMISSION_STRUCTURE" | "DE_CAPPING" | "GAMING" | "OTHER" | "REBALANCING_BETWEEN_AGENTS" | "REBALANCING_BY_BANK" | "SIGN_UP_WITHOUT_ID" | "TRANSFERS" | "TRANSFER_TO_SN" | "WITHDRAWAL_BY_CODE" | "%future added value";
export type ReportAgentIssue_agentUser = {
    readonly agent: {
        readonly ufid: string;
        readonly name: string;
        readonly trainingTopics: ReadonlyArray<{
            readonly type: AgentTrainingTopic;
            readonly displayName: string;
        }> | null;
        readonly possibleIssueTypes: ReadonlyArray<{
            readonly type: AgentIssueType;
            readonly displayName: string;
            readonly displayGroupName: string | null;
        }>;
        readonly principal: {
            readonly id: string;
        };
        readonly agentUsers: ReadonlyArray<{
            readonly isActive: boolean;
            readonly user: {
                readonly id: string;
                readonly contactMobile: string;
                readonly name: string;
            };
        }> | null;
    };
    readonly " $refType": "ReportAgentIssue_agentUser";
};
export type ReportAgentIssue_agentUser$data = ReportAgentIssue_agentUser;
export type ReportAgentIssue_agentUser$key = {
    readonly " $data"?: ReportAgentIssue_agentUser$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ReportAgentIssue_agentUser">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportAgentIssue_agentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Agent",
      "kind": "LinkedField",
      "name": "agent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ufid",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentTrainingTopicEntry",
          "kind": "LinkedField",
          "name": "trainingTopics",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentIssueEntry",
          "kind": "LinkedField",
          "name": "possibleIssueTypes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayGroupName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "principal",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentUser",
          "kind": "LinkedField",
          "name": "agentUsers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "contactMobile",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AgentUser",
  "abstractKey": null
};
})();
(node as any).hash = 'a31db8f4c63ea942751af6fff93dce99';
export default node;
