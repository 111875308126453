import { Validation } from "@/types/FormValidation";

/** Get validation errors that should be shown to the user */
export const validationErrors = (
    isBlurred: boolean,
    validations: Validation[]
): Validation[] => {
    const shouldDisplay = (v: Validation): boolean =>
        v.type === "error" &&
        (v.display == "always" || (v.display == "if-blurred" && isBlurred));
    return validations.filter((v) => shouldDisplay(v));
};

/** Get validation warnings that should be shown to the user */
export const validationWarnings = (
    isBlurred: boolean,
    validations: Validation[]
): Validation[] => {
    const shouldDisplay = (v: Validation): boolean =>
        v.type != "error" &&
        (v.display == "always" || (v.display == "if-blurred" && isBlurred));
    return validations.filter((v) => shouldDisplay(v));
};

/**
 * Determines whether the form is in an error state given the supplied validation results.
 * If the result is *true* the form should be considered invalid.
 *
 * @param validationArrays an array of validation results that should be calculated
 * by the form's components.
 */
export function hasError(validationArrays: Array<Validation>[]): boolean {
    for (const validationArray of validationArrays) {
        for (const v of validationArray) {
            if (v.type == "error") {
                return true;
            }
        }
    }
    return false;
}
