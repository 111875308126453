import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ActionButton } from "../../components/ActionButton";

import { ClearPinRateLimit } from "@/pages/mobile.[mobile].personal/ClearPinRateLimit";

import { ClearPinRateLimitAction_user } from "./__generated__/ClearPinRateLimitAction_user.graphql";

type Props = {
    user: ClearPinRateLimitAction_user;
};
const _ClearPinRateLimitAction = (props: Props) => {
    const { user } = props;
    const { t } = useTranslation();

    const shouldEnable = user.devices?.some((d) => d.hasPinRateLimitBlock);

    return (
        <ActionButton
            name={t("clear-pin-rate-limit--action")}
            disabled={!shouldEnable}
            requiredPermissions={["supportapp_expire_pin_rate_limit_block"]}
        >
            <ClearPinRateLimit user={user} />
        </ActionButton>
    );
};

export const ClearPinRateLimitAction = createFragmentContainer(
    _ClearPinRateLimitAction,
    {
        user: graphql`
            fragment ClearPinRateLimitAction_user on User {
                devices {
                    hasPinRateLimitBlock
                }
                ...ClearPinRateLimit_user
            }
        `,
    }
);
