import {
    faBan,
    faIdBadge,
    faShoppingCart,
    faStore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { useNavigation } from "@/hooks/useNavigation";

import { getLanguageName } from "@/utils/language";

import { ProfileByMobileQueryResponse } from "./__generated__/ProfileByMobileQuery.graphql";
import { UserInfoHeader_walletData } from "./__generated__/UserInfoHeader_walletData.graphql";

import { Banners } from "./Banners";
import { WalletUserBlocks } from "./WalletUserBlocks";

interface UserInfoHeaderProps {
    walletData: UserInfoHeader_walletData;
    caller?: ProfileByMobileQueryResponse["caller"];
    supportCallerMetrics?: ProfileByMobileQueryResponse["supportCallerMetrics"];
}

type PreferredCallcenterLanguageBadgeProps = {
    language: string;
};

function PreferredCallcenterLanguageBadge({
    language,
}: PreferredCallcenterLanguageBadgeProps) {
    let styles = "";
    switch (language) {
        case "swa":
            styles = "text-gray-800 bg-amber-300";
            break;
        case "nyn":
            styles = "text-white bg-gray-500";
            break;
    }

    return (
        <span className={`badge ${styles}`}>{getLanguageName(language)}</span>
    );
}

export const _UserInfoHeader = (props: UserInfoHeaderProps) => {
    const { goToProfileByMobile } = useNavigation();
    const { caller, supportCallerMetrics } = props;
    const { legalEntity, mobile } = props.walletData;
    const walletUser = props.walletData["user"];

    const { t } = useTranslation();

    const legalEntityWallets = [];
    let hasAgentAccount = false,
        hasMerchantAccount = false;
    for (const w of legalEntity?.wallets || [props.walletData]) {
        legalEntityWallets.push({
            mobile: w?.mobile,
            isAgentWallet: w?.user?.agentUser?.isActive,
            isMerchantWallet: w?.user?.merchantUser?.isActive,
            isPromoterWallet: w?.user?.promoter?.canKioskSignup,
        });
        hasAgentAccount ||= w?.user?.agentUser?.isActive || false;
        hasMerchantAccount ||= w?.user?.merchantUser?.isActive || false;
    }

    const selector =
        legalEntityWallets.length > 1 ? (
            <>
                <DropdownButton variant="outline-secondary" title={mobile}>
                    {legalEntityWallets.map((wd, wdi) => (
                        <DropdownItem
                            onClick={(_) => goToProfileByMobile(wd.mobile)}
                            key={wdi}
                        >
                            {wd.mobile}
                            {wd.isAgentWallet && (
                                <>
                                    &nbsp;
                                    <FontAwesomeIcon icon={faStore} />
                                </>
                            )}
                            {wd.isMerchantWallet && (
                                <>
                                    &nbsp;
                                    <FontAwesomeIcon icon={faShoppingCart} />
                                </>
                            )}
                            {wd.isPromoterWallet && (
                                <>
                                    &nbsp;
                                    <FontAwesomeIcon icon={faIdBadge} />
                                </>
                            )}
                        </DropdownItem>
                    ))}
                </DropdownButton>
                <h6 id="mobile_and_email">{mobile}</h6>
            </>
        ) : (
            <h6 id="mobile_and_email" data-testid="user-info-header-mobile">
                {mobile}
            </h6>
        );

    const banners = <Banners banners={props.walletData} />;
    const userBlocks = <WalletUserBlocks blockData={props.walletData} />;

    const userBadges = (
        <div className="user-badges">
            {hasAgentAccount && (
                <Badge variant="info">
                    <FontAwesomeIcon
                        icon={faStore}
                        title={
                            props.walletData.user?.agentUser?.isActive
                                ? t("user-info--is-agent")
                                : t("user-info--has-agent-account")
                        }
                    />
                </Badge>
            )}
            {hasMerchantAccount && (
                <Badge variant="primary">
                    <FontAwesomeIcon
                        icon={faShoppingCart}
                        title={
                            props.walletData.user?.agentUser?.isActive
                                ? t("user-info--is-merchant")
                                : t("user-info--has-merchant-account")
                        }
                    />
                </Badge>
            )}
            {walletUser?.promoter?.canKioskSignup && (
                <Badge variant="warning">
                    <FontAwesomeIcon
                        icon={faIdBadge}
                        title={t("user-info--is-distributor")}
                        color="#fff"
                    />
                </Badge>
            )}
        </div>
    );

    const userInfo = (
        <div className="flex justify-center relative">
            <div className="flex flex-1 flex-col justify-center">
                {userBadges}
                <h4 id="name_header">
                    <span id="user_name">{props.walletData.name}</span>
                    {props.walletData?.user?.name &&
                    props.walletData.name !== props.walletData?.user?.name ? (
                        <span className="block text-sm">
                            {props.walletData?.user?.name}
                        </span>
                    ) : null}
                    {props.walletData.whenTerminated && (
                        <Badge variant="light">
                            <FontAwesomeIcon
                                icon={faBan}
                                title={
                                    t("user-info--terminated") +
                                    props.walletData.whoTerminated
                                }
                                color="red"
                            />
                        </Badge>
                    )}
                </h4>
                {selector}
            </div>

            {caller?.preferredCallcenterLanguage ||
            (supportCallerMetrics?.numberCallsToday &&
                supportCallerMetrics?.numberCallsToday > 1) ? (
                <div className="absolute top-0 right-4 flex flex-col gap-2">
                    {caller?.preferredCallcenterLanguage && (
                        <PreferredCallcenterLanguageBadge
                            language={caller.preferredCallcenterLanguage}
                        />
                    )}
                    {supportCallerMetrics?.numberCallsToday &&
                    supportCallerMetrics?.numberCallsToday > 1 ? (
                        <span className="badge badge-info">
                            {supportCallerMetrics?.numberCallsToday} calls today
                        </span>
                    ) : null}
                </div>
            ) : null}
        </div>
    );

    return (
        <div className="text-center">
            <div className="blocks-banners">
                {userBlocks}
                {banners}
            </div>
            {userInfo}
        </div>
    );
};

export const UserInfoHeader = createFragmentContainer(_UserInfoHeader, {
    walletData: graphql`
        fragment UserInfoHeader_walletData on Wallet
        @argumentDefinitions(canReadBalance: { type: "Boolean!" }) {
            id
            ...WalletUserBlocks_blockData
            ...Banners_banners
            name
            mobile
            whenTerminated
            reasonTerminated
            whoTerminated
            balance @include(if: $canReadBalance)
            amountPending @include(if: $canReadBalance)
            kycTier
            kybTier
            isTrader
            photoEditUrl
            documentStatus
            hasRejectedGovIds
            isRefundAbuser
            tickets {
                whenOpened
                whenResolved
                status
                slackMessageUrl
                ticketType
                reference
            }
            activeMobileBlock {
                whenBlocked
                blockSubject
                requestToModifyBlockTickets {
                    status
                }
            }
            activeLegalEntityBlock {
                whenBlocked
                blockSubject
                requestToModifyBlockTickets {
                    status
                }
            }
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            billPayProblems {
                whenOpened
                billType
                status
                transactionId
            }
            supportGroupLeadEscalations {
                whenOpened
                status
                clickupTaskId
            }
            merchantSupportEscalations {
                whenOpened
                status
                clickupTaskId
            }
            balanceLimitInfoV1 {
                max
            }
            inflowLimitInfoV1 {
                max
            }
            user {
                id
                name
                agentUser {
                    isActive
                }
                merchantUser {
                    isActive
                }
                promoter {
                    canKioskSignup
                }
                customerAppVersion {
                    appType
                    string
                    isUpToDate
                }
                hasQrCard
                customerOverdraftStatus
                qrCardBlock {
                    whenBlocked
                }
                personalMultipleDevicesBlock {
                    whenBlocked
                }
                agentMultipleDevicesBlock {
                    whenBlocked
                }
                agentDepositsBlock {
                    whenBlocked
                }
                needsIdPhotoConfirmation {
                    legalName
                }
                userOnAgentGamingWatchlist {
                    isOnList
                    whenUpdated
                }
                userOnAgentGamingWhitelist {
                    isOnList
                    whenUpdated
                }
                inPinRecovery
                hasWeakPin
                elligibleToReceivePayPromo
                ...AllowToRecoverPin_user
                ...UnblockFromUsingQrCards_user
                ...UnblockFromUsingMultipleDevices_user
                ...BlockFromAgentDeposits_user
                ...UnblockFromAgentDeposits_user
                ...LogoutAllUserSessions_user
                ...ForgetQr_user
                ...EscalateToMerchantSupport_user
            }
            legalEntity {
                wallets(walletType: PERSONAL) {
                    mobile
                    user {
                        agentUser {
                            isActive
                        }
                        merchantUser {
                            isActive
                        }
                        promoter {
                            canKioskSignup
                        }
                    }
                }
            }
            ...ShowWithdrawalCodes_wallet
            ...ShowWoyofalCodes_wallet
            ...ResetPin_wallet
            ...SendUpdateAppLink_wallet
            ...SendWithdrawalCode_wallet
            ...SendWoyofalCode_wallet
            ...RenameWallet_wallet
            ...CancelAllAtx_wallet
            ...RequestToBlockUnblockUser_wallet
            ...RequestToUnblockFromAgentDeposits_wallet
            ...RequestToUnblockFromUsingMultipleDevices_wallet
            ...BlockUnblockUser_wallet
        }
    `,
});
