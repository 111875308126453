import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { NavigationLink } from "@/components/NavigationLink";

import { notEmpty } from "@/utils/value";

import { AgentUsersList_agent } from "./__generated__/AgentUsersList_agent.graphql";

import { AgentAppStatus } from "./AgentAppStatus";

const _AgentUsersList = (props: { agent: AgentUsersList_agent }) => {
    const { t } = useTranslation();
    const { agentUsers: _agentUsers, principal } = props.agent;

    // relay freezes props; clone this so we can sort
    // sort so priority like principal, enabled assistants, temporarily disabled assistants, deactivated assistants.
    let agentUsers = _agentUsers?.filter(notEmpty)?.slice() ?? [];
    agentUsers = principal
        ? agentUsers.sort((a) => (a.user.id === principal.id ? -1 : 1))
        : agentUsers;
    agentUsers = agentUsers.sort((a, b) => {
        if (b === null) {
            return -1;
        }
        if (a === null) {
            return 1;
        }
        if (a.canLogin && !b.canLogin) {
            return -1;
        } else if (!a.canLogin && b.canLogin) {
            return 1;
        } else if (!a.canLogin && !b.canLogin) {
            if (a.isActive && !b.isActive) {
                return -1;
            } else if (!a.isActive && b.isActive) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    });

    return (
        <ListGroup>
            {agentUsers.map((au) => (
                <ListGroupItem key={au.user.id}>
                    <NavigationLink
                        mobile={au.user.contactMobile}
                        tab={
                            principal.id === au.user.id ? "personal" : undefined
                        }
                    >
                        {au.user.name}{" "}
                        {principal.id === au.user.id &&
                            t("agent-users-list--primary")}
                    </NavigationLink>
                    {!au.isActive && (
                        <span className="mx-1 badge badge-danger">
                            {t("agent-users-list--deactivated")}
                        </span>
                    )}
                    {au.isActive && !au.canLogin && (
                        <span className="mx-1 badge badge-warning">
                            {t("agent-users-list--cannot-login")}
                        </span>
                    )}
                    {au.isActive ? <AgentAppStatus agentUser={au} /> : <></>}
                    <span className="float-right">{au.user.contactMobile}</span>
                </ListGroupItem>
            ))}
        </ListGroup>
    );
};

export const AgentUsersList = createFragmentContainer(_AgentUsersList, {
    agent: graphql`
        fragment AgentUsersList_agent on Agent {
            principal {
                id
            }
            agentUsers {
                isActive
                canLogin
                user {
                    id
                    contactMobile
                    name
                }
                agentAppVersion {
                    appType
                    string
                    isUpToDate
                }
            }
        }
    `,
});
