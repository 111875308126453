/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfileSearcher_wallet = {
    readonly id: string;
    readonly mobile: string;
    readonly name: string;
    readonly balance: string;
    readonly sendFeeFormula: string | null;
    readonly whenTerminated: string | null;
};
export type ProfileSearcher_wallet$data = ProfileSearcher_wallet;
export type ProfileSearcher_wallet$key = {
    readonly " $data"?: ProfileSearcher_wallet$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfileSearcher_wallet">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "ProfileSearcher_wallet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendFeeFormula",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenTerminated",
      "storageKey": null
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
(node as any).hash = '69331abed0bcb23c39c7ccfc9d3c5727';
export default node;
