import { VoidFunctionComponent, useCallback, useEffect } from "react";

import { Flex } from "@/external/flex";

import { useCurrentCall } from "@/hooks/useCurrentCall";

import { FLEX_MESSAGE_TASK_INFO, TaskInfo } from "@/types/flex-comm-layer";

function assertUnreachable(_x: never): never {
    throw new Error("Unreachable code path");
}

export const FlexIntegration: VoidFunctionComponent = () => {
    const { setCurrentCall } = useCurrentCall();

    const handleTaskInfoUpdate = useCallback(
        (task: TaskInfo) => {
            const channel = task.support_channel;
            switch (channel) {
                case "whatsapp":
                    setCurrentCall({
                        callType: "whatsapp",
                        callId: task.conversationSid,
                        mobile: task.customerMobile,
                    });
                    break;
                case "voice":
                    setCurrentCall({
                        callType: "incoming",
                        callId: task.callId,
                        mobile: task.customerMobile,
                        isVoip: task.theirRoute === "twilio-voip",
                        isWrapping: task.status === "wrapping",
                    });
                    break;
                default:
                    assertUnreachable(channel);
            }
        },
        [setCurrentCall]
    );

    useEffect(() => {
        const listener = Flex.register((message) => {
            switch (message.action) {
                case FLEX_MESSAGE_TASK_INFO:
                    handleTaskInfoUpdate(message.taskInfo);
                    break;
            }
        });

        return () => {
            listener.unregister();
        };
    }, [handleTaskInfoUpdate]);

    useEffect(() => {
        // We do this after having registered the listener so that we are ready
        // to receive any buffered messages from flex.
        Flex.init();
    }, []);

    return null;
};
