/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnblockFromAgentDeposits_user = {
    readonly id: string;
    readonly agentDepositsBlock: {
        readonly whenBlocked: string;
        readonly blockedBy: string;
        readonly blockReason: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"AgentDepositsBlockHistory_user">;
    readonly " $refType": "UnblockFromAgentDeposits_user";
};
export type UnblockFromAgentDeposits_user$data = UnblockFromAgentDeposits_user;
export type UnblockFromAgentDeposits_user$key = {
    readonly " $data"?: UnblockFromAgentDeposits_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UnblockFromAgentDeposits_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnblockFromAgentDeposits_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "agentDepositsBlock",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenBlocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockReason",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgentDepositsBlockHistory_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'ffac66bd5c9541dca66b27607b5dfa01';
export default node;
