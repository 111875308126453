/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type TicketType = "AgentIssueReport" | "AgentScamReport" | "AmlMatch" | "B2WOrW2BCustomerProblem" | "BankRebalanceRequest" | "BillPayProblem" | "BusinessFundingRequest" | "KYBEscalation" | "MerchantComplianceFollowup" | "MerchantSupportEscalation" | "PaymentCardProblem" | "PoliceRequisition" | "RebalancingDifficulty" | "RefundPartialFunds" | "RefundingDispute" | "RequestToBlockWallet" | "RequestToExplainCommissionCuts" | "RequestToModifyBlockTicket" | "ScamReport" | "ScreeningResult" | "SpamCallerReport" | "SupportGroupLeadEscalation" | "%future added value";
export type UserAtxEntry_tx = {
    readonly agentTransactionId: string;
    readonly isDeposit: boolean;
    readonly agentName: string;
    readonly agentId: string;
    readonly agentUfid: string;
    readonly customerMobile: string | null;
    readonly customerName: string | null;
    readonly maybeTerminatedCustomerMobile: string | null;
    readonly amount: string;
    readonly relatedTicket: {
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly slackMessageUrl: string | null;
        readonly ticketType: TicketType;
        readonly reference: string | null;
    } | null;
    readonly scamReports: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly transactionIds: string | null;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "UserAtxEntry_tx";
};
export type UserAtxEntry_tx$data = UserAtxEntry_tx;
export type UserAtxEntry_tx$key = {
    readonly " $data"?: UserAtxEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserAtxEntry_tx">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenOpened",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenResolved",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserAtxEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentTransactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeposit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentUfid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedCustomerMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketShortInfo",
      "kind": "LinkedField",
      "name": "relatedTicket",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackMessageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reference",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamReportTicket",
      "kind": "LinkedField",
      "name": "scamReports",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "AgentTransactionEntry",
  "abstractKey": null
};
})();
(node as any).hash = '9ccdedf0ae7246933b9978ae5a137966';
export default node;
