import { useCallback, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { ProfileSearchField } from "../../components/ProfileSearchField";

import { usePermissions } from "@/hooks/usePermissions";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_HISTORY } from "@/types/Permissions";
import { Profile } from "@/types/ProfileSearcher";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";

import { ChangeRecipientMutationResponse } from "./__generated__/ChangeRecipientMutation.graphql";
import { ChangeRecipient_transfer } from "./__generated__/ChangeRecipient_transfer.graphql";

import { UserHistoryAroundTransfer } from "./UserHistoryAroundTransfer";

const mutation = graphql`
    mutation ChangeRecipientMutation(
        $transferId: ID!
        $newMobile: String!
        $canReadHistory: Boolean!
    ) {
        changeRecipient(transferId: $transferId, newMobile: $newMobile) {
            transfer {
                id
                user {
                    primaryWallet {
                        ...History_wallet @include(if: $canReadHistory)
                    }
                }
            }
        }
    }
`;

type ChangeRecipientInputParams = {
    transfer: ChangeRecipient_transfer;
};

const _ChangeRecipientMutator = (
    props: RelayModalProps & ChangeRecipientInputParams
) => {
    const { onHide, transfer } = props;
    const amount = M.fromSerialized(transfer.receiveAmount);
    const canReadHistory = usePermissions(CAN_READ_HISTORY);

    const [recipient, setRecipient] = useState<Profile | null>(null);
    const data = useMemo(() => ({ recipient }), [recipient]);
    const trackEvent = useTrackEvent();

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ChangeRecipientMutationResponse): string | null => {
            if (response) return t("change-recipient--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ChangeRecipient,
            data: { transferId: transfer.transferId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            transferId: transfer.transferId,
            newMobile: recipient?.mobile,
            canReadHistory,
        });
    }, [mutator, recipient, canReadHistory, transfer]);

    const recipientValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (data.recipient) {
            let message = "";

            if (!("wallet" in data.recipient)) {
                message = t("change-recipient--first-time");
            } else {
                const name =
                    data.recipient.wallet?.name || data.recipient.mobile;

                if (data.recipient.wallet?.balance) {
                    const newBalance = amount
                        .add(data.recipient.wallet.balance)
                        .toString();
                    message = t("change-recipient--new-balance", {
                        name,
                        balance: newBalance,
                    });
                }
            }

            validations.push({
                type: "none",
                message,
                display: "always",
            });
        } else {
            validations.push({
                type: "error",
                message: t("form-validation--missing-mobile"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [t, data.recipient, amount]);

    const isValid = useMemo(() => {
        return !hasError([recipientValidations]);
    }, [recipientValidations]);

    return (
        <MutatorModal
            {...mutator}
            title={t("change-recipient--title", { amount: amount.toString() })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("change-recipient--submit")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <ProfileSearchField
                    autoFocus
                    allowUnregistered
                    allowMerchants
                    allowWallets
                    allowTerminated
                    label={t("label-mobile")}
                    name="recipient"
                    allowAgents={false}
                    validations={recipientValidations}
                    onChange={(profile) => {
                        if (profile) setRecipient(profile as Profile);
                    }}
                />
                <UserHistoryAroundTransfer
                    mobile={transfer.recipientMobile}
                    name={transfer.recipientName}
                    transferId={transfer.transferId}
                    first={1}
                    last={20}
                    listWrapperClassName="max-h-[40vh]"
                    listClassName="max-h-[40vh] overflow-auto"
                />
                <Button
                    onClick={() => {
                        trackEvent(Action.ChangeRecipientRejected, {
                            transferId: transfer.transferId,
                        });
                        onHide?.();
                    }}
                    variant="danger"
                    className="float-left"
                >
                    {t("refund--reject")}
                </Button>
            </Form>
        </MutatorModal>
    );
};

export const ChangeRecipientMutator = createFragmentContainer(
    _ChangeRecipientMutator,
    {
        transfer: graphql`
            fragment ChangeRecipient_transfer on MobileTransferEntry {
                id
                transferId
                amount
                receiveAmount
                recipientMobile
                recipientName
            }
        `,
    }
);
