import { useEffect } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";

import { Analytics } from "@/external/analytics";

import { useCountry } from "@/hooks/useCountry";
import { useNavigation } from "@/hooks/useNavigation";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { COUNTRIES, Country } from "@/types/country";

type CountryButtonDropdownItemProps = {
    country: string;
    description: Country;
};

export const _CountryButtonDropdownItem = ({
    country,
    description,
}: CountryButtonDropdownItemProps) => {
    const { updateCountry } = useCountry();
    const { goToPage } = useNavigation();
    const trackEvent = useTrackEvent();

    return (
        <Dropdown.Item
            onClick={() => {
                // make sure we are tracking callId - Analytics.context is not persisted
                // between page loads. TODO: refactor this component to useMutator so that
                // this is done properly
                Analytics.addContext({ country });
                trackEvent("change country");
                // if we switch country, we also go back to the starting page, since the current profile can't be displayed in the new country
                goToPage("/");
                updateCountry(country);
            }}
            data-testid="country-button-dropdown-item"
        >
            {description?.flag} ({description?.localPrefix}){" "}
            {description?.name || country}
        </Dropdown.Item>
    );
};

export const CountryButton = () => {
    const { country, countries } = useCountry();
    const countryDescription = COUNTRIES[country.toUpperCase()];

    useEffect(() => {
        Analytics.addContext({ country });
    }, [country]);

    if (countries.length > 1) {
        return (
            <DropdownButton
                title={`${countryDescription?.flag} ${
                    countryDescription?.name || country
                }`}
                className="ml-2"
                variant="outline-info"
            >
                {countries
                    .filter((c) => c !== country)
                    .map((c) => {
                        const description = COUNTRIES[c.toUpperCase()];
                        return (
                            <_CountryButtonDropdownItem
                                key={c}
                                country={c}
                                description={description}
                            />
                        );
                    })}
            </DropdownButton>
        );
    } else {
        return (
            <Button className="ml-2" variant="outline-info">
                {countryDescription?.flag} {countryDescription?.name || country}
            </Button>
        );
    }
};
