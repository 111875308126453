import { Fragment } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { MerchantProfileLink } from "@/components/MerchantProfileLink";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { ChangePayoutRecipientMutator } from "@/pages/mobile.[mobile].personal/ChangePayoutRecipient";

import { CAN_REFUND_BULK_TRANSFER } from "@/types/Permissions";

import { PayoutTransferEntry_tx } from "./__generated__/PayoutTransferEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { PayoutTransferReverseMutator } from "./SupportPayoutTransferReverse";

function _PayoutTransferEntry(
    props: { tx: PayoutTransferEntry_tx } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx } = props;
    const hasSupportAppRefundPermission = usePermissions(
        CAN_REFUND_BULK_TRANSFER
    );
    const hasSupportAppChangeRecipientPermission = usePermissions(
        "supportapp_change_recipient"
    );

    const buttons = tx.isReversal ? null : (
        <Fragment>
            <HistoryActionButton
                name={t("refund--action")}
                disabled={!hasSupportAppRefundPermission}
            >
                <PayoutTransferReverseMutator transfer={tx} />
            </HistoryActionButton>
            <HistoryActionButton
                name={t("change-recipient--action")}
                disabled={!hasSupportAppChangeRecipientPermission}
            >
                <ChangePayoutRecipientMutator transfer={tx} />
            </HistoryActionButton>
        </Fragment>
    );

    const info = <TxInfo>{tx.tcid}</TxInfo>;

    return (
        <>
            <HistoryEntry
                {...props}
                entry={tx}
                title={
                    <Fragment>
                        <TxTitle>
                            {tx.isReversal
                                ? t("payout-received-reversed-entry--title")
                                : t("payout-received-entry--title")}
                            {tx.senderOpaqueId ? (
                                <MerchantProfileLink
                                    name={tx.senderName}
                                    merchantId={tx.senderOpaqueId}
                                    opaqueId={tx.tcid}
                                />
                            ) : (
                                tx.senderName
                            )}
                        </TxTitle>
                        {info}
                    </Fragment>
                }
                buttons={buttons}
            />
        </>
    );
}

export default createFragmentContainer(_PayoutTransferEntry, {
    tx: graphql`
        fragment PayoutTransferEntry_tx on PayoutTransferEntry {
            tcid
            amount
            senderOpaqueId
            senderName
            recipientMobile
            isReversal
            ...HistoryEntry_entry
            ...SupportPayoutTransferReverse_transfer
            ...ChangePayoutRecipient_transfer
        }
    `,
});
