import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { RequestToBlockUnblockUserMutator } from "@/pages/mobile.[mobile]/RequestToBlockUnblockUser";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const RequestToUnblockUserAction = (props: {
    wallet: PersonalTabHeader_wallet;
}) => {
    const { t } = useTranslation();

    const wallet = props.wallet;
    const walletBlock =
        wallet.activeMobileBlock || wallet.activeLegalEntityBlock;

    const pendingUnblockRequestTickets = (
        walletBlock?.requestToModifyBlockTickets || []
    ).filter((t) => t?.status == "OPEN");

    const canRequestToBlock =
        !walletBlock && pendingUnblockRequestTickets.length == 0;
    return (
        <ActionButton
            as="menuitem"
            disabled={!canRequestToBlock}
            name={t("request-block-user--action")}
            requiredPermissions={["supportapp_request_to_block_wallet"]}
        >
            <RequestToBlockUnblockUserMutator
                unregisteredMobile={null}
                wallet={wallet}
            />
        </ActionButton>
    );
};
