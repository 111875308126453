import {
    IconDefinition,
    faComment,
    faPhone,
    faPhoneSlash,
    faReply,
    faShuffle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CurrentCallState } from "@/context/CurrentCall";

import { useCurrentCall } from "@/hooks/useCurrentCall";
import { useNavigation } from "@/hooks/useNavigation";

import { formatTimestamp } from "@/utils/time";

const callIcon = (callInfo: CurrentCallState): IconDefinition => {
    switch (callInfo.callType) {
        case "none":
        case "outgoing":
            return faPhoneSlash;
        case "callback":
            return faReply;
        case "transfer":
            return faShuffle;
        case "incoming":
            return faPhone;
        case "whatsapp":
            return faComment;
    }
};

export const CallInfoBadge = () => {
    const { t } = useTranslation();
    const { currentCall } = useCurrentCall();
    const { goToProfileByMobile } = useNavigation();

    const onClick = useCallback(() => {
        if (currentCall.callType != "none") {
            goToProfileByMobile(currentCall.mobile);
        }
    }, [currentCall, goToProfileByMobile]);

    const callerId =
        currentCall.callType == "none"
            ? t("call-info--no-caller")
            : currentCall.mobile;

    const isVoipCall = currentCall.callType != "none" && currentCall.isVoip;

    const tooltip = ((): string => {
        if (isVoipCall) {
            // knowing its voip is more important than the transfer tooltip.
            // call can't be a callback and also a voip call
            return t("call-info--voip-call");
        }
        switch (currentCall.callType) {
            case "none":
            case "outgoing":
                return t("call-info--no-active-caller");
            case "incoming":
                return t("call-info--call-from", {
                    customerMobile: currentCall.mobile,
                });
            case "callback":
                return t("call-info--callback-to", {
                    customerMobile: currentCall.mobile,
                    originatingCallAt: formatTimestamp(
                        currentCall.originatingCallAt
                    ),
                });
            case "transfer":
                return t("call-info--transfer-from", {
                    customerMobile: currentCall.mobile,
                    originalRep: currentCall.originatingCallRep,
                });
            case "whatsapp":
                return "whatsapp";
        }
    })();

    let buttonVariant = "info";
    if (["callback", "transfer"].includes(currentCall.callType) || isVoipCall) {
        buttonVariant = "warning";
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="call-info-tooltip">{tooltip}</Tooltip>}
        >
            <Button
                onClick={onClick}
                variant={buttonVariant}
                className="m-2"
                data-testid="call-info-icon"
            >
                <FontAwesomeIcon
                    icon={callIcon(currentCall)}
                    className="mr-2"
                />
                {isVoipCall ? "VOIP " : ""}
                {callerId}
            </Button>
        </OverlayTrigger>
    );
};
