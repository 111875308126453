/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CancelAllAtxMutationVariables = {
    mobile: string;
};
export type CancelAllAtxMutationResponse = {
    readonly cancelAllAtx: {
        readonly success: boolean;
    } | null;
};
export type CancelAllAtxMutation = {
    readonly response: CancelAllAtxMutationResponse;
    readonly variables: CancelAllAtxMutationVariables;
};



/*
mutation CancelAllAtxMutation(
  $mobile: String!
) {
  cancelAllAtx(mobile: $mobile) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      }
    ],
    "concreteType": "CancelAllAtx",
    "kind": "LinkedField",
    "name": "cancelAllAtx",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelAllAtxMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelAllAtxMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2060c7a53060b4ce3c5adc769426dbd0",
    "id": null,
    "metadata": {},
    "name": "CancelAllAtxMutation",
    "operationKind": "mutation",
    "text": "mutation CancelAllAtxMutation(\n  $mobile: String!\n) {\n  cancelAllAtx(mobile: $mobile) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '336dea9968132fba167816072775886c';
export default node;
