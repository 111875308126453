import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { M } from "@/utils/currency";

import { AgentHistorySummary_summary$key } from "./__generated__/AgentHistorySummary_summary.graphql";

const summaryFragment = graphql`
    fragment AgentHistorySummary_summary on AgentDailySummary {
        date
        whenDayEnded
        dailyAward
        numSignups
        absoluteTransactionVolume
        effectiveTransactionVolume
        totalCommission
    }
`;
interface Props {
    summary: AgentHistorySummary_summary$key;
}

function _AgentHistorySummary(props: Props) {
    const { t } = useTranslation();
    const summary = useFragment(summaryFragment, props.summary);

    const commission = M.fromSerialized(summary.totalCommission).toString();
    const tx = M.fromSerialized(summary.effectiveTransactionVolume).toString();
    return (
        <div className="group relative py-1 pr-12 pl-2 border-b border-gray-200 bg-gray-200">
            <div className="tx-info">
                <TxTitle>
                    {t("agent-history--title", {
                        date: summary.date,
                        commission,
                    })}
                </TxTitle>
                <TxInfo>
                    {t("agent-history--description", {
                        count: summary.numSignups,
                        tx,
                    })}
                </TxInfo>
            </div>
        </div>
    );
}

export default _AgentHistorySummary;
