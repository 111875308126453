import { useFlag } from "@/hooks/useFlag";
import { usePermissions } from "@/hooks/usePermissions";

import { CustomerAppStatusBadge } from "@/pages/mobile.[mobile].personal/CustomerAppStatusBadge";
import { IdPhotosBadge } from "@/pages/mobile.[mobile].personal/IdPhotosBadge";
import { KycBadge } from "@/pages/mobile.[mobile].personal/KycBadge";
import { RefundAbuserBadge } from "@/pages/mobile.[mobile].personal/RefundAbuserBadge";
import { SendPayPromoBadge } from "@/pages/mobile.[mobile].personal/SendPayPromoBadge";
import { PersonalTabHeader_wallet } from "@/pages/mobile.[mobile].personal/__generated__/PersonalTabHeader_wallet.graphql";
import { WatchlistedBadge } from "@/pages/mobile.[mobile]/WatchlistedBadge";
import { WeakPinBadge } from "@/pages/mobile.[mobile]/WeakPinBadge";
import { WhitelistedBadge } from "@/pages/mobile.[mobile]/WhitelistedBadge";

import { UserInfoHeader_walletData } from "../mobile.[mobile]/__generated__/UserInfoHeader_walletData.graphql";

type PersonalBadgesProps = {
    wallet: PersonalTabHeader_wallet | UserInfoHeader_walletData;
};

const PersonalBadges = ({ wallet }: PersonalBadgesProps) => {
    const shouldShowPayPromo = useFlag("pay_with_wave_promo")?.isActive;
    const shouldShowRefundAbuser = useFlag("show_refund_abuser")?.isActive;
    const shouldShowWeakPins = usePermissions("supportapp_show_weak_pin_badge");

    return (
        <div className="personal-badges">
            <KycBadge wallet={wallet} />
            <IdPhotosBadge
                photoEditUrl={wallet.photoEditUrl}
                documentStatus={wallet.documentStatus}
                needsIdPhotoConfirmation={wallet.user?.needsIdPhotoConfirmation}
            />
            <CustomerAppStatusBadge wallet={wallet} />
            {shouldShowPayPromo && wallet.user?.elligibleToReceivePayPromo && (
                <SendPayPromoBadge name={wallet.name} mobile={wallet.mobile} />
            )}
            {shouldShowRefundAbuser && wallet.isRefundAbuser && (
                <RefundAbuserBadge />
            )}
            {wallet.user?.userOnAgentGamingWhitelist.isOnList && (
                <WhitelistedBadge
                    date={wallet.user?.userOnAgentGamingWhitelist.whenUpdated}
                />
            )}
            {wallet.user?.userOnAgentGamingWatchlist.isOnList && (
                <WatchlistedBadge
                    date={wallet.user?.userOnAgentGamingWatchlist.whenUpdated}
                />
            )}
            {shouldShowWeakPins && wallet.user?.hasWeakPin && <WeakPinBadge />}
        </div>
    );
};

export default PersonalBadges;
