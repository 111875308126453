import {
    faClock,
    faExclamationCircle,
    faGlobeAfrica,
    faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Children, Dispatch, Fragment, ReactNode, SetStateAction } from "react";
import * as React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    DropdownButton,
    OverlayTrigger,
    Table,
    Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ActionButton } from "@/components/ActionButton";
import { Amount } from "@/components/Amount";

import { Flags } from "@/external/flags";

import { useCurrentCall } from "@/hooks/useCurrentCall";
import { usePermissions } from "@/hooks/usePermissions";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { AgentNetworkModal } from "@/pages/mobile.[mobile].agent/AgentNetworkModal";
import {
    AgentTransactionMutator,
    DEPOSIT,
    DEPOSIT_AND_SEND,
    REBALANCE_DEPOSIT,
    REBALANCE_WITHDRAW,
    WITHDRAW,
} from "@/pages/mobile.[mobile].agent/AgentTransaction";
import { CreateAgentTaskMutator } from "@/pages/mobile.[mobile].agent/CreateAgentTask";
import { ExtendAgentGamingAgentWatchlistMutator } from "@/pages/mobile.[mobile].agent/ExtendAgentGamingAgentWatchlist";
import { ModifyAgentGamingAgentWatchlistMutator } from "@/pages/mobile.[mobile].agent/ModifyAgentGamingAgentWatchlist";
import { ModifyAgentGamingAgentWhitelistMutator } from "@/pages/mobile.[mobile].agent/ModifyAgentGamingAgentWhitelist";
import { ReportAgentIssueMutator } from "@/pages/mobile.[mobile].agent/ReportAgentIssue";
import { RequestToExplainCommissionCutsMutator } from "@/pages/mobile.[mobile].agent/RequestToExplainCommissionCuts";
import { SendAgentAppLinkMutator } from "@/pages/mobile.[mobile].agent/SendAgentAppLink";
import { JumpToDateInput } from "@/pages/mobile.[mobile]/JumpToDate";

import { Action } from "@/types/actions";
import { OperationEnum } from "@/types/misc";

import { M } from "@/utils/currency";

import { AgentTab_agentUser } from "./__generated__/AgentTab_agentUser.graphql";

import { WatchlistedBadge } from "../mobile.[mobile]/WatchlistedBadge";
import { WhitelistedBadge } from "../mobile.[mobile]/WhitelistedBadge";
import { AgentUsersList } from "./AgentUsersList";
import { CreateAgentAssistant } from "./CreateAgentAssistant";
import { ReportUserComplaintMutator } from "./ReportUserComplaint";

interface ClockIconProps {
    openingHoursDisplay: string;
}
const ClockIcon = ({ openingHoursDisplay }: ClockIconProps) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id="button-tooltip-2">
                    {t("agent-tab-header--operating-hours")}
                    <br />
                    {openingHoursDisplay}
                </Tooltip>
            }
        >
            <FontAwesomeIcon
                icon={faClock}
                title={t("agent-tab-header--operating-hours--icon")}
            />
        </OverlayTrigger>
    );
};

interface MapIconProps {
    lat: any | null;
    lng: any | null;
}
const MapIcon = ({ lat, lng }: MapIconProps) => {
    const { t } = useTranslation();
    if (!lat || !lng)
        return (
            <>
                <FontAwesomeIcon
                    icon={faGlobeAfrica}
                    title={t("agent-tab-header--missing-location")}
                />
                <FontAwesomeIcon
                    icon={faExclamationCircle}
                    title={t("agent-tab-header--missing-location")}
                />
            </>
        );

    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/place/${lat},${lng}/@${lat},${lng},10z`}
        >
            <FontAwesomeIcon
                icon={faMapMarkedAlt}
                title={t("agent-tab-header--open-map")}
            />
        </a>
    );
};

interface AgentBalanceProps {
    agent: AgentTab_agentUser["agent"];
    wallet: AgentTab_agentUser["agent"]["floatWallet"];
}
const AgentBalance = ({ agent, wallet }: AgentBalanceProps) => {
    const { t } = useTranslation();
    let agentBal = <>??.???</>;
    let floatBal: M | undefined;

    if (wallet.balance) {
        floatBal = M.fromSerialized(wallet.balance);
        agentBal = <Amount amount={floatBal} />;
    }

    // show recovery balance if it exists and is not zero
    if (
        floatBal &&
        agent.recoveryBalance &&
        !M.fromSerialized(agent.recoveryBalance).isZero()
    ) {
        const recoveryBal = M.fromSerialized(agent.recoveryBalance);
        const totalBalance = floatBal.add(recoveryBal);
        agentBal = (
            <span className="amount">
                {totalBalance.toString()}
                &nbsp;(&nbsp;
                <span className="badge badge-info">
                    {t("agent-tab-header--float", {
                        float: floatBal.toString(),
                    })}
                </span>
                &nbsp;-&nbsp;
                <span className="badge badge-warning">
                    {t("agent-tab-header--recovery", {
                        recovery: recoveryBal.multiply(-1).toString(),
                    })}
                </span>
                &nbsp;)
            </span>
        );
    }

    return agentBal;
};

interface AgentRebalanceLimitProps {
    agent: AgentTab_agentUser["agent"];
}
const AgentRebalanceLimit = ({ agent }: AgentRebalanceLimitProps) => {
    const { t } = useTranslation();
    if (!agent.activeRebalanceLimit) {
        return <>{t("agent-tab-header--no-rebalance-limit")}</>;
    }
    const { rebalanceLimit, note, isCurrentlySuspended } =
        agent.activeRebalanceLimit;
    const limit = M.fromSerialized(rebalanceLimit);
    const formattedNote = note ? ` (${note})` : "";
    const suspension = isCurrentlySuspended
        ? " " + t("agent-tab-header--rebalance-limit-suspended")
        : "";
    return (
        <>
            <span className={isCurrentlySuspended ? "line-through" : ""}>
                <Amount amount={limit} />
                {formattedNote}
            </span>
            {suspension}
        </>
    );
};

interface TableHeaderProps {
    colummCount: number;
    children: ReactNode;
}
const TableHeader = ({ colummCount, children }: TableHeaderProps) => (
    <thead>
        <tr>
            <th colSpan={colummCount}>
                {Children.toArray(children)
                    .filter(Boolean)
                    .map((c, i) => (
                        <Fragment key={i}>{c}&nbsp;&nbsp;</Fragment>
                    ))}
            </th>
        </tr>
    </thead>
);

interface TableRowProps {
    title: string;
    children: ReactNode;
}
const TableRow = ({ title, children }: TableRowProps) => (
    <tr>
        <td>{title}</td>
        <td>{children}</td>
    </tr>
);

interface AgentTabHeaderProps {
    agentUser: AgentTab_agentUser;
    when: Date | undefined;
    setWhen: (date: Date) => void;
    showCancelledAtx: boolean;
    setShowCancelledAtx: Dispatch<SetStateAction<boolean>>;
}

type ShowCancelledTransactionsButtonProps = {
    showCancelledAtx: boolean;
    setShowCancelledAtx: Dispatch<SetStateAction<boolean>>;
};

export const _ShowCancelledTransactionsButton = ({
    showCancelledAtx,
    setShowCancelledAtx,
}: ShowCancelledTransactionsButtonProps) => {
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();

    return (
        <Button
            variant="outline-danger"
            className="mr-2"
            onClick={() => {
                trackEvent(
                    `agent cancelled transactions ${
                        showCancelledAtx ? "hide" : "show"
                    }`
                );
                setShowCancelledAtx(!showCancelledAtx);
            }}
            data-testid="toggle-agent-cancelled-transactions"
        >
            {showCancelledAtx
                ? t("agent-tab-header--cancelled-transactions--hide")
                : t("agent-tab-header--cancelled-transactions--show")}
        </Button>
    );
};

const BankAccountSurvey = ({
    agentUser,
}: {
    agentUser: AgentTab_agentUser;
}) => {
    const { t } = useTranslation();
    const { currentCall } = useCurrentCall();
    const trackEvent = useTrackEvent();
    const agentBankSurveyEnabled =
        Flags.getFlag("show_agent_bank_survey")?.isActive || false;
    const checkCallerAgentBankSurvey =
        Flags.getFlag("check_caller_agent_bank_survey")?.isActive || false;

    const { agent, user } = agentUser;

    const isCurrentCaller =
        currentCall.callType != "none" && currentCall.mobile === user.mobile;
    const isPrincipal = agent.principal.id === user.id;
    const showAgentSurvey =
        agentBankSurveyEnabled &&
        isPrincipal &&
        (!checkCallerAgentBankSurvey || isCurrentCaller);

    const handleBankSurveyClick = () => {
        trackEvent(Action.OpenAgentBankAccountSurvey);
        window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSf6cyJS1c7M44UeUhiid9bAve-8bSMpRE85C2WSj-iOSIXBAw/viewform",
            "_blank"
        );
    };
    return (
        <>
            {showAgentSurvey && (
                <Button
                    variant={"link"}
                    onClick={handleBankSurveyClick}
                    target="_blank"
                >
                    {t("agent-tab-header--bank-account-survey")}
                </Button>
            )}
        </>
    );
};

export const AgentTabHeader = ({
    agentUser,
    when,
    setWhen,
    showCancelledAtx,
    setShowCancelledAtx,
}: AgentTabHeaderProps) => {
    const { t } = useTranslation();
    const { agent } = agentUser;
    const wallet = agent.floatWallet;

    const shouldShowUserComplaint =
        Flags.getFlag("show-user-complaint")?.isActive || false;

    let location = `${agent.city}, ${agent.region}`;
    if (agent.subcity != null) {
        location = `${agent.subcity}, ${location}`;
    }

    const commission_structure = () => {
        const commission = agent.commissionStructureName
            ? t("agent-tab-header--commission-on", {
                  name: agent.commissionStructureName,
              })
            : t("agent-tab-header--commission-off");
        if (agent.commissionUrl !== null) {
            return (
                <a href={agent.commissionUrl} target="_blank" rel="noreferrer">
                    {commission}
                </a>
            );
        } else {
            return <>{commission}</>;
        }
    };

    const hasStandingOverdraftOffer = (agent: AgentTab_agentUser["agent"]) => {
        const status = agent.overdraftOffer?.status;
        return (
            status == t("agent-tab-header--status-approved") ||
            status == t("agent-tab-header--status-pending")
        );
    };

    const accountStateUIHelper = (() => {
        if (agent.isActive)
            return {
                badge: "success",
                translationKey: "agent-tab-header--agent-active",
            };

        if (agent.whenTerminated)
            return {
                badge: "danger",
                translationKey: "agent-tab-header--agent-terminated",
            };

        return {
            badge: "warning",
            translationKey: "agent-tab-header--agent-suspended",
        };
    })();

    return (
        <div className="bg-gray-50 border-b border-gray-200 py-2 pr-12 pl-2">
            <Table variant="condensed">
                <TableHeader colummCount={2}>
                    <MapIcon lat={agent.coords?.lat} lng={agent.coords?.lng} />
                    <>
                        {agent.ufid} - {agent.name} - {location}
                    </>
                    <span
                        className={`text-uppercase badge badge-${
                            agent.isReported ? "primary" : "secondary"
                        }`}
                    >
                        {agent.isReported
                            ? t("agent-tab-header--agent-reported")
                            : t("agent-tab-header--agent-unreported")}
                    </span>
                    <span
                        className={`text-uppercase badge badge-${accountStateUIHelper.badge}`}
                    >
                        {t(accountStateUIHelper.translationKey)}
                    </span>
                    <span className={`text-uppercase badge bg-gray-300`}>
                        {agent.floatWallet.partnerOrg}
                    </span>
                    {agent.openingHoursDisplay && (
                        <ClockIcon
                            openingHoursDisplay={agent.openingHoursDisplay}
                        />
                    )}
                    <a
                        href={agent.floatWallet.photoEditUrl ?? undefined}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span
                            className={`text-uppercase badge badge-${
                                agent.isCompliant ? "success" : "warning"
                            }`}
                        >
                            {agent.isCompliant
                                ? t("agent-tab-header--agent-compliant")
                                : t("agent-tab-header--agent-non-compliant")}
                        </span>
                    </a>
                    {agent.agentOnAgentGamingWhitelist.isOnList && (
                        <WhitelistedBadge
                            date={agent.agentOnAgentGamingWhitelist.whenUpdated}
                        />
                    )}
                    {agent.agentOnAgentGamingWatchlist.isOnList && (
                        <WatchlistedBadge
                            date={agent.agentOnAgentGamingWatchlist.whenUpdated}
                        />
                    )}
                </TableHeader>
                <tbody>
                    <TableRow title={t("agent-tab-header--table--balance")}>
                        <AgentBalance agent={agent} wallet={wallet} />
                    </TableRow>
                    <TableRow title={t("agent-tab-header--table--commission")}>
                        {commission_structure()}
                    </TableRow>
                    <TableRow title={t("agent-tab-header--table--agent-type")}>
                        {agent.agentType}
                    </TableRow>
                    <TableRow
                        title={t("agent-tab-header--table--minimum-balance")}
                    >
                        {agent.limitBalMin != null ? (
                            <Amount
                                amount={M.fromSerialized(agent.limitBalMin)}
                            />
                        ) : (
                            t("agent-tab-header--table--no-limit")
                        )}
                        {hasStandingOverdraftOffer(agent) && " (overdraft)"}
                    </TableRow>
                    <TableRow
                        title={t("agent-tab-header--table--rebalance-limit")}
                    >
                        <AgentRebalanceLimit agent={agent} />
                    </TableRow>
                </tbody>
            </Table>
            <ButtonToolbar className="tab-header-toolbar justify-between">
                <ButtonGroup>
                    {usePermissions("supportapp_create_agent_assistant") && (
                        <ActionButton name="Create Agent Assistant">
                            <CreateAgentAssistant agentUser={agentUser} />
                        </ActionButton>
                    )}
                    {usePermissions("supportapp_create_agent_task") && (
                        <ActionButton name={t("create-agent-task--action")}>
                            <CreateAgentTaskMutator agent={agent} />
                        </ActionButton>
                    )}
                    <ActionButton
                        name={t("agent-transaction--withdraw--action")}
                        disabled={!usePermissions("supportapp_manual_withdraw")}
                    >
                        <AgentTransactionMutator
                            agentUser={agentUser}
                            direction={WITHDRAW}
                        />
                    </ActionButton>
                    <ActionButton
                        name={t("agent-transaction--deposit--action")}
                        disabled={!usePermissions("supportapp_manual_deposit")}
                    >
                        <AgentTransactionMutator
                            agentUser={agentUser}
                            direction={DEPOSIT}
                        />
                    </ActionButton>
                    <ActionButton
                        name={t("agent-transaction--deposit-send--action")}
                        disabled={
                            !usePermissions(
                                "supportapp_manual_deposit",
                                "supportapp_manual_transfer"
                            )
                        }
                    >
                        <AgentTransactionMutator
                            agentUser={agentUser}
                            direction={DEPOSIT_AND_SEND}
                        />
                    </ActionButton>
                    <ActionButton
                        name={t("report-agent-issue--action")}
                        disabled={
                            !usePermissions("supportapp_report_agent_issue")
                        }
                    >
                        <ReportAgentIssueMutator agentUser={agentUser} />
                    </ActionButton>
                    <ActionButton
                        name={t("report-user-complaint--action")}
                        disabled={
                            !usePermissions("supportapp_report_agent_issue")
                        }
                        className={shouldShowUserComplaint ? "" : "hidden"}
                    >
                        <ReportUserComplaintMutator agentUser={agentUser} />
                    </ActionButton>
                    <DropdownButton
                        title={t("dropdown--escalate")}
                        id="agent-dropdown-escalate"
                        variant="outline-dark"
                    >
                        <ActionButton
                            as="menuitem"
                            name={t("request-explain-commission--action")}
                            disabled={
                                !usePermissions(
                                    "supportapp_request_to_explain_commission_cuts"
                                )
                            }
                        >
                            <RequestToExplainCommissionCutsMutator
                                agent={agent}
                            />
                        </ActionButton>
                    </DropdownButton>
                    <DropdownButton
                        title={t("agent-tab-header--more")}
                        id="agent-dropdown"
                        variant="outline-dark"
                        className="float-right"
                    >
                        <ActionButton
                            as="menuitem"
                            name={t("agent-transaction--rebalance-withdraw")}
                            disabled={
                                !usePermissions("supportapp_rebalance_withdraw")
                            }
                        >
                            <AgentTransactionMutator
                                agentUser={agentUser}
                                direction={REBALANCE_WITHDRAW}
                                isRebalance
                            />
                        </ActionButton>
                        <ActionButton
                            as="menuitem"
                            name={t("agent-transaction--rebalance-deposit")}
                            disabled={
                                !usePermissions("supportapp_rebalance_deposit")
                            }
                        >
                            <AgentTransactionMutator
                                agentUser={agentUser}
                                direction={REBALANCE_DEPOSIT}
                                isRebalance
                            />
                        </ActionButton>
                        <ActionButton
                            as="menuitem"
                            name={t("send-agent-link--action")}
                            disabled={
                                !usePermissions(
                                    "supportapp_send_agent_app_link"
                                )
                            }
                        >
                            <SendAgentAppLinkMutator
                                name={wallet.name}
                                mobile={wallet.mobile}
                            />
                        </ActionButton>
                        <ActionButton
                            as="menuitem"
                            name={t("agent-network--action")}
                            disabled={false}
                        >
                            <AgentNetworkModal agent={agent} />
                        </ActionButton>
                        {!agent.agentOnAgentGamingWhitelist.isOnList && (
                            <ActionButton
                                as="menuitem"
                                name={t("agent-gaming-agent-whitelist--action")}
                                requiredPermissions={[
                                    "supportapp_modify_agent_gaming_agent_whitelist",
                                ]}
                            >
                                <ModifyAgentGamingAgentWhitelistMutator
                                    name={agent.name}
                                    agentId={agent.ufid}
                                    operation={OperationEnum.ADD}
                                />
                            </ActionButton>
                        )}
                        {agent.agentOnAgentGamingWhitelist.isOnList && (
                            <ActionButton
                                as="menuitem"
                                name={t(
                                    "agent-gaming-agent-whitelist--action-remove"
                                )}
                                requiredPermissions={[
                                    "supportapp_modify_agent_gaming_agent_whitelist",
                                ]}
                            >
                                <ModifyAgentGamingAgentWhitelistMutator
                                    name={agent.name}
                                    agentId={agent.ufid}
                                    operation={OperationEnum.REMOVE}
                                />
                            </ActionButton>
                        )}
                        {!agent.agentOnAgentGamingWatchlist.isOnList && (
                            <ActionButton
                                as="menuitem"
                                name={t("agent-gaming-agent-watchlist--action")}
                                requiredPermissions={[
                                    "supportapp_modify_agent_gaming_agent_watchlist",
                                ]}
                            >
                                <ModifyAgentGamingAgentWatchlistMutator
                                    name={agent.name}
                                    agentId={agent.ufid}
                                    operation={OperationEnum.ADD}
                                />
                            </ActionButton>
                        )}
                        {agent.agentOnAgentGamingWatchlist.isOnList && (
                            <>
                                <ActionButton
                                    as="menuitem"
                                    name={t(
                                        "agent-gaming-agent-watchlist--action-remove"
                                    )}
                                    requiredPermissions={[
                                        "supportapp_modify_agent_gaming_agent_watchlist",
                                    ]}
                                >
                                    <ModifyAgentGamingAgentWatchlistMutator
                                        name={agent.name}
                                        agentId={agent.ufid}
                                        operation={OperationEnum.REMOVE}
                                    />
                                </ActionButton>
                                <ActionButton
                                    as="menuitem"
                                    name={t(
                                        "agent-gaming-agent-watchlist--action-extend"
                                    )}
                                    requiredPermissions={[
                                        "supportapp_modify_agent_gaming_agent_watchlist",
                                    ]}
                                >
                                    <ExtendAgentGamingAgentWatchlistMutator
                                        agentId={agent.ufid}
                                    />
                                </ActionButton>
                            </>
                        )}
                    </DropdownButton>
                    <BankAccountSurvey agentUser={agentUser} />
                </ButtonGroup>
                <div>
                    <_ShowCancelledTransactionsButton
                        showCancelledAtx={showCancelledAtx}
                        setShowCancelledAtx={setShowCancelledAtx}
                    />
                    <JumpToDateInput when={when} setWhen={setWhen} />
                </div>
            </ButtonToolbar>
            <div className="mt-2">
                <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {t("agent-tab-header--agent-users")}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <AgentUsersList agent={agent} />
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
};
