import { Suspense } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";

import ErrorBoundary from "../components/ErrorBoundary";
import { LoadingIndicator } from "../components/Loading";
import { ProfileSearcher } from "../components/ProfileSearcher";

import { Flags } from "@/external/flags";
import { Flex } from "@/external/flex";

import { useNavigation } from "@/hooks/useNavigation";
import { useStoredCallCount } from "@/hooks/useStoredCallCount";

import { CountryButton } from "./CountryButton";
import { LanguageButton } from "./LanguageButton";
import LoadCallerButton from "./LoadCallerButton";
import { OpenStandalone } from "./OpenStandalone";
import { ReloadButton } from "./ReloadButton";
import { RepInfoHeader } from "./RepInfoHeader";
import { SwitchVoiceRoute } from "./SwitchVoiceRoute";
import { TransferCall } from "./TransferCall";

const _NavBar = () => {
    const {
        canGoBack,
        canGoForward,
        goBack,
        goForward,
        goToProfileById,
        goToProfileByMobile,
        goToSpecialPage,
    } = useNavigation();
    const shouldShowRepInfoHeader = Flags.getFlag("rep_info_header")?.isActive;
    const shouldShowTransferCallButton = Flags.getFlag(
        "show_transfer_call_button"
    )?.isActive;
    const shouldShowSwitchVoiceRoute = Flags.getFlag(
        "show-switch-voice-route"
    )?.isActive;

    const { hourlyCallCount, topOfHour, incrementCallCallback } =
        useStoredCallCount();

    return (
        <>
            <div className="flex items-center grow-0 shrink-0 basis-auto p-2 mb-2 border-b border-gray-200 bg-gray-100">
                <ErrorBoundary>
                    <Button
                        disabled={!canGoBack}
                        onClick={goBack}
                        className="ml-2"
                        variant="outline-success"
                    >
                        &#9664;
                    </Button>
                    <div className="flex-auto ml-2">
                        <ProfileSearcher
                            allowWallets
                            allowUnregistered
                            allowAgents
                            allowMerchants
                            allowTerminated
                            clearAfterSelection
                            onChange={(profile, entry?: string) => {
                                if (!profile) return;

                                if (profile.kind === "Agent") {
                                    goToProfileById(
                                        profile.agent.id,
                                        entry,
                                        "agent",
                                        {
                                            clearLocationSearch: true,
                                        }
                                    );
                                } else if (profile.kind === "Merchant") {
                                    goToProfileById(
                                        profile.id,
                                        entry,
                                        "merchant",
                                        {
                                            clearLocationSearch: true,
                                        }
                                    );
                                } else {
                                    goToProfileByMobile(
                                        profile.mobile,
                                        entry,
                                        "personal",
                                        {
                                            clearLocationSearch: true,
                                        }
                                    );
                                }
                            }}
                        />
                    </div>
                    <Button
                        disabled={!canGoForward}
                        className="ml-2"
                        onClick={goForward}
                        variant="outline-success"
                    >
                        &#9654;
                    </Button>
                    {!Flex.isEmbedded ? (
                        <>
                            <LoadCallerButton
                                onCurrentCallFound={(mobile) => {
                                    mobile
                                        ? goToProfileByMobile(mobile)
                                        : undefined;
                                    incrementCallCallback(mobile);
                                }}
                                onCurrentCallMissing={() => {
                                    goToSpecialPage("no-current-call");
                                }}
                            />
                            {shouldShowTransferCallButton && <TransferCall />}
                        </>
                    ) : null}
                </ErrorBoundary>
                <ReloadButton />
                {Flex.isEmbedded ? <OpenStandalone /> : null}
                {!Flex.isEmbedded ? (
                    <>{shouldShowSwitchVoiceRoute && <SwitchVoiceRoute />}</>
                ) : null}
                <CountryButton />
                <LanguageButton />
            </div>
            {shouldShowRepInfoHeader && (
                <Suspense fallback={<LoadingIndicator />}>
                    <ErrorBoundary>
                        <RepInfoHeader
                            callCount={hourlyCallCount}
                            topOfHour={topOfHour}
                        />
                    </ErrorBoundary>
                </Suspense>
            )}
        </>
    );
};

export const NavBar = withRouter(_NavBar);
