/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnblockFromUsingMultipleDevices_user = {
    readonly id: string;
    readonly personalMultipleDevicesBlock: {
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly whenExpires: string | null;
        readonly lockedDevice: {
            readonly deviceId: string;
            readonly deviceName: string;
            readonly deviceModel: string;
        } | null;
    } | null;
    readonly agentMultipleDevicesBlock: {
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly whenExpires: string | null;
        readonly lockedDevice: {
            readonly deviceId: string;
            readonly deviceName: string;
            readonly deviceModel: string;
        } | null;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"DirectDepositBlockHistory_user">;
    readonly " $refType": "UnblockFromUsingMultipleDevices_user";
};
export type UnblockFromUsingMultipleDevices_user$data = UnblockFromUsingMultipleDevices_user;
export type UnblockFromUsingMultipleDevices_user$key = {
    readonly " $data"?: UnblockFromUsingMultipleDevices_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UnblockFromUsingMultipleDevices_user">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "blockReason",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "blockedBy",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenBlocked",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenExpires",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserDevice",
    "kind": "LinkedField",
    "name": "lockedDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceModel",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnblockFromUsingMultipleDevices_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MultipleDevicesBlock",
      "kind": "LinkedField",
      "name": "personalMultipleDevicesBlock",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MultipleDevicesBlock",
      "kind": "LinkedField",
      "name": "agentMultipleDevicesBlock",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DirectDepositBlockHistory_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'f284ed08631dc24f571c15066956af13';
export default node;
