import { createContext } from "react";

import { DEFAULT_COUNTRY } from "@/types/country";

interface CurrentCountry {
    /** The iso2 code of the currently selected country */
    country: string;
    /** A list of iso2 codes of all selectable countries */
    countries: readonly string[];
    /** Set the currently selected country by iso2 code */
    updateCountry: (country: string) => void;
    /** Set all available countries by iso2 code */
    updateCountries: (countries: readonly string[]) => void;
}

export const CountryContext = createContext<CurrentCountry>({
    country: DEFAULT_COUNTRY,
    countries: [DEFAULT_COUNTRY],
    updateCountry: (_) => {
        /** noop */
    },
    updateCountries: (_) => {
        /** noop */
    },
});
