/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Country = "BF" | "BJ" | "CI" | "CM" | "ET" | "GH" | "GM" | "ML" | "NE" | "NG" | "QQ" | "SN" | "TG" | "UG" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type MerchantTab_merchant = {
    readonly id: string;
    readonly ufid: string;
    readonly name: string;
    readonly city: string | null;
    readonly region: string | null;
    readonly subcity: string | null;
    readonly country: Country;
    readonly nearbyLandmark: string | null;
    readonly isActive: boolean;
    readonly whenDeactivated: string | null;
    readonly deactivatedBy: string | null;
    readonly deactivatedBecause: string | null;
    readonly photos: ReadonlyArray<{
        readonly url: string | null;
    }> | null;
    readonly promotedBy: {
        readonly name: string;
        readonly mobile: string | null;
    } | null;
    readonly activeKybGracePeriod: {
        readonly kybTierGranted: string;
    } | null;
    readonly businessNetwork: {
        readonly name: string;
        readonly wallets: ReadonlyArray<{
            readonly walletId: string;
        }>;
    } | null;
    readonly wallet: {
        readonly id: string;
        readonly balance?: string | undefined;
        readonly name: string;
        readonly mobile: string;
        readonly kybTier: string | null;
        readonly photoEditUrl: string | null;
        readonly merchantSupportEscalations: ReadonlyArray<{
            readonly whenOpened: string;
            readonly status: TicketStatus;
            readonly clickupTaskId: string | null;
        }>;
        readonly supportHistoryConnection?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly maybeTransferId?: string | null | undefined;
                    readonly __typename: "PayoutTransferEntry";
                    readonly tcid?: string | undefined;
                    readonly amount?: string | undefined;
                    readonly whenEntered?: string | undefined;
                    readonly agentTransactionId?: string | undefined;
                    readonly " $fragmentRefs": FragmentRefs<"MerchantHistoryEntry_entry">;
                };
            }>;
            readonly pageInfo: {
                readonly hasPreviousPage: boolean;
                readonly hasNextPage: boolean;
                readonly startCursor: string | null;
                readonly endCursor: string | null;
            };
        } | undefined;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"MerchantUsersList_merchant">;
    readonly " $refType": "MerchantTab_merchant";
};
export type MerchantTab_merchant$data = MerchantTab_merchant;
export type MerchantTab_merchant$key = {
    readonly " $data"?: MerchantTab_merchant$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MerchantTab_merchant">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "around"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "canReadBalance"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "canReadHistory"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "when"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": [
          "wallet",
          "supportHistoryConnection"
        ]
      }
    ]
  },
  "name": "MerchantTab_merchant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ufid",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "region",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subcity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nearbyLandmark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenDeactivated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deactivatedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deactivatedBecause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MerchantPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "promotedBy",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KybGracePeriod",
      "kind": "LinkedField",
      "name": "activeKybGracePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kybTierGranted",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessNetwork",
      "kind": "LinkedField",
      "name": "businessNetwork",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BusinessNetworkWallet",
          "kind": "LinkedField",
          "name": "wallets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "walletId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Wallet",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kybTier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "photoEditUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MerchantSupportEscalationTicket",
          "kind": "LinkedField",
          "name": "merchantSupportEscalations",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "whenOpened",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clickupTaskId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "canReadBalance",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balance",
              "storageKey": null
            }
          ]
        },
        {
          "condition": "canReadHistory",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "supportHistoryConnection",
              "args": [
                {
                  "kind": "Variable",
                  "name": "around",
                  "variableName": "around"
                },
                {
                  "kind": "Variable",
                  "name": "when",
                  "variableName": "when"
                }
              ],
              "concreteType": "SupportHistoryConnection",
              "kind": "LinkedField",
              "name": "__MerchantTab_supportHistoryConnection_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SupportHistoryEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": "maybeTransferId",
                              "args": null,
                              "kind": "ScalarField",
                              "name": "transferId",
                              "storageKey": null
                            }
                          ],
                          "type": "MerchantSaleEntry",
                          "abstractKey": null
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "tcid",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "amount",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "whenEntered",
                              "storageKey": null
                            }
                          ],
                          "type": "PayoutTransferEntry",
                          "abstractKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "MerchantHistoryEntry_entry"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "agentTransactionId",
                              "storageKey": null
                            }
                          ],
                          "type": "AgentTransactionEntry",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MerchantUsersList_merchant"
    }
  ],
  "type": "Merchant",
  "abstractKey": null
};
})();
(node as any).hash = '77dc613e936113c810a41ec1fdad640b';
export default node;
