/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ShowWithdrawalCodesMutationVariables = {
    walletId: string;
};
export type ShowWithdrawalCodesMutationResponse = {
    readonly showWithdrawalCodes: {
        readonly agentTransactions: ReadonlyArray<{
            readonly id: string;
            readonly amount: string | null;
            readonly isDeposit: boolean;
            readonly whenCreated: string;
            readonly code: {
                readonly code: string;
            } | null;
        } | null>;
    } | null;
};
export type ShowWithdrawalCodesMutation = {
    readonly response: ShowWithdrawalCodesMutationResponse;
    readonly variables: ShowWithdrawalCodesMutationVariables;
};



/*
mutation ShowWithdrawalCodesMutation(
  $walletId: ID!
) {
  showWithdrawalCodes(walletId: $walletId) {
    agentTransactions {
      id
      amount
      isDeposit
      whenCreated
      code {
        code
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "walletId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "walletId",
        "variableName": "walletId"
      }
    ],
    "concreteType": "ShowWithdrawalCodes",
    "kind": "LinkedField",
    "name": "showWithdrawalCodes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentTransaction",
        "kind": "LinkedField",
        "name": "agentTransactions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDeposit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "whenCreated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AgentTransactionCode",
            "kind": "LinkedField",
            "name": "code",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShowWithdrawalCodesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShowWithdrawalCodesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "db70273924dab2e740c0b11aec9e347b",
    "id": null,
    "metadata": {},
    "name": "ShowWithdrawalCodesMutation",
    "operationKind": "mutation",
    "text": "mutation ShowWithdrawalCodesMutation(\n  $walletId: ID!\n) {\n  showWithdrawalCodes(walletId: $walletId) {\n    agentTransactions {\n      id\n      amount\n      isDeposit\n      whenCreated\n      code {\n        code\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ebceb96655ecc9dd32c94b8d31837983';
export default node;
