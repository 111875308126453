/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MerchantIssueType = "ADD_REMOVE_ASSISTANT" | "BUSINESS_PROPOSAL_FOLLOW_UP" | "KYB3_REQUEST" | "NOT_SEEING_PAYMENTS" | "OTHER" | "REFUND_REQUEST_MERCHANT_ERROR" | "REFUND_REQUEST_USER_ERROR" | "%future added value";
export type EscalateToMerchantSupport_user = {
    readonly possibleMerchantIssueTypes: ReadonlyArray<{
        readonly type: MerchantIssueType;
        readonly displayName: string;
    }>;
    readonly merchant: {
        readonly ufid: string;
    } | null;
    readonly " $refType": "EscalateToMerchantSupport_user";
};
export type EscalateToMerchantSupport_user$data = EscalateToMerchantSupport_user;
export type EscalateToMerchantSupport_user$key = {
    readonly " $data"?: EscalateToMerchantSupport_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EscalateToMerchantSupport_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EscalateToMerchantSupport_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MerchantIssueEntry",
      "kind": "LinkedField",
      "name": "possibleMerchantIssueTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Merchant",
      "kind": "LinkedField",
      "name": "merchant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ufid",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '26264a1f5fee7dd18ba183062c2ee117';
export default node;
