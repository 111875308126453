/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserInterface = "AGENT_PORTAL" | "API" | "AUTOMATIC" | "BUSINESS_PORTAL" | "CUSTOMER_APP_IOS" | "DISTRIBUTOR_APP" | "FRONTAPP" | "INTEGRATIONS_RECONCILIATION_PORTAL" | "KIOSK_NFC" | "KIOSK_QR" | "MERCHANT_QR" | "SLACK_TOOL" | "SMARTPHONE_APP" | "USSD" | "%future added value";
export type History_wallet = {
    readonly mobile: string;
    readonly cell: string;
    readonly user: {
        readonly devices: ReadonlyArray<{
            readonly deviceId: string;
            readonly activeBlock: {
                readonly blockReason: string;
            } | null;
        }> | null;
    } | null;
    readonly supportHistoryConnection: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string;
            readonly node: {
                readonly __typename: string;
                readonly id: string;
                readonly scamInvestigationFields: {
                    readonly deviceId: string | null;
                    readonly deviceModel: string | null;
                    readonly userInterface: UserInterface | null;
                } | null;
                readonly whenEntered?: string | undefined;
                readonly isPending?: boolean | undefined;
                readonly isCancelled?: boolean | undefined;
                readonly transferId?: string | undefined;
                readonly agentTransactionId?: string | undefined;
                readonly tcid?: string | undefined;
                readonly amount?: string | undefined;
                readonly reversedTransferId?: string | undefined;
                readonly opaqueId?: string | undefined;
                readonly sendAmount?: string | undefined;
                readonly receiveAmount?: string | undefined;
                readonly " $fragmentRefs": FragmentRefs<"PersonalHistoryEntry_entry">;
            };
        }>;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly hasPreviousPage: boolean;
            readonly startCursor: string | null;
            readonly endCursor: string | null;
        };
    };
    readonly " $refType": "History_wallet";
};
export type History_wallet$data = History_wallet;
export type History_wallet$key = {
    readonly " $data"?: History_wallet$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"History_wallet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPending",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelled",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "transferId",
    "storageKey": null
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": "reversedTransferId",
    "args": null,
    "kind": "ScalarField",
    "name": "transferId",
    "storageKey": null
  },
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "around"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "deviceId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "when"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": [
          "supportHistoryConnection"
        ]
      }
    ]
  },
  "name": "History_wallet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": "cell",
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserDevice",
          "kind": "LinkedField",
          "name": "devices",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ActiveBlock",
              "kind": "LinkedField",
              "name": "activeBlock",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "blockReason",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "supportHistoryConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "around",
          "variableName": "around"
        },
        {
          "kind": "Variable",
          "name": "deviceId",
          "variableName": "deviceId"
        },
        {
          "kind": "Variable",
          "name": "when",
          "variableName": "when"
        }
      ],
      "concreteType": "SupportHistoryConnection",
      "kind": "LinkedField",
      "name": "__History_supportHistoryConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SupportHistoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ScamInvestigationFields",
                  "kind": "LinkedField",
                  "name": "scamInvestigationFields",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deviceModel",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "userInterface",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "TransferSentEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "TransferReceivedEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "agentTransactionId",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "type": "AgentTransactionEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "BillPaymentEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tcid",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    }
                  ],
                  "type": "PayoutTransferEntry",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PersonalHistoryEntry_entry"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "whenEntered",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "type": "HistoryEntry",
                  "abstractKey": "__isHistoryEntry"
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v4/*: any*/),
                  "type": "TransferReceivedReversalEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v4/*: any*/),
                  "type": "TransferSentReversalEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v3/*: any*/),
                  "type": "PurchaseEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "opaqueId",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "type": "RemittanceTransferReversalEntry",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sendAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "receiveAmount",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "FailedMerchantTransferEntry",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
})();
(node as any).hash = '8d7307cadd8683a5f5556612eb325fb5';
export default node;
