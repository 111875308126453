import { useCallback, useEffect } from "react";

import { useLocalStorage } from "./useLocalStorage";

const COUNT_ANSWERED_CALLS_LAST_HOUR = "COUNT_ANSWERED_CALLS_LAST_HOUR";
const TOP_OF_HOUR_ANSWERED_CALLS = "TOP_OF_HOUR_ANSWERED_CALLS";
const CURRENT_CALLER = "CURRENT_CALLER";

const calcTopOfTheHour = (): number => {
    // call new Date() with Date.now() so that we can mock Date.now() in tests
    const date = new Date();
    return date.setMinutes(0, 0, 0);
};

// this is a somewhat hacky way to get the number of calls the rep answered in
// the last hour. We increment hourlyCallCount every time the "load current caller"
// button is clicked, and reset it back to 0 at the top of the hour.
// the main problem with this is that if the local storage gets cleared for whatever reason,
// the call number will not be accurate. I am hoping this is at least directionally correct
interface useStoredCallCountReturn {
    hourlyCallCount: number;
    topOfHour: number;
    incrementCallCallback: (mobile: string | null) => void;
}
export const useStoredCallCount = (): useStoredCallCountReturn => {
    const [topOfHour, getTopOfHour, setTopOfHour] = useLocalStorage(
        calcTopOfTheHour(),
        TOP_OF_HOUR_ANSWERED_CALLS
    );
    const [hourlyCallCount, getHourlyCallCount, setHourlyCallCount] =
        useLocalStorage(0, COUNT_ANSWERED_CALLS_LAST_HOUR);
    const [_, getCurrentCaller, setCurrentCaller] = useLocalStorage(
        "",
        CURRENT_CALLER
    );

    // we check every 5 seconds if the hour has turned over (i.e. 3:59 -> 4:00)
    // if so, we reset the hourlyCallCount to 0. We also use this
    // timer to check for changes from another tab
    useEffect(() => {
        const interval = setInterval(() => {
            if (getTopOfHour() !== calcTopOfTheHour()) {
                // the hour has turned over, so we should reset our counter to 0
                // or the stored call count is old (i.e. from yesterday)
                setTopOfHour(calcTopOfTheHour());
                setHourlyCallCount(0);
            }
            // in case this changed in another tab
            setHourlyCallCount(getHourlyCallCount());
        }, 5 * 1000);

        return () => clearInterval(interval);
    }, [getHourlyCallCount, setHourlyCallCount, getTopOfHour, setTopOfHour]);

    // this callback gets called when the user successfully clicks the LoadCallerButton,
    // so we increment the hourlyCallCount
    const incrementCallCallback = useCallback(
        (mobile: string | null) => {
            if (mobile === getCurrentCaller()) return;
            setHourlyCallCount(getHourlyCallCount() + 1);
            setCurrentCaller(mobile);
        },
        [
            getHourlyCallCount,
            setHourlyCallCount,
            getCurrentCaller,
            setCurrentCaller,
        ]
    );

    return { hourlyCallCount, topOfHour, incrementCallCallback };
};
