import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

// Injected by webpack
declare const SENTRY_ENV_NAME: string;
export const ENV_NAME = SENTRY_ENV_NAME;
export const IS_DEV = SENTRY_ENV_NAME === "development";
export const IS_PROD = SENTRY_ENV_NAME === "production";

declare const BACKEND_ORIGIN: string;
declare const BACKEND_PATH_PREFIX: string;
export const WAVE_ROOT_URL = new URL(
    BACKEND_PATH_PREFIX,
    BACKEND_ORIGIN || location.origin
);

declare const WEBSOCKET_ORIGIN: string;
declare const WEBSOCKET_PATH_PREFIX: string;
export const LIVE_EVENTS_URL = new URL(
    WEBSOCKET_PATH_PREFIX,
    WEBSOCKET_ORIGIN || location.origin
);
export const LIVE_EVENTS_WS_URL = new URL(LIVE_EVENTS_URL);
LIVE_EVENTS_WS_URL.protocol =
    LIVE_EVENTS_URL.protocol === "https:" ? "wss:" : "ws:";

declare const APP_VERSION_NAME: string;
export const VERSION_NAME = APP_VERSION_NAME;
