import { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";

import { NavigationLink } from "../../components/NavigationLink";
import { Modal } from "@/components/Modal";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { AgentTab_agentUser } from "@/pages/mobile.[mobile].agent/__generated__/AgentTab_agentUser.graphql";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

interface SubAgentsTableProps {
    agents: AgentTab_agentUser["agent"]["subAgents"];
}
const SubAgentsTable = ({ agents }: SubAgentsTableProps) => (
    <Table>
        <tbody>
            {agents.map((a) => (
                <tr key={a.id}>
                    <td>
                        <NavigationLink id={a.id} tab={"agent"}>
                            {a.ufid}
                        </NavigationLink>
                    </td>
                    <td>
                        <NavigationLink id={a.id} tab={"agent"}>
                            {a.name}
                        </NavigationLink>
                    </td>
                    <td>{a.agentType}</td>
                </tr>
            ))}
        </tbody>
    </Table>
);
type AgentNetworkModalProps = {
    agent: AgentTab_agentUser["agent"];
};

const _AgentNetworkModal = ({
    agent,
    show,
    onHide,
}: AgentNetworkModalProps & RelayModalProps) => {
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();

    useEffect(() => {
        trackEvent(Action.ShowAgentNetwork, {
            agentId: agent.ufid,
        });
    }, [agent.ufid, trackEvent]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            title={t("agent-network--title", { agent: agent.ufid })}
        >
            {" "}
            <p>
                {t("agent-network--rebalance")}{" "}
                {agent.rebalanceNetworkHeadAgent ? (
                    <NavigationLink
                        id={agent.rebalanceNetworkHeadAgent.id}
                        tab={"agent"}
                    >{`${agent.rebalanceNetworkHeadAgent.ufid} - ${agent.rebalanceNetworkHeadAgent.name}`}</NavigationLink>
                ) : (
                    <strong>{t("none")}</strong>
                )}
            </p>
            <p>
                {t("agent-network--managed-by")}{" "}
                {agent.managedByAgent ? (
                    <NavigationLink id={agent.managedByAgent.id} tab={"agent"}>
                        {agent.managedByAgent.ufid}
                        {" - "}
                        {agent.managedByAgent.name}
                    </NavigationLink>
                ) : (
                    <strong>{t("none")}</strong>
                )}
            </p>
            {t("agent-network--subagents")}
            <SubAgentsTable agents={agent.subAgents} />
        </Modal>
    );
};

export const AgentNetworkModal = createFragmentContainer(
    _AgentNetworkModal,
    {}
);
