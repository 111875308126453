import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import {
    fetchQuery,
    graphql,
    useLazyLoadQuery,
    useRelayEnvironment,
} from "react-relay";

import ErrorBoundary from "@/components/ErrorBoundary";

import { Flags } from "@/external/flags";
import { Flex } from "@/external/flex";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { RepInfoHeaderLoggedInStatusQuery } from "./__generated__/RepInfoHeaderLoggedInStatusQuery.graphql";

import { CallInfoBadge } from "./CallInfoBadge";
import { LiveCallMetrics } from "./LiveCallMetrics";
import { NoActionCallSurveyAction } from "./NoActionCallSurveyAction";
import { RepLoginLogoutWidget } from "./RepLoginLogoutWidget";

const loggedInStatusPollingQuery = graphql`
    query RepInfoHeaderLoggedInStatusQuery {
        supportAppUser {
            id
            isLoggedInToCallcenterQueue
        }
    }
`;

interface RepInfoHeaderProps {
    callCount: number;
    topOfHour: number;
}

export const RepInfoHeader = ({ callCount, topOfHour }: RepInfoHeaderProps) => {
    const environment = useRelayEnvironment();
    const [isRefreshingLoggedInStatus, setIsRefreshingLoggedInStatus] =
        useState(false);
    const enableNoActionSurvey =
        Flags.getFlag("show_no_action_survey")?.isActive || false;
    const showCallInfoBadge =
        Flags.getFlag("show_call_info_badge")?.isActive || false;
    const liveMetricsEnabled =
        Flags.getFlag("show_live_metrics")?.isActive || false;
    const trackEvent = useTrackEvent();

    const {
        supportAppUser: {
            isLoggedInToCallcenterQueue: isRepLoggedIn,
            id: email,
        },
    } = useLazyLoadQuery<RepInfoHeaderLoggedInStatusQuery>(
        loggedInStatusPollingQuery,
        {},
        {
            networkCacheConfig: { force: true },
            fetchPolicy: "network-only",
        }
    );

    const refreshLoggedInStatus = useCallback(() => {
        if (isRefreshingLoggedInStatus) return;

        setIsRefreshingLoggedInStatus(true);

        fetchQuery<RepInfoHeaderLoggedInStatusQuery>(
            environment,
            loggedInStatusPollingQuery,
            {}
        ).subscribe({
            complete: () => {
                setIsRefreshingLoggedInStatus(false);
            },
            error: () => {
                setIsRefreshingLoggedInStatus(false);
            },
        });
    }, [environment, isRefreshingLoggedInStatus]);

    return (
        <div className="flex items-center justify-end mt-1 mb-3 mr-2">
            {!Flex.isEmbedded ? (
                <>
                    {showCallInfoBadge && <CallInfoBadge />}
                    <ErrorBoundary>
                        {liveMetricsEnabled && (
                            <LiveCallMetrics
                                isRepLoggedIn={isRepLoggedIn}
                                hourlyCallCount={callCount}
                                topOfHour={topOfHour}
                            />
                        )}
                    </ErrorBoundary>
                    <RepLoginLogoutWidget
                        isRepLoggedIn={isRepLoggedIn}
                        email={email}
                    />
                    {isRepLoggedIn !== null && (
                        <Button
                            onClick={() => {
                                trackEvent(
                                    "reload logged in call queue status"
                                );
                                refreshLoggedInStatus();
                            }}
                            variant="outline-info"
                        >
                            &#8635;
                        </Button>
                    )}
                </>
            ) : null}

            {enableNoActionSurvey && <NoActionCallSurveyAction />}
        </div>
    );
};
