import { CopyAgentIDButton } from "../layout/Clipboard";

import { NavigationLink } from "./NavigationLink";

export const AgentProfileLink = (props: {
    agentId: string;
    agentUfid: string;
    name: string;
    opaqueId?: string;
}) => {
    if (!props.agentId) {
        return <>{props.name}</>;
    }
    return (
        <>
            <NavigationLink
                id={props.agentId}
                tab="agent"
                opaqueId={props.opaqueId}
            >
                {props.name}
            </NavigationLink>{" "}
            <CopyAgentIDButton copyText={props.agentUfid} />
        </>
    );
};
