import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Banners_banners } from "./__generated__/Banners_banners.graphql";

import { Banner } from "./Banner";

const formatDate = (date: string | null) =>
    dayjs(date).format("MMM D, YYYY HH:mm");

const title = (title: "marked" | "terminated" | "paid-out") =>
    `agent-notice-banners--termination-banner--${title}-title`;

interface TerminationBannerProps {
    agent?: NonNullable<
        NonNullable<NonNullable<Banners_banners["user"]>["agentUser"]>["agent"]
    >;
}

const TerminationBanner = ({ agent }: TerminationBannerProps) => {
    const { t } = useTranslation();
    if (!agent) return null;

    const isAgentTerminated =
        agent.whenTerminated || agent.whenTerminationPayoutCompletionConfirmed;
    const isAgentMarkedForTermination =
        agent.whenMarkedForTermination || agent.whenTerminationExpected;
    if (!(isAgentTerminated || isAgentMarkedForTermination)) return null;

    const markedTitle =
        t(title("marked")) + formatDate(agent.whenTerminationExpected);
    const terminatedTitle =
        t(title("terminated")) + formatDate(agent.whenTerminated);

    const paidOutOn = agent.whenTerminationPayoutCompletionConfirmed;
    const paidOutTitle = t(title("paid-out")) + formatDate(paidOutOn);

    return (
        <Banner
            title={
                (isAgentTerminated ? terminatedTitle : markedTitle) +
                (paidOutOn ? paidOutTitle : "")
            }
            infoType={isAgentTerminated ? "severe" : "warning"}
            icon={null}
            info={{
                whenSet: agent.whenMarkedForTermination || "",
                whenExpires: null,
                reason: agent.terminationReason || "",
                setBy: "Admin",
            }}
        />
    );
};

interface BannersProps {
    banners: Banners_banners;
}

const _Banners = (props: BannersProps) => (
    <TerminationBanner agent={props.banners.user?.agentUser?.agent} />
);

export const Banners = createFragmentContainer(_Banners, {
    banners: graphql`
        fragment Banners_banners on Wallet {
            user {
                agentUser {
                    agent {
                        whenTerminated
                        terminationReason
                        whenMarkedForTermination
                        whenTerminationExpected
                        whenTerminationPayoutCompletionConfirmed
                    }
                }
            }
        }
    `,
});
