/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BlockSubject = "DEFAULT" | "DEVICE" | "LEGAL_ENTITY" | "MOBILE" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type BlockUnblockDevice_user = {
    readonly id: string;
    readonly mobile: string | null;
    readonly devices: ReadonlyArray<{
        readonly deviceId: string;
        readonly deviceName: string;
        readonly deviceModel: string;
        readonly blockHistory: ReadonlyArray<{
            readonly whenBlocked: string;
            readonly blockedBy: string;
            readonly blockReason: string;
            readonly whenUnblocked: string | null;
            readonly unblockedBy: string | null;
            readonly unblockReason: string | null;
            readonly blockSubject: BlockSubject | null;
        }>;
        readonly activeBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
            readonly requestToModifyBlockTickets: ReadonlyArray<{
                readonly status: TicketStatus;
                readonly whenOpened: string;
            }> | null;
            readonly " $fragmentRefs": FragmentRefs<"RequestToModifyBlock_activeBlock">;
        } | null;
    }> | null;
    readonly " $refType": "BlockUnblockDevice_user";
};
export type BlockUnblockDevice_user$data = BlockUnblockDevice_user;
export type BlockUnblockDevice_user$key = {
    readonly " $data"?: BlockUnblockDevice_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BlockUnblockDevice_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlockUnblockDevice_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserDevice",
      "kind": "LinkedField",
      "name": "devices",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Block",
          "kind": "LinkedField",
          "name": "blockHistory",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "whenUnblocked",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unblockedBy",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unblockReason",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "blockSubject",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActiveBlock",
          "kind": "LinkedField",
          "name": "activeBlock",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "whenExpires",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TicketShortInfo",
              "kind": "LinkedField",
              "name": "requestToModifyBlockTickets",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenOpened",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RequestToModifyBlock_activeBlock"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'da315d1088e8a5cbc335cbe0fc64b501';
export default node;
