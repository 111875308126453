import { Fragment, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
    AsyncTypeahead,
    Menu,
    MenuItem as TypeaheadMenuItem,
} from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";
import { useCountry } from "@/hooks/useCountry";
import { useOnMount } from "@/hooks/useOnMount";

import i18n from "@/locales/i18n";

import {
    AgentInfo,
    AgentUser,
    MerchantInfo,
    Profile,
    ProfileSearcherProps,
    RESULT_SEPARATOR,
    SearchResult,
    WalletInfo,
} from "@/types/ProfileSearcher";
import { COUNTRIES, Country } from "@/types/country";

import { M } from "@/utils/currency";
import { Formula } from "@/utils/formula";
import { isValidMobile } from "@/utils/mobile";
import { notEmpty } from "@/utils/value";

import { ProfileSearcherQuery } from "./__generated__/ProfileSearcherQuery.graphql";
import { ProfileSearcher_agent } from "./__generated__/ProfileSearcher_agent.graphql";
import { ProfileSearcher_merchant } from "./__generated__/ProfileSearcher_merchant.graphql";
import { ProfileSearcher_wallet } from "./__generated__/ProfileSearcher_wallet.graphql";

const SEARCH_DEBOUNCE_DELAY_MILLIS = 700;

// region GRAPHQL FRAGMENTS
// relay's babel plugin forces us to declare fragments separately
graphql`
    fragment ProfileSearcher_wallet on Wallet @relay(mask: false) {
        id
        mobile
        name
        balance
        sendFeeFormula
        whenTerminated
    }
`;

graphql`
    fragment ProfileSearcher_agent on Agent @relay(mask: false) {
        id
        name
        city
        subcity
        ufid
        isActive
        floatWallet {
            id
            balance
        }
    }
`;

graphql`
    fragment ProfileSearcher_merchant on Merchant @relay(mask: false) {
        id
        name
        city
        subcity
        ufid
        isActive
        principal {
            mobile
        }
    }
`;
// endregion

export const profileSearcherQuery = graphql`
    query ProfileSearcherQuery(
        $query: String!
        $wallets: Boolean!
        $agents: Boolean!
        $merchants: Boolean!
    ) {
        searchWallets(query: $query) @include(if: $wallets) {
            ...ProfileSearcher_wallet @relay(mask: false)
        }
        searchAgents(query: $query) @include(if: $agents) {
            ...ProfileSearcher_agent @relay(mask: false)
        }
        searchAgentCities(query: $query) @include(if: $agents) {
            ...ProfileSearcher_agent @relay(mask: false)
        }
        searchMerchants(query: $query) @include(if: $merchants) {
            ...ProfileSearcher_merchant @relay(mask: false)
        }
    }
`;

const getTypeaheadLabel = (profile: SearchResult): string => {
    switch (profile.kind) {
        case "Agent": {
            let label = profile.agent.name;
            if (profile.agent.subcity) {
                label = `${label} (${profile.agent.subcity}) ${RESULT_SEPARATOR} ${profile.agent.ufid}`;
            }
            if (!profile.agent.isActive) {
                label += ` ${i18n.t("profile-searcher--terminated")}`;
            }
            return label;
        }
        case "Wallet": {
            let label = profile.mobile;
            // remove the suffix from the mobile for terminated wallets
            label = profile.mobile.split("_terminated")[0];
            if (profile.wallet.name) {
                label = `${label} ${RESULT_SEPARATOR} ${profile.wallet.name}`;
            }
            if (profile.wallet.whenTerminated) {
                label = `${label} ${i18n.t("profile-searcher--terminated")}`;
            }
            return label;
        }
        case "Merchant": {
            const description = [profile.name, profile.subcity]
                .filter((x) => !!x)
                .join(" ");
            return `${description} (${profile.ufid}) ${
                !profile.isActive ? i18n.t("profile-searcher--terminated") : ""
            }`;
        }
        case "UnregisteredUser": {
            return `${profile.mobile} ${i18n.t(
                "profile-searcher--unregistered"
            )}`;
        }
    }
};

const sanitizeQuery = (query: string) => {
    /** Sometimes people paste typeahead labels into here, so we should strip
     * off the ' • name' or ' (city)' parts of those when we do a query.
     */
    let sanitized = query.trim();
    if (sanitized.indexOf(RESULT_SEPARATOR) > 0) {
        sanitized = sanitized.split(RESULT_SEPARATOR)[0];
    }
    if (query.indexOf(" (") > 0) {
        sanitized = sanitized.split(" (")[0];
    }
    return sanitized;
};

const makeWalletInfo = (gqlWallet: ProfileSearcher_wallet) => {
    return {
        id: gqlWallet.id,
        name: gqlWallet.name,
        balance: M.fromSerialized(gqlWallet.balance),
        sendFeeFormula: Formula.parseBlob(gqlWallet.sendFeeFormula || '"0"'),
        whenTerminated: gqlWallet.whenTerminated,
    } as WalletInfo;
};

const makeAgentInfo = (gqlAgent: ProfileSearcher_agent) => {
    return {
        id: gqlAgent.id,
        name: gqlAgent.name,
        subcity: gqlAgent.subcity,
        city: gqlAgent.city,
        walletId: gqlAgent.floatWallet.id,
        ufid: gqlAgent.ufid,
        isActive: gqlAgent.isActive,
        balance: M.fromSerialized(gqlAgent.floatWallet.balance),
    } as AgentInfo;
};

function mobileNotFromCountry(country: Country, mobile?: string) {
    if (
        mobile &&
        ((MobileNumberFormat.test(mobile) &&
            !mobile.startsWith(country.localPrefix)) ||
            (!MobileNumberFormat.test(mobile) &&
                !mobile.toLowerCase().includes(country.iso2)))
    ) {
        return true;
    }

    return false;
}

const makeProfilesFromResults = (
    query: string,
    response: ProfileSearcherQuery["response"],
    country: Country,
    allowTerminated: boolean
): SearchResult[] => {
    if (!response) {
        return [];
    }
    let result: SearchResult[] = [];

    if (response.searchWallets) {
        let wallets = response.searchWallets.filter(notEmpty);
        if (!allowTerminated) {
            wallets = wallets.filter((w) => !w.whenTerminated);
        }
        result = result.concat(
            wallets.map((option) => ({
                kind: "Wallet",
                mobile: option.mobile,
                wallet: makeWalletInfo(option),
                opaque_id: query.match(OpaqueIdFormat) ? query : undefined,
            }))
        );
    }

    let agents = (response.searchAgents || []).concat(
        response.searchAgentCities || []
    );

    const seenAgents: { [id: string]: boolean } = {};

    if (!allowTerminated) {
        agents = agents.filter((a) => a && a.isActive);
    }

    agents
        .sort((a) => (!a?.isActive ? 1 : -1))
        .forEach((agent) => {
            if (!agent || seenAgents[agent.id]) return;

            seenAgents[agent.id] = true;

            if (!agent.ufid.toLowerCase().includes(country.iso2)) return;

            result.push({
                kind: "Agent",
                agent: makeAgentInfo(agent),
                opaque_id: query.match(OpaqueIdFormat) ? query : undefined,
            } as AgentUser);
        });

    let merchants = (response.searchMerchants || [])
        .filter((x) => !!x)
        .map((gqlMerchant: ProfileSearcher_merchant | null) => {
            const mobile = gqlMerchant?.principal?.mobile || gqlMerchant?.ufid;

            if (mobileNotFromCountry(country, mobile)) return;

            return {
                kind: "Merchant",
                id: gqlMerchant?.id,
                ufid: gqlMerchant?.ufid,
                name: gqlMerchant?.name,
                subcity: gqlMerchant?.subcity,
                city: gqlMerchant?.city,
                mobile: mobile,
                isActive: gqlMerchant?.isActive,
            } as MerchantInfo;
        })
        .filter(notEmpty)
        .sort((a) => (!a.isActive ? 1 : -1));

    if (!allowTerminated) {
        merchants = merchants.filter((m) => m && m.isActive);
    }

    result.push(...merchants);
    return result;
};

type menuItemType<SearchResultType = SearchResult> = {
    index: number;
    profile: SearchResultType;
};

const renderMenu = (results: SearchResult[], menuProps: any) => {
    const nonAgents: menuItemType<Exclude<Profile, AgentUser>>[] = [];
    const agentsByCity: { [city: string]: menuItemType<AgentUser>[] } = {};
    results.forEach((profile, index) => {
        if (profile.kind != "Agent") {
            nonAgents.push({ index, profile });
        } else {
            const { city } = profile.agent;
            const arr = agentsByCity[city] || [];
            agentsByCity[city] = arr;
            arr.push({ index, profile });
        }
    });

    return (
        <Menu id="menu" {...menuProps}>
            {nonAgents.map(({ index, profile }) => (
                <TypeaheadMenuItem
                    option={profile}
                    position={index}
                    key={profile.mobile}
                >
                    {getTypeaheadLabel(profile)}
                </TypeaheadMenuItem>
            ))}
            {Object.keys(agentsByCity).map((city) => (
                <Fragment key={city}>
                    <Dropdown.Header>
                        {city !== "null"
                            ? i18n.t("profile-searcher--agents-city", {
                                  city,
                              })
                            : i18n.t("profile-searcher--agents-city-none")}
                    </Dropdown.Header>
                    {agentsByCity[city].map(({ profile, index }) => (
                        <TypeaheadMenuItem
                            option={profile}
                            position={index}
                            key={profile.agent.walletId}
                        >
                            {getTypeaheadLabel(profile)}
                        </TypeaheadMenuItem>
                    ))}
                </Fragment>
            ))}
        </Menu>
    );
};

const OpaqueIdFormat = /^(T|ATX)_[a-zA-Z0-9-_]{12}$/;
const TransferIdFormat = /^(T_[a-zA-Z0-9-_]{12}|T_[A-Z2-7]{10})$/;
const AgentTransactionIdFormat = /^(ATX_[a-zA-Z0-9-_]{12}|ATX_[A-Z2-7]{10})$/;
const AgentIdFormat = /^A\d+$/;
const MerchantIdFormat = /^M\d+$/;
const MobileNumberFormat = /^\+?\d+$/;

async function fetchProfiles(
    query: string,
    options: {
        allowWallets: boolean;
        allowAgents: boolean;
        allowMerchants: boolean;
        allowUnregistered: boolean;
        allowTerminated: boolean;
    },
    country: Country,
    environment: RelayModernEnvironment
) {
    // someone might have pasted a typeahead label in here, e.g.
    // mobile • name
    query = sanitizeQuery(query);

    const maybeMobileNumber = MobileNumberFormat.test(query);
    const isTransferId = TransferIdFormat.test(query);
    const isAgentTransactionId = AgentTransactionIdFormat.test(query);
    const isAgentId = AgentIdFormat.test(query);
    const isMerchantId = MerchantIdFormat.test(query);

    // The API doesn't return results for less than 5-digit queries, so no point in
    // making the call in the first place.
    if (maybeMobileNumber && query.replace("+", "").length < 5) {
        return [];
    }

    const result = await fetchQuery<ProfileSearcherQuery>(
        environment,
        profileSearcherQuery,
        {
            query: query,
            agents:
                options.allowAgents &&
                !maybeMobileNumber &&
                !isTransferId &&
                !isMerchantId,
            merchants:
                options.allowMerchants &&
                !maybeMobileNumber &&
                !isTransferId &&
                !isAgentTransactionId &&
                !isAgentId,
            wallets:
                options.allowWallets && (maybeMobileNumber || isTransferId),
        }
    ).toPromise();

    const profiles = result
        ? makeProfilesFromResults(
              query,
              result,
              country,
              options.allowTerminated
          )
        : [];

    /* If we allow unregistered #s and they typed a full number, force it
     * to appear in options list
     */
    if (
        options.allowUnregistered &&
        isValidMobile(query) &&
        !profiles.find(
            (profile) => profile.kind !== "Agent" && profile.mobile === query
        )
    ) {
        profiles.push({
            kind: "UnregisteredUser",
            mobile: query,
        });
    }

    return profiles;
}

export const ProfileSearcher = (props: ProfileSearcherProps) => {
    const { t } = useTranslation();
    const environment = useRelayEnvironment();
    const inputRef = useRef<any>();
    const { country: countryISO2 } = useCountry();
    const [selectedProfile, setSelectedProfile] = useState<
        SearchResult | undefined
    >(undefined);
    const [currentQuery, setCurrentQuery] = useState<string>("");

    const country = COUNTRIES[countryISO2.toUpperCase()];

    const searchOptions = {
        allowWallets: props.allowWallets,
        allowUnregistered: props.allowUnregistered,
        allowAgents: props.allowAgents,
        allowMerchants: props.allowMerchants,
        allowTerminated: props.allowTerminated,
    };

    /* Bit tricky, AsyncTypeahead cares about the object identity of its onSearch prop:
    https://github.com/ericgio/react-bootstrap-typeahead/issues/561
    so we need to pass some deps to useAsyncFunction so it won't keep recreating a new
    .invoke method from scratch.

    And ugh, React checks deps by object equality, not a deep comparison etc, so we need
    to turn `searchOptions` into a list of boolean flags (the `searchOptions` object
    itself gets recreated on every render).*/
    const deps = Object.values(searchOptions);
    const getProfiles = useAsyncFunction(
        (query: string) =>
            fetchProfiles(query, searchOptions, country, environment),
        [...deps, environment]
    );

    const { initialQuery, onChange, clearAfterSelection } = props;
    // If you've provided an initial value for the query prop, fetch it right after the
    // first render.
    useOnMount(() => {
        if (initialQuery) {
            getProfiles.invoke(initialQuery).then((profiles) => {
                if (profiles && profiles.length === 1) {
                    onChange(profiles[0]);
                    if (!clearAfterSelection) {
                        setSelectedProfile(profiles[0]);
                    }
                }
            });
        }
    });

    return (
        <AsyncTypeahead
            id="user-search"
            selected={selectedProfile ? [selectedProfile] : []}
            options={getProfiles.result ?? []}
            inputProps={{ spellCheck: false }}
            isLoading={getProfiles.isLoading}
            labelKey={getTypeaheadLabel}
            disabled={props.disabled}
            filterBy={() => true}
            ref={(typeahead: any) => (inputRef.current = typeahead)}
            onSearch={(query) => {
                setCurrentQuery(query);
                getProfiles.invoke(query);
            }}
            delay={SEARCH_DEBOUNCE_DELAY_MILLIS}
            onChange={(options) => {
                const selection = options.length > 0 ? options[0] : undefined;

                const sanitizedQuery = sanitizeQuery(currentQuery);
                if (
                    TransferIdFormat.test(sanitizedQuery) ||
                    AgentTransactionIdFormat.test(sanitizedQuery)
                ) {
                    props.onChange(selection, sanitizedQuery);
                } else {
                    props.onChange(selection);
                }

                if (selection) {
                    // you don't want to keep typing here after selecting
                    inputRef.current?.blur();
                }
                if (!props.clearAfterSelection) {
                    setSelectedProfile(selection);
                }
            }}
            useCache={false}
            renderMenu={renderMenu}
            autoFocus={props.autoFocus}
            emptyLabel={
                getProfiles.error
                    ? `${t("profile-searcher--error")} ${
                          getProfiles.error.message
                      }`
                    : t("profile-searcher--not-found")
            }
        />
    );
};
