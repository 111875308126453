import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { NavigationLink } from "@/components/NavigationLink";

import { notEmpty } from "@/utils/value";

import { MerchantUsersList_merchant } from "./__generated__/MerchantUsersList_merchant.graphql";

const _MerchantUsersList = (props: {
    merchant: MerchantUsersList_merchant;
}) => {
    const { t } = useTranslation();
    const { assistants: _assistants, principal } = props.merchant;

    // relay freezes props; clone this so we can sort
    let assistants = _assistants?.filter(notEmpty)?.slice() ?? [];
    assistants = principal
        ? assistants.sort((a) => (a.id === principal.id ? -1 : 1))
        : assistants;

    return (
        <ListGroup>
            {assistants.map((mu) => (
                <ListGroupItem key={mu.id}>
                    <NavigationLink
                        mobile={mu.mobile}
                        tab={principal.id === mu.id ? "personal" : undefined}
                    >
                        {mu.name}{" "}
                        {principal.id === mu.id &&
                            t("merchant-users-list--principal")}
                    </NavigationLink>
                    <span className="float-right">{mu.mobile}</span>
                </ListGroupItem>
            ))}
        </ListGroup>
    );
};

export const MerchantUsersList = createFragmentContainer(_MerchantUsersList, {
    merchant: graphql`
        fragment MerchantUsersList_merchant on Merchant {
            principal {
                id
                name
                contactMobile
            }
            assistants {
                id
                name
                mobile
            }
        }
    `,
});
