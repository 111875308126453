/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentOverdraftOfferStatus = "APPROVED" | "CANCELED" | "DISABLED" | "PENDING" | "%future added value";
export type AgentType = "BANK" | "CASHIER" | "CASH_IN_TRANSIT" | "HEAD_OFFICE" | "MASTER" | "OUTLET" | "PREMIUM" | "PREMIUM_MASTER" | "REGULAR" | "SAFE" | "SINGLETON" | "SUPERAGENT" | "TEST" | "%future added value";
export type Currency = "CAD" | "CFA" | "ETB" | "GBP" | "GHS" | "GMD" | "KES" | "NGN" | "QQD" | "TZS" | "UGX" | "UNIT" | "USD" | "XAF" | "%future added value";
export type PartnerOrg = "BF_ORABANK" | "BF_UBA" | "BJ_ORABANK" | "BJ_UBA" | "CI_ECOBANK" | "CI_ORABANK" | "CI_UBA" | "CM_CBC" | "ET_SMFI" | "GM_ACCESSBANK" | "GM_MEGABANK" | "ML_ORABANK" | "ML_UBA" | "NE_ORABANK" | "QQ_DUMMYEMI" | "QQ_FAKEBANK" | "SN_ECOBANK" | "SN_UBA" | "SN_WDF" | "TG_ORABANK" | "UG_EQUITYBANK" | "UNKNOWN__" | "%future added value";
export type AgentTab_agentUser = {
    readonly isActive: boolean;
    readonly agent: {
        readonly id: string;
        readonly ufid: string;
        readonly name: string;
        readonly city: string | null;
        readonly subcity: string | null;
        readonly region: string | null;
        readonly isActive: boolean;
        readonly isReported: boolean;
        readonly isCompliant: boolean | null;
        readonly whenTerminated: string | null;
        readonly currency: Currency;
        readonly commissionStructureName: string | null;
        readonly commissionUrl: string | null;
        readonly principal: {
            readonly id: string;
        };
        readonly agentOnAgentGamingWatchlist: {
            readonly isOnList: boolean;
            readonly whenUpdated: string | null;
        };
        readonly agentOnAgentGamingWhitelist: {
            readonly isOnList: boolean;
            readonly whenUpdated: string | null;
        };
        readonly openingHoursDisplay: string | null;
        readonly agentType: AgentType | null;
        readonly recoveryBalance: string | null;
        readonly limitBalMin: string | null;
        readonly overdraftOffer: {
            readonly status: AgentOverdraftOfferStatus;
        } | null;
        readonly subAgents: ReadonlyArray<{
            readonly id: string;
            readonly ufid: string;
            readonly name: string;
            readonly agentType: AgentType | null;
        }>;
        readonly managedByAgent: {
            readonly id: string;
            readonly ufid: string;
            readonly name: string;
        } | null;
        readonly rebalanceNetworkHeadAgent: {
            readonly id: string;
            readonly ufid: string;
            readonly name: string;
        } | null;
        readonly floatWallet: {
            readonly partnerOrg: PartnerOrg | null;
            readonly photoEditUrl: string | null;
            readonly id: string;
            readonly balance?: string | undefined;
            readonly mobile: string;
            readonly name: string;
        };
        readonly coords: {
            readonly lng: number;
            readonly lat: number;
        } | null;
        readonly activeRebalanceLimit: {
            readonly rebalanceLimit: string;
            readonly whenActiveFrom: string;
            readonly whenActiveTo: string | null;
            readonly note: string;
            readonly isCurrentlySuspended: boolean;
            readonly suspensions: ReadonlyArray<{
                readonly whenSuspendedFrom: string;
                readonly whenSuspendedTo: string;
            }>;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"AgentHistory_agent" | "AgentUsersList_agent" | "CreateAgentTask_agent" | "RequestToExplainCommissionCuts_agent">;
    };
    readonly user: {
        readonly id: string;
        readonly mobile: string | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"ReportAgentIssue_agentUser" | "ReportUserComplaint_agentUser" | "AgentTransaction_agentUser" | "CreateAgentAssistant_agentUser">;
    readonly " $refType": "AgentTab_agentUser";
};
export type AgentTab_agentUser$data = AgentTab_agentUser;
export type AgentTab_agentUser$key = {
    readonly " $data"?: AgentTab_agentUser$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentTab_agentUser">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ufid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOnList",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenUpdated",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentType",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "canReadBalance"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "canReadHistory"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "historyAround"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeCancelled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "when"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentTab_agentUser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Agent",
      "kind": "LinkedField",
      "name": "agent",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subcity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "region",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isReported",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCompliant",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenTerminated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commissionStructureName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commissionUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "principal",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentGamingListEntry",
          "kind": "LinkedField",
          "name": "agentOnAgentGamingWatchlist",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentGamingListEntry",
          "kind": "LinkedField",
          "name": "agentOnAgentGamingWhitelist",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "format",
              "value": "LONG"
            }
          ],
          "kind": "ScalarField",
          "name": "openingHoursDisplay",
          "storageKey": "openingHoursDisplay(format:\"LONG\")"
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recoveryBalance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limitBalMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentOverdraftOffer",
          "kind": "LinkedField",
          "name": "overdraftOffer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "subAgents",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "managedByAgent",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "rebalanceNetworkHeadAgent",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Wallet",
          "kind": "LinkedField",
          "name": "floatWallet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "partnerOrg",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "photoEditUrl",
              "storageKey": null
            },
            (v1/*: any*/),
            (v7/*: any*/),
            (v3/*: any*/),
            {
              "condition": "canReadBalance",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "balance",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Coordinates",
          "kind": "LinkedField",
          "name": "coords",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lng",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lat",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentRebalanceLimit",
          "kind": "LinkedField",
          "name": "activeRebalanceLimit",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rebalanceLimit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "whenActiveFrom",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "whenActiveTo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "note",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCurrentlySuspended",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AgentRebalanceLimitSuspension",
              "kind": "LinkedField",
              "name": "suspensions",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenSuspendedFrom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenSuspendedTo",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "canReadHistory",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "around",
                  "variableName": "historyAround"
                },
                {
                  "kind": "Variable",
                  "name": "includeCancelled",
                  "variableName": "includeCancelled"
                },
                {
                  "kind": "Literal",
                  "name": "last",
                  "value": 30
                },
                {
                  "kind": "Variable",
                  "name": "when",
                  "variableName": "when"
                }
              ],
              "kind": "FragmentSpread",
              "name": "AgentHistory_agent"
            }
          ]
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AgentUsersList_agent"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAgentTask_agent"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RequestToExplainCommissionCuts_agent"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportAgentIssue_agentUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportUserComplaint_agentUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgentTransaction_agentUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateAgentAssistant_agentUser"
    }
  ],
  "type": "AgentUser",
  "abstractKey": null
};
})();
(node as any).hash = 'c46bf1ca1c10bbf12d5da63b76f84e2e';
export default node;
