import { useContext } from "react";

import { FieldProps } from "@/types/FormFieldProps";
import { Profile, UseProfileSearcherResult } from "@/types/ProfileSearcher";

import { FormContext } from "./Form";
import { FormGroup } from "./FormGroup";
import { ProfileSearcher } from "./ProfileSearcher";

export type ProfileSearchFieldProps = FieldProps<Profile | null> &
    UseProfileSearcherResult;
/**
 * Component to search profiles.
 * This is a wrapper around `@/components/ProfileSearcher` specifically for forms.
 *
 * Use `useProfileSearchField` to initialise the state, and prop-spread the result.
 */
export const ProfileSearchField = (props: ProfileSearchFieldProps) => {
    const { name, onChange, label, validations } = props;
    const handleFocus = (event: any) => {
        event.target.setSelectionRange(0, event.target.value.length);
    };
    const context = useContext(FormContext);
    return (
        <FormGroup label={label} name={name} validations={validations}>
            <ProfileSearcher
                {...props}
                disabled={context.isDisabled}
                onFocus={handleFocus}
                onChange={onChange}
            />
        </FormGroup>
    );
};
