import React from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ActionButton } from "@/components/ActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { SendAgentAppLinkMutator } from "@/pages/mobile.[mobile].agent/SendAgentAppLink";

import { AgentUsersList_agent } from "./__generated__/AgentUsersList_agent.graphql";

type AgentUserList = Exclude<AgentUsersList_agent["agentUsers"], null>;
type AgentUser = Exclude<AgentUserList[0], null>;

export const AgentAppStatus = ({ agentUser }: { agentUser: AgentUser }) => {
    const { t } = useTranslation();
    const { user, agentAppVersion } = agentUser;
    const isUpdateAppLinkAllowed = usePermissions(
        "supportapp_send_agent_app_link"
    );
    let text = "";
    let variant: BadgeProps["variant"] = "primary";
    let isUpdateAvailable = false;

    if (!agentAppVersion) {
        text = t("agent-app-status--no-app");
        variant = "danger";
    } else if (agentAppVersion.isUpToDate) {
        text = t("agent-app-status--version", {
            version: agentAppVersion.string,
        });
    } else {
        text = t("agent-app-status--update", {
            version: agentAppVersion.string,
        });
        variant = "warning";
        isUpdateAvailable = true;
    }

    if (isUpdateAppLinkAllowed && isUpdateAvailable) {
        return (
            <ActionButton name={text} as="badge" badgeVariant={variant}>
                <SendAgentAppLinkMutator
                    name={user.name}
                    mobile={user.contactMobile}
                />
            </ActionButton>
        );
    }

    return (
        <Badge className="text-capitalize" variant={variant}>
            {text}
        </Badge>
    );
};
