/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ChangeRecipient_transfer = {
    readonly id: string;
    readonly transferId: string;
    readonly amount: string;
    readonly receiveAmount: string;
    readonly recipientMobile: string;
    readonly recipientName: string;
    readonly " $refType": "ChangeRecipient_transfer";
};
export type ChangeRecipient_transfer$data = ChangeRecipient_transfer;
export type ChangeRecipient_transfer$key = {
    readonly " $data"?: ChangeRecipient_transfer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ChangeRecipient_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeRecipient_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiveAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientName",
      "storageKey": null
    }
  ],
  "type": "MobileTransferEntry",
  "abstractKey": "__isMobileTransferEntry"
};
(node as any).hash = '76761c1dc333ef63383ea3608b272d1c';
export default node;
