import * as React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RelayProp, createFragmentContainer, graphql } from "react-relay";

import { Timestamp } from "../../components/Timestamp";

import { notEmpty } from "@/utils/value";

import { DirectDepositBlockHistory_user } from "./__generated__/DirectDepositBlockHistory_user.graphql";

type DirectDepositBlockHistoryParams = {
    user: DirectDepositBlockHistory_user;
    relay?: RelayProp;
};

const _DirectDepositBlockHistory = (props: DirectDepositBlockHistoryParams) => {
    const { t } = useTranslation();

    if (props.user.narrativeDirectDepositBlockHistory.length == 0) {
        return <></>;
    }

    return (
        <div>
            <h3>{t("direct-deposit-block-history--title")}</h3>

            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>
                            {t("direct-deposit-block-history--when-blocked")}
                        </th>
                        <th>
                            {t("direct-deposit-block-history--what-blocked")}
                        </th>
                        <th>{t("direct-deposit-block-history--blocked-by")}</th>
                        <th>
                            {t("direct-deposit-block-history--why-blocked")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.user.narrativeDirectDepositBlockHistory
                        .filter(notEmpty)
                        .map((block, i) => (
                            <tr key={i}>
                                <td>
                                    <Timestamp
                                        value={new Date(block.whenHappened)}
                                        format={"MMM, DD YYYY HH:mm"}
                                    />
                                </td>
                                <td>{block.whatHappened}</td>
                                <td>{block.doneBy}</td>
                                <td>{block.reason}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
};

export const DirectDepositBlockHistory = createFragmentContainer(
    _DirectDepositBlockHistory,
    {
        user: graphql`
            fragment DirectDepositBlockHistory_user on User {
                id
                narrativeDirectDepositBlockHistory {
                    whatHappened
                    whenHappened
                    doneBy
                    reason
                }
            }
        `,
    }
);
