/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SendQueryVariables = {
    mobile: string;
};
export type SendQueryResponse = {
    readonly wallet: {
        readonly id: string;
        readonly name: string;
        readonly balance: string;
        readonly sendFeeFormula: string | null;
        readonly receiveFeeFormula: string | null;
        readonly internationalFeeFormulas: ReadonlyArray<{
            readonly destCountry: string;
            readonly sendFeeFormula: string | null;
            readonly receiveFeeFormula: string | null;
            readonly sendFeeFormulaDescription: string;
        }>;
        readonly multicurrencyFeeFormulas: ReadonlyArray<{
            readonly destCountry: string;
            readonly sendFeeFormula: string | null;
            readonly receiveFeeFormula: string | null;
            readonly sendFeeFormulaDescription: string;
            readonly exchangeToForeignFormula: string;
            readonly exchangeFromForeignFormula: string;
            readonly fxRate: string;
            readonly fxRateDescription: string | null;
        }>;
    } | null;
};
export type SendQuery = {
    readonly response: SendQueryResponse;
    readonly variables: SendQueryVariables;
};



/*
query SendQuery(
  $mobile: String!
) {
  wallet(mobile: $mobile) {
    id
    name
    balance
    sendFeeFormula
    receiveFeeFormula
    internationalFeeFormulas {
      destCountry
      sendFeeFormula
      receiveFeeFormula
      sendFeeFormulaDescription
    }
    multicurrencyFeeFormulas {
      destCountry
      sendFeeFormula
      receiveFeeFormula
      sendFeeFormulaDescription
      exchangeToForeignFormula
      exchangeFromForeignFormula
      fxRate
      fxRateDescription
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendFeeFormula",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "receiveFeeFormula",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destCountry",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sendFeeFormulaDescription",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      }
    ],
    "concreteType": "Wallet",
    "kind": "LinkedField",
    "name": "wallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "balance",
        "storageKey": null
      },
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "CountryFeeFormula",
        "kind": "LinkedField",
        "name": "internationalFeeFormulas",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MulticurrencyFeeFormula",
        "kind": "LinkedField",
        "name": "multicurrencyFeeFormulas",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "exchangeToForeignFormula",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "exchangeFromForeignFormula",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fxRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fxRateDescription",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "d2f83231ffd8e3ab9a51e1e895985e52",
    "id": null,
    "metadata": {},
    "name": "SendQuery",
    "operationKind": "query",
    "text": "query SendQuery(\n  $mobile: String!\n) {\n  wallet(mobile: $mobile) {\n    id\n    name\n    balance\n    sendFeeFormula\n    receiveFeeFormula\n    internationalFeeFormulas {\n      destCountry\n      sendFeeFormula\n      receiveFeeFormula\n      sendFeeFormulaDescription\n    }\n    multicurrencyFeeFormulas {\n      destCountry\n      sendFeeFormula\n      receiveFeeFormula\n      sendFeeFormulaDescription\n      exchangeToForeignFormula\n      exchangeFromForeignFormula\n      fxRate\n      fxRateDescription\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd572b8efdb2636d765941a42e13c4b81';
export default node;
