import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { ReportB2WOrW2BTransferCustomerProblemeMutationResponse } from "./__generated__/ReportB2WOrW2BTransferCustomerProblemeMutation.graphql";

const mutation = graphql`
    mutation ReportB2WOrW2BTransferCustomerProblemeMutation(
        $transactionId: ID!
        $comments: String!
    ) {
        reportB2wOrW2bCustomerProblem(
            comments: $comments
            transactionId: $transactionId
        ) {
            result
        }
    }
`;

type ReportB2WOrW2BTransferCustomerProblemeMutationInputParams = {
    transactionId: string;
    comments?: string;
    title?: string;
};

const _ReportB2WOrW2BTransferCustomerProblemMutator = (
    props: ReportB2WOrW2BTransferCustomerProblemeMutationInputParams &
        RelayModalProps
) => {
    const { onHide, transactionId, comments } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ReportB2WOrW2BTransferCustomerProblemeMutationResponse) => {
            return response.reportB2wOrW2bCustomerProblem?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportB2wOrW2bCustomerProblem,
            data: { transactionId },
        },
        ...props,
    });
    const transferIdField = useTextField();
    const commentsField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            transactionId: transferIdField.value,
            comments: commentsField.value,
        });
    }, [mutator, transferIdField.value, commentsField.value]);

    const commentsValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (commentsField.value.length < 3) {
            validations.push({
                type: "error",
                display: "if-blurred",
                message: t("form-validation--missing-coments"),
            } as Validation);
        }
        return validations;
    }, [t, commentsField.value]);

    const isValid = useMemo(
        () => !hasError([commentsValidations]),
        [commentsValidations]
    );

    return (
        <MutatorModal
            {...mutator}
            title={t("report-b2w-problem--title", {
                title: props.title || "B2W/W2B",
            })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...transferIdField}
                    label={t("label-transaction-id")}
                    name="transferId"
                    validations={[]}
                    defaultValue={transactionId}
                    disabled
                />

                <TextField
                    {...commentsField}
                    label={t("label-comments")}
                    name="comments"
                    validations={commentsValidations}
                    defaultValue={comments}
                    as="textarea"
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};

export const ReportB2WOrW2BTransferCustomerProblemMutator =
    createFragmentContainer(_ReportB2WOrW2BTransferCustomerProblemMutator, {});
