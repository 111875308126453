/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RenameWallet_wallet = {
    readonly id: string;
    readonly name: string;
    readonly mobile: string;
    readonly user: {
        readonly name: string;
    } | null;
    readonly " $refType": "RenameWallet_wallet";
};
export type RenameWallet_wallet$data = RenameWallet_wallet;
export type RenameWallet_wallet$key = {
    readonly " $data"?: RenameWallet_wallet$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RenameWallet_wallet">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RenameWallet_wallet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
})();
(node as any).hash = 'b0d55c8666243c6b2d38ed6e8af2abb5';
export default node;
