import { t } from "i18next";
import _ from "lodash";
import * as React from "react";
import { useCallback, useState } from "react";
import { Badge, BadgeProps, Dropdown, Modal } from "react-bootstrap";

import { useCurrentCall } from "@/hooks/useCurrentCall";
import { usePermissions } from "@/hooks/usePermissions";

import { Button } from "./Button";
import ErrorBoundary from "./ErrorBoundary";

// whether or not the action is allowed in the scenario when the user is calling on VOIP
// (we can't guarantee that voip callers are calling from the wave account phone number)
export enum VOIPVerificationLevel {
    FORBIDDEN = "FORBIDDEN", // an action that a voip caller should never need to do
    REQUIRES_CALLBACK = "REQUIRES_CALLBACK", // a risky action that requires a callback on the user's wave account number
    ALLOWED = "ALLOWED", // allowed for all voip callers
}
export type ActionButtonType = "button" | "menuitem" | "badge";
type ActionButtonProps = {
    name: string;
    children: React.ReactNode;
    as?: ActionButtonType;
    onShow?: () => void;
    onHide?: () => void;
    badgeVariant?: BadgeProps["variant"];
    disabled?: boolean;
    requiredPermissions?: string[];
    variant?: string;
    title?: string;
    "data-testid"?: string;
    className?: string;
    VOIPVerification?: VOIPVerificationLevel;
};

/**
 * Clickable component, shown as button (**default**), menu item (for dropdowns) or
 * badge (label). A click triggers the display of a Modal dialog which is specified
 * as a child to the component.
 *
 * This is used mostly for calling mutator components.
 * Props contain:
 *
 *  - `name` - text displayed on the `ActionButton`
 *  - `children` - a React component providing a Modal. Wrapped in an `ErrorBoundary`
 *  - `as` - how to display the `ActionButton`: `'button'` (default), `'menuitem'`, or `'badge'`
 *  - `onHide` - function to call when the Modal is closed. Can be used to force-refresh an underlying component.
 *  - `badgeVariant` - If `as == 'badge'` then one can specify the `badgeVariant` to indicate the colour
 *          of the badge. The values for `badgeVariant` are bootstrap-standard
 *          (`primary, danger, warning` etc.)
 */
export const ActionButton = ({
    name,
    children,
    as,
    onShow,
    onHide,
    badgeVariant = "primary",
    disabled = false,
    requiredPermissions = [],
    variant = "outline-dark",
    title = "",
    "data-testid": dataTestId = "action-btn",
    className = "",
    VOIPVerification = VOIPVerificationLevel.ALLOWED,
}: ActionButtonProps) => {
    const [isModalActive, setModalActive] = useState(false);
    const { currentCall } = useCurrentCall();
    // is the current call a voip call?
    const isVoip = currentCall.callType != "none" && currentCall.isVoip;
    // some actions aren't allowed if the user is calling over voip
    const disabledBecauseVoipCaller =
        isVoip && VOIPVerification == VOIPVerificationLevel.FORBIDDEN;
    const isAllowed = usePermissions(...requiredPermissions);
    const isActive =
        !disabled &&
        (requiredPermissions.length === 0 || isAllowed) &&
        !disabledBecauseVoipCaller;
    const handleShow = useCallback(() => {
        if (onShow) {
            onShow();
        }
        setModalActive(true);
    }, [onShow]);
    const handleHide = useCallback(() => {
        setModalActive(false);
        if (onHide) {
            onHide();
        }
    }, [onHide]);

    let node: React.ReactElement<any>;

    if (_.isNil(as) || as === "button") {
        node = (
            <Button
                onClick={handleShow}
                variant={variant}
                disabled={!isActive}
                title={title}
                data-testid={dataTestId}
                className={className}
            >
                {name}
            </Button>
        );
    } else if (as == "menuitem") {
        node = (
            <Dropdown.Item
                onClick={handleShow}
                disabled={!isActive}
                data-testid={dataTestId}
                className={className}
            >
                {name}
            </Dropdown.Item>
        );
    } else {
        node = (
            <Badge
                onClick={isActive ? handleShow : undefined}
                className={`cursor-pointer text-white ${className}`}
                as="a"
                variant={isActive ? badgeVariant : "secondary"}
            >
                {name}
            </Badge>
        );
    }

    if (isVoip && VOIPVerification == VOIPVerificationLevel.REQUIRES_CALLBACK) {
        children = (
            <Modal show={isModalActive} onHide={handleHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("voip-callback--title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("voip-callback--body")}</Modal.Body>
            </Modal>
        );
    }

    children = isModalActive
        ? React.Children.map(children, (child) =>
              React.cloneElement(child as React.ReactElement<any>, {
                  show: isModalActive,
                  onHide: handleHide,
              })
          )
        : null;

    return (
        <>
            {node}
            <ErrorBoundary>{children}</ErrorBoundary>
        </>
    );
};
