/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AgentGamingListOperation = "ADD" | "REMOVE" | "%future added value";
export type ModifyAgentGamingAgentWhitelistMutationVariables = {
    agentId: string;
    operation: AgentGamingListOperation;
    comment?: string | null | undefined;
};
export type ModifyAgentGamingAgentWhitelistMutationResponse = {
    readonly modifyAgentGamingAgentWhitelist: {
        readonly success: boolean;
    } | null;
};
export type ModifyAgentGamingAgentWhitelistMutation = {
    readonly response: ModifyAgentGamingAgentWhitelistMutationResponse;
    readonly variables: ModifyAgentGamingAgentWhitelistMutationVariables;
};



/*
mutation ModifyAgentGamingAgentWhitelistMutation(
  $agentId: ID!
  $operation: AgentGamingListOperation!
  $comment: String
) {
  modifyAgentGamingAgentWhitelist(agentId: $agentId, operation: $operation, comment: $comment) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "operation"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "agentId",
        "variableName": "agentId"
      },
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "operation",
        "variableName": "operation"
      }
    ],
    "concreteType": "ModifyAgentGamingAgentWhitelist",
    "kind": "LinkedField",
    "name": "modifyAgentGamingAgentWhitelist",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModifyAgentGamingAgentWhitelistMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ModifyAgentGamingAgentWhitelistMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "27f58911821a1ffc7547a29ea21d4c0a",
    "id": null,
    "metadata": {},
    "name": "ModifyAgentGamingAgentWhitelistMutation",
    "operationKind": "mutation",
    "text": "mutation ModifyAgentGamingAgentWhitelistMutation(\n  $agentId: ID!\n  $operation: AgentGamingListOperation!\n  $comment: String\n) {\n  modifyAgentGamingAgentWhitelist(agentId: $agentId, operation: $operation, comment: $comment) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cfdf40c288e6188a4a9c803144092d58';
export default node;
