import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ProfileByMobileQueryResponse } from "./__generated__/ProfileByMobileQuery.graphql";
import { UnregisteredMobileInfoHeader_unregisteredMobile } from "./__generated__/UnregisteredMobileInfoHeader_unregisteredMobile.graphql";

import { UnregisteredMobileBlocks } from "./UnregisteredMobileBlocks";

interface UnregisteredMobileInfoHeaderProps {
    mobile: string;
    unregisteredMobile: UnregisteredMobileInfoHeader_unregisteredMobile;
    supportCallerMetrics?: ProfileByMobileQueryResponse["supportCallerMetrics"];
}

export const _UnregisteredMobileInfoHeader = (
    props: UnregisteredMobileInfoHeaderProps
) => {
    const { t } = useTranslation();
    const { mobile, supportCallerMetrics } = props;

    return (
        <div className="text-center relative">
            <UnregisteredMobileBlocks blockData={props.unregisteredMobile} />
            <div className="flex justify-center">
                <div className="flex flex-1 flex-col justify-center">
                    <h4>
                        <span id="user_name">{t("no-user-found")}</span>
                    </h4>
                    <h6 id="mobile_and_email">{mobile}</h6>
                </div>
            </div>

            <div className="absolute top-0 right-4 flex flex-col gap-2">
                {supportCallerMetrics?.numberCallsToday &&
                supportCallerMetrics?.numberCallsToday > 1 ? (
                    <span className="badge badge-info">
                        {supportCallerMetrics?.numberCallsToday} calls today
                    </span>
                ) : null}
            </div>
        </div>
    );
};

export const UnregisteredMobileInfoHeader = createFragmentContainer(
    _UnregisteredMobileInfoHeader,
    {
        unregisteredMobile: graphql`
            fragment UnregisteredMobileInfoHeader_unregisteredMobile on UnregisteredMobile {
                ...BlockUnblockUser_unregisteredMobile
                ...UnregisteredMobileBlocks_blockData
                mobile
            }
        `,
    }
);
