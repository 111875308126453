/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RequestToExplainCommissionCuts_agent = {
    readonly id: string;
    readonly ufid: string;
    readonly " $refType": "RequestToExplainCommissionCuts_agent";
};
export type RequestToExplainCommissionCuts_agent$data = RequestToExplainCommissionCuts_agent;
export type RequestToExplainCommissionCuts_agent$key = {
    readonly " $data"?: RequestToExplainCommissionCuts_agent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RequestToExplainCommissionCuts_agent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequestToExplainCommissionCuts_agent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ufid",
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
(node as any).hash = 'b270a697034bd709a5014740a5067723';
export default node;
