/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UnlinkLegalEntityMutationVariables = {
    walletId: string;
};
export type UnlinkLegalEntityMutationResponse = {
    readonly unlinkLegalEntity: {
        readonly wallet: {
            readonly id: string;
            readonly name: string;
            readonly legalEntity: {
                readonly wallets: ReadonlyArray<{
                    readonly id: string;
                }>;
            } | null;
        };
    } | null;
};
export type UnlinkLegalEntityMutation = {
    readonly response: UnlinkLegalEntityMutationResponse;
    readonly variables: UnlinkLegalEntityMutationVariables;
};



/*
mutation UnlinkLegalEntityMutation(
  $walletId: ID!
) {
  unlinkLegalEntity(walletId: $walletId) {
    wallet {
      id
      name
      legalEntity {
        wallets {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "walletId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "walletId",
        "variableName": "walletId"
      }
    ],
    "concreteType": "UnlinkLegalEntity",
    "kind": "LinkedField",
    "name": "unlinkLegalEntity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Wallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LegalEntity",
            "kind": "LinkedField",
            "name": "legalEntity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Wallet",
                "kind": "LinkedField",
                "name": "wallets",
                "plural": true,
                "selections": [
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnlinkLegalEntityMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnlinkLegalEntityMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4af28a0ad0a84f5a4be994cc7ce79f84",
    "id": null,
    "metadata": {},
    "name": "UnlinkLegalEntityMutation",
    "operationKind": "mutation",
    "text": "mutation UnlinkLegalEntityMutation(\n  $walletId: ID!\n) {\n  unlinkLegalEntity(walletId: $walletId) {\n    wallet {\n      id\n      name\n      legalEntity {\n        wallets {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '40eecaaa88ab77277181933e910af669';
export default node;
