import Modal from "react-bootstrap/cjs/Modal";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { TicketLink } from "../../components/TicketLink";
import { HistoryActionButton } from "@/components/HistoryActionButton";
import { TxInfo, TxInfoError } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { M } from "@/utils/currency";

import { BillPaymentEntry_tx } from "./__generated__/BillPaymentEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ReportCustomerScamMutator } from "../mobile.[mobile]/ReportCustomerScam";
import { ReportBillPayProblemMutator } from "./ReportBillPayProblem";

const ReportBillPayButton = ({ tx }: { tx: BillPaymentEntry_tx }) => {
    const { t } = useTranslation();
    return (
        <HistoryActionButton
            name={t("report-bill-pay--action")}
            disabled={!usePermissions("supportapp_report_bill_pay_problem")}
        >
            <ReportBillPayProblemMutator
                transactionId={tx.transferId}
                mobile={tx.senderMobile}
                billPayProblems={tx.billPayProblems}
            />
        </HistoryActionButton>
    );
};

const ReportCustomerScamButton = ({ tx }: { tx: BillPaymentEntry_tx }) => {
    const { t } = useTranslation();
    const canReportScam = usePermissions("supportapp_report_customer_scam");
    const canReportScamWithDetails = usePermissions(
        "supportapp_report_customer_scam_with_details"
    );
    return (
        <HistoryActionButton
            name={t("report-customer-scam--action")}
            disabled={!canReportScam}
        >
            <ReportCustomerScamMutator
                transactionId={tx.transferId}
                mobile={tx.maybeTerminatedSenderMobile}
                amountStolen={M.fromSerialized(tx.amount).negate()}
                scamReports={tx.scamReports}
                hideAdditionalInfoFields={!canReportScamWithDetails}
            />
        </HistoryActionButton>
    );
};

function _BillPaymentEntry(
    props: { tx: BillPaymentEntry_tx } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx } = props;

    let info = <TxInfo>{tx.summary}</TxInfo>;
    let buttons = (
        <>
            <ReportCustomerScamButton tx={tx} />
            <ReportBillPayButton tx={tx} />
        </>
    );

    if (tx.summary && tx.billId === "BT_woyofal") {
        info = (
            <TxInfo>
                {t("bill-payment-entry--meter-number")} {tx.meterNumber}
                <br />
                {tx.summary}
            </TxInfo>
        );
    }
    if (tx.billId.includes("BT_airtime") && tx.billAccount) {
        info = (
            <>
                <TxInfo>
                    {t("bill-payment-entry--mobile-number")} {tx.billAccount}
                </TxInfo>
                <TxInfo>{tx.transferId}</TxInfo>
                <TxInfo>{tx.summary}</TxInfo>
            </>
        );
        buttons = (
            <>
                <HistoryActionButton name={t("bill-payment-entry--see-sms")}>
                    <Modal>
                        <Modal.Header>
                            <Modal.Title>
                                {t("bill-payment-entry--airtime-sms")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{tx.airtimeSms}</Modal.Body>
                    </Modal>
                </HistoryActionButton>
                <ReportCustomerScamButton tx={tx} />
                <ReportBillPayButton tx={tx} />
            </>
        );
    }

    if (tx.isCancelled) {
        buttons = (
            <>
                <HistoryActionButton name="Error Details">
                    <Modal>
                        <Modal.Header>
                            <Modal.Title>Error Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Error Code: {tx.errorCode}
                            <br />
                            Description: {tx.localizedErrMessage}
                        </Modal.Body>
                    </Modal>
                </HistoryActionButton>
            </>
        );
    }

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <>
                    <TxTitle>
                        {t("bill-payment-entry--payment")} {tx.billName}{" "}
                        {tx.relatedTicket && (
                            <TicketLink
                                status={tx.relatedTicket.status}
                                slackMessageUrl={
                                    tx.relatedTicket.slackMessageUrl
                                }
                            />
                        )}
                    </TxTitle>
                    {tx.isCancelled && (
                        <TxInfoError>
                            {t("bill-payment-entry--cancelled")}
                        </TxInfoError>
                    )}
                    {info}
                </>
            }
            buttons={buttons}
        />
    );
}

export default createFragmentContainer(_BillPaymentEntry, {
    tx: graphql`
        fragment BillPaymentEntry_tx on BillPaymentEntry {
            transferId
            senderMobile
            maybeTerminatedSenderMobile
            relatedTicket {
                status
                slackMessageUrl
            }
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            billPayProblems {
                whenOpened
                billType
                status
                transactionId
            }
            amount
            billAccount
            billId
            billName
            isCancelled
            summary
            errorCode
            localizedErrMessage
            airtimeSms
            meterNumber
            ...HistoryEntry_entry
        }
    `,
});
