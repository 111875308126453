import { Experiment, ExperimentClient } from "@amplitude/experiment-js-client";
import * as Sentry from "@sentry/react";

/**
 * The model for a feature flag.
 */
export type Flag = {
    /** The name of the flag (can only contain numbers, letters and `-` and `_`). */
    key: string;
    /** Whether this particular feature flag is active/enabled or not. */
    isActive: boolean;
    /** The value for this flag. If this is a simple "toggle" flag this will be
     * either `"on"` or `"off"`. If it's something else it means the flag is on,
     * and you're seeing the value you're supposed to see. */
    value: string;
    /** Any extra values associated with this flag at a particular moment in time. This
     * comes from the server as JSON which we then parse. */
    payload?: unknown;
};

/**
 * Parses the browser's current HTTP address looking for any flags, returning them.
 * @return a {@link Map} with all the {@link Flag}s declared in the browser's address
 * box.
 */
function getFlagsFromQueryString(): Map<string, Flag> {
    const queryString = window ? window.location.search : "";
    const queryStringFlags = new URLSearchParams(queryString)
        .getAll("ff")
        .map((str) => str.split(","))
        .flat();
    const qsFlags = new Map<string, Flag>();
    queryStringFlags.forEach((qsFlag) => {
        qsFlags.set(qsFlag, { key: qsFlag, isActive: true, value: "on" });
    });
    return qsFlags;
}

/**
 * Static class used to fetch feature flag values from the server (Amplitude). Flags
 * are managed through Amplitude's UI. See {@link Flag} for details on how we model the
 * data we get from the server.
 */
export class Flags {
    private static experiment?: ExperimentClient;

    /**
     * Initialises Amplitude Experiment - the Amplitude service we use to manage feature
     * flags. Note that we assume Amplitude analytics is being used alongside this. If
     * this ever changes (we stop using Ampltiude analytics but keep using Amplitude
     * Experiment) this implementation needs to change.
     * @param key Amplitude Experiment key for this Experiment deployment.
     */
    static init(key: string) {
        this.experiment = Experiment.initializeWithAmplitudeAnalytics(key);
    }

    /**
     * Synchronise the local values for all feature flags with the server's. Note that
     * as this is an async operation, if you have any UI that is immediately visible and
     * depends on any feature flag values, you may want to delay rendering until after
     * this operation completes.
     * @param user_id the user's id. This is usually the user's email.
     */
    static async sync(user_id: string) {
        const fetchExperiment = async () => {
            const user = {
                user_id: user_id,
            };
            await this.experiment?.fetch(user);
        };
        fetchExperiment().catch((error) => {
            Sentry.captureException(error);
        });
    }

    /**
     * Check the value of a feature flag.
     * @return `true` if the feature flag identified by `key` is active, `false` if not,
     * or if the flag is not available (this can happen if the local data is not
     * synchronised with the remote data - see {@link sync}). Bear in mind that an
     * "active" feature flag is a flag that is effectively ON.
     * @param key the `string` that identifies the feature flag (no spaces).
     */
    static isFlagOn(key: string): boolean {
        const value =
            this.experiment?.variant(key).value ||
            this.maybeFlagFromQueryString(key)?.value;
        if (!value || value === "on" || value === "off") {
            return value === "on";
        }
        return true;
    }

    /**
     * Get the data pertaining to a particular {@link Flag feature flag}.
     * Note that at a particular moment in time, when you call this, the data might not
     * be what you expect as the local data might have fallen out of sync with the
     * remote data.
     * @return the {@link Flag} or `undefined` if not available.
     * @param key the `string` that identifies a particular feature flag.
     */
    static getFlag(key: string): Flag | null {
        const variant = this.experiment?.variant(key);
        const value = variant?.value;

        if (!value) {
            return this.maybeFlagFromQueryString(key);
        }

        const isActive = this.isFlagOn(key);
        return {
            key,
            isActive,
            value: value || (isActive ? "on" : "off"),
            payload: variant.payload
                ? JSON.parse(variant.payload)
                : variant.payload,
        };
    }

    private static maybeFlagFromQueryString(key: string): Flag | null {
        return getFlagsFromQueryString().has(key)
            ? {
                  key,
                  isActive: true,
                  value: "on",
                  payload: null,
              }
            : null;
    }

    /**
     * Get all the active flags. Note that this method should only be called **after**
     * {@link sync} has been called and has finished running.
     * @return a {@link Map} of all the {@link Flag}s, merged from the remote source and
     * the URL.
     */
    static getFlags(): Map<string, Flag> {
        const map = new Map<string, Flag>();
        const variants = this.experiment?.all();
        for (const key in variants) {
            const variant = variants[key];
            const value = variant?.value;
            const isActive = this.isFlagOn(key);
            map.set(key, {
                key,
                isActive,
                value: value || (isActive ? "on" : "off"),
                payload: variant.payload
                    ? JSON.parse(variant.payload)
                    : variant.payload,
            });
        }
        const qsFlags: Map<string, Flag> = getFlagsFromQueryString();
        for (const [key, flag] of qsFlags) {
            map.set(key, flag);
        }
        return map;
    }
}
