import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { usePermissions } from "@/hooks/usePermissions";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

type PersonalTabHeader_walletUser = Exclude<
    PersonalTabHeader_wallet["user"],
    null
>;

interface IdPhotosBadgeProps {
    photoEditUrl: PersonalTabHeader_wallet["photoEditUrl"];
    documentStatus: PersonalTabHeader_wallet["documentStatus"];
    needsIdPhotoConfirmation?: PersonalTabHeader_walletUser["needsIdPhotoConfirmation"];
}

export const IdPhotosBadge = (props: IdPhotosBadgeProps) => {
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();

    const needsIdPhotoConfirmation = !!props.needsIdPhotoConfirmation;
    const documentStatusText = needsIdPhotoConfirmation
        ? t("id-photos-badge--need-agent-confirmation")
        : props.documentStatus;

    return usePermissions("supportapp_photo_review") ? (
        <span className="badge badge-primary cursor-pointer">
            <a
                href={props.photoEditUrl ?? undefined}
                data-testid="photoEditAnchor"
                className="no-underline text-white"
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                    trackEvent("view id photo", {
                        needsIdPhotoConfirmation,
                        documentStatus: props.documentStatus,
                    })
                }
            >
                <span
                    data-testid="documentStatus"
                    className="text-capitalize"
                    title={t("id-photos-badge--title-review")}
                >
                    {t("id-photos-badge--description", {
                        status: documentStatusText,
                    })}
                </span>
            </a>
        </span>
    ) : (
        <Badge
            data-testid="documentStatus"
            className="text-capitalize"
            title={t("id-photos-badge--title")}
            variant="primary"
        >
            {t("id-photos-badge--description", { status: documentStatusText })}
        </Badge>
    );
};
