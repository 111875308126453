/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentTerminationReason = "DEVICE_STOLEN" | "EXTRA_FEES" | "GAMING" | "INACTIVE" | "LOW_DAILY_ACTIVE_USERS" | "LOW_VOLUME" | "MISCONDUCT" | "MISTAKENLY_OPENED" | "NON_COMPLIANT" | "OTHER" | "RECOVERY" | "REFUSING_SELF_FUNDING" | "REQUESTED_BY_AGENT" | "STAFF_MEMBER" | "WRONG_LOCATION" | "%future added value";
export type Banners_banners = {
    readonly user: {
        readonly agentUser: {
            readonly agent: {
                readonly whenTerminated: string | null;
                readonly terminationReason: AgentTerminationReason | null;
                readonly whenMarkedForTermination: string | null;
                readonly whenTerminationExpected: string | null;
                readonly whenTerminationPayoutCompletionConfirmed: string | null;
            };
        } | null;
    } | null;
    readonly " $refType": "Banners_banners";
};
export type Banners_banners$data = Banners_banners;
export type Banners_banners$key = {
    readonly " $data"?: Banners_banners$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Banners_banners">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Banners_banners",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentUser",
          "kind": "LinkedField",
          "name": "agentUser",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Agent",
              "kind": "LinkedField",
              "name": "agent",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenTerminated",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terminationReason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenMarkedForTermination",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenTerminationExpected",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenTerminationPayoutCompletionConfirmed",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
(node as any).hash = 'ad0c2559bd4d02f3665cbeaa30261117';
export default node;
