/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SupportPayoutTransferReverseMutationVariables = {
    tcid: string;
};
export type SupportPayoutTransferReverseMutationResponse = {
    readonly supportReversePayout: {
        readonly isRefunded: boolean;
    } | null;
};
export type SupportPayoutTransferReverseMutation = {
    readonly response: SupportPayoutTransferReverseMutationResponse;
    readonly variables: SupportPayoutTransferReverseMutationVariables;
};



/*
mutation SupportPayoutTransferReverseMutation(
  $tcid: ID!
) {
  supportReversePayout(tcid: $tcid) {
    isRefunded
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tcid"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tcid",
        "variableName": "tcid"
      }
    ],
    "concreteType": "SupportPayoutReversal",
    "kind": "LinkedField",
    "name": "supportReversePayout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRefunded",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportPayoutTransferReverseMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupportPayoutTransferReverseMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1818d632df3ad00ba135868e9fc9e8d6",
    "id": null,
    "metadata": {},
    "name": "SupportPayoutTransferReverseMutation",
    "operationKind": "mutation",
    "text": "mutation SupportPayoutTransferReverseMutation(\n  $tcid: ID!\n) {\n  supportReversePayout(tcid: $tcid) {\n    isRefunded\n  }\n}\n"
  }
};
})();
(node as any).hash = '1183b0340aac6eacd26610b79a71f078';
export default node;
