import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { SendRequestToBeAnAgentLinkMutationResponse } from "./__generated__/SendRequestToBeAnAgentLinkMutation.graphql";

const mutation = graphql`
    mutation SendRequestToBeAnAgentLinkMutation($mobile: String!) {
        sendRequestToBeAnAgentLink(mobile: $mobile) {
            result
        }
    }
`;

type SendRequestToBeAnAgentInputParams = {
    mobile: string;
};

const _SendRequestToBeAnAgentLinkMutator = (
    props: SendRequestToBeAnAgentInputParams & RelayModalProps
) => {
    const { onHide, mobile } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (
            response: SendRequestToBeAnAgentLinkMutationResponse
        ): string | null => {
            return response.sendRequestToBeAnAgentLink?.result || null;
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.SendRequestToBeAnAgentLink,
            data: { mobile },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { mobile });
    }, [mutator, mobile]);

    return (
        <MutatorModal
            {...mutator}
            title={t("send-request-agent-link--title", { mobile })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-confirm")}
                onSubmit={handleSubmit}
                onDone={onHide}
            ></Form>
        </MutatorModal>
    );
};

export const SendRequestToBeAnAgentLinkMutator = createFragmentContainer(
    _SendRequestToBeAnAgentLinkMutator,
    {}
);
