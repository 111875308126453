import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";

import { hasError } from "@/utils/formValidation";

import { CreateSupportNoteMutationResponse } from "./__generated__/CreateSupportNoteMutation.graphql";

const mutation = graphql`
    mutation CreateSupportNoteMutation($mobile: String!, $text: String!) {
        createSupportNote(mobile: $mobile, text: $text) {
            note {
                noteId
                mobile
                adminUsername
                text
            }
            supportNotes {
                ...NotesList_supportNotes
            }
        }
    }
`;

type CreateSupportNoteInputParams = {
    mobile: string;
};

const _CreateSupportNoteMutator = (
    props: CreateSupportNoteInputParams & RelayModalProps
) => {
    const { onHide, mobile } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: CreateSupportNoteMutationResponse) => {
            return response.createSupportNote?.note.noteId
                ? t("create-support-note--success")
                : "";
        },
        [t]
    );
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: { name: "create note", data: { mobile } },
        ...props,
    });

    const contentField = useTextField();

    const handleSubmit = useCallback(() => {
        const text = contentField.value;
        mutator.submit(mutation, { mobile, text });
    }, [mutator, mobile, contentField.value]);

    const contentValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (contentField.value.length < 3) {
            validations.push({
                type: "error",
                display: "if-blurred",
                message: t("form-validation--empty-content"),
            } as Validation);
        }
        return validations;
    }, [t, contentField.value]);

    const isValid = useMemo(() => {
        return !hasError([contentValidations]);
    }, [contentValidations]);

    return (
        <MutatorModal {...mutator} title={t("create-support-note--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-add")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...contentField}
                    label={t("label-content")}
                    name="content"
                    validations={contentValidations}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};

export const CreateSupportNoteMutator = createFragmentContainer(
    _CreateSupportNoteMutator,
    {}
);
