/**
 * NOTE(antonio): This file is symlinked inside `web/support/src/types/flex-comm-layer.ts`
 * from `web/flex/plugin/types/flex-comm-layer.ts`.
 */

/**
 * Defining the key used to identify messages coming from Flex.
 *
 * Ideally we would be using a Symbol here, but the `structuredClone` algorithm
 * used by `window.postMessage` doesn't support Symbols
 */
export const FLEX_MESSAGE_TYPE_KEY = "$__MESSAGE_TYPE__#";
export const FLEX_MESSAGE_TYPE = "flex";

/**
 * Defining a list of well-known actions that are transmitted between Flex and
 * the support app.
 */
export const FLEX_MESSAGE_ACTION_SUPPORT_INIT = "support-init";
export const FLEX_MESSAGE_TASK_INFO = "task-info";

export type FlexMessageAction =
    | typeof FLEX_MESSAGE_TASK_INFO
    | typeof FLEX_MESSAGE_ACTION_SUPPORT_INIT;

/**
 * Defining all message data that is transmitted between the support app
 * and Flex.
 */
export type SupportMessageInit = {
    action: typeof FLEX_MESSAGE_ACTION_SUPPORT_INIT;
} & { hello: "flex"; tabId: string | null };
export type SupportMessageData = SupportMessageInit;

// NOTE(antonio): We are redefining the task status type here so that we don't
// depend on `@twilio/flex-ui` as this file is symlinked into the support app.
//
// Similarly for the `TaskAnalyticsContext` type definition.
//
type TaskStatus =
    | "reserved"
    | "assigned"
    | "wrapping"
    | "completed"
    | "canceled";

export type TaskAnalyticsContext = {
    conversationSid?: string; // ITask["attributes"]["conversationSid"];
    customerMobile?: string; // ITask["attributes"]["customer"]["phone"];
    taskStatus: TaskStatus; // ITask["taskStatus"]
    channelType: string; // ITask["channelType"]
};

type CommonTaskInfo = {
    status: TaskStatus;
    customerMobile: string;
};

type ChatTaskInfo = CommonTaskInfo & {
    support_channel: "whatsapp";
    conversationSid: string;
};

type VoiceTaskInfo = CommonTaskInfo & {
    support_channel: "voice";
    callId: string;
    theirRoute: string;
};

export type TaskInfo = ChatTaskInfo | VoiceTaskInfo;

export type FlexMessageTaskInfo = {
    action: typeof FLEX_MESSAGE_TASK_INFO;
    taskInfo: TaskInfo;
};

export type FlexMessageData = FlexMessageTaskInfo;
