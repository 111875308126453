/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ReportAgentScamMutationVariables = {
    transactionId: string;
    amountStolen: string;
    customerSharedPinCode: boolean;
    customerSharedSmsCode: boolean;
    additionalInfo: string;
};
export type ReportAgentScamMutationResponse = {
    readonly reportAgentScam: {
        readonly result: string | null;
    } | null;
};
export type ReportAgentScamMutation = {
    readonly response: ReportAgentScamMutationResponse;
    readonly variables: ReportAgentScamMutationVariables;
};



/*
mutation ReportAgentScamMutation(
  $transactionId: ID!
  $amountStolen: Money!
  $customerSharedPinCode: Boolean!
  $customerSharedSmsCode: Boolean!
  $additionalInfo: String!
) {
  reportAgentScam(transactionId: $transactionId, amount: $amountStolen, customerSharedPinCode: $customerSharedPinCode, customerSharedSmsCode: $customerSharedSmsCode, additionalInfo: $additionalInfo) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "additionalInfo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountStolen"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerSharedPinCode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerSharedSmsCode"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "additionalInfo",
        "variableName": "additionalInfo"
      },
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amountStolen"
      },
      {
        "kind": "Variable",
        "name": "customerSharedPinCode",
        "variableName": "customerSharedPinCode"
      },
      {
        "kind": "Variable",
        "name": "customerSharedSmsCode",
        "variableName": "customerSharedSmsCode"
      },
      {
        "kind": "Variable",
        "name": "transactionId",
        "variableName": "transactionId"
      }
    ],
    "concreteType": "ReportAgentScam",
    "kind": "LinkedField",
    "name": "reportAgentScam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportAgentScamMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportAgentScamMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "405cfdb19a919010c4e24164b3eae211",
    "id": null,
    "metadata": {},
    "name": "ReportAgentScamMutation",
    "operationKind": "mutation",
    "text": "mutation ReportAgentScamMutation(\n  $transactionId: ID!\n  $amountStolen: Money!\n  $customerSharedPinCode: Boolean!\n  $customerSharedSmsCode: Boolean!\n  $additionalInfo: String!\n) {\n  reportAgentScam(transactionId: $transactionId, amount: $amountStolen, customerSharedPinCode: $customerSharedPinCode, customerSharedSmsCode: $customerSharedSmsCode, additionalInfo: $additionalInfo) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'adb134b8fec693b45e878daa84a305d2';
export default node;
