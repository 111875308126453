/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AppType = "AGENT_APP" | "BUSINESS_APP" | "BUSINESS_APP_IOS" | "CUSTOMER_APP_ANDROID" | "CUSTOMER_APP_IOS" | "DISTRIBUTOR_APP" | "FIELD_APP" | "INTERNAL_WEB_APP" | "ISSUER_APP" | "KIOSK_APP" | "MERCHANT_APP" | "SUPPORT_WEB_APP" | "UNKNOWN_APP" | "%future added value";
export type BlockSubject = "DEFAULT" | "DEVICE" | "LEGAL_ENTITY" | "MOBILE" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type TicketType = "AgentIssueReport" | "AgentScamReport" | "AmlMatch" | "B2WOrW2BCustomerProblem" | "BankRebalanceRequest" | "BillPayProblem" | "BusinessFundingRequest" | "KYBEscalation" | "MerchantComplianceFollowup" | "MerchantSupportEscalation" | "PaymentCardProblem" | "PoliceRequisition" | "RebalancingDifficulty" | "RefundPartialFunds" | "RefundingDispute" | "RequestToBlockWallet" | "RequestToExplainCommissionCuts" | "RequestToModifyBlockTicket" | "ScamReport" | "ScreeningResult" | "SpamCallerReport" | "SupportGroupLeadEscalation" | "%future added value";
export type UserInfoHeader_walletData = {
    readonly id: string;
    readonly name: string;
    readonly mobile: string;
    readonly whenTerminated: string | null;
    readonly reasonTerminated: string | null;
    readonly whoTerminated: string | null;
    readonly balance?: string | undefined;
    readonly amountPending?: string | null | undefined;
    readonly kycTier: string | null;
    readonly kybTier: string | null;
    readonly isTrader: boolean | null;
    readonly photoEditUrl: string | null;
    readonly documentStatus: string | null;
    readonly hasRejectedGovIds: boolean;
    readonly isRefundAbuser: boolean | null;
    readonly tickets: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly slackMessageUrl: string | null;
        readonly ticketType: TicketType;
        readonly reference: string | null;
    } | null>;
    readonly activeMobileBlock: {
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
        readonly requestToModifyBlockTickets: ReadonlyArray<{
            readonly status: TicketStatus;
        }> | null;
    } | null;
    readonly activeLegalEntityBlock: {
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
        readonly requestToModifyBlockTickets: ReadonlyArray<{
            readonly status: TicketStatus;
        }> | null;
    } | null;
    readonly scamReports: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly transactionIds: string | null;
    }>;
    readonly billPayProblems: ReadonlyArray<{
        readonly whenOpened: string;
        readonly billType: string | null;
        readonly status: TicketStatus;
        readonly transactionId: string | null;
    }>;
    readonly supportGroupLeadEscalations: ReadonlyArray<{
        readonly whenOpened: string;
        readonly status: TicketStatus;
        readonly clickupTaskId: string | null;
    }>;
    readonly merchantSupportEscalations: ReadonlyArray<{
        readonly whenOpened: string;
        readonly status: TicketStatus;
        readonly clickupTaskId: string | null;
    }>;
    readonly balanceLimitInfoV1: {
        readonly max: string;
    } | null;
    readonly inflowLimitInfoV1: {
        readonly max: string;
    } | null;
    readonly user: {
        readonly id: string;
        readonly name: string;
        readonly agentUser: {
            readonly isActive: boolean;
        } | null;
        readonly merchantUser: {
            readonly isActive: boolean;
        } | null;
        readonly promoter: {
            readonly canKioskSignup: boolean;
        } | null;
        readonly customerAppVersion: {
            readonly appType: AppType;
            readonly string: string;
            readonly isUpToDate: boolean;
        } | null;
        readonly hasQrCard: boolean;
        readonly customerOverdraftStatus: string;
        readonly qrCardBlock: {
            readonly whenBlocked: string;
        } | null;
        readonly personalMultipleDevicesBlock: {
            readonly whenBlocked: string;
        } | null;
        readonly agentMultipleDevicesBlock: {
            readonly whenBlocked: string;
        } | null;
        readonly agentDepositsBlock: {
            readonly whenBlocked: string;
        } | null;
        readonly needsIdPhotoConfirmation: {
            readonly legalName: string;
        } | null;
        readonly userOnAgentGamingWatchlist: {
            readonly isOnList: boolean;
            readonly whenUpdated: string | null;
        };
        readonly userOnAgentGamingWhitelist: {
            readonly isOnList: boolean;
            readonly whenUpdated: string | null;
        };
        readonly inPinRecovery: boolean;
        readonly hasWeakPin: boolean;
        readonly elligibleToReceivePayPromo: boolean | null;
        readonly " $fragmentRefs": FragmentRefs<"AllowToRecoverPin_user" | "UnblockFromUsingQrCards_user" | "UnblockFromUsingMultipleDevices_user" | "BlockFromAgentDeposits_user" | "UnblockFromAgentDeposits_user" | "LogoutAllUserSessions_user" | "ForgetQr_user" | "EscalateToMerchantSupport_user">;
    } | null;
    readonly legalEntity: {
        readonly wallets: ReadonlyArray<{
            readonly mobile: string;
            readonly user: {
                readonly agentUser: {
                    readonly isActive: boolean;
                } | null;
                readonly merchantUser: {
                    readonly isActive: boolean;
                } | null;
                readonly promoter: {
                    readonly canKioskSignup: boolean;
                } | null;
            } | null;
        }>;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"WalletUserBlocks_blockData" | "Banners_banners" | "ShowWithdrawalCodes_wallet" | "ShowWoyofalCodes_wallet" | "ResetPin_wallet" | "SendUpdateAppLink_wallet" | "SendWithdrawalCode_wallet" | "SendWoyofalCode_wallet" | "RenameWallet_wallet" | "CancelAllAtx_wallet" | "RequestToBlockUnblockUser_wallet" | "RequestToUnblockFromAgentDeposits_wallet" | "RequestToUnblockFromUsingMultipleDevices_wallet" | "BlockUnblockUser_wallet">;
    readonly " $refType": "UserInfoHeader_walletData";
};
export type UserInfoHeader_walletData$data = UserInfoHeader_walletData;
export type UserInfoHeader_walletData$key = {
    readonly " $data"?: UserInfoHeader_walletData$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserInfoHeader_walletData">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenOpened",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenResolved",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "blockSubject",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TicketShortInfo",
    "kind": "LinkedField",
    "name": "requestToModifyBlockTickets",
    "plural": true,
    "selections": [
      (v5/*: any*/)
    ],
    "storageKey": null
  }
],
v8 = [
  (v3/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "clickupTaskId",
    "storageKey": null
  }
],
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "max",
    "storageKey": null
  }
],
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isActive",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "AgentUser",
  "kind": "LinkedField",
  "name": "agentUser",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "MerchantUser",
  "kind": "LinkedField",
  "name": "merchantUser",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Promoter",
  "kind": "LinkedField",
  "name": "promoter",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canKioskSignup",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = [
  (v6/*: any*/)
],
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isOnList",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenUpdated",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "canReadBalance"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInfoHeader_walletData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenTerminated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reasonTerminated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whoTerminated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kycTier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kybTier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTrader",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "photoEditUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasRejectedGovIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRefundAbuser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketShortInfo",
      "kind": "LinkedField",
      "name": "tickets",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackMessageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reference",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeMobileBlock",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeLegalEntityBlock",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamReportTicket",
      "kind": "LinkedField",
      "name": "scamReports",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillPayProblemTicket",
      "kind": "LinkedField",
      "name": "billPayProblems",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billType",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SupportGroupLeadEscalationTicket",
      "kind": "LinkedField",
      "name": "supportGroupLeadEscalations",
      "plural": true,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MerchantSupportEscalationTicket",
      "kind": "LinkedField",
      "name": "merchantSupportEscalations",
      "plural": true,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LimitInfo",
      "kind": "LinkedField",
      "name": "balanceLimitInfoV1",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LimitInfo",
      "kind": "LinkedField",
      "name": "inflowLimitInfoV1",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AppVersion",
          "kind": "LinkedField",
          "name": "customerAppVersion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "string",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isUpToDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasQrCard",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerOverdraftStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActiveBlock",
          "kind": "LinkedField",
          "name": "qrCardBlock",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MultipleDevicesBlock",
          "kind": "LinkedField",
          "name": "personalMultipleDevicesBlock",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MultipleDevicesBlock",
          "kind": "LinkedField",
          "name": "agentMultipleDevicesBlock",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActiveBlock",
          "kind": "LinkedField",
          "name": "agentDepositsBlock",
          "plural": false,
          "selections": (v14/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IdDocument",
          "kind": "LinkedField",
          "name": "needsIdPhotoConfirmation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "legalName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentGamingListEntry",
          "kind": "LinkedField",
          "name": "userOnAgentGamingWatchlist",
          "plural": false,
          "selections": (v15/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentGamingListEntry",
          "kind": "LinkedField",
          "name": "userOnAgentGamingWhitelist",
          "plural": false,
          "selections": (v15/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inPinRecovery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasWeakPin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "elligibleToReceivePayPromo",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AllowToRecoverPin_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnblockFromUsingQrCards_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnblockFromUsingMultipleDevices_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BlockFromAgentDeposits_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnblockFromAgentDeposits_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LogoutAllUserSessions_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ForgetQr_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EscalateToMerchantSupport_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalEntity",
      "kind": "LinkedField",
      "name": "legalEntity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "walletType",
              "value": "PERSONAL"
            }
          ],
          "concreteType": "Wallet",
          "kind": "LinkedField",
          "name": "wallets",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "wallets(walletType:\"PERSONAL\")"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WalletUserBlocks_blockData"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Banners_banners"
    },
    {
      "condition": "canReadBalance",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "balance",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountPending",
          "storageKey": null
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShowWithdrawalCodes_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShowWoyofalCodes_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResetPin_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendUpdateAppLink_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendWithdrawalCode_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendWoyofalCode_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RenameWallet_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CancelAllAtx_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestToBlockUnblockUser_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestToUnblockFromAgentDeposits_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestToUnblockFromUsingMultipleDevices_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlockUnblockUser_wallet"
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
})();
(node as any).hash = 'd164c371af82883ee664a916494ddc48';
export default node;
