import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ProfileLink } from "@/components/ProfileLink";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { AdjustmentEntry_tx } from "./__generated__/AdjustmentEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";

function _AdjustmentEntry(
    props: {
        tx: AdjustmentEntry_tx;
    } & HistoryEntryProps
) {
    const { tx } = props;
    const { t } = useTranslation();

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <>
                    <TxTitle>
                        {tx.description ||
                            t("adjustment-entry--balance-adjustment")}{" "}
                        {tx.senderMobile && tx.recipientMobile ? (
                            <span>
                                ({t("adjustment-entry--from")}{" "}
                                <ProfileLink
                                    name={tx.senderMobile}
                                    mobile={tx.senderMobile}
                                ></ProfileLink>{" "}
                                {t("adjustment-entry--to")}{" "}
                                <ProfileLink
                                    name={tx.recipientMobile}
                                    mobile={tx.recipientMobile}
                                ></ProfileLink>
                                )
                            </span>
                        ) : null}
                    </TxTitle>
                    <TxInfo>{tx.__typename}</TxInfo>
                </>
            }
        />
    );
}

export default createFragmentContainer(_AdjustmentEntry, {
    tx: graphql`
        fragment AdjustmentEntry_tx on AdjustmentEntry {
            __typename
            description
            senderMobile
            recipientMobile
            ...HistoryEntry_entry
        }
    `,
});
