import { Card, CardDeck, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QueryRenderer, graphql, useRelayEnvironment } from "react-relay";

import { RelayModalProps } from "@/types/MutatorModal";

import { formatCurrencyAmount } from "@/utils/currency";
import { formatTimestamp } from "@/utils/time";

import { CalculateLimitsQuery } from "./__generated__/CalculateLimitsQuery.graphql";

type CalculatLimitsInputParams = {
    wallet: Exclude<CalculateLimitsQuery["response"]["wallet"], null>;
};

const _CalculateLimitsQuery = (
    props: CalculatLimitsInputParams & RelayModalProps
) => {
    const { t } = useTranslation();

    const { wallet } = props;
    // get out all the properties and format them nicely
    const futureInflowRemaining = wallet.futureInflowRemaining;
    const inflowLimitInfo = wallet.inflowLimitInfoV1;
    const balanceLimitInfo = wallet.balanceLimitInfoV1;
    const inflowRemaining = inflowLimitInfo
        ? formatCurrencyAmount(inflowLimitInfo?.remaining)
        : t("calculate-limits--no-limits");
    const inflowUsed = inflowLimitInfo
        ? formatCurrencyAmount(inflowLimitInfo.used)
        : t("calculate-limits--no-limits");
    const maxInflow = inflowLimitInfo
        ? formatCurrencyAmount(inflowLimitInfo.max)
        : t("calculate-limits--no-limits");
    const maxBalance = balanceLimitInfo
        ? formatCurrencyAmount(balanceLimitInfo.max)
        : t("calculate-limits--no-limits");
    const balanceUsed = balanceLimitInfo
        ? formatCurrencyAmount(balanceLimitInfo.used)
        : t("calculate-limits--no-limits");
    const balanceRemaining = balanceLimitInfo
        ? formatCurrencyAmount(balanceLimitInfo.remaining)
        : t("calculate-limits--no-limits");

    // define a component for a card with information about the user's
    // activity with regards to a particular limit
    type LimitUseInfoParams = {
        limitName: string;
        amountRemaining: string;
        amountUsed: string;
        maxAmount: string;
        description?: string;
        currentMonth: string | null;
    };

    const LimitUseInfo = (props: LimitUseInfoParams) => {
        return (
            <Card>
                <Card.Body>
                    <Card.Title>{props.limitName.toUpperCase()}</Card.Title>
                    {props.description && (
                        <Card.Subtitle className="text-muted mb-2">
                            {props.description || ""}
                        </Card.Subtitle>
                    )}
                    <Card.Text>
                        <strong>
                            {t(
                                "calculate-limits--limit-use-info--remaining-allowed",
                                {
                                    name: props.limitName,
                                    month: props.currentMonth || "",
                                }
                            )}
                        </strong>
                        {props.amountRemaining}
                        <br />
                        <strong>
                            {t(
                                "calculate-limits--limit-use-info--current-used",
                                {
                                    name: props.limitName,
                                    period: props.currentMonth || "Current",
                                }
                            )}
                        </strong>
                        {props.amountUsed}
                        <br />
                        <strong>
                            {t("calculate-limits--limit-use-info--maximum", {
                                name: props.limitName,
                            })}
                        </strong>
                        {props.maxAmount}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    // define a component that lists upcoming changes to the user's inflow limits
    type LimitChange = {
        date: string;
        amount: string;
    };

    type InflowChangesParams = {
        futureInflowRemaining: readonly LimitChange[];
    };

    const InflowChanges = (_props: InflowChangesParams) => {
        if (inflowLimitInfo?.period === "MONTHLY") return <></>;
        const limitChangesTable = futureInflowRemaining.map(
            (limitChange, i) => {
                if (i == 0) return;
                return (
                    <tr key={i}>
                        <td>{formatTimestamp(new Date(limitChange.date))}</td>
                        <td>{formatCurrencyAmount(limitChange.amount)}</td>
                    </tr>
                );
            }
        );
        return (
            <>
                <p>{t("calculate-limits--inflow-changes")}</p>
                <Table hover bordered>
                    <thead>
                        <tr>
                            <th>
                                {t("calculate-limits--inflow-changes--when")}
                            </th>
                            <th>
                                {t("calculate-limits--inflow-changes--maximum")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{limitChangesTable}</tbody>
                </Table>
            </>
        );
    };

    let inflowDescription = t("calculate-limits--inflow-description");
    let balanceDescription = "";
    if (inflowRemaining < balanceRemaining) {
        balanceDescription = t("calculate-limits--balance-description");
    } else {
        inflowDescription += t("calculate-limits--inflow-description-limited");
    }

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const today = new Date();
    const currentMonth =
        inflowLimitInfo?.period === "MONTHLY" ? months[today.getMonth()] : null;

    const limitPeriod =
        inflowLimitInfo?.period === "DAILY"
            ? t("period-daily")
            : inflowLimitInfo?.period === "MONTHLY"
            ? currentMonth
            : `${inflowLimitInfo?.periodDays || 30} ${t("period-day")}`;

    return (
        <Modal show={props.show} onHide={props.onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("calculate-limits--title")}
                    <br />
                    <small>
                        {t("calculate-limits--subtitle", {
                            kycTier: wallet.isTrader
                                ? wallet.kybTier
                                : wallet.kycTier,
                            period: limitPeriod,
                        })}
                    </small>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CardDeck>
                    <LimitUseInfo
                        limitName={t("inflow")}
                        amountRemaining={inflowRemaining}
                        amountUsed={inflowUsed}
                        maxAmount={maxInflow}
                        description={inflowDescription}
                        currentMonth={currentMonth}
                    />
                    <LimitUseInfo
                        limitName={t("balance")}
                        amountRemaining={balanceRemaining}
                        amountUsed={balanceUsed}
                        maxAmount={maxBalance}
                        description={balanceDescription}
                        currentMonth={null}
                    />
                </CardDeck>
                <br />
                <InflowChanges futureInflowRemaining={futureInflowRemaining} />
            </Modal.Body>
        </Modal>
    );
};

const calculateLimitsQuery = graphql`
    query CalculateLimitsQuery($mobile: String!) {
        wallet(mobile: $mobile) {
            id
            futureInflowRemaining {
                date
                amount
            }
            inflowLimitInfoV1 {
                max
                used
                remaining
                periodDays
                period
            }
            kycTier
            kybTier
            isTrader
            balanceLimitInfoV1 {
                max
                used
                remaining
            }
        }
    }
`;

export const CalculateLimits = ({
    mobile,
    onHide,
    show,
}: { mobile: string } & RelayModalProps) => {
    const environment = useRelayEnvironment();
    const { t } = useTranslation();
    return (
        <QueryRenderer<CalculateLimitsQuery>
            environment={environment}
            query={calculateLimitsQuery}
            render={({ error, props }) => {
                if (error) {
                    return <div>{error.message}</div>;
                }
                if (props && props.wallet) {
                    return (
                        <_CalculateLimitsQuery
                            wallet={props.wallet}
                            show={show}
                            onHide={onHide}
                        />
                    );
                }
                return <div>{t("loading")}</div>;
            }}
            variables={{ mobile }}
        />
    );
};
