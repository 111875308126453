import { useCallback } from "react";
import { RelayRefetchProp, createRefetchContainer, graphql } from "react-relay";
import { Variables } from "relay-runtime";

import InfiniteScroll from "../../components/InfiniteScroll";

import { asyncRefetch } from "@/external/relay";
import { logEmptyHistoryConnectionToSentry } from "@/external/sentry";

import { M } from "@/utils/currency";

import { History_wallet } from "./__generated__/History_wallet.graphql";

import PersonalHistoryEntry from "../mobile.[mobile].personal/PersonalHistoryEntry";

function _History({
    wallet,
    jumpTo,
    relay,
}: {
    wallet: History_wallet;
    jumpTo?: string;
    relay: RelayRefetchProp;
}) {
    logEmptyHistoryConnectionToSentry(wallet);

    // history goes forwards in time; reverse entries so most recent first
    const _entries = (wallet.supportHistoryConnection?.edges ?? [])
        .slice()
        .reverse();

    // quick fix for duplicates being returned by the backend supportHistoryConnection
    // resolver during pagination
    const entries = [
        ...new Map(_entries.map((item) => [item["cursor"], item])).values(),
    ];

    const pageInfo = wallet.supportHistoryConnection?.pageInfo ?? {
        hasNextPage: false,
        endCursor: undefined,
        hasPreviousPage: false,
        startCursor: undefined,
    };

    // GROSSNESS AHOY: We figure out whether transfers are reversed on the
    // client side to avoid issuing O(n) queries. Right now, this is not a
    // problem because history is always displayed from the present backwards.
    // If we ever display it differently, we will become sad about this though.
    // Hopefully before that happens we'll have efficient server side reversal
    // info.
    const reversedTransfers: {
        [key: string]: Date;
    } = {};
    for (const entry of entries) {
        const {
            __typename,
            reversedTransferId,
            whenEntered,
            opaqueId,
            isCancelled,
        } = entry.node;
        if (
            (__typename === "TransferReceivedReversalEntry" ||
                __typename === "TransferSentReversalEntry") &&
            reversedTransferId &&
            "reversedTransferId" in entry.node &&
            !isCancelled &&
            whenEntered
        ) {
            reversedTransfers[reversedTransferId] = new Date(whenEntered);
        }
        if (
            __typename === "RemittanceTransferReversalEntry" &&
            opaqueId &&
            "opaqueId" in entry.node &&
            whenEntered
        ) {
            reversedTransfers[opaqueId] = new Date(whenEntered);
        }
        if (
            __typename === "PayoutTransferEntry" &&
            "tcid" in entry.node &&
            whenEntered &&
            entry.node.amount
        ) {
            const amount = M.fromSerialized(entry.node.amount);
            const tcid = entry.node.tcid;

            if (amount.isNegative() && tcid != null) {
                reversedTransfers[tcid] = new Date(whenEntered);
            }
        }
    }

    const loadMore = useCallback(
        async (variables: Variables) => {
            await asyncRefetch(
                relay,
                {
                    ...variables,
                    mobile: wallet.mobile,
                    around: jumpTo, // This parameter has to be kept, otherwise Relay thinks we start a new pagination
                },
                {
                    mobile: wallet.mobile,
                    first: Number.MAX_SAFE_INTEGER, // Fetch all locally stored entries for rendering
                }
            );
        },
        [relay, wallet, jumpTo]
    );

    const activeDeviceBlocks = (wallet?.user?.devices || [])
        .filter((device) => device.activeBlock != null)
        .map(({ deviceId, activeBlock }) => {
            return {
                deviceId,
                blockReason: activeBlock?.blockReason || "",
            };
        });

    let focusedEntry: React.ReactElement | undefined = undefined;
    const historyEntries = [
        ...entries
            .map(({ node }) => node)
            .filter(
                // Don't show cancelled entries except for cancelled BillPaymentEntries or LinkedAccountTransfers
                ({ isCancelled, __typename }) =>
                    !isCancelled ||
                    __typename === "BillPaymentEntry" ||
                    __typename === "FailedMerchantTransferEntry" ||
                    __typename === "RemittanceTransferReceivedEntry" ||
                    __typename.includes("UserLinkedAccountTransfer")
            ),
    ].map((node) => {
        const historyEntry = (
            <PersonalHistoryEntry
                key={node.id}
                entry={node}
                reversedTransfers={reversedTransfers}
                activeDeviceBlocks={activeDeviceBlocks}
            />
        );
        if (
            jumpTo &&
            (node.transferId === jumpTo ||
                node.id === jumpTo ||
                node.agentTransactionId === jumpTo ||
                node.tcid === jumpTo)
        ) {
            focusedEntry = (
                <div className="bg-yellow-100" key={node.id}>
                    {historyEntry}
                </div>
            );
            return focusedEntry;
        }
        return historyEntry;
    });

    return (
        <InfiniteScroll
            hasMoreAtStart={pageInfo.hasNextPage}
            loadAtStart={() =>
                loadMore({ after: pageInfo.endCursor, first: 20 })
            }
            hasMoreAtEnd={pageInfo.hasPreviousPage}
            loadAtEnd={() =>
                loadMore({ before: pageInfo.startCursor, last: 20 })
            }
            jumpTo={focusedEntry}
        >
            {historyEntries}
        </InfiniteScroll>
    );
}

export const History = createRefetchContainer(
    _History,
    {
        wallet: graphql`
            fragment History_wallet on Wallet
            @argumentDefinitions(
                first: { type: "Int" }
                last: { type: "Int" }
                before: { type: "DateTime" }
                after: { type: "DateTime" }
                around: { type: "ID" }
                when: { type: "DateTime" }
                deviceId: { type: "String" }
            ) {
                mobile
                cell: mobile
                user {
                    devices {
                        deviceId
                        activeBlock {
                            blockReason
                        }
                    }
                }
                supportHistoryConnection(
                    first: $first
                    last: $last
                    before: $before
                    after: $after
                    around: $around
                    when: $when
                    deviceId: $deviceId
                )
                    @connection(
                        key: "History_supportHistoryConnection"
                        filters: ["when", "around", "deviceId"]
                    ) {
                    edges {
                        cursor
                        node {
                            __typename
                            id
                            scamInvestigationFields {
                                deviceId
                                deviceModel
                                userInterface
                            }
                            ... on TransferSentEntry {
                                transferId
                                isPending
                                isCancelled
                            }
                            ... on TransferReceivedEntry {
                                transferId
                                isPending
                                isCancelled
                            }
                            ... on AgentTransactionEntry {
                                agentTransactionId
                                isPending
                                isCancelled
                            }
                            ... on BillPaymentEntry {
                                transferId
                                isPending
                                isCancelled
                            }
                            ... on PayoutTransferEntry {
                                tcid
                                isPending
                                isCancelled
                                amount
                            }
                            ...PersonalHistoryEntry_entry
                            ... on HistoryEntry {
                                whenEntered
                                isPending
                                isCancelled
                            }
                            ... on TransferReceivedReversalEntry {
                                reversedTransferId: transferId
                                isPending
                                isCancelled
                            }
                            ... on TransferSentReversalEntry {
                                reversedTransferId: transferId
                                isPending
                                isCancelled
                            }
                            ... on PurchaseEntry {
                                transferId
                                isPending
                                isCancelled
                            }
                            ... on RemittanceTransferReversalEntry {
                                opaqueId
                                isPending
                                isCancelled
                            }
                            ... on FailedMerchantTransferEntry {
                                sendAmount
                                receiveAmount
                                isCancelled
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }
        `,
    },
    graphql`
        query HistoryRefetchQuery(
            $mobile: String!
            $first: Int
            $last: Int
            $before: DateTime
            $after: DateTime
            $around: ID
            $when: DateTime
            $deviceId: String
        ) {
            wallet(mobile: $mobile) {
                ...History_wallet
                    @arguments(
                        first: $first
                        last: $last
                        after: $after
                        before: $before
                        around: $around
                        when: $when
                        deviceId: $deviceId
                    )
            }
        }
    `
);
