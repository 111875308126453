import { createFragmentContainer, graphql } from "react-relay";

import { HistoryEntryProps } from "@/pages/mobile.[mobile]/HistoryEntry";

import { UserLinkedAccountTransferB2WEntry_tx } from "./__generated__/UserLinkedAccountTransferB2WEntry_tx.graphql";

import { createUserLinkedAccountB2WTransferEntry } from "./UserLinkedAccountBankTransferEntryFactory";

interface UserLinkedAccountTransferB2WEntryProps {
    tx: UserLinkedAccountTransferB2WEntry_tx;
}

function _UserLinkedAccountTransferB2WEntry(
    props: UserLinkedAccountTransferB2WEntryProps & HistoryEntryProps
) {
    return createUserLinkedAccountB2WTransferEntry(props);
}

export default createFragmentContainer(_UserLinkedAccountTransferB2WEntry, {
    tx: graphql`
        fragment UserLinkedAccountTransferB2WEntry_tx on UserLinkedAccountTransferB2WEntry {
            summary
            isCancelled
            partnerName
            liaTransferId
            errorCode
            errorMessage
            ...HistoryEntry_entry
        }
    `,
});
