import { faIdCard, faSimCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    createFragmentContainer,
    graphql,
    useLazyLoadQuery,
} from "react-relay";

import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";

import { usePermissions } from "@/hooks/usePermissions";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { RefundP2PPartialRefundMutationResponse } from "@/pages/mobile.[mobile].personal/__generated__/RefundP2PPartialRefundMutation.graphql";
import { BlockBanner } from "@/pages/mobile.[mobile]/BlockBanner";

import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";
import { Action } from "@/types/actions";

import { M, scalarFromAmount } from "@/utils/currency";
import { getMobileFromWindowLocation } from "@/utils/navigation";

import { RefundP2PMutationResponse } from "./__generated__/RefundP2PMutation.graphql";
import {
    RefundP2PRecipientWalletQuery,
    RefundP2PRecipientWalletQueryResponse,
} from "./__generated__/RefundP2PRecipientWalletQuery.graphql";
import { RefundP2P_transfer } from "./__generated__/RefundP2P_transfer.graphql";

import { UserHistoryAroundTransfer } from "./UserHistoryAroundTransfer";

const refundMutation = graphql`
    mutation RefundP2PMutation($transferId: ID!, $canReadHistory: Boolean!) {
        refund(transferId: $transferId) {
            transfer {
                id
                user {
                    primaryWallet {
                        ...History_wallet
                            @arguments(last: 10)
                            @include(if: $canReadHistory)
                    }
                }
            }
        }
    }
`;

const partialRefundMutation = graphql`
    mutation RefundP2PPartialRefundMutation(
        $transferOrLedgerTransactionId: ID!
        $currentUserMobile: String!
        $amount: Decimal!
        $canReadHistory: Boolean!
        $canReadBalance: Boolean!
    ) {
        partialRefund(
            transferOrLedgerTransactionId: $transferOrLedgerTransactionId
            currentUserMobile: $currentUserMobile
            amount: $amount
        ) {
            success
            user {
                primaryWallet {
                    ...PersonalTabHeader_wallet
                        @arguments(canReadBalance: $canReadBalance)
                    ...History_wallet
                        @include(if: $canReadHistory)
                        @arguments(last: 10)
                }
            }
        }
    }
`;

const refundP2PRecipientWalletQuery = graphql`
    query RefundP2PRecipientWalletQuery(
        $mobile: String!
        $canReadBalance: Boolean!
        $transferId: String!
    ) {
        wallet(mobile: $mobile) {
            mobile
            balance @include(if: $canReadBalance)
            amountPending @include(if: $canReadBalance)
            hasOutgoingTransactions(transferId: $transferId)
            activeMobileBlock {
                id
                blockReason
                blockedBy
                whenBlocked
                whenExpires
            }
            activeLegalEntityBlock {
                id
                blockReason
                blockedBy
                whenBlocked
                whenExpires
            }
        }
    }
`;

type RefundP2PInputParams = {
    transfer: RefundP2P_transfer;
};

type BlockBannersProps = {
    wallet: RefundP2PRecipientWalletQueryResponse["wallet"];
};

function BlockBanners({ wallet }: BlockBannersProps): JSX.Element {
    const { t } = useTranslation();

    const blockBannerMobile = wallet?.activeMobileBlock ? (
        <BlockBanner
            title={t("wallet-user-block--block-notice--title", {
                status: wallet?.activeMobileBlock?.whenExpires
                    ? t("wallet-user-block--temporary")
                    : t("wallet-user-block--complete"),
                type: t("mobile-number"),
            })}
            blockType={"full"}
            icon={<FontAwesomeIcon icon={faSimCard} />}
            block={wallet?.activeMobileBlock}
            actions={[]}
        />
    ) : null;

    const blockBannerLegalEntity = wallet?.activeLegalEntityBlock ? (
        <BlockBanner
            title={t("wallet-user-block--block-notice--title", {
                status: wallet?.activeLegalEntityBlock?.whenExpires
                    ? t("wallet-user-block--temporary")
                    : t("wallet-user-block--complete"),
                type: t("legal-entity"),
            })}
            blockType={"full"}
            icon={<FontAwesomeIcon icon={faIdCard} />}
            block={wallet?.activeLegalEntityBlock}
            actions={[]}
        />
    ) : null;

    return (
        <>
            {blockBannerMobile}
            {blockBannerLegalEntity}
        </>
    );
}

type RefundAlertProps = {
    noBalanceAvailable: boolean;
    onlyPartialFundsAvailable: boolean | null;
    balance: string;
    amountPending: string;
    hasOutgoingTransactions: boolean | null;
    hasBlocks: boolean;
    hasPendingTransactions: boolean;
    isSameCurrency: boolean;
};

function RefundAlert({
    noBalanceAvailable,
    onlyPartialFundsAvailable,
    balance,
    hasOutgoingTransactions,
    hasBlocks,
    hasPendingTransactions,
    amountPending,
    isSameCurrency,
}: RefundAlertProps): JSX.Element | null {
    const { t } = useTranslation();

    const alert = {
        variant: "",
        text: "",
    };

    if (noBalanceAvailable) {
        alert.variant = "danger";
        alert.text = t("refund--no-funds-available");
    } else if (onlyPartialFundsAvailable) {
        alert.variant = "warning";
        alert.text = t("refund--only-partial-funds-available", {
            amount: M.fromSerialized(balance || ""),
        });
    } else if (
        hasOutgoingTransactions === true /* can be `true`, `false` or `null`*/
    ) {
        alert.variant = "warning";
        alert.text = t("refund--recipient-has-outgoing-transactions");
    } else if (
        hasOutgoingTransactions === null /* can be `true`, `false` or `null`*/
    ) {
        alert.variant = "warning";
        alert.text = t("refund--recipient-cannot-verify-outgoing-transactions");
    } else if (!hasBlocks && !hasPendingTransactions) {
        alert.variant = "success";
        alert.text = t("refund--possible");
    }

    if (!isSameCurrency) {
        alert.variant = alert.variant === "danger" ? "danger" : "warning";
        if (alert.text) alert.text += " ";
        alert.text = t("refund--wallet-different-currency");
    }

    if (hasBlocks) {
        alert.variant = alert.variant === "danger" ? "danger" : "warning";
        if (alert.text) alert.text += " ";
        alert.text += t("refund--has-blocks");
    }

    if (hasPendingTransactions) {
        alert.variant = alert.variant === "danger" ? "danger" : "warning";
        if (alert.text) alert.text += " ";
        alert.text += t("refund--has-amount-pending", { amountPending });
    }

    return alert.text ? (
        <Alert variant={alert.variant}>{alert.text}</Alert>
    ) : null;
}

const _RefundP2PMutator = (props: RelayModalProps & RefundP2PInputParams) => {
    const trackEvent = useTrackEvent();
    const { onHide, transfer } = props;
    const sendAmount = M.fromSerialized(transfer.sendAmount);
    const receiveAmount = M.fromSerialized(transfer.receiveAmount);
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const currentUserMobile = getMobileFromWindowLocation();

    const { t } = useTranslation();

    const { wallet: recipientWallet } =
        useLazyLoadQuery<RefundP2PRecipientWalletQuery>(
            refundP2PRecipientWalletQuery,
            {
                mobile: props.transfer.maybeTerminatedRecipientMobile,
                canReadBalance,
                transferId: props.transfer.transferId,
            }
        );

    const balance = scalarFromAmount(recipientWallet?.balance || "");
    const pendingAmount = scalarFromAmount(
        recipientWallet?.amountPending || ""
    );
    const noBalanceAvailable = balance <= 0;
    const isSameCurrency = receiveAmount.currency === sendAmount.currency;
    // It's not trivial to compare amounts of different currencies,
    // so we delegate the decision to the rep
    const onlyPartialFundsAvailable = isSameCurrency
        ? 0 < balance && balance < scalarFromAmount(transfer.receiveAmount)
        : null;
    const hasBalance = isSameCurrency
        ? !noBalanceAvailable
        : // When currencies are different, we only look at the existence of funds and
          // we delegate the decision to the rep
          !noBalanceAvailable;
    const hasBlocks =
        Boolean(recipientWallet?.activeMobileBlock) ||
        Boolean(recipientWallet?.activeLegalEntityBlock);
    const hasPendingTransactions = pendingAmount > 0;

    const canRefund = hasBalance;

    /* Refund Mutation */
    const onRefundMutationSuccess = useCallback(
        (response: RefundP2PMutationResponse): string | null => {
            if (response) return t("refund--success");
            return null;
        },
        [t]
    );

    const refundMutator = useMutator({
        onMutationSuccess: onRefundMutationSuccess,
        trackActionInfo: {
            name: Action.Refund,
            data: {
                transferId: transfer.transferId,
            },
        },
        ...props,
    });

    const onPartialRefundMutationSuccess = useCallback(
        ({
            partialRefund,
        }: RefundP2PPartialRefundMutationResponse): string | null => {
            if (partialRefund?.success) return t("freeze-transaction--success");
            return null;
        },
        [t]
    );

    const partialRefundMutator = useMutator({
        onMutationSuccess: onPartialRefundMutationSuccess,
        trackActionInfo: {
            name: Action.PartialRefund,
            data: {
                transferId: transfer.transferId,
            },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        if (onlyPartialFundsAvailable) {
            // we do a partial refund by freezing the tx and then reversing it
            partialRefundMutator.submit(partialRefundMutation, {
                transferOrLedgerTransactionId: transfer.transferId,
                currentUserMobile,
                amount: balance,
                canReadBalance,
                canReadHistory,
            });
        } else {
            refundMutator.submit(refundMutation, {
                transferId: transfer.transferId,
                canReadHistory,
            });
        }
    }, [
        currentUserMobile,
        refundMutator,
        transfer,
        canReadHistory,
        canReadBalance,
        onlyPartialFundsAvailable,
        balance,
        partialRefundMutator,
    ]);

    return (
        <MutatorModal
            isWorking={
                refundMutator.isWorking || partialRefundMutator.isWorking
            }
            errors={refundMutator.errors || partialRefundMutator.errors}
            show={refundMutator.show || partialRefundMutator.show}
            onHide={() => {
                refundMutator.onHide?.();
                partialRefundMutator.onHide?.();
            }}
            size="xl"
            title={t("refund--title", {
                amount: sendAmount,
                name: transfer.senderName,
            })}
        >
            <Form
                isWorking={
                    refundMutator.isWorking || partialRefundMutator.isWorking
                }
                result={refundMutator.result || partialRefundMutator.result}
                errors={refundMutator.errors || partialRefundMutator.errors}
                isValid={canRefund}
                submitText={
                    onlyPartialFundsAvailable
                        ? t("action-partial-refund")
                        : t("action-refund")
                }
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <p className="mb-2">
                    {t("refund--description", {
                        amount: sendAmount,
                        name: transfer.senderName,
                        mobile: transfer.senderMobile,
                    })}
                </p>
                <RefundAlert
                    balance={recipientWallet?.balance || ""}
                    hasBlocks={hasBlocks}
                    hasOutgoingTransactions={
                        recipientWallet?.hasOutgoingTransactions ?? null
                    }
                    noBalanceAvailable={noBalanceAvailable}
                    onlyPartialFundsAvailable={onlyPartialFundsAvailable}
                    hasPendingTransactions={hasPendingTransactions}
                    amountPending={recipientWallet?.amountPending || ""}
                    isSameCurrency={isSameCurrency}
                />
                <BlockBanners wallet={recipientWallet} />
                <>
                    {!isSameCurrency ||
                    (hasBalance &&
                        recipientWallet?.hasOutgoingTransactions !== false) ? (
                        <div className="max-h-[50vh] mb-4">
                            <UserHistoryAroundTransfer
                                mobile={transfer.maybeTerminatedRecipientMobile}
                                name={transfer.recipientName}
                                transferId={transfer.transferId}
                                first={1}
                                last={20}
                                listWrapperClassName="max-h-[40vh]"
                                listClassName="max-h-[40vh] overflow-auto"
                            />
                        </div>
                    ) : null}
                    {!(refundMutator.result || partialRefundMutator.result) ||
                    refundMutator.errors ||
                    partialRefundMutator.errors ? (
                        <>
                            <Button
                                onClick={() => {
                                    trackEvent(Action.RefundRejected, {
                                        transferId: transfer.transferId,
                                    });
                                    onHide?.();
                                }}
                                variant="danger"
                                className="float-left"
                                title={t("refund--reject--tooltip")}
                            >
                                {t("refund--reject")}
                            </Button>
                        </>
                    ) : null}
                </>
            </Form>
        </MutatorModal>
    );
};

export const RefundP2PMutator = createFragmentContainer(_RefundP2PMutator, {
    transfer: graphql`
        fragment RefundP2P_transfer on MobileTransferEntry {
            id
            transferId
            sendAmount
            receiveAmount
            senderName
            senderMobile
            recipientName
            maybeTerminatedRecipientMobile
        }
    `,
});
