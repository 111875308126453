/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RemittanceTransferReceivedEntry_tx = {
    readonly id: string;
    readonly opaqueId: string;
    readonly description: string;
    readonly amount: string;
    readonly isPending: boolean;
    readonly isCancelled: boolean;
    readonly senderName: string;
    readonly senderMobile: string;
    readonly senderCountry: string;
    readonly recipientMobile: string;
    readonly receiveAmount: string;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "RemittanceTransferRefund_transfer" | "ChangeRemittanceRecipient_transfer">;
    readonly " $refType": "RemittanceTransferReceivedEntry_tx";
};
export type RemittanceTransferReceivedEntry_tx$data = RemittanceTransferReceivedEntry_tx;
export type RemittanceTransferReceivedEntry_tx$key = {
    readonly " $data"?: RemittanceTransferReceivedEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RemittanceTransferReceivedEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemittanceTransferReceivedEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "opaqueId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPending",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiveAmount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemittanceTransferRefund_transfer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeRemittanceRecipient_transfer"
    }
  ],
  "type": "RemittanceTransferReceivedEntry",
  "abstractKey": null
};
(node as any).hash = '39aefe730c8760b4e474e17928a90224';
export default node;
