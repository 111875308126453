import { ReactElement, useCallback } from "react";
import { RelayRefetchProp } from "react-relay";
import { Variables } from "relay-runtime";

import InfiniteScroll from "@/components/InfiniteScroll";

import { asyncRefetch } from "@/external/relay";

import { M } from "@/utils/currency";
import { notEmpty } from "@/utils/value";

import { MerchantTab_merchant } from "./__generated__/MerchantTab_merchant.graphql";

import MerchantHistoryEntry from "./MerchantHistoryEntry";

type MerchantHistoryConnection = Exclude<
    Exclude<
        MerchantTab_merchant["wallet"],
        null | undefined
    >["supportHistoryConnection"],
    null | undefined
>;

interface MerchantHistoryProps {
    merchantId: string;
    historyConnection: MerchantHistoryConnection;
    jumpTo: string | undefined;
    relay: RelayRefetchProp;
}

export const MerchantHistory = ({
    merchantId,
    historyConnection,
    jumpTo,
    relay,
}: MerchantHistoryProps) => {
    const { pageInfo, edges } = historyConnection;
    // entries to show when page is first loaded; reverse entries so most recent first
    const entries = edges?.slice()?.reverse() ?? [];

    const reversedTransfers: {
        [key: string]: Date;
    } = {};
    for (const entry of entries) {
        const { __typename } = entry.node;
        if (__typename === "PayoutTransferEntry") {
            const { amount, tcid, whenEntered } = entry.node;
            if (amount && tcid && whenEntered) {
                if (M.fromSerialized(amount).isPositive()) {
                    reversedTransfers[tcid] = new Date(whenEntered);
                }
            }
        }
    }

    const loadMore = useCallback(
        async (variables: Variables) => {
            await asyncRefetch(
                relay,
                {
                    ...variables,
                    merchantId,
                    around: jumpTo, // This parameter has to be kept, otherwise Relay thinks we start a new pagination
                },
                {
                    merchantId,
                    first: Number.MAX_SAFE_INTEGER, // Fetch all locally stored entries for rendering
                }
            );
        },
        [relay, merchantId, jumpTo]
    );

    let focusedEntry: ReactElement | undefined = undefined;
    let historyChildren: ReactElement[] = [];

    historyChildren = (entries || []).filter(notEmpty).map((entry) => {
        if (
            jumpTo &&
            (jumpTo === entry.node?.maybeTransferId ||
                jumpTo === entry.node?.agentTransactionId ||
                jumpTo === entry.node?.tcid)
        ) {
            focusedEntry = (
                <div key={entry.node?.id} className="bg-yellow-100">
                    <MerchantHistoryEntry
                        entry={entry.node}
                        reversedTransfers={reversedTransfers}
                    />
                </div>
            );

            return focusedEntry;
        }

        return (
            <MerchantHistoryEntry
                key={entry.node?.id}
                entry={entry.node || {}}
                reversedTransfers={reversedTransfers}
            />
        );
    });

    return (
        <InfiniteScroll
            hasMoreAtStart={pageInfo.hasNextPage}
            loadAtStart={() =>
                loadMore({ after: pageInfo.endCursor, first: 20 })
            }
            hasMoreAtEnd={pageInfo.hasPreviousPage}
            loadAtEnd={() =>
                loadMore({ before: pageInfo.startCursor, last: 20 })
            }
            jumpTo={focusedEntry}
        >
            {historyChildren}
        </InfiniteScroll>
    );
};
