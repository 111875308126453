import React, { ReactNode, createContext, useState } from "react";

export type LiveMetrics = {
    callsInQueue?: { count: number };
    missedCallRate?: { fiveMinutes: number };
    totalCallsInQueue?: { count: number } | null;
    unansweredCallCount?: { count: number } | null;
};

export type LiveMetricsContext = {
    liveMetrics: LiveMetrics;
    setLiveMetrics: (liveMetrics: LiveMetrics) => void;
};

export const LiveMetricsContext = createContext<LiveMetricsContext>({
    liveMetrics: {},
    setLiveMetrics: () => undefined,
});

type LiveMetricsProviderProps = {
    children?: ReactNode | undefined;
};

export const LiveMetricsProvider = (props: LiveMetricsProviderProps) => {
    const [liveMetrics, setLiveMetrics] = useState<LiveMetrics>({});

    return (
        <LiveMetricsContext.Provider value={{ liveMetrics, setLiveMetrics }}>
            {props.children}
        </LiveMetricsContext.Provider>
    );
};
