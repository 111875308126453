/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RenameWalletMutationVariables = {
    walletId: string;
    newName: string;
    reason: string;
};
export type RenameWalletMutationResponse = {
    readonly renameWallet: {
        readonly result: string;
        readonly wallet: {
            readonly name: string;
            readonly user: {
                readonly name: string;
            } | null;
        };
    } | null;
};
export type RenameWalletMutation = {
    readonly response: RenameWalletMutationResponse;
    readonly variables: RenameWalletMutationVariables;
};



/*
mutation RenameWalletMutation(
  $walletId: ID!
  $newName: String!
  $reason: String!
) {
  renameWallet(walletId: $walletId, newName: $newName, reason: $reason) {
    result
    wallet {
      name
      user {
        name
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "walletId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "newName",
    "variableName": "newName"
  },
  {
    "kind": "Variable",
    "name": "reason",
    "variableName": "reason"
  },
  {
    "kind": "Variable",
    "name": "walletId",
    "variableName": "walletId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "result",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RenameWalletMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "RenameWallet",
        "kind": "LinkedField",
        "name": "renameWallet",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RenameWalletMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "RenameWallet",
        "kind": "LinkedField",
        "name": "renameWallet",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a77910b592c1eccce90120a86b6ca0f6",
    "id": null,
    "metadata": {},
    "name": "RenameWalletMutation",
    "operationKind": "mutation",
    "text": "mutation RenameWalletMutation(\n  $walletId: ID!\n  $newName: String!\n  $reason: String!\n) {\n  renameWallet(walletId: $walletId, newName: $newName, reason: $reason) {\n    result\n    wallet {\n      name\n      user {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a84d716192d869378438b28256d89de1';
export default node;
