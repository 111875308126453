import { faIdBadge } from "@fortawesome/free-solid-svg-icons/faIdBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export function CopyAgentIDButton(props: { copyText: string }) {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text: string) {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(props.copyText)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    };

    return (
        <span
            className="agent-id-button"
            about={(isCopied && "Copied") || props.copyText}
            onClick={handleCopyClick}
        >
            <FontAwesomeIcon icon={faIdBadge} size="sm" />
        </span>
    );
}
