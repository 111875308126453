import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { DropdownField, useDropdownField } from "@/components/DropdownField";
import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";
import { ProfileSearchField } from "@/components/ProfileSearchField";
import { TextField, useTextField } from "@/components/TextField";
import { TypeaheadField, useTypeaheadField } from "@/components/TypeaheadField";

import { Flags } from "@/external/flags";

import { useCurrentCall } from "@/hooks/useCurrentCall";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { NoActionCallSurveyMutationResponse } from "./__generated__/NoActionCallSurveyMutation.graphql";

type Answer = {
    reason: string;
    call_failed_details?: string | undefined;
    account_info_details?: string | undefined;
    wave_info_details?: string | undefined;
    not_phone_owner_details?: string | undefined;
    partner_issue_details?: string | undefined;
    comments: string | undefined;
};
const mutation = graphql`
    mutation NoActionCallSurveyMutation(
        $mobile: String!
        $answer: JSONString!
        $textAnswer: String
        $phoneCallId: String
    ) {
        createSurveySubmission(
            mobile: $mobile
            answer: $answer
            textAnswer: $textAnswer
            phoneCallId: $phoneCallId
        ) {
            surveySubmission {
                surveySubmissionId
            }
            supportNotes {
                ...NotesList_supportNotes
            }
        }
    }
`;

const partners = [
    { value: "1xbet", displayName: "1xBet" },
    { value: "airtime", displayName: "Airtime" },
    { value: "aquatech", displayName: "Aquatech" },
    { value: "baobab_plus", displayName: "Baobab+" },
    { value: "boa", displayName: "BOA" },
    { value: "campusen", displayName: "Campusen" },
    { value: "canal", displayName: "Canal+" },
    { value: "cie", displayName: "CIE" },
    { value: "cnps", displayName: "CNPS" },
    { value: "der", displayName: "DER" },
    { value: "ecobank", displayName: "Ecobank" },
    { value: "edm", displayName: "EDM" },
    { value: "expresso", displayName: "Expresso" },
    { value: "fer", displayName: "FER" },
    { value: "flexeau", displayName: "Flexeau" },
    { value: "free", displayName: "Free" },
    { value: "intech", displayName: "Intech" },
    { value: "isago", displayName: "Isago" },
    { value: "mytouchpoint", displayName: "MyTouchPoint" },
    { value: "nawec", displayName: "Nawec" },
    { value: "nwsc", displayName: "NWSC" },
    { value: "orabank", displayName: "Orabank" },
    { value: "orange", displayName: "Orange" },
    { value: "paytech", displayName: "Paytech" },
    { value: "pegasus", displayName: "Pegasus" },
    { value: "premier_bet", displayName: "Premier Bet" },
    { value: "promobile_bet", displayName: "Promobile" },
    { value: "oolu", displayName: "Oolu" },
    { value: "pont_hkb", displayName: "Pont HKB" },
    { value: "rapido", displayName: "Rapido" },
    { value: "seneau", displayName: "SEN'EAU" },
    { value: "sendwave", displayName: "Sendwave" },
    { value: "senelec", displayName: "Senelec" },
    { value: "sodeci", displayName: "Sodeci" },
    { value: "somagep", displayName: "Somagep" },
    { value: "sonabel", displayName: "Sonabel" },
    { value: "startimes", displayName: "Startimes" },
    { value: "sunubet", displayName: "Sunubet" },
    { value: "tnt", displayName: "TNT" },
    { value: "uba", displayName: "UBA" },
    { value: "ucad", displayName: "UCAD" },
    { value: "umeme", displayName: "UMEME" },
    { value: "uvs", displayName: "UVS" },
    { value: "woyofal", displayName: "Woyofal" },
    { value: "xeweul", displayName: "Xeweul" },
    { value: "yaka", displayName: "Yaka" },
];

enum REASONS {
    callFailed = "call_failed",
    findAgents = "find_agents",
    askedAccountInfo = "asked_account_info",
    askedWaveInfo = "asked_wave_info",
    notPhoneOwner = "not_phone_owner",
    callbackReportedIssue = "callback_reported_issue",
    joker = "joker",
    qrError = "qr_error",
    partnerIssue = "partner_issue",
    other = "other",
}

const _NoActionCallSurveyMutator = (props: RelayModalProps) => {
    const { onHide } = props;
    const { t } = useTranslation();
    const { currentCall } = useCurrentCall();

    const singleDropdownEnabled =
        Flags.getFlag("single_dropdown_no_action_call_survey")?.isActive ||
        false;
    const customPartnerEnabled =
        Flags.getFlag("allow_custom_partner_no_action_call_survey")?.isActive ||
        false;

    const reasonField = useDropdownField();
    const detailsField = useDropdownField();
    const accountInfoTypeField = useDropdownField();
    const infoTypeField = useDropdownField();
    const commentsField = useTextField();
    const notPhoneOwnerDetailsField = useDropdownField();
    const partnerField = useTypeaheadField();
    const [callerMobile, setCallerMobile] = useState<string | undefined>(
        currentCall.callType !== "none" ? currentCall.mobile : undefined
    );

    const reason_dropdown_options = [
        {
            value: REASONS.callFailed,
            displayName: t("no-action-survey--call-failed"),
        },
        {
            value: REASONS.findAgents,
            displayName: t("no-action-survey--find-agents"),
        },
        {
            value: REASONS.askedAccountInfo,
            displayName: t("no-action-survey--asked-account-info"),
        },
        {
            value: REASONS.notPhoneOwner,
            displayName: t("no-action-survey--not-phone-owner"),
        },
        {
            value: REASONS.askedWaveInfo,
            displayName: t("no-action-survey--asked-wave-info"),
        },
        {
            value: REASONS.callbackReportedIssue,
            displayName: t("no-action-survey--callback-reported-issue"),
        },
        { value: REASONS.joker, displayName: t("no-action-survey--joker") },
        {
            value: REASONS.qrError,
            displayName: t("no-action-survey--qr-error"),
        },
        { value: REASONS.other, displayName: t("no-action-survey--other") },
    ];

    const acountInfoTypes = [
        { value: "balance", displayName: t("no-action-survey--balance") },
        {
            value: "confirm-transfer",
            displayName: t("no-action-survey--confirm-transfer"),
        },
        {
            value: "list_of_transactions",
            displayName: t("no-action-survey--list-of-transactions"),
        },
        {
            value: "bill_payments",
            displayName: t("no-action-survey--bill-payments"),
        },
        { value: "dormancy", displayName: t("no-action-survey--dormancy") },
        { value: "pin", displayName: t("no-action-survey--pin") },
        { value: "other", displayName: t("no-action-survey--other") },
    ];

    const infoTypes = [
        {
            value: "how_wave_works",
            displayName: t("no-action-survey--how-wave-works"),
        },
        { value: "wave_app", displayName: t("no-action-survey--wave-app") },
        { value: "qr_cards", displayName: t("no-action-survey--qr-cards") },
        { value: "agent_app", displayName: t("no-action-survey--agent-app") },
        {
            value: "merchant_payments",
            displayName: t("no-action-survey--merchant-payments"),
        },
        {
            value: "payez_avec_wave",
            displayName: t("no-action-survey--payez-avec-wave"),
        },
        {
            value: "new_feature_request",
            displayName: t("no-action-survey--new-feature-request"),
        },
        { value: "other", displayName: t("no-action-survey--other") },
    ];

    const notPhoneOwnerDetails = [
        {
            value: "failed_verification",
            displayName: t("no-action-survey--failed-verification"),
        },
        {
            value: "will_call_back",
            displayName: t("no-action-survey--will-call-back"),
        },
        { value: "other", displayName: t("no-action-survey--other") },
    ];

    const callFailedOptions = [
        {
            value: "call_dropped",
            displayName: t("no-action-survey--call-dropped"),
        },
        {
            value: "silent_call",
            displayName: t("no-action-survey--silent-call"),
        },
        {
            value: "robotic_call",
            displayName: t("no-action-survey--robotic-call"),
        },
        { value: "other", displayName: t("no-action-survey--other") },
    ];

    const allOptionsDropdown = [
        {
            value: "balance",
            displayName: t("no-action-survey--balance"),
            optGroup: t("no-action-survey--asked-account-info"),
            groupValue: REASONS.askedAccountInfo,
        },
        {
            value: "confirm-transfer",
            displayName: t("no-action-survey--confirm-transfer"),
            optGroup: t("no-action-survey--asked-account-info"),
            groupValue: REASONS.askedAccountInfo,
        },
        {
            value: "list_of_transactions",
            displayName: t("no-action-survey--list-of-transactions"),
            optGroup: t("no-action-survey--asked-account-info"),
            groupValue: REASONS.askedAccountInfo,
        },
        {
            value: "dormancy",
            displayName: t("no-action-survey--dormancy"),
            optGroup: t("no-action-survey--asked-account-info"),
            groupValue: REASONS.askedAccountInfo,
        },
        {
            value: "pin",
            displayName: t("no-action-survey--pin"),
            optGroup: t("no-action-survey--asked-account-info"),
            groupValue: REASONS.askedAccountInfo,
        },
        {
            value: "failed_verification",
            displayName: t("no-action-survey--failed-verification"),
            optGroup: t("no-action-survey--not-phone-owner"),
            groupValue: REASONS.notPhoneOwner,
        },
        {
            value: "will_call_back",
            displayName: t("no-action-survey--will-call-back"),
            optGroup: t("no-action-survey--not-phone-owner"),
            groupValue: REASONS.notPhoneOwner,
        },
        {
            value: "call_dropped",
            displayName: t("no-action-survey--call-dropped"),
            optGroup: t("no-action-survey--call-failed"),
            groupValue: REASONS.callFailed,
        },
        {
            value: "silent_call",
            displayName: t("no-action-survey--silent-call"),
            optGroup: t("no-action-survey--call-failed"),
            groupValue: REASONS.callFailed,
        },
        {
            value: "robotic_call",
            displayName: t("no-action-survey--robotic-call"),
            optGroup: t("no-action-survey--call-failed"),
            groupValue: REASONS.callFailed,
        },
        {
            value: REASONS.askedWaveInfo,
            displayName: t("no-action-survey--asked-wave-info"),
        },
        {
            value: REASONS.callbackReportedIssue,
            displayName: t("no-action-survey--callback-reported-issue"),
        },
        {
            value: REASONS.partnerIssue,
            displayName: t("no-action-survey--partner-issue"),
        },
        { value: REASONS.joker, displayName: t("no-action-survey--joker") },
        {
            value: REASONS.findAgents,
            displayName: t("no-action-survey--find-agents"),
        },
        {
            value: REASONS.qrError,
            displayName: t("no-action-survey--qr-error"),
        },
        { value: REASONS.other, displayName: t("no-action-survey--other") },
    ];

    const isReasonSelected = (reason: string): boolean => {
        return reasonField.dropdownItem?.value == reason;
    };

    const onMutationSuccess = useCallback(
        (response: NoActionCallSurveyMutationResponse): string | null => {
            return response.createSurveySubmission?.surveySubmission
                .surveySubmissionId
                ? t("no-action-survey-submission--success")
                : "";
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.SubmitNoActionSurvey,
            data: {},
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        const phoneCallId = currentCall.callId;

        const reasonValue = reasonField.dropdownItem?.value as string;
        const answer: Answer = {
            reason: reasonValue,
            comments: commentsField.value || undefined,
        };

        let reasonText = reasonField.dropdownItem?.displayName || "";
        let detailText = "";

        if (singleDropdownEnabled) {
            if (reasonField.dropdownItem?.groupValue) {
                answer.reason = reasonField.dropdownItem?.groupValue;

                answer.call_failed_details =
                    reasonField.dropdownItem?.groupValue === REASONS.callFailed
                        ? reasonValue
                        : undefined;
                answer.account_info_details =
                    reasonField.dropdownItem?.groupValue ===
                    REASONS.askedAccountInfo
                        ? reasonValue
                        : undefined;
                answer.wave_info_details =
                    reasonField.dropdownItem?.groupValue ===
                    REASONS.askedWaveInfo
                        ? reasonValue
                        : undefined;
                answer.not_phone_owner_details =
                    reasonField.dropdownItem?.groupValue ===
                    REASONS.notPhoneOwner
                        ? reasonValue
                        : undefined;

                reasonText = reasonField.dropdownItem?.optGroup || "";
                detailText = reasonField.dropdownItem?.displayName;
            } else {
                answer.partner_issue_details =
                    reasonValue === REASONS.partnerIssue
                        ? (partnerField.typeaheadItem?.value as string)
                        : undefined;
                detailText = partnerField.typeaheadItem?.displayName || "";
            }
        } else {
            answer.call_failed_details =
                reasonValue === REASONS.callFailed
                    ? (detailsField.dropdownItem?.value as string)
                    : undefined;
            answer.account_info_details =
                reasonValue === REASONS.askedAccountInfo
                    ? (accountInfoTypeField.dropdownItem?.value as string)
                    : undefined;
            answer.wave_info_details =
                reasonValue === REASONS.askedWaveInfo
                    ? (infoTypeField.dropdownItem?.value as string)
                    : undefined;
            answer.not_phone_owner_details =
                reasonValue === REASONS.notPhoneOwner
                    ? (notPhoneOwnerDetailsField.dropdownItem?.value as string)
                    : undefined;

            detailText =
                detailsField.dropdownItem?.displayName ||
                accountInfoTypeField.dropdownItem?.displayName ||
                infoTypeField.dropdownItem?.displayName ||
                notPhoneOwnerDetailsField.dropdownItem?.displayName ||
                "";
        }

        mutator.submit(mutation, {
            mobile: callerMobile,
            answer: JSON.stringify(answer),
            textAnswer: `${reasonText}${detailText ? ": " : ""}${detailText}. ${
                commentsField.value
            }${commentsField.value ? "." : ""}`,
            phoneCallId,
        });
    }, [
        mutator,
        currentCall,
        reasonField,
        detailsField,
        accountInfoTypeField,
        infoTypeField,
        commentsField,
        notPhoneOwnerDetailsField,
        callerMobile,
        singleDropdownEnabled,
        partnerField,
    ]);

    const reasonValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!reasonField.dropdownItem?.value) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-option"),
                display: "none",
            });
        }

        return validations;
    }, [reasonField.dropdownItem?.value, t]);

    const detailsValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (
            reasonField.dropdownItem?.value === REASONS.callFailed &&
            !detailsField.dropdownItem?.value
        ) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-option"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [reasonField.dropdownItem?.value, detailsField.dropdownItem?.value, t]);

    const accountInfoValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (
            reasonField.dropdownItem?.value === REASONS.askedAccountInfo &&
            !accountInfoTypeField.dropdownItem?.value
        ) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-option"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [
        reasonField.dropdownItem?.value,
        accountInfoTypeField.dropdownItem?.value,
        t,
    ]);

    const infoValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (
            !singleDropdownEnabled &&
            reasonField.dropdownItem?.value === REASONS.askedWaveInfo &&
            !infoTypeField.dropdownItem?.value
        ) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-option"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [
        singleDropdownEnabled,
        reasonField.dropdownItem?.value,
        infoTypeField.dropdownItem?.value,
        t,
    ]);

    const notPhoneOwnerValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (
            reasonField.dropdownItem?.value === REASONS.notPhoneOwner &&
            !notPhoneOwnerDetailsField.dropdownItem?.value
        ) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-option"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [
        reasonField.dropdownItem?.value,
        notPhoneOwnerDetailsField.dropdownItem?.value,
        t,
    ]);

    const partnerValidation = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (
            reasonField.dropdownItem?.value === REASONS.partnerIssue &&
            !partnerField.typeaheadItem?.value
        ) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-option"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [partnerField, reasonField.dropdownItem?.value, t]);

    const callerMobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!callerMobile) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-mobile"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [callerMobile, t]);

    const isValid = useMemo(() => {
        return !hasError([
            reasonValidations,
            detailsValidations,
            accountInfoValidations,
            infoValidations,
            notPhoneOwnerValidations,
            callerMobileValidations,
        ]);
    }, [
        reasonValidations,
        detailsValidations,
        accountInfoValidations,
        infoValidations,
        notPhoneOwnerValidations,
        callerMobileValidations,
    ]);

    return (
        <MutatorModal {...mutator} title={t("no-action-survey--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("form--submit")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <ProfileSearchField
                    allowWallets
                    allowUnregistered
                    allowMerchants={false}
                    allowAgents={false}
                    allowTerminated={false}
                    initialQuery={
                        currentCall.callType != "none"
                            ? currentCall.mobile
                            : undefined
                    }
                    label={t("no-action-survey--mobile")}
                    name="caller-mobile"
                    validations={callerMobileValidations}
                    onChange={(profile) => {
                        if (profile) setCallerMobile(profile?.mobile);
                    }}
                />
                {singleDropdownEnabled ? (
                    <>
                        <DropdownField
                            {...reasonField}
                            label={t("label-reason")}
                            name="reason"
                            validations={reasonValidations}
                            values={allOptionsDropdown}
                            placeholder={t("dropdown--select-option")}
                        />
                        {isReasonSelected(REASONS.partnerIssue) && (
                            <TypeaheadField
                                {...partnerField}
                                label={t("no-action-survey--partner")}
                                name="partner"
                                validations={partnerValidation}
                                values={partners}
                                placeholder={t("dropdown--select-option")}
                                allowNew={customPartnerEnabled}
                                addOptionOther={!customPartnerEnabled}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <DropdownField
                            {...reasonField}
                            label={t("label-reason")}
                            name="reason"
                            validations={reasonValidations}
                            values={reason_dropdown_options}
                            placeholder={t("dropdown--select-option")}
                        />

                        {isReasonSelected(REASONS.callFailed) && (
                            <DropdownField
                                {...detailsField}
                                label={t("no-action-survey--details")}
                                name="details"
                                validations={detailsValidations}
                                values={callFailedOptions}
                                placeholder={t("dropdown--select-option")}
                            />
                        )}

                        {isReasonSelected(REASONS.askedAccountInfo) && (
                            <DropdownField
                                {...accountInfoTypeField}
                                label={t("no-action-survey--account-info")}
                                name="account-info-type"
                                validations={accountInfoValidations}
                                values={acountInfoTypes}
                                placeholder={t("dropdown--select-option")}
                            />
                        )}

                        {isReasonSelected(REASONS.askedWaveInfo) && (
                            <DropdownField
                                {...infoTypeField}
                                label={t("no-action-survey--info-type")}
                                name="info-type"
                                validations={infoValidations}
                                values={infoTypes}
                                placeholder={t("dropdown--select-option")}
                            />
                        )}

                        {isReasonSelected(REASONS.notPhoneOwner) && (
                            <DropdownField
                                {...notPhoneOwnerDetailsField}
                                label={t("no-action-survey--details")}
                                name="not-phone-owner-details"
                                validations={notPhoneOwnerValidations}
                                values={notPhoneOwnerDetails}
                                placeholder={t("dropdown--select-option")}
                            />
                        )}
                    </>
                )}

                <TextField
                    {...commentsField}
                    label={t("no-action-survey--comments")}
                    name="comments"
                    validations={[]}
                />
            </Form>
        </MutatorModal>
    );
};
export const NoActionCallSurveyMutator = createFragmentContainer(
    _NoActionCallSurveyMutator,
    {}
);
