import * as React from "react";
import { useTranslation } from "react-i18next";

export const NoProfileSelected = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <>
            <title>{t("no-profile-selected--title")}</title>
            <div className="text-center text-gray-500">
                <h3 className="text-xl text-bold">
                    {t("no-profile-selected--subtitle")}
                </h3>
                <h5 className=" text-bold">
                    {t("no-profile-selected--description")}
                </h5>
            </div>
        </>
    );
};
