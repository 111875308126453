import { NavigationLink } from "./NavigationLink";

export const MerchantUserProfileLink = (props: {
    mobile: string;
    name: string;
    ufid: string;
    opaqueId?: string;
}) => {
    if (!props.mobile) return <>{props.name}</>;

    return (
        <NavigationLink
            tab="merchant"
            mobile={props.mobile}
            opaqueId={props.opaqueId}
        >
            {props.name}: {props.ufid} ({props.mobile})
        </NavigationLink>
    );
};
