import * as React from "react";
import { ReactNode } from "react";

import { COUNTRIES } from "@/types/countries";

import { parseMobile } from "@/utils/mobile";

import { InvalidMobile } from "./InvalidMobile";
import { ProfileNotFound } from "./ProfileNotFound";

type ProfileBoundaryProps = {
    mobile: string;
    country?: string;
    children?: ReactNode;
};

/**
 * Wrapper for the profile components. It renders the wrapped component if the country
 * of profile's mobile number matches the selected country (see {@link useCountry}).
 * It renders {@link ProfileNotFound} if the countries don't match.
 * @param props {@link ProfileBoundaryProps}
 */
export const ProfileBoundary = (
    props: ProfileBoundaryProps
): React.ReactElement => {
    try {
        const phoneNumber = parseMobile(props.mobile);
        const selectedCountry = props.country
            ? COUNTRIES[props.country?.toUpperCase()]
            : undefined;
        const countryFromMobile = phoneNumber?.country
            ? COUNTRIES[phoneNumber?.country]
            : undefined;

        /**
         * FIXME (antonio): we shouldn't do the check on the client, as this is
         * easy to bypass and have access to profiles outside of one's country!
         * Ideally this check is done on the server when requesting a profile.
         */
        if (
            selectedCountry &&
            countryFromMobile &&
            selectedCountry.iso2 !== countryFromMobile.iso2
        ) {
            return (
                <ProfileNotFound
                    countryFromMobile={countryFromMobile}
                    selectedCountry={selectedCountry}
                />
            );
        }

        return <>{props.children}</>;
    } catch {
        return <InvalidMobile mobile={props.mobile} />;
    }
};
