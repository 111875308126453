import * as React from "react";
import { Button, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type SubmitButtonProps = {
    isValid: boolean;
    isWorking: boolean;
    children: React.ReactNode;
};

/**
 * Submit button for the Form component which itself should be wrapped inside a
 * `<MutatorModal>`. You should not need to use the `<SubmitButton>` component explicitly.
 */
export const SubmitButton = (props: SubmitButtonProps) => {
    const { isValid, isWorking, children } = props;
    const { t } = useTranslation();

    return (
        <FormGroup>
            <Button
                type="submit"
                variant="primary"
                disabled={isWorking || !isValid}
                className="float-right"
                data-testid="submit-button"
            >
                {isWorking ? t("submit-button--working") : children}
            </Button>
            <div className="clearfix" />
        </FormGroup>
    );
};
