import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { RemittanceTransferRefundMutationResponse } from "./__generated__/RemittanceTransferRefundMutation.graphql";
import { RemittanceTransferRefund_transfer } from "./__generated__/RemittanceTransferRefund_transfer.graphql";

const mutation = graphql`
    mutation RemittanceTransferRefundMutation($remittanceTransferId: ID!) {
        remittanceTransferRefund(remittanceTransferId: $remittanceTransferId) {
            isRefunded
            transfer {
                senderName
                country
                senderMobile
                receiveAmount
            }
        }
    }
`;

type RemittanceTransferRefundInputParams = {
    transfer: RemittanceTransferRefund_transfer;
};

const _RemittanceTransferRefundMutator = (
    props: RelayModalProps & RemittanceTransferRefundInputParams
) => {
    const { onHide, transfer } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: RemittanceTransferRefundMutationResponse): string | null => {
            if (response) return t("refund-remittance--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.RemittanceRefund,
            data: { transferOpaqueId: transfer.opaqueId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            remittanceTransferId: transfer.opaqueId,
        });
    }, [mutator, transfer]);

    return (
        <MutatorModal
            {...mutator}
            title={t("refund-remittance--title", {
                amount: transfer.amount,
                name: transfer.senderName,
            })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-refund")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <p>
                    {t("refund-remittance--description", {
                        amount: transfer.amount,
                        name: transfer.senderName,
                        mobile: transfer.senderMobile,
                    })}
                </p>
            </Form>
        </MutatorModal>
    );
};

export const RemittanceTransferRefundMutator = createFragmentContainer(
    _RemittanceTransferRefundMutator,
    {
        transfer: graphql`
            fragment RemittanceTransferRefund_transfer on RemittanceTransferReceivedEntry {
                opaqueId
                senderName
                senderMobile
                amount
            }
        `,
    }
);
