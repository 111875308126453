import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { ExtendAgentGamingAgentWatchlistMutationResponse } from "./__generated__/ExtendAgentGamingAgentWatchlistMutation.graphql";

const mutation = graphql`
    mutation ExtendAgentGamingAgentWatchlistMutation(
        $agentId: ID!
        $comment: String!
    ) {
        extendAgentGamingAgentWatchlist(agentId: $agentId, comment: $comment) {
            success
        }
    }
`;

type AgentGamingAgentWatchlistParams = {
    agentId: string;
};

const _ExtendAgentGamingAgentWatchlistMutator = (
    props: AgentGamingAgentWatchlistParams & RelayModalProps
) => {
    const { onHide, agentId } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ExtendAgentGamingAgentWatchlistMutationResponse) => {
            if (response?.extendAgentGamingAgentWatchlist?.success) {
                return t("agent-gaming-agent-watchlist--success-extend");
            }
            return t("agent-gaming-agent-watchlist--error");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.UpdateAgentOnAgentGamingWatchlist,
            data: { agentId },
        },
        ...props,
    });

    const commentField = useTextField();

    const handleSubmit = useCallback(() => {
        const body = {
            agentId: agentId,
            comment: commentField.value,
        };
        mutator.submit(mutation, body);
    }, [mutator, commentField.value, agentId]);

    return (
        <MutatorModal
            {...mutator}
            title={t("agent-gaming-agent-watchlist--title-extend", { name })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...commentField}
                    label={t("label-comment")}
                    name="comment"
                    defaultValue={""}
                    validations={[]}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};
export const ExtendAgentGamingAgentWatchlistMutator = createFragmentContainer(
    _ExtendAgentGamingAgentWatchlistMutator,
    {}
);
