import { Reducer, useCallback, useEffect, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

/**
 * Run an async function in a hook-y way, keeping track of whether it's currently
 * in-flight and whether it has produced a return value or thrown an exception.
 *
 * @param f: the async function you want to run.
 *
 * @param deps: an *optional* array of dependecies à la `useCallback` or `useMemo`.
 *
 *     `useAsyncFunction` returns an object with an `.invoke` method. If you don't care
 *     about the object identity of this method, you can leave `deps` undefined; every
 *     time you run `useAsyncFunction` `.invoke` will be a fresh function instance.
 *
 *     If you _do_ care though, you can pass an explicit array of deps and you'll get a
 *     stable identity for `.invoke` as long as the deps don't change.
 */
export function useAsyncFunction<F extends (...args: any[]) => Promise<any>>(
    f: F,
    deps?: ReadonlyArray<any>
) {
    // Why `useReducer` instead of `useState`? I just think the state transitions are
    // sufficiently tricky that it's helpful to see them in an explicit reducer.
    const [state, dispatch] = useReducer(
        asyncFunctionReducer as AsyncFunctionReducer<F>,
        undefined
    );

    /*
    If our `deps` change, that means `f` is now "meaningfully" different (not just a
    different instantiation of the "same" function), so whatever state we were keeping
    about the previous invocation is no longer relevant.
     */
    useEffect(() => dispatch({ type: "reset" }), deps ?? []); // eslint-disable-line react-hooks/exhaustive-deps

    const invoke = useCallback(
        async (...args: Parameters<F>): Promise<PromisedBy<F> | undefined> => {
            const invocationId = uuidv4();
            dispatch({ type: "invoke", invocationId });
            try {
                const result = await f(...args);
                dispatch({ type: "result", invocationId, result });
                return result;
            } catch (error: any) {
                dispatch({ type: "error", invocationId, error });
            }
        },
        deps ?? [f] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return {
        invoke,
        isLoading: state?.type === "loading",
        error: state?.type === "error" ? state.error : undefined,
        result: state?.type === "result" ? state.result : undefined,
    };
}

type PromisedBy<F> = F extends (...args: any[]) => Promise<infer T> ? T : never;

/*
We may end up invoking `f` multiple times (possibly in quick succession), and the
invocations could finish out of order. We want isLoading/error/result to reflect
the most recent _invocation_, not the most recent response, so we tag each
invocation with an id.
 */
type AsyncFunctionState<F> =
    | { invocationId: string; type: "loading" }
    | { invocationId: string; type: "error"; error: Error }
    | { invocationId: string; type: "result"; result: PromisedBy<F> }
    | undefined;

type AsyncFunctionAction<F> =
    | { type: "result"; invocationId: string; result: PromisedBy<F> }
    | { type: "error"; invocationId: string; error: Error }
    | { type: "invoke"; invocationId: string }
    | { type: "reset" };

type AsyncFunctionReducer<F> = Reducer<
    AsyncFunctionState<F> | undefined,
    AsyncFunctionAction<F>
>;

function asyncFunctionReducer<F>(
    state: AsyncFunctionState<F>,
    action: AsyncFunctionAction<F>
): AsyncFunctionState<F> {
    switch (action.type) {
        case "result":
            if (action.invocationId !== state?.invocationId) {
                return state;
            }
            return {
                invocationId: state.invocationId,
                type: "result",
                result: action.result,
            };
        case "error":
            if (action.invocationId !== state?.invocationId) {
                return state;
            }
            return {
                invocationId: state.invocationId,
                type: "error",
                error: action.error,
            };
        case "invoke":
            return {
                invocationId: action.invocationId,
                type: "loading",
            };
        case "reset":
            return undefined;
    }
}
