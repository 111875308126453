/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ChangePayoutRecipient_transfer = {
    readonly tcid: string;
    readonly amount: string;
    readonly senderOpaqueId: string | null;
    readonly sender: string;
    readonly maybeRecipientName: string | null;
    readonly recipientMobile: string;
    readonly isReversal: boolean;
    readonly " $refType": "ChangePayoutRecipient_transfer";
};
export type ChangePayoutRecipient_transfer$data = ChangePayoutRecipient_transfer;
export type ChangePayoutRecipient_transfer$key = {
    readonly " $data"?: ChangePayoutRecipient_transfer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ChangePayoutRecipient_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangePayoutRecipient_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tcid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderOpaqueId",
      "storageKey": null
    },
    {
      "alias": "sender",
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": "maybeRecipientName",
      "args": null,
      "kind": "ScalarField",
      "name": "recipientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isReversal",
      "storageKey": null
    }
  ],
  "type": "PayoutTransferEntry",
  "abstractKey": null
};
(node as any).hash = 'a80a6c8631d07969b6b9bdd0656b79af';
export default node;
