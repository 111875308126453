import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import {
    CurrencyField,
    useCurrencyField,
} from "../../components/CurrencyField";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { usePermissions } from "@/hooks/usePermissions";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";
import { Action } from "@/types/actions";

import { C, M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";

import { AwardCreditMutationResponse } from "./__generated__/AwardCreditMutation.graphql";

const mutation = graphql`
    mutation AwardCreditMutation(
        $mobile: String!
        $amount: Money!
        $reason: String!
        $canReadHistory: Boolean!
        $canReadBalance: Boolean!
    ) {
        awardCredit(mobile: $mobile, amount: $amount, reason: $reason) {
            withdrawalCode
            wallet {
                ...PersonalTabHeader_wallet
                    @arguments(canReadBalance: $canReadBalance)
                ...History_wallet
                    @include(if: $canReadHistory)
                    @arguments(last: 5)
            }
        }
    }
`;

type AwardCreditInputParams = {
    name: string;
    mobile: string;
    balance: M;
};

const _AwardCreditMutator = (
    props: RelayModalProps & AwardCreditInputParams
) => {
    const { onHide, name, mobile, balance } = props;
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: AwardCreditMutationResponse): string => {
            if (!response.awardCredit) {
                return "";
            }

            const wdcode = response.awardCredit.withdrawalCode;
            let s = t("award-credit--success", { name, mobile });
            if (!_.isNil(wdcode)) {
                s += t("award-credit--success-wd", { code: wdcode });
            } else {
                s += t("award-credit--success-sms");
            }
            return s;
        },
        [t, name, mobile]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.AwardCredit,
            data: { mobile, balance },
        },
        ...props,
    });

    const amountField = useCurrencyField();
    const reasonField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobile,
            amount: amountField.value?.serialize(),
            reason: reasonField.value,
            canReadHistory,
            canReadBalance,
        });
    }, [
        mutator,
        mobile,
        amountField.value,
        reasonField.value,
        canReadHistory,
        canReadBalance,
    ]);

    const amountValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!amountField.value) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-amount"),
                display: "none",
            });
        } else {
            if (!amountField.value.isPositive()) {
                validations.push({
                    type: "error",
                    message: t("form-validation--negative-credit"),
                    display: "if-blurred",
                });
            }
            validations.push({
                type: "info",
                message: t("balance-new--customer", {
                    balance: balance.add(amountField.value).toString(),
                }),
                display: "always",
            });
        }
        return validations;
    }, [t, amountField.value, balance]);

    const reasonValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (_.isEmpty(reasonField.value)) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-reason"),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, reasonField.value]);

    const isValid = useMemo(() => {
        return !hasError([reasonValidations, amountValidations]);
    }, [reasonValidations, amountValidations]);

    const defaultAmounts: { [key in C]?: number } = {
        UGX: 5000,
        CFA: 1000,
        GMD: 100,
        XAF: 1000,
    };
    const defaultAmount = defaultAmounts[balance.currency] || 1000;

    return (
        <MutatorModal {...mutator} title={t("award-credit--title", { name })}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("award-credit--submit")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <CurrencyField
                    {...amountField}
                    label={t("label-amount")}
                    currency={balance.currency}
                    name="amount"
                    validations={amountValidations}
                    defaultValue={new M(balance.currency, defaultAmount)}
                    autoFocus
                />
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};

export const AwardCreditMutator = createFragmentContainer(
    _AwardCreditMutator,
    {}
);
