/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnblockFromUsingQrCardsMutationVariables = {
    userId: string;
    reason: string;
};
export type UnblockFromUsingQrCardsMutationResponse = {
    readonly unblockFromUsingQrCards: {
        readonly user: {
            readonly " $fragmentRefs": FragmentRefs<"UnblockFromUsingQrCards_user">;
        };
    } | null;
};
export type UnblockFromUsingQrCardsMutation = {
    readonly response: UnblockFromUsingQrCardsMutationResponse;
    readonly variables: UnblockFromUsingQrCardsMutationVariables;
};



/*
mutation UnblockFromUsingQrCardsMutation(
  $userId: ID!
  $reason: String!
) {
  unblockFromUsingQrCards(userId: $userId, reason: $reason) {
    user {
      ...UnblockFromUsingQrCards_user
      id
    }
  }
}

fragment DirectDepositBlockHistory_user on User {
  id
  narrativeDirectDepositBlockHistory {
    whatHappened
    whenHappened
    doneBy
    reason
  }
}

fragment UnblockFromUsingQrCards_user on User {
  ...DirectDepositBlockHistory_user
  id
  qrCardBlock {
    whenBlocked
    blockedBy
    blockReason
    whenExpires
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "reason",
    "variableName": "reason"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnblockFromUsingQrCardsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnblockFromUsingQrCards",
        "kind": "LinkedField",
        "name": "unblockFromUsingQrCards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UnblockFromUsingQrCards_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnblockFromUsingQrCardsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnblockFromUsingQrCards",
        "kind": "LinkedField",
        "name": "unblockFromUsingQrCards",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BlockHistoryEntry",
                "kind": "LinkedField",
                "name": "narrativeDirectDepositBlockHistory",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whatHappened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenHappened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doneBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reason",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ActiveBlock",
                "kind": "LinkedField",
                "name": "qrCardBlock",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenBlocked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "blockedBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "blockReason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenExpires",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "256965ca4896086334638fe9dc09d0aa",
    "id": null,
    "metadata": {},
    "name": "UnblockFromUsingQrCardsMutation",
    "operationKind": "mutation",
    "text": "mutation UnblockFromUsingQrCardsMutation(\n  $userId: ID!\n  $reason: String!\n) {\n  unblockFromUsingQrCards(userId: $userId, reason: $reason) {\n    user {\n      ...UnblockFromUsingQrCards_user\n      id\n    }\n  }\n}\n\nfragment DirectDepositBlockHistory_user on User {\n  id\n  narrativeDirectDepositBlockHistory {\n    whatHappened\n    whenHappened\n    doneBy\n    reason\n  }\n}\n\nfragment UnblockFromUsingQrCards_user on User {\n  ...DirectDepositBlockHistory_user\n  id\n  qrCardBlock {\n    whenBlocked\n    blockedBy\n    blockReason\n    whenExpires\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f2dcd414131df3e91f35e0d6377ff349';
export default node;
