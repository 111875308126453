import { useTranslation } from "react-i18next";

import { AgentProfileLink } from "@/components/AgentProfileLink";
import { MerchantProfileLink } from "@/components/MerchantProfileLink";
import { MerchantUserProfileLink } from "@/components/MerchantUserProfileLink";
import { ProfileLink } from "@/components/ProfileLink";
import { TxInfo } from "@/components/TxInfo";

import { M } from "@/utils/currency";

import { AgentHistoryAtxEntry_tx } from "./__generated__/AgentHistoryAtxEntry_tx.graphql";

const capitalizeFirstLetter = (string: string) =>
    string[0].toUpperCase() + string.slice(1);

interface LinkProps {
    tx: AgentHistoryAtxEntry_tx;
}

const LinkToProfile = ({ tx }: LinkProps) => {
    const { t } = useTranslation();

    if (tx.type === "REBALANCE") {
        // if the amount is the "wrong" sign, it means we're the subagent, not
        // the superagent
        const isSubagent =
            M.fromSerialized(tx.amount).isPositive() === tx.isDeposit;
        if (isSubagent)
            return (
                <AgentProfileLink
                    agentId={tx.agentId}
                    name={tx.agentName}
                    agentUfid={tx.agentUfid}
                    opaqueId={tx.agentTransactionId}
                />
            );

        return (
            <AgentProfileLink
                agentId={tx.subagentId || ""}
                name={tx.subagentName || t("agent-atx-entry--no-subagent")}
                agentUfid={tx.subagentUfid || ""}
                opaqueId={tx.agentTransactionId}
            />
        );
    }

    if (tx.type === "BUSINESS" && tx.walletAgentId !== null) {
        const isWalletAgent =
            M.fromSerialized(tx.amount).isPositive() === tx.isDeposit;
        if (isWalletAgent)
            return (
                <AgentProfileLink
                    agentId={tx.agentId}
                    name={tx.agentName}
                    agentUfid={tx.agentUfid}
                    opaqueId={tx.agentTransactionId}
                />
            );

        return (
            <AgentProfileLink
                agentId={tx.walletAgentId || ""}
                name={tx.customerName || t("agent-atx-entry--no-subagent")}
                agentUfid={tx.walletAgentUfid || ""}
                opaqueId={tx.agentTransactionId}
            />
        );
    }

    if (tx.type === "BUSINESS" && tx.merchantUfid !== null) {
        return (
            <MerchantProfileLink
                name={
                    tx.customerName ||
                    t("agent-atx-entry--merchant-name-missing")
                }
                merchantId={
                    tx.merchantId || t("agent-atx-entry--merchant-id-missing")
                }
                opaqueId={tx.agentTransactionId}
            />
        );
    }

    if (tx.isBusinessWalletAtx)
        return (
            <MerchantUserProfileLink
                mobile={
                    tx.maybeTerminatedCustomerMobile ||
                    t("agent-atx-entry--merchant-mobile-missing")
                }
                name={
                    tx.customerName ||
                    t("agent-atx-entry--merchant-name-missing")
                }
                ufid={
                    tx.merchantUfid || t("agent-atx-entry--merchant-id-missing")
                }
                opaqueId={tx.agentTransactionId}
            />
        );

    return (
        <ProfileLink
            name={
                tx.customerName || t("agent-atx-entry--customer-name-missing")
            }
            mobile={
                tx.maybeTerminatedCustomerMobile ||
                t("agent-atx-entry--customer-mobile-missing")
            }
            opaqueId={tx.agentTransactionId}
        />
    );
};

interface SubLineProps {
    tx: AgentHistoryAtxEntry_tx;
    hideId?: boolean;
}

export function AgentAtxEntrySubLine({ tx, hideId }: SubLineProps) {
    const { t } = useTranslation();

    // if the amount is the "wrong" sign, it means we're the subagent, not
    // the superagent
    const isSubagent =
        tx.type === "REBALANCE" &&
        M.fromSerialized(tx.amount).isPositive() === tx.isDeposit;

    const for_or_at = isSubagent
        ? t("agent-atx-entry--at")
        : t("agent-atx-entry--for");

    const title = hideId
        ? capitalizeFirstLetter(for_or_at)
        : `${tx.agentTransactionId} ${for_or_at}`;

    return (
        <TxInfo>
            {title} <LinkToProfile tx={tx} />
        </TxInfo>
    );
}
