/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RemittanceTransferReversalEntry_tx = {
    readonly id: string;
    readonly opaqueId: string;
    readonly description: string;
    readonly amount: string;
    readonly isPending: boolean;
    readonly senderName: string;
    readonly senderMobile: string;
    readonly senderCountry: string;
    readonly recipientMobile: string;
    readonly receiveAmount: string;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "RemittanceTransferReversalEntry_tx";
};
export type RemittanceTransferReversalEntry_tx$data = RemittanceTransferReversalEntry_tx;
export type RemittanceTransferReversalEntry_tx$key = {
    readonly " $data"?: RemittanceTransferReversalEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RemittanceTransferReversalEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemittanceTransferReversalEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "opaqueId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPending",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiveAmount",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "RemittanceTransferReversalEntry",
  "abstractKey": null
};
(node as any).hash = 'f5a983aaae16d2fe588a37d0681004bc';
export default node;
