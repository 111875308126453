/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TerminateUserMutationVariables = {
    mobile: string;
    terminationReason: string;
};
export type TerminateUserMutationResponse = {
    readonly terminateUser: {
        readonly result: string;
        readonly isTerminated: boolean;
    } | null;
};
export type TerminateUserMutation = {
    readonly response: TerminateUserMutationResponse;
    readonly variables: TerminateUserMutationVariables;
};



/*
mutation TerminateUserMutation(
  $mobile: String!
  $terminationReason: String!
) {
  terminateUser(mobile: $mobile, terminationReason: $terminationReason) {
    result
    isTerminated
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "terminationReason"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "terminationReason",
        "variableName": "terminationReason"
      }
    ],
    "concreteType": "TerminateUser",
    "kind": "LinkedField",
    "name": "terminateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTerminated",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TerminateUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TerminateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1103c1c78c5f9ebc05324ca93017d82f",
    "id": null,
    "metadata": {},
    "name": "TerminateUserMutation",
    "operationKind": "mutation",
    "text": "mutation TerminateUserMutation(\n  $mobile: String!\n  $terminationReason: String!\n) {\n  terminateUser(mobile: $mobile, terminationReason: $terminationReason) {\n    result\n    isTerminated\n  }\n}\n"
  }
};
})();
(node as any).hash = '07725c1069ec31fcd1438f44b742a4fb';
export default node;
