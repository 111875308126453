import * as React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { useCurrentCall } from "@/hooks/useCurrentCall";
import { useFlag } from "@/hooks/useFlag";
import { usePermissions } from "@/hooks/usePermissions";

import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_PERFORM_RISKY_PIN_RESET } from "@/types/Permissions";
import { Action } from "@/types/actions";

import { AllowToRecoverPinMutationResponse } from "./__generated__/AllowToRecoverPinMutation.graphql";
import { AllowToRecoverPin_user } from "./__generated__/AllowToRecoverPin_user.graphql";

const mutation = graphql`
    mutation AllowToRecoverPinMutation($userId: ID!) {
        allowToRecoverPin(userId: $userId) {
            user {
                id
                inPinRecovery
            }
            supportCopy
        }
    }
`;

type AllowToRecoverPinParams = {
    user: AllowToRecoverPin_user;
};

const PIN_RECOVERY_MINIMUM_IOS_VERSION = 21012000;
const PIN_RECOVERY_MINIMUM_ANDROID_VERSION = 21010800;

const _AllowToRecoverPinMutator = (
    props: AllowToRecoverPinParams & RelayModalProps
) => {
    const { user } = props;

    const { t } = useTranslation();

    const { currentCall } = useCurrentCall();
    const isVoipCall = currentCall.callType != "none" && currentCall.isVoip;
    const allowVOIPVerification = useFlag(
        "tmp-allow-voip-verification"
    )?.isActive;
    const showRiskyPinResetWarning =
        useFlag("show-risky-pin-reset-warning")?.isActive ?? false;
    const canPerformRiskyPinReset = usePermissions(CAN_PERFORM_RISKY_PIN_RESET);
    const isPinResetRisky = props.user.isPinResetRisky;

    const customerAppMinimumAppVersion = user.customerAppVersion?.appType
        ?.toLowerCase()
        .includes("ios")
        ? PIN_RECOVERY_MINIMUM_IOS_VERSION
        : PIN_RECOVERY_MINIMUM_ANDROID_VERSION;
    const customerAppSupportsPINRecovery = user.customerAppVersion
        ?.appVersionCode
        ? user.customerAppVersion?.appVersionCode >=
          customerAppMinimumAppVersion
        : false;

    const mutator = useMutator({
        onMutationSuccess: (result: AllowToRecoverPinMutationResponse) => {
            if (result.allowToRecoverPin?.supportCopy) {
                return result.allowToRecoverPin?.supportCopy;
            }

            return t("recover-pin--error");
        },
        trackActionInfo: { name: Action.RecoverPin, data: { userId: user.id } },
        ...props,
    });

    const riskyPinResetWarning = (
        <Alert variant="warning">
            {t("risky-pin-reset--be-extra-careful")}
        </Alert>
    );

    let content;
    if (
        showRiskyPinResetWarning &&
        isPinResetRisky &&
        !canPerformRiskyPinReset
    ) {
        content = (
            <Alert variant="danger">
                {t("risky-pin-reset--insufficient-permissions")}
            </Alert>
        );
    } else {
        content = (
            <Form
                {...mutator}
                isValid={true}
                submitText={t("recover-pin--submit")}
                onSubmit={() => {
                    mutator.submit(mutation, { userId: user.id });
                }}
            >
                {showRiskyPinResetWarning && isPinResetRisky
                    ? riskyPinResetWarning
                    : null}
                <p className="mb-2">{t("recover-pin--description")}</p>
                {allowVOIPVerification && isVoipCall ? (
                    <Alert variant="warning">
                        <span className="whitespace-pre">
                            {t("voip-verification")}
                        </span>
                    </Alert>
                ) : null}
                {!customerAppSupportsPINRecovery && (
                    <p className="bg-pink-400">
                        {t("recover-pin--app-version-out-dated")}
                    </p>
                )}
            </Form>
        );
    }
    return (
        <MutatorModal {...mutator} title={t("recover-pin--title")}>
            {content}
        </MutatorModal>
    );
};

export const AllowToRecoverPinMutator = createFragmentContainer(
    _AllowToRecoverPinMutator,
    {
        user: graphql`
            fragment AllowToRecoverPin_user on User {
                id
                inPinRecovery
                isPinResetRisky
                customerAppVersion {
                    appType
                    string
                    isUpToDate
                    appVersionCode
                }
            }
        `,
    }
);
