import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";

import { RelayModalProps } from "../../types/MutatorModal";
import { Action } from "@/types/actions";

import { M } from "../../utils/currency";

import { RefundPurchaseEntryMutationResponse } from "./__generated__/RefundPurchaseEntryMutation.graphql";
import { RefundPurchaseEntry_transfer } from "./__generated__/RefundPurchaseEntry_transfer.graphql";

const mutation = graphql`
    mutation RefundPurchaseEntryMutation($transferId: ID!) {
        refundMerchantSale(transferId: $transferId) {
            transfer {
                id
            }
        }
    }
`;

type RefundPurchaseEntryInputParams = {
    transfer: RefundPurchaseEntry_transfer;
};

const _RefundPurchaseEntryMutator = (
    props: RelayModalProps & RefundPurchaseEntryInputParams
) => {
    const { onHide, transfer } = props;
    const amount = M.fromSerialized(transfer.amount);

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: RefundPurchaseEntryMutationResponse): string | null => {
            if (response) return t("refund--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.Refund,
            data: { transferId: transfer.transferId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            transferId: transfer.transferId,
        });
    }, [mutator, transfer]);

    return (
        <MutatorModal
            {...mutator}
            title={t("refund-merchant-sale--title", {
                amount,
                name: transfer.merchantName,
            })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-refund")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <p>
                    {t("refund-merchant-sale--description", {
                        amount,
                        merchantName: transfer.merchantName,
                    })}
                </p>
            </Form>
        </MutatorModal>
    );
};
export const RefundPurchaseEntryMutator = createFragmentContainer(
    _RefundPurchaseEntryMutator,
    {
        transfer: graphql`
            fragment RefundPurchaseEntry_transfer on PurchaseEntry {
                id
                transferId
                amount

                merchantName
            }
        `,
    }
);
