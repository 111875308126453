import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { CancelAllAtxMutationResponse } from "./__generated__/CancelAllAtxMutation.graphql";
import { CancelAllAtx_wallet } from "./__generated__/CancelAllAtx_wallet.graphql";

const mutation = graphql`
    mutation CancelAllAtxMutation($mobile: String!) {
        cancelAllAtx(mobile: $mobile) {
            success
        }
    }
`;

type CancelAllAtxInputParams = {
    wallet: CancelAllAtx_wallet;
};

const _CancelAllAtxMutator = (
    props: CancelAllAtxInputParams & RelayModalProps
) => {
    const { wallet, onHide } = props;
    const { mobile } = wallet;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: CancelAllAtxMutationResponse): string => {
            const success = response.cancelAllAtx?.success;
            return success ? t("cancel-all-atx--success") : "";
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: { name: Action.CancelAllAtx, data: { mobile } },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { mobile });
    }, [mutator, mobile]);

    return (
        <MutatorModal
            {...mutator}
            title={t("cancel-all-atx--title", { name: wallet.name })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-confirm")}
                onSubmit={handleSubmit}
                onDone={onHide}
            />
        </MutatorModal>
    );
};

export const CancelAllAtxMutator = createFragmentContainer(
    _CancelAllAtxMutator,
    {
        wallet: graphql`
            fragment CancelAllAtx_wallet on Wallet {
                name
                mobile
            }
        `,
    }
);
