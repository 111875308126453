import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { BlockFromAgentDeposits_user } from "./__generated__/BlockFromAgentDeposits_user.graphql";

import { AgentDepositsBlockHistory } from "./AgentDepositsBlockHistory";

const mutation = graphql`
    mutation BlockFromAgentDepositsMutation($userId: ID!, $reason: String!) {
        blockFromAgentDeposits(userId: $userId, reason: $reason) {
            user {
                ...BlockFromAgentDeposits_user
            }
        }
    }
`;

type BlockFromAgentDepositsInputParams = {
    user: BlockFromAgentDeposits_user;
};

const _BlockFromAgentDepositsMutator = (
    props: BlockFromAgentDepositsInputParams & RelayModalProps
) => {
    const { user, onHide } = props;
    const onMutationSuccess = (_: BlockFromAgentDeposits_user) => {
        return "";
    };

    const { t } = useTranslation();

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.BlockFromAgentDeposits,
            data: { userId: user.id },
        },
        ...props,
    });

    const reasonField = useTextField();

    const reasonValidations: Validation[] = useMemo(
        () =>
            reasonField.value.length === 0
                ? [
                      {
                          type: "error",
                          message: t("block-agent-deposits--missing-reason"),
                          display: "if-blurred",
                      },
                  ]
                : [],
        [t, reasonField.value.length]
    );

    const handleSubmit = () => {
        mutator.submit(mutation, {
            userId: user.id,
            reason: reasonField.value,
        });
    };

    const isValid = useMemo(() => {
        return !hasError([reasonValidations]);
    }, [reasonValidations]);

    const history: ReactNode = <AgentDepositsBlockHistory user={user} />;

    return (
        <MutatorModal
            {...mutator}
            size="lg"
            title={t("block-agent-deposits--title")}
        >
            <Form
                {...mutator}
                submitText={t("block-agent-deposits--submit")}
                isValid={isValid}
                onDone={onHide}
                onSubmit={handleSubmit}
            >
                <p>
                    <b>{t("block-agent-deposits--description")}</b>
                </p>
                <p>{t("block-agent-deposits--disclaimer")}</p>
                <TextField
                    {...reasonField}
                    as="textarea"
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
            {history}
        </MutatorModal>
    );
};

export const BlockFromAgentDepositsMutator = createFragmentContainer(
    _BlockFromAgentDepositsMutator,
    {
        user: graphql`
            fragment BlockFromAgentDeposits_user on User {
                id
                agentDepositsBlock {
                    whenBlocked
                    blockedBy
                    blockReason
                }
                ...AgentDepositsBlockHistory_user
            }
        `,
    }
);
