import * as React from "react";
import { useFragment } from "react-relay";

import { M } from "@/utils/currency";

import {
    AgentHistoryEntry_entry,
    AgentHistoryEntry_entry$key,
} from "./__generated__/AgentHistoryEntry_entry.graphql";

import { historyEntryFragment } from "./AgentHistory.fragments";
import * as HistoryEntry from "./HistoryEntry";

export type TxProps = {
    entry: AgentHistoryEntry_entry$key;
    title?: React.ReactNode;
    titleCallable?: (entry: AgentHistoryEntry_entry) => React.ReactNode;
    buttons?: React.ReactNode;
    isFocussed: boolean;
};

function AgentHistoryEntry(props: TxProps) {
    const entry = useFragment(historyEntryFragment, props.entry);

    return (
        <HistoryEntry.Container id={entry.id} isFocussed={props.isFocussed}>
            <HistoryEntry.TopRow
                isPending={entry.isPending}
                amount={M.fromSerialized(entry.amount)}
            >
                {props.title || props.titleCallable?.(entry) || null}
            </HistoryEntry.TopRow>

            <HistoryEntry.MiddleRow entry={entry} buttons={props.buttons} />
        </HistoryEntry.Container>
    );
}

export default AgentHistoryEntry;
