import { ReactNode } from "react";

import { ActionButton } from "./ActionButton";

type HistoryActionButtonProps = {
    name: string;
    requiredPermissions?: string[];
    children: ReactNode;
    disabled?: boolean;
};

export const HistoryActionButton = (props: HistoryActionButtonProps) => {
    const disabled = !!props.disabled;
    return (
        <ActionButton
            name={props.name}
            as="menuitem"
            disabled={disabled}
            requiredPermissions={props.requiredPermissions}
        >
            {props.children}
        </ActionButton>
    );
};
