/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LimitPeriod = "DAILY" | "MONTHLY" | "THIRTY_DAY" | "%future added value";
export type CalculateLimitsQueryVariables = {
    mobile: string;
};
export type CalculateLimitsQueryResponse = {
    readonly wallet: {
        readonly id: string;
        readonly futureInflowRemaining: ReadonlyArray<{
            readonly date: string;
            readonly amount: string;
        }>;
        readonly inflowLimitInfoV1: {
            readonly max: string;
            readonly used: string;
            readonly remaining: string;
            readonly periodDays: number | null;
            readonly period: LimitPeriod | null;
        } | null;
        readonly kycTier: string | null;
        readonly kybTier: string | null;
        readonly isTrader: boolean | null;
        readonly balanceLimitInfoV1: {
            readonly max: string;
            readonly used: string;
            readonly remaining: string;
        } | null;
    } | null;
};
export type CalculateLimitsQuery = {
    readonly response: CalculateLimitsQueryResponse;
    readonly variables: CalculateLimitsQueryVariables;
};



/*
query CalculateLimitsQuery(
  $mobile: String!
) {
  wallet(mobile: $mobile) {
    id
    futureInflowRemaining {
      date
      amount
    }
    inflowLimitInfoV1 {
      max
      used
      remaining
      periodDays
      period
    }
    kycTier
    kybTier
    isTrader
    balanceLimitInfoV1 {
      max
      used
      remaining
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "max",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "used",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remaining",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      }
    ],
    "concreteType": "Wallet",
    "kind": "LinkedField",
    "name": "wallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InflowRemaining",
        "kind": "LinkedField",
        "name": "futureInflowRemaining",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LimitInfo",
        "kind": "LinkedField",
        "name": "inflowLimitInfoV1",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periodDays",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "period",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kycTier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "kybTier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTrader",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LimitInfo",
        "kind": "LinkedField",
        "name": "balanceLimitInfoV1",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CalculateLimitsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CalculateLimitsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "521dac33bfef2bda4ffbdcbf427a4878",
    "id": null,
    "metadata": {},
    "name": "CalculateLimitsQuery",
    "operationKind": "query",
    "text": "query CalculateLimitsQuery(\n  $mobile: String!\n) {\n  wallet(mobile: $mobile) {\n    id\n    futureInflowRemaining {\n      date\n      amount\n    }\n    inflowLimitInfoV1 {\n      max\n      used\n      remaining\n      periodDays\n      period\n    }\n    kycTier\n    kybTier\n    isTrader\n    balanceLimitInfoV1 {\n      max\n      used\n      remaining\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e008699e0b24a473e4e3cefa2d0a134a';
export default node;
