import * as React from "react";
import { useLocation, useRouteMatch } from "react-router";

import {
    DEVICE_ID_FILTER_QUERY_PARAM_NAME,
    OPAQUE_ID_QUERY_PARAM_NAME,
    TabName,
    WHEN_QUERY_PARAM_NAME,
    getAddressForProfileById,
    getAddressForProfileByMobile,
    getTabFromWindowLocation,
} from "@/utils/navigation";

import { useNavigation } from "./useNavigation";

export function useFilterByDeviceId() {
    const { goToPage } = useNavigation();
    const match = useRouteMatch<{
        id: string;
        mobile: string;
        tab?: TabName;
    }>();
    const { search } = useLocation();
    const params = React.useMemo(() => new URLSearchParams(search), [search]);

    const [deviceId, setDeviceId] = React.useState<string | null>(
        params.get(DEVICE_ID_FILTER_QUERY_PARAM_NAME)
    );

    React.useEffect(() => {
        setDeviceId(params.get(DEVICE_ID_FILTER_QUERY_PARAM_NAME));
    }, [params]);

    const setDeviceIdFilter = React.useCallback(
        (deviceId: string | null) => {
            if (deviceId) {
                params.set(DEVICE_ID_FILTER_QUERY_PARAM_NAME, deviceId);
            } else {
                params.delete(DEVICE_ID_FILTER_QUERY_PARAM_NAME);
            }
            params.delete(WHEN_QUERY_PARAM_NAME);
            params.delete(OPAQUE_ID_QUERY_PARAM_NAME);

            const query = params.toString();
            const { id, mobile } = match.params;

            const tab = getTabFromWindowLocation();

            const address = mobile
                ? getAddressForProfileByMobile(mobile, query, tab)
                : getAddressForProfileById(id, query, tab);

            goToPage(address);

            setDeviceId(deviceId);
        },
        // adding match.params to dependencies will create unnecessary rerenders
        [goToPage, params] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return { deviceIdFilter: deviceId, setDeviceIdFilter };
}
