import { useState } from "react";
import * as React from "react";
import { Dropdown, DropdownButton, Spinner, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UseMutationConfig, graphql, useMutation } from "react-relay";
import { Disposable, MutationParameters, PayloadError } from "relay-runtime";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { Action } from "@/types/actions";

import { TransferCallBackofficeMutationResponse } from "./__generated__/TransferCallBackofficeMutation.graphql";
import { TransferCallFraudMutationResponse } from "./__generated__/TransferCallFraudMutation.graphql";

const BACKOFFICE_TYPE = "backoffice";
const FRAUD_TYPE = "fraud";

const mutationBackoffice = graphql`
    mutation TransferCallBackofficeMutation {
        transferCallBackoffice {
            result
        }
    }
`;

const mutationFraud = graphql`
    mutation TransferCallFraudMutation {
        transferCallFraud {
            result
        }
    }
`;

export const TransferCall = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isError, setIsError] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [commitBackoffice] = useMutation(mutationBackoffice);
    const [commitFraud] = useMutation(mutationFraud);
    const { t } = useTranslation();
    const trackEvent = useTrackEvent();

    const showMessage = (message: string) => {
        setShowToast(true);
        setToastMessage(message);
    };

    const onError = (errors: readonly PayloadError[] | null | undefined) => {
        setIsError(true);
        const message = errors ? errors[0].message : "";
        showMessage(`${t("transfer-call--error")} ${message}`);
    };

    const onSuccess = (message: string) => {
        setIsError(false);
        showMessage(message);
    };

    const onMutationSuccessBackoffice = (
        response: TransferCallBackofficeMutationResponse
    ) => {
        if (response.transferCallBackoffice?.result) {
            onSuccess(response.transferCallBackoffice?.result);
        }
    };

    const onMutationSuccessFraud = (
        response: TransferCallFraudMutationResponse
    ) => {
        if (response.transferCallFraud?.result) {
            onSuccess(response.transferCallFraud?.result);
        }
    };

    const handleClick = <RelayResultType, _VariablesType, _ParsedResultType>(
        commit: (config: UseMutationConfig<MutationParameters>) => Disposable,
        onMutationSuccess: (response: RelayResultType) => void,
        type: string
    ) => {
        setIsLoading(true);

        const action =
            type === FRAUD_TYPE
                ? Action.TransferCallFraud
                : Action.TransferCallBackoffice;
        trackEvent(action);

        commit({
            variables: {},
            onCompleted(response, payloadErrors) {
                if (payloadErrors) {
                    onError(payloadErrors);
                } else {
                    onMutationSuccess(response as RelayResultType);
                }
                setIsLoading(false);
            },
            onError(error) {
                onError([error]);
                setIsLoading(false);
            },
        });
    };

    return (
        <>
            <Toast
                className={`absolute top-[80px] right-[20px] ${
                    isError ? "bg-red-400" : "bg-green-400"
                }`}
                show={showToast}
                onClose={() => setShowToast(false)}
                delay={isError ? 5000 : 2000}
                autohide
            >
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
            <DropdownButton
                data-testid={"dropdown-transfer"}
                title={
                    isLoading ? (
                        <Spinner animation="border" size="sm" />
                    ) : (
                        <>&#10145;&nbsp;</>
                    )
                }
                className="ml-2"
                variant="outline-info"
            >
                <Dropdown.Item
                    onClick={() =>
                        handleClick(
                            commitBackoffice,
                            onMutationSuccessBackoffice,
                            BACKOFFICE_TYPE
                        )
                    }
                >
                    {t("transfer-call--back-office")}
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() =>
                        handleClick(
                            commitFraud,
                            onMutationSuccessFraud,
                            FRAUD_TYPE
                        )
                    }
                >
                    {t("transfer-call--fraud")}
                </Dropdown.Item>
            </DropdownButton>
        </>
    );
};
