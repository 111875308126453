import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { AwardCreditMutator } from "@/pages/mobile.[mobile].personal/AwardCredit";

import { M } from "../../utils/currency";

export const AwardCreditAction = (props: {
    mobile: string;
    name: string;
    balance: string;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("award-credit--action")}
            requiredPermissions={["supportapp_award_credit"]}
        >
            <AwardCreditMutator
                mobile={props.mobile}
                name={props.name}
                balance={M.fromSerialized(props.balance)}
            />
        </ActionButton>
    );
};
