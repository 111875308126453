/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentHistory_agent = {
    readonly id: string;
    readonly history: {
        readonly edges: ReadonlyArray<{
            readonly dailySummaries: ReadonlyArray<{
                readonly whenDayEnded: string;
                readonly date: string;
                readonly totalCommission: string;
                readonly " $fragmentRefs": FragmentRefs<"AgentHistorySummary_summary">;
            }> | null;
            readonly node: {
                readonly id: string;
                readonly whenEntered: string;
                readonly isCancelled: boolean;
                readonly agentTransactionId?: string | undefined;
                readonly composingEntries?: ReadonlyArray<{
                    readonly agentTransactionId?: string | undefined;
                }> | undefined;
                readonly " $fragmentRefs": FragmentRefs<"AgentHistoryEntryDelegate_entry">;
            } | null;
        } | null>;
        readonly pageInfo: {
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor: string | null;
            readonly endCursor: string | null;
        };
    } | null;
    readonly " $refType": "AgentHistory_agent";
};
export type AgentHistory_agent$data = AgentHistory_agent;
export type AgentHistory_agent$key = {
    readonly " $data"?: AgentHistory_agent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentHistory_agent">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentTransactionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "around"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeCancelled"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "when"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": [
          "history"
        ]
      }
    ]
  },
  "name": "AgentHistory_agent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "history",
      "args": [
        {
          "kind": "Variable",
          "name": "around",
          "variableName": "around"
        },
        {
          "kind": "Variable",
          "name": "includeCancelled",
          "variableName": "includeCancelled"
        },
        {
          "kind": "Literal",
          "name": "includePending",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "when",
          "variableName": "when"
        }
      ],
      "concreteType": "AgentHistoryConnection",
      "kind": "LinkedField",
      "name": "__AgentHistory_history_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AgentHistoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AgentDailySummary",
              "kind": "LinkedField",
              "name": "dailySummaries",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenDayEnded",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCommission",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AgentHistorySummary_summary"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "whenEntered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCancelled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "composingEntries",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v1/*: any*/)
                          ],
                          "type": "AgentTransactionEntry",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "AgentTransactionEntry",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AgentHistoryEntryDelegate_entry"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})();
(node as any).hash = 'abea828ea1becf0bc61217504f40a8e6';
export default node;
