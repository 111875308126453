import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { NavigationLink } from "./NavigationLink";

export const ScamInvestigationFields = ({
    opaqueId,
    scamInvestigationFields,
    activeBlock,
}: {
    opaqueId?: string;
    scamInvestigationFields: {
        deviceId: string | null;
        deviceModel: string | null;
        deviceName: string | null;
        userInterface: string | null;
        agentMobile?: string | null;
    };
    activeBlock?: {
        deviceId: string;
        blockReason: string;
    };
}) => {
    const { t } = useTranslation();
    const userInterfaceInfo = (
        <>
            {scamInvestigationFields.userInterface}
            {scamInvestigationFields.agentMobile && opaqueId && (
                <>
                    {" "}
                    at agent{" "}
                    <NavigationLink
                        mobile={scamInvestigationFields.agentMobile}
                        tab="agent"
                        opaqueId={opaqueId}
                    >
                        {scamInvestigationFields.agentMobile}
                    </NavigationLink>
                </>
            )}
        </>
    );
    return (
        <div className="border-l-2 pl-1 ml-4">
            {scamInvestigationFields.deviceId && (
                <>
                    <div className="text-ellipsis overflow-hidden">
                        {scamInvestigationFields.deviceModel}
                    </div>
                    <div
                        title={`Device Name: ${scamInvestigationFields.deviceName}`}
                    >
                        <b>Device ID:</b> {scamInvestigationFields.deviceId}
                        {activeBlock && (
                            <Badge
                                variant="warning"
                                title={`Reason: ${activeBlock.blockReason}`}
                                className="ml-1 uppercase"
                            >
                                {t("blocked")}
                            </Badge>
                        )}
                    </div>
                </>
            )}
            {(!scamInvestigationFields.deviceId ||
                scamInvestigationFields.agentMobile) && (
                <>{userInterfaceInfo}</>
            )}
        </div>
    );
};
