import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faPeopleGroup, faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    fetchQuery,
    graphql,
    useLazyLoadQuery,
    useRelayEnvironment,
} from "react-relay";

import { LiveMetricsContext } from "@/context/LiveMetrics";

import { Flags } from "@/external/flags";

import { useCountry } from "@/hooks/useCountry";
import { useCurrentCall } from "@/hooks/useCurrentCall";
import { usePermissions } from "@/hooks/usePermissions";

import { CAN_SEE_GENERAL_LIVE_METRICS } from "@/types/Permissions";
import { COUNTRIES } from "@/types/country";

import { LiveCallMetricsQuery } from "./__generated__/LiveCallMetricsQuery.graphql";

interface LiveCallMetricsProps {
    isRepLoggedIn: boolean | null;
    hourlyCallCount: number;
    topOfHour: number;
}

interface CallsInQueueProps {
    count: number;
    totalCount?: number;
}

interface MissedCallRateProps {
    status?: "warning" | "danger" | "info";
    rate: number;
}

interface CallsCountProps {
    count: number;
    topOfHour: number;
    icon?: IconDefinition;
    infoKey: string;
    errorKey: string;
    warning?: number;
    bad?: number;
}

function displayCount(count: number) {
    return count >= 0 ? count : "⛔";
}

export enum PendingCallThresholds {
    warning = 2,
    bad = 3,
}

enum MissedCallThresholds {
    warning = 1,
    bad = 10,
}

export const CallsInQueue = ({ count, totalCount }: CallsInQueueProps) => {
    const { t } = useTranslation();

    const pending_calls = Math.ceil(count);
    const type =
        pending_calls >= PendingCallThresholds.bad ? "default" : "minimal";

    let divClass = "text-white bg-gray-600";
    let dataTestStatus = "no-info";
    if (pending_calls < 0) {
        divClass = "text-white bg-gray-600";
        dataTestStatus = "no-info";
    } else if (pending_calls < PendingCallThresholds.warning) {
        divClass = "bg-gray-50";
        dataTestStatus = "ok";
    } else if (pending_calls < PendingCallThresholds.bad) {
        divClass = "bg-gray-50";
        dataTestStatus = "warning";
    } else {
        divClass = "bg-gray-50";
        dataTestStatus = "bad";
    }

    const tooltipText =
        totalCount !== undefined
            ? t("live-metrics--calls-in-queue-with-total", {
                  totalCount,
              })
            : t("live-metrics--calls-in-queue");

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id="call-in-queue-tooltip">
                    {count >= 0
                        ? tooltipText
                        : t("live-metrics--calls-in-queue--error")}
                </Tooltip>
            }
        >
            <div
                className={`flex items-center justify-center text-sm mx-2 rounded py-2 px-3 h-8 max-h-8 ${divClass}`}
                data-testid="metric-calls-in-queue"
                data-test-status={dataTestStatus}
            >
                <FontAwesomeIcon icon={faPeopleGroup} />
                {type !== "minimal" && (
                    <p className="ml-2 text-xs">
                        {t("live-metrics--call-queue")}
                    </p>
                )}
                <p className="ml-2 text-xl text-bold">
                    {displayCount(pending_calls)}
                </p>
            </div>
        </OverlayTrigger>
    );
};

export const CallsCount = ({
    count,
    topOfHour,
    icon,
    infoKey,
    errorKey,
    warning,
    bad,
}: CallsCountProps) => {
    const start = dayjs(topOfHour).format("hA");
    const end = dayjs(topOfHour).add(1, "hours").format("hA");

    const { t } = useTranslation();
    let divClass = "text-blue-600 bg-blue-100";
    let dataTestStatus = "";
    if ((warning || bad) && count != -1) {
        if (bad && count >= bad) {
            divClass = "text-black bg-red-600";
            dataTestStatus = "bad";
        } else if (warning && count >= warning) {
            divClass = "text-black bg-amber-600";
            dataTestStatus = "warning";
        } else {
            divClass = "text-white bg-green-600";
            dataTestStatus = "ok";
        }
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id="calls-tooltip">
                    {count >= 0 ? (
                        <>{t(infoKey, { start, end })}</>
                    ) : (
                        t(errorKey)
                    )}
                </Tooltip>
            }
        >
            <div
                className={`flex items-center justify-between py-2 px-3 rounded  mx-2 h-8 max-h-8 ${divClass}`}
                data-testid="metric-calls"
                data-test-status={dataTestStatus}
            >
                <div className="flex flex-col items-center justify-center mr-2 text-xs uppercase">
                    <span>{start}</span>
                    <span>{end}</span>
                </div>
                {icon && <FontAwesomeIcon icon={icon} />}
                <p className="text-xl text-medium">{displayCount(count)}</p>
            </div>
        </OverlayTrigger>
    );
};

export const MissedCallRate = ({
    rate,
    status = "info",
}: MissedCallRateProps) => {
    const { t } = useTranslation();

    const { country: countryISO2 } = useCountry();
    const country = COUNTRIES[countryISO2.toUpperCase()];
    const _rate = `${(rate * 100).toFixed(1)}%`;

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id="missed-call-rate-tooltip">
                    {rate >= 0 ? (
                        <>
                            {t("live-metrics--missed-call-rate", {
                                country: country.name,
                            })}
                        </>
                    ) : (
                        t("live-metrics--missed-call-rate--error")
                    )}
                </Tooltip>
            }
        >
            <div
                className={`${
                    status === "info" ? "bg-gray-600" : "bg-red-600"
                } flex items-center justify-between py-1 px-3 rounded text-white text-xl my-0 mx-2 h-8 max-h-8`}
            >
                <FontAwesomeIcon icon={faPhoneSlash} color="white" />
                <p className="ml-2 text-xl text-bold">
                    {rate >= 0 ? _rate : "⛔"}
                </p>
            </div>
        </OverlayTrigger>
    );
};

export const CallDuration = () => {
    const { t } = useTranslation();
    const [duration, setDuration] = useState(0);
    const [running, setRunning] = useState(false);
    const componentWillUnmount = useRef(false);
    const interval = useRef<string | number | NodeJS.Timeout | undefined>();
    const lastStartTime = useRef<Date>();

    const { currentCall } = useCurrentCall();

    useEffect(() => {
        return () => {
            componentWillUnmount.current = true;
        };
    }, []);

    useEffect(() => {
        if (currentCall.callType != "none") {
            if (currentCall.endTime && running) {
                setRunning(false);
                setDuration(0);
                if (interval.current) clearInterval(interval.current);
            } else if (!currentCall.endTime) {
                if (!running) {
                    if (currentCall?.startTime) {
                        const start = currentCall.startTime;
                        lastStartTime.current = start;
                        const now = new Date();
                        setDuration(now.getTime() - start.getTime());
                        setRunning(true);
                        interval.current = setInterval(() => {
                            if (lastStartTime.current) {
                                const newNow = new Date();
                                setDuration(
                                    newNow.getTime() -
                                        lastStartTime.current.getTime()
                                );
                            } else {
                                setDuration((prevTime) => prevTime + 1000);
                            }
                        }, 1000);
                    }
                } else {
                    if (
                        currentCall?.startTime &&
                        lastStartTime.current !== currentCall.startTime
                    ) {
                        const start = currentCall.startTime;
                        lastStartTime.current = start;
                        const now = new Date();
                        setDuration(now.getTime() - start.getTime());
                    }
                }
            }
        }
        return () => {
            if (componentWillUnmount.current && interval.current) {
                clearInterval(interval.current);
            }
        };
    }, [currentCall, running]);

    function formatted(duration: number) {
        const minutes = Math.trunc(duration / (60 * 1000));
        const seconds = Math.trunc((duration / 1000) % 60);
        const minutesStr = ("00" + minutes).slice(-2);
        const secondsStr = ("00" + seconds).slice(-2);
        return `${minutesStr}:${secondsStr}`;
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id="missed-call-rate-tooltip">
                    {t("live-metrics--call-duration-description")}
                </Tooltip>
            }
        >
            <div
                className={
                    "inline-block w-16 whitespace-nowrap rounded bg-gray-600 p-2 text-center leading-none text-white"
                }
            >
                {formatted(duration)}
            </div>
        </OverlayTrigger>
    );
};

const liveCallMetricsPollingQuery = graphql`
    query LiveCallMetricsQuery(
        $showMissedCallsCount: Boolean!
        $showGeneralMetrics: Boolean!
        $showTotalCallsInQueue: Boolean!
    ) {
        supportLiveCallMetrics {
            callsInQueue {
                count
            }
            missedCallRate @include(if: $showGeneralMetrics) {
                fiveMinutes
            }
            unansweredCallCount @include(if: $showMissedCallsCount) {
                count
            }
            totalCallsInQueue @include(if: $showTotalCallsInQueue) {
                count
            }
        }
    }
`;
export const LiveCallMetrics = ({
    isRepLoggedIn,
    hourlyCallCount,
    topOfHour,
}: LiveCallMetricsProps) => {
    const environment = useRelayEnvironment();
    const { setLiveMetrics } = useContext(LiveMetricsContext);
    const showMissedCallsCount =
        Flags.getFlag("show_missed_calls_count")?.isActive || false;
    const showTotalCallsInQueue =
        Flags.getFlag("show_total_calls_in_queue")?.isActive || false;
    const showCallDuration =
        Flags.getFlag("show_call_duration")?.isActive || false;
    const showGeneralMetrics = usePermissions(CAN_SEE_GENERAL_LIVE_METRICS);
    const { supportLiveCallMetrics: metrics } =
        useLazyLoadQuery<LiveCallMetricsQuery>(
            liveCallMetricsPollingQuery,
            { showMissedCallsCount, showGeneralMetrics, showTotalCallsInQueue },
            {
                networkCacheConfig: { force: true },
                fetchPolicy: "network-only",
            }
        );
    useEffect(() => {
        setLiveMetrics(metrics);
    }, [metrics, setLiveMetrics]);

    const {
        callsInQueue,
        missedCallRate,
        unansweredCallCount,
        totalCallsInQueue,
    } = metrics;

    useEffect(() => {
        if (isRepLoggedIn) {
            const intervalId = setInterval(() => {
                fetchQuery<LiveCallMetricsQuery>(
                    environment,
                    liveCallMetricsPollingQuery,
                    {
                        showMissedCallsCount,
                        showGeneralMetrics,
                        showTotalCallsInQueue,
                    }
                ).subscribe({});
            }, 30 * 1000);
            return () => clearInterval(intervalId);
        }
    }, [
        isRepLoggedIn,
        environment,
        showGeneralMetrics,
        showMissedCallsCount,
        showTotalCallsInQueue,
    ]);

    return (
        <div className="flex items-center justify-end">
            {showCallDuration && <CallDuration />}
            <CallsInQueue
                count={callsInQueue.count}
                totalCount={totalCallsInQueue?.count}
            />
            {missedCallRate && (
                <MissedCallRate rate={missedCallRate.fiveMinutes} />
            )}
            {unansweredCallCount && (
                <CallsCount
                    count={unansweredCallCount.count}
                    topOfHour={topOfHour}
                    icon={faPhoneSlash}
                    infoKey={"live-metrics--unanswered-calls"}
                    errorKey={"live-metrics--unanswered-calls--error"}
                    warning={MissedCallThresholds.warning}
                    bad={MissedCallThresholds.bad}
                />
            )}
            <CallsCount
                count={hourlyCallCount}
                topOfHour={topOfHour}
                infoKey={"live-metrics--answered-calls"}
                errorKey={"live-metrics--answered-calls--error"}
            />
        </div>
    );
};
