import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer, graphql, useRelayEnvironment } from "react-relay";

import { WrongBackendError } from "@/external/relay";

import { usePermissions } from "@/hooks/usePermissions";

import { ProfileBoundary } from "@/pages/mobile.[mobile]/ProfileBoundary";
import {
    ProfileView,
    ProfileViewPayload,
} from "@/pages/mobile.[mobile]/ProfileView";

import { CAN_READ_BALANCE } from "@/types/Permissions";
import { ReadyState } from "@/types/RelayReadyState";

import { TabName } from "@/utils/navigation";
import { notEmpty } from "@/utils/value";

import {
    ProfileByIdQuery,
    ProfileByIdQueryResponse,
} from "./__generated__/ProfileByIdQuery.graphql";

const nodeHeaderQuery = graphql`
    query ProfileByIdQuery($id: ID!, $canReadBalance: Boolean!) {
        node(id: $id) {
            __typename
            ... on Agent {
                principal {
                    primaryWallet {
                        ...UserInfoHeader_walletData
                            @arguments(canReadBalance: $canReadBalance)
                        mobile
                        name
                        balance @include(if: $canReadBalance)
                        ownerName
                        supportNotes {
                            list {
                                noteId
                                whenCreated
                            }
                        }
                        user {
                            id
                            mobile
                            agentUser {
                                name
                            }
                            merchant {
                                id
                                name
                            }
                        }
                    }
                }
            }
            ... on Merchant {
                # Need to alias, as the merchant principal is nullable, but agent isn't
                # We should probably unify the types, but we're planing to rework the
                # principal relation for merchants at some point, so not doing it now.
                maybePrincipal: principal {
                    primaryWallet {
                        ...UserInfoHeader_walletData
                            @arguments(canReadBalance: $canReadBalance)
                        mobile
                        name
                        balance @include(if: $canReadBalance)
                        ownerName
                        supportNotes {
                            list {
                                noteId
                                whenCreated
                            }
                        }
                        user {
                            id
                            mobile
                            agentUser {
                                name
                            }
                            merchant {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

type ProfileByIdProps = {
    id: string;
    tab: TabName;
    country?: string;
};

/**
 * Renders the profile for the given ID.
 * It uses {@link ProfileBoundary} to manage invalid use cases.
 * @param props {@link ProfileByIdProps}
 */
export const ProfileById = ({ id, country }: ProfileByIdProps) => {
    const { t } = useTranslation();
    const environment = useRelayEnvironment();
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const render = useCallback(
        ({ props, error, retry }: ReadyState<ProfileByIdQueryResponse>) => {
            if (error instanceof WrongBackendError) throw error;

            let payload: ProfileViewPayload | null = null;
            let mobile = "";
            let wallet;

            switch (props?.node?.__typename) {
                case "Agent":
                    wallet = props.node.principal.primaryWallet;
                    break;
                case "Merchant":
                    wallet = props.node.maybePrincipal?.primaryWallet;
                    break;
                default:
                    return <></>;
            }

            payload = wallet
                ? {
                      walletData: wallet,
                      supportNotes: {
                          list:
                              wallet.supportNotes?.list?.filter(notEmpty) || [],
                      },
                      isAgent: Boolean(wallet?.user?.agentUser),
                      isMerchant: Boolean(wallet?.user?.merchant),
                  }
                : payload;
            mobile = wallet ? wallet.mobile : mobile;

            return (
                <ProfileBoundary mobile={mobile} country={country}>
                    <ProfileView
                        mobile={mobile}
                        searchOpaqueId={id}
                        payload={payload}
                        error={error}
                        retry={retry}
                    />
                </ProfileBoundary>
            );
        },
        [country, id]
    );

    if (id === "unknown" || id === "anonymous") {
        return (
            <div style={{ textAlign: "center", color: "#888" }}>
                <h3>{t("profile-by-id--invalid-id--title")}</h3>
                <h5>{t("profile-by-id--invalid-id--description", { id })}</h5>
            </div>
        );
    }

    return (
        <QueryRenderer<ProfileByIdQuery>
            environment={environment}
            render={render}
            fetchPolicy="store-and-network"
            query={nodeHeaderQuery}
            variables={{ id, canReadBalance }}
        />
    );
};
