import React from "react";
import { useTranslation } from "react-i18next";

export const RefundAbuserBadge = () => {
    const { t } = useTranslation();

    const title = t("refund-abuser-badge--title");

    return (
        <span className="text-uppercase badge badge-danger" title={title}>
            {title}
        </span>
    );
};
