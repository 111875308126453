import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import english from "./en.json";
import french from "./fr.json";

const fallbackLng = "en";
let lng = window.localStorage.getItem("lng");
if (!lng) {
    lng = fallbackLng;
    window.localStorage.setItem("lng", lng);
}

const options = {
    resources: {
        en: { translation: english },
        fr: { translation: french },
    },
    lng,
    fallbackLng,
};

i18n.use(initReactI18next).init(options);

export const languages = Object.keys(options.resources);
export default i18n;
