import dayjs from "dayjs";

import i18n from "../locales/i18n";

type FormatOptionsType = {
    timeZone: string;
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
    day?: "numeric" | "2-digit";
    year?: "numeric" | "2-digit";
};
export const formatTimestamp = (date: Date) => {
    // TODO(ben): en-US locale has a bad year format. What I really want to do
    // is override the locale format settings in certain cases...
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZone =
        userTimeZone.indexOf("Africa") == -1 ? "UTC" : userTimeZone;
    const beginningOfDay = new Date();
    beginningOfDay.setHours(0);
    beginningOfDay.setMinutes(0);
    beginningOfDay.setSeconds(0);
    beginningOfDay.setMilliseconds(0);
    const beginningOfYear = new Date(beginningOfDay);
    beginningOfYear.setMonth(0, 1);
    const beginningOfTomorrow = new Date(beginningOfDay);
    beginningOfTomorrow.setDate(beginningOfDay.getDate() + 1);
    const formatOptions: FormatOptionsType = {
        timeZone,
        hour: "2-digit",
        minute: "2-digit",
    };

    const locale = i18n.language;

    // check if date isn't today (i.e. is before today or in the future)
    if (date < beginningOfDay || date > beginningOfTomorrow) {
        formatOptions.month = "short";
        formatOptions.day = "numeric";
    }
    if (date < beginningOfYear) {
        formatOptions.year = "numeric";
    }
    if (formatOptions.day === undefined) {
        return `${i18n.t("today")}, ${date.toLocaleString(
            locale,
            formatOptions
        )}`;
    }

    return date.toLocaleString(locale, formatOptions);
};

export const format_time_interval = (
    first_time: number,
    second_time: number
): string => {
    // first_time and second_time are unit timestamps
    // returns a human readable description of the amount of time between
    // first_time and second_time
    const diff_in_min = Math.floor((second_time - first_time) / 60000);
    const age_days = Math.floor(diff_in_min / (60 * 24));
    const leftover_min = diff_in_min % (60 * 24);

    const age_hours = Math.floor(leftover_min / 60);
    const age_min = leftover_min % 60;

    return `${age_days}d, ${age_hours}h, ${age_min}m`;
};

export const sleep = (ms: number) =>
    new Promise((awaken) => setTimeout(awaken, ms));

export const formatDate = (dateValue: Date, format?: string) =>
    format ? dayjs(dateValue).format(format) : formatTimestamp(dateValue);
