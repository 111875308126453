import { M } from "../utils/currency";
import { Formula } from "../utils/formula";

export const RESULT_SEPARATOR = " • ";

export interface UnregisteredUser {
    kind: "UnregisteredUser";
    mobile: string;
}

export interface AgentUser {
    kind: "Agent";
    mobile?: string;
    agent: AgentInfo;
    wallet?: WalletInfo | null;
}

export interface WalletUser {
    kind: "Wallet";
    mobile: string;
    wallet: WalletInfo;
    transferId?: string;
    agent?: AgentInfo;
}

export interface MerchantInfo {
    kind: "Merchant";
    id: string;
    ufid: string;
    name: string;
    subcity: string;
    city: string;
    mobile: string;
    isActive: boolean;
}

export interface WalletInfo {
    id: string;
    name: string | null;
    balance?: M;
    sendFeeFormula?: Formula;
    whenTerminated: string;
}

export interface AgentInfo {
    id: string;
    name: string;
    subcity: string;
    city: string;
    walletId: string;
    ufid: string;
    isActive: boolean;
    balance?: M;
}

export type Profile = UnregisteredUser | AgentUser | WalletUser | MerchantInfo;
export type SearchResult = Profile & { opaque_id?: string };

export type UseProfileSearcherResult = {
    allowAgents: boolean;
    allowMerchants: boolean;
    allowWallets: boolean;
    allowUnregistered: boolean;
    allowTerminated: boolean;
    onFocus?: (event: any) => void;
    onChange: (profile: SearchResult | undefined, entry?: string) => void;
    clearAfterSelection?: boolean;
    initialQuery?: string;
};
export type ProfileSearcherProps = {
    disabled?: boolean;
    autoFocus?: boolean;
} & UseProfileSearcherResult;
