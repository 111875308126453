import { useCallback, useMemo } from "react";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { Timestamp } from "../../components/Timestamp";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { BlockUnblockDeviceMutationResponse } from "./__generated__/BlockUnblockDeviceMutation.graphql";
import { BlockUnblockDevice_unregisteredMobile } from "./__generated__/BlockUnblockDevice_unregisteredMobile.graphql";
import { BlockUnblockDevice_user } from "./__generated__/BlockUnblockDevice_user.graphql";

const mutation = graphql`
    mutation BlockUnblockDeviceMutation(
        $deviceId: String!
        $unblock: Boolean!
        $mobile: String!
        $reason: String!
    ) {
        #blockDevice(  # NOT YET IMPLEMENTED
        #    mobile: $mobile
        #    reason: $reason
        #    deviceId: $deviceId
        #) @skip(if: $unblock) {
        #    success
        #    message
        #}

        unblockDevice(deviceId: $deviceId, mobile: $mobile, reason: $reason)
            @include(if: $unblock) {
            user {
                id
                ...BlockUnblockDevice_user
            }
            unregisteredMobile {
                mobile
                ...BlockUnblockUser_unregisteredMobile
                ...UnregisteredMobileInfoHeader_unregisteredMobile
                ...NoUserPanel_unregisteredMobile
            }
            success
        }
    }
`;

type Device = NonNullable<
    NonNullable<BlockUnblockDevice_user["devices"]>[number]
>;
type DeviceBlock = Device["blockHistory"][number];

type BlockUnblockDeviceInputParams = {
    user?: BlockUnblockDevice_user;
    unregisteredMobile?: BlockUnblockDevice_unregisteredMobile;
    mobile: string;
    deviceId: string;
    unblock: boolean;
};

const _BlockUnblockDeviceMutator = (
    props: BlockUnblockDeviceInputParams & RelayModalProps
) => {
    const { onHide, unblock, user, deviceId, mobile, unregisteredMobile } =
        props;

    const { t } = useTranslation();

    const reasonField = useTextField();

    const device = (user || unregisteredMobile)?.devices?.find(
        (d) => d?.deviceId === deviceId
    );

    const blockedOrUnblocked = unblock ? t("unblocked") : t("blocked");
    const blockUnblock = unblock ? t("unblock") : t("block");

    const modalHelpText = unblock ? (
        <>
            <p>
                <b>{t("block-device--description-unblock")}</b>
                <br />
            </p>
        </>
    ) : (
        <>
            <p>
                <b>{t("block-device--description-block")}</b>
                <br />
            </p>
        </>
    );

    const onMutationSuccess = useCallback(
        (response: BlockUnblockDeviceMutationResponse) => {
            const success =
                (unblock ? response.unblockDevice?.success : "") || false; // TODO implement response.blockDevice

            if (success) {
                return t("block-device--success", {
                    state: blockedOrUnblocked,
                });
            }

            return t("block-device--error");
        },
        [t, unblock, blockedOrUnblocked]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: unblock ? Action.UnblockDevice : Action.BlockDevice,
            data: { deviceId },
        },
        ...props,
    });
    const reasonValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!reasonField.value) {
            validations.push({
                type: "error",
                message: t("block-device--missing-reason", {
                    state: blockedOrUnblocked,
                }),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, reasonField.value, blockedOrUnblocked]);
    const isValid = useMemo(() => {
        return !hasError([reasonValidations]);
    }, [reasonValidations]);

    if (!device) {
        // make typescript happy
        return null;
    }

    const handleSubmit = () => {
        mutator.submit(mutation, {
            unblock: unblock,
            mobile,
            reason: reasonField.value.trim(),
            deviceId: device.deviceId,
        });
    };

    return (
        <MutatorModal
            {...mutator}
            title={`${blockUnblock} ${device.deviceName}`}
            size={"xl"}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={unblock ? t("action-unblock") : t("action-block")}
                onSubmit={handleSubmit}
                onDone={onHide}
                hideFieldsAfterSubmit={true}
            >
                {modalHelpText}
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
            <h3>{t("block-device-history--title")}</h3>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>{t("block-device-history--when-blocked")}</th>
                        <th>{t("block-device-history--why-blocked")}</th>
                        <th>{t("block-device-history--why-unblocked")}</th>
                        <th>{t("block-device-history--unblocked-by")}</th>
                        <th>{t("block-device-history--when-unblocked")}</th>
                    </tr>
                </thead>
                <tbody>
                    {(device.blockHistory || []).map(
                        (block: DeviceBlock, i: number) => (
                            <tr key={i}>
                                <td>
                                    <Timestamp
                                        value={new Date(block.whenBlocked)}
                                        format={"MMM, DD YYYY HH:mm"}
                                    />
                                </td>
                                <td>{block.blockReason}</td>
                                <td>
                                    {block.whenUnblocked
                                        ? block.unblockReason
                                        : "–"}
                                </td>
                                <td>
                                    {block.whenUnblocked
                                        ? block.unblockedBy
                                        : "–"}
                                </td>
                                <td>
                                    {block.whenUnblocked ? (
                                        <Timestamp
                                            value={
                                                new Date(block.whenUnblocked)
                                            }
                                            format={"MMM, DD YYYY HH:mm"}
                                        />
                                    ) : (
                                        "–"
                                    )}
                                </td>
                            </tr>
                        )
                    )}
                </tbody>
            </Table>
        </MutatorModal>
    );
};

export const BlockUnblockDeviceMutator = createFragmentContainer(
    _BlockUnblockDeviceMutator,
    {
        user: graphql`
            fragment BlockUnblockDevice_user on User {
                id
                mobile
                devices {
                    deviceId
                    deviceName
                    deviceModel
                    blockHistory {
                        whenBlocked
                        blockedBy
                        blockReason
                        whenUnblocked
                        unblockedBy
                        unblockReason
                        blockSubject
                    }
                    activeBlock {
                        id
                        blockReason
                        blockedBy
                        whenBlocked
                        whenExpires
                        requestToModifyBlockTickets {
                            status
                            whenOpened
                        }
                        ...RequestToModifyBlock_activeBlock
                    }
                }
            }
        `,
        unregisteredMobile: graphql`
            fragment BlockUnblockDevice_unregisteredMobile on UnregisteredMobile {
                mobile
                devices {
                    deviceId
                    deviceName
                    deviceModel
                    blockHistory {
                        whenBlocked
                        blockedBy
                        blockReason
                        whenUnblocked
                        unblockedBy
                        unblockReason
                        blockSubject
                    }
                    activeBlock {
                        id
                        blockReason
                        blockedBy
                        whenBlocked
                        whenExpires
                        requestToModifyBlockTickets {
                            status
                            whenOpened
                        }
                        ...RequestToModifyBlock_activeBlock
                    }
                }
            }
        `,
    }
);
