/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserLinkedAccountTransferW2BEntry_tx = {
    readonly summary: string | null;
    readonly isCancelled: boolean;
    readonly partnerName: string;
    readonly liaTransferId: string;
    readonly errorCode: string | null;
    readonly errorMessage: string | null;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "UserLinkedAccountTransferW2BEntry_tx";
};
export type UserLinkedAccountTransferW2BEntry_tx$data = UserLinkedAccountTransferW2BEntry_tx;
export type UserLinkedAccountTransferW2BEntry_tx$key = {
    readonly " $data"?: UserLinkedAccountTransferW2BEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserLinkedAccountTransferW2BEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserLinkedAccountTransferW2BEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "liaTransferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "UserLinkedAccountTransferW2BEntry",
  "abstractKey": null
};
(node as any).hash = '4d63e50f5561ba70ef1a5c98dca4a85a';
export default node;
