/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PersonalHistoryEntry_entry = {
    readonly __typename: string;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "UserAtxEntry_tx" | "BillPaymentEntry_tx" | "UserLinkedAccountTransferB2WEntry_tx" | "UserLinkedAccountTransferW2BEntry_tx" | "PaymentCardTransferEntry_tx" | "TransferReceivedEntry_tx" | "TransferSentEntry_tx" | "TransferSentReversalEntry_tx" | "TransferReceivedReversalEntry_tx" | "PayoutTransferEntry_tx" | "PurchaseEntry_tx" | "RemittanceTransferReceivedEntry_tx" | "RemittanceTransferReversalEntry_tx" | "AdjustmentEntry_tx" | "LoginLogoutEvent_tx" | "FailedMerchantTransferEntry_tx" | "ReversalDisputeEntry_tx">;
    readonly " $refType": "PersonalHistoryEntry_entry";
};
export type PersonalHistoryEntry_entry$data = PersonalHistoryEntry_entry;
export type PersonalHistoryEntry_entry$key = {
    readonly " $data"?: PersonalHistoryEntry_entry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PersonalHistoryEntry_entry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalHistoryEntry_entry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserAtxEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillPaymentEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserLinkedAccountTransferB2WEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserLinkedAccountTransferW2BEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaymentCardTransferEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransferReceivedEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransferSentEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransferSentReversalEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransferReceivedReversalEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PayoutTransferEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PurchaseEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemittanceTransferReceivedEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemittanceTransferReversalEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdjustmentEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoginLogoutEvent_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FailedMerchantTransferEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReversalDisputeEntry_tx"
    }
  ],
  "type": "SupportHistoryEntry",
  "abstractKey": "__isSupportHistoryEntry"
};
(node as any).hash = 'e8ba485c3a6c34e04fd63ae55724a4f5';
export default node;
