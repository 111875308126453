import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useCountry } from "@/hooks/useCountry";

import { Country } from "@/types/countries";

export const ProfileNotFound = ({
    countryFromMobile,
    selectedCountry,
}: {
    countryFromMobile: Country;
    selectedCountry: Country;
}): React.ReactElement => {
    const { t } = useTranslation();
    const { country, countries, updateCountry } = useCountry();

    useEffect(() => {
        if (
            countryFromMobile.iso2 !== country &&
            countries.includes(countryFromMobile.iso2)
        ) {
            updateCountry(countryFromMobile.iso2);
        }
    }, [country, countries, updateCountry, countryFromMobile.iso2]);

    return (
        <div data-testid="profile-not-found">
            <title>{t("profile-not-found--title")}</title>
            <div className="text-secondary mt-4 text-center">
                <h3>{t("profile-not-found--subtitle")}</h3>
                <h5>
                    {t("profile-not-found--description", {
                        from: countryFromMobile.name,
                        selected: selectedCountry.name,
                    })}
                </h5>
            </div>
        </div>
    );
};
