import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Timestamp } from "@/components/Timestamp";

import { LoginLogoutEvent_tx } from "./__generated__/LoginLogoutEvent_tx.graphql";

enum LoginLogoutEventEnum {
    LOGIN,
    LOGOUT,
}

const COLORS = {
    [LoginLogoutEventEnum.LOGIN]: {
        text: "#307DF6",
        bg: "#F0FAFE",
    },
    [LoginLogoutEventEnum.LOGOUT]: {
        text: "#CB4749",
        bg: "#FDE4E2",
    },
};

function _LoginLogoutEvent(props: {
    tx: LoginLogoutEvent_tx;
    activeDeviceBlocks: Array<{ deviceId: string; blockReason: string }>;
}) {
    const { t } = useTranslation();
    const { tx, activeDeviceBlocks } = props;
    const event =
        tx.event === "LOGIN"
            ? LoginLogoutEventEnum.LOGIN
            : LoginLogoutEventEnum.LOGOUT;
    const eventName = t(
        tx.event === "LOGIN"
            ? "login-logout-event--login"
            : "login-logout-event--logout"
    ).toUpperCase();

    const color = COLORS[event];
    const activeBlock = activeDeviceBlocks.find(
        ({ deviceId }) => deviceId === tx.scamInvestigationFields?.deviceId
    );
    return (
        <div
            className={`py-1 border-b border-gray-200`}
            style={{ backgroundColor: color.bg }}
            id={`tx_div${tx.id}`}
        >
            <div
                className={`text-center text-sm`}
                style={{ color: color.text }}
            >
                <FontAwesomeIcon icon={faMobileAlt} />{" "}
                <span className="font-bold max-w-md inline-block text-ellipsis overflow-hidden align-middle">
                    {eventName}&nbsp;-&nbsp;
                    {tx.scamInvestigationFields?.deviceModel}
                </span>{" "}
                | <Timestamp value={new Date(tx.whenEntered)} />
                {event === LoginLogoutEventEnum.LOGOUT &&
                    tx.correspondingLoginTime && (
                        <>
                            {" "}
                            ({t("login-logout-event--logged-in-on")}{" "}
                            <Timestamp
                                value={new Date(tx.correspondingLoginTime)}
                            />
                            )
                        </>
                    )}{" "}
                |{" "}
                <span
                    title={`Device Name: ${tx.scamInvestigationFields?.deviceName}`}
                >
                    <span className="font-bold">DeviceID:</span>{" "}
                    {tx.scamInvestigationFields?.deviceId}
                    {activeBlock && (
                        <Badge
                            variant="warning"
                            title={`Reason: ${activeBlock.blockReason}`}
                            className="ml-1 uppercase"
                        >
                            {t("blocked")}
                        </Badge>
                    )}
                </span>
            </div>
        </div>
    );
}

export default createFragmentContainer(_LoginLogoutEvent, {
    tx: graphql`
        fragment LoginLogoutEvent_tx on LoginLogoutEvent {
            id
            event
            whenEntered
            correspondingLoginTime
            scamInvestigationFields {
                deviceId
                deviceModel
                deviceName
            }
        }
    `,
});
