import { useTranslation } from "react-i18next";

import {
    ActionButton,
    VOIPVerificationLevel,
} from "../../components/ActionButton";

import { useFlag } from "@/hooks/useFlag";

import { ResetPinMutator } from "@/pages/mobile.[mobile].personal/ResetPin";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const ResetPinAction = (props: { wallet: PersonalTabHeader_wallet }) => {
    const { t } = useTranslation();
    const allowVOIPVerification = useFlag(
        "tmp-allow-voip-verification"
    )?.isActive;
    const showRiskyPinResetWarning =
        useFlag("show-risky-pin-reset-warning")?.isActive ?? false;

    const { isPinResetRisky } = props.wallet.user ?? {};
    const prefix = showRiskyPinResetWarning && isPinResetRisky ? "⚠ " : "";

    return (
        <ActionButton
            as="menuitem"
            name={prefix + t("reset-pin--action")}
            requiredPermissions={["supportapp_reset_pin"]}
            VOIPVerification={
                allowVOIPVerification
                    ? VOIPVerificationLevel.ALLOWED
                    : VOIPVerificationLevel.FORBIDDEN
            }
        >
            <ResetPinMutator wallet={props.wallet} />
        </ActionButton>
    );
};
