import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { FreezeTransactionHistoryActionButton } from "@/pages/mobile.[mobile].personal/FreezeTransactionHistoryActionButton";
import { ReverseFrozenTransactionHistoryActionButton } from "@/pages/mobile.[mobile].personal/ReverseFrozenTransactionHistoryActionButton";
import { UnfreezeTransactionHistoryActionButton } from "@/pages/mobile.[mobile].personal/UnfreezeTransactionHistoryActionButton";

import { TransferSentReversalEntry_tx } from "./__generated__/TransferSentReversalEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ReportRefundingDisputeMutator } from "./ReportRefundingDispute";

function _TransferSentReversalEntry(
    props: {
        tx: TransferSentReversalEntry_tx;
        typename: string;
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx, typename } = props;

    const hasReportRefundingDisputePermission = usePermissions(
        "supportapp_report_refunding_dispute"
    );

    const historyActionButton = tx.isPending ? (
        <>
            <UnfreezeTransactionHistoryActionButton
                transferOrLedgerTransactionId={tx.transferId}
            />

            <ReverseFrozenTransactionHistoryActionButton
                transferOrLedgerTransactionId={tx.transferId}
            />
        </>
    ) : (
        <>
            <HistoryActionButton
                name={t("report-refunding-dispute--action")}
                disabled={!hasReportRefundingDisputePermission}
            >
                <ReportRefundingDisputeMutator transferId={tx.transferId} />
            </HistoryActionButton>
            <FreezeTransactionHistoryActionButton
                transferOrLedgerTransactionId={tx.ledgerTransactionId}
                // This is a reversal, but the WalletPresenter takes sender and receiver from the original transfer
                // Therefore, these fields are switched
                recipientMobile={tx.senderMobile}
                receiveAmount={tx.sendAmount}
            />
        </>
    );

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <Fragment>
                    <TxTitle>
                        {t("reversal-entry--transaction-id")}&nbsp;
                        <a
                            href={`?entry=${tx.transferId}`}
                            className="text-blue-600"
                        >
                            {tx.transferId}
                        </a>
                        &nbsp;{tx.description}
                    </TxTitle>
                    <TxInfo>{typename}</TxInfo>
                </Fragment>
            }
            buttons={historyActionButton}
        />
    );
}

export default createFragmentContainer(_TransferSentReversalEntry, {
    tx: graphql`
        fragment TransferSentReversalEntry_tx on TransferSentReversalEntry {
            transferId
            description
            isPending
            isFreezingFunds
            ledgerTransactionId
            senderMobile
            sendAmount
            ...HistoryEntry_entry
        }
    `,
});
