/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentTransactionType = "BUSINESS" | "REBALANCE" | "USER" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type MerchantAtxEntry_tx = {
    readonly agentTransactionId: string;
    readonly isDeposit: boolean;
    readonly amount: string;
    readonly cashierName: string;
    readonly cashierMobile: string | null;
    readonly agentId: string;
    readonly agentUfid: string;
    readonly agentName: string;
    readonly subagentId: string | null;
    readonly subagentName: string | null;
    readonly subagentUfid: string | null;
    readonly type: AgentTransactionType;
    readonly isCancelled: boolean;
    readonly scamReports: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly transactionIds: string | null;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "MerchantAtxEntry_tx";
};
export type MerchantAtxEntry_tx$data = MerchantAtxEntry_tx;
export type MerchantAtxEntry_tx$key = {
    readonly " $data"?: MerchantAtxEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MerchantAtxEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MerchantAtxEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentTransactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeposit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": "cashierName",
      "args": null,
      "kind": "ScalarField",
      "name": "counterpartyNameOnly",
      "storageKey": null
    },
    {
      "alias": "cashierMobile",
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedCustomerMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentUfid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subagentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subagentName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subagentUfid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamReportTicket",
      "kind": "LinkedField",
      "name": "scamReports",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenOpened",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenResolved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "AgentTransactionEntry",
  "abstractKey": null
};
(node as any).hash = '4764b8ed38d9c49cfb2a380b71835535';
export default node;
