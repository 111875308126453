import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { ProfileLink } from "@/components/ProfileLink";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { RefundP2PMutator } from "@/pages/mobile.[mobile].personal/RefundP2P";
import HistoryEntry, {
    HistoryEntryProps,
} from "@/pages/mobile.[mobile]/HistoryEntry";
import { ReportCustomerScamMutator } from "@/pages/mobile.[mobile]/ReportCustomerScam";

import { CAN_REFUND_BULK_TRANSFER } from "@/types/Permissions";

import { M } from "@/utils/currency";

import { MerchantTransferSentEntry_tx } from "./__generated__/MerchantTransferSentEntry_tx.graphql";

function _TransferSentEntry(
    props: {
        tx: MerchantTransferSentEntry_tx;
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const entry = props.tx;
    const profileLink =
        entry.maybeTerminatedRecipientMobile && entry.recipientName ? (
            <ProfileLink
                name={entry.recipientName}
                mobile={entry.maybeTerminatedRecipientMobile}
                opaqueId={entry.tx_id}
            />
        ) : (
            <></>
        );
    const info = entry.tx_id;

    const hasSupportAppRefundPermission = usePermissions(
        CAN_REFUND_BULK_TRANSFER
    );
    const canReportScam = usePermissions("supportapp_report_customer_scam");
    const canReportScamWithDetails = usePermissions(
        "supportapp_report_customer_scam_with_details"
    );

    const buttons = (
        <Fragment>
            <HistoryActionButton
                name={t("report-customer-scam--action")}
                disabled={!canReportScam}
            >
                <ReportCustomerScamMutator
                    transactionId={entry.tx_id}
                    mobile={entry.mobile}
                    amountStolen={M.fromSerialized(entry.amount).negate()}
                    scamReports={entry.scamReports}
                    hideAdditionalInfoFields={!canReportScamWithDetails}
                />
            </HistoryActionButton>
            <HistoryActionButton
                name={t("refund--action")}
                disabled={!hasSupportAppRefundPermission}
            >
                <RefundP2PMutator transfer={props.tx} />
            </HistoryActionButton>
        </Fragment>
    );
    return (
        <HistoryEntry
            {...props}
            entry={entry}
            title={
                <>
                    <TxTitle>
                        {t("merchant-transfer-sent-entry--title")} {profileLink}
                    </TxTitle>
                    <TxInfo>{info}</TxInfo>
                </>
            }
            buttons={buttons}
        />
    );
}

export default createFragmentContainer(_TransferSentEntry, {
    tx: graphql`
        fragment MerchantTransferSentEntry_tx on TransferSentEntry {
            tx_id: transferId
            sender: senderName
            mobile: senderMobile
            recipientName
            recipientMobile
            maybeTerminatedRecipientMobile
            amount
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            ...HistoryEntry_entry
            ...RefundP2P_transfer
        }
    `,
});
