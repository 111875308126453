/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MerchantHistoryEntry_entry = {
    readonly __typename: string;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "MerchantSaleEntry_tx" | "MerchantTransferSentEntry_tx" | "MerchantAtxEntry_tx" | "PayoutTransferEntry_tx" | "PayoutTransferSentEntry_tx">;
    readonly " $refType": "MerchantHistoryEntry_entry";
};
export type MerchantHistoryEntry_entry$data = MerchantHistoryEntry_entry;
export type MerchantHistoryEntry_entry$key = {
    readonly " $data"?: MerchantHistoryEntry_entry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MerchantHistoryEntry_entry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MerchantHistoryEntry_entry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MerchantSaleEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MerchantTransferSentEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MerchantAtxEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PayoutTransferEntry_tx"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PayoutTransferSentEntry_tx"
    }
  ],
  "type": "HistoryEntry",
  "abstractKey": "__isHistoryEntry"
};
(node as any).hash = 'e84a647cb09b9fc7f3de2d5ee8814d6f';
export default node;
