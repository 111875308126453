/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BlockRecipient_transfer = {
    readonly id: string;
    readonly transferId: string;
    readonly recipientName: string;
    readonly recipientMobile: string;
    readonly " $refType": "BlockRecipient_transfer";
};
export type BlockRecipient_transfer$data = BlockRecipient_transfer;
export type BlockRecipient_transfer$key = {
    readonly " $data"?: BlockRecipient_transfer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BlockRecipient_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlockRecipient_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    }
  ],
  "type": "MobileTransferEntry",
  "abstractKey": "__isMobileTransferEntry"
};
(node as any).hash = 'e57fa3ecf3fab431c6ec9ef21dc58a4c';
export default node;
