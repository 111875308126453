/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentHistoryEntryDelegate_entry = {
    readonly __typename: string;
    readonly " $fragmentRefs": FragmentRefs<"AgentHistoryEntry_entry" | "AgentHistoryAtxEntry_tx">;
    readonly " $refType": "AgentHistoryEntryDelegate_entry";
};
export type AgentHistoryEntryDelegate_entry$data = AgentHistoryEntryDelegate_entry;
export type AgentHistoryEntryDelegate_entry$key = {
    readonly " $data"?: AgentHistoryEntryDelegate_entry$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentHistoryEntryDelegate_entry">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentHistoryEntryDelegate_entry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgentHistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgentHistoryAtxEntry_tx"
    }
  ],
  "type": "HistoryEntry",
  "abstractKey": "__isHistoryEntry"
};
(node as any).hash = '08c0458651a359f5c948eaddfd428930';
export default node;
