import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import { graphql } from "relay-runtime";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";

import { hasError } from "@/utils/formValidation";

const mutation = graphql`
    mutation EditSupportNoteMutation($noteId: ID!, $text: String!) {
        editSupportNote(noteId: $noteId, text: $text) {
            note {
                noteId
                text
            }
            supportNotes {
                ...NotesList_supportNotes
            }
        }
    }
`;

type EditSupportNoteProps = {
    mobile: string;
    noteId: string;
    noteText: string;
};

const _EditSupportNote = (props: EditSupportNoteProps & RelayModalProps) => {
    const { mobile, noteId, noteText } = props;
    const { onHide } = props;

    const { t } = useTranslation();

    const mutator = useMutator({
        onMutationSuccess: () => t("edit-support-note--success"),
        trackActionInfo: { name: "edit note", data: { mobile, noteId } },
        ...props,
    });

    const contentField = useTextField(noteText);

    const handleSubmit = () => {
        const text = contentField.value;
        mutator.submit(mutation, { noteId, text });
    };

    const contentValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (contentField.value.length < 3) {
            validations.push({
                type: "error",
                display: "if-blurred",
                message: t("form-validation--empty-content"),
            });
        }

        return validations;
    }, [t, contentField.value]);

    const isValid = useMemo(
        () => !hasError([contentValidations]),
        [contentValidations]
    );

    return (
        <MutatorModal {...mutator} title={t("edit-support-note--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-edit")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...contentField}
                    label={t("label-content")}
                    name="content"
                    validations={contentValidations}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};

export const EditSupportNote = createFragmentContainer(_EditSupportNote, {});
