/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NotesList_supportNotes = {
    readonly list: ReadonlyArray<{
        readonly noteId: string;
        readonly text: string | null;
        readonly mobile: string;
        readonly adminUsername: string;
        readonly whenCreated: string;
        readonly whenUpdated: string;
    }>;
    readonly " $refType": "NotesList_supportNotes";
};
export type NotesList_supportNotes$data = NotesList_supportNotes;
export type NotesList_supportNotes$key = {
    readonly " $data"?: NotesList_supportNotes$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NotesList_supportNotes">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotesList_supportNotes",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SupportNote",
      "kind": "LinkedField",
      "name": "list",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noteId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobile",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminUsername",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenCreated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenUpdated",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SupportNotes",
  "abstractKey": null
};
(node as any).hash = '482a73ea41bd2fbb0bbe0821e1e9f844';
export default node;
