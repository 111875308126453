/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AgentIssueType = "AGENT_BOYCOTTING" | "AGENT_NEEDS_TRAINING" | "CHARGING_EXTRA_FEES" | "CHARGING_EXTRA_FEES_FOR_SIGNUP" | "CHARGING_EXTRA_FEES_FOR_TRANSACTIONS" | "CLOSED_DURING_BUSINESS_HOURS" | "CLOSED_PERMANENTLY" | "NEEDS_BANK_REBALANCE" | "NEEDS_BANNER" | "NEEDS_CASH" | "NEEDS_CASH_PICKUP" | "NEEDS_FLYERS" | "NEEDS_QR_CARDS" | "NEEDS_SHARED_AGENT" | "NOT_ENOUGH_CASH_FOR_WITHDRAWAL" | "NOT_ENOUGH_FLOAT_FOR_DEPOSIT" | "OTHER" | "OTHER_AGENT_REQUEST" | "OTHER_USER_COMPLAINT" | "REDIRECTING_TO_COMPETITION" | "REFUSING_TO_DO_SMALL_TRANSACTIONS" | "REQUEST_AGENT_REACTIVATION" | "REQUEST_AGENT_SUSPENSION" | "REQUEST_AGENT_TERMINATION" | "USER_COMPLAINT_LOW_LIQUIDITY" | "%future added value";
export type ReportUserComplaintMutationVariables = {
    agentId: string;
    description?: string | null | undefined;
    issueType: AgentIssueType;
    reportingCustomerMobile?: string | null | undefined;
};
export type ReportUserComplaintMutationResponse = {
    readonly reportAgentIssue: {
        readonly success: boolean;
    } | null;
};
export type ReportUserComplaintMutation = {
    readonly response: ReportUserComplaintMutationResponse;
    readonly variables: ReportUserComplaintMutationVariables;
};



/*
mutation ReportUserComplaintMutation(
  $agentId: ID!
  $description: String
  $issueType: AgentIssueType!
  $reportingCustomerMobile: String
) {
  reportAgentIssue(agentId: $agentId, description: $description, issueType: $issueType, reportingCustomerMobile: $reportingCustomerMobile) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "description"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportingCustomerMobile"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "agentId",
        "variableName": "agentId"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "issueType",
        "variableName": "issueType"
      },
      {
        "kind": "Variable",
        "name": "reportingCustomerMobile",
        "variableName": "reportingCustomerMobile"
      }
    ],
    "concreteType": "ReportAgentIssue",
    "kind": "LinkedField",
    "name": "reportAgentIssue",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportUserComplaintMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportUserComplaintMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "88576be5fe8b3d7b6e56e1c1567d4bb6",
    "id": null,
    "metadata": {},
    "name": "ReportUserComplaintMutation",
    "operationKind": "mutation",
    "text": "mutation ReportUserComplaintMutation(\n  $agentId: ID!\n  $description: String\n  $issueType: AgentIssueType!\n  $reportingCustomerMobile: String\n) {\n  reportAgentIssue(agentId: $agentId, description: $description, issueType: $issueType, reportingCustomerMobile: $reportingCustomerMobile) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ea6f5eac42254b5ce6e830905ae6467e';
export default node;
