/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BlockSubject = "DEFAULT" | "DEVICE" | "LEGAL_ENTITY" | "MOBILE" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type TicketType = "AgentIssueReport" | "AgentScamReport" | "AmlMatch" | "B2WOrW2BCustomerProblem" | "BankRebalanceRequest" | "BillPayProblem" | "BusinessFundingRequest" | "KYBEscalation" | "MerchantComplianceFollowup" | "MerchantSupportEscalation" | "PaymentCardProblem" | "PoliceRequisition" | "RebalancingDifficulty" | "RefundPartialFunds" | "RefundingDispute" | "RequestToBlockWallet" | "RequestToExplainCommissionCuts" | "RequestToModifyBlockTicket" | "ScamReport" | "ScreeningResult" | "SpamCallerReport" | "SupportGroupLeadEscalation" | "%future added value";
export type UnregisteredMobileBlocks_blockData = {
    readonly mobile: string;
    readonly activeBlock: {
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
        readonly whenExpires: string | null;
        readonly requestToModifyBlockTickets: ReadonlyArray<{
            readonly status: TicketStatus;
            readonly whenOpened: string;
        }> | null;
        readonly " $fragmentRefs": FragmentRefs<"RequestToModifyBlock_activeBlock" | "UpdateBlock_activeBlock">;
    } | null;
    readonly blockHistory: ReadonlyArray<{
        readonly whenBlocked: string;
        readonly blockedBy: string;
        readonly blockReason: string;
        readonly whenUnblocked: string | null;
        readonly unblockedBy: string | null;
        readonly unblockReason: string | null;
        readonly blockSubject: BlockSubject | null;
    }>;
    readonly tickets: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly slackMessageUrl: string | null;
        readonly ticketType: TicketType;
        readonly reference: string | null;
    } | null>;
    readonly devices: ReadonlyArray<{
        readonly deviceId: string;
        readonly deviceName: string;
        readonly deviceModel: string;
        readonly activeBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
            readonly requestToModifyBlockTickets: ReadonlyArray<{
                readonly status: TicketStatus;
                readonly whenOpened: string;
            }> | null;
            readonly " $fragmentRefs": FragmentRefs<"RequestToModifyBlock_activeBlock" | "UpdateBlock_activeBlock">;
        } | null;
    }> | null;
    readonly " $fragmentRefs": FragmentRefs<"RequestToBlockUnblockUser_unregisteredMobile" | "BlockUnblockUser_unregisteredMobile">;
    readonly " $refType": "UnregisteredMobileBlocks_blockData";
};
export type UnregisteredMobileBlocks_blockData$data = UnregisteredMobileBlocks_blockData;
export type UnregisteredMobileBlocks_blockData$key = {
    readonly " $data"?: UnregisteredMobileBlocks_blockData$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UnregisteredMobileBlocks_blockData">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockSubject",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenExpires",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenOpened",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketShortInfo",
  "kind": "LinkedField",
  "name": "requestToModifyBlockTickets",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RequestToModifyBlock_activeBlock"
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UpdateBlock_activeBlock"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnregisteredMobileBlocks_blockData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeBlock",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "blockHistory",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenUnblocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockReason",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketShortInfo",
      "kind": "LinkedField",
      "name": "tickets",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenResolved",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackMessageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reference",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserDevice",
      "kind": "LinkedField",
      "name": "devices",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActiveBlock",
          "kind": "LinkedField",
          "name": "activeBlock",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v4/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestToBlockUnblockUser_unregisteredMobile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlockUnblockUser_unregisteredMobile"
    }
  ],
  "type": "UnregisteredMobile",
  "abstractKey": null
};
})();
(node as any).hash = 'e947e684f15cb085c2959ee589acc0cf';
export default node;
