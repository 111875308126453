import { Fragment } from "react";
import * as React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";
import {
    ButtonGroup,
    ButtonToolbar,
    Carousel,
    CarouselItem,
    Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    QueryRenderer,
    RelayRefetchProp,
    createRefetchContainer,
    graphql,
    useRelayEnvironment,
} from "react-relay";
import { useLocation } from "react-router";

import { ActionButton } from "@/components/ActionButton";
import { Amount } from "@/components/Amount";
import { LoadingIndicator } from "@/components/Loading";
import { ProfileLink } from "@/components/ProfileLink";

import { WrongBackendError } from "@/external/relay";
import { logEmptyHistoryConnectionToSentry } from "@/external/sentry";

import { useJumpToDate } from "@/hooks/useJumpToDate";
import { usePermissions } from "@/hooks/usePermissions";

import { BusinessAppStatus } from "@/pages/mobile.[mobile].merchant/BusinessAppStatus";
import { EscalateToMerchantSupportMutator } from "@/pages/mobile.[mobile].merchant/EscalateToMerchantSupport";
import { MerchantUsersList } from "@/pages/mobile.[mobile].merchant/MerchantUsersList";
import { SendMerchantAppLinkMutator } from "@/pages/mobile.[mobile].merchant/SendMerchantAppLink";
import { JumpToDateInput } from "@/pages/mobile.[mobile]/JumpToDate";

import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";
import { ReadyState } from "@/types/RelayReadyState";

import { M } from "@/utils/currency";
import { OPAQUE_ID_QUERY_PARAM_NAME } from "@/utils/navigation";
import { formatTimestamp } from "@/utils/time";

import { MerchantTab_merchant } from "./__generated__/MerchantTab_merchant.graphql";

import { MerchantHistory } from "./MerchantHistory";

interface MerchantTabProps {
    merchant: MerchantTab_merchant;
    user: any;
    relay: RelayRefetchProp;
    canReadHistory: boolean;
    canReadBalance: boolean;
    opaqueId?: string;
    when?: Date;
    setWhen: (date: Date) => void;
}

const KYB: {
    [key: string]: string;
} = {
    KYB0: "KYB-0",
    KYB1: "KYB-1",
    KYB2: "KYB-2",
    KYB3: "KYB-3",
};

function _MerchantTab({
    merchant,
    user,
    relay,
    canReadBalance,
    canReadHistory,
    opaqueId,
    when,
    setWhen,
}: MerchantTabProps) {
    const { t } = useTranslation();

    if (!merchant) {
        return <div>{t("merchant-tab--error-merchant-inactive")}</div>;
    }

    if (merchant.wallet && canReadHistory) {
        logEmptyHistoryConnectionToSentry(merchant.wallet);
    }

    const { wallet } = merchant;
    const { photos } = merchant;

    const photoEditUrl = merchant.wallet?.photoEditUrl;
    const history = merchant.wallet?.supportHistoryConnection;

    const location = [
        merchant.nearbyLandmark,
        merchant.subcity,
        merchant.city,
        merchant.region,
    ]
        .filter((x) => !!x)
        .join(", ");

    const { promotedBy } = merchant;

    if (history == null) {
        return <div>{t("merchant-tab--error")}</div>;
    }

    return (
        <Fragment>
            <div className="bg-gray-50 border-b border-gray-200 py-2 pr-12 pl-2">
                {photos && photos?.length > 0 && (
                    <div className="d-flex align-items-center justify-content-center not-found-container text-center">
                        <Carousel
                            slide={false}
                            interval={null}
                            className="bg-gray-300"
                        >
                            {photos.map((p) => (
                                <CarouselItem key={p.url}>
                                    <img
                                        src={buildUrl(p.url ?? "")}
                                        className="bg-gray-300 block max-h-[500px] -rotate-90"
                                    />
                                </CarouselItem>
                            ))}
                        </Carousel>
                    </div>
                )}
                <Table variant="condensed">
                    <thead>
                        <tr>
                            <th colSpan={2}>
                                {merchant.ufid} - {merchant.name}
                                &nbsp;
                                <span
                                    className={`capitalize badge badge-${
                                        merchant.isActive
                                            ? "success"
                                            : "warning"
                                    }`}
                                >
                                    {merchant.isActive
                                        ? t("merchant-tab--table--active")
                                        : t("merchant-tab--table--deactivated")}
                                </span>
                                {wallet?.kybTier && (
                                    <a
                                        href={photoEditUrl ?? undefined}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span
                                            className={`mx-1 capitalize badge badge-${
                                                wallet?.kybTier === "KYB0"
                                                    ? "danger"
                                                    : "primary"
                                            }`}
                                        >
                                            {KYB[wallet?.kybTier]}
                                            {merchant.activeKybGracePeriod &&
                                                t(
                                                    "merchant-tab--grace-period-enabled"
                                                )}
                                        </span>
                                    </a>
                                )}
                                {merchant.businessNetwork && (
                                    <span
                                        className={`mx-1 capitalize badge badge-tertiary`}
                                    >
                                        Corporate
                                    </span>
                                )}
                                {wallet && user.merchantUser && (
                                    <BusinessAppStatus
                                        merchantUser={user.merchantUser}
                                        name={wallet.name}
                                        mobile={wallet.mobile}
                                    />
                                )}
                            </th>
                        </tr>
                        {merchant.businessNetwork &&
                            merchant.businessNetwork.wallets.length > 1 && (
                                <tr>
                                    <th colSpan={2}>
                                        {t("merchant-tab--table--network-name")}{" "}
                                        <span className={"font-weight-normal"}>
                                            {merchant.businessNetwork.name}{" "}
                                            <a
                                                href={
                                                    "https://apps.wave-internal.com/business-network/" +
                                                    merchant.country.toLowerCase() +
                                                    "/" +
                                                    merchant.ufid
                                                }
                                                className={"text-info"}
                                                target={"_blank"}
                                                rel="noreferrer"
                                            >
                                                {t(
                                                    "merchant-tab--table--visualization-link"
                                                )}
                                            </a>
                                        </span>
                                    </th>
                                </tr>
                            )}
                        <tr>
                            <th colSpan={2}>
                                {t("merchant-tab--table--location", {
                                    location,
                                })}
                            </th>
                        </tr>
                        {!merchant.isActive && (
                            <tr>
                                <th colSpan={2}>
                                    {t(
                                        "merchant-tab--table--deactivated-reason",
                                        {
                                            date:
                                                merchant.whenDeactivated &&
                                                formatTimestamp(
                                                    new Date(
                                                        merchant.whenDeactivated
                                                    )
                                                ),
                                            by: merchant.deactivatedBy,
                                            reason: merchant.deactivatedBecause,
                                        }
                                    )}
                                </th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {promotedBy && promotedBy.mobile ? (
                            <span>
                                {t("merchant-tab--table--distributor")}{" "}
                                <ProfileLink
                                    mobile={promotedBy.mobile}
                                    name={promotedBy.name}
                                />
                            </span>
                        ) : (
                            <></>
                        )}
                        {wallet ? (
                            <>
                                <tr>
                                    <td>{t("merchant-tab--table--balance")}</td>
                                    <td>
                                        {canReadBalance && wallet.balance ? (
                                            <Amount
                                                amount={M.fromSerialized(
                                                    wallet.balance
                                                )}
                                            />
                                        ) : (
                                            <>??.???</>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <ButtonToolbar className="tab-header-toolbar justify-between">
                                            <ButtonGroup>
                                                <ActionButton
                                                    as="button"
                                                    name={t(
                                                        "send-merchant-app-link--action"
                                                    )}
                                                    requiredPermissions={[
                                                        "supportapp_send_merchant_app_link",
                                                    ]}
                                                >
                                                    <SendMerchantAppLinkMutator
                                                        name={wallet.name}
                                                        mobile={wallet.mobile}
                                                    />
                                                </ActionButton>
                                                <ActionButton
                                                    as="button"
                                                    name={t(
                                                        "escalate-merchant-support--action"
                                                    )}
                                                    requiredPermissions={[
                                                        "supportapp_escalate_to_merchant_support",
                                                    ]}
                                                >
                                                    <EscalateToMerchantSupportMutator
                                                        mobile={wallet.mobile}
                                                        user={user}
                                                        merchantSupportEscalations={
                                                            wallet.merchantSupportEscalations
                                                        }
                                                    />
                                                </ActionButton>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                <JumpToDateInput
                                                    when={when}
                                                    setWhen={setWhen}
                                                />
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <></>
                        )}
                    </tbody>
                </Table>
                <div className="mt-2">
                    <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {t("merchant-tab--merchant-users")}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <MerchantUsersList merchant={merchant} />
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            {canReadHistory ? (
                <MerchantHistory
                    merchantId={merchant.id}
                    historyConnection={history}
                    jumpTo={opaqueId}
                    relay={relay}
                />
            ) : (
                <div className="text-center">
                    {t("merchant-tab--no-permission")}
                </div>
            )}
        </Fragment>
    );
}

function buildUrl(url: string): string {
    if (process.env.NODE_ENV == "development") {
        return url.replace("https://", "http://");
    }
    return url;
}

const MerchantTabContents = createRefetchContainer(
    _MerchantTab,
    {
        merchant: graphql`
            fragment MerchantTab_merchant on Merchant
            @argumentDefinitions(
                first: { type: "Int" }
                after: { type: "DateTime" }
                last: { type: "Int" }
                before: { type: "DateTime" }
                around: { type: "ID" }
                when: { type: "DateTime" }
                canReadHistory: { type: "Boolean!" }
                canReadBalance: { type: "Boolean!" }
            ) {
                id
                ufid
                name
                city
                region
                subcity
                country
                nearbyLandmark
                isActive
                whenDeactivated
                deactivatedBy
                deactivatedBecause
                photos {
                    url
                }
                promotedBy {
                    name
                    mobile
                }
                activeKybGracePeriod {
                    kybTierGranted
                }
                businessNetwork {
                    name
                    wallets {
                        walletId
                    }
                }
                ...MerchantUsersList_merchant
                wallet {
                    id
                    balance @include(if: $canReadBalance)
                    name
                    mobile
                    kybTier
                    photoEditUrl
                    merchantSupportEscalations {
                        whenOpened
                        status
                        clickupTaskId
                    }
                    supportHistoryConnection(
                        first: $first
                        after: $after
                        last: 30
                        before: $before
                        around: $around
                        when: $when
                    )
                        @include(if: $canReadHistory)
                        @connection(
                            key: "MerchantTab_supportHistoryConnection"
                        ) {
                        edges {
                            node {
                                id
                                ... on MerchantSaleEntry {
                                    maybeTransferId: transferId
                                }
                                ... on PayoutTransferEntry {
                                    __typename
                                    tcid
                                    amount
                                    whenEntered
                                }
                                ...MerchantHistoryEntry_entry
                                ... on AgentTransactionEntry {
                                    agentTransactionId
                                }
                            }
                        }
                        pageInfo {
                            hasPreviousPage
                            hasNextPage
                            startCursor
                            endCursor
                        }
                    }
                }
            }
        `,
    },
    graphql`
        query MerchantTabRefetchQuery(
            $merchantId: ID!
            $first: Int
            $after: DateTime
            $last: Int
            $before: DateTime
            $around: ID
            $when: DateTime
            $canReadHistory: Boolean!
            $canReadBalance: Boolean!
        ) {
            node(id: $merchantId) {
                ... on Merchant {
                    ...MerchantTab_merchant
                        @arguments(
                            first: $first
                            after: $after
                            last: $last
                            before: $before
                            around: $around
                            when: $when
                            canReadHistory: $canReadHistory
                            canReadBalance: $canReadBalance
                        )
                }
            }
        }
    `
);

const MerchantTabQuery = graphql`
    query MerchantTabQuery(
        $mobile: String!
        $around: ID
        $when: DateTime
        $canReadHistory: Boolean!
        $canReadBalance: Boolean!
    ) {
        wallet(mobile: $mobile) {
            user {
                merchantUser {
                    businessAppVersion {
                        appType
                        appVersionCode
                        isUpToDate
                        string
                    }
                }
                merchant {
                    ...MerchantTab_merchant
                        @arguments(
                            around: $around
                            when: $when
                            canReadHistory: $canReadHistory
                            canReadBalance: $canReadBalance
                        )
                }
                ...EscalateToMerchantSupport_user
            }
        }
    }
`;

export const MerchantTab = ({ mobile }: { mobile: string }) => {
    const { t } = useTranslation();
    const environment = useRelayEnvironment();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const opaqueId = params.get(OPAQUE_ID_QUERY_PARAM_NAME);
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const { date, setWhen } = useJumpToDate();

    return (
        <QueryRenderer
            environment={environment}
            render={({ error, props }: ReadyState<any>) => {
                if (error) {
                    if (error instanceof WrongBackendError) throw error;
                    if (error.message === "Failed to fetch") {
                        return (
                            <div>
                                {t("merchant-tab--error-networ")}{" "}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://frontplugin.wave-internal.com/backend_proxy/"
                                    className="text-blue-600"
                                >
                                    https://frontplugin.wave-internal.com/backend_proxy/
                                </a>
                            </div>
                        );
                    }

                    return (
                        <div>
                            {t("merchant-tab--error-loading")} {error.message}
                        </div>
                    );
                }

                if (!props) return <LoadingIndicator />;

                return (
                    <MerchantTabContents
                        opaqueId={opaqueId || undefined}
                        merchant={props.wallet.user.merchant}
                        user={props.wallet.user}
                        canReadBalance={canReadBalance}
                        canReadHistory={canReadHistory}
                        when={date}
                        setWhen={setWhen}
                    />
                );
            }}
            fetchPolicy="store-and-network"
            query={MerchantTabQuery}
            variables={{
                mobile,
                around: opaqueId,
                when: date?.toISOString(),
                canReadHistory,
                canReadBalance,
            }}
        />
    );
};
