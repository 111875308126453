/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RefundP2P_transfer = {
    readonly id: string;
    readonly transferId: string;
    readonly sendAmount: string;
    readonly receiveAmount: string;
    readonly senderName: string;
    readonly senderMobile: string;
    readonly recipientName: string;
    readonly maybeTerminatedRecipientMobile: string;
    readonly " $refType": "RefundP2P_transfer";
};
export type RefundP2P_transfer$data = RefundP2P_transfer;
export type RefundP2P_transfer$key = {
    readonly " $data"?: RefundP2P_transfer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RefundP2P_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefundP2P_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sendAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiveAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedRecipientMobile",
      "storageKey": null
    }
  ],
  "type": "MobileTransferEntry",
  "abstractKey": "__isMobileTransferEntry"
};
(node as any).hash = 'ae03dc69a1a00fc496f6fd84947f2a36';
export default node;
