import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { UnlinkLegalEntityMutationResponse } from "./__generated__/UnlinkLegalEntityMutation.graphql";

const mutation = graphql`
    mutation UnlinkLegalEntityMutation($walletId: ID!) {
        unlinkLegalEntity(walletId: $walletId) {
            wallet {
                id
                name
                legalEntity {
                    wallets {
                        id
                    }
                }
            }
        }
    }
`;

type UnlinkLegalEntityInputParams = {
    walletId: string;
};

const _UnlinkLegalEntityMutator = (
    props: UnlinkLegalEntityInputParams & RelayModalProps
) => {
    const { onHide, walletId } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: UnlinkLegalEntityMutationResponse) => {
            return response.unlinkLegalEntity?.wallet.id || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.UnlinkFromLegalEntity,
            data: { walletId },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { walletId });
    }, [mutator, walletId]);

    return (
        <MutatorModal {...mutator} title={t("unlink-entity--title")}>
            <Form
                {...mutator}
                submitText={t("action-confirm")}
                isValid={true}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <p>{t("unlink-entity--description-1")}</p>
                <p>{t("unlink-entity--description-2")}</p>
            </Form>
        </MutatorModal>
    );
};
export const UnlinkLegalEntityMutator = createFragmentContainer(
    _UnlinkLegalEntityMutator,
    {}
);
