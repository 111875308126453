// this hook is similar to useState except the state is also saved to
// local storage. Use this hook to make sure that local storage and
// state remin in sync
import { useCallback, useState } from "react";

const getStoredItem = (storageKey: string, defaultVal: any): any => {
    const value = window.localStorage.getItem(storageKey);
    return value != null ? JSON.parse(value) : defaultVal;
};

export const useLocalStorage = (
    defaultVal: any,
    storageKey: string
): [any, () => any, (value: any) => void] => {
    const [value, setValue] = useState<any>(
        getStoredItem(storageKey, defaultVal)
    );

    const setValueAndStore = useCallback(
        (newValue: any) => {
            setValue(newValue);
            window.localStorage.setItem(storageKey, JSON.stringify(newValue));
        },
        [storageKey]
    );

    const getValue = useCallback(() => {
        return getStoredItem(storageKey, defaultVal);
    }, [storageKey, defaultVal]);

    return [value, getValue, setValueAndStore];
};
