import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isValidMobile, mobileValidationErrorMessage } from "@/utils/mobile";

import { ReportBillPayProblemMutationResponse } from "./__generated__/ReportBillPayProblemMutation.graphql";

import {
    BillPayProblems,
    BillPayProblemsHistoryTable,
} from "./BillPayProblemsHistoryTable";

const mutation = graphql`
    mutation ReportBillPayProblemMutation(
        $mobile: String!
        $transactionId: ID
        $comments: String!
    ) {
        reportBillPayProblem(
            mobile: $mobile
            transactionId: $transactionId
            comments: $comments
        ) {
            result
        }
    }
`;

type ReportBillPayProblemInputParams = {
    mobile: string;
    transactionId?: string;
    comments?: string;
    billPayProblems?: BillPayProblems;
};

const _ReportBillPayProblemMutator = (
    props: ReportBillPayProblemInputParams & RelayModalProps
) => {
    const { onHide, mobile, transactionId, comments, billPayProblems } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ReportBillPayProblemMutationResponse) => {
            return response.reportBillPayProblem?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportBillPayProblem,
            data: { mobile, transactionId },
        },
        ...props,
    });

    const mobileField = useTextField();
    const transactionIdField = useTextField();
    const additionalInfoField = useTextField();

    const handleSubmit = useCallback(() => {
        if (transactionIdField.value) {
            mutator.submit(mutation, {
                mobile: mobileField.value,
                transactionId: transactionIdField.value,
                comments: additionalInfoField.value,
            });
        } else if (confirm(t("report-bill-pay--confirm"))) {
            mutator.submit(mutation, {
                mobile: mobileField.value,
                transactionId: undefined,
                comments: additionalInfoField.value,
            });
        } else {
            // do nothing
        }
    }, [
        t,
        mutator,
        mobileField.value,
        transactionIdField.value,
        additionalInfoField.value,
    ]);

    const transactionIdValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (
            transactionIdField.value &&
            !transactionIdField.value.includes("T_")
        ) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-transaction-id"),
            });
        }
        return validations;
    }, [t, transactionIdField.value]);

    const mobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!isValidMobile(mobileField.value)) {
            validations.push(mobileValidationErrorMessage as Validation);
        }

        return validations;
    }, [mobileField.value]);
    const isValid = useMemo(() => {
        return !hasError([mobileValidations, transactionIdValidations]);
    }, [mobileValidations, transactionIdValidations]);

    return (
        <MutatorModal {...mutator} title={t("report-bill-pay--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...mobileField}
                    label={t("label-customer-mobile")}
                    name="mobile"
                    validations={mobileValidations}
                    defaultValue={mobile}
                    autoFocus
                />

                <TextField
                    {...transactionIdField}
                    label={t("label-transaction-id")}
                    name="transactionId"
                    validations={transactionIdValidations}
                    defaultValue={transactionId}
                />

                <TextField
                    {...additionalInfoField}
                    label={t("label-comments")}
                    name="comments"
                    validations={[]}
                    defaultValue={comments}
                    as="textarea"
                    autoFocus
                />
            </Form>
            <p>{t("report-bill-pay-history--title")}</p>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t("report-bill-pay-history--reported-on")}</th>
                        <th>{t("report-bill-pay-history--status")}</th>
                        <th>{t("report-bill-pay-history--bill-type")}</th>
                        <th>{t("report-bill-pay-history--transaction-id")}</th>
                    </tr>
                </thead>
                <tbody>
                    <BillPayProblemsHistoryTable tickets={billPayProblems} />
                </tbody>
            </table>
        </MutatorModal>
    );
};
export const ReportBillPayProblemMutator = createFragmentContainer(
    _ReportBillPayProblemMutator,
    {}
);
