/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RefundPurchaseEntryMutationVariables = {
    transferId: string;
};
export type RefundPurchaseEntryMutationResponse = {
    readonly refundMerchantSale: {
        readonly transfer: {
            readonly id: string;
        } | null;
    } | null;
};
export type RefundPurchaseEntryMutation = {
    readonly response: RefundPurchaseEntryMutationResponse;
    readonly variables: RefundPurchaseEntryMutationVariables;
};



/*
mutation RefundPurchaseEntryMutation(
  $transferId: ID!
) {
  refundMerchantSale(transferId: $transferId) {
    transfer {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transferId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "transferId",
        "variableName": "transferId"
      }
    ],
    "concreteType": "MerchantRefund",
    "kind": "LinkedField",
    "name": "refundMerchantSale",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MerchantTransfer",
        "kind": "LinkedField",
        "name": "transfer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefundPurchaseEntryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefundPurchaseEntryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c625777deccec26811c214bb96402855",
    "id": null,
    "metadata": {},
    "name": "RefundPurchaseEntryMutation",
    "operationKind": "mutation",
    "text": "mutation RefundPurchaseEntryMutation(\n  $transferId: ID!\n) {\n  refundMerchantSale(transferId: $transferId) {\n    transfer {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4da94d3725f7ada2f62c7f1e8dc49abc';
export default node;
