/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnblockUserFromDormancy_user = {
    readonly id: string;
    readonly dormancyBlock: {
        readonly whenBlocked: string;
        readonly blockedBy: string;
        readonly blockReason: string;
        readonly whenExpires: string | null;
    } | null;
    readonly " $refType": "UnblockUserFromDormancy_user";
};
export type UnblockUserFromDormancy_user$data = UnblockUserFromDormancy_user;
export type UnblockUserFromDormancy_user$key = {
    readonly " $data"?: UnblockUserFromDormancy_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UnblockUserFromDormancy_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnblockUserFromDormancy_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "dormancyBlock",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenBlocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "blockReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenExpires",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '7ed9f88b147483e6ee12903d597ff237';
export default node;
