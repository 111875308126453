import { useTranslation } from "react-i18next";

import { HistoryActionButton } from "@/components/HistoryActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { ReverseFrozenTransactionMutator } from "@/pages/mobile.[mobile].personal/ReverseFrozenTransaction";

import { CAN_REVERSE_FROZEN_TRANSACTION } from "@/types/Permissions";

interface FinalizeFrozenFundsHistoryActionButtonProps {
    transferOrLedgerTransactionId: string;
}

export const ReverseFrozenTransactionHistoryActionButton = ({
    transferOrLedgerTransactionId,
}: FinalizeFrozenFundsHistoryActionButtonProps) => {
    const { t } = useTranslation();
    const canReverseFrozenTransaction = usePermissions(
        CAN_REVERSE_FROZEN_TRANSACTION
    );

    return canReverseFrozenTransaction ? (
        <HistoryActionButton name={t("reverse-frozen-transaction--action")}>
            <ReverseFrozenTransactionMutator
                transferOrLedgerTransactionId={transferOrLedgerTransactionId}
            />
        </HistoryActionButton>
    ) : null;
};
