import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { DropdownField, useDropdownField } from "@/components/DropdownField";
import { ProfileSearchField } from "@/components/ProfileSearchField";

import { useCurrentCall } from "@/hooks/useCurrentCall";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { ReportUserComplaintMutationResponse } from "./__generated__/ReportUserComplaintMutation.graphql";
import { ReportUserComplaint_agentUser } from "./__generated__/ReportUserComplaint_agentUser.graphql";

const mutation = graphql`
    mutation ReportUserComplaintMutation(
        $agentId: ID!
        $description: String
        $issueType: AgentIssueType!
        $reportingCustomerMobile: String
    ) {
        reportAgentIssue(
            agentId: $agentId
            description: $description
            issueType: $issueType
            reportingCustomerMobile: $reportingCustomerMobile
        ) {
            success
        }
    }
`;

type ReportUserComplaintInputParams = {
    agentUser: ReportUserComplaint_agentUser;
};

const _ReportUserComplaintMutator = (
    props: RelayModalProps & ReportUserComplaintInputParams
) => {
    const { onHide, agentUser } = props;

    const { t } = useTranslation();
    const { currentCall } = useCurrentCall();

    const issueTypeField = useDropdownField();
    const descriptionField = useTextField();

    const [callerMobile, setCallerMobile] = useState(
        currentCall.callType !== "none" ? currentCall.mobile : ""
    );

    const onMutationSuccess = useCallback(
        (response: ReportUserComplaintMutationResponse): string => {
            if (!response.reportAgentIssue) {
                return "";
            }

            const success = response.reportAgentIssue.success;
            return success
                ? t("report-agent-issue--success")
                : t("report-agent-issue--error");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportUserComplaint,
            data: {
                agentUfid: agentUser.agent.ufid,
                issueType: issueTypeField.dropdownItem?.value,
                description: descriptionField.value,
                reportingCustomerMobile: callerMobile.trim(),
            },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            agentId: agentUser.agent.ufid,
            issueType: issueTypeField.dropdownItem?.value,
            description: descriptionField.value,
            reportingCustomerMobile: callerMobile.trim(),
        });
    }, [
        agentUser.agent.ufid,
        issueTypeField.dropdownItem?.value,
        descriptionField.value,
        callerMobile,
        mutator,
    ]);

    const issueTypeValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!issueTypeField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-issue-type"),
                display: "none",
            });
        }
        return validations;
    }, [t, issueTypeField.dropdownItem]);

    const callerMobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!callerMobile) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-mobile"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [callerMobile, t]);

    const isValid = useMemo(() => {
        return !hasError([issueTypeValidations, callerMobileValidations]);
    }, [issueTypeValidations, callerMobileValidations]);

    const issueTypes = useMemo(() => {
        return agentUser.agent.possibleIssueTypes
            .filter((type) => type.displayGroupName === "User Complaint")
            .map((type) => ({
                value: type.type,
                displayName: type.displayName,
            }));
    }, [agentUser.agent.possibleIssueTypes]);

    const descriptionPlaceholder = useMemo(() => {
        if (!issueTypeField.dropdownItem) {
            return t("report-user-complaint--placeholder-empty");
        }
        if (
            [
                "CHARGING_EXTRA_FEES_FOR_SIGNUP",
                "CHARGING_EXTRA_FEES_FOR_TRANSACTIONS",
                "REFUSING_TO_DO_SMALL_TRANSACTIONS",
            ].includes(String(issueTypeField.dropdownItem.value))
        ) {
            return t("report-user-complaint--placeholder-add-amount");
        }
        if (
            issueTypeField.dropdownItem.value === "REDIRECTING_TO_COMPETITION"
        ) {
            return t("report-user-complaint--placeholder-competitor");
        }
        return t("report-user-complaint--placeholder-add-notes");
    }, [issueTypeField.dropdownItem, t]);

    return (
        <MutatorModal
            {...mutator}
            title={t("report-user-complaint--title", {
                name: agentUser.agent.name,
            })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-report")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <ProfileSearchField
                    allowWallets
                    allowUnregistered
                    allowMerchants={false}
                    allowAgents={false}
                    allowTerminated
                    initialQuery={callerMobile}
                    label={t("no-action-survey--mobile")}
                    name="caller-mobile"
                    validations={callerMobileValidations}
                    onChange={(profile) => {
                        if (profile?.mobile) setCallerMobile(profile.mobile);
                    }}
                />
                <DropdownField
                    {...issueTypeField}
                    label={t("label-issue-type")}
                    name="issueType"
                    validations={issueTypeValidations}
                    values={issueTypes}
                    autoFocus
                    placeholder={t("placeholder-issue-type")}
                />
                <TextField
                    {...descriptionField}
                    as="textarea"
                    label={t("label-description")}
                    placeholder={descriptionPlaceholder}
                    name="description"
                    validations={[]}
                />
            </Form>
        </MutatorModal>
    );
};

export const ReportUserComplaintMutator = createFragmentContainer(
    _ReportUserComplaintMutator,
    {
        agentUser: graphql`
            fragment ReportUserComplaint_agentUser on AgentUser {
                agent {
                    ufid
                    name
                    possibleIssueTypes {
                        type
                        displayName
                        displayGroupName
                    }
                }
            }
        `,
    }
);
