import * as React from "react";
import { useState } from "react";
import {
    FormGroup as BootstrapFormGroup,
    FormLabel,
    FormText,
} from "react-bootstrap";

import { Validation } from "@/types/FormValidation";

import { validationErrors, validationWarnings } from "@/utils/formValidation";

type FormGroupProps = {
    label: string;
    name: string;
    children: React.ReactNode;
    validations: Validation[];
};
/**
 * Custom FormGroup component used inside other components. You should never need to use
 * it yourself unless you are developing a new form control component.
 *
 * FormGroup checks the validation state and displays an error or info message if necessary
 */
export const FormGroup = (props: FormGroupProps) => {
    const [isFocused, setFocused] = useState(false);

    const label = props.label && <FormLabel>{props.label}</FormLabel>;
    const { validations, name, children } = props;
    const errors = validationErrors(!isFocused, validations);
    const warnings = validationWarnings(!isFocused, validations);
    const hasError = errors.length > 0;
    return (
        <BootstrapFormGroup
            controlId={name}
            className={hasError ? "has-error" : undefined}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
        >
            {label}
            {children}
            {errors.map((e) => (
                <FormText key={e.message}>{e.message}</FormText>
            ))}
            {!hasError
                ? warnings.map((w) => (
                      <FormText key={w.message}>{w.message}</FormText>
                  ))
                : null}
        </BootstrapFormGroup>
    );
};
