import * as React from "react";

import { Amount } from "@/components/Amount";

import { M } from "@/utils/currency";

interface Props {
    amount: M;
    children: React.ReactNode;
    isPending: boolean;
}

export const HistoryEntryTopRow = ({ isPending, children, amount }: Props) => {
    return (
        <div className="history-entry-top-row">
            <div>{children}</div>
            <div className={`italic ${isPending && "opacity-50"}`}>
                <Amount amount={amount} />
            </div>
        </div>
    );
};
