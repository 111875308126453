/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UserLinkedAccountTransferB2WEntry_tx = {
    readonly summary: string | null;
    readonly isCancelled: boolean;
    readonly partnerName: string;
    readonly liaTransferId: string;
    readonly errorCode: string | null;
    readonly errorMessage: string | null;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "UserLinkedAccountTransferB2WEntry_tx";
};
export type UserLinkedAccountTransferB2WEntry_tx$data = UserLinkedAccountTransferB2WEntry_tx;
export type UserLinkedAccountTransferB2WEntry_tx$key = {
    readonly " $data"?: UserLinkedAccountTransferB2WEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UserLinkedAccountTransferB2WEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserLinkedAccountTransferB2WEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "partnerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "liaTransferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorMessage",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "UserLinkedAccountTransferB2WEntry",
  "abstractKey": null
};
(node as any).hash = '99769e620f4e0a07438570b7e2538638';
export default node;
