import { faBalanceScale, faGavel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export const TicketLink = (props: {
    status: "OPEN" | "ASSIGNED" | "RESOLVED" | "%future added value"; // the last is for compatibility with generated graphql
    slackMessageUrl: string | null;
}) => {
    const { t } = useTranslation();

    const content = (
        <span
            className={`text-capitalize badge badge-${
                props.status == "RESOLVED" ? "primary" : "danger"
            }`}
            title={t("ticket-link--status", {
                status:
                    props.status == "RESOLVED"
                        ? t("ticket-link--status--resolved")
                        : t("ticket-link--status--open"),
            })}
        >
            <FontAwesomeIcon
                icon={props.status == "RESOLVED" ? faGavel : faBalanceScale}
            />
        </span>
    );

    return (
        <>
            {props.slackMessageUrl ? (
                <a
                    href={props.slackMessageUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-600"
                >
                    {content}
                </a>
            ) : (
                content
            )}
        </>
    );
};
