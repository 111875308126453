import * as React from "react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import {
    DropdownField,
    useDropdownField,
} from "../../components/DropdownField";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { ClearPinRateLimitMutationResponse } from "./__generated__/ClearPinRateLimitMutation.graphql";
import { ClearPinRateLimit_user } from "./__generated__/ClearPinRateLimit_user.graphql";

const mutation = graphql`
    mutation ClearPinRateLimitMutation($userId: ID!, $deviceId: String!) {
        expirePinRateLimitBlock(userId: $userId, deviceId: $deviceId) {
            user {
                ...ClearPinRateLimit_user
            }
        }
    }
`;

type Props = {
    user: ClearPinRateLimit_user;
};

const _ClearPinRateLimitModal = (props: Props & RelayModalProps) => {
    const { user } = props;
    const { t } = useTranslation();
    const selectedDeviceField = useDropdownField();
    const selectedDeviceFieldValidations = useMemo(() => {
        const validations: Validation[] = [];
        if (!selectedDeviceField.dropdownItem?.value) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-option"),
                display: "none",
            });
        }
        return validations;
    }, [selectedDeviceField.dropdownItem?.value, t]);

    const onMutationSuccess = useCallback(
        (_response: ClearPinRateLimitMutationResponse) => {
            return t("clear-pin-rate-limit--success");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ClearPinRateLimit,
            data: { deviceId: selectedDeviceField.dropdownItem?.value },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            userId: user.id,
            deviceId: selectedDeviceField.dropdownItem?.value,
        });
    }, [mutator, user, selectedDeviceField.dropdownItem?.value]);

    return (
        <MutatorModal {...mutator} title={t("clear-pin-rate-limit--title")}>
            <Form
                {...mutator}
                onSubmit={handleSubmit}
                onDone={props.onHide}
                submitText={t("clear-pin-rate-limit--submit")}
                isValid={!hasError([selectedDeviceFieldValidations])}
            >
                <DropdownField
                    {...selectedDeviceField}
                    label={t("clear-pin-rate-limit--select-device")}
                    name="selectedDevice"
                    values={(user.devices || [])
                        .filter((d) => d.hasPinRateLimitBlock)
                        .map((d) => {
                            return {
                                value: d.deviceId,
                                displayName: `${d.deviceName} (${d.deviceModel})`,
                            };
                        })}
                    validations={selectedDeviceFieldValidations}
                    placeholder={t("dropdown--select-option")}
                />
            </Form>
        </MutatorModal>
    );
};

export const ClearPinRateLimit = createFragmentContainer(
    _ClearPinRateLimitModal,
    {
        user: graphql`
            fragment ClearPinRateLimit_user on User {
                id
                devices {
                    deviceId
                    deviceModel
                    deviceName
                    hasPinRateLimitBlock
                }
            }
        `,
    }
);
