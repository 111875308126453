import { ReactNode, createContext, useEffect, useState } from "react";

import { Flag, Flags } from "@/external/flags";

export const FlagContext = createContext(new Map<string, Flag>());

export function FlagContextProvider(props: { children: ReactNode }) {
    const [flags, setFlags] = useState(new Map<string, Flag>());
    useEffect(() => {
        setFlags(Flags.getFlags());
    }, []);

    return (
        <FlagContext.Provider value={flags}>
            {props.children}
        </FlagContext.Provider>
    );
}
