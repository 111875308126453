import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { mutate } from "@/external/relay";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { RequestToExplainCommissionCutsMutation } from "./__generated__/RequestToExplainCommissionCutsMutation.graphql";
import { RequestToExplainCommissionCuts_agent } from "./__generated__/RequestToExplainCommissionCuts_agent.graphql";

const mutation = graphql`
    mutation RequestToExplainCommissionCutsMutation(
        $agentId: String!
        $reason: String!
    ) {
        requestToExplainCommissionCuts(agentId: $agentId, reason: $reason) {
            result
        }
    }
`;

type RequestToExplainCommissionCutsInputParams = {
    agent: RequestToExplainCommissionCuts_agent;
};

const _RequestToExplainCommissionCutsMutator = (
    props: RequestToExplainCommissionCutsInputParams & RelayModalProps
) => {
    const { agent } = props;

    const { t } = useTranslation();
    const trackEvent = useTrackEvent();

    const reasonField = useTextField();
    const agentId = agent.ufid;

    const submitText = t("request-explain-commission--submit");
    const modalHelpText = <p>{t("request-explain-commission--description")}</p>;

    const requestToExplainCommissionCutsRequest = useAsyncFunction(async () => {
        if (!props.relay) {
            return;
        }
        const [response, payloadErrors] =
            await mutate<RequestToExplainCommissionCutsMutation>(
                props.relay.environment,
                mutation,
                {
                    agentId: agentId,
                    reason: reasonField.value,
                }
            );

        trackEvent(Action.RequestToExplainComissionCuts, {
            agentUfid: props.agent.ufid,
        });

        if (payloadErrors) {
            return payloadErrors;
        }

        return response?.requestToExplainCommissionCuts?.result;
    });

    const reasonValidations: Validation[] = [];
    if (!reasonField.value) {
        reasonValidations.push({
            type: "error",
            message: t("request-explain-commission--missing-reason"),
            display: "if-blurred",
        });
    }
    const isValid = !hasError([reasonValidations]);
    const requestToExplainCommissionCutsResultText =
        typeof requestToExplainCommissionCutsRequest.result === "string"
            ? requestToExplainCommissionCutsRequest.result
            : null;
    const requestToExplainCommissionCutsErrors = Array.isArray(
        requestToExplainCommissionCutsRequest.result
    )
        ? requestToExplainCommissionCutsRequest.result
        : requestToExplainCommissionCutsRequest.error
        ? [requestToExplainCommissionCutsRequest.error]
        : null;

    return (
        <MutatorModal
            title={submitText}
            isWorking={requestToExplainCommissionCutsRequest.isLoading}
            show={props.show}
            onHide={props.onHide}
            errors={requestToExplainCommissionCutsErrors}
        >
            {modalHelpText}
            <Form
                onSubmit={requestToExplainCommissionCutsRequest.invoke}
                submitText={submitText}
                isWorking={requestToExplainCommissionCutsRequest.isLoading}
                errors={requestToExplainCommissionCutsErrors}
                isValid={isValid}
                result={requestToExplainCommissionCutsResultText}
            >
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
        </MutatorModal>
    );
};

export const RequestToExplainCommissionCutsMutator = createFragmentContainer(
    _RequestToExplainCommissionCutsMutator,
    {
        agent: graphql`
            fragment RequestToExplainCommissionCuts_agent on Agent {
                id
                ufid
            }
        `,
    }
);
