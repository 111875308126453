/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoginLogoutEventEnum = "LOGIN" | "LOGOUT" | "%future added value";
export type LoginLogoutEvent_tx = {
    readonly id: string;
    readonly event: LoginLogoutEventEnum;
    readonly whenEntered: string;
    readonly correspondingLoginTime: string | null;
    readonly scamInvestigationFields: {
        readonly deviceId: string | null;
        readonly deviceModel: string | null;
        readonly deviceName: string | null;
    } | null;
    readonly " $refType": "LoginLogoutEvent_tx";
};
export type LoginLogoutEvent_tx$data = LoginLogoutEvent_tx;
export type LoginLogoutEvent_tx$key = {
    readonly " $data"?: LoginLogoutEvent_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LoginLogoutEvent_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LoginLogoutEvent_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "event",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenEntered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correspondingLoginTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamInvestigationFields",
      "kind": "LinkedField",
      "name": "scamInvestigationFields",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceModel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deviceName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LoginLogoutEvent",
  "abstractKey": null
};
(node as any).hash = '21d319c65ee4000f0f9ab7353bcb01cf';
export default node;
