import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

export const ConfirmationModal = ({
    onConfirm,
    onCancel,
    show,
    confirmationText,
}: {
    onConfirm: () => void;
    onCancel: () => void;
    show: boolean;
    confirmationText: string;
}) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal show={show} onHide={onCancel}>
                <Modal.Body>{confirmationText}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCancel}>
                        {t("confirmation-modal--cancel")}
                    </Button>
                    <Button variant="primary" onClick={onConfirm}>
                        {t("confirmation-modal--continue")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
