import { Fragment } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { TxInfo, TxInfoError } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { CAN_REFUND_P2P_TX } from "@/types/Permissions";

import { RemittanceTransferReceivedEntry_tx } from "./__generated__/RemittanceTransferReceivedEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ChangeRemittanceRecipientMutator } from "./ChangeRemittanceRecipient";
import { RemittanceTransferRefundMutator } from "./RemittanceTransferRefund";

function _RemittanceTransferReceivedEntry(
    props: {
        tx: RemittanceTransferReceivedEntry_tx;
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx, reversedTransfers } = props;
    const whenReversed = reversedTransfers[tx.opaqueId];
    const isReversed = !!whenReversed || tx.isCancelled;
    const hasSupportAppChangeRecipientPermission = usePermissions(
        "supportapp_change_recipient"
    );
    const hasSupportAppRefundPermission = usePermissions(CAN_REFUND_P2P_TX);

    const buttons = isReversed ? null : (
        <Fragment>
            <HistoryActionButton
                name={t("refund--action")}
                disabled={!hasSupportAppRefundPermission}
            >
                <RemittanceTransferRefundMutator transfer={tx} />
            </HistoryActionButton>
            {!tx.isPending && (
                <HistoryActionButton
                    name={t("change-recipient--action")}
                    disabled={!hasSupportAppChangeRecipientPermission}
                >
                    <ChangeRemittanceRecipientMutator transfer={tx} />
                </HistoryActionButton>
            )}
        </Fragment>
    );

    const info = <TxInfo>{tx.opaqueId}</TxInfo>; // &#40 is ( but for some reason ( breaks syntax highlighting...

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <Fragment>
                    <TxTitle>
                        {t("remittance-transfer-received--title", {
                            name: tx.senderName,
                            mobile: tx.senderMobile,
                        })}
                    </TxTitle>
                    {tx.isCancelled && (
                        <TxInfoError>
                            {t("remittance-transfer--cancelled")}
                        </TxInfoError>
                    )}
                    {info}
                </Fragment>
            }
            buttons={buttons}
        />
    );
}

export default createFragmentContainer(_RemittanceTransferReceivedEntry, {
    tx: graphql`
        fragment RemittanceTransferReceivedEntry_tx on RemittanceTransferReceivedEntry {
            id
            opaqueId
            description
            amount
            isPending
            isCancelled
            senderName
            senderMobile
            senderCountry
            recipientMobile
            receiveAmount
            ...HistoryEntry_entry
            ...RemittanceTransferRefund_transfer
            ...ChangeRemittanceRecipient_transfer
        }
    `,
});
