/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AgentVisitPriority = "HIGH" | "LOW" | "MEDIUM" | "URGENT" | "%future added value";
export type AgentCustomTaskInput = {
    agentId: string;
    staffMemberId: string;
    description: string;
    priority: AgentVisitPriority;
};
export type CreateAgentTaskMutationVariables = {
    agentCustomTask: AgentCustomTaskInput;
};
export type CreateAgentTaskMutationResponse = {
    readonly createAgentCustomTask: {
        readonly visitTask: {
            readonly assignedTo: {
                readonly name: string;
            } | null;
            readonly description?: string | undefined;
        };
    } | null;
};
export type CreateAgentTaskMutation = {
    readonly response: CreateAgentTaskMutationResponse;
    readonly variables: CreateAgentTaskMutationVariables;
};



/*
mutation CreateAgentTaskMutation(
  $agentCustomTask: AgentCustomTaskInput!
) {
  createAgentCustomTask(agentCustomTask: $agentCustomTask) {
    visitTask {
      __typename
      assignedTo {
        name
        id
      }
      ... on AgentCustomTask {
        description
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentCustomTask"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "agentCustomTask",
    "variableName": "agentCustomTask"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "AgentCustomTask",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAgentTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAgentCustomTask",
        "kind": "LinkedField",
        "name": "createAgentCustomTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "visitTask",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffMember",
                "kind": "LinkedField",
                "name": "assignedTo",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAgentTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAgentCustomTask",
        "kind": "LinkedField",
        "name": "createAgentCustomTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "visitTask",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffMember",
                "kind": "LinkedField",
                "name": "assignedTo",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b31b2c7e7745f941ff24532b84749f3",
    "id": null,
    "metadata": {},
    "name": "CreateAgentTaskMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAgentTaskMutation(\n  $agentCustomTask: AgentCustomTaskInput!\n) {\n  createAgentCustomTask(agentCustomTask: $agentCustomTask) {\n    visitTask {\n      __typename\n      assignedTo {\n        name\n        id\n      }\n      ... on AgentCustomTask {\n        description\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '056fdada5c3c662a6f36440c5eea7671';
export default node;
