import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { TicketLink } from "../../components/TicketLink";
import { HistoryActionButton } from "@/components/HistoryActionButton";
import { MerchantProfileLink } from "@/components/MerchantProfileLink";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { RefundPurchaseEntryMutator } from "@/pages/mobile.[mobile].merchant/RefundPurchaseEntry";

import { CAN_REFUND_MERCHANT_SALE } from "@/types/Permissions";

import { M } from "@/utils/currency";

import { PurchaseEntry_tx } from "../mobile.[mobile].merchant/__generated__/PurchaseEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ReportCustomerScamMutator } from "../mobile.[mobile]/ReportCustomerScam";

function _PurchaseEntry(props: { tx: PurchaseEntry_tx } & HistoryEntryProps) {
    const { t } = useTranslation();
    const entry = props.tx;
    const info = entry.promoUsed ? "(Promo)" : "";

    const has_refund_perm = usePermissions(CAN_REFUND_MERCHANT_SALE);
    const refundButton =
        entry.isRemote && !entry.isRefunded ? (
            <HistoryActionButton
                name={t("action-refund")}
                disabled={!has_refund_perm}
            >
                <RefundPurchaseEntryMutator transfer={entry} />
            </HistoryActionButton>
        ) : null;
    const canReportScam = usePermissions("supportapp_report_customer_scam");
    const canReportScamWithDetails = usePermissions(
        "supportapp_report_customer_scam_with_details"
    );

    const buttons = (
        <>
            <HistoryActionButton
                name={t("report-customer-scam--action")}
                disabled={!canReportScam}
            >
                <ReportCustomerScamMutator
                    transactionId={entry.transferId}
                    mobile={entry.maybeTerminatedSenderMobile}
                    amountStolen={M.fromSerialized(entry.amount).negate()}
                    scamReports={entry.scamReports}
                    hideAdditionalInfoFields={!canReportScamWithDetails}
                />
            </HistoryActionButton>
            {refundButton}
        </>
    );

    return (
        <HistoryEntry
            {...props}
            entry={entry}
            title={
                <Fragment>
                    <TxTitle>
                        {t("purchase-entry--title")}{" "}
                        {entry.merchantId ? (
                            <MerchantProfileLink
                                name={entry.merchantName}
                                merchantId={entry.merchantId}
                                opaqueId={entry.transferId}
                            />
                        ) : (
                            entry.merchantName
                        )}{" "}
                        {entry.relatedTicket && (
                            <TicketLink
                                status={entry.relatedTicket.status}
                                slackMessageUrl={
                                    entry.relatedTicket.slackMessageUrl
                                }
                            />
                        )}
                    </TxTitle>
                    <TxInfo>
                        {entry.transferId} {info}
                    </TxInfo>
                </Fragment>
            }
            buttons={buttons}
        />
    );
}

export default createFragmentContainer(_PurchaseEntry, {
    tx: graphql`
        fragment PurchaseEntry_tx on PurchaseEntry {
            senderMobile
            maybeTerminatedSenderMobile
            amount
            merchantId
            merchantName
            merchantCode
            merchantMobile
            promoUsed
            transferId
            isRefunded
            isRemote
            relatedTicket {
                whenOpened
                whenResolved
                status
                slackMessageUrl
                ticketType
                reference
            }
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            ...HistoryEntry_entry
            ...RefundPurchaseEntry_transfer
        }
    `,
});
