/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AppType = "AGENT_APP" | "BUSINESS_APP" | "BUSINESS_APP_IOS" | "CUSTOMER_APP_ANDROID" | "CUSTOMER_APP_IOS" | "DISTRIBUTOR_APP" | "FIELD_APP" | "INTERNAL_WEB_APP" | "ISSUER_APP" | "KIOSK_APP" | "MERCHANT_APP" | "SUPPORT_WEB_APP" | "UNKNOWN_APP" | "%future added value";
export type AgentUsersList_agent = {
    readonly principal: {
        readonly id: string;
    };
    readonly agentUsers: ReadonlyArray<{
        readonly isActive: boolean;
        readonly canLogin: boolean;
        readonly user: {
            readonly id: string;
            readonly contactMobile: string;
            readonly name: string;
        };
        readonly agentAppVersion: {
            readonly appType: AppType;
            readonly string: string;
            readonly isUpToDate: boolean;
        } | null;
    }> | null;
    readonly " $refType": "AgentUsersList_agent";
};
export type AgentUsersList_agent$data = AgentUsersList_agent;
export type AgentUsersList_agent$key = {
    readonly " $data"?: AgentUsersList_agent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentUsersList_agent">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentUsersList_agent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "principal",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AgentUser",
      "kind": "LinkedField",
      "name": "agentUsers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canLogin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactMobile",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AppVersion",
          "kind": "LinkedField",
          "name": "agentAppVersion",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "string",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isUpToDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})();
(node as any).hash = '4bc37ba7d86fa79e56a6eae71f5fc7df';
export default node;
