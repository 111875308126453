/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type TicketType = "AgentIssueReport" | "AgentScamReport" | "AmlMatch" | "B2WOrW2BCustomerProblem" | "BankRebalanceRequest" | "BillPayProblem" | "BusinessFundingRequest" | "KYBEscalation" | "MerchantComplianceFollowup" | "MerchantSupportEscalation" | "PaymentCardProblem" | "PoliceRequisition" | "RebalancingDifficulty" | "RefundPartialFunds" | "RefundingDispute" | "RequestToBlockWallet" | "RequestToExplainCommissionCuts" | "RequestToModifyBlockTicket" | "ScamReport" | "ScreeningResult" | "SpamCallerReport" | "SupportGroupLeadEscalation" | "%future added value";
export type RequestToUnblockFromUsingMultipleDevicesMutationVariables = {
    mobile: string;
    forAgent: boolean;
    reason: string;
};
export type RequestToUnblockFromUsingMultipleDevicesMutationResponse = {
    readonly requestToUnblockFromUsingMultipleDevices: {
        readonly result: string;
        readonly wallet: {
            readonly tickets: ReadonlyArray<{
                readonly whenOpened: string;
                readonly whenResolved: string | null;
                readonly status: TicketStatus;
                readonly slackMessageUrl: string | null;
                readonly ticketType: TicketType;
                readonly reference: string | null;
            } | null>;
            readonly user: {
                readonly personalMultipleDevicesBlock: {
                    readonly blockReason: string;
                    readonly blockedBy: string;
                    readonly whenBlocked: string;
                    readonly lockedDevice: {
                        readonly deviceId: string;
                        readonly deviceName: string;
                        readonly deviceModel: string;
                    } | null;
                } | null;
                readonly agentMultipleDevicesBlock: {
                    readonly blockReason: string;
                    readonly blockedBy: string;
                    readonly whenBlocked: string;
                    readonly lockedDevice: {
                        readonly deviceId: string;
                        readonly deviceName: string;
                        readonly deviceModel: string;
                    } | null;
                } | null;
            } | null;
        } | null;
    } | null;
};
export type RequestToUnblockFromUsingMultipleDevicesMutation = {
    readonly response: RequestToUnblockFromUsingMultipleDevicesMutationResponse;
    readonly variables: RequestToUnblockFromUsingMultipleDevicesMutationVariables;
};



/*
mutation RequestToUnblockFromUsingMultipleDevicesMutation(
  $mobile: String!
  $forAgent: Boolean!
  $reason: String!
) {
  requestToUnblockFromUsingMultipleDevices(mobile: $mobile, reason: $reason, forAgent: $forAgent) {
    result
    wallet {
      tickets {
        whenOpened
        whenResolved
        status
        slackMessageUrl
        ticketType
        reference
      }
      user {
        personalMultipleDevicesBlock {
          blockReason
          blockedBy
          whenBlocked
          lockedDevice {
            deviceId
            deviceName
            deviceModel
          }
          id
        }
        agentMultipleDevicesBlock {
          blockReason
          blockedBy
          whenBlocked
          lockedDevice {
            deviceId
            deviceName
            deviceModel
          }
          id
        }
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forAgent"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v3 = [
  {
    "kind": "Variable",
    "name": "forAgent",
    "variableName": "forAgent"
  },
  {
    "kind": "Variable",
    "name": "mobile",
    "variableName": "mobile"
  },
  {
    "kind": "Variable",
    "name": "reason",
    "variableName": "reason"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "result",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketShortInfo",
  "kind": "LinkedField",
  "name": "tickets",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenOpened",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenResolved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackMessageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reference",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "UserDevice",
  "kind": "LinkedField",
  "name": "lockedDevice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deviceModel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestToUnblockFromUsingMultipleDevicesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "RequestToUnblockFromUsingMultipleDevices",
        "kind": "LinkedField",
        "name": "requestToUnblockFromUsingMultipleDevices",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleDevicesBlock",
                    "kind": "LinkedField",
                    "name": "personalMultipleDevicesBlock",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleDevicesBlock",
                    "kind": "LinkedField",
                    "name": "agentMultipleDevicesBlock",
                    "plural": false,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RequestToUnblockFromUsingMultipleDevicesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "RequestToUnblockFromUsingMultipleDevices",
        "kind": "LinkedField",
        "name": "requestToUnblockFromUsingMultipleDevices",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleDevicesBlock",
                    "kind": "LinkedField",
                    "name": "personalMultipleDevicesBlock",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MultipleDevicesBlock",
                    "kind": "LinkedField",
                    "name": "agentMultipleDevicesBlock",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c5140b26a66890d1ae6703f6e5707079",
    "id": null,
    "metadata": {},
    "name": "RequestToUnblockFromUsingMultipleDevicesMutation",
    "operationKind": "mutation",
    "text": "mutation RequestToUnblockFromUsingMultipleDevicesMutation(\n  $mobile: String!\n  $forAgent: Boolean!\n  $reason: String!\n) {\n  requestToUnblockFromUsingMultipleDevices(mobile: $mobile, reason: $reason, forAgent: $forAgent) {\n    result\n    wallet {\n      tickets {\n        whenOpened\n        whenResolved\n        status\n        slackMessageUrl\n        ticketType\n        reference\n      }\n      user {\n        personalMultipleDevicesBlock {\n          blockReason\n          blockedBy\n          whenBlocked\n          lockedDevice {\n            deviceId\n            deviceName\n            deviceModel\n          }\n          id\n        }\n        agentMultipleDevicesBlock {\n          blockReason\n          blockedBy\n          whenBlocked\n          lockedDevice {\n            deviceId\n            deviceName\n            deviceModel\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a89bbb76816444145cc4bbd1ea742e9f';
export default node;
