import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { ReverseFrozenTransactionHistoryActionButton } from "@/pages/mobile.[mobile].personal/ReverseFrozenTransactionHistoryActionButton";
import { UnfreezeTransactionHistoryActionButton } from "@/pages/mobile.[mobile].personal/UnfreezeTransactionHistoryActionButton";

import { ReversalDisputeEntry_tx } from "./__generated__/ReversalDisputeEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";

function _ReversalDisputeEntry(
    props: {
        tx: ReversalDisputeEntry_tx;
    } & HistoryEntryProps
) {
    const { tx } = props;
    const { t } = useTranslation();

    const buttons = tx.isPending ? (
        <>
            <UnfreezeTransactionHistoryActionButton
                transferOrLedgerTransactionId={tx.ledgerTransactionId}
            />
            <ReverseFrozenTransactionHistoryActionButton
                transferOrLedgerTransactionId={tx.ledgerTransactionId}
            />
        </>
    ) : undefined;

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            buttons={buttons}
            title={
                <>
                    <TxTitle>
                        {t("reversal-dispute-entry--transaction-id")}&nbsp;
                        {tx.originalTransferId ? (
                            <a
                                href={`?entry=${tx.originalTransferId}`}
                                className="text-blue-600"
                            >
                                {tx.originalTransferId}
                            </a>
                        ) : (
                            <></>
                        )}
                        &nbsp;{tx.description}
                    </TxTitle>
                    <TxInfo>{tx.__typename}</TxInfo>
                </>
            }
        />
    );
}

export default createFragmentContainer(_ReversalDisputeEntry, {
    tx: graphql`
        fragment ReversalDisputeEntry_tx on ReversalDisputeEntry {
            __typename
            description
            isPending
            ledgerTransactionId
            originalTransferId
            ...HistoryEntry_entry
        }
    `,
});
