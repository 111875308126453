/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AppType = "AGENT_APP" | "BUSINESS_APP" | "BUSINESS_APP_IOS" | "CUSTOMER_APP_ANDROID" | "CUSTOMER_APP_IOS" | "DISTRIBUTOR_APP" | "FIELD_APP" | "INTERNAL_WEB_APP" | "ISSUER_APP" | "KIOSK_APP" | "MERCHANT_APP" | "SUPPORT_WEB_APP" | "UNKNOWN_APP" | "%future added value";
export type AllowToRecoverPin_user = {
    readonly id: string;
    readonly inPinRecovery: boolean;
    readonly isPinResetRisky: boolean;
    readonly customerAppVersion: {
        readonly appType: AppType;
        readonly string: string;
        readonly isUpToDate: boolean;
        readonly appVersionCode: number | null;
    } | null;
    readonly " $refType": "AllowToRecoverPin_user";
};
export type AllowToRecoverPin_user$data = AllowToRecoverPin_user;
export type AllowToRecoverPin_user$key = {
    readonly " $data"?: AllowToRecoverPin_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AllowToRecoverPin_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AllowToRecoverPin_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inPinRecovery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPinResetRisky",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppVersion",
      "kind": "LinkedField",
      "name": "customerAppVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "string",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUpToDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appVersionCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '955d3f01e1fa23878b8e13a53fb4c266';
export default node;
