/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CorrectAtxMutationVariables = {
    transactionId: string;
    errorType: string;
    direction?: string | null | undefined;
    amount?: string | null | undefined;
    description?: string | null | undefined;
};
export type CorrectAtxMutationResponse = {
    readonly correctAtx: {
        readonly success: boolean;
    } | null;
};
export type CorrectAtxMutation = {
    readonly response: CorrectAtxMutationResponse;
    readonly variables: CorrectAtxMutationVariables;
};



/*
mutation CorrectAtxMutation(
  $transactionId: String!
  $errorType: String!
  $direction: String
  $amount: Decimal
  $description: String
) {
  correctAtx(transactionId: $transactionId, errorType: $errorType, direction: $direction, amount: $amount, description: $description) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "direction"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "errorType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "direction",
        "variableName": "direction"
      },
      {
        "kind": "Variable",
        "name": "errorType",
        "variableName": "errorType"
      },
      {
        "kind": "Variable",
        "name": "transactionId",
        "variableName": "transactionId"
      }
    ],
    "concreteType": "CorrectATX",
    "kind": "LinkedField",
    "name": "correctAtx",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CorrectAtxMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CorrectAtxMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "691f3e595607bdfec83d9aa170fb6c8e",
    "id": null,
    "metadata": {},
    "name": "CorrectAtxMutation",
    "operationKind": "mutation",
    "text": "mutation CorrectAtxMutation(\n  $transactionId: String!\n  $errorType: String!\n  $direction: String\n  $amount: Decimal\n  $description: String\n) {\n  correctAtx(transactionId: $transactionId, errorType: $errorType, direction: $direction, amount: $amount, description: $description) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '72f30bce29007f6295e529e42b2e2224';
export default node;
