import { Timestamp } from "../../components/Timestamp";

import i18n from "@/locales/i18n";

import { PersonalTabHeader_wallet } from "@/pages/mobile.[mobile].personal/__generated__/PersonalTabHeader_wallet.graphql";

import { notEmpty } from "@/utils/value";

export type ScamReports = PersonalTabHeader_wallet["scamReports"];

type ScamReportsProps = {
    tickets?: ScamReports;
};

export const ScamReportsHistoryTable = ({ tickets }: ScamReportsProps) => {
    if (tickets == null || tickets.length === 0) {
        return (
            <tr>
                <td colSpan={3} align="center">
                    {i18n.t("no-reports")}
                </td>
            </tr>
        );
    }

    return (
        <>
            {tickets.filter(notEmpty).map((ticket, i) => {
                const whenOpened = (
                    <Timestamp value={new Date(ticket.whenOpened)} />
                );

                return (
                    <tr key={i}>
                        <td>{whenOpened}</td>
                        <td>{ticket.status}</td>
                        <td>{ticket.transactionIds}</td>
                    </tr>
                );
            })}
        </>
    );
};
