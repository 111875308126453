import { createContext, useEffect, useState } from "react";

import { Flex } from "@/external/flex";
import { logWebsocketError } from "@/external/sentry";

import { LIVE_EVENTS_WS_URL } from "@/utils/environment";
import { buildUrlString } from "@/utils/url";

export const WebSocketContext = createContext<WebSocket | undefined>(undefined);

interface IWebSocketProvider {
    channel: string;
    children: React.ReactChild;
}

export const WebSocketProvider = (props: IWebSocketProvider) => {
    const [ws, setWs] = useState<WebSocket | undefined>(undefined);
    const [waitingToReconnect, setWaitingToReconnect] = useState(false);

    useEffect(() => {
        if (Flex.isEmbedded) {
            return;
        }
        if (waitingToReconnect) {
            return;
        }

        const url = buildUrlString(
            LIVE_EVENTS_WS_URL,
            "subscribe",
            props.channel
        );
        const webSocket = new WebSocket(url);

        webSocket.onclose = (event) => {
            if (waitingToReconnect) {
                return;
            }

            if (event.code !== 1000) {
                if (event.code !== 1006) {
                    logWebsocketError(event.code, event.reason, url);
                }
                setWaitingToReconnect(true);
                setTimeout(() => {
                    setWaitingToReconnect(false);
                }, 1000);
            }
        };

        setWs(webSocket);

        return () => {
            if (webSocket) webSocket.close(1000, "clean");
            setWs(undefined);
        };
    }, [props.channel, waitingToReconnect]);

    return (
        <WebSocketContext.Provider value={ws}>
            {props.children}
        </WebSocketContext.Provider>
    );
};
