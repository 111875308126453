import { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RelayRefetchProp, createRefetchContainer, graphql } from "react-relay";
import { Variables } from "relay-runtime";

import InfiniteScroll from "@/components/InfiniteScroll";

import { asyncRefetch } from "@/external/relay";

import AgentHistorySummary from "@/pages/mobile.[mobile].agent/AgentHistorySummary";

import { M } from "@/utils/currency";

import { AgentHistory_agent } from "./__generated__/AgentHistory_agent.graphql";

import AgentHistoryEntryDelegate from "./AgentHistoryEntryDelegate";

interface Props {
    agent: AgentHistory_agent;
    relay: RelayRefetchProp;
    jumpTo?: string;
    showCancelledAtx: boolean;
}

function _AgentHistory({ agent, relay, jumpTo, showCancelledAtx }: Props) {
    const { t } = useTranslation();
    const { id: agentId, history } = agent;

    const historyChildren: ReactElement[] = [];

    const loadMore = useCallback(
        async (variables: Variables) => {
            // 'jumpTo' has to be kept, otherwise Relay thinks we start a new pagination
            const refetchVariables = { ...variables, agentId, around: jumpTo };

            // Fetch all locally stored entries for rendering
            const renderVariables = { agentId, first: Number.MAX_SAFE_INTEGER };

            await asyncRefetch(relay, refetchVariables, renderVariables);
        },
        [relay, agentId, jumpTo]
    );
    if (history == null) return <div>{t("agent-history--error")}</div>;

    let focusedEntry: ReactElement | undefined = undefined;
    for (const edge of history.edges.slice().reverse()) {
        if (edge == null) continue;

        const dailySummaries = edge.dailySummaries || [];
        for (let i = dailySummaries.length - 1; i >= 0; i--) {
            const summary = dailySummaries[i];
            if (summary == null) continue;
            // all daily summaries except the last (least recent) one are for
            // days w/ no transactions. If commission is also zero, skip them.
            if (i !== 0 && M.fromSerialized(summary.totalCommission).isZero())
                continue;

            historyChildren.push(
                <AgentHistorySummary key={summary.date} summary={summary} />
            );
        }

        const { node } = edge;

        if (node == null || (node.isCancelled && !showCancelledAtx)) continue;
        const isNodeFocussed = (() => {
            if (!jumpTo) return false;

            if (node.agentTransactionId === jumpTo) return true;

            if (!node.composingEntries) return false;

            return node.composingEntries
                .map((e) => e.agentTransactionId)
                .includes(jumpTo);
        })();

        const entry = (
            <AgentHistoryEntryDelegate
                key={node.id}
                entry={node}
                subTransactionCount={(node.composingEntries || []).length}
                isFocussed={isNodeFocussed}
            />
        );
        /* I need to hook in here and get the focus as well as the open tx */
        if (isNodeFocussed) focusedEntry = entry;

        historyChildren.push(entry);
    }

    const { pageInfo } = history;
    const { hasNextPage, endCursor, startCursor, hasPreviousPage } = pageInfo;

    return (
        <InfiniteScroll
            hasMoreAtStart={hasNextPage}
            loadAtStart={() => loadMore({ after: endCursor, first: 20 })}
            hasMoreAtEnd={hasPreviousPage}
            loadAtEnd={() => loadMore({ before: startCursor, last: 20 })}
            jumpTo={focusedEntry}
        >
            {historyChildren}
        </InfiniteScroll>
    );
}

export const AgentHistory = createRefetchContainer(
    _AgentHistory,
    {
        agent: graphql`
            fragment AgentHistory_agent on Agent
            @argumentDefinitions(
                first: { type: "Int" }
                after: { type: "DateTime" }
                last: { type: "Int" }
                before: { type: "DateTime" }
                around: { type: "ID" }
                when: { type: "DateTime" }
                includeCancelled: { type: "Boolean" }
            ) {
                id
                history(
                    first: $first
                    after: $after
                    last: $last
                    before: $before
                    around: $around
                    when: $when
                    includePending: true
                    includeCancelled: $includeCancelled
                ) @connection(key: "AgentHistory_history") {
                    edges {
                        dailySummaries {
                            whenDayEnded
                            date
                            totalCommission
                            ...AgentHistorySummary_summary
                        }
                        node {
                            id
                            whenEntered
                            isCancelled
                            ... on AgentTransactionEntry {
                                agentTransactionId
                                composingEntries {
                                    ... on AgentTransactionEntry {
                                        agentTransactionId
                                    }
                                }
                            }
                            ...AgentHistoryEntryDelegate_entry
                        }
                    }
                    pageInfo {
                        hasPreviousPage
                        hasNextPage
                        startCursor
                        endCursor
                    }
                }
            }
        `,
    },
    graphql`
        query AgentHistoryRefetchQuery(
            $agentId: ID!
            $first: Int
            $after: DateTime
            $last: Int
            $before: DateTime
            $around: ID
            $when: DateTime
            $includeCancelled: Boolean
        ) {
            node(id: $agentId) {
                ... on Agent {
                    ...AgentHistory_agent
                        @arguments(
                            first: $first
                            after: $after
                            last: $last
                            before: $before
                            around: $around
                            when: $when
                            includeCancelled: $includeCancelled
                        )
                }
            }
        }
    `
);
