/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ShowWithdrawalCodes_wallet = {
    readonly id: string;
    readonly name: string;
    readonly mobile: string;
    readonly " $fragmentRefs": FragmentRefs<"SendWithdrawalCode_wallet">;
    readonly " $refType": "ShowWithdrawalCodes_wallet";
};
export type ShowWithdrawalCodes_wallet$data = ShowWithdrawalCodes_wallet;
export type ShowWithdrawalCodes_wallet$key = {
    readonly " $data"?: ShowWithdrawalCodes_wallet$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ShowWithdrawalCodes_wallet">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowWithdrawalCodes_wallet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendWithdrawalCode_wallet"
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
(node as any).hash = 'a783c802d201ff864257eeb9da708ad3';
export default node;
