/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type TicketType = "AgentIssueReport" | "AgentScamReport" | "AmlMatch" | "B2WOrW2BCustomerProblem" | "BankRebalanceRequest" | "BillPayProblem" | "BusinessFundingRequest" | "KYBEscalation" | "MerchantComplianceFollowup" | "MerchantSupportEscalation" | "PaymentCardProblem" | "PoliceRequisition" | "RebalancingDifficulty" | "RefundPartialFunds" | "RefundingDispute" | "RequestToBlockWallet" | "RequestToExplainCommissionCuts" | "RequestToModifyBlockTicket" | "ScamReport" | "ScreeningResult" | "SpamCallerReport" | "SupportGroupLeadEscalation" | "%future added value";
export type RequestToBlockUnblockUserMutationVariables = {
    unblock: boolean;
    mobile: string;
    reason: string;
};
export type RequestToBlockUnblockUserMutationResponse = {
    readonly requestToBlockUser?: {
        readonly result: string;
        readonly wallet: {
            readonly tickets: ReadonlyArray<{
                readonly whenOpened: string;
                readonly whenResolved: string | null;
                readonly status: TicketStatus;
                readonly slackMessageUrl: string | null;
                readonly ticketType: TicketType;
                readonly reference: string | null;
            } | null>;
        } | null;
        readonly unregisteredMobile: {
            readonly tickets: ReadonlyArray<{
                readonly whenOpened: string;
                readonly whenResolved: string | null;
                readonly status: TicketStatus;
                readonly slackMessageUrl: string | null;
                readonly ticketType: TicketType;
                readonly reference: string | null;
            } | null>;
        } | null;
    } | null | undefined;
    readonly requestToUnblockUser?: {
        readonly result: string;
        readonly wallet: {
            readonly tickets: ReadonlyArray<{
                readonly whenOpened: string;
                readonly whenResolved: string | null;
                readonly status: TicketStatus;
                readonly slackMessageUrl: string | null;
                readonly ticketType: TicketType;
                readonly reference: string | null;
            } | null>;
        } | null;
        readonly unregisteredMobile: {
            readonly tickets: ReadonlyArray<{
                readonly whenOpened: string;
                readonly whenResolved: string | null;
                readonly status: TicketStatus;
                readonly slackMessageUrl: string | null;
                readonly ticketType: TicketType;
                readonly reference: string | null;
            } | null>;
        } | null;
    } | null | undefined;
};
export type RequestToBlockUnblockUserMutation = {
    readonly response: RequestToBlockUnblockUserMutationResponse;
    readonly variables: RequestToBlockUnblockUserMutationVariables;
};



/*
mutation RequestToBlockUnblockUserMutation(
  $unblock: Boolean!
  $mobile: String!
  $reason: String!
) {
  requestToBlockUser(mobile: $mobile, reason: $reason) @skip(if: $unblock) {
    result
    wallet {
      tickets {
        whenOpened
        whenResolved
        status
        slackMessageUrl
        ticketType
        reference
      }
      id
    }
    unregisteredMobile {
      tickets {
        whenOpened
        whenResolved
        status
        slackMessageUrl
        ticketType
        reference
      }
      id
    }
  }
  requestToUnblockUser(mobile: $mobile, reason: $reason) @include(if: $unblock) {
    result
    wallet {
      tickets {
        whenOpened
        whenResolved
        status
        slackMessageUrl
        ticketType
        reference
      }
      id
    }
    unregisteredMobile {
      tickets {
        whenOpened
        whenResolved
        status
        slackMessageUrl
        ticketType
        reference
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unblock"
},
v3 = [
  {
    "kind": "Variable",
    "name": "mobile",
    "variableName": "mobile"
  },
  {
    "kind": "Variable",
    "name": "reason",
    "variableName": "reason"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "result",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketShortInfo",
  "kind": "LinkedField",
  "name": "tickets",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenOpened",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenResolved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackMessageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reference",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Wallet",
    "kind": "LinkedField",
    "name": "wallet",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UnregisteredMobile",
    "kind": "LinkedField",
    "name": "unregisteredMobile",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
],
v8 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v9 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Wallet",
    "kind": "LinkedField",
    "name": "wallet",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UnregisteredMobile",
    "kind": "LinkedField",
    "name": "unregisteredMobile",
    "plural": false,
    "selections": (v8/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestToBlockUnblockUserMutation",
    "selections": [
      {
        "condition": "unblock",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RequestToBlockUser",
            "kind": "LinkedField",
            "name": "requestToBlockUser",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "unblock",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RequestToUnblockUser",
            "kind": "LinkedField",
            "name": "requestToUnblockUser",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RequestToBlockUnblockUserMutation",
    "selections": [
      {
        "condition": "unblock",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RequestToBlockUser",
            "kind": "LinkedField",
            "name": "requestToBlockUser",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "unblock",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RequestToUnblockUser",
            "kind": "LinkedField",
            "name": "requestToUnblockUser",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "4fe197a10b9cfd96e9ed3d6762a4b6ec",
    "id": null,
    "metadata": {},
    "name": "RequestToBlockUnblockUserMutation",
    "operationKind": "mutation",
    "text": "mutation RequestToBlockUnblockUserMutation(\n  $unblock: Boolean!\n  $mobile: String!\n  $reason: String!\n) {\n  requestToBlockUser(mobile: $mobile, reason: $reason) @skip(if: $unblock) {\n    result\n    wallet {\n      tickets {\n        whenOpened\n        whenResolved\n        status\n        slackMessageUrl\n        ticketType\n        reference\n      }\n      id\n    }\n    unregisteredMobile {\n      tickets {\n        whenOpened\n        whenResolved\n        status\n        slackMessageUrl\n        ticketType\n        reference\n      }\n      id\n    }\n  }\n  requestToUnblockUser(mobile: $mobile, reason: $reason) @include(if: $unblock) {\n    result\n    wallet {\n      tickets {\n        whenOpened\n        whenResolved\n        status\n        slackMessageUrl\n        ticketType\n        reference\n      }\n      id\n    }\n    unregisteredMobile {\n      tickets {\n        whenOpened\n        whenResolved\n        status\n        slackMessageUrl\n        ticketType\n        reference\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7d73fea7ae929cd67aa28f37f41f0f5b';
export default node;
