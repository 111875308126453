import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ActionButton } from "../../components/ActionButton";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { LoadingIcon } from "@/components/Loading";
import { Timestamp } from "@/components/Timestamp";

import { usePermissions } from "@/hooks/usePermissions";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";

import { ShowWithdrawalCodesMutationResponse } from "./__generated__/ShowWithdrawalCodesMutation.graphql";
import { ShowWithdrawalCodes_wallet } from "./__generated__/ShowWithdrawalCodes_wallet.graphql";

import { SendWithdrawalCodeMutator } from "./SendWithdrawalCode";

const mutation = graphql`
    mutation ShowWithdrawalCodesMutation($walletId: ID!) {
        showWithdrawalCodes(walletId: $walletId) {
            agentTransactions {
                id
                amount
                isDeposit
                whenCreated
                code {
                    code
                }
            }
        }
    }
`;

type Withdrawal = {
    id: string;
    whenCreated: Date;
    amount: M | null; // null if full balance
    code: string;
};

type ShowWithdrawalCodesInputParams = {
    wallet: ShowWithdrawalCodes_wallet;
};

const hyphenateWithdrawalCode = (code: string) => {
    return `${code.substring(0, 4)} - ${code.substring(4)}`;
};

const _ShowWithdrawalCodesMutator = (
    props: ShowWithdrawalCodesInputParams & RelayModalProps
) => {
    const { wallet } = props;

    const { t } = useTranslation();

    const sendWithdrawalCodeAllowed = usePermissions(
        "supportapp_send_withdrawal_code"
    );

    const onMutationSuccess = useCallback(
        (response: ShowWithdrawalCodesMutationResponse): Withdrawal[] => {
            if (
                !response.showWithdrawalCodes ||
                _.isNil(response.showWithdrawalCodes.agentTransactions)
            )
                return [];

            const result: Withdrawal[] = [];
            for (const wd of response.showWithdrawalCodes.agentTransactions) {
                // TODO(paul) look at the backend code to figure out if we need the careful nil comparisons
                if (_.isNil(wd)) continue;
                const { code } = wd;
                if (_.isNil(code)) continue;
                result.push({
                    id: wd.id,
                    whenCreated: new Date(wd.whenCreated),
                    code: hyphenateWithdrawalCode(code.code),
                    amount: wd.amount ? M.fromSerialized(wd.amount) : null,
                });
            }
            return result;
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ShowWithdrawalCode,
            data: { mobile: wallet.mobile },
        },
        ...props,
    });

    useEffect(() => {
        if (props.show) {
            mutator.submit(mutation, { walletId: wallet.id });
        }
    }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

    let contentsNode: React.ReactNode = null;
    if (mutator.isWorking) {
        contentsNode = (
            <>
                <LoadingIcon /> {t("wd-code-show-fetching")}
            </>
        );
    }
    if (mutator.errors && mutator.errors.length > 0) {
        contentsNode = (
            <>
                <h2>{t("wd-code-show-error--title")}</h2>
                <p>{t("wd-code-show-error--body")}</p>
            </>
        );
    } else if (!mutator.result?.length) {
        contentsNode = (
            <>
                <h2>{t("wd-code-show-none--title")}</h2>
                <p>{t("wd-code-show-none--body")}</p>
            </>
        );
    } else {
        contentsNode = (
            <Table>
                <thead>
                    <tr>
                        <th>{t("wd-code-show-table--when-created")}</th>
                        <th>{t("wd-code-show-table--amount")}</th>
                        <th>{t("wd-code-show-table--code")}</th>
                        <th>{t("wd-code-show-table--send-code")}</th>
                    </tr>
                </thead>
                <tbody>
                    {mutator.result.map((wd) => (
                        <tr key={wd.id}>
                            <td>
                                <Timestamp value={wd.whenCreated} />
                            </td>
                            <td>
                                {wd.amount
                                    ? wd.amount.toString()
                                    : t("amount--full-balance")}
                            </td>
                            <td>{wd.code}</td>
                            <td>
                                <ActionButton
                                    name={t("action-send")}
                                    disabled={!sendWithdrawalCodeAllowed}
                                >
                                    <SendWithdrawalCodeMutator
                                        wallet={wallet}
                                        withdrawalCode={wd.code}
                                    />
                                </ActionButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    return (
        <MutatorModal
            {...mutator}
            showErrors
            title={t("wd-code-show", { name: wallet.name })}
        >
            {contentsNode}
        </MutatorModal>
    );
};

export const ShowWithdrawalCodesMutator = createFragmentContainer(
    _ShowWithdrawalCodesMutator,
    {
        wallet: graphql`
            fragment ShowWithdrawalCodes_wallet on Wallet {
                id
                name
                mobile
                ...SendWithdrawalCode_wallet
            }
        `,
    }
);
