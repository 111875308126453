import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { TerminateUserMutator } from "@/pages/mobile.[mobile].personal/TerminateUser";

export const TerminateUserAction = (props: { mobile: string }) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("terminate-user--title")}
            requiredPermissions={["supportapp_terminate_user"]}
        >
            <TerminateUserMutator mobile={props.mobile} />
        </ActionButton>
    );
};
