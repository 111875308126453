/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TransferCallFraudMutationVariables = {};
export type TransferCallFraudMutationResponse = {
    readonly transferCallFraud: {
        readonly result: string;
    } | null;
};
export type TransferCallFraudMutation = {
    readonly response: TransferCallFraudMutationResponse;
    readonly variables: TransferCallFraudMutationVariables;
};



/*
mutation TransferCallFraudMutation {
  transferCallFraud {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TransferCallFraud",
    "kind": "LinkedField",
    "name": "transferCallFraud",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TransferCallFraudMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TransferCallFraudMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bf3d9a55a2a79f69a73ace58a25896d6",
    "id": null,
    "metadata": {},
    "name": "TransferCallFraudMutation",
    "operationKind": "mutation",
    "text": "mutation TransferCallFraudMutation {\n  transferCallFraud {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fc4f778bade110d7d9c9cbf0391c5a96';
export default node;
