import * as Sentry from "@sentry/react";

interface Wallet {
    readonly supportHistoryConnection?: {
        readonly edges: readonly unknown[] | null;
    } | null;
    [index: string]: unknown;
}

/**
 * If the history connection of a wallet is completely absent, it might be a bug in the backend.
 * Therefore, we log these cases to Sentry.
 * @param wallet
 */
export function logEmptyHistoryConnectionToSentry(wallet: Wallet) {
    if (
        !wallet.supportHistoryConnection ||
        !wallet.supportHistoryConnection.edges
    ) {
        Sentry.captureMessage(
            "(Support)HistoryConnection on wallet is not present",
            (scope) => {
                scope.setContext("wallet", wallet);
                return scope;
            }
        );
    }
}

export function logWebsocketError(code: number, reason: string, url: string) {
    Sentry.captureMessage("Websocket error occurred", (scope) => {
        scope.setContext("websocket error", { code, reason, url });
        return scope;
    });
}

export function logErrorMessage(message: string) {
    Sentry.captureMessage(message);
}
