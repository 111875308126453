import React, { useCallback, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { LoadingIcon } from "../../components/Loading";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { LogoutAllUserSessionsMutationResponse } from "./__generated__/LogoutAllUserSessionsMutation.graphql";
import { LogoutAllUserSessions_user } from "./__generated__/LogoutAllUserSessions_user.graphql";

const mutation = graphql`
    mutation LogoutAllUserSessionsMutation($userId: ID!) {
        logoutAllUserSessions(userId: $userId) {
            success
        }
    }
`;

type LogoutAllUserSessionsMutatorInputParams = {
    user: LogoutAllUserSessions_user;
};

const _LogoutMutator = (
    props: LogoutAllUserSessionsMutatorInputParams & RelayModalProps
) => {
    const { user } = props;
    const userId = user.id;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: LogoutAllUserSessionsMutationResponse): boolean => {
            return response.logoutAllUserSessions?.success || false;
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.LogoutAllUserSessions,
            data: { userId },
        },
        ...props,
    });

    useEffect(() => {
        if (props.show) {
            mutator.submit(mutation, { userId });
        }
    }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

    let contentsNode: React.ReactNode = null;
    if (mutator.isWorking) {
        contentsNode = (
            <>
                <LoadingIcon /> {t("logout-user--loading")}
            </>
        );
    } else if (mutator.errors && mutator.errors.length > 0) {
        contentsNode = (
            <>
                <h2>{t("logout-user--error--title")}</h2>
                <p>{t("logout-user--error--description")}</p>
            </>
        );
    } else {
        contentsNode = (
            <Alert variant="success">
                <h1>{t("logout-user--success")}</h1>
            </Alert>
        );
    }
    return (
        <MutatorModal {...mutator} showErrors title={t("logout-user--title")}>
            {contentsNode}
        </MutatorModal>
    );
};

export const LogoutAllUserSessionsMutator = createFragmentContainer(
    _LogoutMutator,
    {
        user: graphql`
            fragment LogoutAllUserSessions_user on User {
                id
            }
        `,
    }
);
