import * as amplitude from "@amplitude/analytics-browser";

import { IS_DEV, VERSION_NAME } from "../utils/environment";

/**
 * Data to be tracked alongside analytics events - i.e. event properties.
 */
export type AnalyticsData = {
    [key: string]: object | string | number | boolean | undefined | null;
};

/**
 * Static class used to track any events in our analytics platform. {@link init} has to
 * be called first, before any other methods in the class. This class keeps track of
 * any number of properties (of type {@link AnalyticsData} in an internal object we call
 * `context`.
 */
export class Analytics {
    private static context: any = {};

    /**
     * Initialises Amplitude, the underlying service we use for analytics.
     * @param key Amplitude key for this project.
     */
    static init(key: string) {
        amplitude.init(key, "", { appVersion: VERSION_NAME });
    }

    /**
     * Set the user id that will be associated with any events being tracked. This usually
     * is the user's email.
     * @see clear
     * @param id
     */
    static identify(id: string) {
        amplitude.setUserId(id);
    }

    /**
     * Set the user's selected country. This is used to then target feature flags based
     * on the selected country
     * @param country ISO2 country code (eg. "sn")
     */
    static setCountry(country: string, callback?: () => void) {
        const identity = new amplitude.Identify().set(
            "selected_country",
            country
        );
        amplitude.identify(identity).promise.then(callback);

        if (IS_DEV) {
            // eslint-disable-next-line no-console
            console.log("Analytics set country:", "selected_country", country);
        }
    }

    /**
     * Tracks an event, alongside any properties you may pass here _**and**_ properties
     * present in the context object.
     * @param key the name of the event to track - example "logout clicked"
     * @param data an optional property, or properties, ({@link AnalyticsData}) to be
     * tracked alongside this event and any other properties that might be in the
     * context object.
     */
    static track(key: string, data?: AnalyticsData) {
        const contextWithData = this.getMergedContextWith(data);
        amplitude.track(key, contextWithData);

        if (IS_DEV) {
            // eslint-disable-next-line no-console
            console.log("Analytics track:", key, contextWithData);
        }
    }

    /**
     * Merges the current static context object with the data object passed as parameter
     * and the context in local storage and returns that. The context remains unchanged.
     * @param data an object
     */
    private static getMergedContextWith(data?: AnalyticsData) {
        const merged = JSON.parse(JSON.stringify(Analytics.context));

        if (data) {
            for (const [key, value] of Object.entries(data)) {
                merged[key] = value;
            }
        }

        return merged;
    }

    /**
     * Adds an item to the context.
     * @see AnalyticsData
     * @see removeFromContext
     * @param context the new bit of {@link AnalyticsData} that you want to have in the
     * analytics context.
     */
    static addContext(context: AnalyticsData) {
        Analytics.context = this.getMergedContextWith(context);
        if (IS_DEV) {
            // eslint-disable-next-line no-console
            console.log("Analytics addContext:", context);
        }
    }

    /**
     * Removes an item from the analytics context object.
     * @see addContext
     * @param key the name of the item to remove from the analytics context.
     */
    static removeFromContext(key: string) {
        delete Analytics.context[key];
        if (IS_DEV) {
            // eslint-disable-next-line no-console
            console.log("Analytics removeFromContext:", key);
        }
    }

    /**
     * Clears the context object, removing all values within.
     */
    static clearContext() {
        Analytics.context = {};
        if (IS_DEV) {
            // eslint-disable-next-line no-console
            console.log("Analytics clearContext");
        }
    }

    /**
     * Clear the analytics session, clearing the user's identification and the context
     * object. If any events are tracked after, they should not be associated with any
     * particular user id.
     * @see clearContext
     */
    static clear() {
        amplitude.setUserId(undefined);
        this.clearContext();
    }
}
