/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BlockSubject = "DEFAULT" | "DEVICE" | "LEGAL_ENTITY" | "MOBILE" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type BlockUnblockUser_unregisteredMobile = {
    readonly mobile: string;
    readonly activeBlock: {
        readonly id: string;
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
        readonly whenExpires: string | null;
        readonly requestToModifyBlockTickets: ReadonlyArray<{
            readonly status: TicketStatus;
            readonly whenOpened: string;
        }> | null;
    } | null;
    readonly blockHistory: ReadonlyArray<{
        readonly whenBlocked: string;
        readonly blockedBy: string;
        readonly blockReason: string;
        readonly whenUnblocked: string | null;
        readonly unblockedBy: string | null;
        readonly unblockReason: string | null;
        readonly blockSubject: BlockSubject | null;
    }>;
    readonly " $refType": "BlockUnblockUser_unregisteredMobile";
};
export type BlockUnblockUser_unregisteredMobile$data = BlockUnblockUser_unregisteredMobile;
export type BlockUnblockUser_unregisteredMobile$key = {
    readonly " $data"?: BlockUnblockUser_unregisteredMobile$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BlockUnblockUser_unregisteredMobile">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockSubject",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlockUnblockUser_unregisteredMobile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeBlock",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenExpires",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TicketShortInfo",
          "kind": "LinkedField",
          "name": "requestToModifyBlockTickets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "whenOpened",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "blockHistory",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenUnblocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockReason",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UnregisteredMobile",
  "abstractKey": null
};
})();
(node as any).hash = 'b8af7763b7588f658ed94e8620942a54';
export default node;
