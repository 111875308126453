import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";
import { TextField, useTextField } from "@/components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { ReportPaymentCardTransferProblemMutationResponse } from "./__generated__/ReportPaymentCardTransferProblemMutation.graphql";

const mutation = graphql`
    mutation ReportPaymentCardTransferProblemMutation(
        $transactionId: ID!
        $comment: String!
    ) {
        reportPaymentCardTransferProblem(
            transactionId: $transactionId
            comment: $comment
        ) {
            result
        }
    }
`;

type ReportPaymentCardTransferProblemInputParams = {
    transactionId?: string;
    comment?: string;
};

const _ReportPaymentCardTransferProblemMutator = (
    props: ReportPaymentCardTransferProblemInputParams & RelayModalProps
) => {
    const { onHide, transactionId, comment } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ReportPaymentCardTransferProblemMutationResponse) => {
            return response.reportPaymentCardTransferProblem?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportPaymentCardTransferProblem,
            data: { transactionId },
        },
        ...props,
    });

    const transactionIdField = useTextField();
    const additionalInfoField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            transactionId: transactionIdField.value,
            comment: additionalInfoField.value,
        });
    }, [mutator, transactionIdField.value, additionalInfoField.value]);

    const commentValidation = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!additionalInfoField.value) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-comments"),
            });
        }
        return validations;
    }, [additionalInfoField.value, t]);

    const isValid = useMemo(() => {
        return !hasError([commentValidation]);
    }, [commentValidation]);

    return (
        <MutatorModal
            {...mutator}
            title={t("report-payment-card-transfer--title")}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...transactionIdField}
                    label={t("label-transaction-id")}
                    name="transactionId"
                    validations={[]}
                    defaultValue={transactionId}
                    disabled={true}
                />

                <TextField
                    {...additionalInfoField}
                    label={t("label-comment")}
                    name="comment"
                    validations={commentValidation}
                    defaultValue={comment}
                    as="textarea"
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};
export const ReportPaymentCardTransferProblemMutator = createFragmentContainer(
    _ReportPaymentCardTransferProblemMutator,
    {}
);
