export type SpecialPageType = "blank" | "no-current-call";
export type TabName = "personal" | "agent" | "merchant" | "notes";
export type GoToOptions = {
    clearLocationSearch?: boolean;
};
const TabNames: TabName[] = ["personal", "agent", "merchant", "notes"];

export function isTabName(name: any): name is TabName {
    return TabNames.includes(name);
}

/**
 * Returns the value of the tab based on the current window location pathname.
 */
export function getTabFromWindowLocation(): TabName | undefined {
    const tab = window.location.pathname.split("/")[3];
    if (isTabName(tab)) {
        return tab as TabName;
    }
}

/**
 * Returns the mobile number of the currently selected profile, or undefined
 */
export function getMobileFromWindowLocation(): string | undefined {
    const match = window.location.pathname.match(/\+[0-9]+/);
    if (match) {
        return match[0];
    }
}
export const OPAQUE_ID_QUERY_PARAM_NAME = "entry";
export const WHEN_QUERY_PARAM_NAME = "when";
export const DEVICE_ID_FILTER_QUERY_PARAM_NAME = "device_id";

/**
 * This function converts the profile page state into URL and should not be used
 * directly. Use `useNavigation()` hook or `<NavigationLink>` component instead.
 */
export const getAddressForSpecialPage = (
    pageType: SpecialPageType,
    queryString: string
) => {
    return (
        (pageType == "blank" ? "/" : `/specialpage/${pageType}`) +
        (queryString || "")
    );
};

/**
 * This function converts the profile page state into URL and should not be used
 * directly. Use `useNavigation()` hook or `<NavigationLink>` component instead.
 */
export const getAddressForProfileByMobile = (
    mobile: string,
    queryString: string,
    tab?: TabName
) => {
    const tabSuffix = tab ? `/${tab}` : "";
    let query = queryString;
    if (queryString !== "" && !queryString.startsWith("?")) {
        query = `?${queryString}`;
    }
    return `/mobile/${mobile}${tabSuffix}${query}`;
};

/**
 * This function converts the profile page state into URL and should not be used
 * directly. Use `useNavigation()` hook or `<NavigationLink>` component instead.
 */
export const getAddressForProfileById = (
    id: string,
    queryString: string,
    tab?: TabName
) => {
    const tabSuffix = tab ? `/${tab}` : "";
    const querySuffix =
        queryString && !queryString.startsWith("?")
            ? `?${queryString}`
            : queryString;
    return `/id/${id}${tabSuffix}${querySuffix || ""}`;
};
