/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RepLoginLogoutWidgetMutationVariables = {
    logIn: boolean;
};
export type RepLoginLogoutWidgetMutationResponse = {
    readonly supportRepLoginLogout: {
        readonly supportRep: {
            readonly id: string;
            readonly isLoggedInToCallcenterQueue: boolean | null;
        };
    } | null;
};
export type RepLoginLogoutWidgetMutation = {
    readonly response: RepLoginLogoutWidgetMutationResponse;
    readonly variables: RepLoginLogoutWidgetMutationVariables;
};



/*
mutation RepLoginLogoutWidgetMutation(
  $logIn: Boolean!
) {
  supportRepLoginLogout(logIn: $logIn) {
    supportRep {
      id
      isLoggedInToCallcenterQueue
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "logIn"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "logIn",
        "variableName": "logIn"
      }
    ],
    "concreteType": "SupportRepLoginLogout",
    "kind": "LinkedField",
    "name": "supportRepLoginLogout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SupportAppUser",
        "kind": "LinkedField",
        "name": "supportRep",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLoggedInToCallcenterQueue",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RepLoginLogoutWidgetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RepLoginLogoutWidgetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ebfc6a92badd7cdbb4dd0c87a63b9c34",
    "id": null,
    "metadata": {},
    "name": "RepLoginLogoutWidgetMutation",
    "operationKind": "mutation",
    "text": "mutation RepLoginLogoutWidgetMutation(\n  $logIn: Boolean!\n) {\n  supportRepLoginLogout(logIn: $logIn) {\n    supportRep {\n      id\n      isLoggedInToCallcenterQueue\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '950e4a4017f5ce7073c9e03a551cec7c';
export default node;
