/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SendWithdrawalCode_wallet = {
    readonly id: string;
    readonly mobile: string;
    readonly name: string;
    readonly " $refType": "SendWithdrawalCode_wallet";
};
export type SendWithdrawalCode_wallet$data = SendWithdrawalCode_wallet;
export type SendWithdrawalCode_wallet$key = {
    readonly " $data"?: SendWithdrawalCode_wallet$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SendWithdrawalCode_wallet">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendWithdrawalCode_wallet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
(node as any).hash = '32e9d093409115a8703c9f0452681b1d';
export default node;
