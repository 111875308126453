/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnblockFromAgentDepositsMutationVariables = {
    userId: string;
    reason: string;
};
export type UnblockFromAgentDepositsMutationResponse = {
    readonly unblockFromAgentDeposits: {
        readonly user: {
            readonly " $fragmentRefs": FragmentRefs<"UnblockFromAgentDeposits_user">;
        };
    } | null;
};
export type UnblockFromAgentDepositsMutation = {
    readonly response: UnblockFromAgentDepositsMutationResponse;
    readonly variables: UnblockFromAgentDepositsMutationVariables;
};



/*
mutation UnblockFromAgentDepositsMutation(
  $userId: ID!
  $reason: String!
) {
  unblockFromAgentDeposits(userId: $userId, reason: $reason) {
    user {
      ...UnblockFromAgentDeposits_user
      id
    }
  }
}

fragment AgentDepositsBlockHistory_user on User {
  id
  historyOfAgentDepositsUnblocks {
    whenBlocked
    whenUnblocked
    blockedBy
    blockReason
    blockSubject
    unblockReason
    unblockedBy
  }
}

fragment UnblockFromAgentDeposits_user on User {
  id
  agentDepositsBlock {
    whenBlocked
    blockedBy
    blockReason
    id
  }
  ...AgentDepositsBlockHistory_user
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "reason",
    "variableName": "reason"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnblockFromAgentDepositsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnblockFromAgentDeposits",
        "kind": "LinkedField",
        "name": "unblockFromAgentDeposits",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UnblockFromAgentDeposits_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnblockFromAgentDepositsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UnblockFromAgentDeposits",
        "kind": "LinkedField",
        "name": "unblockFromAgentDeposits",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ActiveBlock",
                "kind": "LinkedField",
                "name": "agentDepositsBlock",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Block",
                "kind": "LinkedField",
                "name": "historyOfAgentDepositsUnblocks",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenUnblocked",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "blockSubject",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unblockReason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unblockedBy",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "464a6e72a620d7d57bbe6b4cc70039f2",
    "id": null,
    "metadata": {},
    "name": "UnblockFromAgentDepositsMutation",
    "operationKind": "mutation",
    "text": "mutation UnblockFromAgentDepositsMutation(\n  $userId: ID!\n  $reason: String!\n) {\n  unblockFromAgentDeposits(userId: $userId, reason: $reason) {\n    user {\n      ...UnblockFromAgentDeposits_user\n      id\n    }\n  }\n}\n\nfragment AgentDepositsBlockHistory_user on User {\n  id\n  historyOfAgentDepositsUnblocks {\n    whenBlocked\n    whenUnblocked\n    blockedBy\n    blockReason\n    blockSubject\n    unblockReason\n    unblockedBy\n  }\n}\n\nfragment UnblockFromAgentDeposits_user on User {\n  id\n  agentDepositsBlock {\n    whenBlocked\n    blockedBy\n    blockReason\n    id\n  }\n  ...AgentDepositsBlockHistory_user\n}\n"
  }
};
})();
(node as any).hash = 'e90370370c5dabeff1bda15df3b95e99';
export default node;
