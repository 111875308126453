import { useCallback, useContext, useEffect, useState } from "react";

import { LiveMetricsContext } from "@/context/LiveMetrics";

import { Flags } from "@/external/flags";
import { Flex } from "@/external/flex";

import { useCurrentCall } from "@/hooks/useCurrentCall";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { PendingCallThresholds } from "@/layout/LiveCallMetrics";
import { NoActionCallSurveyMutator } from "@/layout/NoActionCallSurvey";

export const TriggerSurvey = () => {
    const { currentCall, markNoActionSurveyShown } = useCurrentCall();
    const { liveMetrics } = useContext(LiveMetricsContext);
    const trackEvent = useTrackEvent();
    const automaticNoActionCallSurveyEnabled =
        Flags.getFlag("automatic_trigger_no_action_call_survey")?.isActive ||
        false;
    const [isModalActive, setModalActive] = useState(false);

    const isFewCustomersInQueue = useCallback(() => {
        if (Flex.isEmbedded) {
            return true;
        }
        return (
            liveMetrics.callsInQueue?.count &&
            liveMetrics.callsInQueue?.count < PendingCallThresholds.warning
        );
    }, [liveMetrics.callsInQueue?.count]);

    useEffect(() => {
        const isCurrentBrowserTab = document.visibilityState === "visible";
        const isCallEndedWithNoActions =
            currentCall.callType !== "none" &&
            !currentCall.noActionSurveyShown &&
            (currentCall.endTime || currentCall.isWrapping) &&
            currentCall.actions.length === 0;

        if (
            automaticNoActionCallSurveyEnabled &&
            isCurrentBrowserTab &&
            isCallEndedWithNoActions &&
            isFewCustomersInQueue()
        ) {
            markNoActionSurveyShown();
            setModalActive(true);
            trackEvent("trigger no action call survey");
        }
    }, [
        automaticNoActionCallSurveyEnabled,
        currentCall,
        isFewCustomersInQueue,
        markNoActionSurveyShown,
        trackEvent,
    ]);

    const handleHide = () => {
        setModalActive(false);
    };

    return isModalActive ? (
        <NoActionCallSurveyMutator show={isModalActive} onHide={handleHide} />
    ) : null;
};
