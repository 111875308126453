import * as React from "react";
import { useFragment } from "react-relay";

import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { AgentHistoryEntryDelegate_entry$key } from "./__generated__/AgentHistoryEntryDelegate_entry.graphql";

import AgentAtxEffectiveVolumeEntry from "./AgentAtxEffectiveVolumeEntry";
import AgentAtxEntry from "./AgentAtxEntry";
import { delegateFragment } from "./AgentHistory.fragments";
import AgentHistoryEntry from "./AgentHistoryEntry";

interface Props {
    entry: AgentHistoryEntryDelegate_entry$key;
    isFocussed: boolean;
    subTransactionCount: number;
}

export const AgentHistoryEntryDelegate = (props: Props) => {
    const delegateEntry = useFragment(delegateFragment, props.entry);

    if (delegateEntry.__typename == "AgentTransactionEntry") {
        if (props.subTransactionCount > 1)
            return (
                <AgentAtxEffectiveVolumeEntry
                    tx={delegateEntry}
                    entry={delegateEntry}
                    isFocussed={props.isFocussed}
                />
            );

        return (
            <AgentAtxEntry
                tx={delegateEntry}
                entry={delegateEntry}
                isFocussed={props.isFocussed}
            />
        );
    }

    return (
        <AgentHistoryEntry
            entry={delegateEntry}
            isFocussed={props.isFocussed}
            titleCallable={(entry) => (
                <React.Fragment>
                    <TxTitle>{entry.summary}</TxTitle>
                    <TxInfo>{delegateEntry.__typename}</TxInfo>
                </React.Fragment>
            )}
        />
    );
};

export default AgentHistoryEntryDelegate;
