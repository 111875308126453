import Decimal from "decimal.js";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    createFragmentContainer,
    graphql,
    useLazyLoadQuery,
} from "react-relay";

import {
    CurrencyField,
    useCurrencyField,
} from "../../components/CurrencyField";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { ProfileSearchField } from "@/components/ProfileSearchField";

import { usePermissions } from "@/hooks/usePermissions";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_HISTORY } from "@/types/Permissions";
import {
    Profile as IProfile,
    MerchantInfo,
    UnregisteredUser,
} from "@/types/ProfileSearcher";
import { Action } from "@/types/actions";
import { COUNTRIES } from "@/types/countries";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";
import { Formula } from "@/utils/formula";
import { countryForMobile } from "@/utils/mobile";

import { SendMutationResponse } from "./__generated__/SendMutation.graphql";
import { SendQuery } from "./__generated__/SendQuery.graphql";

type Profile = Exclude<Exclude<IProfile, UnregisteredUser>, MerchantInfo>;

const mutation = graphql`
    mutation SendMutation(
        $transfer: P2PTransferInput!
        $canReadHistory: Boolean!
    ) {
        send(transfer: $transfer) {
            transfer {
                id
                user {
                    primaryWallet {
                        ...History_wallet
                            @include(if: $canReadHistory)
                            @arguments(last: 10)
                    }
                }
            }
        }
    }
`;

const sendQuery = graphql`
    query SendQuery($mobile: String!) {
        wallet(mobile: $mobile) {
            id
            name
            balance
            sendFeeFormula
            receiveFeeFormula
            internationalFeeFormulas {
                destCountry
                sendFeeFormula
                receiveFeeFormula
                sendFeeFormulaDescription
            }
            multicurrencyFeeFormulas {
                destCountry
                sendFeeFormula
                receiveFeeFormula
                sendFeeFormulaDescription
                exchangeToForeignFormula
                exchangeFromForeignFormula
                fxRate
                fxRateDescription
            }
        }
    }
`;

type SendInputParams = {
    mobile: string;
};

type SendWallet = Exclude<SendQuery["response"]["wallet"], null>;

type SendMutatorInputParams = {
    mobile: string;
    wallet: SendWallet;
};

const identityFromForeignFormula = Formula.parseBlob('"foreign_amount"');
const identityToForeignFormula = Formula.parseBlob('"domestic_amount"');

const _SendMutator = (props: RelayModalProps & SendMutatorInputParams) => {
    const { onHide, mobile, wallet } = props;
    const { t } = useTranslation();
    const balance = M.fromSerialized(wallet.balance);

    const canReadHistory = usePermissions(CAN_READ_HISTORY);

    const [fee, setFee] = useState<M>(new M("CFA", 0));
    const [fxRate, setFxRate] = useState<Decimal>(new Decimal(1));
    const sendAmountField = useCurrencyField();
    const receiveAmountField = useCurrencyField();
    const [recipient, setRecipient] = useState<Profile | null>(null);

    const senderCountry = useMemo(() => countryForMobile(mobile), [mobile]);
    const recipientCountry = useMemo(
        () => countryForMobile(recipient?.mobile),
        [recipient]
    );

    const supportedRecipientCountries = useMemo(() => {
        return [
            countryForMobile(mobile),
            ...wallet.internationalFeeFormulas.map(
                (f) => COUNTRIES[f.destCountry.toUpperCase()]
            ),
            ...wallet.multicurrencyFeeFormulas.map(
                (f) => COUNTRIES[f.destCountry.toUpperCase()]
            ),
        ];
    }, [mobile, wallet]);

    const onMutationSuccess = useCallback(
        (response: SendMutationResponse): string => {
            if (!response.send) {
                return "";
            }

            return t("manual-transfer--success");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ManualTransferSuccess,
            data: {},
            extractResponseData: (response) => ({
                transferId: response.send?.transfer?.id,
            }),
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            transfer: {
                walletId: wallet.id,
                sendAmount: sendAmountField.value?.serialize(),
                receiveAmount: receiveAmountField.value?.serialize(),
                feeAmount: fee.serialize(),
                fxRate: fxRate.toString(),
                recipientMobile: recipient?.mobile,
            },
            canReadHistory,
        });
    }, [
        mutator,
        recipient,
        canReadHistory,
        wallet.id,
        receiveAmountField,
        sendAmountField,
        fee,
        fxRate,
    ]);

    const sendFormulae = useMemo(() => {
        for (const it of wallet.internationalFeeFormulas) {
            if (it.destCountry === recipientCountry?.iso2) {
                return {
                    fee: Formula.parseBlob(it.sendFeeFormula || "'0'"),
                    exchange: identityToForeignFormula,
                    fxRate: new Decimal(1),
                };
            }
        }
        for (const it of wallet.multicurrencyFeeFormulas) {
            if (it.destCountry === recipientCountry?.iso2) {
                return {
                    fee: Formula.parseBlob(it.sendFeeFormula || "'0'"),
                    exchange: Formula.parseBlob(it.exchangeToForeignFormula),
                    fxRate: new Decimal(it.fxRate),
                };
            }
        }

        return {
            fee: Formula.parseBlob(wallet.sendFeeFormula || "'0'"),
            exchange: identityToForeignFormula,
            fxRate: new Decimal(1),
        };
    }, [recipientCountry, wallet]);

    const receiveFormulae = useMemo(() => {
        for (const it of wallet.internationalFeeFormulas) {
            if (it.destCountry === recipientCountry?.iso2) {
                return {
                    fee: Formula.parseBlob(it.receiveFeeFormula || "'0'"),
                    exchange: identityFromForeignFormula,
                    fxRate: new Decimal(1),
                };
            }
        }
        for (const it of wallet.multicurrencyFeeFormulas) {
            if (it.destCountry === recipientCountry?.iso2) {
                return {
                    fee: Formula.parseBlob(it.receiveFeeFormula || "'0'"),
                    exchange: Formula.parseBlob(it.exchangeFromForeignFormula),
                    fxRate: new Decimal(it.fxRate),
                };
            }
        }

        return {
            fee: Formula.parseBlob(wallet.receiveFeeFormula || "'0'"),
            exchange: identityFromForeignFormula,
            fxRate: new Decimal(1),
        };
    }, [recipientCountry, wallet]);

    const setSendAmount = (m?: M | null) => {
        if (!(m && recipientCountry)) {
            sendAmountField.setValue(null);
            receiveAmountField.setValue(null);
            return;
        }

        sendAmountField.setValue(m);

        const sendFee = sendFormulae.fee.evaluate({ send_amount: m.scalar });
        setFee(new M(m.currency, sendFee));
        setFxRate(sendFormulae.fxRate);
        const receiveAmount = sendFormulae.exchange.evaluate({
            domestic_amount: m.subtract(new M(m.currency, sendFee)).scalar,
            fx_rate: sendFormulae.fxRate,
        });
        receiveAmountField.setValue(
            new M(recipientCountry.currencyCode, receiveAmount)
        );
    };

    const setReceiveAmount = (m?: M | null) => {
        if (!(m && recipient)) {
            sendAmountField.setValue(null);
            receiveAmountField.setValue(null);
            return;
        }

        receiveAmountField.setValue(m);
        const receiveFee = receiveFormulae.fee.evaluate({
            receive_amount: m.scalar,
        });
        setFee(new M(balance.currency, receiveFee));
        setFxRate(sendFormulae.fxRate);
        const sendAmount = receiveFormulae.exchange.evaluate({
            foreign_amount: m.add(new M(m.currency, receiveFee)).scalar,
            fx_rate: sendFormulae.fxRate,
        });
        sendAmountField.setValue(new M(balance.currency, sendAmount));
    };

    const recipientValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (recipient) {
            let message = "";
            if (recipient.wallet == null) {
                message = t("manual-transfer--first-time");
            }
            validations.push({
                type: "none",
                message,
                display: "always",
            });

            if (!supportedRecipientCountries.includes(recipientCountry)) {
                validations.push({
                    type: "error",
                    message: t("manual-transfer--invalid-recipient-country", {
                        senderCountry: senderCountry?.name,
                        recipientCountry: recipientCountry?.name,
                    }),
                    display: "if-blurred",
                });
            } else {
                if (senderCountry != recipientCountry) {
                    for (const it of [
                        ...wallet.internationalFeeFormulas,
                        ...wallet.multicurrencyFeeFormulas,
                    ]) {
                        if (it.destCountry === recipientCountry?.iso2) {
                            validations.push({
                                type: "info",
                                message: t(
                                    "manual-transfer--cross-border-warning",
                                    {
                                        feeDescription:
                                            it.sendFeeFormulaDescription,
                                    }
                                ),
                                display: "always",
                            });
                        }
                    }
                    for (const it of wallet.multicurrencyFeeFormulas) {
                        if (it.destCountry === recipientCountry?.iso2) {
                            validations.push({
                                type: "info",
                                message: t(
                                    "manual-transfer--cross-border-fx-rate-warning",
                                    {
                                        fxRate: it.fxRateDescription,
                                    }
                                ),
                                display: "always",
                            });
                        }
                    }
                }
            }
        } else {
            validations.push({
                type: "error",
                message: t("form-validation--missing-recipient"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [
        t,
        wallet,
        senderCountry,
        recipient,
        recipientCountry,
        supportedRecipientCountries,
    ]);

    const sendAmountValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (sendAmountField.value) {
            const newSenderBalance = balance.subtract(sendAmountField.value);

            if (newSenderBalance.isNegative()) {
                validations.push({
                    type: "error",
                    message: t("manual-transfer--balance-error", { balance }),
                    display: "always",
                });
            } else {
                validations.push({
                    type: "none",
                    message: t("manual-transfer--balance-new", {
                        name: wallet.name,
                        balance: newSenderBalance,
                    }),
                    display: "always",
                });
            }
        } else {
            validations.push({
                type: "error",
                message: t("form-validation--missing-amount"),
                display: "if-blurred",
            });
        }

        return validations;
    }, [t, sendAmountField.value, balance, wallet.name]);

    const receiveAmountValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (receiveAmountField.value) {
            let newRecipBalance = receiveAmountField.value;

            if (recipient?.wallet?.balance) {
                newRecipBalance = newRecipBalance.add(recipient.wallet.balance);
            }

            validations.push({
                type: "none",
                message: t("manual-transfer--balance-new", {
                    name: recipient?.wallet?.name ? recipient.wallet.name : "",
                    balance: newRecipBalance,
                }),
                display: "always",
            });
        }

        return validations;
    }, [t, receiveAmountField.value, recipient]);

    const isValid = useMemo(() => {
        return !hasError([
            recipientValidations,
            sendAmountValidations,
            receiveAmountValidations,
        ]);
    }, [recipientValidations, sendAmountValidations, receiveAmountValidations]);

    return (
        <MutatorModal
            {...mutator}
            title={t("manual-transfer--title", { name: wallet.name })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <p>Current balance: {balance.toString()}</p>
                <ProfileSearchField
                    allowWallets
                    allowUnregistered
                    allowMerchants
                    allowTerminated
                    label={t("label-recipient")}
                    name="recipient"
                    allowAgents={false}
                    validations={recipientValidations}
                    onChange={(profile) => {
                        if (profile) setRecipient(profile as Profile);
                    }}
                />
                <CurrencyField
                    {...sendAmountField}
                    label={t("label-send")}
                    name="sendAmount"
                    currency={balance.currency}
                    validations={sendAmountValidations}
                    setValue={(m) => setSendAmount(m)}
                />
                <CurrencyField
                    {...receiveAmountField}
                    label={t("label-receive")}
                    name="receiveAmount"
                    currency={
                        recipientCountry?.currencyCode || balance.currency
                    }
                    validations={receiveAmountValidations}
                    setValue={(m) => setReceiveAmount(m)}
                />
            </Form>
        </MutatorModal>
    );
};

export const _SendQuery = (props: RelayModalProps & SendInputParams) => {
    const { t } = useTranslation();
    const { mobile } = props;

    const data = useLazyLoadQuery<SendQuery>(sendQuery, { mobile });

    if (data.wallet) {
        return <_SendMutator wallet={data.wallet} {...props} />;
    }
    return <div>{t("loading")}</div>;
};

export const SendMutator = createFragmentContainer(_SendQuery, {});
