import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";
import { ProfileSearchField } from "@/components/ProfileSearchField";
import { TextField, useTextField } from "@/components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Profile } from "@/types/ProfileSearcher";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isDigit } from "@/utils/mobile";

import { CreateAgentAssistantMutationResponse } from "./__generated__/CreateAgentAssistantMutation.graphql";
import { CreateAgentAssistant_agentUser } from "./__generated__/CreateAgentAssistant_agentUser.graphql";

const mutation = graphql`
    mutation CreateAgentAssistantMutation(
        $agentId: ID!
        $assistantMobile: String!
        $assistantName: String
        $pin: String
    ) {
        createAgentAssistant(
            agentId: $agentId
            assistantMobile: $assistantMobile
            assistantName: $assistantName
            pin: $pin
        ) {
            result
            agent {
                id
                ...AgentUsersList_agent
            }
        }
    }
`;

type CreateAgentAssistantInputParams = {
    agentUser: CreateAgentAssistant_agentUser;
};

const _CreateAgentAssistant = (
    props: RelayModalProps & CreateAgentAssistantInputParams
) => {
    const { t } = useTranslation();
    const [assistantProfile, setAssistantProfile] = useState<
        Profile | undefined
    >();
    const assistantNameField = useTextField();
    const pinField = useTextField();
    const onMutationSuccess = useCallback(
        (response: CreateAgentAssistantMutationResponse) => {
            return response.createAgentAssistant?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.CreateAgentAssistant,
            data: {},
        },
        ...props,
    });

    const onSubmit = useCallback(() => {
        mutator.submit(
            mutation,
            assistantProfile?.kind === "Wallet"
                ? {
                      agentId: props.agentUser.agent.ufid,
                      assistantMobile: assistantProfile?.mobile,
                  }
                : {
                      agentId: props.agentUser.agent.ufid,
                      assistantMobile: assistantProfile?.mobile,
                      assistantName: assistantNameField.value,
                      pin: pinField.value,
                  }
        );
    }, [
        mutator,
        props.agentUser.agent.ufid,
        assistantProfile,
        assistantNameField.value,
        pinField.value,
    ]);

    const profileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!assistantProfile) {
            validations.push({
                type: "error",
                message: "Mobile required",
                display: "if-blurred",
            });
        }
        return validations;
    }, [assistantProfile]);

    const nameValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (
            assistantProfile?.kind === "UnregisteredUser" &&
            !assistantNameField.value.trim()
        ) {
            validations.push({
                type: "error",
                message: "Name required",
                display: "if-blurred",
            });
        }
        return validations;
    }, [assistantProfile, assistantNameField.value]);

    const pinValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (assistantProfile?.kind === "UnregisteredUser") {
            const newPin = pinField.value;
            if (!newPin.split("").every(isDigit)) {
                validations.push({
                    type: "error",
                    message: t("form-validation--missing-pin"),
                    display: "always",
                });
            } else if (newPin.length !== 4) {
                validations.push({
                    type: "error",
                    message: t("form-validation--missing-pin"),
                    display: newPin.length > 4 ? "always" : "if-blurred",
                });
            }
        }
        return validations;
    }, [t, assistantProfile, pinField.value]);

    const isValid = useMemo(() => {
        return !(
            hasError([profileValidations]) ||
            hasError([nameValidations]) ||
            hasError([pinValidations])
        );
    }, [profileValidations, nameValidations, pinValidations]);

    return (
        <MutatorModal {...mutator} title="Create agent assistant">
            <Form
                {...mutator}
                isValid={isValid}
                onSubmit={onSubmit}
                submitText="Create assistant"
            >
                <ProfileSearchField
                    allowWallets
                    allowUnregistered
                    allowAgents
                    allowMerchants={false}
                    allowTerminated={false}
                    name="assistant"
                    label="Assistant mobile"
                    validations={profileValidations}
                    onChange={(profile) => setAssistantProfile(profile)}
                />
                {assistantProfile?.kind == "UnregisteredUser" && (
                    <>
                        <TextField
                            {...assistantNameField}
                            label="Assistant name"
                            name="name"
                            validations={nameValidations}
                        />
                        <TextField
                            {...pinField}
                            label="PIN"
                            name="pin"
                            validations={pinValidations}
                        />
                    </>
                )}
            </Form>
        </MutatorModal>
    );
};

export const CreateAgentAssistant = createFragmentContainer(
    _CreateAgentAssistant,
    {
        agentUser: graphql`
            fragment CreateAgentAssistant_agentUser on AgentUser {
                agent {
                    ufid
                }
            }
        `,
    }
);
