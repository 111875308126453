import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { AwardPayPromoMutator } from "@/pages/mobile.[mobile].personal/AwardPayPromo";

export const SendPayPromoBadge = (props: { name: string; mobile: string }) => {
    const { t } = useTranslation();

    return (
        <div className="ml-2">
            <ActionButton
                name={t("award-pay-promo--action")}
                as="badge"
                badgeVariant="success"
            >
                <AwardPayPromoMutator name={props.name} mobile={props.mobile} />
            </ActionButton>
        </div>
    );
};
