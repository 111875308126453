import { useTranslation } from "react-i18next";

import { HistoryActionButton } from "@/components/HistoryActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { C, M } from "@/utils/currency";

import { AgentHistoryAtxEntry_tx } from "./__generated__/AgentHistoryAtxEntry_tx.graphql";

import { ReportAgentScamMutator } from "./ReportAgentScam";

interface Props {
    tx: AgentHistoryAtxEntry_tx;
}
export function AgentAtxEntryAgentScamButton({ tx }: Props) {
    const { t } = useTranslation();
    const canReportScam = usePermissions("supportapp_report_agent_scam");

    return (
        <HistoryActionButton
            key="report-agent-scam"
            name={t("report-agent-scam--action")}
            disabled={!canReportScam}
        >
            <ReportAgentScamMutator
                transactionId={tx.agentTransactionId}
                transactionCurrency={tx.currency as C}
                amountStolen={M.fromSerialized(tx.amount).negate()}
                scamReports={tx.scamReports}
            />
        </HistoryActionButton>
    );
}
