import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ActionButton } from "../../components/ActionButton";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { LoadingIcon } from "@/components/Loading";
import { Timestamp } from "@/components/Timestamp";

import { usePermissions } from "@/hooks/usePermissions";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { notEmpty } from "@/utils/value";

import { ShowWoyofalCodesMutationResponse } from "./__generated__/ShowWoyofalCodesMutation.graphql";
import { ShowWoyofalCodes_wallet } from "./__generated__/ShowWoyofalCodes_wallet.graphql";

import { SendWoyofalCodeMutator } from "./SendWoyofalCode";

const mutation = graphql`
    mutation ShowWoyofalCodesMutation($walletId: ID!) {
        showWoyofalCodes(walletId: $walletId) {
            billTransfers {
                id
                sendAmount
                whenCreated
                code
            }
        }
    }
`;

type BillTransfer = {
    id: string;
    whenCreated: Date;
    sendAmount: M | null; // strictly speaking this could be null as this is stored in JSON metadata
    code: string;
};

type ShowWoyofalCodesInputParams = {
    wallet: ShowWoyofalCodes_wallet;
};

const _ShowWoyofalCodesMutator = (
    props: ShowWoyofalCodesInputParams & RelayModalProps
) => {
    const { wallet } = props;

    const { t } = useTranslation();

    const sendWoyofalCodeAllowed = usePermissions(
        "supportapp_send_woyofal_code"
    );

    const onMutationSuccess = useCallback(
        (response: ShowWoyofalCodesMutationResponse): BillTransfer[] => {
            if (
                !response.showWoyofalCodes ||
                _.isNil(response.showWoyofalCodes.billTransfers)
            )
                return [];

            const result: BillTransfer[] = [];
            for (const bt of response.showWoyofalCodes.billTransfers.filter(
                notEmpty
            )) {
                if (_.isNil(bt)) continue;
                const { code } = bt;
                if (_.isNil(code)) continue;
                result.push({
                    id: bt.id,
                    whenCreated: new Date(bt.whenCreated),
                    code: code || t("woyofal-code-show--none"),
                    sendAmount: bt.sendAmount
                        ? M.fromSerialized(bt.sendAmount)
                        : null,
                });
            }
            return result;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ShowWoyofalCodes,
            data: { mobile: wallet.mobile },
        },
        ...props,
    });

    useEffect(() => {
        if (props.show) {
            mutator.submit(mutation, { walletId: wallet.id });
        }
    }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

    let contentsNode: React.ReactNode = null;
    if (mutator.isWorking) {
        contentsNode = (
            <>
                <LoadingIcon /> {t("woyofal-code-show-fetching")}
            </>
        );
    } else if (mutator.errors && mutator.errors.length > 0) {
        contentsNode = (
            <>
                <h2>{t("woyofal-code-show-error--title")}</h2>
                <p>{t("woyofal-code-show-error--body")}</p>
            </>
        );
    } else if (!mutator.result?.length) {
        contentsNode = (
            <>
                <h2>{t("woyofal-code-show-none--title")}</h2>
                <p>{t("woyofal-code-show-none--body")}</p>
            </>
        );
    } else {
        contentsNode = (
            <Table>
                <thead>
                    <tr>
                        <th>{t("woyofal-code-show-table--when-created")}</th>
                        <th>{t("woyofal-code-show-table--send-amount")}</th>
                        <th>{t("woyofal-code-show-table--code")}</th>
                        <th>{t("woyofal-code-show-table--send-code")}</th>
                    </tr>
                </thead>
                <tbody>
                    {mutator.result.map((bt) => (
                        <tr key={bt.id}>
                            <td>
                                <Timestamp value={bt.whenCreated} />
                            </td>
                            <td>
                                {bt.sendAmount
                                    ? bt.sendAmount.toString()
                                    : t("amount--full-balance")}
                            </td>
                            <td>{bt.code}</td>
                            <td>
                                <ActionButton
                                    name={t("action-send")}
                                    disabled={!sendWoyofalCodeAllowed}
                                >
                                    <SendWoyofalCodeMutator
                                        wallet={wallet}
                                        woyofalCode={bt.code}
                                    />
                                </ActionButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    return (
        <MutatorModal
            {...mutator}
            showErrors
            title={t("woyofal-code-show", { name: wallet.name })}
        >
            {contentsNode}
        </MutatorModal>
    );
};

export const ShowWoyofalCodesMutator = createFragmentContainer(
    _ShowWoyofalCodesMutator,
    {
        wallet: graphql`
            fragment ShowWoyofalCodes_wallet on Wallet {
                id
                name
                mobile
                ...SendWoyofalCode_wallet
            }
        `,
    }
);
