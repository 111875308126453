import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { RenameWalletMutator } from "@/pages/mobile.[mobile].personal/RenameWallet";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const RenameWalletAction = (props: {
    wallet: PersonalTabHeader_wallet;
}) => {
    const wallet = props.wallet;
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={
                wallet.user
                    ? t("rename-wallet--action-user")
                    : t("rename-wallet--action-wallet")
            }
            requiredPermissions={["supportapp_rename_user_or_wallet"]}
        >
            <RenameWalletMutator wallet={wallet} />
        </ActionButton>
    );
};
