import { faIdCard, faSimCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { Timestamp } from "../../components/Timestamp";

import { mutate } from "@/external/relay";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { RequestToBlockUnblockUserMutation } from "./__generated__/RequestToBlockUnblockUserMutation.graphql";
import { RequestToBlockUnblockUser_unregisteredMobile } from "./__generated__/RequestToBlockUnblockUser_unregisteredMobile.graphql";
import { RequestToBlockUnblockUser_wallet } from "./__generated__/RequestToBlockUnblockUser_wallet.graphql";

const mutation = graphql`
    mutation RequestToBlockUnblockUserMutation(
        $unblock: Boolean!
        $mobile: String!
        $reason: String!
    ) {
        requestToBlockUser(mobile: $mobile, reason: $reason)
            @skip(if: $unblock) {
            result
            wallet {
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
            unregisteredMobile {
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
        }

        requestToUnblockUser(mobile: $mobile, reason: $reason)
            @include(if: $unblock) {
            result
            wallet {
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
            unregisteredMobile {
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
        }
    }
`;

type RequestToBlockUnblockUserInputParams = {
    wallet: RequestToBlockUnblockUser_wallet | null;
    unregisteredMobile: RequestToBlockUnblockUser_unregisteredMobile | null;
};

const _RequestToBlockUnblockUserMutator = (
    props: RequestToBlockUnblockUserInputParams & RelayModalProps
) => {
    const trackEvent = useTrackEvent();
    const { wallet, unregisteredMobile } = props;
    const { t } = useTranslation();
    const reasonField = useTextField();
    const unblock = !!(
        wallet?.activeMobileBlock ||
        wallet?.activeLegalEntityBlock ||
        unregisteredMobile?.activeBlock
    );
    const isMobileRegistered = wallet && !unregisteredMobile;
    const mobile = isMobileRegistered
        ? wallet.mobile
        : unregisteredMobile
        ? unregisteredMobile.mobile
        : "";

    // use different help text / button text depending on whether we are
    // blocking a user or unblocking them
    const subject = unregisteredMobile ? t("mobile-number") : t("account");
    let submitText = t("request-block-user--submit", {
        action: unblock ? t("unblock") : t("block"),
        subject,
    });
    let modalHelpText = (
        <p>
            <b>{t("request-block-uesr--descritpion-block-1", { subject })}</b>
            <br />
            {t("request-block-user--description-block-2", { subject })}{" "}
            {!unblock && !unregisteredMobile && (
                <>{t("request-block-user--description-block-registered")}</>
            )}
        </p>
    );

    if (unblock) {
        modalHelpText = (
            <p>{t("request-block-user--description-unblock", { subject })}</p>
        );
        submitText = t("request-block-user--submit-unblock");
    }

    const requestToBlockUnblockWalletRequest = useAsyncFunction(async () => {
        if (!props.relay) {
            return;
        }
        const [response, payloadErrors] =
            await mutate<RequestToBlockUnblockUserMutation>(
                props.relay.environment,
                mutation,
                {
                    unblock: unblock,
                    mobile: mobile,
                    reason: reasonField.value,
                }
            );
        if (payloadErrors) {
            return payloadErrors;
        }

        const action = unblock
            ? Action.RequestToUnblockUser
            : Action.RequestToBlockUser;
        trackEvent(action);

        return unblock
            ? response?.requestToUnblockUser?.result
            : response?.requestToBlockUser?.result;
    });

    const reasonValidations: Validation[] = [];
    if (!reasonField.value) {
        reasonValidations.push({
            type: "error",
            message: t("request-block-user--missing-reason", {
                subject: unregisteredMobile ? "mobile number" : "account",
                action: unblock ? "unblocked" : "blocked",
            }),
            display: "if-blurred",
        });
    }
    const isValid = !hasError([reasonValidations]);
    const blockUnblockWalletResultText =
        typeof requestToBlockUnblockWalletRequest.result === "string"
            ? requestToBlockUnblockWalletRequest.result
            : null;
    const requestToBlockUnblockWalletErrors = Array.isArray(
        requestToBlockUnblockWalletRequest.result
    )
        ? requestToBlockUnblockWalletRequest.result
        : requestToBlockUnblockWalletRequest.error
        ? [requestToBlockUnblockWalletRequest.error]
        : null;

    return (
        <MutatorModal
            title={submitText}
            isWorking={requestToBlockUnblockWalletRequest.isLoading}
            show={props.show}
            onHide={props.onHide}
            errors={requestToBlockUnblockWalletErrors}
            size={"lg"}
        >
            {modalHelpText}
            <Form
                onSubmit={requestToBlockUnblockWalletRequest.invoke}
                submitText={submitText}
                isWorking={requestToBlockUnblockWalletRequest.isLoading}
                errors={requestToBlockUnblockWalletErrors}
                isValid={isValid}
                result={blockUnblockWalletResultText}
            >
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
            <h3>{t("request-block-user-history--title")}</h3>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>{t("request-block-user-history--when-blocked")}</th>
                        <th>{t("request-block-user-history--why-blocked")}</th>
                        <th>
                            {t("request-block-user-history--why-unblocked")}
                        </th>
                        <th>{t("request-block-user-history--unblocked-by")}</th>
                        <th>
                            {t("request-block-user-history--when-unblocked")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {(isMobileRegistered
                        ? wallet.blockHistory
                        : unregisteredMobile
                        ? unregisteredMobile.blockHistory
                        : []
                    ).map((block, i) => (
                        <tr key={i}>
                            <td>
                                {block.blockSubject == "LEGAL_ENTITY" ? (
                                    <FontAwesomeIcon
                                        icon={faIdCard}
                                        title={t(
                                            "request-block-user--block-entity"
                                        )}
                                    />
                                ) : block.blockSubject == "MOBILE" ? (
                                    <FontAwesomeIcon
                                        icon={faSimCard}
                                        title={t(
                                            "request-block-user--block-mobile"
                                        )}
                                    />
                                ) : (
                                    "&nbsp;"
                                )}
                            </td>
                            <td>
                                <Timestamp
                                    value={new Date(block.whenBlocked)}
                                    format={"MMM, DD YYYY HH:mm"}
                                />
                            </td>
                            <td>{block.blockReason}</td>
                            <td>
                                {block.whenUnblocked
                                    ? block.unblockReason
                                    : "–"}
                            </td>
                            <td>
                                {block.whenUnblocked ? block.unblockedBy : "–"}
                            </td>
                            <td>
                                {block.whenUnblocked ? (
                                    <Timestamp
                                        value={new Date(block.whenUnblocked)}
                                        format={"MMM, DD YYYY HH:mm"}
                                    />
                                ) : (
                                    "–"
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </MutatorModal>
    );
};

export const RequestToBlockUnblockUserMutator = createFragmentContainer(
    _RequestToBlockUnblockUserMutator,
    {
        wallet: graphql`
            fragment RequestToBlockUnblockUser_wallet on Wallet {
                id
                mobile
                activeMobileBlock {
                    whenBlocked
                }
                activeLegalEntityBlock {
                    whenBlocked
                }
                blockHistory {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenUnblocked
                    unblockedBy
                    unblockReason
                    blockSubject
                }
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
        `,
        unregisteredMobile: graphql`
            fragment RequestToBlockUnblockUser_unregisteredMobile on UnregisteredMobile {
                mobile
                activeBlock {
                    blockReason
                    blockedBy
                    whenBlocked
                    blockSubject
                    whenExpires
                    requestToModifyBlockTickets {
                        status
                        whenOpened
                    }
                }
                blockHistory {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenUnblocked
                    unblockedBy
                    unblockReason
                    blockSubject
                }
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
        `,
    }
);
