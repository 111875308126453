import { useTranslation } from "react-i18next";

import {
    ActionButton,
    VOIPVerificationLevel,
} from "../../components/ActionButton";

import { ShowWithdrawalCodesMutator } from "@/pages/mobile.[mobile].personal/ShowWithdrawalCodes";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const ShowWithdrawalCodesAction = (props: {
    wallet: PersonalTabHeader_wallet;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            name={t("wd-code--action")}
            requiredPermissions={["supportapp_show_withdrawal_codes"]}
            VOIPVerification={VOIPVerificationLevel.FORBIDDEN}
        >
            <ShowWithdrawalCodesMutator wallet={props.wallet} />
        </ActionButton>
    );
};
