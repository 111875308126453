import { useCallback, useMemo } from "react";
import { Alert, ModalBody, ModalFooter } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Button } from "@/components/Button";
import { CurrencyField, useCurrencyField } from "@/components/CurrencyField";
import { DropdownField, useDropdownField } from "@/components/DropdownField";
import { Form } from "@/components/Form";
import { Modal } from "@/components/Modal";
import { MutatorModal, useMutator } from "@/components/MutatorModal";
import { TextField, useTextField } from "@/components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";

import { CorrectAtxMutationResponse } from "./__generated__/CorrectAtxMutation.graphql";

const mutation = graphql`
    mutation CorrectAtxMutation(
        $transactionId: String!
        $errorType: String!
        $direction: String
        $amount: Decimal
        $description: String
    ) {
        correctAtx(
            transactionId: $transactionId
            errorType: $errorType
            direction: $direction
            amount: $amount
            description: $description
        ) {
            success
        }
    }
`;

type CorrectAtxInputParams = {
    transactionId: string;
    agentId: string;
    agentName: string;
    customerMobile: string | null;
    customerName: string | null;
    isDeposit: boolean;
    amount: M;
};

export enum SupportATXErrorType {
    DUPLICATE = "DUPLICATE",
    AMOUNT_DIRECTION = "AMOUNT_DIRECTION",
}

export enum SupportATXDirection {
    DEPOSIT = "DEPOSIT",
    WITHDRAWAL = "WITHDRAWAL",
}

function _generateOperationDescriptor({
    amount,
    intendedAmount,
    isDeposit,
    customerName,
    agentName,
    intendedDirection,
    errorType,
}: {
    amount: M;
    isDeposit: boolean;
    customerName: string | null;
    agentName: string;
    errorType: string;
    intendedAmount?: M;
    intendedDirection?: string;
}) {
    let amountToMove = intendedAmount
        ? amount.subtract(intendedAmount).abs()
        : amount;

    let fromName;
    let toName;
    if (errorType === SupportATXErrorType.DUPLICATE) {
        if (isDeposit) {
            fromName = customerName;
            toName = agentName;
        } else {
            fromName = agentName;
            toName = customerName;
        }
    } else if (errorType === SupportATXErrorType.AMOUNT_DIRECTION) {
        if (!intendedDirection || !intendedAmount) {
            return;
        }

        if (intendedDirection === SupportATXDirection.DEPOSIT) {
            if (isDeposit) {
                fromName = amount.scalar.lessThan(intendedAmount.scalar)
                    ? agentName
                    : customerName;
                toName = amount.scalar.lessThan(intendedAmount.scalar)
                    ? customerName
                    : agentName;
            } else {
                amountToMove = amount.add(intendedAmount);

                fromName = agentName;
                toName = customerName;
            }
        } else if (intendedDirection === SupportATXDirection.WITHDRAWAL) {
            if (!isDeposit) {
                fromName = amount.scalar.lessThan(intendedAmount.scalar)
                    ? customerName
                    : agentName;
                toName = amount.scalar.lessThan(intendedAmount.scalar)
                    ? agentName
                    : customerName;
            } else {
                amountToMove = amount.add(intendedAmount);

                fromName = customerName;
                toName = agentName;
            }
        } else {
            return;
        }
    } else {
        return;
    }

    return {
        amountToMove,
        fromName,
        toName,
    };
}

const _CorrectAtxMutator = (props: CorrectAtxInputParams & RelayModalProps) => {
    const {
        transactionId,
        agentId,
        agentName,
        customerMobile,
        customerName,
        isDeposit,
        amount,
        onHide,
    } = props;

    const { t } = useTranslation();

    const errorTypeOptions = [
        {
            value: SupportATXErrorType.DUPLICATE,
            displayName: t("correct-atx--error-type--duplicate"),
        },
        {
            value: SupportATXErrorType.AMOUNT_DIRECTION,
            displayName: t("correct-atx--error-type--amount-direction"),
        },
    ];
    const directionOptions = [
        {
            value: SupportATXDirection.DEPOSIT,
            displayName: t("correct-atx--direction--deposit"),
        },
        {
            value: SupportATXDirection.WITHDRAWAL,
            displayName: t("correct-atx--direction--withdrawal"),
        },
    ];

    const onMutationSuccess = useCallback(
        (response: CorrectAtxMutationResponse) => {
            return response.correctAtx?.success
                ? t("correct-atx--success")
                : "";
        },
        [t]
    );

    const agentIdField = useTextField();
    const mobileField = useTextField();
    const transactionIdField = useTextField();
    const errorTypeField = useDropdownField();
    const directionField = useDropdownField();
    const amountField = useCurrencyField();
    const additionalInfoField = useTextField();

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.CorrectAtx,
            data: {
                transactionId,
                agentId,
                customerMobile,
                amount,
                isDeposit,
                errorType: errorTypeField.dropdownItem?.value,
                direction_adjustment: directionField.dropdownItem?.value,
                amount_adjustment: amountField.value,
                description: additionalInfoField.value,
            },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        // NOTE(antonio): Adding this check here to make TypeScript happy
        // as it shouldn't be possible to submit the form while `isValid`
        // isn't true, and that already checks that the error type has been
        // selected.
        if (!errorTypeField.dropdownItem) {
            return;
        }

        mutator.submit(mutation, {
            transactionId: transactionIdField.value.trim(),
            errorType: errorTypeField.dropdownItem.value.toString(),
            direction: directionField.dropdownItem?.value.toString(),
            amount: amountField.value?.scalar,
            description: additionalInfoField.value,
        });
    }, [
        mutator,
        transactionIdField.value,
        errorTypeField.dropdownItem,
        directionField.dropdownItem,
        amountField.value,
        additionalInfoField.value,
    ]);

    const amountValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!amountField.value || !amountField.value?.isPositive()) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-amount"),
            });
        }
        return validations;
    }, [t, amountField.value]);

    const isValid = useMemo(() => {
        return Boolean(
            errorTypeField.dropdownItem?.value.toString() ===
                SupportATXErrorType.DUPLICATE ||
                (errorTypeField.dropdownItem?.value.toString() ===
                    SupportATXErrorType.AMOUNT_DIRECTION &&
                    directionField.dropdownItem &&
                    !hasError([amountValidations]))
        );
    }, [
        errorTypeField.dropdownItem,
        directionField.dropdownItem,
        amountValidations,
    ]);

    const operationDescription = useMemo(() => {
        if (!isValid || !errorTypeField.dropdownItem) {
            return;
        }

        const operationDescriptor = _generateOperationDescriptor({
            amount,
            isDeposit,
            customerName,
            agentName,
            intendedAmount: amountField.value ?? undefined,
            intendedDirection: directionField.dropdownItem?.value.toString(),
            errorType: errorTypeField.dropdownItem.value.toString(),
        });

        if (!operationDescriptor) {
            return;
        }

        return `${t("correct-atx--operation-description--original", {
            amount: amount.serialize(),
            type: isDeposit
                ? t("correct-atx--deposit")
                : t("correct-atx--withdrawal"),
        })} ${
            errorTypeField.dropdownItem.value.toString() ===
            SupportATXErrorType.DUPLICATE
                ? t("correct-atx--operation-description--duplicate")
                : t("correct-atx--operation-description--amount-direction", {
                      amount: amountField.value?.serialize(),
                      type:
                          directionField.dropdownItem?.value.toString() ===
                          SupportATXDirection.DEPOSIT
                              ? t("correct-atx--deposit")
                              : t("correct-atx--withdrawal"),
                  })
        } ${t("correct-atx--operation-description--move", {
            amount: operationDescriptor.amountToMove.serialize(),
            fromName: operationDescriptor.fromName,
            toName: operationDescriptor.toName,
        })}`;
    }, [
        t,
        isValid,
        amount,
        isDeposit,
        agentName,
        customerName,
        errorTypeField.dropdownItem,
        directionField.dropdownItem,
        amountField.value,
    ]);

    if (!customerMobile || !customerName) {
        return (
            <Modal title={t("correct-atx--missing-customer-data--title")}>
                <ModalBody>
                    {t("correct-atx--missing-customer-data--description")}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onHide || (() => null)}>
                        {t("action-confirm")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    const currency = M.deduceCurrencyFromMobile(customerMobile);

    return (
        <MutatorModal
            {...mutator}
            title={t("correct-atx--title", { transactionId })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("correct-atx--submit")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...agentIdField}
                    label={t("label-agent")}
                    name="agent"
                    validations={[]}
                    defaultValue={agentId}
                    disabled
                />
                <TextField
                    {...mobileField}
                    label={t("label-customer-mobile")}
                    name="mobile"
                    validations={[]}
                    defaultValue={customerMobile}
                    disabled
                />
                <TextField
                    {...transactionIdField}
                    label={t("label-transaction-id")}
                    name="transaction"
                    validations={[]}
                    defaultValue={transactionId}
                    disabled
                />
                <DropdownField
                    {...errorTypeField}
                    label={t("label-error-type")}
                    name="errorType"
                    validations={[]}
                    values={errorTypeOptions}
                    placeholder={t("dropdown--select-option")}
                />
                {errorTypeField.dropdownItem?.value.toString() ===
                SupportATXErrorType.AMOUNT_DIRECTION ? (
                    <>
                        <DropdownField
                            {...directionField}
                            label={t("label-direction")}
                            name="direction"
                            validations={[]}
                            values={directionOptions}
                            placeholder={t("dropdown--select-option")}
                        />

                        <CurrencyField
                            {...amountField}
                            label={t("label-amount")}
                            name="amount"
                            validations={amountValidations}
                            defaultValue={amount}
                            currency={currency}
                        />
                    </>
                ) : null}
                <TextField
                    {...additionalInfoField}
                    label={t("label-additional-info")}
                    name="additionalInfo"
                    validations={[]}
                />
            </Form>
            {isValid && <Alert variant="warning">{operationDescription}</Alert>}
        </MutatorModal>
    );
};

export const CorrectAtx = createFragmentContainer(_CorrectAtxMutator, {});

export { _generateOperationDescriptor as _test__generateOperationDescriptor };
