/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BlockSubject = "DEFAULT" | "DEVICE" | "LEGAL_ENTITY" | "MOBILE" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type TicketType = "AgentIssueReport" | "AgentScamReport" | "AmlMatch" | "B2WOrW2BCustomerProblem" | "BankRebalanceRequest" | "BillPayProblem" | "BusinessFundingRequest" | "KYBEscalation" | "MerchantComplianceFollowup" | "MerchantSupportEscalation" | "PaymentCardProblem" | "PoliceRequisition" | "RebalancingDifficulty" | "RefundPartialFunds" | "RefundingDispute" | "RequestToBlockWallet" | "RequestToExplainCommissionCuts" | "RequestToModifyBlockTicket" | "ScamReport" | "ScreeningResult" | "SpamCallerReport" | "SupportGroupLeadEscalation" | "%future added value";
export type WalletUserBlocks_blockData = {
    readonly id: string;
    readonly mobile: string;
    readonly activeMobileBlock: {
        readonly id: string;
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
        readonly whenExpires: string | null;
        readonly requestToModifyBlockTickets: ReadonlyArray<{
            readonly status: TicketStatus;
            readonly whenOpened: string;
        }> | null;
        readonly " $fragmentRefs": FragmentRefs<"RequestToModifyBlock_activeBlock" | "UpdateBlock_activeBlock">;
    } | null;
    readonly activeLegalEntityBlock: {
        readonly id: string;
        readonly blockReason: string;
        readonly blockedBy: string;
        readonly whenBlocked: string;
        readonly blockSubject: BlockSubject | null;
        readonly whenExpires: string | null;
        readonly requestToModifyBlockTickets: ReadonlyArray<{
            readonly status: TicketStatus;
            readonly whenOpened: string;
        }> | null;
        readonly " $fragmentRefs": FragmentRefs<"RequestToModifyBlock_activeBlock" | "UpdateBlock_activeBlock">;
    } | null;
    readonly tickets: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly slackMessageUrl: string | null;
        readonly ticketType: TicketType;
        readonly reference: string | null;
    } | null>;
    readonly blockHistory: ReadonlyArray<{
        readonly whenBlocked: string;
        readonly blockedBy: string;
        readonly blockReason: string;
        readonly whenUnblocked: string | null;
        readonly unblockedBy: string | null;
        readonly unblockReason: string | null;
        readonly blockSubject: BlockSubject | null;
        readonly whenExpires: string | null;
    }>;
    readonly user: {
        readonly id: string;
        readonly name: string;
        readonly mobile: string | null;
        readonly qrCardBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
        } | null;
        readonly dormancyBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
        } | null;
        readonly personalMultipleDevicesBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
            readonly lockedDevice: {
                readonly deviceId: string;
                readonly deviceName: string;
                readonly deviceModel: string;
            } | null;
            readonly requestToModifyBlockTickets: ReadonlyArray<{
                readonly status: TicketStatus;
                readonly whenOpened: string;
            }> | null;
        } | null;
        readonly agentMultipleDevicesBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
            readonly lockedDevice: {
                readonly deviceId: string;
                readonly deviceName: string;
                readonly deviceModel: string;
            } | null;
            readonly requestToModifyBlockTickets: ReadonlyArray<{
                readonly status: TicketStatus;
                readonly whenOpened: string;
            }> | null;
        } | null;
        readonly agentDepositsBlock: {
            readonly id: string;
            readonly blockReason: string;
            readonly blockedBy: string;
            readonly blockSubject: BlockSubject | null;
            readonly whenBlocked: string;
            readonly whenExpires: string | null;
            readonly requestToModifyBlockTickets: ReadonlyArray<{
                readonly status: TicketStatus;
                readonly whenOpened: string;
            }> | null;
            readonly " $fragmentRefs": FragmentRefs<"UpdateBlock_activeBlock">;
        } | null;
        readonly devices: ReadonlyArray<{
            readonly deviceId: string;
            readonly deviceName: string;
            readonly deviceModel: string;
            readonly activeBlock: {
                readonly id: string;
                readonly blockReason: string;
                readonly blockedBy: string;
                readonly whenBlocked: string;
                readonly whenExpires: string | null;
                readonly requestToModifyBlockTickets: ReadonlyArray<{
                    readonly status: TicketStatus;
                    readonly whenOpened: string;
                }> | null;
                readonly " $fragmentRefs": FragmentRefs<"RequestToModifyBlock_activeBlock" | "UpdateBlock_activeBlock">;
            } | null;
        }> | null;
        readonly " $fragmentRefs": FragmentRefs<"UnblockFromUsingMultipleDevices_user" | "UnblockFromUsingQrCards_user" | "UnblockFromAgentDeposits_user" | "UnblockUserFromDormancy_user" | "BlockUnblockDevice_user">;
    } | null;
    readonly legalEntity: {
        readonly wallets: ReadonlyArray<{
            readonly mobile: string;
            readonly user: {
                readonly agentUser: {
                    readonly isActive: boolean;
                } | null;
                readonly merchantUser: {
                    readonly isActive: boolean;
                } | null;
                readonly promoter: {
                    readonly canKioskSignup: boolean;
                } | null;
            } | null;
        }>;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"BlockUnblockUser_wallet" | "RequestToBlockUnblockUser_wallet" | "RequestToUnblockFromUsingMultipleDevices_wallet" | "RequestToUnblockFromAgentDeposits_wallet">;
    readonly " $refType": "WalletUserBlocks_blockData";
};
export type WalletUserBlocks_blockData$data = WalletUserBlocks_blockData;
export type WalletUserBlocks_blockData$key = {
    readonly " $data"?: WalletUserBlocks_blockData$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"WalletUserBlocks_blockData">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockSubject",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenExpires",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenOpened",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketShortInfo",
  "kind": "LinkedField",
  "name": "requestToModifyBlockTickets",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "RequestToModifyBlock_activeBlock"
},
v11 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UpdateBlock_activeBlock"
},
v12 = [
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = [
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceModel",
  "storageKey": null
},
v17 = [
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "UserDevice",
    "kind": "LinkedField",
    "name": "lockedDevice",
    "plural": false,
    "selections": [
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/)
    ],
    "storageKey": null
  },
  (v9/*: any*/)
],
v18 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isActive",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WalletUserBlocks_blockData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeMobileBlock",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActiveBlock",
      "kind": "LinkedField",
      "name": "activeLegalEntityBlock",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketShortInfo",
      "kind": "LinkedField",
      "name": "tickets",
      "plural": true,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenResolved",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackMessageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reference",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Block",
      "kind": "LinkedField",
      "name": "blockHistory",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v3/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenUnblocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unblockReason",
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ActiveBlock",
          "kind": "LinkedField",
          "name": "qrCardBlock",
          "plural": false,
          "selections": (v13/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActiveBlock",
          "kind": "LinkedField",
          "name": "dormancyBlock",
          "plural": false,
          "selections": (v13/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MultipleDevicesBlock",
          "kind": "LinkedField",
          "name": "personalMultipleDevicesBlock",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MultipleDevicesBlock",
          "kind": "LinkedField",
          "name": "agentMultipleDevicesBlock",
          "plural": false,
          "selections": (v17/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActiveBlock",
          "kind": "LinkedField",
          "name": "agentDepositsBlock",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/),
            (v4/*: any*/),
            (v6/*: any*/),
            (v9/*: any*/),
            (v11/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserDevice",
          "kind": "LinkedField",
          "name": "devices",
          "plural": true,
          "selections": [
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ActiveBlock",
              "kind": "LinkedField",
              "name": "activeBlock",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v6/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnblockFromUsingMultipleDevices_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnblockFromUsingQrCards_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnblockFromAgentDeposits_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnblockUserFromDormancy_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BlockUnblockDevice_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LegalEntity",
      "kind": "LinkedField",
      "name": "legalEntity",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "walletType",
              "value": "PERSONAL"
            }
          ],
          "concreteType": "Wallet",
          "kind": "LinkedField",
          "name": "wallets",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AgentUser",
                  "kind": "LinkedField",
                  "name": "agentUser",
                  "plural": false,
                  "selections": (v18/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MerchantUser",
                  "kind": "LinkedField",
                  "name": "merchantUser",
                  "plural": false,
                  "selections": (v18/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Promoter",
                  "kind": "LinkedField",
                  "name": "promoter",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "canKioskSignup",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "wallets(walletType:\"PERSONAL\")"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlockUnblockUser_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestToBlockUnblockUser_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestToUnblockFromUsingMultipleDevices_wallet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestToUnblockFromAgentDeposits_wallet"
    }
  ],
  "type": "Wallet",
  "abstractKey": null
};
})();
(node as any).hash = '846bcbe27acc75a7e330390ab59a3a84';
export default node;
