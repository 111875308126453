import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { LogoutAllUserSessionsMutator } from "@/pages/mobile.[mobile].personal/LogoutAllUserSessions";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const LogoutAllUserSessionsAction = (props: {
    user: Exclude<PersonalTabHeader_wallet["user"], null>;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("logout-user--action")}
            requiredPermissions={["supportapp_logout_all_user_sessions"]}
        >
            <LogoutAllUserSessionsMutator user={props.user} />
        </ActionButton>
    );
};
