/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PayoutTransferEntry_tx = {
    readonly tcid: string;
    readonly amount: string;
    readonly senderOpaqueId: string | null;
    readonly senderName: string;
    readonly recipientMobile: string;
    readonly isReversal: boolean;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "SupportPayoutTransferReverse_transfer" | "ChangePayoutRecipient_transfer">;
    readonly " $refType": "PayoutTransferEntry_tx";
};
export type PayoutTransferEntry_tx$data = PayoutTransferEntry_tx;
export type PayoutTransferEntry_tx$key = {
    readonly " $data"?: PayoutTransferEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PayoutTransferEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PayoutTransferEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tcid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderOpaqueId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isReversal",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SupportPayoutTransferReverse_transfer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangePayoutRecipient_transfer"
    }
  ],
  "type": "PayoutTransferEntry",
  "abstractKey": null
};
(node as any).hash = 'f145b60317651674d1c1ff6197c8e91c';
export default node;
