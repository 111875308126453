/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnblockFromUsingMultipleDevicesMutationVariables = {
    userId: string;
    reason: string;
    unlockOnce: boolean;
    forAgent: boolean;
};
export type UnblockFromUsingMultipleDevicesMutationResponse = {
    readonly unblockFromUsingMultipleDevices: {
        readonly user: {
            readonly " $fragmentRefs": FragmentRefs<"UnblockFromUsingMultipleDevices_user">;
        };
    } | null;
};
export type UnblockFromUsingMultipleDevicesMutation = {
    readonly response: UnblockFromUsingMultipleDevicesMutationResponse;
    readonly variables: UnblockFromUsingMultipleDevicesMutationVariables;
};



/*
mutation UnblockFromUsingMultipleDevicesMutation(
  $userId: ID!
  $reason: String!
  $unlockOnce: Boolean!
  $forAgent: Boolean!
) {
  unblockFromUsingMultipleDevices(userId: $userId, reason: $reason, unlockOnce: $unlockOnce, forAgent: $forAgent) {
    user {
      ...UnblockFromUsingMultipleDevices_user
      id
    }
  }
}

fragment DirectDepositBlockHistory_user on User {
  id
  narrativeDirectDepositBlockHistory {
    whatHappened
    whenHappened
    doneBy
    reason
  }
}

fragment UnblockFromUsingMultipleDevices_user on User {
  id
  personalMultipleDevicesBlock {
    blockReason
    blockedBy
    whenBlocked
    whenExpires
    lockedDevice {
      deviceId
      deviceName
      deviceModel
    }
    id
  }
  agentMultipleDevicesBlock {
    blockReason
    blockedBy
    whenBlocked
    whenExpires
    lockedDevice {
      deviceId
      deviceName
      deviceModel
    }
    id
  }
  ...DirectDepositBlockHistory_user
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forAgent"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unlockOnce"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "forAgent",
    "variableName": "forAgent"
  },
  {
    "kind": "Variable",
    "name": "reason",
    "variableName": "reason"
  },
  {
    "kind": "Variable",
    "name": "unlockOnce",
    "variableName": "unlockOnce"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "blockReason",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "blockedBy",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenBlocked",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "whenExpires",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UserDevice",
    "kind": "LinkedField",
    "name": "lockedDevice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deviceModel",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnblockFromUsingMultipleDevicesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UnblockFromUsingMultipleDevices",
        "kind": "LinkedField",
        "name": "unblockFromUsingMultipleDevices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UnblockFromUsingMultipleDevices_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnblockFromUsingMultipleDevicesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UnblockFromUsingMultipleDevices",
        "kind": "LinkedField",
        "name": "unblockFromUsingMultipleDevices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MultipleDevicesBlock",
                "kind": "LinkedField",
                "name": "personalMultipleDevicesBlock",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MultipleDevicesBlock",
                "kind": "LinkedField",
                "name": "agentMultipleDevicesBlock",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BlockHistoryEntry",
                "kind": "LinkedField",
                "name": "narrativeDirectDepositBlockHistory",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whatHappened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenHappened",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doneBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reason",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c6dafa7c84ab6e9a021419ac751172d",
    "id": null,
    "metadata": {},
    "name": "UnblockFromUsingMultipleDevicesMutation",
    "operationKind": "mutation",
    "text": "mutation UnblockFromUsingMultipleDevicesMutation(\n  $userId: ID!\n  $reason: String!\n  $unlockOnce: Boolean!\n  $forAgent: Boolean!\n) {\n  unblockFromUsingMultipleDevices(userId: $userId, reason: $reason, unlockOnce: $unlockOnce, forAgent: $forAgent) {\n    user {\n      ...UnblockFromUsingMultipleDevices_user\n      id\n    }\n  }\n}\n\nfragment DirectDepositBlockHistory_user on User {\n  id\n  narrativeDirectDepositBlockHistory {\n    whatHappened\n    whenHappened\n    doneBy\n    reason\n  }\n}\n\nfragment UnblockFromUsingMultipleDevices_user on User {\n  id\n  personalMultipleDevicesBlock {\n    blockReason\n    blockedBy\n    whenBlocked\n    whenExpires\n    lockedDevice {\n      deviceId\n      deviceName\n      deviceModel\n    }\n    id\n  }\n  agentMultipleDevicesBlock {\n    blockReason\n    blockedBy\n    whenBlocked\n    whenExpires\n    lockedDevice {\n      deviceId\n      deviceName\n      deviceModel\n    }\n    id\n  }\n  ...DirectDepositBlockHistory_user\n}\n"
  }
};
})();
(node as any).hash = '81c19ca48f8570f239d113f6f0c7bfab';
export default node;
