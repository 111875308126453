import { faIdCard, faSimCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { Timestamp } from "../../components/Timestamp";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { BlockUnblockUserMutationResponse } from "./__generated__/BlockUnblockUserMutation.graphql";
import { BlockUnblockUser_unregisteredMobile } from "./__generated__/BlockUnblockUser_unregisteredMobile.graphql";
import { BlockUnblockUser_wallet } from "./__generated__/BlockUnblockUser_wallet.graphql";

const mutation = graphql`
    mutation BlockUnblockUserMutation(
        $unblock: Boolean!
        $mobile: String!
        $reason: String!
        $byLegalEntity: Boolean!
    ) {
        blockUser(
            mobile: $mobile
            reason: $reason
            blockLegalEntity: $byLegalEntity
        ) @skip(if: $unblock) {
            success
            message
            wallet {
                ...BlockUnblockUser_wallet
            }
            unregisteredMobile {
                mobile
                ...BlockUnblockUser_unregisteredMobile
                ...UnregisteredMobileInfoHeader_unregisteredMobile
                ...NoUserPanel_unregisteredMobile
            }
        }

        unblockUser(
            mobile: $mobile
            reason: $reason
            unblockLegalEntity: $byLegalEntity
        ) @include(if: $unblock) {
            success
            message
            wallet {
                ...BlockUnblockUser_wallet
            }
            unregisteredMobile {
                mobile
                ...BlockUnblockUser_unregisteredMobile
                ...UnregisteredMobileInfoHeader_unregisteredMobile
                ...NoUserPanel_unregisteredMobile
            }
        }
    }
`;

type BlockUnblockUserInputParams = {
    wallet: BlockUnblockUser_wallet | null;
    unregisteredMobile: BlockUnblockUser_unregisteredMobile | null;
    unblock: boolean;
    byLegalEntity: boolean | null;
};

const _BlockUnblockUserMutator = (
    props: BlockUnblockUserInputParams & RelayModalProps
) => {
    const { onHide, unblock, byLegalEntity, wallet, unregisteredMobile } =
        props;

    const { t } = useTranslation();

    const reasonField = useTextField();

    const isMobileRegistered = wallet && !unregisteredMobile;
    const mobile = isMobileRegistered
        ? wallet.mobile
        : unregisteredMobile?.mobile;
    // use different help text / button text depending on whether we are
    // blocking a user or unblocking them
    const blockedOrUnblocked = unblock ? t("unblocked") : t("blocked");
    const blockUnblock = unblock ? t("unblock") : t("block");
    const subject = isMobileRegistered ? t("account") : t("mobile-number");

    let modalHelpText = isMobileRegistered ? (
        <p>
            <b>
                {byLegalEntity
                    ? t("block-user--legal-entity")
                    : t("block-user--account")}{" "}
                {t("block-user--will-be-blocked")}
            </b>
            <br />
            {t("block-user--block-description")}
        </p>
    ) : (
        <>
            <p>
                <b>{t("block-user--mobile-block-list")}</b>
                <br />
                {t("block-user--mobile-block-list--title")}
            </p>
            <ul>
                <li>{t("block-user--mobile-block-list--description-1")}</li>
                <li>{t("block-user--mobile-block-list--description-2")}</li>
                <li>{t("block-user--mobile-block-list--description-3")}</li>
            </ul>
        </>
    );

    if (unblock) {
        modalHelpText = isMobileRegistered ? (
            <p>
                <b>
                    {byLegalEntity
                        ? t("block-user--legal-entity")
                        : t("block-user--account")}{" "}
                    {t("block-user--will-be-unblocked")}
                </b>
                <br />
                {t("block-user--unblock-description")}
            </p>
        ) : (
            <p>{t("block-user--unblock-description--unregistered")}</p>
        );
    }
    const submitText = `${blockUnblock} ${
        isMobileRegistered ? t("user") : t("phone-number")
    }`;

    const onMutationSuccess = useCallback(
        (response: BlockUnblockUserMutationResponse) => {
            const success =
                (unblock
                    ? response.unblockUser?.success
                    : response.blockUser?.success) || false;

            const message =
                (unblock
                    ? response.unblockUser?.message
                    : response.blockUser?.message) || t("block-user--error");

            return (success ? "OK: " : "ERROR: ") + message;
        },
        [t, unblock]
    );
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: unblock ? Action.UnblockUser : Action.BlockUser,
            data: { mobile },
        },
        ...props,
    });
    const reasonValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!reasonField.value) {
            validations.push({
                type: "error",
                message: t("block-user--missing-reason", {
                    subject,
                    state: blockedOrUnblocked,
                }),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, reasonField.value, blockedOrUnblocked, subject]);
    const isValid = useMemo(() => {
        return !hasError([reasonValidations]);
    }, [reasonValidations]);
    const handleSubmit = () => {
        mutator.submit(mutation, {
            unblock: unblock,
            mobile: mobile,
            reason: reasonField.value.trim(),
            byLegalEntity: byLegalEntity,
        });
    };

    return (
        <MutatorModal
            {...mutator}
            title={`${blockUnblock} ${
                byLegalEntity ? t("legal-entity-for") : ""
            } ${subject} ${mobile}`}
            size={"xl"}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={submitText}
                onSubmit={handleSubmit}
                onDone={onHide}
                hideFieldsAfterSubmit={true}
            >
                {modalHelpText}
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
            <h3>{t("block-user-history--title")}</h3>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>{t("block-user-history--when-blocked")}</th>
                        <th>{t("block-user-history--why-blocked")}</th>
                        <th>{t("block-user-history--why-unblocked")}</th>
                        <th>{t("block-user-history--unblocked-by")}</th>
                        <th>{t("block-user-history--when-unblocked")}</th>
                    </tr>
                </thead>
                <tbody>
                    {(isMobileRegistered
                        ? wallet.blockHistory
                        : unregisteredMobile
                        ? unregisteredMobile.blockHistory
                        : []
                    ).map((block, i) => (
                        <tr key={i}>
                            <td>
                                {block.blockSubject == "LEGAL_ENTITY" ? (
                                    <FontAwesomeIcon
                                        icon={faIdCard}
                                        title={t("block-user--block-by-entity")}
                                    />
                                ) : block.blockSubject == "MOBILE" ? (
                                    <FontAwesomeIcon
                                        icon={faSimCard}
                                        title={t("block-user--block-by-number")}
                                    />
                                ) : (
                                    "&nbsp;"
                                )}
                            </td>
                            <td>
                                <Timestamp
                                    value={new Date(block.whenBlocked)}
                                    format={"MMM, DD YYYY HH:mm"}
                                />
                            </td>
                            <td>{block.blockReason}</td>
                            <td>
                                {block.whenUnblocked
                                    ? block.unblockReason
                                    : "–"}
                            </td>
                            <td>
                                {block.whenUnblocked ? block.unblockedBy : "–"}
                            </td>
                            <td>
                                {block.whenUnblocked ? (
                                    <Timestamp
                                        value={new Date(block.whenUnblocked)}
                                        format={"MMM, DD YYYY HH:mm"}
                                    />
                                ) : (
                                    "–"
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </MutatorModal>
    );
};

export const BlockUnblockUserMutator = createFragmentContainer(
    _BlockUnblockUserMutator,
    {
        wallet: graphql`
            fragment BlockUnblockUser_wallet on Wallet {
                id
                mobile
                activeMobileBlock {
                    id
                    blockReason
                    blockedBy
                    whenBlocked
                    blockSubject
                }
                activeLegalEntityBlock {
                    id
                    blockReason
                    blockedBy
                    whenBlocked
                    blockSubject
                }
                blockHistory {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenUnblocked
                    unblockedBy
                    unblockReason
                    blockSubject
                }
            }
        `,
        unregisteredMobile: graphql`
            fragment BlockUnblockUser_unregisteredMobile on UnregisteredMobile {
                mobile
                activeBlock {
                    id
                    blockReason
                    blockedBy
                    whenBlocked
                    blockSubject
                    whenExpires
                    requestToModifyBlockTickets {
                        status
                        whenOpened
                    }
                }
                blockHistory {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenUnblocked
                    unblockedBy
                    unblockReason
                    blockSubject
                }
            }
        `,
    }
);
