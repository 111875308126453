import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { mutate } from "@/external/relay";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";
import { useTrackEvent } from "@/hooks/useTrackEvent";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { RequestToUnblockFromUsingMultipleDevicesMutation } from "./__generated__/RequestToUnblockFromUsingMultipleDevicesMutation.graphql";
import { RequestToUnblockFromUsingMultipleDevices_wallet } from "./__generated__/RequestToUnblockFromUsingMultipleDevices_wallet.graphql";

const mutation = graphql`
    mutation RequestToUnblockFromUsingMultipleDevicesMutation(
        $mobile: String!
        $forAgent: Boolean!
        $reason: String!
    ) {
        requestToUnblockFromUsingMultipleDevices(
            mobile: $mobile
            reason: $reason
            forAgent: $forAgent
        ) {
            result
            wallet {
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
                user {
                    personalMultipleDevicesBlock {
                        blockReason
                        blockedBy
                        whenBlocked
                        lockedDevice {
                            deviceId
                            deviceName
                            deviceModel
                        }
                    }
                    agentMultipleDevicesBlock {
                        blockReason
                        blockedBy
                        whenBlocked
                        lockedDevice {
                            deviceId
                            deviceName
                            deviceModel
                        }
                    }
                }
            }
        }
    }
`;

type RequestToUnblockFromUsingMultipleDevicesInputParams = {
    wallet: RequestToUnblockFromUsingMultipleDevices_wallet;
    forAgent: boolean;
};

const _RequestToUnblockFromUsingMultipleDevicesMutator = (
    props: RequestToUnblockFromUsingMultipleDevicesInputParams & RelayModalProps
) => {
    const { wallet, forAgent } = props;

    const { t } = useTranslation();
    const trackEvent = useTrackEvent();

    const reasonField = useTextField();
    const mobile = wallet.mobile;

    const submitText = t("request-unblock-multiple-devices--submit");
    const modalHelpText = (
        <p>
            <b>
                {t("request-unblock-multiple-devices--description-1", {
                    subject: forAgent
                        ? t(
                              "request-unblock-multiple-devices--description--agent"
                          )
                        : t(
                              "request-unblock-multiple-devices--description--user"
                          ),
                })}
            </b>
            <br />
            {t("request-unblock-multiple-devices--description-2", {
                subject: forAgent
                    ? t("request-unblock-multiple-devices--description--agent")
                    : t("request-unblock-multiple-devices--description--user"),
            })}
        </p>
    );

    const requestToUnblockFromUsingMultipleDevicesRequest = useAsyncFunction(
        async () => {
            if (!props.relay) {
                return;
            }
            const [response, payloadErrors] =
                await mutate<RequestToUnblockFromUsingMultipleDevicesMutation>(
                    props.relay.environment,
                    mutation,
                    {
                        mobile: mobile,
                        forAgent: forAgent,
                        reason: reasonField.value,
                    }
                );
            if (payloadErrors) {
                return payloadErrors;
            }

            trackEvent(Action.RequestToUnblockFromUsingMultipleDevices);

            return response?.requestToUnblockFromUsingMultipleDevices?.result;
        }
    );

    const reasonValidations: Validation[] = [];
    if (!reasonField.value) {
        reasonValidations.push({
            type: "error",
            message: t("request-unblock-multiple-devices--missing-reason"),
            display: "if-blurred",
        });
    }
    const isValid = !hasError([reasonValidations]);
    const requestToUnblockFromUsingMultipleDevicesResultText =
        typeof requestToUnblockFromUsingMultipleDevicesRequest.result ===
        "string"
            ? requestToUnblockFromUsingMultipleDevicesRequest.result
            : null;
    const requestToUnblockFromUsingMultipleDevicesErrors = Array.isArray(
        requestToUnblockFromUsingMultipleDevicesRequest.result
    )
        ? requestToUnblockFromUsingMultipleDevicesRequest.result
        : requestToUnblockFromUsingMultipleDevicesRequest.error
        ? [requestToUnblockFromUsingMultipleDevicesRequest.error]
        : null;

    return (
        <MutatorModal
            title={submitText}
            isWorking={
                requestToUnblockFromUsingMultipleDevicesRequest.isLoading
            }
            show={props.show}
            onHide={props.onHide}
            errors={requestToUnblockFromUsingMultipleDevicesErrors}
        >
            {modalHelpText}
            <Form
                onSubmit={
                    requestToUnblockFromUsingMultipleDevicesRequest.invoke
                }
                submitText={submitText}
                isWorking={
                    requestToUnblockFromUsingMultipleDevicesRequest.isLoading
                }
                errors={requestToUnblockFromUsingMultipleDevicesErrors}
                isValid={isValid}
                result={requestToUnblockFromUsingMultipleDevicesResultText}
            >
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
        </MutatorModal>
    );
};

export const RequestToUnblockFromUsingMultipleDevicesMutator =
    createFragmentContainer(_RequestToUnblockFromUsingMultipleDevicesMutator, {
        wallet: graphql`
            fragment RequestToUnblockFromUsingMultipleDevices_wallet on Wallet {
                id
                mobile
                activeMobileBlock {
                    whenBlocked
                }
                activeLegalEntityBlock {
                    whenBlocked
                }
                blockHistory {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenUnblocked
                    unblockedBy
                    unblockReason
                    blockSubject
                }
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
            }
        `,
    });
