/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NoActionCallSurveyMutationVariables = {
    mobile: string;
    answer: unknown;
    textAnswer?: string | null | undefined;
    phoneCallId?: string | null | undefined;
};
export type NoActionCallSurveyMutationResponse = {
    readonly createSurveySubmission: {
        readonly surveySubmission: {
            readonly surveySubmissionId: string;
        };
        readonly supportNotes: {
            readonly " $fragmentRefs": FragmentRefs<"NotesList_supportNotes">;
        };
    } | null;
};
export type NoActionCallSurveyMutation = {
    readonly response: NoActionCallSurveyMutationResponse;
    readonly variables: NoActionCallSurveyMutationVariables;
};



/*
mutation NoActionCallSurveyMutation(
  $mobile: String!
  $answer: JSONString!
  $textAnswer: String
  $phoneCallId: String
) {
  createSurveySubmission(mobile: $mobile, answer: $answer, textAnswer: $textAnswer, phoneCallId: $phoneCallId) {
    surveySubmission {
      surveySubmissionId
    }
    supportNotes {
      ...NotesList_supportNotes
      id
    }
  }
}

fragment NotesList_supportNotes on SupportNotes {
  list {
    noteId
    text
    mobile
    adminUsername
    whenCreated
    whenUpdated
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "answer"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneCallId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "textAnswer"
},
v4 = [
  {
    "kind": "Variable",
    "name": "answer",
    "variableName": "answer"
  },
  {
    "kind": "Variable",
    "name": "mobile",
    "variableName": "mobile"
  },
  {
    "kind": "Variable",
    "name": "phoneCallId",
    "variableName": "phoneCallId"
  },
  {
    "kind": "Variable",
    "name": "textAnswer",
    "variableName": "textAnswer"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SurveySubmission",
  "kind": "LinkedField",
  "name": "surveySubmission",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surveySubmissionId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NoActionCallSurveyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CreateSurveySubmission",
        "kind": "LinkedField",
        "name": "createSurveySubmission",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportNotes",
            "kind": "LinkedField",
            "name": "supportNotes",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotesList_supportNotes"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "NoActionCallSurveyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CreateSurveySubmission",
        "kind": "LinkedField",
        "name": "createSurveySubmission",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportNotes",
            "kind": "LinkedField",
            "name": "supportNotes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SupportNote",
                "kind": "LinkedField",
                "name": "list",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noteId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mobile",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adminUsername",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenCreated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "whenUpdated",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c94514e51e6821d2c81c33d288a943b3",
    "id": null,
    "metadata": {},
    "name": "NoActionCallSurveyMutation",
    "operationKind": "mutation",
    "text": "mutation NoActionCallSurveyMutation(\n  $mobile: String!\n  $answer: JSONString!\n  $textAnswer: String\n  $phoneCallId: String\n) {\n  createSurveySubmission(mobile: $mobile, answer: $answer, textAnswer: $textAnswer, phoneCallId: $phoneCallId) {\n    surveySubmission {\n      surveySubmissionId\n    }\n    supportNotes {\n      ...NotesList_supportNotes\n      id\n    }\n  }\n}\n\nfragment NotesList_supportNotes on SupportNotes {\n  list {\n    noteId\n    text\n    mobile\n    adminUsername\n    whenCreated\n    whenUpdated\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cb3098704ba49b58478aad9d02f3f4c8';
export default node;
