import { faSimCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ActionButton } from "@/components/ActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { BlockUnblockUserMutator } from "@/pages/mobile.[mobile]/BlockUnblockUser";
import { RequestToBlockUnblockUserMutator } from "@/pages/mobile.[mobile]/RequestToBlockUnblockUser";
import { RequestToModifyBlockMutator } from "@/pages/mobile.[mobile]/RequestToModifyBlock";
import { UpdateBlockMutator } from "@/pages/mobile.[mobile]/UpdateBlock";
import { DeviceBlockBanner } from "@/pages/mobile.[mobile]/WalletUserBlocks";

import { UnregisteredMobileBlocks_blockData } from "./__generated__/UnregisteredMobileBlocks_blockData.graphql";

import { BlockBanner } from "./BlockBanner";

const _UnregisteredMobileBlocks = (props: {
    blockData: UnregisteredMobileBlocks_blockData;
}) => {
    const { blockData } = props;

    const { t } = useTranslation();

    const actions = [];
    const block = blockData.activeBlock;
    const pendingUnblockRequestTickets = (
        block?.requestToModifyBlockTickets || []
    ).filter((t) => t?.status == "OPEN");

    if (usePermissions("supportapp_request_to_unblock_wallet")) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    pendingUnblockRequestTickets.length > 0
                        ? t(
                              "unregistered-mobile-block--action-request-unblock-in-progress"
                          )
                        : t("unregistered-mobile-block--action-request-unblock")
                }
                title={
                    pendingUnblockRequestTickets.length > 0
                        ? `${t(
                              "unregistered-mobile-block--action-request-unblock-in-progress"
                          )} ${pendingUnblockRequestTickets[0]?.whenOpened}`
                        : ""
                }
                disabled={pendingUnblockRequestTickets.length > 0}
                requiredPermissions={["supportapp_request_to_unblock_wallet"]}
                variant={"outline-light"}
            >
                <RequestToBlockUnblockUserMutator
                    unregisteredMobile={blockData}
                    wallet={null}
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_unblock_user")) {
        actions.push(
            <ActionButton
                as="button"
                name={t("action-unblock")}
                requiredPermissions={["supportapp_unblock_user"]}
                variant={"outline-light"}
            >
                <BlockUnblockUserMutator
                    unregisteredMobile={blockData}
                    wallet={null}
                    unblock={true}
                    byLegalEntity={false}
                />
            </ActionButton>
        );
    }

    const hasOpenModifyRequests =
        (block?.requestToModifyBlockTickets || []).filter(
            (r) => r?.status == "OPEN"
        ).length > 0;
    if (
        usePermissions("supportapp_request_to_modify_block") &&
        block &&
        !!block?.whenExpires
    ) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    hasOpenModifyRequests
                        ? t(
                              "unregistered-mobile-block--action-modify-submitted"
                          )
                        : t("unregistered-mobile-block--action-modify")
                }
                requiredPermissions={["supportapp_request_to_modify_block"]}
                variant={"outline-light"}
                disabled={hasOpenModifyRequests}
            >
                <RequestToModifyBlockMutator
                    block={block}
                    requestToUnblock={false} // request to make permanent, not to unblock
                    mobile={blockData.mobile}
                    title={t("unregistered-mobile-block--permanent--title")}
                    body={
                        <>
                            {t(
                                "unregistered-mobile-block--permanent--description"
                            )}
                        </>
                    }
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_block_user") && !!block?.whenExpires) {
        actions.push(
            <ActionButton
                as="button"
                name={t("unregistered-mobile-block--action-make-permanent")}
                requiredPermissions={["supportapp_block_user"]}
                variant={"outline-light"}
            >
                <BlockUnblockUserMutator
                    unregisteredMobile={blockData}
                    wallet={null}
                    unblock={false}
                    byLegalEntity={false}
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_update_block") && block) {
        actions.push(
            <ActionButton
                as="button"
                name={t("unregistered-mobile-block--action-edit-reason")}
                requiredPermissions={["supportapp_update_block"]}
                variant="outline-light"
            >
                <UpdateBlockMutator activeBlock={block} />
            </ActionButton>
        );
    }

    // If it is a temporary block, add a button (or buttons) to make it permanent.
    const temporary = block?.whenExpires;
    const title = t("unregistered-mobile-block--block-notice--title", {
        status: temporary
            ? t("unregistered-mobile-block--temporary")
            : t("unregistered-mobile-block--complete"),
    });
    const icon = faSimCard;
    return (
        <>
            <BlockBanner
                title={title}
                blockType={"full"}
                icon={<FontAwesomeIcon icon={icon} />}
                block={block}
                actions={actions}
            />
            {(blockData.devices || []).map((device, deviceIndex) => (
                <DeviceBlockBanner
                    key={deviceIndex}
                    deviceId={device.deviceId}
                    unregisteredMobile={props.blockData}
                />
            ))}
        </>
    );
};

export const UnregisteredMobileBlocks = createFragmentContainer(
    _UnregisteredMobileBlocks,
    {
        blockData: graphql`
            fragment UnregisteredMobileBlocks_blockData on UnregisteredMobile {
                ...RequestToBlockUnblockUser_unregisteredMobile
                ...BlockUnblockUser_unregisteredMobile
                mobile
                activeBlock {
                    ...RequestToModifyBlock_activeBlock
                    ...UpdateBlock_activeBlock
                    blockReason
                    blockedBy
                    whenBlocked
                    blockSubject
                    whenExpires
                    requestToModifyBlockTickets {
                        status
                        whenOpened
                    }
                }
                blockHistory {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenUnblocked
                    unblockedBy
                    unblockReason
                    blockSubject
                }
                tickets {
                    whenOpened
                    whenResolved
                    status
                    slackMessageUrl
                    ticketType
                    reference
                }
                devices {
                    deviceId
                    deviceName
                    deviceModel
                    activeBlock {
                        id
                        blockReason
                        blockedBy
                        whenBlocked
                        whenExpires
                        requestToModifyBlockTickets {
                            status
                            whenOpened
                        }
                        ...RequestToModifyBlock_activeBlock
                        ...UpdateBlock_activeBlock
                    }
                }
            }
        `,
    }
);
