/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type MerchantTransferSentEntry_tx = {
    readonly tx_id: string;
    readonly sender: string;
    readonly mobile: string;
    readonly recipientName: string;
    readonly recipientMobile: string;
    readonly maybeTerminatedRecipientMobile: string;
    readonly amount: string;
    readonly scamReports: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly transactionIds: string | null;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "RefundP2P_transfer">;
    readonly " $refType": "MerchantTransferSentEntry_tx";
};
export type MerchantTransferSentEntry_tx$data = MerchantTransferSentEntry_tx;
export type MerchantTransferSentEntry_tx$key = {
    readonly " $data"?: MerchantTransferSentEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MerchantTransferSentEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MerchantTransferSentEntry_tx",
  "selections": [
    {
      "alias": "tx_id",
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": "sender",
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": "mobile",
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedRecipientMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamReportTicket",
      "kind": "LinkedField",
      "name": "scamReports",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenOpened",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenResolved",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefundP2P_transfer"
    }
  ],
  "type": "TransferSentEntry",
  "abstractKey": null
};
(node as any).hash = 'db0064e838426d73ca60c81d934c6116';
export default node;
