import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { ProfileLink } from "@/components/ProfileLink";
import { Timestamp } from "@/components/Timestamp";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { FreezeTransactionHistoryActionButton } from "@/pages/mobile.[mobile].personal/FreezeTransactionHistoryActionButton";

import {
    CAN_REFUND_BULK_TRANSFER,
    CAN_REFUND_P2P_TX,
} from "@/types/Permissions";

import { TransferReceivedEntry_tx } from "./__generated__/TransferReceivedEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ChangeRecipientMutator } from "./ChangeRecipient";
import { RefundP2PMutator } from "./RefundP2P";

function _TransferReceivedEntry(
    props: {
        tx: TransferReceivedEntry_tx;
        reversedTransfers: {
            [key: string]: Date;
        };
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx, reversedTransfers } = props;
    const whenReversed = reversedTransfers[tx.transferId];
    const isReversed = !!whenReversed;

    const changeRecipientPermission =
        tx.paymentBatchId === null
            ? "supportapp_change_recipient"
            : "supportapp_change_bulk_transfer_recipient";

    const hasSupportAppChangeRecipientPermission = usePermissions(
        changeRecipientPermission
    );

    const refundPermission =
        tx.paymentBatchId === null
            ? CAN_REFUND_P2P_TX
            : CAN_REFUND_BULK_TRANSFER;
    const hasSupportAppRefundPermission = usePermissions(refundPermission);

    const buttons = isReversed ? null : (
        <Fragment>
            <HistoryActionButton
                name={t("refund--action")}
                disabled={!hasSupportAppRefundPermission}
            >
                <RefundP2PMutator transfer={tx} />
            </HistoryActionButton>
            <HistoryActionButton
                name={t("change-recipient--action")}
                disabled={!hasSupportAppChangeRecipientPermission}
            >
                <ChangeRecipientMutator transfer={tx} />
            </HistoryActionButton>
            <FreezeTransactionHistoryActionButton
                transferOrLedgerTransactionId={tx.transferId}
                recipientMobile={tx.recipientMobile}
                receiveAmount={tx.receiveAmount}
            />
        </Fragment>
    );

    const info = isReversed ? (
        <TxInfo>
            {tx.transferId}
            &nbsp;&#40;{t("transfer-received-entry--reversed")}{" "}
            <Timestamp value={whenReversed} />)
        </TxInfo>
    ) : (
        <TxInfo>{tx.transferId}</TxInfo>
    ); // &#40 is ( but for some reason ( breaks syntax highlighting...

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <Fragment>
                    <TxTitle>
                        {t("transfer-received-entry--title")}{" "}
                        {tx.maybeTerminatedSenderMobile === "unknown" ? (
                            <>
                                {tx.senderName} (
                                {t("transfer-received-business")})
                            </>
                        ) : (
                            <ProfileLink
                                name={tx.senderName}
                                mobile={tx.maybeTerminatedSenderMobile}
                                opaqueId={tx.transferId}
                            />
                        )}
                    </TxTitle>
                    {info}
                </Fragment>
            }
            buttons={buttons}
        />
    );
}
export default createFragmentContainer(_TransferReceivedEntry, {
    tx: graphql`
        fragment TransferReceivedEntry_tx on TransferReceivedEntry {
            transferId
            senderName
            senderMobile
            maybeTerminatedSenderMobile
            paymentBatchId
            recipientMobile
            receiveAmount
            ...HistoryEntry_entry
            ...ChangeRecipient_transfer
            ...RefundP2P_transfer
        }
    `,
});
