import * as React from "react";
import { ButtonGroup, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ActionButton } from "@/components/ActionButton";
import { Timestamp } from "@/components/Timestamp";

import { usePermissions } from "@/hooks/usePermissions";
import { useSupportUserId } from "@/hooks/useSupportUserId";

import { DeleteSupportNote } from "@/pages/mobile.[mobile].notes/DeleteSupportNote";
import { EditSupportNote } from "@/pages/mobile.[mobile].notes/EditSupportNote";

import { CAN_DELETE_NOTES, CAN_EDIT_NOTES } from "@/types/Permissions";

import { NotesList_supportNotes } from "./__generated__/NotesList_supportNotes.graphql";

type NotesTabDataProps = {
    mobile: string;
    supportNotes: NotesList_supportNotes;
};

function _NotesList(props: NotesTabDataProps) {
    const supportUserId = useSupportUserId();

    const canDeleteNotes = usePermissions(CAN_DELETE_NOTES);
    const canEditNotes = usePermissions(CAN_EDIT_NOTES);

    const { t } = useTranslation();

    const notes = props.supportNotes.list
        ?.filter((n) => n != null)
        ?.reverse()
        .map((note) => {
            if (!note) {
                return <></>;
            }

            const timestampCreated = new Date(note.whenCreated);
            const timestampUpdated = new Date(note.whenUpdated);

            // The values of `whenCreated` and `whenUpdated` are slightly off by a few
            // ms when a note is created, so we add a 1-sec grace period to consider if
            // a note has been edited.
            const hasNoteBeenEdited =
                timestampUpdated.getTime() - timestampCreated.getTime() >= 1000;

            const showDeleteNoteButton =
                note.adminUsername === supportUserId || canDeleteNotes;
            const showEditNoteButton =
                note.adminUsername === supportUserId || canEditNotes;
            const showNoteActionButtons =
                showDeleteNoteButton || showEditNoteButton;

            return (
                <span
                    key={note.noteId}
                    className="m-2 relative border-b border-gray-200"
                >
                    <h5 className="notes-list-item-user">
                        {note.adminUsername}
                    </h5>
                    <h6
                        className="pl-2"
                        dangerouslySetInnerHTML={{ __html: note.text || "" }}
                    />
                    <span className="italic">
                        <Timestamp value={timestampCreated} />{" "}
                        {hasNoteBeenEdited && (
                            <span>
                                ({t("notes-list--edited")}{" "}
                                <Timestamp value={timestampUpdated} />)
                            </span>
                        )}
                    </span>

                    {showNoteActionButtons && (
                        <div className="absolute top-3 right-2">
                            <ButtonGroup size="sm">
                                <DropdownButton
                                    variant="outline-dark"
                                    className="transaction-row-button float-right"
                                    title=""
                                    id={`notes-list-${note.noteId}`}
                                    data-testid="note-action-btn"
                                >
                                    {showEditNoteButton && (
                                        <ActionButton
                                            name={t("action-edit")}
                                            as="menuitem"
                                        >
                                            <EditSupportNote
                                                mobile={props.mobile}
                                                noteId={note.noteId}
                                                noteText={note.text || ""}
                                            ></EditSupportNote>
                                        </ActionButton>
                                    )}
                                    {showDeleteNoteButton && (
                                        <ActionButton
                                            name={t("action-delete")}
                                            as="menuitem"
                                        >
                                            <DeleteSupportNote
                                                mobile={props.mobile}
                                                noteId={note.noteId}
                                                noteText={note.text}
                                            ></DeleteSupportNote>
                                        </ActionButton>
                                    )}
                                </DropdownButton>
                            </ButtonGroup>
                        </div>
                    )}
                </span>
            );
        });

    return <div className="flex flex-col gap-4">{notes}</div>;
}

export const NotesList = createFragmentContainer(_NotesList, {
    supportNotes: graphql`
        fragment NotesList_supportNotes on SupportNotes {
            list {
                noteId
                text
                mobile
                adminUsername
                whenCreated
                whenUpdated
            }
        }
    `,
});
