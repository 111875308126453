import {
    faChevronDown,
    faChevronRight,
    faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

dayjs.extend(relativeTime);

/*
NOTE(sophie):
This is a first foray into separating 'general' banners in the Info Header from block banners
(e.g. phone being blocked due to abuse). Ideally, in the future, the BlockBanner would use the
more universal Banner internally (and the functionality would be narrowed down better).

The current code (2022-11-03) is a first, time-boxed, best effort to get to a starting point.
*/

const formatDate = (date?: string) =>
    date ? dayjs(date).format("MMM D, YYYY HH:mm") : "";

interface Props {
    info: {
        whenSet: string;
        whenExpires?: string | null;
        reason: string;
        setBy: string;
    } | null;
    title: string;
    icon: ReactNode;
    infoType: "severe" | "warning";
    details?: { [key: string]: string | ReactNode };
}

export const Banner = (props: Props) => {
    const { infoType, info, title, icon, details } = props;
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();

    const toggleExpanded = () => setExpanded(!expanded);

    const fullDetails = {
        Reason: info?.reason && (
            <>
                {info.reason}
                <TranslationLink stringToTranslate={info.reason} />
            </>
        ),
        Since: formatDate(info?.whenSet),
        Until: !!info?.whenExpires && formatDate(info?.whenExpires),
        ...details,
    };

    return (
        <div
            className={`min-w-[60ch] w-2/5 relative p-2 cursor-pointer text-left inline-flex m-1 rounded transition-all ${
                infoType === "severe" ? "text-white bg-red-800" : ""
            }${infoType === "warning" ? "text-black bg-amber-500" : ""} ${
                expanded ? "expanded" : "collapsed"
            }`}
            onClick={toggleExpanded}
        >
            <div className={`pl-2`}>
                <FontAwesomeIcon
                    className="w-4"
                    icon={expanded ? faChevronDown : faChevronRight}
                />
            </div>
            <div
                className={
                    "flex-grow-0 flex-shrink-0 opacity-70 px-2 transition-all"
                }
            >
                {icon}
            </div>
            <div className={"flex-grow-1 flex-shrink-0"}>
                {expanded && (
                    <div className={"text-sm"}>
                        {info?.whenSet && dayjs(info?.whenSet).fromNow()}{" "}
                        <span className="opacity-50">by</span> {info?.setBy}
                    </div>
                )}
                <div className={`font-bold`}>
                    {title}
                    {info?.whenExpires &&
                        t("info-notice--when-expires", {
                            date: dayjs(info?.whenExpires).fromNow(),
                        })}
                </div>
                {expanded && (
                    <div
                        className="text-sm cursor-text"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {Object.entries(fullDetails || {})
                            .filter(([_k, v]) => !!v)
                            .map(([k, v], di) => (
                                <InfoRow key={di} title={k}>
                                    {v}
                                </InfoRow>
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

interface TranslationLinkProps {
    stringToTranslate?: string;
}
const TranslationLink = ({ stringToTranslate = "" }: TranslationLinkProps) => {
    const { t } = useTranslation();
    return (
        <a
            href={`https://translate.google.com/?sl=en&tl=fr&text=${encodeURIComponent(
                stringToTranslate
            )}&op=translate`}
            rel={"noreferrer"}
            target={"_blank"}
            className="m-2 align-middle opacity-70 text-base text-blue-600 hover:opacity-90 hover:text-black"
            title={t("google-translate")}
        >
            <FontAwesomeIcon icon={faLanguage} />
        </a>
    );
};

interface InfoRowProps {
    title: string;
    children: ReactNode;
}

const InfoRow = ({ title, children }: InfoRowProps) => (
    <>
        <span>
            <span className="opacity-50">{`${title}: `}</span>
            {children}
        </span>
        <br />
    </>
);
