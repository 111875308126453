/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MerchantIssueType = "ADD_REMOVE_ASSISTANT" | "BUSINESS_PROPOSAL_FOLLOW_UP" | "KYB3_REQUEST" | "NOT_SEEING_PAYMENTS" | "OTHER" | "REFUND_REQUEST_MERCHANT_ERROR" | "REFUND_REQUEST_USER_ERROR" | "%future added value";
export type EscalateToMerchantSupportMutationVariables = {
    mobile: string;
    reporter: string;
    issueType: MerchantIssueType;
    comments: string;
    merchantUfid?: string | null | undefined;
};
export type EscalateToMerchantSupportMutationResponse = {
    readonly escalateToMerchantSupport: {
        readonly result: string | null;
    } | null;
};
export type EscalateToMerchantSupportMutation = {
    readonly response: EscalateToMerchantSupportMutationResponse;
    readonly variables: EscalateToMerchantSupportMutationVariables;
};



/*
mutation EscalateToMerchantSupportMutation(
  $mobile: String!
  $reporter: String!
  $issueType: MerchantIssueType!
  $comments: String!
  $merchantUfid: String
) {
  escalateToMerchantSupport(mobile: $mobile, reporter: $reporter, issueType: $issueType, comments: $comments, merchantUfid: $merchantUfid) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comments"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "merchantUfid"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reporter"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comments",
        "variableName": "comments"
      },
      {
        "kind": "Variable",
        "name": "issueType",
        "variableName": "issueType"
      },
      {
        "kind": "Variable",
        "name": "merchantUfid",
        "variableName": "merchantUfid"
      },
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "reporter",
        "variableName": "reporter"
      }
    ],
    "concreteType": "EscalateToMerchantSupport",
    "kind": "LinkedField",
    "name": "escalateToMerchantSupport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EscalateToMerchantSupportMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EscalateToMerchantSupportMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "c66f4dc733661cd3306f65c488090165",
    "id": null,
    "metadata": {},
    "name": "EscalateToMerchantSupportMutation",
    "operationKind": "mutation",
    "text": "mutation EscalateToMerchantSupportMutation(\n  $mobile: String!\n  $reporter: String!\n  $issueType: MerchantIssueType!\n  $comments: String!\n  $merchantUfid: String\n) {\n  escalateToMerchantSupport(mobile: $mobile, reporter: $reporter, issueType: $issueType, comments: $comments, merchantUfid: $merchantUfid) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '578167c6233c4b3588db9eeab81b4aba';
export default node;
