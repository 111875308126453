import { createFragmentContainer, graphql } from "react-relay";

import { HistoryEntryProps } from "@/pages/mobile.[mobile]/HistoryEntry";

import { UserLinkedAccountTransferW2BEntry_tx } from "./__generated__/UserLinkedAccountTransferW2BEntry_tx.graphql";

import { createUserLinkedAccountW2BTransferEntry } from "./UserLinkedAccountBankTransferEntryFactory";

interface UserLinkedAccountTransferW2BEntryProps {
    tx: UserLinkedAccountTransferW2BEntry_tx;
}

function _UserLinkedAccountTransferW2BEntry(
    props: UserLinkedAccountTransferW2BEntryProps & HistoryEntryProps
) {
    return createUserLinkedAccountW2BTransferEntry(props);
}

export default createFragmentContainer(_UserLinkedAccountTransferW2BEntry, {
    tx: graphql`
        fragment UserLinkedAccountTransferW2BEntry_tx on UserLinkedAccountTransferW2BEntry {
            summary
            isCancelled
            partnerName
            liaTransferId
            errorCode
            errorMessage
            ...HistoryEntry_entry
        }
    `,
});
