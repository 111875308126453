import * as React from "react";
import { useCallback, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { LoadingIcon } from "@/components/Loading";
import { Timestamp } from "@/components/Timestamp";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { ShowSMSCodeMutationResponse } from "./__generated__/ShowSMSCodeMutation.graphql";

const mutation = graphql`
    mutation ShowSMSCodeMutation($mobile: String!) {
        showSmsCode(userId: null, mobile: $mobile) {
            result {
                code
                whenExpires
            }
        }
    }
`;

type SMSCode = {
    code: string;
    whenExpires: Date;
};

type ShowSMSCodeMutatorProps = {
    mobile: string;
};

const _ShowSMSCodeMutator = (
    props: ShowSMSCodeMutatorProps & RelayModalProps
) => {
    const { mobile } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ShowSMSCodeMutationResponse): SMSCode | null => {
            const { showSmsCode } = response;
            if (!showSmsCode) {
                return null;
            }
            const { result } = showSmsCode;

            if (!result) {
                return null;
            }

            return {
                code: result.code,
                whenExpires: new Date(result.whenExpires),
            };
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: { name: Action.ShowSmsCode, data: { mobile } },
        ...props,
    });
    useEffect(() => {
        if (props.show) {
            mutator.submit(mutation, { mobile });
        }
    }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps

    let contentsNode: React.ReactNode = null;
    if (mutator.isWorking) {
        contentsNode = (
            <>
                <LoadingIcon /> {t("show-sms-code--loading")}
            </>
        );
    } else if (mutator.errors && mutator.errors.length > 0) {
        contentsNode = (
            <>
                <h2>{t("show-sms-code--error-title")}</h2>
                <p>
                    {t("show-sms-code--error-description")}
                    {mutator.errors}
                </p>
            </>
        );
    } else if (mutator.result === null) {
        contentsNode = (
            <>
                <h2>{t("show-sms-code--no-code--title")}</h2>
                <p>{t("show-sms-code--no-code--description-1", { mobile })}</p>
                <p>{t("show-sms-code--no-code--description-2")}</p>
            </>
        );
    } else if (mutator.result.whenExpires < new Date()) {
        contentsNode = (
            <>
                <h2>{t("show-sms-code--expired--title")}</h2>
                <p>
                    {t("show-sms-code--expired--description-1")}{" "}
                    <Timestamp value={mutator.result.whenExpires} />.
                </p>
                <p>{t("show-sms-code--expired--description-2")}</p>
            </>
        );
    } else {
        contentsNode = (
            <Alert variant="success">
                <h1>{mutator.result.code}</h1> ({t("show-sms-code--expires-at")}{" "}
                <Timestamp value={mutator.result.whenExpires} />)
            </Alert>
        );
    }
    return (
        <MutatorModal
            {...mutator}
            showErrors
            title={t("show-sms-code--title", { mobile })}
        >
            {contentsNode}
        </MutatorModal>
    );
};

export const ShowSMSCodeMutator = createFragmentContainer(
    _ShowSMSCodeMutator,
    {}
);
