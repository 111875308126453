import { Timestamp } from "../../components/Timestamp";

import i18n from "@/locales/i18n";

import { PersonalTabHeader_wallet } from "@/pages/mobile.[mobile].personal/__generated__/PersonalTabHeader_wallet.graphql";

import { notEmpty } from "@/utils/value";

export type SupportGroupLeadEscalations =
    PersonalTabHeader_wallet["supportGroupLeadEscalations"];
export type SupportPartialRefundEscalations =
    PersonalTabHeader_wallet["supportPartialRefundEscalations"];
type SupportGroupLeadEscalationsProps = {
    tickets?: SupportGroupLeadEscalations | SupportPartialRefundEscalations;
};

export const SupportGroupLeadEscalationsHistoryTable = ({
    tickets,
}: SupportGroupLeadEscalationsProps) => {
    if (tickets == null || tickets.length === 0) {
        return (
            <tr>
                <td colSpan={4} align="center">
                    {i18n.t("no-escalations")}
                </td>
            </tr>
        );
    }

    return (
        <>
            {tickets.filter(notEmpty).map((ticket, i) => {
                const whenOpened = (
                    <Timestamp value={new Date(ticket.whenOpened)} />
                );

                return (
                    <tr key={i}>
                        <td>{whenOpened}</td>
                        <td>{ticket.status}</td>
                        <td>
                            {ticket.clickupTaskId ? (
                                <a
                                    href={`https://app.clickup.com/t/${ticket.clickupTaskId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-blue-600"
                                >
                                    {ticket.clickupTaskId}
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>
                    </tr>
                );
            })}
        </>
    );
};
