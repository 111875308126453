import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { useNavigation } from "@/hooks/useNavigation";

import { TerminateUserMutationResponse } from "@/pages/mobile.[mobile].personal/__generated__/TerminateUserMutation.graphql";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

type TerminateUserParams = {
    mobile: string;
};

const mutation = graphql`
    mutation TerminateUserMutation(
        $mobile: String!
        $terminationReason: String!
    ) {
        terminateUser(mobile: $mobile, terminationReason: $terminationReason) {
            result
            isTerminated
        }
    }
`;
const _TerminateUserMutator = (
    props: TerminateUserParams & RelayModalProps
) => {
    const { onHide, mobile } = props;

    const { t } = useTranslation();

    const { goToProfileByMobile } = useNavigation();

    const onMutationSuccess = useCallback(
        (response: TerminateUserMutationResponse) => {
            if (response.terminateUser?.isTerminated) {
                response.terminateUser.result
                    ? goToProfileByMobile(response.terminateUser.result)
                    : undefined;
            }
            return response.terminateUser?.result || "";
        },
        [goToProfileByMobile]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.TerminateUser,
            data: { mobile },
        },
        ...props,
    });

    const terminationReasonField = useTextField();

    const reasonValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (terminationReasonField.value.length == 0) {
            validations.push({
                display: "always",
                type: "error",
                message: t("form-validation--missing-reason"),
            });
        }
        return validations;
    }, [t, terminationReasonField.value]);

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobile: props.mobile,
            terminationReason: terminationReasonField.value,
        });
    }, [mutator, props.mobile, terminationReasonField.value]);
    return (
        <MutatorModal {...mutator} title={t("terminate-user--title")}>
            <p>{t("terminate-user--warning")}</p>
            <br />
            <Form
                {...mutator}
                isValid={!hasError([reasonValidations])}
                submitText={t("terminate-user--action")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <p>
                    {t("label-customer-mobile")}: {mobile}
                </p>
                <br />

                <TextField
                    {...terminationReasonField}
                    label={t("label-reason")}
                    name="terminationReason"
                    validations={reasonValidations}
                    defaultValue={""}
                    as="textarea"
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};
export const TerminateUserMutator = createFragmentContainer(
    _TerminateUserMutator,
    {}
);
