import { useCallback } from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { ForgetQrMutationResponse } from "./__generated__/ForgetQrMutation.graphql";
import { ForgetQr_user } from "./__generated__/ForgetQr_user.graphql";

const mutation = graphql`
    mutation ForgetQrMutation($userId: ID!) {
        forgetQr(userId: $userId) {
            qrText
        }
    }
`;

type ForgetQrMutatorInputParams = {
    user: ForgetQr_user;
};

const PinCodeStatusBadge = (props: { needsNewPin: boolean }) => {
    const hasPinSetUp = !props.needsNewPin;
    const { t } = useTranslation();

    return (
        <Badge className="text" variant={hasPinSetUp ? "info" : "warning"}>
            {hasPinSetUp
                ? t("forget-qr--pin-set")
                : t("forget-qr--pin-not-set")}
        </Badge>
    );
};

const _ForgetQrMutator = (
    props: ForgetQrMutatorInputParams & RelayModalProps
) => {
    const { user, onHide } = props;
    const userId = user.id;
    const userName = user.primaryWallet?.name || user.name;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ForgetQrMutationResponse) => {
            return response.forgetQr
                ? response.forgetQr.qrText
                    ? t("forget-qr--success")
                    : t("forget-qr--error")
                : "";
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: { name: Action.ForgetQr, data: { userId } },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { userId });
    }, [mutator, userId]);

    return (
        <MutatorModal
            {...mutator}
            title={t("forget-qr--title", { name: userName })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-confirm")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <p>
                    <PinCodeStatusBadge needsNewPin={user.needsNewPin} />
                </p>
                <p>{t("forget-qr--description")}</p>
                <p>{t("forget-qr--disclaimer")}</p>
            </Form>
        </MutatorModal>
    );
};

export const ForgetQrMutator = createFragmentContainer(_ForgetQrMutator, {
    user: graphql`
        fragment ForgetQr_user on User {
            id
            name
            needsNewPin
            primaryWallet {
                name
            }
        }
    `,
});
