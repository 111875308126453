import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const OpenStandalone = () => {
    const { t } = useTranslation();

    const loc = window.location;
    const targetPath = loc.origin + loc.pathname;
    return (
        <a target="_blank" rel="noreferrer" href={targetPath}>
            <Button
                className="ml-2"
                variant="outline-primary"
                title={t("open-standalone")}
            >
                &#11016;
            </Button>
        </a>
    );
};
