/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ReportCustomerScamMutationVariables = {
    mobile: string;
    transactionId: string;
    amountStolen: string;
    customerSharedPinCode: boolean;
    customerSharedSmsCode: boolean;
    additionalInfo: string;
};
export type ReportCustomerScamMutationResponse = {
    readonly reportCustomerScam: {
        readonly result: string | null;
    } | null;
};
export type ReportCustomerScamMutation = {
    readonly response: ReportCustomerScamMutationResponse;
    readonly variables: ReportCustomerScamMutationVariables;
};



/*
mutation ReportCustomerScamMutation(
  $mobile: String!
  $transactionId: ID!
  $amountStolen: Money!
  $customerSharedPinCode: Boolean!
  $customerSharedSmsCode: Boolean!
  $additionalInfo: String!
) {
  reportCustomerScam(mobile: $mobile, transactionId: $transactionId, amount: $amountStolen, customerSharedPinCode: $customerSharedPinCode, customerSharedSmsCode: $customerSharedSmsCode, additionalInfo: $additionalInfo) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "additionalInfo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountStolen"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerSharedPinCode"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customerSharedSmsCode"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transactionId"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "additionalInfo",
        "variableName": "additionalInfo"
      },
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amountStolen"
      },
      {
        "kind": "Variable",
        "name": "customerSharedPinCode",
        "variableName": "customerSharedPinCode"
      },
      {
        "kind": "Variable",
        "name": "customerSharedSmsCode",
        "variableName": "customerSharedSmsCode"
      },
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "transactionId",
        "variableName": "transactionId"
      }
    ],
    "concreteType": "ReportCustomerScam",
    "kind": "LinkedField",
    "name": "reportCustomerScam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportCustomerScamMutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportCustomerScamMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "1f61aca7ec2b33063b517038af3b1bb5",
    "id": null,
    "metadata": {},
    "name": "ReportCustomerScamMutation",
    "operationKind": "mutation",
    "text": "mutation ReportCustomerScamMutation(\n  $mobile: String!\n  $transactionId: ID!\n  $amountStolen: Money!\n  $customerSharedPinCode: Boolean!\n  $customerSharedSmsCode: Boolean!\n  $additionalInfo: String!\n) {\n  reportCustomerScam(mobile: $mobile, transactionId: $transactionId, amount: $amountStolen, customerSharedPinCode: $customerSharedPinCode, customerSharedSmsCode: $customerSharedSmsCode, additionalInfo: $additionalInfo) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c213bb1a577b18d6c56537e3278ab974';
export default node;
