import i18n from "@/locales/i18n";

/**
 * Returns the language name based on its abbreviation
 * @param lang {String} - Language abbreviation (ex. "swa")
 * @return {String} Language name
 */
const languages: Record<string, string> = {
    swa: i18n.t("lang-swa"),
    nyn: i18n.t("lang-nyn"),
    eng: i18n.t("lang-en"),
};
export function getLanguageName(lang: string): string {
    return languages[lang] || i18n.t("lang-none");
}
