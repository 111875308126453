import { faMobileAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import Select, {
    CSSObjectWithLabel,
    DropdownIndicatorProps,
    MultiValue,
    SingleValue,
    SingleValueProps,
    components,
} from "react-select";

import { ConfirmationModal } from "@/components/ConfirmationModal";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { WHEN_QUERY_PARAM_NAME } from "@/utils/navigation";

interface Option {
    value: string;
    label: string;
}
export interface DeviceFilterProps {
    devices: Array<{
        deviceId: string;
        deviceModel: string;
        whenLoggedOut: string | null;
    }>;
    deviceIdFilter: string | null;
    setDeviceIdFilter: (deviceId: string | null) => void;
}

function isMultiValue<T>(
    arg: MultiValue<T> | SingleValue<T>
): arg is MultiValue<T> {
    return Array.isArray(arg);
}

export const DeviceFilter = (props: DeviceFilterProps) => {
    const { devices, deviceIdFilter, setDeviceIdFilter } = props;
    const { t } = useTranslation();
    const { search } = useLocation();
    const trackEvent = useTrackEvent();

    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [modalConfirmDeviceId, setModalConfirmDeviceId] = React.useState<
        string | null
    >(null);

    const onSelect = (option: MultiValue<Option> | SingleValue<Option>) => {
        const newValue = !option || isMultiValue(option) ? null : option.value;

        const params = new URLSearchParams(search);
        if (params.get(WHEN_QUERY_PARAM_NAME)) {
            trackEvent("device id filter: overriding when");
            setModalConfirmDeviceId(newValue);
            setShowConfirmation(true);
        } else {
            trackEvent("device id filter");
            setDeviceIdFilter(newValue);
        }
    };

    const options = React.useMemo(
        () =>
            devices.map(({ deviceId, deviceModel, whenLoggedOut }) => ({
                value: deviceId,
                // If the same device model is present multiple times, we add device id in parenthesis
                label: (
                    <span>
                        {!whenLoggedOut && (
                            <FontAwesomeIcon
                                icon={faUser}
                                className="mr-2 w-3"
                                title="Logged in"
                            />
                        )}
                        {deviceModel}
                        {devices.filter(
                            ({ deviceModel: _deviceModel }) =>
                                _deviceModel == deviceModel
                        ).length > 1
                            ? ` (${deviceId})`
                            : ""}
                    </span>
                ),
            })),
        [devices]
    );

    const selectedOption = options.find(
        (option) => option.value == deviceIdFilter
    );

    const SingleValue = ({ children, ...props }: SingleValueProps<Option>) => (
        <div
            className="overflow-hidden flex justify-between"
            style={{ gridArea: "1/1/2/3" }}
        >
            <components.SingleValue {...props}>
                {children}
            </components.SingleValue>
            <FontAwesomeIcon
                icon={faMobileAlt}
                color="darkgray"
                data-toggle
                className="cursor-pointer mx-2 my-1"
            />
        </div>
    );

    const DropdownIndicator = ({
        children,
        ...props
    }: DropdownIndicatorProps<Option>) => {
        if (props.hasValue) return null;
        return (
            <components.DropdownIndicator {...props}>
                {children}
            </components.DropdownIndicator>
        );
    };

    const customStyles = {
        control: (base: CSSObjectWithLabel) => ({
            ...base,
            height: "2rem",
            minHeight: "2rem",
        }),
        valueContainer: (base: CSSObjectWithLabel) => ({
            ...base,
            height: "2rem",
            padding: "0 6px",
        }),
        input: (base: CSSObjectWithLabel) => ({
            ...base,
            margin: "0px",
        }),
        indicatorsContainer: (base: CSSObjectWithLabel) => ({
            ...base,
            height: "2rem",
        }),
    };

    return (
        <div className="max-h-8" style={{ width: "250px" }}>
            <Select
                components={{
                    SingleValue,
                    DropdownIndicator,
                    IndicatorSeparator: null,
                }}
                // Types in the library seem to be defined incorrectly. They expect a string label, but JSX element works just fine
                value={selectedOption as any}
                options={options as any}
                onChange={onSelect}
                placeholder={t("filter-by-device--placeholder")}
                isClearable
                styles={customStyles}
            />
            <ConfirmationModal
                confirmationText={t("filter-by-device--disclaimer")}
                show={showConfirmation}
                onCancel={() => {
                    trackEvent("device id filter: cancel override");
                    setShowConfirmation(false);
                }}
                onConfirm={() => {
                    trackEvent("device id filter: confirm override");
                    setShowConfirmation(false);
                    setDeviceIdFilter(modalConfirmDeviceId);
                }}
            />
        </div>
    );
};
