/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LiveCallMetricsQueryVariables = {
    showMissedCallsCount: boolean;
    showGeneralMetrics: boolean;
    showTotalCallsInQueue: boolean;
};
export type LiveCallMetricsQueryResponse = {
    readonly supportLiveCallMetrics: {
        readonly callsInQueue: {
            readonly count: number;
        };
        readonly missedCallRate?: {
            readonly fiveMinutes: number;
        } | undefined;
        readonly unansweredCallCount?: {
            readonly count: number;
        } | null | undefined;
        readonly totalCallsInQueue?: {
            readonly count: number;
        } | null | undefined;
    };
};
export type LiveCallMetricsQuery = {
    readonly response: LiveCallMetricsQueryResponse;
    readonly variables: LiveCallMetricsQueryVariables;
};



/*
query LiveCallMetricsQuery(
  $showMissedCallsCount: Boolean!
  $showGeneralMetrics: Boolean!
  $showTotalCallsInQueue: Boolean!
) {
  supportLiveCallMetrics {
    callsInQueue {
      count
    }
    missedCallRate @include(if: $showGeneralMetrics) {
      fiveMinutes
    }
    unansweredCallCount @include(if: $showMissedCallsCount) {
      count
    }
    totalCallsInQueue @include(if: $showTotalCallsInQueue) {
      count
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showGeneralMetrics"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showMissedCallsCount"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "showTotalCallsInQueue"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SupportLiveCallMetrics",
    "kind": "LinkedField",
    "name": "supportLiveCallMetrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SupportCallsInQueuePerRep",
        "kind": "LinkedField",
        "name": "callsInQueue",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "condition": "showGeneralMetrics",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportMissedCallRate",
            "kind": "LinkedField",
            "name": "missedCallRate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fiveMinutes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "showMissedCallsCount",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportUnansweredCalls",
            "kind": "LinkedField",
            "name": "unansweredCallCount",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "showTotalCallsInQueue",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportCallsInQueue",
            "kind": "LinkedField",
            "name": "totalCallsInQueue",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveCallMetricsQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LiveCallMetricsQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "179934982dcf5175879eff32097a46f1",
    "id": null,
    "metadata": {},
    "name": "LiveCallMetricsQuery",
    "operationKind": "query",
    "text": "query LiveCallMetricsQuery(\n  $showMissedCallsCount: Boolean!\n  $showGeneralMetrics: Boolean!\n  $showTotalCallsInQueue: Boolean!\n) {\n  supportLiveCallMetrics {\n    callsInQueue {\n      count\n    }\n    missedCallRate @include(if: $showGeneralMetrics) {\n      fiveMinutes\n    }\n    unansweredCallCount @include(if: $showMissedCallsCount) {\n      count\n    }\n    totalCallsInQueue @include(if: $showTotalCallsInQueue) {\n      count\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd042862492ce5f1520a8042fd7a41c3c';
export default node;
