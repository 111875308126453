import * as Sentry from "@sentry/react";
import React, { Component } from "react";

import i18n from "@/locales/i18n";

interface InfiniteScrollProps {
    hasMoreAtStart: boolean;
    loadAtStart: () => Promise<void>;
    hasMoreAtEnd: boolean;
    loadAtEnd: () => Promise<void>;
    children: Array<React.ReactElement>;
    jumpTo?: React.ReactElement;
    loadingIndicator?: React.ReactElement;
}

interface InfiniteScrollState {
    loadingAtStart: boolean;
    loadingAtEnd: boolean;
}

const DefaultLoadingIndicator = (
    <div className="d-flex justify-content-center mt-1 mb-1">
        <div className="spinner-border" role="status"></div>
    </div>
);
export default class InfiniteScroll extends Component<
    InfiniteScrollProps,
    InfiniteScrollState
> {
    private loadDistanceThreshold = 15; // pixel
    private containerRef = React.createRef<HTMLDivElement>();
    private lastScrollTop = 0;
    private needsScrolling = false;

    state = {
        loadingAtStart: false,
        loadingAtEnd: false,
    };

    componentDidMount() {
        const container = this.containerRef.current;
        if (!container || !this.props.jumpTo) {
            return;
        }
        const i = this.props.children.indexOf(this.props.jumpTo);
        // We offset by 1 because the first child is the loading indicator
        const focusedElement = container.children.item(i + 1);
        if (focusedElement && focusedElement instanceof HTMLElement) {
            focusedElement.scrollIntoView(true /* align to top */);
        }
    }

    componentDidUpdate(prevProps: Readonly<InfiniteScrollProps>) {
        const container = this.containerRef.current;
        if (!container) {
            return;
        }
        const newChildren =
            this.props.children.length - prevProps.children.length;
        if (newChildren > 0 && this.needsScrolling) {
            this.needsScrolling = false;
            // We offset by 1 because the first child is the loading indicator
            const firstElement = container.children.item(1);
            const firstOldElement = container.children.item(newChildren + 1);
            if (
                firstElement instanceof HTMLElement &&
                firstOldElement instanceof HTMLElement
            ) {
                const dY = firstOldElement.offsetTop - firstElement.offsetTop;
                container.scrollBy(0, dY);
            }
        }
    }

    onScroll = async (e: React.UIEvent<HTMLDivElement>) => {
        const element = e.currentTarget;
        const direction =
            this.lastScrollTop < element.scrollTop ? "DOWN" : "UP";
        this.lastScrollTop = element.scrollTop;
        const closeToStart = element.scrollTop < this.loadDistanceThreshold;
        if (
            direction === "UP" &&
            closeToStart &&
            this.props.hasMoreAtStart &&
            !this.state.loadingAtStart
        ) {
            await this.loadAtStart();
        }
        const scrollBottom =
            element.scrollHeight - element.scrollTop - element.clientHeight;
        const closeToEnd = scrollBottom < this.loadDistanceThreshold;
        if (
            direction === "DOWN" &&
            closeToEnd &&
            this.props.hasMoreAtEnd &&
            !this.state.loadingAtEnd
        ) {
            await this.loadAtEnd();
        }
    };

    loadAtStart = async () => {
        this.setState({ loadingAtStart: true });
        this.needsScrolling = true;
        try {
            await this.props.loadAtStart();
        } catch (e) {
            Sentry.captureException(e);
        }
        this.setState({ loadingAtStart: false });
    };

    loadAtEnd = async () => {
        this.setState({ loadingAtEnd: true });
        try {
            await this.props.loadAtEnd();
        } catch (e) {
            Sentry.captureException(e);
        }
        this.setState({ loadingAtEnd: false });
    };

    render() {
        const { loadingAtStart, loadingAtEnd } = this.state;
        const { hasMoreAtStart, hasMoreAtEnd } = this.props;
        return (
            <div
                className="overflow-auto"
                onScroll={this.onScroll}
                ref={this.containerRef}
            >
                <div>
                    <div
                        className={`justify-center p-1 ${
                            !loadingAtStart && hasMoreAtStart
                                ? "flex"
                                : "hidden"
                        }`}
                    >
                        <button
                            className="btn btn-primary"
                            onClick={this.loadAtStart}
                        >
                            {i18n.t("infinite-scroll--more")}
                        </button>
                    </div>
                    <div
                        className={`${
                            this.state.loadingAtStart ? "block" : "hidden"
                        }`}
                    >
                        {this.props.loadingIndicator || DefaultLoadingIndicator}
                    </div>
                </div>
                {this.props.children}
                {/* Adding an extra element at the bottom of the list to ensure there is enough room for the action items to properly display */}
                <div className="h-[100px]"></div>
                <div>
                    <div
                        className={`justify-center p-1 ${
                            !loadingAtEnd && hasMoreAtEnd ? "flex" : "hidden"
                        }`}
                    >
                        <button
                            className="btn btn-primary"
                            onClick={this.loadAtEnd}
                        >
                            {i18n.t("infinite-scroll--more")}
                        </button>
                    </div>
                    <div
                        className={`${
                            this.state.loadingAtEnd ? "block" : "hidden"
                        }`}
                    >
                        {this.props.loadingIndicator || DefaultLoadingIndicator}
                    </div>
                </div>
            </div>
        );
    }
}
