import { createFragmentContainer, graphql } from "react-relay";

import { delegateHistoryEntryComponent } from "@/pages/mobile.[mobile]/HistoryEntry";

import MerchantAtxEntry from "./MerchantAtxEntry";
import MerchantSaleEntry from "./MerchantSaleEntry";
import MerchantTransferSentEntry from "./MerchantTransferSentEntry";
import PayoutTransferSentEntry from "./PayoutTransferSentEntry";

type MerchantHistoryEntryProps = {
    reversedTransfers: {
        [key: string]: Date;
    };
};

const TX_TYPE_RENDERERS: {
    [key: string]: any & MerchantHistoryEntryProps;
} = {
    MerchantSaleEntry,
    TransferSentEntry: MerchantTransferSentEntry,
    AgentTransactionEntry: MerchantAtxEntry,
    PayoutTransferEntry: PayoutTransferSentEntry,
};

function _MerchantHistoryEntry(
    props: { entry: { [key: string]: any } } & MerchantHistoryEntryProps
) {
    return delegateHistoryEntryComponent(
        props.entry.__typename,
        TX_TYPE_RENDERERS,
        { activeDeviceBlocks: [], ...props }
    );
}

export default createFragmentContainer(_MerchantHistoryEntry, {
    entry: graphql`
        fragment MerchantHistoryEntry_entry on HistoryEntry {
            __typename
            ...HistoryEntry_entry
            ...MerchantSaleEntry_tx
            ...MerchantTransferSentEntry_tx
            ...MerchantAtxEntry_tx
            ...PayoutTransferEntry_tx
            ...PayoutTransferSentEntry_tx
        }
    `,
});
