import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QueryRenderer, graphql, useRelayEnvironment } from "react-relay";
import { useLocation } from "react-router";

import { LoadingIndicator } from "@/components/Loading";

import { WrongBackendError } from "@/external/relay";

import { useFilterByDeviceId } from "@/hooks/useFilterByDeviceId";
import { useJumpToDate } from "@/hooks/useJumpToDate";
import { usePermissions } from "@/hooks/usePermissions";

import { History } from "@/pages/mobile.[mobile]/History";

import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";

import { OPAQUE_ID_QUERY_PARAM_NAME } from "@/utils/navigation";

import { PersonalTabQuery } from "./__generated__/PersonalTabQuery.graphql";

import { PersonalTabHeader } from "./PersonalTabHeader";

const personalTabQuery = graphql`
    query PersonalTabQuery(
        $mobile: String!
        $historyAround: ID
        $when: DateTime
        $deviceId: String
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
    ) {
        wallet(mobile: $mobile) {
            mobile
            balance @include(if: $canReadBalance)
            user {
                id
                mobile
            }
            ...PersonalTabHeader_wallet
                @arguments(canReadBalance: $canReadBalance)
            ...History_wallet
                @arguments(
                    last: 30
                    around: $historyAround
                    when: $when
                    deviceId: $deviceId
                )
                @include(if: $canReadHistory)
        }
    }
`;

const FetchError = () => {
    const { t } = useTranslation();

    return (
        <div>
            {t("personal-tab--error-network")}{" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://frontplugin.wave-internal.com/backend_proxy/"
                className="text-blue-600"
            >
                https://frontplugin.wave-internal.com/backend_proxy/
            </a>
        </div>
    );
};

/**
 * Contains a `QueryRenderer` which, depending on whether we deal with the `User`-less wallet,
 * or a proper registered user, issues an appropriate query and wraps `_PersonalTab` into an
 * appropriate wrapper. This trick saves us from retrieving the history twice.
 *
 * @param component_props
 *  `mobile` - mobile number for the profile
 *
 *  The props are resolved and provided by `ProfileView`.
 */
type PersonalTabProps = {
    mobile: string;
};

export const PersonalTab = ({ mobile }: PersonalTabProps) => {
    const environment = useRelayEnvironment();
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const opaqueId = params.get(OPAQUE_ID_QUERY_PARAM_NAME);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const canReadHistory = usePermissions(CAN_READ_HISTORY);

    const { date, setWhen } = useJumpToDate();
    const { deviceIdFilter, setDeviceIdFilter } = useFilterByDeviceId();

    const { t } = useTranslation();

    return (
        <QueryRenderer<PersonalTabQuery>
            environment={environment}
            render={({ error, props }) => {
                if (error) {
                    if (error.message === "Failed to fetch")
                        return <FetchError />;
                    if (error instanceof WrongBackendError) throw error;

                    return (
                        <div>
                            {t("personal-tab--error")} {error.message}
                        </div>
                    );
                }

                if (!props) return <LoadingIndicator />;

                const { wallet } = props;

                if (!wallet) return <div>{t("personal-tab--error")}</div>;

                return (
                    <div data-testid="personal-tab-view">
                        <PersonalTabHeader
                            wallet={wallet}
                            when={date}
                            setWhen={setWhen}
                            deviceIdFilter={deviceIdFilter}
                            setDeviceIdFilter={setDeviceIdFilter}
                        />
                        {canReadHistory ? (
                            <History
                                wallet={wallet}
                                jumpTo={opaqueId || undefined}
                            />
                        ) : (
                            <div className="text-center">
                                {t("personal-tab--no-permission")}
                            </div>
                        )}
                    </div>
                );
            }}
            fetchPolicy="store-and-network"
            query={personalTabQuery}
            variables={{
                mobile,
                historyAround: opaqueId,
                when: date?.toISOString(),
                canReadBalance,
                canReadHistory,
                deviceId: deviceIdFilter,
            }}
        />
    );
};
