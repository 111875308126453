import { useTranslation } from "react-i18next";
import { createFragmentContainer } from "react-relay";
import { graphql } from "relay-runtime";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";

const mutation = graphql`
    mutation DeleteSupportNoteMutation($noteId: ID!) {
        deleteSupportNote(noteId: $noteId) {
            note {
                noteId
            }
            supportNotes {
                ...NotesList_supportNotes
            }
        }
    }
`;

type DeleteSupportNoteProps = {
    mobile: string;
    noteId: string;
    noteText: string | null;
};

const _DeleteSupportNote = (
    props: DeleteSupportNoteProps & RelayModalProps
) => {
    const { mobile, noteId, noteText } = props;
    const { onHide } = props;

    const { t } = useTranslation();

    const mutator = useMutator({
        onMutationSuccess: () => t("delete-support-note--success"),
        trackActionInfo: { name: "delete note", data: { mobile, noteId } },
        ...props,
    });

    return (
        <MutatorModal {...mutator} title={t("delete-support-note--title")}>
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-delete")}
                onSubmit={() => mutator.submit(mutation, { noteId })}
                onDone={onHide}
            >
                <p>{t("delete-support-note--description")}</p>
                <pre className={"whitespace-normal mt-2"}>{noteText}</pre>
            </Form>
        </MutatorModal>
    );
};

export const DeleteSupportNote = createFragmentContainer(
    _DeleteSupportNote,
    {}
);
