import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { makeEnvironment } from "@/external/relay";

import { DEFAULT_COUNTRY } from "@/types/country";

const LOCALSTORAGE_COUNTRY_KEY = "country";

function useCountryScopedEnvironment() {
    /* This custom hook ties together the handling of the current country and updating it. */
    const initialCountry = useMemo(() => {
        return (
            localStorage.getItem(LOCALSTORAGE_COUNTRY_KEY) || DEFAULT_COUNTRY
        );
    }, []);
    const [country, updateCountryRaw] = useState(initialCountry);
    const [countries, updateCountriesRaw] = useState<readonly string[]>([
        initialCountry,
    ]);
    const environment = useMemo(() => makeEnvironment(country), [country]);
    const updateCountry = useCallback(
        // Will only update the selected country if it is one of the available countries
        (newCountry: string) => {
            const country = countries.includes(newCountry)
                ? newCountry
                : countries[0];
            if (country) {
                localStorage.setItem(LOCALSTORAGE_COUNTRY_KEY, country);
                updateCountryRaw(country);
            }
        },
        [countries, updateCountryRaw]
    );
    const updateCountries = useCallback(
        // Will only update the list of countries if it changed
        (newCountries: readonly string[]) => {
            if (!isEqual(newCountries, countries)) {
                updateCountriesRaw(newCountries);
            }
            // if we don't have permission to access the current country, we need to switch to another country
            if (!newCountries.includes(country)) {
                updateCountry(newCountries[0] ?? DEFAULT_COUNTRY);
            }
        },
        [country, updateCountry, countries, updateCountriesRaw]
    );
    return {
        country,
        updateCountry,
        countries,
        updateCountries,
        environment,
    };
}

export default useCountryScopedEnvironment;
