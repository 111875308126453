/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type RequestToModifyBlockMutationVariables = {
    id: string;
    mobile: string;
    unblock: boolean;
    reason: string;
};
export type RequestToModifyBlockMutationResponse = {
    readonly requestToModifyBlock: {
        readonly result: string;
        readonly block: {
            readonly id: string;
            readonly requestToModifyBlockTickets: ReadonlyArray<{
                readonly status: TicketStatus;
                readonly whenOpened: string;
            }> | null;
        };
    } | null;
};
export type RequestToModifyBlockMutation = {
    readonly response: RequestToModifyBlockMutationResponse;
    readonly variables: RequestToModifyBlockMutationVariables;
};



/*
mutation RequestToModifyBlockMutation(
  $id: ID!
  $mobile: String!
  $unblock: Boolean!
  $reason: String!
) {
  requestToModifyBlock(id: $id, mobile: $mobile, unblock: $unblock, reason: $reason) {
    result
    block {
      id
      requestToModifyBlockTickets {
        status
        whenOpened
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reason"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unblock"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "reason",
        "variableName": "reason"
      },
      {
        "kind": "Variable",
        "name": "unblock",
        "variableName": "unblock"
      }
    ],
    "concreteType": "RequestToModifyBlock",
    "kind": "LinkedField",
    "name": "requestToModifyBlock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ActiveBlock",
        "kind": "LinkedField",
        "name": "block",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TicketShortInfo",
            "kind": "LinkedField",
            "name": "requestToModifyBlockTickets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "whenOpened",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestToModifyBlockMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RequestToModifyBlockMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "8c8bbd83763bd6eb6b66afa4cb745bc6",
    "id": null,
    "metadata": {},
    "name": "RequestToModifyBlockMutation",
    "operationKind": "mutation",
    "text": "mutation RequestToModifyBlockMutation(\n  $id: ID!\n  $mobile: String!\n  $unblock: Boolean!\n  $reason: String!\n) {\n  requestToModifyBlock(id: $id, mobile: $mobile, unblock: $unblock, reason: $reason) {\n    result\n    block {\n      id\n      requestToModifyBlockTickets {\n        status\n        whenOpened\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1342d69894f3c1bb25e1db9a87b8ddf1';
export default node;
