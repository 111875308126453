/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type RequestToModifyBlock_activeBlock = {
    readonly id: string;
    readonly requestToModifyBlockTickets: ReadonlyArray<{
        readonly status: TicketStatus;
        readonly whenOpened: string;
    }> | null;
    readonly " $refType": "RequestToModifyBlock_activeBlock";
};
export type RequestToModifyBlock_activeBlock$data = RequestToModifyBlock_activeBlock;
export type RequestToModifyBlock_activeBlock$key = {
    readonly " $data"?: RequestToModifyBlock_activeBlock$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"RequestToModifyBlock_activeBlock">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RequestToModifyBlock_activeBlock",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketShortInfo",
      "kind": "LinkedField",
      "name": "requestToModifyBlockTickets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenOpened",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActiveBlock",
  "abstractKey": null
};
(node as any).hash = '83a888c824b75989ecae2985187c0f31';
export default node;
