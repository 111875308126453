/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProfileSearcherQueryVariables = {
    query: string;
    wallets: boolean;
    agents: boolean;
    merchants: boolean;
};
export type ProfileSearcherQueryResponse = {
    readonly searchWallets?: ReadonlyArray<{
        readonly id: string;
        readonly mobile: string;
        readonly name: string;
        readonly balance: string;
        readonly sendFeeFormula: string | null;
        readonly whenTerminated: string | null;
    } | null> | undefined;
    readonly searchAgents?: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly city: string | null;
        readonly subcity: string | null;
        readonly ufid: string;
        readonly isActive: boolean;
        readonly floatWallet: {
            readonly id: string;
            readonly balance: string;
        };
    } | null> | undefined;
    readonly searchAgentCities?: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly city: string | null;
        readonly subcity: string | null;
        readonly ufid: string;
        readonly isActive: boolean;
        readonly floatWallet: {
            readonly id: string;
            readonly balance: string;
        };
    } | null> | undefined;
    readonly searchMerchants?: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly city: string | null;
        readonly subcity: string | null;
        readonly ufid: string;
        readonly isActive: boolean;
        readonly principal: {
            readonly mobile: string | null;
        };
    } | null> | undefined;
};
export type ProfileSearcherQuery = {
    readonly response: ProfileSearcherQueryResponse;
    readonly variables: ProfileSearcherQueryVariables;
};



/*
query ProfileSearcherQuery(
  $query: String!
  $wallets: Boolean!
  $agents: Boolean!
  $merchants: Boolean!
) {
  searchWallets(query: $query) @include(if: $wallets) {
    id
    mobile
    name
    balance
    sendFeeFormula
    whenTerminated
  }
  searchAgents(query: $query) @include(if: $agents) {
    id
    name
    city
    subcity
    ufid
    isActive
    floatWallet {
      id
      balance
    }
  }
  searchAgentCities(query: $query) @include(if: $agents) {
    id
    name
    city
    subcity
    ufid
    isActive
    floatWallet {
      id
      balance
    }
  }
  searchMerchants(query: $query) @include(if: $merchants) {
    id
    name
    city
    subcity
    ufid
    isActive
    principal {
      mobile
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agents"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "merchants"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wallets"
},
v4 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
},
v9 = {
  "condition": "wallets",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": (v4/*: any*/),
      "concreteType": "Wallet",
      "kind": "LinkedField",
      "name": "searchWallets",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sendFeeFormula",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenTerminated",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcity",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ufid",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v14 = [
  (v5/*: any*/),
  (v7/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Wallet",
    "kind": "LinkedField",
    "name": "floatWallet",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      (v8/*: any*/)
    ],
    "storageKey": null
  }
],
v15 = {
  "condition": "agents",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": (v4/*: any*/),
      "concreteType": "Agent",
      "kind": "LinkedField",
      "name": "searchAgents",
      "plural": true,
      "selections": (v14/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "concreteType": "Agent",
      "kind": "LinkedField",
      "name": "searchAgentCities",
      "plural": true,
      "selections": (v14/*: any*/),
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileSearcherQuery",
    "selections": [
      (v9/*: any*/),
      (v15/*: any*/),
      {
        "condition": "merchants",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Merchant",
            "kind": "LinkedField",
            "name": "searchMerchants",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "principal",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfileSearcherQuery",
    "selections": [
      (v9/*: any*/),
      (v15/*: any*/),
      {
        "condition": "merchants",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Merchant",
            "kind": "LinkedField",
            "name": "searchMerchants",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "principal",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "59fd384cdce8278ae448a2b7afad17d5",
    "id": null,
    "metadata": {},
    "name": "ProfileSearcherQuery",
    "operationKind": "query",
    "text": "query ProfileSearcherQuery(\n  $query: String!\n  $wallets: Boolean!\n  $agents: Boolean!\n  $merchants: Boolean!\n) {\n  searchWallets(query: $query) @include(if: $wallets) {\n    id\n    mobile\n    name\n    balance\n    sendFeeFormula\n    whenTerminated\n  }\n  searchAgents(query: $query) @include(if: $agents) {\n    id\n    name\n    city\n    subcity\n    ufid\n    isActive\n    floatWallet {\n      id\n      balance\n    }\n  }\n  searchAgentCities(query: $query) @include(if: $agents) {\n    id\n    name\n    city\n    subcity\n    ufid\n    isActive\n    floatWallet {\n      id\n      balance\n    }\n  }\n  searchMerchants(query: $query) @include(if: $merchants) {\n    id\n    name\n    city\n    subcity\n    ufid\n    isActive\n    principal {\n      mobile\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3a8148e845136af020aec404c55c3592';
export default node;
