/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MerchantSaleEntry_tx = {
    readonly maybeTransferId: string | null;
    readonly maybeSenderName: string | null;
    readonly maybeSenderMobile: string | null;
    readonly maybeTerminatedSenderMobile: string | null;
    readonly qrText: string | null;
    readonly merchantUName: string | null;
    readonly actionSource: string | null;
    readonly isRefunded: boolean;
    readonly isRemote: boolean;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry" | "RefundMerchantSale_transfer">;
    readonly " $refType": "MerchantSaleEntry_tx";
};
export type MerchantSaleEntry_tx$data = MerchantSaleEntry_tx;
export type MerchantSaleEntry_tx$key = {
    readonly " $data"?: MerchantSaleEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MerchantSaleEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MerchantSaleEntry_tx",
  "selections": [
    {
      "alias": "maybeTransferId",
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": "maybeSenderName",
      "args": null,
      "kind": "ScalarField",
      "name": "senderName",
      "storageKey": null
    },
    {
      "alias": "maybeSenderMobile",
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedSenderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qrText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "merchantUName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionSource",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRefunded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemote",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefundMerchantSale_transfer"
    }
  ],
  "type": "MerchantSaleEntry",
  "abstractKey": null
};
(node as any).hash = '98a72f3712c87a2041bb6092a89d1db0';
export default node;
