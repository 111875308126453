/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type TicketType = "AgentIssueReport" | "AgentScamReport" | "AmlMatch" | "B2WOrW2BCustomerProblem" | "BankRebalanceRequest" | "BillPayProblem" | "BusinessFundingRequest" | "KYBEscalation" | "MerchantComplianceFollowup" | "MerchantSupportEscalation" | "PaymentCardProblem" | "PoliceRequisition" | "RebalancingDifficulty" | "RefundPartialFunds" | "RefundingDispute" | "RequestToBlockWallet" | "RequestToExplainCommissionCuts" | "RequestToModifyBlockTicket" | "ScamReport" | "ScreeningResult" | "SpamCallerReport" | "SupportGroupLeadEscalation" | "%future added value";
export type RequestToUnblockFromAgentDepositsMutationVariables = {
    mobile: string;
    reason: string;
};
export type RequestToUnblockFromAgentDepositsMutationResponse = {
    readonly requestToUnblockFromAgentDeposits: {
        readonly result: string;
        readonly wallet: {
            readonly user: {
                readonly agentDepositsBlock: {
                    readonly whenBlocked: string;
                    readonly blockedBy: string;
                    readonly blockReason: string;
                } | null;
            } | null;
            readonly tickets: ReadonlyArray<{
                readonly whenOpened: string;
                readonly whenResolved: string | null;
                readonly status: TicketStatus;
                readonly slackMessageUrl: string | null;
                readonly ticketType: TicketType;
                readonly reference: string | null;
            } | null>;
        } | null;
    } | null;
};
export type RequestToUnblockFromAgentDepositsMutation = {
    readonly response: RequestToUnblockFromAgentDepositsMutationResponse;
    readonly variables: RequestToUnblockFromAgentDepositsMutationVariables;
};



/*
mutation RequestToUnblockFromAgentDepositsMutation(
  $mobile: String!
  $reason: String!
) {
  requestToUnblockFromAgentDeposits(mobile: $mobile, reason: $reason) {
    result
    wallet {
      user {
        agentDepositsBlock {
          whenBlocked
          blockedBy
          blockReason
          id
        }
        id
      }
      tickets {
        whenOpened
        whenResolved
        status
        slackMessageUrl
        ticketType
        reference
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reason"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "mobile",
    "variableName": "mobile"
  },
  {
    "kind": "Variable",
    "name": "reason",
    "variableName": "reason"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "result",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenBlocked",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockedBy",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blockReason",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TicketShortInfo",
  "kind": "LinkedField",
  "name": "tickets",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenOpened",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "whenResolved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackMessageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ticketType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reference",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestToUnblockFromAgentDepositsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestToUnblockFromAgentDeposits",
        "kind": "LinkedField",
        "name": "requestToUnblockFromAgentDeposits",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActiveBlock",
                    "kind": "LinkedField",
                    "name": "agentDepositsBlock",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestToUnblockFromAgentDepositsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestToUnblockFromAgentDeposits",
        "kind": "LinkedField",
        "name": "requestToUnblockFromAgentDeposits",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Wallet",
            "kind": "LinkedField",
            "name": "wallet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActiveBlock",
                    "kind": "LinkedField",
                    "name": "agentDepositsBlock",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f4fb882a35dfeef7a74a24108f93d17",
    "id": null,
    "metadata": {},
    "name": "RequestToUnblockFromAgentDepositsMutation",
    "operationKind": "mutation",
    "text": "mutation RequestToUnblockFromAgentDepositsMutation(\n  $mobile: String!\n  $reason: String!\n) {\n  requestToUnblockFromAgentDeposits(mobile: $mobile, reason: $reason) {\n    result\n    wallet {\n      user {\n        agentDepositsBlock {\n          whenBlocked\n          blockedBy\n          blockReason\n          id\n        }\n        id\n      }\n      tickets {\n        whenOpened\n        whenResolved\n        status\n        slackMessageUrl\n        ticketType\n        reference\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0d18325429403d2f73f550179e176fb6';
export default node;
