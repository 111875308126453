import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "../../types/MutatorModal";
import { Action } from "@/types/actions";

import { SendWithdrawalCodeMutationResponse } from "./__generated__/SendWithdrawalCodeMutation.graphql";
import { SendWithdrawalCode_wallet } from "./__generated__/SendWithdrawalCode_wallet.graphql";

const mutation = graphql`
    mutation SendWithdrawalCodeMutation(
        $mobile: String!
        $withdrawalCode: String!
    ) {
        sendWithdrawalCode(mobile: $mobile, withdrawalCode: $withdrawalCode) {
            result
        }
    }
`;

type SendWithdrawalCodeInputParams = {
    wallet: SendWithdrawalCode_wallet;
    withdrawalCode: string;
};

const _SendWithdrawalCodeMutator = (
    props: SendWithdrawalCodeInputParams & RelayModalProps
) => {
    const { onHide, wallet, withdrawalCode } = props;
    const { mobile } = wallet;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: SendWithdrawalCodeMutationResponse): string => {
            return response.sendWithdrawalCode?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: { name: Action.SendWithdrawalCode, data: { mobile } },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { mobile, withdrawalCode });
    }, [mutator, mobile, withdrawalCode]);

    return (
        <MutatorModal
            {...mutator}
            title={t("wd-code-send", {
                code: withdrawalCode,
                name: wallet.name,
                mobile: wallet.mobile,
            })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-confirm")}
                onSubmit={handleSubmit}
                onDone={onHide}
            />
        </MutatorModal>
    );
};

export const SendWithdrawalCodeMutator = createFragmentContainer(
    _SendWithdrawalCodeMutator,
    {
        wallet: graphql`
            fragment SendWithdrawalCode_wallet on Wallet {
                id
                mobile
                name
            }
        `,
    }
);
