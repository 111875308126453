import { ReactNode, useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { NavigationContext } from "@/context/Navigation";

import { useNavigation } from "@/hooks/useNavigation";

import {
    DEVICE_ID_FILTER_QUERY_PARAM_NAME,
    OPAQUE_ID_QUERY_PARAM_NAME,
    TabName,
    getAddressForProfileById,
    getAddressForProfileByMobile,
} from "@/utils/navigation";

type LinkProps = {
    mobile?: string;
    id?: string;
    tab?: TabName;
    children?: ReactNode;
    opaqueId?: string;
};
/**
 * This class should be used instead of React's `<Link>` for navigation between the
 * app pages. This provides the developer a way to create links without thinking about
 * the explicit URLs, and ensures the consistency of history stack for iframe navigation
 * using back/forward buttons next to the profile searcher at the top of the iframe.
 */
export const NavigationLink = ({
    children,
    mobile,
    id,
    tab,
    opaqueId,
}: LinkProps) => {
    const { queryString } = useContext(NavigationContext);
    const { goToPage } = useNavigation();
    const params = new URLSearchParams(queryString);
    params.delete(OPAQUE_ID_QUERY_PARAM_NAME);
    params.delete(DEVICE_ID_FILTER_QUERY_PARAM_NAME);
    if (opaqueId) {
        params.set(OPAQUE_ID_QUERY_PARAM_NAME, opaqueId);
    }
    const query = params.toString();
    const address = useMemo(() => {
        if (mobile) return getAddressForProfileByMobile(mobile, query, tab);
        if (id) return getAddressForProfileById(id, query, tab);
        return window.location.toString();
    }, [id, mobile, tab, query]);
    const clickCallback = useCallback(
        (e) => {
            // If Command key was pressed, we allow default behaviour of opening the link in a new tab/window
            if (e.metaKey) {
                return;
            }
            e.preventDefault();
            goToPage(address);
        },
        [goToPage, address]
    );
    if (!mobile && !id) {
        return <div className="danger">BAD LINK</div>;
    }
    return (
        <Link to={address} onClick={clickCallback} className="text-blue-600">
            {children}
        </Link>
    );
};
