import { useCallback, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router";

import { NavigationContext } from "@/context/Navigation";

import { Navigation } from "@/types/Navigation";

import {
    getAddressForProfileById,
    getAddressForProfileByMobile,
    getAddressForSpecialPage,
} from "@/utils/navigation";
import {
    DEVICE_ID_FILTER_QUERY_PARAM_NAME,
    GoToOptions,
    OPAQUE_ID_QUERY_PARAM_NAME,
    SpecialPageType,
    TabName,
} from "@/utils/navigation";

/**
 * Hook for navigation functions.
 * @returns struct with the following functions
 *
 * - `goToTab` changes the tab of the current page.
 *
 * - `goToProfileByMobile` navigates to a profile by phone number. This is 90% of the use cases.
 *
 * - `goToProfileById` navigates to a profile by agent ID. This is used by Agent history.
 *
 * - `goToSpecialPage` navigates to a non-profile page (`'blank'` or `'no-current-call'`)
 *
 * - `goToPage` changes the page URL while maintaining the history consistency. This is
 * intended to be an internal function, for use by this hook and the `<NavigationLink>`
 * component. Use other `goTo*` functions instead.
 *
 * - `canGoBack`, `canGoForward`, `goBack`, `goForward` should only be used by back/forward
 * buttons in the top bar next to the profile searcher.
 */

interface RouteParams {
    id: string;
    mobile: string;
}

export const useNavigation = (): Navigation => {
    const history = useHistory();
    const {
        setPagesAhead,
        setPagesBehind,
        pagesBehind,
        pagesAhead,
        queryString,
    } = useContext(NavigationContext);
    const { id, mobile } = useParams<RouteParams>();

    const canGoBack = useMemo(() => pagesBehind > 0, [pagesBehind]);
    const canGoForward = useMemo(() => pagesAhead > 0, [pagesAhead]);

    const goBack = useCallback(() => {
        if (!canGoBack) return;
        setPagesAhead(pagesAhead + 1);
        setPagesBehind(pagesBehind - 1);
        history.goBack();
    }, [
        canGoBack,
        history,
        pagesAhead,
        setPagesAhead,
        pagesBehind,
        setPagesBehind,
    ]);
    const goForward = useCallback(() => {
        if (!canGoForward) return;
        setPagesAhead(pagesAhead - 1);
        setPagesBehind(pagesBehind + 1);
        history.goForward();
    }, [
        canGoForward,
        history,
        pagesAhead,
        setPagesAhead,
        pagesBehind,
        setPagesBehind,
    ]);

    const goToPage = useCallback(
        (address: string) => {
            setPagesAhead(0);
            setPagesBehind(pagesBehind + 1);
            history.push(address);
        },
        [history, setPagesAhead, setPagesBehind, pagesBehind]
    );

    const goToSpecialPage = useCallback(
        (pageType: SpecialPageType) => {
            goToPage(getAddressForSpecialPage(pageType, queryString));
        },
        [queryString, goToPage]
    );

    const goToProfileByMobile = useCallback(
        (
            mobile: string,
            opaqueId?: string,
            tab?: TabName,
            opts?: GoToOptions
        ) => {
            const query = new URLSearchParams(queryString);
            query.delete(OPAQUE_ID_QUERY_PARAM_NAME);
            query.delete(DEVICE_ID_FILTER_QUERY_PARAM_NAME);
            if (opts?.clearLocationSearch) {
                query.forEach((qk, _qv) => query.delete(qk));
            }
            if (opaqueId) {
                query.set(OPAQUE_ID_QUERY_PARAM_NAME, opaqueId);
            }
            goToPage(
                getAddressForProfileByMobile(mobile, query.toString(), tab)
            );
        },
        [queryString, goToPage]
    );

    const goToProfileById = useCallback(
        (id: string, opaqueId?: string, tab?: TabName, opts?: GoToOptions) => {
            const query = new URLSearchParams(queryString);
            query.delete(OPAQUE_ID_QUERY_PARAM_NAME);
            query.delete(DEVICE_ID_FILTER_QUERY_PARAM_NAME);
            if (opts?.clearLocationSearch) {
                query.forEach((qk, _qv) => query.delete(qk));
            }
            if (opaqueId) {
                query.set(OPAQUE_ID_QUERY_PARAM_NAME, opaqueId);
            }
            goToPage(getAddressForProfileById(id, query.toString(), tab));
        },
        [queryString, goToPage]
    );

    const goToTab = useCallback(
        (tab: TabName) => {
            if (mobile) {
                goToProfileByMobile(mobile, undefined, tab);
            } else if (id) {
                goToProfileById(id, tab);
            }
        },
        [id, mobile, goToProfileByMobile, goToProfileById]
    );

    const reloadPage = useCallback(() => {
        const path = `${history.location.pathname}${history.location.search}`;
        if (history.length === 1) {
            // add a page before the current one so that we can navigate back
            history.replace(`/${history.location.search}`);
            history.push(path);
        }
        history.goBack();
        setTimeout(history.goForward, 10);
    }, [history]);

    return {
        canGoBack,
        canGoForward,
        goBack,
        goForward,
        goToSpecialPage,
        goToProfileById,
        goToProfileByMobile,
        goToTab,
        goToPage,
        reloadPage,
    };
};
