import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { SupportPayoutTransferReverseMutationResponse } from "./__generated__/SupportPayoutTransferReverseMutation.graphql";
import { SupportPayoutTransferReverse_transfer } from "./__generated__/SupportPayoutTransferReverse_transfer.graphql";

const mutation = graphql`
    mutation SupportPayoutTransferReverseMutation($tcid: ID!) {
        supportReversePayout(tcid: $tcid) {
            isRefunded
        }
    }
`;

type PayoutTransferReverseInputParams = {
    transfer: SupportPayoutTransferReverse_transfer;
};

const _PayoutTransferReverseMutator = (
    props: RelayModalProps & PayoutTransferReverseInputParams
) => {
    const { onHide, transfer } = props;
    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (
            response: SupportPayoutTransferReverseMutationResponse
        ): string | null => {
            if (response) return t("refund--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.Refund,
            data: { tcid: transfer.tcid },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, transfer);
    }, [mutator, transfer]);

    return (
        <MutatorModal
            {...mutator}
            title={t("reverse-payout--title", {
                amount: transfer.amount,
                // Yes, we send money back to the original sender:
                recipientName: transfer.sender,
            })}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-refund")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            >
                <p>
                    {t("reverse-payout--description", {
                        amount: transfer.amount,
                        recipientName: transfer.maybeRecipientName,
                        recipientMobile: transfer.recipientMobile,
                        senderName: transfer.sender,
                    })}
                </p>
            </Form>
        </MutatorModal>
    );
};

export const PayoutTransferReverseMutator = createFragmentContainer(
    _PayoutTransferReverseMutator,
    {
        transfer: graphql`
            fragment SupportPayoutTransferReverse_transfer on PayoutTransferEntry {
                tcid
                amount
                senderOpaqueId
                sender: senderName
                maybeRecipientName: recipientName
                recipientMobile
                isReversal
            }
        `,
    }
);
