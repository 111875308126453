import { useCallback, useMemo } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { RequestToModifyBlockMutationResponse } from "./__generated__/RequestToModifyBlockMutation.graphql";
import { RequestToModifyBlock_activeBlock } from "./__generated__/RequestToModifyBlock_activeBlock.graphql";

const mutation = graphql`
    mutation RequestToModifyBlockMutation(
        $id: ID!
        $mobile: String!
        $unblock: Boolean!
        $reason: String!
    ) {
        requestToModifyBlock(
            id: $id
            mobile: $mobile
            unblock: $unblock
            reason: $reason
        ) {
            result
            block {
                id
                requestToModifyBlockTickets {
                    status
                    whenOpened
                }
            }
        }
    }
`;

type RequestToModifyBlockProps = {
    block: RequestToModifyBlock_activeBlock;
    mobile: string;
    requestToUnblock: boolean;
    title: string;
    body: string | React.ReactNode;
};

const _RequestToModifyBlockMutator = (
    props: RequestToModifyBlockProps & RelayModalProps
) => {
    const { onHide, requestToUnblock, block, mobile, title, body } = props;

    const { t } = useTranslation();

    const reasonField = useTextField();

    const blockedOrUnblocked = requestToUnblock
        ? t("request-modify-block--unblock-requested")
        : t("request-modify-block--blocked");

    const onMutationSuccess = useCallback(
        (response: RequestToModifyBlockMutationResponse) => {
            return (
                response.requestToModifyBlock?.result ??
                t("request-modify-block--error")
            );
        },
        [t]
    );
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportToModifyBlock,
            data: { mobile },
        },
        ...props,
    });
    const reasonValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!reasonField.value) {
            validations.push({
                type: "error",
                message: t("request-modify-block--missing-reason", {
                    action: blockedOrUnblocked,
                }),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, reasonField.value, blockedOrUnblocked]);
    const isValid = useMemo(() => {
        return !hasError([reasonValidations]);
    }, [reasonValidations]);
    const handleSubmit = () => {
        mutator.submit(mutation, {
            unblock: requestToUnblock,
            mobile: mobile,
            reason: reasonField.value.trim(),
            id: block.id,
        });
    };

    return (
        <MutatorModal {...mutator} title={title}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={
                    requestToUnblock ? t("action-unblock") : t("action-block")
                }
                onSubmit={handleSubmit}
                onDone={onHide}
                hideFieldsAfterSubmit={true}
            >
                {body}
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
        </MutatorModal>
    );
};

export const RequestToModifyBlockMutator = createFragmentContainer(
    _RequestToModifyBlockMutator,
    {
        activeBlock: graphql`
            fragment RequestToModifyBlock_activeBlock on ActiveBlock {
                id
                requestToModifyBlockTickets {
                    status
                    whenOpened
                }
            }
        `,
    }
);
