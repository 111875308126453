import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "react-relay";

import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";

import { ReverseFrozenTransactionMutationResponse } from "@/pages/mobile.[mobile].personal/__generated__/ReverseFrozenTransactionMutation.graphql";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { getMobileFromWindowLocation } from "@/utils/navigation";

const mutation = graphql`
    mutation ReverseFrozenTransactionMutation(
        $transferOrLedgerTransactionId: ID!
        $currentUserMobile: String!
    ) {
        reverseFrozenTransaction(
            transferOrLedgerTransactionId: $transferOrLedgerTransactionId
            currentUserMobile: $currentUserMobile
        ) {
            success
            historyEntry {
                id
                isCancelled
                isPending
            }
        }
    }
`;

type ReverseFrozneTransactionParams = {
    transferOrLedgerTransactionId: string;
};

export const ReverseFrozenTransactionMutator = (
    props: RelayModalProps & ReverseFrozneTransactionParams
) => {
    const { onHide, transferOrLedgerTransactionId } = props;

    const { t } = useTranslation();
    const currentUserMobile = getMobileFromWindowLocation();
    if (typeof currentUserMobile === "undefined") {
        throw new Error("Can only use this mutator when a profile is selected");
    }

    const onMutationSuccess = useCallback(
        ({
            reverseFrozenTransaction,
        }: ReverseFrozenTransactionMutationResponse): string | null => {
            if (reverseFrozenTransaction?.success)
                return t("reverse-frozen-transaction--success");
            return null;
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.FinalizeFrozenFunds,
            data: { transferOrLedgerTransactionId },
        },
        ...props,
    });

    const onSubmitForm = useCallback(() => {
        mutator.submit(mutation, {
            transferOrLedgerTransactionId,
            currentUserMobile,
        });
    }, [mutator, transferOrLedgerTransactionId, currentUserMobile]);

    return (
        <MutatorModal
            {...mutator}
            title={t("reverse-frozen-transaction--title")}
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("reverse-frozen-transaction--submit")}
                onSubmit={onSubmitForm}
                onDone={onHide}
            ></Form>
        </MutatorModal>
    );
};
