/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ChangePayoutRecipientMutationVariables = {
    transferId: string;
    newMobile: string;
};
export type ChangePayoutRecipientMutationResponse = {
    readonly changePayoutRecipient: {
        readonly transfer: {
            readonly recipientMobile: string;
        } | null;
    } | null;
};
export type ChangePayoutRecipientMutation = {
    readonly response: ChangePayoutRecipientMutationResponse;
    readonly variables: ChangePayoutRecipientMutationVariables;
};



/*
mutation ChangePayoutRecipientMutation(
  $transferId: ID!
  $newMobile: String!
) {
  changePayoutRecipient(transferId: $transferId, newMobile: $newMobile) {
    transfer {
      recipientMobile
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newMobile"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transferId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "newMobile",
    "variableName": "newMobile"
  },
  {
    "kind": "Variable",
    "name": "transferId",
    "variableName": "transferId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipientMobile",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePayoutRecipientMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ChangePayoutRecipient",
        "kind": "LinkedField",
        "name": "changePayoutRecipient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutTransfer",
            "kind": "LinkedField",
            "name": "transfer",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangePayoutRecipientMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ChangePayoutRecipient",
        "kind": "LinkedField",
        "name": "changePayoutRecipient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutTransfer",
            "kind": "LinkedField",
            "name": "transfer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "12b1689959e674df2447f0054fc35c42",
    "id": null,
    "metadata": {},
    "name": "ChangePayoutRecipientMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePayoutRecipientMutation(\n  $transferId: ID!\n  $newMobile: String!\n) {\n  changePayoutRecipient(transferId: $transferId, newMobile: $newMobile) {\n    transfer {\n      recipientMobile\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0174b5cec2b1983ccd3a77fbf34f15e6';
export default node;
