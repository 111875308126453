import { useState } from "react";
import "react-accessible-accordion/dist/fancy-example.css";
import { useTranslation } from "react-i18next";
import {
    QueryRenderer,
    createFragmentContainer,
    graphql,
    useRelayEnvironment,
} from "react-relay";
import { useLocation } from "react-router";

import { LoadingIndicator } from "@/components/Loading";

import { WrongBackendError } from "@/external/relay";

import { useJumpToDate } from "@/hooks/useJumpToDate";
import { usePermissions } from "@/hooks/usePermissions";

import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";
import { ReadyState } from "@/types/RelayReadyState";

import { OPAQUE_ID_QUERY_PARAM_NAME } from "@/utils/navigation";

import { AgentTab_agentUser } from "./__generated__/AgentTab_agentUser.graphql";

import { AgentHistory } from "./AgentHistory";
import { AgentTabHeader } from "./AgentTabHeader";

interface AgentTabProps {
    mobile: string;
    searchOpaqueId: string | null;
}

interface FragmentContainerProps {
    agentUser: AgentTab_agentUser;
    opaqueId?: string;
    when?: Date;
    setWhen: (date: Date) => void;
}

const _AgentTab = ({
    agentUser,
    opaqueId,
    when,
    setWhen,
}: FragmentContainerProps) => {
    const { t } = useTranslation();
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const [showCancelledAtx, setShowCancelledAtx] = useState(false);

    return (
        <>
            <AgentTabHeader
                agentUser={agentUser}
                when={when}
                setWhen={setWhen}
                showCancelledAtx={showCancelledAtx}
                setShowCancelledAtx={setShowCancelledAtx}
            />
            {canReadHistory ? (
                <AgentHistory
                    agent={agentUser.agent}
                    jumpTo={opaqueId || undefined}
                    showCancelledAtx={showCancelledAtx}
                />
            ) : (
                <div className="text-center">{t("agent-tab--error")}</div>
            )}
        </>
    );
};

// TODO(ben) extract the common history subquery to a fragment
const AgentTabContents = createFragmentContainer(_AgentTab, {
    agentUser: graphql`
        fragment AgentTab_agentUser on AgentUser
        @argumentDefinitions(
            when: { type: "DateTime" }
            historyAround: { type: "ID" }
            canReadBalance: { type: "Boolean!" }
            canReadHistory: { type: "Boolean!" }
            includeCancelled: { type: "Boolean!" }
        ) {
            isActive
            ...ReportAgentIssue_agentUser
            ...ReportUserComplaint_agentUser
            ...AgentTransaction_agentUser
            ...CreateAgentAssistant_agentUser
            agent {
                id
                ufid
                name
                city
                subcity
                region
                isActive
                isReported
                isCompliant
                whenTerminated
                currency
                commissionStructureName
                commissionUrl
                principal {
                    id
                }
                agentOnAgentGamingWatchlist {
                    isOnList
                    whenUpdated
                }
                agentOnAgentGamingWhitelist {
                    isOnList
                    whenUpdated
                }
                openingHoursDisplay(format: LONG)
                agentType
                recoveryBalance
                limitBalMin
                overdraftOffer {
                    status
                }
                subAgents {
                    id
                    ufid
                    name
                    agentType
                }
                managedByAgent {
                    id
                    ufid
                    name
                }
                rebalanceNetworkHeadAgent {
                    id
                    ufid
                    name
                }
                floatWallet {
                    partnerOrg
                    photoEditUrl
                    id
                    balance @include(if: $canReadBalance)
                    mobile
                    name
                }
                coords {
                    lng
                    lat
                }
                activeRebalanceLimit {
                    rebalanceLimit
                    whenActiveFrom
                    whenActiveTo
                    note
                    isCurrentlySuspended
                    suspensions {
                        whenSuspendedFrom
                        whenSuspendedTo
                    }
                }
                ...AgentHistory_agent
                    @arguments(
                        last: 30
                        around: $historyAround
                        when: $when
                        includeCancelled: $includeCancelled
                    )
                    @include(if: $canReadHistory)
                ...AgentUsersList_agent
                ...CreateAgentTask_agent
                ...RequestToExplainCommissionCuts_agent
            }
            user {
                id
                mobile
            }
        }
    `,
});

const AgentTabByIdQuery = graphql`
    query AgentTabByIdQuery(
        $agentOpaqueId: ID!
        $historyAround: ID
        $when: DateTime
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $includeCancelled: Boolean
    ) {
        agent(agentId: $agentOpaqueId) {
            name
            agentUsers(isPrincipal: true) {
                ...AgentTab_agentUser
                    @arguments(
                        when: $when
                        historyAround: $historyAround
                        canReadBalance: $canReadBalance
                        canReadHistory: $canReadHistory
                        includeCancelled: $includeCancelled
                    )
            }
        }
    }
`;
const AgentTabQuery = graphql`
    query AgentTabQuery(
        $mobile: String!
        $historyAround: ID
        $when: DateTime
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $includeCancelled: Boolean
    ) {
        wallet(mobile: $mobile) {
            user {
                agentUser {
                    ...AgentTab_agentUser
                        @arguments(
                            when: $when
                            historyAround: $historyAround
                            canReadBalance: $canReadBalance
                            canReadHistory: $canReadHistory
                            includeCancelled: $includeCancelled
                        )
                }
            }
        }
    }
`;

export const AgentTab = ({ mobile, searchOpaqueId }: AgentTabProps) => {
    const environment = useRelayEnvironment();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const opaqueId = params.get(OPAQUE_ID_QUERY_PARAM_NAME);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const { date, setWhen } = useJumpToDate();
    const AgentOpaqueIdFormat = /^A_[a-zA-Z0-9-_]{12}$/;

    const byId =
        searchOpaqueId != null && AgentOpaqueIdFormat.test(searchOpaqueId);

    return (
        <QueryRenderer
            environment={environment}
            render={({ error, props }: ReadyState<any>) => {
                if (error) {
                    if (error instanceof WrongBackendError) throw error;
                    if (error.message === "Failed to fetch") {
                        return (
                            <div>
                                Error loading agent tab! Try logging in at{" "}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://frontplugin.wave-internal.com/backend_proxy/"
                                    className="text-blue-600"
                                >
                                    https://frontplugin.wave-internal.com/backend_proxy/
                                </a>
                            </div>
                        );
                    }

                    return <div>Error loading agent tab! {error.message}</div>;
                }

                if (!props) return <LoadingIndicator />;
                return (
                    <AgentTabContents
                        agentUser={
                            byId
                                ? props.agent.agentUsers[0]
                                : props.wallet.user.agentUser
                        }
                        opaqueId={opaqueId || undefined}
                        when={date}
                        setWhen={setWhen}
                    />
                );
            }}
            fetchPolicy="store-and-network"
            query={byId ? AgentTabByIdQuery : AgentTabQuery}
            variables={{
                byId,
                agentOpaqueId: searchOpaqueId,
                mobile,
                when: date?.toISOString(),
                historyAround: opaqueId,
                canReadBalance,
                canReadHistory,
                includeCancelled: true,
            }}
        />
    );
};
