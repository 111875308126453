/* eslint-disable @typescript-eslint/ban-ts-comment */
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "flatpickr/dist/themes/light.css";
import * as React from "react";
import { ButtonGroup } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { Button } from "../../components/Button";
import { ConfirmationModal } from "@/components/ConfirmationModal";

import { useTrackEvent } from "@/hooks/useTrackEvent";

import { DEVICE_ID_FILTER_QUERY_PARAM_NAME } from "../../utils/navigation";

export interface JumpToDateInputProps {
    when: Date | undefined;
    setWhen: (date: Date) => void;
}

export const JumpToDateInput = ({ when, setWhen }: JumpToDateInputProps) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const trackEvent = useTrackEvent();

    const onChooseDate = ([date]: Date[]) => {
        const params = new URLSearchParams(search);
        if (params.get(DEVICE_ID_FILTER_QUERY_PARAM_NAME)) {
            trackEvent("when query: overriding device id filter");
            setModalConfirmDate(date);
            setShowConfirmation(true);
        } else {
            setWhen(date);
        }
    };

    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [modalConfirmDate, setModalConfirmDate] = React.useState(when);

    const goToToday = () => {
        const date = new Date();
        onChooseDate([date]);
    };

    return (
        <ButtonGroup>
            {/* @ts-ignore: there's something wrong with Flatpickr types */}
            <Flatpickr
                data-enable-time
                className="flex items-center justify-end"
                value={when}
                options={{
                    altInput: true,
                    altFormat: "M j, Y H:i",
                    wrap: true,
                }}
                onClose={onChooseDate}
            >
                <input
                    type="text"
                    placeholder={t("jump-to-date--placeholder")}
                    data-input
                    data-testid="dateInput"
                    className="max-h-8 bg-white"
                />
                <FontAwesomeIcon
                    icon={faCalendarAlt}
                    color="darkgray"
                    data-toggle
                    className="absolute mr-2 cursor-pointer"
                />
            </Flatpickr>
            <Button onClick={goToToday}>{t("jump-to-date--today")}</Button>
            <ConfirmationModal
                confirmationText={t("jump-to-date--disclaimer")}
                show={showConfirmation}
                onCancel={() => {
                    trackEvent("when query: cancel override");
                    setShowConfirmation(false);
                }}
                onConfirm={() => {
                    trackEvent("when query: confirm override");
                    setShowConfirmation(false);
                    modalConfirmDate && setWhen(modalConfirmDate);
                }}
            />
        </ButtonGroup>
    );
};
