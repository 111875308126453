import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { SendBusinessProposalFormLinkMutator } from "@/pages/mobile.[mobile]/SendBusinessProposalFormLink";

export const SendBusinessProposalFormLinkAction = (props: {
    name: string;
    mobile: string;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("send-business-proposal-link--action")}
            requiredPermissions={[
                "supportapp_send_business_proposal_form_link",
            ]}
        >
            <SendBusinessProposalFormLinkMutator
                name={props.name}
                mobile={props.mobile}
            />
        </ActionButton>
    );
};
