/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EscalateToSupportGroupLeadMutationVariables = {
    mobile: string;
    comments: string;
    priority: number;
};
export type EscalateToSupportGroupLeadMutationResponse = {
    readonly escalateToSupportGroupLead: {
        readonly result: string | null;
    } | null;
};
export type EscalateToSupportGroupLeadMutation = {
    readonly response: EscalateToSupportGroupLeadMutationResponse;
    readonly variables: EscalateToSupportGroupLeadMutationVariables;
};



/*
mutation EscalateToSupportGroupLeadMutation(
  $mobile: String!
  $comments: String!
  $priority: Int!
) {
  escalateToSupportGroupLead(mobile: $mobile, comments: $comments, priority: $priority) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comments"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priority"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comments",
        "variableName": "comments"
      },
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      },
      {
        "kind": "Variable",
        "name": "priority",
        "variableName": "priority"
      }
    ],
    "concreteType": "EscalateToSupportGroupLead",
    "kind": "LinkedField",
    "name": "escalateToSupportGroupLead",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EscalateToSupportGroupLeadMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EscalateToSupportGroupLeadMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "294e233771bdaf898a4009535efd1425",
    "id": null,
    "metadata": {},
    "name": "EscalateToSupportGroupLeadMutation",
    "operationKind": "mutation",
    "text": "mutation EscalateToSupportGroupLeadMutation(\n  $mobile: String!\n  $comments: String!\n  $priority: Int!\n) {\n  escalateToSupportGroupLead(mobile: $mobile, comments: $comments, priority: $priority) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b815578204a5d68e26209c7cf2fd6968';
export default node;
