import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "@/components/Loading";

import { IS_DEV, WAVE_ROOT_URL } from "../utils/environment";
import { buildUrlString } from "@/utils/url";

/**
 * Component that tries to reach the backend upon loading.
 * If the response is "opaque redirect", it means that CloudFlare is redirecting the
 * user for authentication. In this case we pop up a dialog box to suggest that the user
 * open the authentication URL in a separate window, authenticate themselves via
 * CloudFlare, and then subsequently reload the support app.
 */
export const SuggestLoginModal = (props: {
    children: React.ReactNode;
}): React.ReactElement => {
    const { t } = useTranslation();
    const [error, setError] = useState<Error | undefined>();
    const [checkingAuth, setCheckingAuth] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);

    const handleClose = () => {
        window.location.reload();
    };

    // ErrorBoundary doesn't catch async errors. Re-throwing a caught error will
    // trigger the boundary
    if (error) {
        throw error;
    }

    useEffect(() => {
        if (IS_DEV) {
            // In dev we are always authenticated, but the check would fail on CORS
            setAuthenticated(true);
            setCheckingAuth(false);
        } else {
            // Check we can reach the money-srv backend
            fetch(buildUrlString(WAVE_ROOT_URL, "/"), {
                method: "GET",
                redirect: "manual",
                cache: "no-cache",
                headers: {
                    "upgrade-insecure-requests": "1",
                },
            })
                .then((resp) => {
                    if (resp.ok) {
                        setAuthenticated(true);
                    }
                    setCheckingAuth(false);
                })
                .catch((err) => setError(err));
        }
    }, []);

    const promptLoginModal = (
        <div>
            <Modal
                show={true}
                onHide={handleClose}
                backdropClassName="zero-opacity"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("suggest-login--title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t("suggest-login--link")}{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://frontplugin.wave-internal.com/backend_proxy/"
                        className="text-blue-600"
                    >
                        https://frontplugin.wave-internal.com/backend_proxy/
                    </a>{" "}
                    {t("suggest-login--description")}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose}>
                        {t("suggest-login--button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

    return checkingAuth ? (
        <LoadingIndicator />
    ) : authenticated ? (
        <>{props.children}</>
    ) : (
        promptLoginModal
    );
};
