import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { ReportPaymentCardProblemMutator } from "@/pages/mobile.[mobile].personal/ReportPaymentCardProblem";

export const ReportPaymentCardProblemAction = (props: { mobile: string }) => {
    const mobile = props.mobile;
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("report-payment-card--action")}
            requiredPermissions={["supportapp_report_payment_card_problem"]}
        >
            <ReportPaymentCardProblemMutator mobile={mobile} comment={""} />
        </ActionButton>
    );
};
