/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ChangeRemittanceRecipientMutationVariables = {
    remittanceTransferId: string;
    newMobile: string;
};
export type ChangeRemittanceRecipientMutationResponse = {
    readonly changeRemittanceRecipient: {
        readonly remittanceTransfer: {
            readonly opaqueId: string;
        } | null;
    } | null;
};
export type ChangeRemittanceRecipientMutation = {
    readonly response: ChangeRemittanceRecipientMutationResponse;
    readonly variables: ChangeRemittanceRecipientMutationVariables;
};



/*
mutation ChangeRemittanceRecipientMutation(
  $remittanceTransferId: ID!
  $newMobile: String!
) {
  changeRemittanceRecipient(remittanceTransferId: $remittanceTransferId, newMobile: $newMobile) {
    remittanceTransfer {
      opaqueId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newMobile"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "remittanceTransferId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "newMobile",
        "variableName": "newMobile"
      },
      {
        "kind": "Variable",
        "name": "remittanceTransferId",
        "variableName": "remittanceTransferId"
      }
    ],
    "concreteType": "ChangeRemittanceRecipient",
    "kind": "LinkedField",
    "name": "changeRemittanceRecipient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RemittanceTransfer",
        "kind": "LinkedField",
        "name": "remittanceTransfer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "opaqueId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeRemittanceRecipientMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangeRemittanceRecipientMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "269dc22d6a2abe3686daed5e1b54d944",
    "id": null,
    "metadata": {},
    "name": "ChangeRemittanceRecipientMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeRemittanceRecipientMutation(\n  $remittanceTransferId: ID!\n  $newMobile: String!\n) {\n  changeRemittanceRecipient(remittanceTransferId: $remittanceTransferId, newMobile: $newMobile) {\n    remittanceTransfer {\n      opaqueId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fc5ec2fd75efd33abaeaedd7f49cc6b3';
export default node;
