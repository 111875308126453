import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql, useQueryLoader } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isValidMobile, mobileValidationErrorMessage } from "@/utils/mobile";

import { AwardPayPromoMutationResponse } from "./__generated__/AwardPayPromoMutation.graphql";

const mutation = graphql`
    mutation AwardPayPromoMutation($mobile: String!, $code: String!) {
        awardPromo(mobile: $mobile, code: $code) {
            result
        }
    }
`;

/**
 * When we render the profile of a given customer, we display something based on their
 * value for `elligibleToReceivePayPromo` - (see `SendPayPromoBadge`). Given that this
 * modal will be displayed on top of that profile and it can change whether or not that
 * badge is displayed, we need to refresh the value we have (for
 * `elligibleToReceivePayPromo`). Normally we would just have it returned in the
 * mutation response. However, if we do that, the mutator modal disappears immediately
 * when the mutation succeeds. In order to avoid that jarring experience, we need to
 * defer the update to the moment the user/rep clicks on the modal's done button.
 * @return callback to be called when you want to fetch the value for
 * 'elligibleToReceivePayPromo' for a given user (through their mobile number).
 */
function useRefreshElligibleToReceivePayPromo() {
    const [, loadQuery] = useQueryLoader(
        graphql`
            query AwardPayPromoQuery($mobile: String!) {
                wallet(mobile: $mobile) {
                    user {
                        elligibleToReceivePayPromo
                    }
                }
            }
        `
    );
    return (mobile: string) => {
        loadQuery({ mobile }, { fetchPolicy: "network-only" });
    };
}

type AwardPayPromoInputParams = {
    name: string;
    mobile: string;
    code?: string;
};

const _AwardPayPromoMutator = (
    props: AwardPayPromoInputParams & RelayModalProps
) => {
    const { onHide, name, mobile, code } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: AwardPayPromoMutationResponse) => {
            return response.awardPromo?.result
                ? t("award-pay-promo--success")
                : "";
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.AwardPayPromo,
            data: { mobile, code },
        },
        ...props,
    });

    const mobileField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { mobile: mobileField.value, code });
    }, [mutator, mobileField.value, code]);

    const mobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!isValidMobile(mobileField.value)) {
            validations.push(mobileValidationErrorMessage as Validation);
        }
        return validations;
    }, [mobileField.value]);
    const isValid = useMemo(() => {
        return !hasError([mobileValidations]);
    }, [mobileValidations]);

    const refreshElligibleToReceivePayPromo =
        useRefreshElligibleToReceivePayPromo();
    const wrappedOnHide = () => {
        if (mutator.result) {
            refreshElligibleToReceivePayPromo(mobile);
        }
        if (onHide) {
            onHide();
        }
    };

    return (
        <MutatorModal
            {...mutator}
            onHide={wrappedOnHide}
            title={t("award-pay-promo--title", { name })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={wrappedOnHide}
            >
                <TextField
                    {...mobileField}
                    label={t("label-mobile")}
                    name="mobile"
                    validations={mobileValidations}
                    defaultValue={mobile}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};

export const AwardPayPromoMutator = createFragmentContainer(
    _AwardPayPromoMutator,
    {}
);

AwardPayPromoMutator.defaultProps = {
    code: "PITCH_TO_NEW_PAYERS",
};
