/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentTransactionType = "BUSINESS" | "REBALANCE" | "USER" | "%future added value";
export type Currency = "CAD" | "CFA" | "ETB" | "GBP" | "GHS" | "GMD" | "KES" | "NGN" | "QQD" | "TZS" | "UGX" | "UNIT" | "USD" | "XAF" | "%future added value";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type AgentHistoryAtxEntry_tx = {
    readonly agentTransactionId: string;
    readonly currency: Currency;
    readonly isDeposit: boolean;
    readonly amount: string;
    readonly customerName: string | null;
    readonly customerMobile: string | null;
    readonly maybeTerminatedCustomerMobile: string | null;
    readonly agentId: string;
    readonly agentName: string;
    readonly agentUfid: string;
    readonly agentUserName: string | null;
    readonly subagentId: string | null;
    readonly subagentName: string | null;
    readonly subagentUfid: string | null;
    readonly type: AgentTransactionType;
    readonly isBusinessWalletAtx: boolean | null;
    readonly merchantUfid: string | null;
    readonly merchantId: string | null;
    readonly walletAgentUfid: string | null;
    readonly walletAgentId: string | null;
    readonly isCancelled: boolean;
    readonly composingEntries: ReadonlyArray<{
        readonly amount: string;
        readonly summary: string | null;
        readonly isPending: boolean;
        readonly isCancelled: boolean;
        readonly whenEntered: string;
        readonly agentTransactionId?: string | undefined;
        readonly isDeposit?: boolean | undefined;
    }>;
    readonly relatedTicket: {
        readonly status: TicketStatus;
        readonly slackMessageUrl: string | null;
    } | null;
    readonly scamReports: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly transactionIds: string | null;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"AgentHistoryEntry_entry">;
    readonly " $refType": "AgentHistoryAtxEntry_tx";
};
export type AgentHistoryAtxEntry_tx$data = AgentHistoryAtxEntry_tx;
export type AgentHistoryAtxEntry_tx$key = {
    readonly " $data"?: AgentHistoryAtxEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentHistoryAtxEntry_tx">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agentTransactionId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDeposit",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCancelled",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentHistoryAtxEntry_tx",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedCustomerMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentUfid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agentUserName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subagentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subagentName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subagentUfid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isBusinessWalletAtx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "merchantUfid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "merchantId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "walletAgentUfid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "walletAgentId",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "composingEntries",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "summary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPending",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenEntered",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "type": "AgentTransactionEntry",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketShortInfo",
      "kind": "LinkedField",
      "name": "relatedTicket",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackMessageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamReportTicket",
      "kind": "LinkedField",
      "name": "scamReports",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenOpened",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenResolved",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AgentHistoryEntry_entry"
    }
  ],
  "type": "AgentTransactionEntry",
  "abstractKey": null
};
})();
(node as any).hash = '5960b59a575648efb61aa89f114ef2b2';
export default node;
