/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ClearPinRateLimitMutationVariables = {
    userId: string;
    deviceId: string;
};
export type ClearPinRateLimitMutationResponse = {
    readonly expirePinRateLimitBlock: {
        readonly user: {
            readonly " $fragmentRefs": FragmentRefs<"ClearPinRateLimit_user">;
        };
    } | null;
};
export type ClearPinRateLimitMutation = {
    readonly response: ClearPinRateLimitMutationResponse;
    readonly variables: ClearPinRateLimitMutationVariables;
};



/*
mutation ClearPinRateLimitMutation(
  $userId: ID!
  $deviceId: String!
) {
  expirePinRateLimitBlock(userId: $userId, deviceId: $deviceId) {
    user {
      ...ClearPinRateLimit_user
      id
    }
  }
}

fragment ClearPinRateLimit_user on User {
  id
  devices {
    deviceId
    deviceModel
    deviceName
    hasPinRateLimitBlock
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "deviceId",
    "variableName": "deviceId"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClearPinRateLimitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ExpirePinRateLimitBlock",
        "kind": "LinkedField",
        "name": "expirePinRateLimitBlock",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClearPinRateLimit_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClearPinRateLimitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ExpirePinRateLimitBlock",
        "kind": "LinkedField",
        "name": "expirePinRateLimitBlock",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserDevice",
                "kind": "LinkedField",
                "name": "devices",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceModel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deviceName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasPinRateLimitBlock",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cdec4cca6359cf020dc0adff399724f9",
    "id": null,
    "metadata": {},
    "name": "ClearPinRateLimitMutation",
    "operationKind": "mutation",
    "text": "mutation ClearPinRateLimitMutation(\n  $userId: ID!\n  $deviceId: String!\n) {\n  expirePinRateLimitBlock(userId: $userId, deviceId: $deviceId) {\n    user {\n      ...ClearPinRateLimit_user\n      id\n    }\n  }\n}\n\nfragment ClearPinRateLimit_user on User {\n  id\n  devices {\n    deviceId\n    deviceModel\n    deviceName\n    hasPinRateLimitBlock\n  }\n}\n"
  }
};
})();
(node as any).hash = '23f6daec6fece550e5724031da58bd10';
export default node;
