import * as Sentry from "@sentry/react";
import { isEqual, omit } from "lodash";
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";

import { Analytics } from "@/external/analytics";
import { Flags } from "@/external/flags";
import { Flex } from "@/external/flex";
import { logErrorMessage } from "@/external/sentry";

import { RepHangUpCall, WsEventType, useWebSocket } from "@/hooks/useWebSocket";

import { Action } from "@/types/actions";

export const CURRENT_CALL_LOCALSTORAGE_KEY = "currentCall";

/**
 * CallStateWithActions schema version.
 *
 * **If you alter the schema of CallStateWithActions then you must update this
 * schema version.**
 */
export const CURRENT_CALL_LOCALSTORAGE_VERSION = 1;

type CallActionsState = {
    actions: readonly Action[];
    noActionSurveyShown: boolean;
};
type NoCallState = {
    callType: "none";
    callId: undefined;
};
type CommonCallState = {
    callId?: string;
    mobile: string;
    isVoip?: boolean;
    isWrapping?: boolean;
    startTime?: Date;
    endTime?: Date;
};
type IncomingCallState = CommonCallState & {
    callType: "incoming";
};
type CallbackCallState = CommonCallState & {
    callType: "callback";
    originatingCallId: string;
    originatingCallAt: Date;
};
type TransferCallState = CommonCallState & {
    callType: "transfer";
    originatingCallId: string;
    originatingCallRep: string;
};
type OutgoingCallState = CommonCallState & {
    callType: "outgoing";
};
type WhatsappConversation = CommonCallState & {
    callType: "whatsapp";
};
export type CurrentCallState =
    | NoCallState
    | IncomingCallState
    | CallbackCallState
    | TransferCallState
    | OutgoingCallState
    | WhatsappConversation;

export type CallStateWithActions = CurrentCallState & CallActionsState;
/*
 * Reminder: if you have updated the schema of CallStateWithActions above then make
 * sure that you have also incremented the version number!
 */

export const noCall: NoCallState = { callType: "none", callId: undefined };

type AnalyticsCallContext = {
    callType: CurrentCallState["callType"];
    callId: string | undefined;
    mobile: string | undefined;
};

/**
 * Explicitly extract the current call state we want to use for the analytics context.
 *
 * We do this so that we can change the information that we make available in the
 * current call context without worrying about changing what data ends up in snowflake.
 */
const extractAnalyticsCallContext = (
    state: CurrentCallState
): AnalyticsCallContext => {
    if (state.callType === "none") {
        return {
            callType: "none",
            callId: undefined,
            mobile: undefined,
        };
    } else {
        return {
            callType: state.callType,
            callId: state.callId,
            mobile: state.mobile,
        };
    }
};

export type CurrentCall = {
    currentCall: CallStateWithActions;
    setCurrentCall: (newState: CurrentCallState) => void;
    trackCallAction: (action: Action) => void;
    markNoActionSurveyShown: () => void;
};

/**
 * The React context that contains information about the current
 * call the rep is on (to the best of our ability)
 */
export const CurrentCallContext = React.createContext<CurrentCall | undefined>(
    undefined
);

/**
 * Get the current call state from local storage.
 */
const getCurrentCallFromLocalStorage = (): CallStateWithActions | undefined => {
    const storedValue = window.localStorage.getItem(
        CURRENT_CALL_LOCALSTORAGE_KEY
    );
    const parsedValue = JSON.parse(storedValue || "{}", (key, value) => {
        if (
            key == "originatingCallAt" ||
            key == "startTime" ||
            key == "endTime"
        )
            return new Date(value);
        return value;
    });
    if (parsedValue.schemaVersion === CURRENT_CALL_LOCALSTORAGE_VERSION) {
        return omit(parsedValue, "schemaVersion") as CallStateWithActions;
    }
};

const storeCurrentCallToLocalStorage = (callInfo: CallStateWithActions) => {
    Sentry.addBreadcrumb({
        type: "info",
        category: "currentCall",
        level: "info",
        message: "Storing current call to localstorage",
        data: { callInfo },
    });

    window.localStorage.setItem(
        CURRENT_CALL_LOCALSTORAGE_KEY,
        JSON.stringify({
            schemaVersion: CURRENT_CALL_LOCALSTORAGE_VERSION,
            ...callInfo,
        })
    );
    return callInfo;
};

type CurrentCallProviderProps = {
    children?: ReactNode | undefined;
};

const initialCallState = (): CallStateWithActions => {
    return (
        getCurrentCallFromLocalStorage() || {
            ...noCall,
            actions: [],
            noActionSurveyShown: false,
        }
    );
};

export const CurrentCallProvider = (props: CurrentCallProviderProps) => {
    const [currentCall, setCurrentCallState] = useState(initialCallState);

    // TODO: Remove websocket related stuff after we fully migrate to flex
    const enablePreFlexLegacy = !Flex.isEmbedded;
    const lastRepHangUpCallRef = useRef<RepHangUpCall>();
    const lastRepHangUpCall = useWebSocket<RepHangUpCall>(
        WsEventType.repHangUpCall
    );
    const enableUpdateCurrentCallWithLiveEvents =
        Flags.getFlag("live_event_updates_current_call")?.isActive || false;

    const setCurrentCall = useCallback(
        (state: CurrentCallState) => {
            setCurrentCallState((prevState) => {
                if (
                    prevState.callType !== state.callType ||
                    prevState.callId !== state.callId
                ) {
                    // A new call so reset the action tracking
                    return storeCurrentCallToLocalStorage({
                        ...state,
                        actions: [],
                        noActionSurveyShown: false,
                    });
                } else if (
                    !isEqual(
                        state,
                        omit(prevState, "actions", "noActionSurveyShown")
                    )
                ) {
                    // Merge the existing call info and actions with the new info
                    return storeCurrentCallToLocalStorage({
                        ...prevState,
                        ...state,
                    });
                }
                // No change so return original state to avoid re-rendering of dependents
                return prevState;
            });
        },
        [setCurrentCallState]
    );

    const trackCallAction = useCallback(
        (action: Action) => {
            setCurrentCallState((prevState) => {
                return storeCurrentCallToLocalStorage({
                    ...prevState,
                    actions: [...prevState.actions, action],
                });
            });
        },
        [setCurrentCallState]
    );

    const markNoActionSurveyShown = useCallback(() => {
        setCurrentCallState((prevState) => {
            return storeCurrentCallToLocalStorage({
                ...prevState,
                noActionSurveyShown: true,
            });
        });
    }, [setCurrentCallState]);

    useEffect(() => {
        Sentry.addBreadcrumb({
            type: "info",
            category: "currentCall",
            level: "info",
            message: "Current call updated",
            data: { currentCall },
        });
        Analytics.addContext(extractAnalyticsCallContext(currentCall));
    }, [currentCall]);

    useEffect(() => {
        if (enableUpdateCurrentCallWithLiveEvents && enablePreFlexLegacy) {
            if (lastRepHangUpCallRef.current !== lastRepHangUpCall) {
                lastRepHangUpCallRef.current = lastRepHangUpCall;
                setCurrentCallState((prevState) => {
                    if (
                        lastRepHangUpCall &&
                        prevState.callType !== "none" &&
                        !prevState.endTime
                    ) {
                        let callerMobile = lastRepHangUpCall.callerMobile;
                        if (callerMobile.includes("anonymous")) {
                            callerMobile = "anonymous";
                        }
                        if (
                            callerMobile !== prevState.mobile &&
                            !callerMobile.includes(prevState.mobile)
                        ) {
                            logErrorMessage(
                                `Live Events: The repHangUpCall caller mobile does not match current call mobile`
                            );
                        } else {
                            return storeCurrentCallToLocalStorage({
                                ...prevState,
                                endTime: lastRepHangUpCall.time,
                            });
                        }
                    }
                    return prevState;
                });
            }
        }
    }, [
        enableUpdateCurrentCallWithLiveEvents,
        lastRepHangUpCall,
        setCurrentCallState,
        enablePreFlexLegacy,
    ]);

    useEffect(() => {
        if (enablePreFlexLegacy) {
            // Current call state is updated with the localStorage information
            // on local storage changes. This will keep all open support app tabs synced
            // Note that we don't need this when embedded in flex as the context is
            // set when the task is selected.
            const onLocalStorageChange = (e: StorageEvent) => {
                if (e.key === CURRENT_CALL_LOCALSTORAGE_KEY) {
                    const storedCall = getCurrentCallFromLocalStorage();
                    Sentry.addBreadcrumb({
                        type: "info",
                        category: "currentCall",
                        level: "info",
                        message:
                            "Detected change to current call in localstorage",
                        data: { storedCall },
                    });
                    if (storedCall) {
                        setCurrentCallState(storedCall);
                    }
                }
            };

            window.addEventListener("storage", onLocalStorageChange);

            return () => {
                window.removeEventListener("storage", onLocalStorageChange);
            };
        }
    }, [setCurrentCallState, enablePreFlexLegacy]);

    return (
        <CurrentCallContext.Provider
            value={{
                currentCall,
                setCurrentCall,
                trackCallAction,
                markNoActionSurveyShown,
            }}
        >
            {props.children}
        </CurrentCallContext.Provider>
    );
};
