import { useCallback, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";
import { $ReadOnly } from "utility-types";

import { CurrencyField, useCurrencyField } from "@/components/CurrencyField";
import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";
import { ProfileSearchField } from "@/components/ProfileSearchField";

import { usePermissions } from "@/hooks/usePermissions";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { CAN_READ_BALANCE, CAN_READ_HISTORY } from "@/types/Permissions";
import { Profile } from "@/types/ProfileSearcher";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";

import { AgentTransactionDepositMutationResponse } from "./__generated__/AgentTransactionDepositMutation.graphql";
import { AgentTransactionRebalanceDepositMutationResponse } from "./__generated__/AgentTransactionRebalanceDepositMutation.graphql";
import { AgentTransactionRebalanceWithdrawMutationResponse } from "./__generated__/AgentTransactionRebalanceWithdrawMutation.graphql";
import { AgentTransactionWithdrawMutationResponse } from "./__generated__/AgentTransactionWithdrawMutation.graphql";
import { AgentTransaction_agentUser } from "./__generated__/AgentTransaction_agentUser.graphql";

const depositMutation = graphql`
    mutation AgentTransactionDepositMutation(
        $atx: AgentTransactionInput!
        $userId: ID!
        $agentId: ID!
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $includeCancelled: Boolean!
    ) {
        manualDeposit(
            agentTransaction: $atx
            agentUserId: $userId
            agentId: $agentId
        ) {
            agentTransaction {
                id
                agentUser {
                    ...AgentTab_agentUser
                        @arguments(
                            canReadBalance: $canReadBalance
                            canReadHistory: $canReadHistory
                            includeCancelled: $includeCancelled
                        )
                }
            }
        }
    }
`;

const rebalanceDepositMutation = graphql`
    mutation AgentTransactionRebalanceDepositMutation(
        $atx: AgentTransactionInput!
        $userId: ID!
        $agentId: ID!
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $includeCancelled: Boolean!
    ) {
        rebalanceDeposit(
            agentTransaction: $atx
            agentUserId: $userId
            agentId: $agentId
        ) {
            agentTransaction {
                id
                agentUser {
                    ...AgentTab_agentUser
                        @arguments(
                            canReadBalance: $canReadBalance
                            canReadHistory: $canReadHistory
                            includeCancelled: $includeCancelled
                        )
                }
            }
        }
    }
`;

const depositSendMutation = graphql`
    mutation AgentTransactionDepositSendMutation(
        $atx: AgentTransactionInput!
        $userId: ID!
        $agentId: ID!
        $transfer: P2PTransferInput!
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $includeCancelled: Boolean!
    ) {
        manualDeposit(
            agentTransaction: $atx
            agentUserId: $userId
            agentId: $agentId
        ) {
            agentTransaction {
                id
                agentUser {
                    ...AgentTab_agentUser
                        @arguments(
                            canReadBalance: $canReadBalance
                            canReadHistory: $canReadHistory
                            includeCancelled: $includeCancelled
                        )
                }
            }
        }
        send(transfer: $transfer) {
            transfer {
                id
                user {
                    primaryWallet {
                        ...History_wallet @include(if: $canReadHistory)
                    }
                }
            }
        }
    }
`;

const withdrawMutation = graphql`
    mutation AgentTransactionWithdrawMutation(
        $atx: AgentTransactionInput!
        $userId: ID!
        $agentId: ID!
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $includeCancelled: Boolean!
    ) {
        manualWithdraw(
            agentTransaction: $atx
            agentUserId: $userId
            agentId: $agentId
        ) {
            agentTransaction {
                id
                agentUser {
                    ...AgentTab_agentUser
                        @arguments(
                            canReadBalance: $canReadBalance
                            canReadHistory: $canReadHistory
                            includeCancelled: $includeCancelled
                        )
                }
            }
        }
    }
`;

const rebalanceWithdrawMutation = graphql`
    mutation AgentTransactionRebalanceWithdrawMutation(
        $atx: AgentTransactionInput!
        $userId: ID!
        $agentId: ID!
        $canReadBalance: Boolean!
        $canReadHistory: Boolean!
        $includeCancelled: Boolean!
    ) {
        rebalanceWithdraw(
            agentTransaction: $atx
            agentUserId: $userId
            agentId: $agentId
        ) {
            agentTransaction {
                id
                agentUser {
                    ...AgentTab_agentUser
                        @arguments(
                            canReadBalance: $canReadBalance
                            canReadHistory: $canReadHistory
                            includeCancelled: $includeCancelled
                        )
                }
            }
        }
    }
`;

export const DEPOSIT = {
    title: "Deposit",
    mutation: depositMutation,
    sign: 1,
    action: Action.Deposit,
};

export const WITHDRAW = {
    title: "Withdraw",
    mutation: withdrawMutation,
    sign: -1,
    action: Action.Withdraw,
};

export const REBALANCE_DEPOSIT = {
    title: "Rebalance Deposit",
    mutation: rebalanceDepositMutation,
    sign: 1,
    action: Action.RebalanceDeposit,
};

export const REBALANCE_WITHDRAW = {
    title: "Rebalance Withdraw",
    mutation: rebalanceWithdrawMutation,
    sign: -1,
    action: Action.RebalanceWithdraw,
};

export const DEPOSIT_AND_SEND = {
    title: "Deposit and Send",
    mutation: depositSendMutation,
    sign: 0, // never used, just to make Flow happy
    action: Action.DepositAndSend,
};

export type AgentTransactionDirection =
    | typeof DEPOSIT
    | typeof WITHDRAW
    | typeof REBALANCE_DEPOSIT
    | typeof REBALANCE_WITHDRAW
    | typeof DEPOSIT_AND_SEND;

type AtxMutationResponse =
    | AgentTransactionDepositMutationResponse
    | AgentTransactionWithdrawMutationResponse
    | AgentTransactionRebalanceDepositMutationResponse
    | AgentTransactionRebalanceWithdrawMutationResponse;

type AgentTransactionParams = {
    customer: Profile | null;
    amount: M | null;
    recipient: Profile | null;
};

type AgentTransactionInputParams = {
    agentUser: AgentTransaction_agentUser;
    direction: AgentTransactionDirection;
    isRebalance?: true;
};

function calcReceiveAmount(
    data: $ReadOnly<AgentTransactionParams>,
    t: TFunction
) {
    if (
        !data.customer ||
        !("wallet" in data.customer) ||
        !data.customer?.wallet
    )
        throw new Error(t("send-fee-formula--none"));
    if (!data.amount) throw new Error(t("amount--none"));
    const scalar =
        data.customer.wallet.sendFeeFormula?.evaluate({
            send_amount: data.amount.scalar,
        }) || 0;
    return data.amount.subtract(new M(data.amount.currency, scalar));
}

const _AgentTransactionMutator = (
    props: AgentTransactionInputParams & RelayModalProps
) => {
    const { t } = useTranslation();

    const { onHide, direction, agentUser } = props;
    const isRebalance = !!props.isRebalance;
    const balance = M.fromSerialized(agentUser.agent.floatWallet.balance);
    const amountField = useCurrencyField();
    const [customer, setCustomer] = useState<Profile | null>(null);
    const [recipient, setRecipient] = useState<Profile | null>(null);
    const label = isRebalance ? t("label-agent") : t("label-customer-mobile");
    const canReadHistory = usePermissions(CAN_READ_HISTORY);
    const canReadBalance = usePermissions(CAN_READ_BALANCE);

    const data = useMemo(
        () => ({
            customer,
            amount: amountField.value,
            recipient,
        }),
        [customer, amountField.value, recipient]
    );

    const onMutationSuccess = (
        response: AtxMutationResponse
    ): string | null => {
        if (response) return t("transaction--success");
        return null;
    };

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: direction.action,
            data: {
                amount: amountField.value,
                recipient: recipient?.mobile,
                customer: customer?.mobile,
            },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        if (!data.customer) {
            throw new Error(t("missing-customer"));
        } else if (data.customer.kind === "Agent" && !data.customer?.agent) {
            throw new Error(t("missing-agent"));
        } else if (data.customer.kind === "Wallet" && !data.customer.wallet) {
            throw new Error(t("missing-wallet"));
        }

        let walletId: string | undefined;

        if (isRebalance && "agent" in data.customer) {
            walletId = data.customer.agent?.walletId;
        } else if ("wallet" in data.customer) {
            walletId = data.customer.wallet?.id;
        }

        if (!walletId) throw new Error(t("wallet-id--error"));
        const vars: any = {
            atx: {
                walletId,
                amount: data.amount?.serialize(),
            },
            userId: agentUser.user.id,
            agentId: agentUser.agent.id,
            canReadHistory,
            canReadBalance,
            includeCancelled: true,
        };

        if (direction === DEPOSIT_AND_SEND) {
            if (data.recipient == null) throw new Error(); // TODO(ben) type assert
            const recipientMobile = data.recipient.mobile;
            const receiveAmount = calcReceiveAmount(data, t);
            vars.transfer = {
                walletId,
                recipientMobile,
                sendAmount: data.amount?.serialize(),
                receiveAmount: receiveAmount.serialize(),
            };
        }
        mutator.submit(direction.mutation, vars);
    }, [
        t,
        canReadBalance,
        canReadHistory,
        data,
        agentUser,
        direction,
        isRebalance,
        mutator,
    ]);

    const amountValidations = useMemo((): Validation[] => {
        let currentBalance: M | null | undefined = null;
        const validations: Validation[] = [];

        if (customer) {
            if (isRebalance && "agent" in customer && customer.agent) {
                currentBalance = customer.agent.balance;
            } else if ("wallet" in customer && customer.wallet) {
                currentBalance = customer.wallet.balance;
            }
        }

        if (!amountField.value) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-amount"),
                display: "none",
            });
        } else if (currentBalance != null && direction !== DEPOSIT_AND_SEND) {
            const newBalance = currentBalance.add(
                amountField.value.multiply(direction.sign)
            );
            if (!isRebalance && newBalance.isNegative()) {
                validations.push({
                    type: "error",
                    message: t("form-validation--no-balance"),
                    display: "always",
                });
            }
            const message =
                isRebalance && customer && "agent" in customer && customer.agent
                    ? t("balance-new--agent", {
                          name: customer.agent.name,
                          balance: newBalance.toString(),
                      })
                    : t("balance-new--customer", {
                          balance: newBalance.toString(),
                      });
            validations.push({
                type: "none",
                message,
                display: "always",
            });
        }

        // write the validaitons in here - check that amount is less than bal
        return validations;
    }, [t, isRebalance, direction, customer, amountField.value]);

    const customerValidations = useMemo((): Validation[] => {
        let currentBalance: M | null | undefined = null;
        const validations: Validation[] = [];

        if (!customer) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-sender"),
                display: "none",
            });
        } else if (customer.kind === "Wallet" && !customer.wallet) {
            validations.push({
                type: "error",
                message: t("form-validation--mobile-not-signed-up"),
                display: "always",
            });
        } else {
            if (isRebalance && "agent" in customer && customer.agent) {
                currentBalance = customer.agent.balance;
            } else if ("wallet" in customer) {
                // should always be true bc no unregistered users allowed
                currentBalance = customer.wallet?.balance;
            }

            if (currentBalance) {
                validations.push({
                    type: "none",
                    message: t("balance-current", {
                        balance: currentBalance.toString(),
                    }),
                    display: "always",
                });
            }
        }

        return validations;
    }, [t, isRebalance, customer]);

    const recipientValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (direction === DEPOSIT_AND_SEND && amountField.value && customer) {
            const receiveAmount = calcReceiveAmount(data, t);

            if (recipient == null) {
                validations.push({
                    type: "error",
                    message: t("form-validation--missing-recipient"),
                    display: "none",
                });
            } else {
                validations.push({
                    type: "info",
                    message: t("amount--to-be-received", {
                        amount: receiveAmount.toString(),
                    }),
                    display: "always",
                });
            }
        }

        return validations;
    }, [t, direction, recipient, customer, data, amountField.value]);

    const isValid = !hasError([
        amountValidations,
        customerValidations,
        recipientValidations,
    ]);
    return (
        <MutatorModal
            {...mutator}
            title={t("agent-transaction--title", {
                direction: direction.title,
                name: agentUser.agent.name,
            })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={direction.title}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <ProfileSearchField
                    autoFocus
                    label={label}
                    name="customer"
                    allowUnregistered={false}
                    allowMerchants={false}
                    allowAgents={isRebalance}
                    allowWallets={!isRebalance}
                    allowTerminated
                    validations={customerValidations}
                    onChange={(profile) => {
                        if (profile) setCustomer(profile as Profile);
                    }}
                />
                <CurrencyField
                    {...amountField}
                    label={t("label-amount")}
                    name="amount"
                    currency={balance.currency}
                    validations={amountValidations}
                />

                {direction === DEPOSIT_AND_SEND ? (
                    <ProfileSearchField
                        allowWallets
                        allowUnregistered
                        allowMerchants
                        allowTerminated
                        label={t("label-recipient")}
                        name="recipient"
                        allowAgents={false}
                        validations={recipientValidations}
                        onChange={(profile) => {
                            if (profile) setRecipient(profile as Profile);
                        }}
                    />
                ) : null}
            </Form>
        </MutatorModal>
    );
};

export const AgentTransactionMutator = createFragmentContainer(
    _AgentTransactionMutator,
    {
        agentUser: graphql`
            fragment AgentTransaction_agentUser on AgentUser {
                user {
                    id
                }
                agent {
                    id
                    name
                    floatWallet {
                        balance
                    }
                }
            }
        `,
    }
);
