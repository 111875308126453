import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { Accordion, Alert, Button, ButtonGroup, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { WrongBackendError } from "@/external/relay";

import { ProfileNotFound } from "@/pages/mobile.[mobile]/ProfileNotFound";

import { COUNTRIES } from "@/types/countries";
import { DEFAULT_COUNTRY } from "@/types/country";

type ErrorBoundaryProps = {
    children: ReactNode;
};

type ErrorBoundaryFallbackProps = {
    error: Error;
    componentStack: string | null;
    eventId?: string;
    resetError: () => void;
};

const ErrorBoundaryFallback = ({
    error,
    componentStack,
    eventId,
    resetError,
}: ErrorBoundaryFallbackProps) => {
    const { t } = useTranslation();

    return (
        <div>
            <Alert variant="danger">
                <p>{t("error-boundary--fallback--title")}</p>
                {eventId && (
                    <p>
                        Sentry Event ID:{" "}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://sentry.io/organizations/wavemm/issues/?project=5707977&query=${eventId}`}
                            className="text-blue-600"
                        >
                            {eventId}
                        </a>
                    </p>
                )}
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                            >
                                {t("error-boundary--fallback--show-details")}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <p>Stack trace:</p>
                                <pre>{error.stack}</pre>
                                <p>Component stack:</p>
                                <pre>{componentStack}</pre>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <ButtonGroup>
                    <Button onClick={resetError}>
                        {t("error-boundary--fallback--try-again")}
                    </Button>
                </ButtonGroup>
            </Alert>
        </div>
    );
};

/**
 * `ErrorBoundary` is a wrapper component to catch exceptions and display them in a
 * reasonably user-friendly way, with a 'Try again' button. Without this component,
 * the user gets an exception in console, and nothing on the screen.
 *
 * @param props only cares for `children` (i.e. the children elements of this component)
 *
 * @example
 * <ErrorBoundary><ComponentThatMayError /></ErrorBoundary>
 */
const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
    const [eventId, setEventId] = useState<string | undefined>();
    const initialCountry = useMemo(() => {
        return localStorage.getItem("country") || DEFAULT_COUNTRY;
    }, []);

    const onError = useCallback(
        (error: Error, componentStack: string, eventId: string) => {
            setEventId(eventId);
        },
        []
    );

    const Fallback = useCallback(
        ({
            error,
            componentStack,
            resetError,
        }: {
            error: Error;
            componentStack: string | null;
            resetError: () => void;
        }) => {
            // if this is a WrongBackendError, no need to show
            // the default Sentry Boundary fallback, just show
            // the user this appropriate error
            if (error instanceof WrongBackendError) {
                const replayBackendCountry =
                    COUNTRIES[error.replayBackend.toUpperCase()];
                const selectedCountry =
                    COUNTRIES[initialCountry?.toUpperCase()];

                return (
                    <ProfileNotFound
                        countryFromMobile={replayBackendCountry}
                        selectedCountry={selectedCountry}
                    />
                );
            }

            return (
                <ErrorBoundaryFallback
                    error={error}
                    componentStack={componentStack}
                    resetError={resetError}
                    eventId={eventId}
                />
            );
        },
        [eventId, initialCountry]
    );

    return (
        <SentryErrorBoundary
            fallback={Fallback}
            onError={onError}
            showDialog={false}
        >
            {children}
        </SentryErrorBoundary>
    );
};

export default ErrorBoundary;
