import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { RenameWalletMutationResponse } from "./__generated__/RenameWalletMutation.graphql";
import { RenameWallet_wallet } from "./__generated__/RenameWallet_wallet.graphql";

const mutation = graphql`
    mutation RenameWalletMutation(
        $walletId: ID!
        $newName: String!
        $reason: String!
    ) {
        renameWallet(walletId: $walletId, newName: $newName, reason: $reason) {
            result
            wallet {
                name
                user {
                    name
                }
            }
        }
    }
`;
type RenameWalletInputParams = {
    wallet: RenameWallet_wallet;
};

const _RenameWalletMutator = (
    props: RenameWalletInputParams & RelayModalProps
) => {
    const { onHide, wallet } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: RenameWalletMutationResponse) => {
            return response.renameWallet?.result || "";
        },
        []
    );
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.RenameWallet,
            data: { walletId: wallet.id },
        },
        ...props,
    });
    const newNameField = useTextField();
    const reasonField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            walletId: wallet.id,
            newName: newNameField.value.trim(),
            reason: reasonField.value.trim(),
        });
    }, [mutator, wallet, newNameField.value, reasonField.value]);

    const newNameValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        const fieldValue = newNameField.value.trim();
        if (_.isEmpty(fieldValue)) {
            validations.push({
                type: "error",
                display: "always",
                message: t("rename-wallet--enter-name"),
            });
        }
        return validations;
    }, [t, newNameField.value]);
    const reasonValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (_.isEmpty(reasonField.value)) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-reason"),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, reasonField.value]);
    const isValid = useMemo(() => {
        return !hasError([newNameValidations]);
    }, [newNameValidations]);

    return (
        <MutatorModal
            {...mutator}
            title={t("rename-wallet--title", {
                name: wallet.name,
                mobile: wallet.mobile,
            })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-rename")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...newNameField}
                    label={t("label-new-name")}
                    name="newName"
                    validations={newNameValidations}
                    defaultValue={wallet.user?.name}
                    autoFocus
                />
                <TextField
                    {...reasonField}
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
        </MutatorModal>
    );
};
export const RenameWalletMutator = createFragmentContainer(
    _RenameWalletMutator,
    {
        wallet: graphql`
            fragment RenameWallet_wallet on Wallet {
                id
                name
                mobile
                user {
                    name
                }
            }
        `,
    }
);
