import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactDOM from "react-dom";

import { Analytics } from "./external/analytics";
import { Flags } from "./external/flags";
import { HttpBadGatewayError, NetworkError } from "./external/relay";

import "./locales/i18n";

import { ENV_NAME, IS_PROD, history } from "./utils/environment";

import App from "./App";
import "./styles.css";

declare const SENTRY_RELEASE_ID: string;
declare const AMPLITUDE_KEY: string;
declare const AMPLITUDE_DEPLOYMENT_KEY: string;

if (IS_PROD) {
    Sentry.init({
        dsn: "https://0ee5da5a6a7a42ee958cf6402117cb68@o407766.ingest.sentry.io/5707977",
        release: SENTRY_RELEASE_ID,
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: [
                    "localhost",
                    "frontplugin.wave-internal.com",
                    /^\//,
                ],
                routingInstrumentation:
                    Sentry.reactRouterV5Instrumentation(history),
            }),
        ],
        tracesSampleRate: 0.2,
        environment: ENV_NAME,
        beforeSend(event, hint) {
            if (hint.originalException instanceof NetworkError) {
                event.fingerprint = ["Relay Network Error"];
            } else if (hint.originalException instanceof HttpBadGatewayError) {
                event.fingerprint = ["Relay HTTP Bad Gateway"];
            }
            return event;
        },
    });
}

Analytics.init(AMPLITUDE_KEY);
Flags.init(AMPLITUDE_DEPLOYMENT_KEY);

ReactDOM.render(<App />, document.querySelector("#app"));
