/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ProfileSearcher_merchant = {
    readonly id: string;
    readonly name: string;
    readonly city: string | null;
    readonly subcity: string | null;
    readonly ufid: string;
    readonly isActive: boolean;
    readonly principal: {
        readonly mobile: string | null;
    };
};
export type ProfileSearcher_merchant$data = ProfileSearcher_merchant;
export type ProfileSearcher_merchant$key = {
    readonly " $data"?: ProfileSearcher_merchant$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProfileSearcher_merchant">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "ProfileSearcher_merchant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subcity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ufid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "principal",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobile",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Merchant",
  "abstractKey": null
};
(node as any).hash = '7be3ac2ad2743f1cd7e188d071892c42';
export default node;
