import React from "react";
import { useTranslation } from "react-i18next";

import { ActionButton } from "../components/ActionButton";

import { useCurrentCall } from "@/hooks/useCurrentCall";

import { NoActionCallSurveyMutator } from "./NoActionCallSurvey";

export const NoActionCallSurveyAction = () => {
    const { t } = useTranslation();
    const { markNoActionSurveyShown } = useCurrentCall();

    return (
        <ActionButton
            as="button"
            name={t("no-action-survey--button")}
            data-testid="no-action-call-survey-btn"
            onShow={markNoActionSurveyShown}
        >
            <NoActionCallSurveyMutator />
        </ActionButton>
    );
};
