import { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownButton,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { graphql, useRelayEnvironment } from "react-relay";

import { Button } from "../components/Button";

import { mutate } from "@/external/relay";

import { useAsyncFunction } from "@/hooks/useAsyncFunction";

import { RepLoginLogoutWidgetMutation } from "./__generated__/RepLoginLogoutWidgetMutation.graphql";

const loginLogoutMutation = graphql`
    mutation RepLoginLogoutWidgetMutation($logIn: Boolean!) {
        supportRepLoginLogout(logIn: $logIn) {
            supportRep {
                id
                isLoggedInToCallcenterQueue
            }
        }
    }
`;

interface RepLoginLogoutWidgetProps {
    isRepLoggedIn: boolean | null;
    email?: string;
}

export function RepLoginLogoutWidget({
    isRepLoggedIn,
    email,
}: RepLoginLogoutWidgetProps) {
    const environment = useRelayEnvironment();
    const { t } = useTranslation();

    const loginLogoutRequest = useAsyncFunction(async (logIn: boolean) => {
        const [_, payloadErrors] = await mutate<RepLoginLogoutWidgetMutation>(
            environment,
            loginLogoutMutation,
            { logIn }
        );

        if (payloadErrors) throw payloadErrors;
    });

    // for other support app users that are not reps i.e. cannot
    // pick calls, isRepLoggedIn is returned as null. So in this
    // case we do not show the login/logout button at all
    if (isRepLoggedIn === null) return null;

    return (
        <div className="flex items-center justify-center mx-2">
            {isRepLoggedIn ? (
                <DropdownButton
                    id="dropdown-item-button"
                    title={email ? email[0].toUpperCase() : "WA"}
                >
                    <Dropdown.Item
                        as="button"
                        onClick={() => loginLogoutRequest.invoke(false)}
                        disabled={loginLogoutRequest.isLoading}
                    >
                        {t("rep-logout")}
                    </Dropdown.Item>
                </DropdownButton>
            ) : (
                <>
                    {loginLogoutRequest.isLoading ? (
                        <DotDotDots />
                    ) : (
                        <Button
                            className="mr-1"
                            onClick={() => loginLogoutRequest.invoke(true)}
                            disabled={loginLogoutRequest.isLoading}
                        >
                            {t("rep-login")}
                        </Button>
                    )}
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Tooltip id="call-in-queue-tooltip">
                                {loginLogoutRequest.error ? (
                                    <>{t("rep-login-error")}</>
                                ) : (
                                    <>{t("rep-currently-logout")}</>
                                )}
                            </Tooltip>
                        }
                    >
                        <div className="dropdown-placeholder" />
                    </OverlayTrigger>
                </>
            )}
        </div>
    );
}

function DotDotDots() {
    const [numDots, setNumDots] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setNumDots((n) => 1 + (n % 3));
        }, 500);
        return () => clearInterval(interval);
    }, []);

    return <div>{".".repeat(numDots)}</div>;
}
