import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { ForgetQrMutator } from "@/pages/mobile.[mobile].personal/ForgetQr";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const ForgetQrAction = (props: {
    user: Exclude<PersonalTabHeader_wallet["user"], null>;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="menuitem"
            name={t("forget-qr--action")}
            requiredPermissions={["supportapp_forget_qr"]}
        >
            <ForgetQrMutator user={props.user} />
        </ActionButton>
    );
};
