import React, { useCallback } from "react";
import { QueryRenderer, graphql, useRelayEnvironment } from "react-relay";

import { WrongBackendError } from "@/external/relay";

import { usePermissions } from "@/hooks/usePermissions";

import { CAN_READ_BALANCE } from "@/types/Permissions";
import { ReadyState } from "@/types/RelayReadyState";

import { isValidMobileProfile } from "@/utils/mobile";

import {
    ProfileByMobileQuery,
    ProfileByMobileQueryResponse,
} from "./__generated__/ProfileByMobileQuery.graphql";

import { InvalidMobile } from "./InvalidMobile";
import { ProfileBoundary } from "./ProfileBoundary";
import { ProfileView, ProfileViewPayload } from "./ProfileView";

export const mobileHeaderQuery = graphql`
    query ProfileByMobileQuery($mobile: String!, $canReadBalance: Boolean!) {
        unregisteredMobile(mobile: $mobile) {
            ...NoUserPanel_unregisteredMobile
            ...UnregisteredMobileInfoHeader_unregisteredMobile
            ...BlockUnblockUser_unregisteredMobile
            mobile
            activeBlock {
                blockReason
                blockedBy
                whenBlocked
                blockSubject
                whenExpires
            }
            blockHistory {
                whenBlocked
                blockedBy
                blockReason
                whenUnblocked
                unblockedBy
                unblockReason
                blockSubject
                whenExpires
            }
        }
        wallet(mobile: $mobile) {
            ...UserInfoHeader_walletData
                @arguments(canReadBalance: $canReadBalance)
            mobile
            name
            balance @include(if: $canReadBalance)
            ownerName
            user {
                id
                mobile
                agentUser {
                    name
                }
                merchant {
                    id
                    name
                }
            }
        }
        caller(telnum: $mobile) {
            preferredCallcenterLanguage
        }
        supportCallerMetrics(telnum: $mobile) {
            numberCallsToday
        }
        supportNotes(mobile: $mobile) {
            list {
                noteId
                whenCreated
            }
        }
    }
`;

type ProfileProps = {
    mobile: string;
};

type ProfileByMobileProps = {
    tab?: string;
    mobile: string;
    country?: string;
};

/**
 * Renders the profile for the given mobile.
 * It uses {@link ProfileBoundary} to manage invalid use cases.
 * @param props {@link ProfileByMobileProps}
 */
export const ProfileByMobile = (
    props: ProfileByMobileProps
): React.ReactElement => {
    return (
        <ProfileBoundary mobile={props.mobile} country={props.country}>
            <Profile mobile={props.mobile} />
        </ProfileBoundary>
    );
};

const Profile = ({ mobile }: ProfileProps) => {
    const environment = useRelayEnvironment();
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const render = useCallback(
        ({ props, error, retry }: ReadyState<ProfileByMobileQueryResponse>) => {
            if (error instanceof WrongBackendError) throw error;

            const payload: ProfileViewPayload | null = props
                ? {
                      walletData: props.wallet,
                      unregisteredMobile: props.unregisteredMobile,
                      caller: props.caller,
                      supportCallerMetrics: props.supportCallerMetrics,
                      supportNotes: props.supportNotes,
                      isAgent: Boolean(props.wallet?.user?.agentUser),
                      isMerchant: Boolean(props.wallet?.user?.merchant),
                  }
                : null;

            return (
                <ProfileView
                    mobile={mobile}
                    searchOpaqueId={null}
                    payload={payload}
                    error={error}
                    retry={retry}
                />
            );
        },
        [mobile]
    );

    if (!isValidMobileProfile(mobile)) return <InvalidMobile mobile={mobile} />;

    return (
        <QueryRenderer<ProfileByMobileQuery>
            environment={environment}
            render={render}
            fetchPolicy="store-and-network"
            query={mobileHeaderQuery}
            variables={{ mobile, canReadBalance }}
        />
    );
};
