/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NotesTabQueryVariables = {
    mobile: string;
};
export type NotesTabQueryResponse = {
    readonly wallet: {
        readonly mobile: string;
    } | null;
    readonly supportNotes: {
        readonly " $fragmentRefs": FragmentRefs<"NotesList_supportNotes">;
    };
};
export type NotesTabQuery = {
    readonly response: NotesTabQueryResponse;
    readonly variables: NotesTabQueryVariables;
};



/*
query NotesTabQuery(
  $mobile: String!
) {
  wallet(mobile: $mobile) {
    mobile
    id
  }
  supportNotes(mobile: $mobile) {
    ...NotesList_supportNotes
    id
  }
}

fragment NotesList_supportNotes on SupportNotes {
  list {
    noteId
    text
    mobile
    adminUsername
    whenCreated
    whenUpdated
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "mobile",
    "variableName": "mobile"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobile",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotesTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Wallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupportNotes",
        "kind": "LinkedField",
        "name": "supportNotes",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotesList_supportNotes"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotesTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Wallet",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupportNotes",
        "kind": "LinkedField",
        "name": "supportNotes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SupportNote",
            "kind": "LinkedField",
            "name": "list",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noteId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminUsername",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "whenCreated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "whenUpdated",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47945b43a7efcda528d4cef72d71be15",
    "id": null,
    "metadata": {},
    "name": "NotesTabQuery",
    "operationKind": "query",
    "text": "query NotesTabQuery(\n  $mobile: String!\n) {\n  wallet(mobile: $mobile) {\n    mobile\n    id\n  }\n  supportNotes(mobile: $mobile) {\n    ...NotesList_supportNotes\n    id\n  }\n}\n\nfragment NotesList_supportNotes on SupportNotes {\n  list {\n    noteId\n    text\n    mobile\n    adminUsername\n    whenCreated\n    whenUpdated\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c2eeb72f70df358c4f1fc564478fe43c';
export default node;
