import dayjs from "dayjs";

import { formatTimestamp } from "@/utils/time";

export const Timestamp = (props: { value: Date; format?: string }) => {
    return (
        <span className="date">
            {props.format
                ? dayjs(props.value).format(props.format)
                : formatTimestamp(props.value)}
        </span>
    );
};
