import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { useCurrentCall } from "@/hooks/useCurrentCall";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";

import { hasError } from "@/utils/formValidation";
import {
    isDigit,
    isValidSupportRepMobile,
    supportRepMobileValidationErrorMessage,
} from "@/utils/mobile";

import { CallUserMutationResponse } from "./__generated__/CallUserMutation.graphql";

const mutation = graphql`
    mutation CallUserMutation(
        $supportMobile: String!
        $customerMobile: String!
    ) {
        callUser(
            supportMobile: $supportMobile
            customerMobile: $customerMobile
        ) {
            result
        }
    }
`;

type CallUserInputParams = {
    name: string;
    customerMobile: string;
};

const WAVEMM_SUPPORT_MOBILE = "WAVEMM_SUPPORT_MOBILE";

const _CallUserMutator = (props: CallUserInputParams & RelayModalProps) => {
    const { onHide, name, customerMobile } = props;
    const { t } = useTranslation();
    const { setCurrentCall } = useCurrentCall();

    const onMutationSuccess = useCallback(
        (response: CallUserMutationResponse) => {
            if (response.callUser?.result) {
                // in the absence of knowing what the call is, we set to outgoing with an empty callId
                // TODO (AR): eventually I'd like this to be accurate but
                // right now we don't know the call info at this stage
                setCurrentCall({
                    callType: "outgoing",
                    mobile: customerMobile,
                });
            }
            return response.callUser?.result || "";
        },
        [setCurrentCall, customerMobile]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: "call user",
            data: { mobile: customerMobile },
        },
        ...props,
    });

    const supportMobileField = useTextField();

    const handleSubmit = useCallback(() => {
        const supportMobileWithoutPlus = `${supportMobileField.value
            .split("")
            .filter(isDigit)
            .join("")}`;
        const supportMobile =
            supportMobileWithoutPlus.slice(0, 3) === "000"
                ? supportMobileWithoutPlus
                : `+${supportMobileWithoutPlus}`;
        window.localStorage.setItem(WAVEMM_SUPPORT_MOBILE, supportMobile);
        mutator.submit(mutation, { customerMobile, supportMobile });
    }, [mutator, supportMobileField.value, customerMobile]);

    const supportMobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!isValidSupportRepMobile(supportMobileField.value)) {
            validations.push(
                supportRepMobileValidationErrorMessage as Validation
            );
        }
        return validations;
    }, [supportMobileField.value]);

    const isValid = useMemo(() => {
        return !hasError([supportMobileValidations]);
    }, [supportMobileValidations]);

    const fromLocalStorage =
        window.localStorage.getItem(WAVEMM_SUPPORT_MOBILE) || "";

    return (
        <MutatorModal
            {...mutator}
            title={t("call-user--title", { name, customerMobile })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-call")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...supportMobileField}
                    label={t("call-user--mobile-label")}
                    name="supportMobile"
                    validations={supportMobileValidations}
                    defaultValue={fromLocalStorage}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};

export const CallUserMutator = createFragmentContainer(_CallUserMutator, {});
