import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isValidMobile, mobileValidationErrorMessage } from "@/utils/mobile";

import { SendAgentAppLinkMutationResponse } from "./__generated__/SendAgentAppLinkMutation.graphql";

const mutation = graphql`
    mutation SendAgentAppLinkMutation($mobile: String!) {
        sendAgentAppLink(mobile: $mobile) {
            result
        }
    }
`;

type SendAgentAppLinkInputParams = {
    name: string;
    mobile: string;
};

const _SendAgentAppLinkMutator = (
    props: SendAgentAppLinkInputParams & RelayModalProps
) => {
    const { onHide, name, mobile } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: SendAgentAppLinkMutationResponse) => {
            return response.sendAgentAppLink?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: { name: Action.SendAgentAppLink, data: { mobile } },
        ...props,
    });

    const mobileField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, { mobile: mobileField.value });
    }, [mutator, mobileField.value]);

    const mobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!isValidMobile(mobileField.value)) {
            validations.push(mobileValidationErrorMessage as Validation);
        }
        return validations;
    }, [mobileField.value]);
    const isValid = useMemo(() => {
        return !hasError([mobileValidations]);
    }, [mobileValidations]);

    return (
        <MutatorModal
            {...mutator}
            title={t("send-agent-link--title", { name })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...mobileField}
                    label={t("label-mobile")}
                    name="mobile"
                    validations={mobileValidations}
                    defaultValue={mobile}
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};
export const SendAgentAppLinkMutator = createFragmentContainer(
    _SendAgentAppLinkMutator,
    {}
);
