import { useTranslation } from "react-i18next";

import {
    ActionButton,
    ActionButtonType,
    VOIPVerificationLevel,
} from "../../components/ActionButton";

import { MoveBalanceMutator } from "@/pages/mobile.[mobile].personal/MoveBalance";

import { M } from "../../utils/currency";

export const MoveBalanceAction = (props: {
    mobile: string;
    balance: string;
    name: string;
    button_type: ActionButtonType;
}) => {
    const { t } = useTranslation();
    return (
        <ActionButton
            as={props.button_type}
            name={t("move-balance--action")}
            requiredPermissions={["supportapp_move_balance"]}
            VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
        >
            <MoveBalanceMutator
                mobile={props.mobile}
                name={props.name}
                balance={M.fromSerialized(props.balance)}
            />
        </ActionButton>
    );
};
