import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";
import { OperationEnum } from "@/types/misc";

import { ModifyAgentGamingAgentWatchlistMutationResponse } from "./__generated__/ModifyAgentGamingAgentWatchlistMutation.graphql";

const mutation = graphql`
    mutation ModifyAgentGamingAgentWatchlistMutation(
        $agentId: ID!
        $operation: AgentGamingListOperation!
        $comment: String
    ) {
        modifyAgentGamingAgentWatchlist(
            agentId: $agentId
            operation: $operation
            comment: $comment
        ) {
            success
        }
    }
`;

type AgentGamingAgentWatchlistParams = {
    name: string;
    agentId: string;
    operation: OperationEnum;
};

const _ModifyAgentGamingAgentWatchlistMutator = (
    props: AgentGamingAgentWatchlistParams & RelayModalProps
) => {
    const { onHide, name, agentId, operation } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ModifyAgentGamingAgentWatchlistMutationResponse) => {
            if (
                response?.modifyAgentGamingAgentWatchlist?.success &&
                operation === OperationEnum.ADD
            ) {
                return t("agent-gaming-agent-watchlist--success-add");
            }
            if (
                response?.modifyAgentGamingAgentWatchlist?.success &&
                operation === OperationEnum.REMOVE
            ) {
                return t("agent-gaming-agent-watchlist--success-remove");
            }
            return t("agent-gaming-agent-watchlist--error");
        },
        [operation, t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name:
                operation === OperationEnum.ADD
                    ? Action.AddAgentToAgentGamingWatchlist
                    : Action.RemoveAgentFromAgentGamingWatchlist,
            data: { agentId },
        },
        ...props,
    });

    const commentField = useTextField();

    const handleSubmit = useCallback(() => {
        const body = {
            agentId: agentId,
            operation,
            comment: commentField.value,
        };
        mutator.submit(mutation, body);
    }, [mutator, commentField.value, agentId, operation]);

    return (
        <MutatorModal
            {...mutator}
            title={
                operation == OperationEnum.ADD
                    ? t("agent-gaming-agent-watchlist--title-add", { name })
                    : t("agent-gaming-agent-watchlist--title-remove", { name })
            }
        >
            <Form
                {...mutator}
                isValid={true}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                {operation === OperationEnum.ADD && (
                    <TextField
                        {...commentField}
                        label={t("label-comment")}
                        name="comment"
                        defaultValue={""}
                        validations={[]}
                        autoFocus
                    />
                )}
                {operation === OperationEnum.REMOVE && (
                    <p>
                        {t("agent-gaming-agent-watchlist--description-remove", {
                            id: agentId,
                        })}
                    </p>
                )}
            </Form>
        </MutatorModal>
    );
};
export const ModifyAgentGamingAgentWatchlistMutator = createFragmentContainer(
    _ModifyAgentGamingAgentWatchlistMutator,
    {}
);
