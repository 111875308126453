import * as Sentry from "@sentry/react";
import { Redirect, Route, Switch } from "react-router";

import { Flags } from "@/external/flags";

import ProfileById from "@/pages/id.[id]";
import ProfileByMobile from "@/pages/mobile.[mobile]";
import NoProfileSelected from "@/pages/no-profile-selected";
import PubSubDemo from "@/pages/pubsub-demo";
import NoIncomingCall from "@/pages/specialpage.no-current-call";

export const SentryRoute = Sentry.withSentryRouting(Route);

type RoutesProps = {
    country?: string;
};

export default function Routes({ country }: RoutesProps) {
    const routes = [
        /**
         * TODO (antonio): Try lazy-loading components?
         * I tried in 84ef6662ff244a9375af4f5b8270b596672a56a4 but the issue was that
         * clicking on tabs in the profile view generated a full page reload.
         * I tried nesting routes inside <ProfileView> without success, but maybe
         * that's the way to go?
         * Route-based code-splitting for free!
         */
        { path: "/id/:id", Component: ProfileById },
        { path: "/mobile/:mobile", Component: ProfileByMobile },
        { path: "/no-profile-selected", Component: NoProfileSelected },
        { path: "/specialpage/no-current-call", Component: NoIncomingCall },
    ];

    const showPubSubDemo = Flags.getFlag("show_pub_sub_demo")?.isActive;

    if (showPubSubDemo) {
        routes.push({ path: "/pubsub-demo", Component: PubSubDemo });
    }

    return (
        <Switch>
            {routes.map(({ path, Component }) => (
                <SentryRoute
                    key={path}
                    path={path}
                    render={(routeProps) => (
                        <Component
                            {...routeProps?.match?.params}
                            country={country}
                        />
                    )}
                />
            ))}

            {/* Catch-all */}
            <Redirect from="*" to="/no-profile-selected" />
        </Switch>
    );
}
