/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UnregisteredMobileInfoHeader_unregisteredMobile = {
    readonly mobile: string;
    readonly " $fragmentRefs": FragmentRefs<"BlockUnblockUser_unregisteredMobile" | "UnregisteredMobileBlocks_blockData">;
    readonly " $refType": "UnregisteredMobileInfoHeader_unregisteredMobile";
};
export type UnregisteredMobileInfoHeader_unregisteredMobile$data = UnregisteredMobileInfoHeader_unregisteredMobile;
export type UnregisteredMobileInfoHeader_unregisteredMobile$key = {
    readonly " $data"?: UnregisteredMobileInfoHeader_unregisteredMobile$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"UnregisteredMobileInfoHeader_unregisteredMobile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnregisteredMobileInfoHeader_unregisteredMobile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobile",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BlockUnblockUser_unregisteredMobile"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UnregisteredMobileBlocks_blockData"
    }
  ],
  "type": "UnregisteredMobile",
  "abstractKey": null
};
(node as any).hash = '01601d5269b13cc67f1ff31d87f9e575';
export default node;
