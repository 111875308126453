import * as React from "react";
import { useTranslation } from "react-i18next";

type InvalidMobileProps = {
    mobile: string;
};

export const InvalidMobile = (
    props: InvalidMobileProps
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <>
            <title>{t("invalid-mobile--title")}</title>
            <div className="text-center text-gray-500">
                <h3 className="text-xl text-bold">
                    {t("invalid-mobile--subtitle")}
                </h3>
                <h5 className="text-bold">
                    {t("invalid-mobile--description", { mobile: props.mobile })}
                </h5>
            </div>
        </>
    );
};
