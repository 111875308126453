/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AppPermissionsQueryVariables = {};
export type AppPermissionsQueryResponse = {
    readonly supportAppUser: {
        readonly id: string;
        readonly permissions: ReadonlyArray<string>;
        readonly countries: ReadonlyArray<string>;
    };
};
export type AppPermissionsQuery = {
    readonly response: AppPermissionsQueryResponse;
    readonly variables: AppPermissionsQueryVariables;
};



/*
query AppPermissionsQuery {
  supportAppUser {
    id
    permissions
    countries
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SupportAppUser",
    "kind": "LinkedField",
    "name": "supportAppUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "countries",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppPermissionsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppPermissionsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ded778ae5a64c4c478d24af4ebf073e3",
    "id": null,
    "metadata": {},
    "name": "AppPermissionsQuery",
    "operationKind": "query",
    "text": "query AppPermissionsQuery {\n  supportAppUser {\n    id\n    permissions\n    countries\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f376d1ed28631f68b29b970d04a812fb';
export default node;
