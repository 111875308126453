/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SendBusinessProposalFormLinkMutationVariables = {
    mobile: string;
};
export type SendBusinessProposalFormLinkMutationResponse = {
    readonly sendBusinessProposalFormLink: {
        readonly result: string | null;
    } | null;
};
export type SendBusinessProposalFormLinkMutation = {
    readonly response: SendBusinessProposalFormLinkMutationResponse;
    readonly variables: SendBusinessProposalFormLinkMutationVariables;
};



/*
mutation SendBusinessProposalFormLinkMutation(
  $mobile: String!
) {
  sendBusinessProposalFormLink(mobile: $mobile) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobile"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      }
    ],
    "concreteType": "SendBusinessProposalFormLink",
    "kind": "LinkedField",
    "name": "sendBusinessProposalFormLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendBusinessProposalFormLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendBusinessProposalFormLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0bdec2d433e16478a5945aec225acf33",
    "id": null,
    "metadata": {},
    "name": "SendBusinessProposalFormLinkMutation",
    "operationKind": "mutation",
    "text": "mutation SendBusinessProposalFormLinkMutation(\n  $mobile: String!\n) {\n  sendBusinessProposalFormLink(mobile: $mobile) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '918dfa8b4a9c6297cc51079fd3b568de';
export default node;
