import { fetchQuery, graphql, useRelayEnvironment } from "react-relay";

import { noCall } from "@/context/CurrentCall";

import { useCurrentCall } from "@/hooks/useCurrentCall";

import {
    useUpdateCurrentCallQuery,
    useUpdateCurrentCallQueryResponse,
} from "./__generated__/useUpdateCurrentCallQuery.graphql";

type CurrentCallQueryProps = {
    onSuccess?: (data: useUpdateCurrentCallQueryResponse | undefined) => void;
    onError?: (error: Error) => void;
    onQueryEnded?: () => void;
};

const query = graphql`
    query useUpdateCurrentCallQuery {
        currentPhoneCall {
            id
            theirTelnum
            opaqueId
            whenStarted
            whenConnected
            callbackForPhoneCallRecord {
                opaqueId
                whenStarted
            }
            transferForPhoneCallRecord {
                opaqueId
                repName
            }
            theirRoute
        }
    }
`;

export const useUpdateCurrentCall = ({
    onSuccess,
    onError,
    onQueryEnded,
}: CurrentCallQueryProps) => {
    const environment = useRelayEnvironment();
    const { setCurrentCall } = useCurrentCall();

    return () => {
        fetchQuery<useUpdateCurrentCallQuery>(environment, query, {})
            .toPromise()
            .then((data?: useUpdateCurrentCallQueryResponse) => {
                if (data && data.currentPhoneCall) {
                    if (data.currentPhoneCall.theirTelnum) {
                        const commonCallState = {
                            mobile: data.currentPhoneCall.theirTelnum,
                            callId: data.currentPhoneCall.opaqueId,
                            startTime: data.currentPhoneCall.whenConnected
                                ? new Date(data.currentPhoneCall.whenConnected)
                                : undefined,
                            isVoip:
                                data.currentPhoneCall.theirRoute ==
                                "twilio-voip",
                        };
                        if (data.currentPhoneCall.callbackForPhoneCallRecord) {
                            const callbackInfo =
                                data.currentPhoneCall
                                    .callbackForPhoneCallRecord;
                            setCurrentCall({
                                callType: "callback",
                                ...commonCallState,
                                originatingCallId: callbackInfo.opaqueId,
                                originatingCallAt: new Date(
                                    callbackInfo.whenStarted
                                ),
                            });
                        } else if (
                            data.currentPhoneCall.transferForPhoneCallRecord
                        ) {
                            const transferInfo =
                                data.currentPhoneCall
                                    .transferForPhoneCallRecord;
                            setCurrentCall({
                                callType: "transfer",
                                ...commonCallState,
                                originatingCallId: transferInfo.opaqueId,
                                originatingCallRep:
                                    transferInfo.repName || "unknown",
                            });
                        } else {
                            setCurrentCall({
                                callType: "incoming",
                                ...commonCallState,
                            });
                        }
                    }
                } else {
                    setCurrentCall(noCall);
                }
                if (onSuccess) onSuccess(data);
            })
            .catch((error) => {
                setCurrentCall(noCall);
                if (onError) {
                    return onError(error);
                } else {
                    return Promise.reject(error);
                }
            })
            .finally(() => {
                if (onQueryEnded) onQueryEnded();
            });
    };
};
