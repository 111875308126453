import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { TicketLink } from "../../components/TicketLink";
import { HistoryActionButton } from "@/components/HistoryActionButton";
import { ProfileLink } from "@/components/ProfileLink";
import { Timestamp } from "@/components/Timestamp";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { FreezeTransactionHistoryActionButton } from "@/pages/mobile.[mobile].personal/FreezeTransactionHistoryActionButton";

import { CAN_REFUND_P2P_TX } from "@/types/Permissions";

import { M } from "@/utils/currency";

import { TransferSentEntry_tx } from "./__generated__/TransferSentEntry_tx.graphql";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ReportCustomerScamMutator } from "../mobile.[mobile]/ReportCustomerScam";
import { BlockRecipientMutator } from "./BlockRecipient";
import { ChangeRecipientMutator } from "./ChangeRecipient";
import { RefundP2PMutator } from "./RefundP2P";

function _TransferSentEntry(
    props: {
        tx: TransferSentEntry_tx;
        reversedTransfers: {
            [key: string]: Date;
        };
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx, reversedTransfers } = props;
    const whenReversed = reversedTransfers[tx.transferId];
    const isReversed = !!whenReversed;
    const hasSupportAppChangeRecipientPermission = usePermissions(
        "supportapp_change_recipient"
    );
    const hasSupportAppRefundPermission = usePermissions(CAN_REFUND_P2P_TX);
    const canReportScam = usePermissions("supportapp_report_customer_scam");
    const canReportScamWithDetails = usePermissions(
        "supportapp_report_customer_scam_with_details"
    );
    const buttons = [
        ...(!isReversed
            ? [
                  <HistoryActionButton
                      key="history-action-button--refund"
                      name={t("refund--action")}
                      disabled={!hasSupportAppRefundPermission}
                  >
                      <RefundP2PMutator transfer={tx} />
                  </HistoryActionButton>,
                  <HistoryActionButton
                      key="history-action-button--change-recipient"
                      name={t("change-recipient--action")}
                      disabled={!hasSupportAppChangeRecipientPermission}
                  >
                      <ChangeRecipientMutator transfer={tx} />
                  </HistoryActionButton>,
                  <FreezeTransactionHistoryActionButton
                      key="history-action-button--freeze-funds"
                      transferOrLedgerTransactionId={tx.transferId}
                      recipientMobile={tx.recipientMobile}
                      receiveAmount={tx.receiveAmount}
                  />,
              ]
            : []),
        ...[
            <HistoryActionButton
                key="history-action-button--report-customer-scam"
                name={t("report-customer-scam--action")}
                disabled={!canReportScam}
            >
                <ReportCustomerScamMutator
                    transactionId={tx.transferId}
                    mobile={tx.senderMobile}
                    amountStolen={M.fromSerialized(tx.amount).negate()}
                    scamReports={tx.scamReports}
                    hideAdditionalInfoFields={!canReportScamWithDetails}
                />
            </HistoryActionButton>,
            <HistoryActionButton
                key="history-action-button--block-recipient"
                name={t("block-recipient--action")}
                disabled={!usePermissions("supportapp_block_recipient")}
            >
                <BlockRecipientMutator transfer={tx} />
            </HistoryActionButton>,
        ],
    ];

    const info = isReversed ? (
        <TxInfo>
            {tx.transferId} &nbsp;&#40;{t("transfer-sent-entry--reversed")}{" "}
            <Timestamp value={whenReversed} />)
        </TxInfo>
    ) : (
        <TxInfo>{tx.transferId}</TxInfo>
    );
    // &#40 is ( but for some reason ( breaks syntax highlighting...

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <>
                    <TxTitle>
                        {t("transfer-sent-entry--title")}{" "}
                        <ProfileLink
                            name={tx.recipientName}
                            mobile={tx.maybeTerminatedRecipientMobile}
                            opaqueId={tx.transferId}
                        />{" "}
                        {tx.relatedTicket && (
                            <TicketLink
                                status={tx.relatedTicket.status}
                                slackMessageUrl={
                                    tx.relatedTicket.slackMessageUrl
                                }
                            />
                        )}
                    </TxTitle>
                    {info}
                </>
            }
            buttons={buttons}
        />
    );
}

export default createFragmentContainer(_TransferSentEntry, {
    tx: graphql`
        fragment TransferSentEntry_tx on TransferSentEntry {
            transferId
            senderMobile
            recipientName
            recipientMobile
            maybeTerminatedRecipientMobile
            amount
            receiveAmount
            relatedTicket {
                whenOpened
                whenResolved
                status
                slackMessageUrl
                ticketType
                reference
            }
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            ...ChangeRecipient_transfer
            ...RefundP2P_transfer
            ...BlockRecipient_transfer
            ...HistoryEntry_entry
        }
    `,
});
