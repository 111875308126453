import { useCallback, useMemo } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isValidMobile } from "@/utils/mobile";

import { SendCustomerAppLinkMutationResponse } from "../mobile.[mobile]/__generated__/SendCustomerAppLinkMutation.graphql";
import { SendUpdateAppLink_wallet } from "./__generated__/SendUpdateAppLink_wallet.graphql";

const mutation = graphql`
    mutation SendUpdateAppLinkMutation($mobile: String!, $opaqueId: String) {
        sendUpdateAppLink(mobile: $mobile, opaqueId: $opaqueId) {
            result
        }
    }
`;

type SendUpdateAppLinkInputParams = {
    wallet: SendUpdateAppLink_wallet;
};

const _SendUpdateAppLinkMutator = (
    props: RelayModalProps & SendUpdateAppLinkInputParams
) => {
    const { onHide, wallet } = props;
    const { t } = useTranslation();
    const mobileField = useTextField(wallet.mobile);
    const isRegistered = !(wallet == null);

    const onMutationSuccess = useCallback(
        (_: SendCustomerAppLinkMutationResponse): string => {
            return t("send-update-app-link--success");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.SendUpdateAppLink,
            data: { mobile: mobileField.value },
        },
        ...props,
    });

    const mobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!isValidMobile(mobileField.value)) {
            validations.push({
                type: "error",
                message: t("send-update-app-link--help"),
                display: "always",
            });
        }
        return validations;
    }, [t, mobileField.value]);

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobile: mobileField.value,
            opaqueId: wallet.id,
        });
    }, [mutator, wallet, mobileField.value]);

    return (
        <MutatorModal
            {...mutator}
            title={t("send-update-app-link--title", { name: wallet.name })}
        >
            {isRegistered ? (
                <Form
                    {...mutator}
                    isValid={!hasError([mobileValidations])}
                    onSubmit={handleSubmit}
                    onDone={onHide}
                    submitText={t("action-send")}
                >
                    <TextField
                        {...mobileField}
                        label={t("label-mobile")}
                        name="mobile"
                        validations={mobileValidations}
                        autoFocus
                    />
                </Form>
            ) : (
                <Alert variant="danger">
                    {t("send-update-app-link--unregistered")}
                </Alert>
            )}
        </MutatorModal>
    );
};

export const SendUpdateAppLinkMutator = createFragmentContainer(
    _SendUpdateAppLinkMutator,
    {
        wallet: graphql`
            fragment SendUpdateAppLink_wallet on Wallet {
                mobile
                name
                id
            }
        `,
    }
);
