import { useCallback } from "react";

import { Analytics, AnalyticsData } from "@/external/analytics";

import { useCurrentCall } from "@/hooks/useCurrentCall";

import { Action } from "@/types/actions";

const isCallAction = (key: string): key is Action => {
    return (Object.values(Action) as string[]).includes(key);
};

export const useTrackEvent = () => {
    const { trackCallAction } = useCurrentCall();

    const trackEvent = useCallback(
        (key: string, data?: AnalyticsData) => {
            if (isCallAction(key)) {
                trackCallAction(key);
            }

            data ? Analytics.track(key, data) : Analytics.track(key);
        },
        [trackCallAction]
    );
    return trackEvent;
};
