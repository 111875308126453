/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from "react";
import { ButtonGroup, ButtonToolbar, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ActionButton, VOIPVerificationLevel } from "@/components/ActionButton";
import { BalanceInfo } from "@/components/BalanceInfo";

import { useFlag } from "@/hooks/useFlag";
import { usePermissions } from "@/hooks/usePermissions";

import { AllowToRecoverPinMutator } from "@/pages/mobile.[mobile].personal/AllowToRecoverPin";
import { ModifyAgentGamingUserWatchlistMutator } from "@/pages/mobile.[mobile].personal/ModifyAgentGamingUserWatchlist";
import { ModifyAgentGamingUserWhitelistMutator } from "@/pages/mobile.[mobile].personal/ModifyAgentGamingUserWhitelist";
import PersonalBadges from "@/pages/mobile.[mobile].personal/PersonalBadges";
import { TerminateUserAction } from "@/pages/mobile.[mobile].personal/TerminateUserAction";
import { JumpToDateInput } from "@/pages/mobile.[mobile]/JumpToDate";

import {
    CAN_READ_BALANCE,
    CAN_SEE_SCAM_INVESTIGATIONS_INFO,
} from "@/types/Permissions";
import { OperationEnum } from "@/types/misc";

import { M } from "@/utils/currency";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

import { AwardCreditAction } from "./AwardCreditAction";
import { BlockFromAgentDepositsAction } from "./BlockFromAgentDepositsAction";
import { BlockUnblockUserAction } from "./BlockUnblockUserAction";
import { BlockUnblockUserLegalEntityAction } from "./BlockUnblockUserLegalEntityAction";
import { CalculateLimitsAction } from "./CalculateLimitsAction";
import { CallUserAction } from "./CallUserAction";
import { CancellAllAtxAction } from "./CancellAllAtxAction";
import { ClearPinRateLimitAction } from "./ClearPinRateLimitAction";
import { DeviceFilter } from "./DeviceFilter";
import { EscalateToMerchantSupportAction } from "./EscalateToMerchantSupportAction";
import { EscalateToSupportGroupLeadAction } from "./EscalateToSupportGroupLeadAction";
import { ForgetQrAction } from "./ForgetQrAction";
import { LogoutAllUserSessionsAction } from "./LogoutAllUserSessionsAction";
import { ManualTransferAction } from "./ManualTransferAction";
import { MoveBalanceAction } from "./MoveBalanceAction";
import { PurchaseAirtimeAction } from "./PurchaseAirtimeAction";
import { RenameWalletAction } from "./RenameWalletAction";
import { ReportPaymentCardProblemAction } from "./ReportPaymentCardProblemAction";
import { RequestToUnblockUserAction } from "./RequestToUnblockUserAction";
import { ResetPinAction } from "./ResetPinAction";
import { SendBusinessProposalFormLinkAction } from "./SendBusinessProposalFormLinkAction";
import { SendCustomerAppLinkAction } from "./SendCustomerAppLinkAction";
import { SendRequestToBeAnAgentLinkAction } from "./SendRequestToBeAnAgentLinkAction";
import { ShowSmsCodeAction } from "./ShowSmsCodeAction";
import { ShowWithdrawalCodesAction } from "./ShowWithdrawalCodesAction";
import { ShowWoyofalCodesAction } from "./ShowWoyofalCodesAction";
import { UnlinkLegalEntityAction } from "./UnlinkLegalEntityAction";

const _PersonalTabHeader = ({
    wallet,
    setWhen,
    when,
    deviceIdFilter,
    setDeviceIdFilter,
}: {
    wallet: PersonalTabHeader_wallet;
    when?: Date;
    setWhen: (date: Date) => void;
    deviceIdFilter: string | null;
    setDeviceIdFilter: (deviceId: string | null) => void;
}) => {
    const canReadBalance = usePermissions(CAN_READ_BALANCE);
    const shouldShowClearPinRateBlock = useFlag(
        "clear-pin-block-action"
    )?.isActive;
    const allowVOIPVerification = useFlag(
        "tmp-allow-voip-verification"
    )?.isActive;
    const showRiskyPinResetWarning =
        useFlag("show-risky-pin-reset-warning")?.isActive ?? false;

    const { t } = useTranslation();

    const hasSupportAddBlockFromAgentDepositsPermission = usePermissions(
        "supportapp_block_from_agent_deposits"
    );
    const hasSupportAppRecoverPinPermission = usePermissions(
        "supportapp_recover_pin"
    );
    const hasScamInvestigationPermission = usePermissions(
        CAN_SEE_SCAM_INVESTIGATIONS_INFO
    );
    const isPinResetRisky = wallet.user?.isPinResetRisky ?? false;
    const allowToRecoverPinButton = (
        <>
            {wallet.user && hasSupportAppRecoverPinPermission && (
                <ActionButton
                    name={
                        (showRiskyPinResetWarning && isPinResetRisky
                            ? "⚠ "
                            : "") + t("recover-pin--action")
                    }
                    disabled={wallet.user.inPinRecovery}
                    VOIPVerification={
                        allowVOIPVerification
                            ? VOIPVerificationLevel.ALLOWED
                            : VOIPVerificationLevel.FORBIDDEN
                    }
                >
                    <AllowToRecoverPinMutator user={wallet.user} />
                </ActionButton>
            )}
        </>
    );
    const clearPinRateLimitButton = (
        <>
            {wallet.user && shouldShowClearPinRateBlock && (
                <ClearPinRateLimitAction user={wallet.user} />
            )}
        </>
    );
    const devices = React.useMemo(() => {
        return (wallet.user?.devices || [])
            .filter(({ failedLoginDevice }) => !failedLoginDevice)
            .map(({ deviceId, deviceModel, whenLoggedOut }) => ({
                deviceId,
                deviceModel,
                whenLoggedOut,
            }));
    }, [wallet.user?.devices]);

    return (
        <div className="bg-gray-50 border-b border-gray-200 py-2 pr-12 pl-2">
            <PersonalBadges wallet={wallet} />

            <BalanceInfo
                balance={
                    wallet.balance
                        ? M.fromSerialized(wallet.balance)
                        : undefined
                }
                amountPending={
                    wallet.amountPending
                        ? M.fromSerialized(wallet.amountPending)
                        : undefined
                }
            />

            <div className="h-1" />

            <ButtonToolbar className="tab-header-toolbar justify-between">
                {wallet.whenTerminated && (
                    <div>
                        <b>
                            {t("personal-tab-header--user-account-terminated", {
                                date: new Date(
                                    wallet.whenTerminated
                                ).toDateString(),
                                reason: wallet.reasonTerminated,
                                terminator: wallet.whoTerminated || "(unknown)",
                            })}
                        </b>
                    </div>
                )}
                {wallet.whenTerminated && (
                    <ButtonGroup>
                        <EscalateToSupportGroupLeadAction
                            mobile={wallet.mobile}
                            supportGroupLeadEscalations={
                                wallet.supportGroupLeadEscalations
                            }
                            supportPartialRefundEscalations={
                                wallet.supportPartialRefundEscalations
                            }
                        />
                        {wallet.balance && (
                            <MoveBalanceAction
                                mobile={wallet.mobile}
                                name={wallet.name}
                                balance={wallet.balance}
                                button_type={"button"}
                            />
                        )}
                    </ButtonGroup>
                )}
                {!wallet.whenTerminated && (
                    <ButtonGroup>
                        {!wallet.user && (
                            <ShowWithdrawalCodesAction wallet={wallet} />
                        )}

                        <ShowSmsCodeAction mobile={wallet.mobile} />

                        <ShowWoyofalCodesAction wallet={wallet} />

                        <CallUserAction
                            name={wallet.name}
                            mobile={wallet.mobile}
                        />

                        {clearPinRateLimitButton}
                        {allowToRecoverPinButton}

                        {wallet.user && (
                            <EscalateToMerchantSupportAction
                                mobile={wallet.mobile}
                                user={wallet.user}
                                merchantSupportEscalations={
                                    wallet.merchantSupportEscalations
                                }
                            />
                        )}

                        <EscalateToSupportGroupLeadAction
                            mobile={wallet.mobile}
                            supportGroupLeadEscalations={
                                wallet.supportGroupLeadEscalations
                            }
                            supportPartialRefundEscalations={
                                wallet.supportPartialRefundEscalations
                            }
                        />

                        <DropdownButton
                            title={
                                <div
                                    className="inline-block"
                                    style={{ margin: "1px 0" }}
                                >
                                    {t("personal-tab-header--more")}
                                </div>
                            }
                            id="user-dropdown"
                            variant="outline-dark"
                            data-testid="more-actions-dropdown"
                        >
                            {wallet.user && (
                                <ForgetQrAction user={wallet.user} />
                            )}

                            <ResetPinAction wallet={wallet} />

                            {canReadBalance ? (
                                <ManualTransferAction mobile={wallet.mobile} />
                            ) : (
                                <></>
                            )}

                            {wallet.user && canReadBalance ? (
                                <PurchaseAirtimeAction mobile={wallet.mobile} />
                            ) : (
                                <></>
                            )}

                            <SendCustomerAppLinkAction
                                name={wallet.name}
                                mobile={wallet.mobile}
                            />

                            {/* An existing agent might want to open a new location, so we still show this option regardless of whether the user is already an agent. */}
                            <SendRequestToBeAnAgentLinkAction
                                mobile={wallet.mobile}
                            />

                            <SendBusinessProposalFormLinkAction
                                name={wallet.name}
                                mobile={wallet.mobile}
                            />

                            <RenameWalletAction wallet={wallet} />

                            <UnlinkLegalEntityAction wallet={wallet} />

                            {wallet.user &&
                                !wallet.user.agentDepositsBlock &&
                                hasSupportAddBlockFromAgentDepositsPermission && (
                                    <BlockFromAgentDepositsAction
                                        user={wallet.user}
                                    />
                                )}

                            <RequestToUnblockUserAction wallet={wallet} />

                            <BlockUnblockUserAction wallet={wallet} />

                            <BlockUnblockUserLegalEntityAction
                                wallet={wallet}
                            />

                            {canReadBalance ? (
                                <CalculateLimitsAction mobile={wallet.mobile} />
                            ) : (
                                <></>
                            )}

                            {wallet.user && (
                                <LogoutAllUserSessionsAction
                                    user={wallet.user}
                                />
                            )}

                            {wallet.balance && (
                                <AwardCreditAction
                                    mobile={wallet.mobile}
                                    name={wallet.name}
                                    balance={wallet.balance}
                                />
                            )}

                            <CancellAllAtxAction wallet={wallet} />

                            {wallet.balance && (
                                <MoveBalanceAction
                                    mobile={wallet.mobile}
                                    name={wallet.name}
                                    balance={wallet.balance}
                                    button_type={"menuitem"}
                                />
                            )}
                            {wallet.user?.userOnAgentGamingWhitelist
                                .isOnList === false && (
                                <ActionButton
                                    as="menuitem"
                                    name={t(
                                        "agent-gaming-user-whitelist--action"
                                    )}
                                    requiredPermissions={[
                                        "supportapp_modify_agent_gaming_user_whitelist",
                                    ]}
                                >
                                    <ModifyAgentGamingUserWhitelistMutator
                                        name={wallet.name}
                                        mobile={wallet.mobile}
                                        operation={OperationEnum.ADD}
                                    />
                                </ActionButton>
                            )}
                            {wallet.user?.userOnAgentGamingWhitelist
                                .isOnList && (
                                <ActionButton
                                    as="menuitem"
                                    name={t(
                                        "agent-gaming-user-whitelist--action-remove"
                                    )}
                                    requiredPermissions={[
                                        "supportapp_modify_agent_gaming_user_whitelist",
                                    ]}
                                >
                                    <ModifyAgentGamingUserWhitelistMutator
                                        name={wallet.name}
                                        mobile={wallet.mobile}
                                        operation={OperationEnum.REMOVE}
                                    />
                                </ActionButton>
                            )}
                            {wallet.user?.userOnAgentGamingWatchlist
                                .isOnList === false && (
                                <ActionButton
                                    as="menuitem"
                                    name={t(
                                        "agent-gaming-user-watchlist--action"
                                    )}
                                    requiredPermissions={[
                                        "supportapp_modify_agent_gaming_user_watchlist",
                                    ]}
                                >
                                    <ModifyAgentGamingUserWatchlistMutator
                                        name={wallet.name}
                                        mobile={wallet.mobile}
                                        operation={OperationEnum.ADD}
                                    />
                                </ActionButton>
                            )}
                            {wallet.user?.userOnAgentGamingWatchlist
                                .isOnList && (
                                <ActionButton
                                    as="menuitem"
                                    name={t(
                                        "agent-gaming-user-watchlist--action-remove"
                                    )}
                                    requiredPermissions={[
                                        "supportapp_modify_agent_gaming_user_watchlist",
                                    ]}
                                >
                                    <ModifyAgentGamingUserWatchlistMutator
                                        name={wallet.name}
                                        mobile={wallet.mobile}
                                        operation={OperationEnum.REMOVE}
                                    />
                                </ActionButton>
                            )}
                            <TerminateUserAction mobile={wallet.mobile} />

                            <ReportPaymentCardProblemAction
                                mobile={wallet.mobile}
                            />
                        </DropdownButton>
                    </ButtonGroup>
                )}
                <ButtonGroup>
                    <JumpToDateInput when={when} setWhen={setWhen} />
                </ButtonGroup>
                {devices && hasScamInvestigationPermission && (
                    <ButtonGroup>
                        <DeviceFilter
                            devices={devices}
                            deviceIdFilter={deviceIdFilter}
                            setDeviceIdFilter={setDeviceIdFilter}
                        />
                    </ButtonGroup>
                )}
            </ButtonToolbar>
        </div>
    );
};

export const PersonalTabHeader = createFragmentContainer(_PersonalTabHeader, {
    wallet: graphql`
        fragment PersonalTabHeader_wallet on Wallet
        @argumentDefinitions(canReadBalance: { type: "Boolean!" }) {
            id
            mobile
            name
            balance @include(if: $canReadBalance)
            amountPending @include(if: $canReadBalance)
            kycTier
            kybTier
            isTrader
            photoEditUrl
            documentStatus
            hasRejectedGovIds
            isRefundAbuser
            whenTerminated
            reasonTerminated
            whoTerminated
            tickets {
                whenOpened
                whenResolved
                status
                slackMessageUrl
                ticketType
                reference
            }
            activeMobileBlock {
                whenBlocked
                blockSubject
                requestToModifyBlockTickets {
                    status
                }
            }
            activeLegalEntityBlock {
                whenBlocked
                blockSubject
                requestToModifyBlockTickets {
                    status
                }
            }
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            billPayProblems {
                whenOpened
                billType
                status
                transactionId
            }
            supportGroupLeadEscalations {
                whenOpened
                status
                clickupTaskId
            }
            supportPartialRefundEscalations {
                whenOpened
                status
                clickupTaskId
            }
            merchantSupportEscalations {
                whenOpened
                status
                clickupTaskId
            }
            balanceLimitInfoV1 {
                max
            }
            inflowLimitInfoV1 {
                max
            }
            ...ShowWithdrawalCodes_wallet
            ...ShowWoyofalCodes_wallet
            ...ResetPin_wallet
            ...SendUpdateAppLink_wallet
            ...SendWithdrawalCode_wallet
            ...SendWoyofalCode_wallet
            ...RenameWallet_wallet
            ...CancelAllAtx_wallet
            ...RequestToBlockUnblockUser_wallet
            ...RequestToUnblockFromAgentDeposits_wallet
            ...RequestToUnblockFromUsingMultipleDevices_wallet
            ...BlockUnblockUser_wallet
            user {
                isPinResetRisky
                devices {
                    deviceId
                    deviceModel
                    whenLoggedOut
                    failedLoginDevice
                    activeBlock {
                        blockReason
                    }
                }
                customerAppVersion {
                    appType
                    string
                    isUpToDate
                }
                hasQrCard
                customerOverdraftStatus
                qrCardBlock {
                    whenBlocked
                }
                personalMultipleDevicesBlock {
                    whenBlocked
                }
                agentMultipleDevicesBlock {
                    whenBlocked
                }
                agentDepositsBlock {
                    whenBlocked
                }
                needsIdPhotoConfirmation {
                    legalName
                }
                userOnAgentGamingWatchlist {
                    isOnList
                    whenUpdated
                }
                userOnAgentGamingWhitelist {
                    isOnList
                    whenUpdated
                }
                inPinRecovery
                hasWeakPin
                elligibleToReceivePayPromo
                ...AllowToRecoverPin_user
                ...UnblockFromUsingQrCards_user
                ...UnblockFromUsingMultipleDevices_user
                ...BlockFromAgentDeposits_user
                ...UnblockFromAgentDeposits_user
                ...LogoutAllUserSessions_user
                ...ForgetQr_user
                ...EscalateToMerchantSupport_user
                ...ClearPinRateLimitAction_user
            }
            legalEntity {
                wallets(walletType: PERSONAL) {
                    mobile
                }
            }
        }
    `,
});
