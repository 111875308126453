import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "@/components/Form";
import { MutatorModal, useMutator } from "@/components/MutatorModal";
import { TextField, useTextField } from "@/components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { ReportPaymentCardProblemMutationResponse } from "./__generated__/ReportPaymentCardProblemMutation.graphql";

const mutation = graphql`
    mutation ReportPaymentCardProblemMutation(
        $mobile: String!
        $comment: String!
    ) {
        reportPaymentCardProblem(mobile: $mobile, comment: $comment) {
            result
        }
    }
`;
type ReportPaymentCardProblemInputParams = {
    mobile: string;
    comment: string;
};

const _ReportPaymentCardProblemMutator = (
    props: ReportPaymentCardProblemInputParams & RelayModalProps
) => {
    const { onHide, mobile, comment } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ReportPaymentCardProblemMutationResponse) => {
            return response.reportPaymentCardProblem?.result || "";
        },
        []
    );
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportPaymentCardProblem,
            data: { mobile: mobile },
        },
        ...props,
    });
    const mobileField = useTextField();
    const additionalInfoField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobile: mobileField.value,
            comment: additionalInfoField.value,
        });
    }, [mutator, mobileField.value, additionalInfoField.value]);

    const commentValidation = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!additionalInfoField.value) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-comments"),
            });
        }
        return validations;
    }, [additionalInfoField.value, t]);

    const isValid = useMemo(() => {
        return !hasError([commentValidation]);
    }, [commentValidation]);

    return (
        <MutatorModal {...mutator} title={t("report-payment-card--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...mobileField}
                    label={t("label-mobile")}
                    name="mobile"
                    validations={[]}
                    defaultValue={mobile}
                    disabled={true}
                />

                <TextField
                    {...additionalInfoField}
                    label={t("label-comment")}
                    name="comment"
                    validations={commentValidation}
                    defaultValue={comment}
                    as="textarea"
                    autoFocus
                />
            </Form>
        </MutatorModal>
    );
};
export const ReportPaymentCardProblemMutator = createFragmentContainer(
    _ReportPaymentCardProblemMutator,
    {}
);
