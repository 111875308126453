/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReversalDisputeEntry_tx = {
    readonly description: string;
    readonly isPending: boolean;
    readonly ledgerTransactionId: string;
    readonly originalTransferId: string | null;
    readonly __typename: "ReversalDisputeEntry";
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "ReversalDisputeEntry_tx";
};
export type ReversalDisputeEntry_tx$data = ReversalDisputeEntry_tx;
export type ReversalDisputeEntry_tx$key = {
    readonly " $data"?: ReversalDisputeEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ReversalDisputeEntry_tx">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReversalDisputeEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPending",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ledgerTransactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalTransferId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "ReversalDisputeEntry",
  "abstractKey": null
};
(node as any).hash = 'd8ea384d55dfa19afd07a12b20bbb88f';
export default node;
