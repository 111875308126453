/**
 * Build a URL from a base URL and a sequence of path elements.
 *
 * Takes care of normalising the path to remove redundant slashes.
 *
 * @example
 * ```
 * const baseUrl = new URL("https://example.com/foo")
 * // Prints "https://example.com/foo/bar/baz"
 * console.log(buildUrlString(baseUrl, "bar", "baz"))
 * ```
 */
export const buildUrlString = (base: URL, ...pathElements: string[]) => {
    const fullPath = (base.pathname + "/" + pathElements.join("/")).replaceAll(
        /\/+/g,
        "/"
    );
    return base.origin + fullPath;
};
