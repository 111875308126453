import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import {
    DropdownField,
    useDropdownField,
} from "../../components/DropdownField";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { ProfileSearchField } from "../../components/ProfileSearchField";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { MerchantInfo } from "@/types/ProfileSearcher";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isValidMobile, mobileValidationErrorMessage } from "@/utils/mobile";

import { EscalateToMerchantSupportMutationResponse } from "./__generated__/EscalateToMerchantSupportMutation.graphql";
import { EscalateToMerchantSupport_user } from "./__generated__/EscalateToMerchantSupport_user.graphql";

import {
    MerchantSupportEscalations,
    MerchantSupportEscalationsHistoryTable,
} from "./MerchantSupportEscalationsHistoryTable";

const mutation = graphql`
    mutation EscalateToMerchantSupportMutation(
        $mobile: String!
        $reporter: String!
        $issueType: MerchantIssueType!
        $comments: String!
        $merchantUfid: String
    ) {
        escalateToMerchantSupport(
            mobile: $mobile
            reporter: $reporter
            issueType: $issueType
            comments: $comments
            merchantUfid: $merchantUfid
        ) {
            result
        }
    }
`;

type EscalateToMerchantSupportInputParams = {
    mobile: string;
    comments?: string;
    merchantSupportEscalations?: MerchantSupportEscalations;
    user: EscalateToMerchantSupport_user | null;
};

const _EscalateToMerchantSupportMutator = (
    props: EscalateToMerchantSupportInputParams & RelayModalProps
) => {
    const { onHide, mobile, comments, merchantSupportEscalations, user } =
        props;

    const [merchantUfid, setMerchantUfid] = useState<string | undefined>(
        user?.merchant?.ufid
    );

    const { t } = useTranslation();

    // TODO(SUP-393): Unify the way we get the list of merchant issues for NoUserPanel, PersonalTab and MerchantTab
    const default_merchant_issues = useMemo(
        () => [
            {
                value: "BUSINESS_PROPOSAL_FOLLOW_UP",
                displayName: t(
                    "escalate-merchant-issue-type--business-proposal"
                ),
            },
            {
                value: "ADD_REMOVE_ASSISTANT",
                displayName: t(
                    "escalate-merchant-issue-type--add-remove-assistant"
                ),
            },
            {
                value: "KYB3_REQUEST",
                displayName: t("escalate-merchant-issue-type--kyb3-request"),
            },
            {
                value: "NOT_SEEING_PAYMENTS",
                displayName: t(
                    "escalate-merchant-issue-type--not-seeing-payments"
                ),
            },
            {
                value: "REFUND_REQUEST_USER_ERROR",
                displayName: t(
                    "escalate-merchant-issue-type--refund-user-error"
                ),
            },
            {
                value: "REFUND_REQUEST_MERCHANT_ERROR",
                displayName: t(
                    "escalate-merchant-issue-type--refund-merchant-error"
                ),
            },
            {
                value: "OTHER",
                displayName: t("escalate-merchant-issue-type--other"),
            },
        ],
        [t]
    );

    const onMutationSuccess = useCallback(
        (response: EscalateToMerchantSupportMutationResponse) => {
            return response.escalateToMerchantSupport?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.EscalateToMerchantSupport,
            data: { mobile },
        },
        ...props,
    });

    const mobileField = useTextField();
    const reporterField = useDropdownField();
    const additionalInfoField = useTextField();
    const issueTypeField = useDropdownField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobile: mobileField.value,
            reporter: reporterField.dropdownItem?.value,
            issueType: issueTypeField.dropdownItem?.value,
            comments: additionalInfoField.value,
            merchantUfid,
        });
    }, [
        mutator,
        mobileField.value,
        reporterField.dropdownItem?.value,
        issueTypeField.dropdownItem?.value,
        additionalInfoField.value,
        merchantUfid,
    ]);

    const mobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!isValidMobile(mobileField.value)) {
            validations.push(mobileValidationErrorMessage as Validation);
        }

        return validations;
    }, [mobileField.value]);

    const reporterValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!reporterField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-reporter"),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, reporterField.dropdownItem]);

    const issueTypeValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!issueTypeField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-issue"),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, issueTypeField.dropdownItem]);

    const additionalInfoFieldValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (
            issueTypeField.dropdownItem?.value === "OTHER" &&
            !additionalInfoField.value
        ) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-description"),
                display: "if-blurred",
            });
        }
        return validations;
    }, [t, issueTypeField.dropdownItem?.value, additionalInfoField.value]);

    const isValid = useMemo(() => {
        return !hasError([
            mobileValidations,
            reporterValidations,
            issueTypeValidations,
            additionalInfoFieldValidations,
        ]);
    }, [
        mobileValidations,
        reporterValidations,
        issueTypeValidations,
        additionalInfoFieldValidations,
    ]);

    const issueTypes = useMemo(() => {
        if (user) {
            return user.possibleMerchantIssueTypes.map((type) => {
                return {
                    value: type.type,
                    displayName: type.displayName,
                };
            });
        }
        return default_merchant_issues;
    }, [default_merchant_issues, user]);

    const reporters = [
        {
            value: "Merchant",
            displayName: t("merchant"),
        },
        {
            value: "Customer",
            displayName: t("customer"),
        },
    ];

    return (
        <MutatorModal
            {...mutator}
            title={t("escalate-merchant-support--title")}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-send")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...mobileField}
                    label={t("label-customer-mobile")}
                    name="mobile"
                    validations={mobileValidations}
                    defaultValue={mobile}
                    autoFocus
                />

                <DropdownField
                    {...reporterField}
                    label={t("label-reporter")}
                    name="reporter"
                    validations={reporterValidations}
                    values={reporters}
                    autoFocus
                    placeholder={t("placeholder-reporter")}
                />

                <ProfileSearchField
                    autoFocus
                    allowAgents={false}
                    allowUnregistered={false}
                    allowMerchants={true}
                    allowWallets={false}
                    allowTerminated
                    label={t("label-merchant-id")}
                    name="merchant"
                    validations={[]}
                    initialQuery={merchantUfid}
                    onChange={(profile) => {
                        if (profile) {
                            setMerchantUfid((profile as MerchantInfo)?.ufid);
                        } else {
                            setMerchantUfid(undefined);
                        }
                    }}
                />

                <DropdownField
                    {...issueTypeField}
                    label={t("label-issue")}
                    name="issueType"
                    validations={issueTypeValidations}
                    values={issueTypes}
                    autoFocus
                    placeholder={t("placeholder-issue")}
                />

                <TextField
                    {...additionalInfoField}
                    label={t("label-comments")}
                    name="comments"
                    validations={additionalInfoFieldValidations}
                    defaultValue={comments}
                    as="textarea"
                    autoFocus
                />
            </Form>
            <p>{t("escalate-merchant-support-history--title")}</p>
            <table className="table">
                <thead>
                    <tr>
                        <th>
                            {t(
                                "escalate-merchant-support-history--reported-on"
                            )}
                        </th>
                        <th>
                            {t("escalate-merchant-support-history--status")}
                        </th>
                        <th>
                            {t("escalate-merchant-support-history--clickup-id")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <MerchantSupportEscalationsHistoryTable
                        tickets={merchantSupportEscalations}
                    />
                </tbody>
            </table>
        </MutatorModal>
    );
};

export const EscalateToMerchantSupportMutator = createFragmentContainer(
    _EscalateToMerchantSupportMutator,
    {
        user: graphql`
            fragment EscalateToMerchantSupport_user on User {
                possibleMerchantIssueTypes {
                    type
                    displayName
                }
                merchant {
                    ufid
                }
            }
        `,
    }
);
