/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TicketStatus = "OPEN" | "RESOLVED" | "%future added value";
export type BillPaymentEntry_tx = {
    readonly transferId: string;
    readonly senderMobile: string;
    readonly maybeTerminatedSenderMobile: string;
    readonly relatedTicket: {
        readonly status: TicketStatus;
        readonly slackMessageUrl: string | null;
    } | null;
    readonly scamReports: ReadonlyArray<{
        readonly whenOpened: string;
        readonly whenResolved: string | null;
        readonly status: TicketStatus;
        readonly transactionIds: string | null;
    }>;
    readonly billPayProblems: ReadonlyArray<{
        readonly whenOpened: string;
        readonly billType: string | null;
        readonly status: TicketStatus;
        readonly transactionId: string | null;
    }>;
    readonly amount: string;
    readonly billAccount: string;
    readonly billId: string;
    readonly billName: string;
    readonly isCancelled: boolean;
    readonly summary: string | null;
    readonly errorCode: string | null;
    readonly localizedErrMessage: string | null;
    readonly airtimeSms: string | null;
    readonly meterNumber: string | null;
    readonly " $fragmentRefs": FragmentRefs<"HistoryEntry_entry">;
    readonly " $refType": "BillPaymentEntry_tx";
};
export type BillPaymentEntry_tx$data = BillPaymentEntry_tx;
export type BillPaymentEntry_tx$key = {
    readonly " $data"?: BillPaymentEntry_tx$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BillPaymentEntry_tx">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whenOpened",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillPaymentEntry_tx",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transferId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maybeTerminatedSenderMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TicketShortInfo",
      "kind": "LinkedField",
      "name": "relatedTicket",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slackMessageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScamReportTicket",
      "kind": "LinkedField",
      "name": "scamReports",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "whenResolved",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillPayProblemTicket",
      "kind": "LinkedField",
      "name": "billPayProblems",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billType",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transactionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billAccount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCancelled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "summary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errorCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedErrMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "airtimeSms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meterNumber",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoryEntry_entry"
    }
  ],
  "type": "BillPaymentEntry",
  "abstractKey": null
};
})();
(node as any).hash = '41720f156015275ff258e7dc930fa74c';
export default node;
