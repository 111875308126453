import * as React from "react";

interface Props {
    children: React.ReactNode;
    isExpanded: boolean;
}

export const HistoryEntryAccordionDetailsContainer = (props: Props) => (
    <div
        className={
            "history-entry-accordion-details " +
            (props.isExpanded ? "open" : "closed")
        }
    >
        {props.children}
    </div>
);
