import * as React from "react";
import { useTranslation } from "react-i18next";

export const NoIncomingCall = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <>
            <title>{t("no-incoming-call--title")}</title>
            <div className="text-center text-gray-500">
                <h3 className="text-xl text-bold">
                    {t("no-incoming-call--subtitle")}
                </h3>
                <h5 className="text-bold">
                    {t("no-incoming-call--description")}
                </h5>
            </div>
        </>
    );
};
