import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { UnblockFromAgentDepositsMutationResponse } from "./__generated__/UnblockFromAgentDepositsMutation.graphql";
import { UnblockFromAgentDeposits_user } from "./__generated__/UnblockFromAgentDeposits_user.graphql";

import { AgentDepositsBlockHistory } from "./AgentDepositsBlockHistory";

const mutation = graphql`
    mutation UnblockFromAgentDepositsMutation($userId: ID!, $reason: String!) {
        unblockFromAgentDeposits(userId: $userId, reason: $reason) {
            user {
                ...UnblockFromAgentDeposits_user
            }
        }
    }
`;

type UnblockFromAgentDepositsParams = {
    user: UnblockFromAgentDeposits_user;
};

const _UnblockFromAgentDepositsMutator = (
    props: UnblockFromAgentDepositsParams & RelayModalProps
) => {
    const { onHide } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (_response: UnblockFromAgentDepositsMutationResponse) => {
            return t("unblock-agent-deposits--success");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.UnblockFromAgentDeposits,
            data: { userId: props.user.id },
        },
        ...props,
    });
    const reasonField = useTextField();

    const reasonValidations: Validation[] = useMemo(
        () =>
            reasonField.value.length === 0
                ? [
                      {
                          type: "error",
                          message: t("unblock-agent-deposits--missing-reason"),
                          display: "if-blurred",
                      },
                  ]
                : [],
        [t, reasonField.value.length]
    );

    const handleSubmit = () => {
        mutator.submit(mutation, {
            userId: props.user.id,
            reason: reasonField.value,
        });
    };

    const isValid = useMemo(() => {
        return !hasError([reasonValidations]);
    }, [reasonValidations]);

    return (
        <MutatorModal
            {...mutator}
            size="lg"
            title={t("unblock-agent-deposits--title")}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("unblock-agent-deposits--submit")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <p>{t("unblock-agent-deposits--description")}</p>
                <TextField
                    {...reasonField}
                    as="textarea"
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
            <AgentDepositsBlockHistory user={props.user} />
        </MutatorModal>
    );
};

export const UnblockFromAgentDepositsMutator = createFragmentContainer(
    _UnblockFromAgentDepositsMutator,
    {
        user: graphql`
            fragment UnblockFromAgentDeposits_user on User {
                id
                agentDepositsBlock {
                    whenBlocked
                    blockedBy
                    blockReason
                }
                ...AgentDepositsBlockHistory_user
            }
        `,
    }
);
