import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { DropdownField, useDropdownField } from "@/components/DropdownField";

import { Flags } from "@/external/flags";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";
import { isValidMobile } from "@/utils/mobile";

import { ReportAgentIssueMutationResponse } from "./__generated__/ReportAgentIssueMutation.graphql";
import { ReportAgentIssue_agentUser } from "./__generated__/ReportAgentIssue_agentUser.graphql";

const mutation = graphql`
    mutation ReportAgentIssueMutation(
        $agentId: ID!
        $description: String
        $issueType: AgentIssueType!
        $reportingCustomerMobile: String
        $trainingTopic: AgentTrainingTopic
        $trainingTarget: String
    ) {
        reportAgentIssue(
            agentId: $agentId
            description: $description
            issueType: $issueType
            reportingCustomerMobile: $reportingCustomerMobile
            trainingTopic: $trainingTopic
            trainingTarget: $trainingTarget
        ) {
            success
        }
    }
`;
type ReportAgentIssueInputParams = {
    agentUser: ReportAgentIssue_agentUser;
};

const _ReportAgentIssueMutator = (
    props: RelayModalProps & ReportAgentIssueInputParams
) => {
    const { onHide, agentUser } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ReportAgentIssueMutationResponse): string => {
            if (!response.reportAgentIssue) {
                return "";
            }

            const success = response.reportAgentIssue.success;
            return success
                ? t("report-agent-issue--success")
                : t("report-agent-issue--error");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportAgentIssue,
            data: { agentUfid: agentUser.agent.ufid },
        },
        ...props,
    });

    const issueTypeField = useDropdownField();
    const trainingTopicField = useDropdownField();
    const trainingTargetField = useDropdownField();
    const descriptionField = useTextField();
    const mobileField = useTextField();

    const isCustomerMobileFieldVisible = useMemo(() => {
        return issueTypeField.dropdownItem?.optGroup == "User Complaint";
    }, [issueTypeField.dropdownItem]);

    const isTrainingTopicFieldVisible = useMemo(() => {
        const needsTraining =
            issueTypeField.dropdownItem?.value == "AGENT_NEEDS_TRAINING";
        if (!needsTraining) {
            trainingTopicField.setDropdownItem(null);
            trainingTargetField.setDropdownItem(null);
        }
        return needsTraining;
    }, [
        issueTypeField.dropdownItem?.value,
        trainingTargetField,
        trainingTopicField,
    ]);

    const isTrainingTargetFieldVisible = useMemo(
        () =>
            isTrainingTopicFieldVisible &&
            agentUser.agent.agentUsers &&
            agentUser.agent.agentUsers.length > 0,
        [isTrainingTopicFieldVisible, agentUser.agent.agentUsers]
    );

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            agentId: agentUser.agent.ufid,
            issueType: issueTypeField.dropdownItem?.value,
            description: descriptionField.value,
            reportingCustomerMobile: isCustomerMobileFieldVisible
                ? mobileField.value.trim()
                : null,
            trainingTopic: isTrainingTopicFieldVisible
                ? trainingTopicField.dropdownItem?.value
                : null,
            trainingTarget: isTrainingTopicFieldVisible
                ? trainingTargetField.dropdownItem?.value
                : null,
        });
    }, [
        mutator,
        agentUser.agent.ufid,
        issueTypeField.dropdownItem?.value,
        descriptionField.value,
        isCustomerMobileFieldVisible,
        mobileField.value,
        isTrainingTopicFieldVisible,
        trainingTopicField.dropdownItem?.value,
        trainingTargetField.dropdownItem?.value,
    ]);

    const issueTypeValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (!issueTypeField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-issue-type"),
                display: "none",
            });
        }
        return validations;
    }, [t, issueTypeField.dropdownItem]);

    const trainingTopicValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (isTrainingTopicFieldVisible && !trainingTopicField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-training-topic"),
                display: "none",
            });
        }
        return validations;
    }, [t, isTrainingTopicFieldVisible, trainingTopicField.dropdownItem]);

    const trainingTargetValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];

        if (isTrainingTargetFieldVisible && !trainingTargetField.dropdownItem) {
            validations.push({
                type: "error",
                message: t("form-validation--missing-training-target"),
                display: "none",
            });
        }
        return validations;
    }, [t, isTrainingTargetFieldVisible, trainingTargetField.dropdownItem]);

    const customerMobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (
            isCustomerMobileFieldVisible &&
            (!mobileField.value || !isValidMobile(mobileField.value))
        ) {
            validations.push({
                type: "error",
                display: "always",
                message: t("report-agent-issue--customer-mobile"),
            });
        }
        return validations;
    }, [t, mobileField.value, isCustomerMobileFieldVisible]);

    const isValid = useMemo(() => {
        return !hasError([
            issueTypeValidations,
            trainingTopicValidations,
            trainingTargetValidations,
            customerMobileValidations,
        ]);
    }, [
        issueTypeValidations,
        trainingTopicValidations,
        trainingTargetValidations,
        customerMobileValidations,
    ]);

    const issueTypes = useMemo(() => {
        const shouldShowUserComplaint =
            Flags.getFlag("show-user-complaint")?.isActive || false;

        return agentUser.agent.possibleIssueTypes
            .filter((type) => {
                if (shouldShowUserComplaint) {
                    return type.displayGroupName === "Agent Request";
                }
                return true;
            })
            .map((type) => {
                if (shouldShowUserComplaint) {
                    return {
                        value: type.type,
                        displayName: type.displayName,
                    };
                }
                return {
                    value: type.type,
                    displayName: type.displayName,
                    optGroup: type.displayGroupName,
                };
            });
    }, [agentUser.agent.possibleIssueTypes]);

    const trainingTargets = useMemo(() => {
        const everyone = {
            value: "EVERYONE",
            displayName: t("everyone"),
        };

        const principalId = agentUser.agent.principal.id;
        const agentUsers = agentUser.agent.agentUsers
            ?.filter((agentUser) => agentUser !== null && agentUser.isActive)
            .map((agentUser) => {
                const user = agentUser?.user || {
                    name: "",
                    contactMobile: "",
                    id: "",
                };
                return {
                    value: `${user.name} (${user.contactMobile})`,
                    displayName:
                        principalId === user.id
                            ? `${user.name} (${t("principal")})`
                            : user.name,
                };
            });
        const users = agentUsers ?? [];
        return users.length > 1 ? [everyone].concat(users) : users;
    }, [t, agentUser.agent.agentUsers, agentUser.agent.principal]);

    const trainingTopics = useMemo(() => {
        return (
            agentUser.agent.trainingTopics?.map((topic) => {
                return {
                    value: topic.type,
                    displayName: topic.displayName,
                };
            }) || []
        );
    }, [agentUser.agent.trainingTopics]);

    return (
        <MutatorModal
            {...mutator}
            title={t("report-agent-issue--title", {
                name: agentUser.agent.name,
            })}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-report")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <DropdownField
                    {...issueTypeField}
                    label={t("label-issue-type")}
                    name="issueType"
                    validations={issueTypeValidations}
                    values={issueTypes}
                    autoFocus
                    placeholder={t("placeholder-issue-type")}
                />
                {isTrainingTopicFieldVisible && (
                    <DropdownField
                        {...trainingTopicField}
                        label={t("label-topic")}
                        name="trainingTopic"
                        validations={trainingTopicValidations}
                        values={trainingTopics}
                        autoFocus
                        placeholder={t("placeholder-topic")}
                    />
                )}
                {isTrainingTopicFieldVisible &&
                    isTrainingTargetFieldVisible && (
                        <DropdownField
                            {...trainingTargetField}
                            label={t("label-training-target")}
                            name="trainingTarget"
                            validations={trainingTargetValidations}
                            values={trainingTargets}
                            placeholder={t("placeholder-training-target")}
                        />
                    )}
                <TextField
                    {...descriptionField}
                    as="textarea"
                    label={t("label-description")}
                    name="description"
                    validations={[]}
                />
                {isCustomerMobileFieldVisible && (
                    <TextField
                        {...mobileField}
                        label={t("label-reporting-customer-mobile")}
                        name="reportingCustomerMobile"
                        validations={customerMobileValidations}
                    />
                )}
            </Form>
        </MutatorModal>
    );
};

export const ReportAgentIssueMutator = createFragmentContainer(
    _ReportAgentIssueMutator,
    {
        agentUser: graphql`
            fragment ReportAgentIssue_agentUser on AgentUser {
                agent {
                    ufid
                    name
                    trainingTopics {
                        type
                        displayName
                    }
                    possibleIssueTypes {
                        type
                        displayName
                        displayGroupName
                    }
                    principal {
                        id
                    }
                    agentUsers {
                        isActive
                        user {
                            id
                            contactMobile
                            name
                        }
                    }
                }
            }
        `,
    }
);
