/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ReportRefundingDisputeMutationVariables = {
    transferId: string;
    comments: string;
};
export type ReportRefundingDisputeMutationResponse = {
    readonly reportRefundingDispute: {
        readonly result: string | null;
    } | null;
};
export type ReportRefundingDisputeMutation = {
    readonly response: ReportRefundingDisputeMutationResponse;
    readonly variables: ReportRefundingDisputeMutationVariables;
};



/*
mutation ReportRefundingDisputeMutation(
  $transferId: ID!
  $comments: String!
) {
  reportRefundingDispute(transferId: $transferId, comments: $comments) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comments"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "transferId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comments",
        "variableName": "comments"
      },
      {
        "kind": "Variable",
        "name": "transferId",
        "variableName": "transferId"
      }
    ],
    "concreteType": "ReportRefundingDispute",
    "kind": "LinkedField",
    "name": "reportRefundingDispute",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportRefundingDisputeMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportRefundingDisputeMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "89b7ada9534d19814c6b62446fc706c9",
    "id": null,
    "metadata": {},
    "name": "ReportRefundingDisputeMutation",
    "operationKind": "mutation",
    "text": "mutation ReportRefundingDisputeMutation(\n  $transferId: ID!\n  $comments: String!\n) {\n  reportRefundingDispute(transferId: $transferId, comments: $comments) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cd10139e6f7f14b4e9a5f0c3a19a6f89';
export default node;
