import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { UnblockFromUsingQrCardsMutationResponse } from "./__generated__/UnblockFromUsingQrCardsMutation.graphql";
import { UnblockFromUsingQrCards_user } from "./__generated__/UnblockFromUsingQrCards_user.graphql";

import { DirectDepositBlockHistory } from "../mobile.[mobile]/DirectDepositBlockHistory";

const mutation = graphql`
    mutation UnblockFromUsingQrCardsMutation($userId: ID!, $reason: String!) {
        unblockFromUsingQrCards(userId: $userId, reason: $reason) {
            user {
                ...UnblockFromUsingQrCards_user
            }
        }
    }
`;

type UnblockFromUsingQrCardsInputParams = {
    user: UnblockFromUsingQrCards_user;
};

const _UnblockFromUsingQrCardsMutator = (
    props: UnblockFromUsingQrCardsInputParams & RelayModalProps
) => {
    const { onHide } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (_response: UnblockFromUsingQrCardsMutationResponse) => {
            return t("unblock-qr-cards--success");
        },
        [t]
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.UnblockFromUsingQrCards,
            data: { userId: props.user.id },
        },
        ...props,
    });
    const reasonField = useTextField();

    const reasonValidations: Validation[] = useMemo(
        () =>
            reasonField.value.length === 0
                ? [
                      {
                          type: "error",
                          message: t("unblock-qr-cards--missing-reason"),
                          display: "if-blurred",
                      },
                  ]
                : [],
        [t, reasonField.value.length]
    );

    const handleSubmit = () => {
        mutator.submit(mutation, {
            userId: props.user.id,
            reason: reasonField.value,
        });
    };

    const isValid = useMemo(() => {
        return !hasError([reasonValidations]);
    }, [reasonValidations]);

    return (
        <MutatorModal
            {...mutator}
            size="xl"
            title={t("unblock-qr-cards--title")}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("unblock-qr-cards--submit")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <p>{t("unblock-qr-cards--description")}</p>
                <TextField
                    {...reasonField}
                    as="textarea"
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
            <DirectDepositBlockHistory user={props.user} />
        </MutatorModal>
    );
};

export const UnblockFromUsingQrCardsMutator = createFragmentContainer(
    _UnblockFromUsingQrCardsMutator,
    {
        user: graphql`
            fragment UnblockFromUsingQrCards_user on User {
                ...DirectDepositBlockHistory_user
                id
                qrCardBlock {
                    whenBlocked
                    blockedBy
                    blockReason
                    whenExpires
                }
            }
        `,
    }
);
