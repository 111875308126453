import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";

import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { BlockRecipientMutationResponse } from "./__generated__/BlockRecipientMutation.graphql";
import { BlockRecipient_transfer } from "./__generated__/BlockRecipient_transfer.graphql";

const mutation = graphql`
    mutation BlockRecipientMutation($transferId: ID!) {
        blockRecipient(transferId: $transferId) {
            transfer {
                id
                recipientMobile
            }
        }
    }
`;

type BlockRecipientInputParams = {
    transfer: BlockRecipient_transfer;
};

const _BlockRecipientMutator = (
    props: BlockRecipientInputParams & RelayModalProps
) => {
    const { transfer, onHide } = props;

    const { t } = useTranslation();

    const onMutationSuccess = (
        response: BlockRecipientMutationResponse
    ): string | null => {
        if (response) {
            return t("block-recipient--success");
        }
        return null;
    };

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.BlockRecipient,
            data: { transferId: transfer.transferId },
        },
        ...props,
    });

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            transferId: transfer.transferId,
        });
    }, [transfer, mutator]);

    return (
        <MutatorModal
            {...mutator}
            title={t("block-recipient--title", {
                name: transfer.recipientName,
                mobile: transfer.recipientMobile,
            })}
        >
            <Form
                {...mutator}
                submitText={t("action-block")}
                isValid={true}
                onDone={onHide}
                onSubmit={handleSubmit}
            >
                <p>
                    {t("block-recipient--description", {
                        name: transfer.recipientName,
                        mobile: transfer.recipientMobile,
                        transfer: transfer.transferId,
                    })}
                </p>
            </Form>
        </MutatorModal>
    );
};

export const BlockRecipientMutator = createFragmentContainer(
    _BlockRecipientMutator,
    {
        transfer: graphql`
            fragment BlockRecipient_transfer on MobileTransferEntry {
                id
                transferId
                recipientName
                recipientMobile
            }
        `,
    }
);
