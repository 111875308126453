import { Fragment } from "react";
import Modal from "react-bootstrap/cjs/Modal";
import { useTranslation } from "react-i18next";

import { HistoryActionButton } from "@/components/HistoryActionButton";
import { TxInfo, TxInfoError } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ReportB2WOrW2BTransferCustomerProblemMutator } from "./ReportB2WOrW2BTransferCustomerProblem";

interface UserLinkedAccountBankTransfer {
    readonly summary: string | null;
    readonly isCancelled: boolean;
    readonly partnerName: string;
    readonly liaTransferId: string;
    readonly errorCode: string | null;
    readonly errorMessage: string | null;
}

function LinkedAccountBankTransferEntry(
    props: {
        tx: UserLinkedAccountBankTransfer;
        title: string;
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { title, tx } = props;
    let errorDetailsButton = null;

    if (tx.isCancelled) {
        errorDetailsButton = (
            <HistoryActionButton
                name={t(
                    "user-linked-account-bank-transfer--error-details--title"
                )}
            >
                <Modal>
                    <Modal.Header>
                        <Modal.Title>
                            {t(
                                "user-linked-account-bank-transfer--error-details--title"
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {t(
                                "user-linked-account-bank-transfer--error-details--transaction"
                            )}{" "}
                            {tx.liaTransferId}
                        </p>
                        <p>
                            {t(
                                "user-linked-account-bank-transfer--error-details--error-code"
                            )}{" "}
                            {tx.errorCode}
                        </p>
                        <p>
                            {t(
                                "user-linked-account-bank-transfer--error-details--description"
                            )}{" "}
                            {tx.errorMessage}
                        </p>
                    </Modal.Body>
                </Modal>
            </HistoryActionButton>
        );
    }

    const buttons = (
        <>
            <HistoryActionButton
                name={t("user-linked-account-bank-transfer--report-problem", {
                    type: title,
                })}
                requiredPermissions={[
                    "supportapp_report_b2w_or_w2b_customer_problem",
                ]}
            >
                <ReportB2WOrW2BTransferCustomerProblemMutator
                    transactionId={tx.liaTransferId}
                    title={title}
                />
            </HistoryActionButton>
            {tx.isCancelled && errorDetailsButton}
        </>
    );

    const summary = tx.summary?.replace("Bank", title);

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <Fragment>
                    <TxTitle>
                        {tx.partnerName} {summary}
                    </TxTitle>
                    {tx.isCancelled && (
                        <TxInfoError>
                            {t("user-linked-account-bank-transfer--cancelled")}
                        </TxInfoError>
                    )}
                    <TxInfo>{tx.liaTransferId}</TxInfo>
                </Fragment>
            }
            buttons={buttons}
        />
    );
}

const userLinkedAccountBankTransferEntryFactory = (
    replacementTitle: "B2W" | "W2B"
) =>
    function linkedAccountBankTransferEntryCreator(
        props: {
            tx: UserLinkedAccountBankTransfer;
        } & HistoryEntryProps
    ) {
        return (
            <LinkedAccountBankTransferEntry
                {...props}
                title={replacementTitle}
            />
        );
    };

export const createUserLinkedAccountB2WTransferEntry =
    userLinkedAccountBankTransferEntryFactory("B2W");
export const createUserLinkedAccountW2BTransferEntry =
    userLinkedAccountBankTransferEntryFactory("W2B");
