/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ResetPinMutationVariables = {
    userId: string;
    pin: string;
};
export type ResetPinMutationResponse = {
    readonly resetPin: {
        readonly hadPinBefore: boolean;
    } | null;
};
export type ResetPinMutation = {
    readonly response: ResetPinMutationResponse;
    readonly variables: ResetPinMutationVariables;
};



/*
mutation ResetPinMutation(
  $userId: ID!
  $pin: String!
) {
  resetPin(userId: $userId, pin: $pin) {
    hadPinBefore
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pin",
        "variableName": "pin"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "ResetPin",
    "kind": "LinkedField",
    "name": "resetPin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hadPinBefore",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPinMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ResetPinMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5feadc720dee75f531836e10f314339f",
    "id": null,
    "metadata": {},
    "name": "ResetPinMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPinMutation(\n  $userId: ID!\n  $pin: String!\n) {\n  resetPin(userId: $userId, pin: $pin) {\n    hadPinBefore\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fc90bc1d44178c8ceff18d14bc99a066';
export default node;
