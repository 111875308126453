import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Checkbox, useCheckbox } from "../../components/Checkbox";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { DenseCheckbox } from "@/components/Checkbox";
import { CurrencyField, useCurrencyField } from "@/components/CurrencyField";

import {
    ScamReports,
    ScamReportsHistoryTable,
} from "@/pages/mobile.[mobile]/ScamReportsHistoryTable";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { M } from "@/utils/currency";
import { hasError } from "@/utils/formValidation";
import { isValidMobile, mobileValidationErrorMessage } from "@/utils/mobile";

import { ReportCustomerScamMutationResponse } from "./__generated__/ReportCustomerScamMutation.graphql";

const mutation = graphql`
    mutation ReportCustomerScamMutation(
        $mobile: String!
        $transactionId: ID!
        $amountStolen: Money!
        $customerSharedPinCode: Boolean!
        $customerSharedSmsCode: Boolean!
        $additionalInfo: String!
    ) {
        reportCustomerScam(
            mobile: $mobile
            transactionId: $transactionId
            amount: $amountStolen
            customerSharedPinCode: $customerSharedPinCode
            customerSharedSmsCode: $customerSharedSmsCode
            additionalInfo: $additionalInfo
        ) {
            result
        }
    }
`;

type ReportCustomerScamInputParams = {
    mobile: string; // Profile? we need to allow non-User numbers; do we need non-Wallet numbers?
    transactionId?: string;
    amountStolen?: M;
    additionalInfo?: string;
    scamReports?: ScamReports; // don't include if you want to hide the report table
    hideAdditionalInfoFields: boolean;
};

const _ReportCustomerScamMutator = (
    props: ReportCustomerScamInputParams & RelayModalProps
) => {
    const {
        onHide,
        mobile,
        transactionId,
        amountStolen,
        additionalInfo,
        scamReports,
        hideAdditionalInfoFields,
    } = props;

    const { t } = useTranslation();

    const onMutationSuccess = useCallback(
        (response: ReportCustomerScamMutationResponse) => {
            return response.reportCustomerScam?.result || "";
        },
        []
    );

    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.ReportCustomerScam,
            data: { mobile, transactionId, amountStolen },
        },
        ...props,
    });

    const mobileField = useTextField();
    const transactionIdField = useTextField();
    const amountField = useCurrencyField();
    const customerSharedSmsCodeCheckbox = useCheckbox();
    const customerSharedPinCodeCheckbox = useCheckbox();
    const additionalInfoField = useTextField();

    const handleSubmit = useCallback(() => {
        mutator.submit(mutation, {
            mobile: mobileField.value,
            transactionId: transactionIdField.value.trim(),
            amountStolen: amountField.value?.serialize(),
            customerSharedSmsCode: customerSharedSmsCodeCheckbox.checked,
            customerSharedPinCode: customerSharedPinCodeCheckbox.checked,
            additionalInfo: additionalInfoField.value,
        });
    }, [
        mutator,
        mobileField.value,
        transactionIdField.value,
        amountField.value,
        customerSharedSmsCodeCheckbox.checked,
        customerSharedPinCodeCheckbox.checked,
        additionalInfoField.value,
    ]);

    const transactionIdValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!transactionIdField.value) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-transaction-id-both"),
            });
        }
        return validations;
    }, [t, transactionIdField.value]);

    const amountValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!amountField.value || !amountField.value?.isPositive()) {
            validations.push({
                type: "error",
                display: "always",
                message: t("form-validation--missing-stolen-amount"),
            });
        }
        return validations;
    }, [t, amountField.value]);

    const mobileValidations = useMemo((): Validation[] => {
        const validations: Validation[] = [];
        if (!isValidMobile(mobileField.value)) {
            validations.push(mobileValidationErrorMessage as Validation);
        }

        return validations;
    }, [mobileField.value]);
    const isValid = useMemo(() => {
        return !hasError([
            mobileValidations,
            amountValidations,
            transactionIdValidations,
        ]);
    }, [mobileValidations, amountValidations, transactionIdValidations]);

    const historyTable =
        scamReports == undefined ? (
            <></>
        ) : (
            <>
                <p>{t("report-customer-scam-history--title")}</p>
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                {t("report-customer-scam-history--reported-on")}
                            </th>
                            <th>{t("report-customer-scam-history--status")}</th>
                            <th>
                                {t(
                                    "report-customer-scam-history--transaction-id"
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ScamReportsHistoryTable tickets={scamReports} />
                    </tbody>
                </table>
            </>
        );

    return (
        <MutatorModal {...mutator} title={t("report-customer-scam--title")}>
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-report")}
                onSubmit={handleSubmit}
                onDone={onHide}
            >
                <TextField
                    {...mobileField}
                    label={t("label-customer-mobile")}
                    name="mobile"
                    validations={mobileValidations}
                    defaultValue={mobile}
                    autoFocus
                />

                <TextField
                    {...transactionIdField}
                    label={t("label-transaction-id")}
                    name="transactionId"
                    validations={transactionIdValidations}
                    defaultValue={transactionId}
                />

                <CurrencyField
                    {...amountField}
                    label={t("label-amount-stolen")}
                    name="amountStolen"
                    validations={amountValidations}
                    defaultValue={amountStolen}
                    currency={
                        amountStolen?.currency ||
                        M.deduceCurrencyFromMobile(mobile)
                    }
                    autoFocus
                />
                <span hidden={hideAdditionalInfoFields}>
                    <DenseCheckbox>
                        <Checkbox
                            {...customerSharedPinCodeCheckbox}
                            label={t("report-customer-scam--shared-pin-code")}
                            name="customerSharedPinCode"
                            validations={[]}
                        />
                    </DenseCheckbox>

                    <DenseCheckbox>
                        <Checkbox
                            {...customerSharedSmsCodeCheckbox}
                            label={t("report-customer-scam--shared-sms-code")}
                            name="customerSharedSmsCode"
                            validations={[]}
                        />
                    </DenseCheckbox>

                    <TextField
                        {...additionalInfoField}
                        label={t("label-additional-info")}
                        name="additionalInfo"
                        validations={[]}
                        defaultValue={additionalInfo}
                        as="textarea"
                        autoFocus
                    />
                </span>
            </Form>
            {historyTable}
        </MutatorModal>
    );
};
export const ReportCustomerScamMutator = createFragmentContainer(
    _ReportCustomerScamMutator,
    {}
);
