/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AgentTransaction_agentUser = {
    readonly user: {
        readonly id: string;
    };
    readonly agent: {
        readonly id: string;
        readonly name: string;
        readonly floatWallet: {
            readonly balance: string;
        };
    };
    readonly " $refType": "AgentTransaction_agentUser";
};
export type AgentTransaction_agentUser$data = AgentTransaction_agentUser;
export type AgentTransaction_agentUser$key = {
    readonly " $data"?: AgentTransaction_agentUser$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AgentTransaction_agentUser">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgentTransaction_agentUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Agent",
      "kind": "LinkedField",
      "name": "agent",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Wallet",
          "kind": "LinkedField",
          "name": "floatWallet",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "balance",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AgentUser",
  "abstractKey": null
};
})();
(node as any).hash = '5ad7dfad631a73c70ccb09eed5169a7e';
export default node;
