import { createFragmentContainer, graphql } from "react-relay";

import ReversalDisputeEntry from "@/pages/mobile.[mobile].personal/ReversalDisputeEntry";

import PayoutTransferEntry from "../mobile.[mobile].merchant/PayoutTransferEntry";
import PurchaseEntry from "../mobile.[mobile].merchant/PurchaseEntry";
import { delegateHistoryEntryComponent } from "../mobile.[mobile]/HistoryEntry";
import AdjustmentEntry from "./AdjustmentEntry";
import BillPaymentEntry from "./BillPaymentEntry";
import FailedMerchantTransferEntry from "./FailedMerchantTransferEntry";
import LoginLogoutEvent from "./LoginLogoutEvent";
import PaymentCardTransferEntry from "./PaymentCardTransferEntry";
import RemittanceTransferReceivedEntry from "./RemittanceTransferReceivedEntry";
import RemittanceTransferReversalEntry from "./RemittanceTransferReversalEntry";
import TransferReceivedEntry from "./TransferReceivedEntry";
import TransferReceivedReversalEntry from "./TransferReceivedReversalEntry";
import TransferSentEntry from "./TransferSentEntry";
import TransferSentReversalEntry from "./TransferSentReversalEntry";
import UserAtxEntry from "./UserAtxEntry";
import UserLinkedAccountTransferB2WEntry from "./UserLinkedAccountTransferB2WEntry";
import UserLinkedAccountTransferW2BEntry from "./UserLinkedAccountTransferW2BEntry";

type PersonalHistoryEntryProps = {
    reversedTransfers: {
        [key: string]: Date;
    };
    activeDeviceBlocks: Array<{ deviceId: string; blockReason: string }>;
};

const TX_TYPE_RENDERERS: {
    [key: string]: any & PersonalHistoryEntryProps;
} = {
    AdjustmentEntry,
    AgentTransactionEntry: UserAtxEntry,
    BillPaymentEntry,
    UserLinkedAccountTransferB2WEntry,
    UserLinkedAccountTransferW2BEntry,
    TransferSentEntry,
    TransferReceivedEntry,
    PaymentCardTransferEntry,
    PayoutTransferEntry,
    PurchaseEntry,
    TransferSentReversalEntry,
    TransferReceivedReversalEntry,
    RemittanceTransferReceivedEntry,
    RemittanceTransferReversalEntry,
    LoginLogoutEvent,
    FailedMerchantTransferEntry,
    ReversalDisputeEntry,
};

function _PersonalHistoryEntry(
    props: {
        entry: { [key: string]: any };
        hideButtons?: boolean;
    } & PersonalHistoryEntryProps
) {
    return delegateHistoryEntryComponent(
        props.entry.__typename,
        TX_TYPE_RENDERERS,
        props
    );
}
export default createFragmentContainer(_PersonalHistoryEntry, {
    entry: graphql`
        fragment PersonalHistoryEntry_entry on SupportHistoryEntry {
            __typename
            ...HistoryEntry_entry
            ...UserAtxEntry_tx
            ...BillPaymentEntry_tx
            ...UserLinkedAccountTransferB2WEntry_tx
            ...UserLinkedAccountTransferW2BEntry_tx
            ...PaymentCardTransferEntry_tx
            ...TransferReceivedEntry_tx
            ...TransferSentEntry_tx
            ...TransferSentReversalEntry_tx
            ...TransferReceivedReversalEntry_tx
            ...PayoutTransferEntry_tx
            ...PurchaseEntry_tx
            ...RemittanceTransferReceivedEntry_tx
            ...RemittanceTransferReversalEntry_tx
            ...AdjustmentEntry_tx
            ...LoginLogoutEvent_tx
            ...FailedMerchantTransferEntry_tx
            ...ReversalDisputeEntry_tx
        }
    `,
});
