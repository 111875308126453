import { useContext } from "react";

import { PermissionsContext } from "@/context/Permissions";

export function usePermissions(...permission: string[]): boolean {
    const permissions = useContext(PermissionsContext);
    for (const n of permission) {
        if (!permissions.includes(n)) {
            return false;
        }
    }
    return true;
}
