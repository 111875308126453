import { useTranslation } from "react-i18next";

import { ActionButton } from "../../components/ActionButton";

import { EscalateToMerchantSupportMutator } from "@/pages/mobile.[mobile].merchant/EscalateToMerchantSupport";
import { MerchantSupportEscalations } from "@/pages/mobile.[mobile].merchant/MerchantSupportEscalationsHistoryTable";

import { PersonalTabHeader_wallet } from "./__generated__/PersonalTabHeader_wallet.graphql";

export const EscalateToMerchantSupportAction = (props: {
    mobile: string;
    user: Exclude<PersonalTabHeader_wallet["user"], null>;
    merchantSupportEscalations?: MerchantSupportEscalations;
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="button"
            name={t("escalate-merchant-support--action")}
            requiredPermissions={["supportapp_escalate_to_merchant_support"]}
        >
            <EscalateToMerchantSupportMutator
                mobile={props.mobile}
                user={props.user}
                merchantSupportEscalations={props.merchantSupportEscalations}
            />
        </ActionButton>
    );
};
