import {
    faIdCard,
    faMobile,
    faQrcode,
    faSackDollar,
    faSimCard,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { ActionButton, VOIPVerificationLevel } from "@/components/ActionButton";

import { usePermissions } from "@/hooks/usePermissions";

import { UnblockFromAgentDepositsMutator } from "@/pages/mobile.[mobile].personal/UnblockFromAgentDeposits";
import { UnblockFromUsingMultipleDevicesMutator } from "@/pages/mobile.[mobile].personal/UnblockFromUsingMultipleDevices";
import { UnblockFromUsingQrCardsMutator } from "@/pages/mobile.[mobile].personal/UnblockFromUsingQrCards";
import { UnblockUserFromDormancyMutator } from "@/pages/mobile.[mobile].personal/UnblockUserFromDormancy";
import { BlockUnblockDeviceMutator } from "@/pages/mobile.[mobile]/BlockUnblockDevice";
import { BlockUnblockUserMutator } from "@/pages/mobile.[mobile]/BlockUnblockUser";
import { RequestToBlockUnblockUserMutator } from "@/pages/mobile.[mobile]/RequestToBlockUnblockUser";
import { RequestToModifyBlockMutator } from "@/pages/mobile.[mobile]/RequestToModifyBlock";
import { RequestToUnblockFromAgentDepositsMutator } from "@/pages/mobile.[mobile]/RequestToUnblockFromAgentDeposits";
import { RequestToUnblockFromUsingMultipleDevicesMutator } from "@/pages/mobile.[mobile]/RequestToUnblockFromUsingMultipleDevices";
import { UpdateBlockMutator } from "@/pages/mobile.[mobile]/UpdateBlock";

import { UnregisteredMobileBlocks_blockData } from "./__generated__/UnregisteredMobileBlocks_blockData.graphql";
import { UpdateBlock_activeBlock$key } from "./__generated__/UpdateBlock_activeBlock.graphql";
import { WalletUserBlocks_blockData } from "./__generated__/WalletUserBlocks_blockData.graphql";

import { BlockBanner } from "./BlockBanner";

const EditBlockReasonActionButton = ({
    block,
    variant = "outline-light",
}: {
    block: UpdateBlock_activeBlock$key;
    variant?: "outline-light" | "outline-dark";
}) => {
    const { t } = useTranslation();

    return (
        <ActionButton
            as="button"
            name={t("wallet-user-block--action-edit-reason")}
            requiredPermissions={["supportapp_update_block"]}
            variant={variant}
        >
            <UpdateBlockMutator activeBlock={block} />
        </ActionButton>
    );
};

const MobileBlockBanner = (props: {
    blockedWallet: WalletUserBlocks_blockData;
    byLegalEntity: boolean;
}) => {
    const { blockedWallet, byLegalEntity } = props;

    const { t } = useTranslation();

    const block = byLegalEntity
        ? blockedWallet.activeLegalEntityBlock
        : blockedWallet.activeMobileBlock;

    const pendingUnblockRequestTickets = (
        block?.requestToModifyBlockTickets || []
    ).filter((t) => t?.status == "OPEN");

    const actions = [];

    if (usePermissions("supportapp_request_to_unblock_wallet")) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    pendingUnblockRequestTickets.length > 0
                        ? t("wallet-user-block--action-unblock-requested")
                        : t("wallet-user-block--action-unblock-request")
                }
                title={
                    pendingUnblockRequestTickets.length > 0
                        ? `${t(
                              "wallet-user-block--action-unblock-requested"
                          )} ${pendingUnblockRequestTickets[0]?.whenOpened}`
                        : ""
                }
                disabled={pendingUnblockRequestTickets.length > 0}
                requiredPermissions={["supportapp_request_to_unblock_wallet"]}
                variant={"outline-light"}
            >
                <RequestToBlockUnblockUserMutator
                    unregisteredMobile={null}
                    wallet={blockedWallet}
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_unblock_user")) {
        actions.push(
            <ActionButton
                as="button"
                name={t("action-unblock")}
                requiredPermissions={["supportapp_unblock_user"]}
                variant={"outline-light"}
                VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
            >
                <BlockUnblockUserMutator
                    unregisteredMobile={null}
                    wallet={blockedWallet}
                    unblock={true}
                    byLegalEntity={byLegalEntity}
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_update_block") && block) {
        actions.push(<EditBlockReasonActionButton block={block} />);
    }

    const hasOpenModifyRequests =
        (block?.requestToModifyBlockTickets || []).filter(
            (r) => r?.status == "OPEN"
        ).length > 0;
    if (
        usePermissions("supportapp_request_to_modify_block") &&
        block &&
        !!block?.whenExpires
    ) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    hasOpenModifyRequests
                        ? t("wallet-user-block--action-modify-submitted")
                        : t("wallet-user-block--action-modify")
                }
                requiredPermissions={["supportapp_request_to_modify_block"]}
                variant={"outline-light"}
                disabled={hasOpenModifyRequests}
            >
                <RequestToModifyBlockMutator
                    block={block}
                    requestToUnblock={false} // request to make permanent, not to unblock
                    mobile={blockedWallet.mobile}
                    title={t("wallet-user-block--action-make-permanent--title")}
                    body={
                        <>
                            {t(
                                "wallet-user-block--action-make-permanent--description",
                                {
                                    subject: byLegalEntity
                                        ? t("legal-entity")
                                        : t("mobile-number"),
                                }
                            )}
                        </>
                    }
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_block_user") && !!block?.whenExpires) {
        actions.push(
            <ActionButton
                as="button"
                name={t("wallet-user-block--action-make-permanent")}
                requiredPermissions={["supportapp_block_user"]}
                variant={"outline-light"}
            >
                <BlockUnblockUserMutator
                    unregisteredMobile={null}
                    wallet={blockedWallet}
                    unblock={false}
                    byLegalEntity={byLegalEntity}
                />
            </ActionButton>
        );
    }

    // If it is a temporary block, add a button (or buttons) to make it permanent.
    const temporary = block?.whenExpires;
    const title = t("wallet-user-block--block-notice--title", {
        status: temporary
            ? t("wallet-user-block--temporary")
            : t("wallet-user-block--complete"),
        type: byLegalEntity ? t("legal-entity") : t("mobile-number"),
    });
    const icon = byLegalEntity ? faIdCard : faSimCard;
    return (
        <BlockBanner
            title={title}
            blockType={"full"}
            icon={<FontAwesomeIcon icon={icon} />}
            block={block}
            actions={actions}
        />
    );
};

const MultipleDevicesBlockBanner = (props: {
    blockedWallet: walletBlockDataWithUser;
    forAgent: boolean;
}) => {
    const { forAgent, blockedWallet } = props;

    const { t } = useTranslation();

    const block = forAgent
        ? blockedWallet.user.agentMultipleDevicesBlock
        : blockedWallet.user.personalMultipleDevicesBlock;

    const pendingUnblockRequestTickets = (
        block?.requestToModifyBlockTickets || []
    ).filter((t) => t?.status == "OPEN");

    const actions = [];

    if (
        usePermissions(
            "supportapp_request_to_unblock_from_using_multiple_devices"
        )
    ) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    pendingUnblockRequestTickets.length > 0
                        ? t("wallet-user-block--action-unblock-requested")
                        : t("wallet-user-block--action-unblock-request")
                }
                title={
                    pendingUnblockRequestTickets.length > 0
                        ? `${t(
                              "wallet-user-block--action-unblock-requested"
                          )} ${pendingUnblockRequestTickets[0]?.whenOpened}`
                        : ""
                }
                disabled={pendingUnblockRequestTickets.length > 0}
                requiredPermissions={[
                    "supportapp_request_to_unblock_from_using_multiple_devices",
                ]}
                variant={"outline-dark"}
            >
                <RequestToUnblockFromUsingMultipleDevicesMutator
                    wallet={blockedWallet}
                    forAgent={forAgent}
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_unblock_from_using_multiple_devices")) {
        actions.push(
            <ActionButton
                as="button"
                name={t("action-unblock")}
                requiredPermissions={[
                    "supportapp_unblock_from_using_multiple_devices",
                ]}
                variant={"outline-dark"}
                VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
            >
                <UnblockFromUsingMultipleDevicesMutator
                    user={blockedWallet.user}
                    forAgent={forAgent}
                />
            </ActionButton>
        );
    }

    // If it is a temporary block, add a button (or buttons) to make it permanent.
    return (
        <BlockBanner
            title={
                (block?.whenExpires
                    ? t("wallet-user-block--device-restriction-temporary")
                    : t("wallet-user-block--device-restriction")) +
                (forAgent
                    ? t("wallet-user-block--device-restriction-agent")
                    : "")
            }
            blockType={"partial"}
            icon={<FontAwesomeIcon icon={faMobile} />}
            block={block}
            details={
                block
                    ? {
                          "Restricted to": block.lockedDevice ? (
                              <span>
                                  {block.lockedDevice.deviceName} (
                                  {block.lockedDevice.deviceModel}{" "}
                                  <span className="font-mono">
                                      {block.lockedDevice.deviceId}
                                  </span>
                                  )
                              </span>
                          ) : (
                              <span>
                                  {t(
                                      "wallet-user-block--device-restriction-next-login"
                                  )}
                              </span>
                          ),
                      }
                    : {}
            }
            actions={actions}
        />
    );
};

const AgentDepositsBlockBanner = (props: {
    blockedWallet: walletBlockDataWithUser;
}) => {
    const { blockedWallet } = props;
    const block = blockedWallet.user.agentDepositsBlock;
    const pendingUnblockRequestTickets = (
        block?.requestToModifyBlockTickets || []
    ).filter((ticket) => ticket.status === "OPEN");

    const { t } = useTranslation();

    const actions = [];

    if (usePermissions("supportapp_request_to_unblock_from_agent_deposits")) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    pendingUnblockRequestTickets.length > 0
                        ? t("wallet-user-block--action-unblock-requested")
                        : t("wallet-user-block--action-unblock-request")
                }
                title={
                    pendingUnblockRequestTickets.length > 0
                        ? `${t(
                              "wallet-user-block--action-unblock-requested"
                          )} ${pendingUnblockRequestTickets[0]?.whenOpened}`
                        : ""
                }
                disabled={pendingUnblockRequestTickets.length > 0}
                requiredPermissions={[
                    "supportapp_request_to_unblock_from_agent_deposits",
                ]}
                variant={"outline-dark"}
            >
                <RequestToUnblockFromAgentDepositsMutator
                    wallet={blockedWallet}
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_unblock_from_agent_deposits")) {
        actions.push(
            <ActionButton
                as="button"
                name={t("action-unblock")}
                requiredPermissions={["supportapp_unblock_from_agent_deposits"]}
                variant={"outline-dark"}
                VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
            >
                <UnblockFromAgentDepositsMutator user={blockedWallet.user} />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_update_block") && block) {
        actions.push(
            <EditBlockReasonActionButton block={block} variant="outline-dark" />
        );
    }

    // If it is a temporary block, add a button (or buttons) to make it permanent.
    const title = t("wallet-user-block--block-deposits--title", {
        status: block?.whenExpires
            ? t("wallet-user-block--temporary")
            : t("wallet-user-block--complete"),
        type:
            block?.blockSubject == "LEGAL_ENTITY"
                ? t("legal-entity")
                : t("mobile-number"),
    });
    return (
        <BlockBanner
            title={title}
            blockType={"partial"}
            icon={<FontAwesomeIcon icon={faSackDollar} />}
            block={block}
            actions={actions}
        />
    );
};

const QrCardBlockBanner = (props: {
    blockedWallet: walletBlockDataWithUser;
}) => {
    const { blockedWallet } = props;
    const block = blockedWallet.user.qrCardBlock;

    const { t } = useTranslation();

    const actions = [];

    if (usePermissions("supportapp_unblock_from_using_qr_cards")) {
        actions.push(
            <ActionButton
                as="button"
                name={t("action-unblock")}
                requiredPermissions={["supportapp_unblock_from_using_qr_cards"]}
                variant={"outline-dark"}
                VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
            >
                <UnblockFromUsingQrCardsMutator user={blockedWallet.user} />
            </ActionButton>
        );
    }

    // If it is a temporary block, add a button (or buttons) to make it permanent.
    return (
        <BlockBanner
            title={
                block?.whenExpires
                    ? t("wallet-user-block--qr-card-temporary")
                    : t("wallet-user-block--qr-card")
            }
            blockType={"partial"}
            icon={<FontAwesomeIcon icon={faQrcode} />}
            block={block}
            actions={actions}
        />
    );
};

const DormancyBlockBanner = (props: {
    blockedWallet: walletBlockDataWithUser;
}) => {
    const { blockedWallet } = props;
    const block = blockedWallet.user.dormancyBlock;

    const { t } = useTranslation();

    const actions = [];

    if (usePermissions("supportapp_unblock_dormant_user")) {
        actions.push(
            <ActionButton
                as="button"
                name={t("action-unblock")}
                requiredPermissions={["supportapp_unblock_dormant_user"]}
                variant={"outline-dark"}
                VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
            >
                <UnblockUserFromDormancyMutator user={blockedWallet.user} />
            </ActionButton>
        );
    }

    return (
        <BlockBanner
            title={t("wallet-user-block--dormancy--title")}
            blockType={"partial"}
            icon={<FontAwesomeIcon icon={faMobile} />}
            block={block ?? null}
            actions={actions}
        />
    );
};

export const DeviceBlockBanner = (props: {
    blockedWallet?: walletBlockDataWithUser;
    unregisteredMobile?: UnregisteredMobileBlocks_blockData;
    deviceId: string;
}) => {
    const { blockedWallet, deviceId, unregisteredMobile } = props;

    const { t } = useTranslation();

    const userDevice = (blockedWallet?.user.devices || []).find(
        (device) => device.deviceId == deviceId
    );
    const unregisteredMobileDevice = (unregisteredMobile?.devices || []).find(
        (device) => device.deviceId == deviceId
    );
    const device = userDevice || unregisteredMobileDevice;

    const block = device?.activeBlock;

    const deviceDescription = device && (
        <span>
            {device.deviceName} ({device.deviceModel}{" "}
            <span className="font-mono">{device.deviceId}</span>)
        </span>
    );

    const temporary = block?.whenExpires;
    const title = temporary
        ? t("wallet-user-block--device-block-temporary")
        : t("wallet-user-block--device-block");

    const actions = [];

    // TODO (sebastian) -- implement making device blocks permanent
    /*if (usePermissions("supportapp_block_device") && device) {
        actions.push(
            <ActionButton
                as="button"
                name={"Make permanent"}
                requiredPermissions={["supportapp_block_device"]}
                variant={"outline-dark"}
            >
                <BlockUnblockDeviceMutator
                    deviceId={device.deviceId}
                    user={blockedWallet.user}
                    unblock={false}
                />
            </ActionButton>
        );
    }*/

    if (usePermissions("supportapp_unblock_device") && device) {
        actions.push(
            <ActionButton
                as="button"
                name={t("action-unblock")}
                requiredPermissions={["supportapp_unblock_device"]}
                variant={"outline-dark"}
                VOIPVerification={VOIPVerificationLevel.REQUIRES_CALLBACK}
            >
                <BlockUnblockDeviceMutator
                    user={blockedWallet?.user}
                    unregisteredMobile={unregisteredMobile as any}
                    deviceId={device.deviceId}
                    mobile={
                        blockedWallet?.user.mobile ||
                        unregisteredMobile?.mobile ||
                        ""
                    }
                    unblock={true}
                />
            </ActionButton>
        );
    }

    const hasOpenModifyRequests =
        (block?.requestToModifyBlockTickets || []).filter(
            (r) => r?.status == "OPEN"
        ).length > 0;
    if (
        usePermissions("supportapp_request_to_modify_block") &&
        block &&
        temporary
    ) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    hasOpenModifyRequests
                        ? t("wallet-user-block--action-modify-submitted")
                        : t("wallet-user-block--action-modify")
                }
                requiredPermissions={["supportapp_request_to_modify_block"]}
                variant={"outline-dark"}
                disabled={hasOpenModifyRequests}
            >
                <RequestToModifyBlockMutator
                    block={block}
                    requestToUnblock={false} // request to make permanent, not to unblock
                    mobile={
                        blockedWallet?.user.mobile ||
                        unregisteredMobile?.mobile ||
                        ""
                    }
                    title={t(
                        "wallet-user-block--action-make-permanent-device--title"
                    )}
                    body={
                        <>
                            {t(
                                "wallet-user-block--action-make-permanent-device--description",
                                { device: deviceDescription }
                            )}
                        </>
                    }
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_request_to_modify_block") && block) {
        actions.push(
            <ActionButton
                as="button"
                name={
                    hasOpenModifyRequests
                        ? t("wallet-user-block--request-submitted")
                        : t("wallet-user-block--request-unblock")
                }
                requiredPermissions={["supportapp_request_to_modify_block"]}
                variant={"outline-dark"}
                disabled={hasOpenModifyRequests}
            >
                <RequestToModifyBlockMutator
                    block={block}
                    requestToUnblock={true}
                    mobile={
                        blockedWallet?.user.mobile ||
                        unregisteredMobile?.mobile ||
                        ""
                    }
                    title={t("wallet-user-block--request-unblock--title")}
                    body={
                        <>
                            {
                                (t(
                                    "wallet-user-block--request-unblock--description"
                                ),
                                { device: deviceDescription })
                            }
                        </>
                    }
                />
            </ActionButton>
        );
    }

    if (usePermissions("supportapp_update_block") && block) {
        actions.push(
            <EditBlockReasonActionButton block={block} variant="outline-dark" />
        );
    }

    return (
        <BlockBanner
            title={title}
            blockType={"device"}
            icon={<FontAwesomeIcon icon={faMobile} />}
            block={block ?? null} // silly, but TS thinks block could be undefined
            details={{ Device: deviceDescription }}
            actions={actions}
        />
    );
};

type walletBlockDataWithUser = WalletUserBlocks_blockData & {
    user: NonNullable<WalletUserBlocks_blockData["user"]>;
};
interface WalletUserBlocksProps {
    blockData: WalletUserBlocks_blockData;
}

const _WalletUserBlocks = (props: WalletUserBlocksProps) => (
    <div className="w-2/5 m-auto">
        <MobileBlockBanner
            blockedWallet={props.blockData}
            byLegalEntity={true}
        />
        <MobileBlockBanner
            blockedWallet={props.blockData}
            byLegalEntity={false}
        />
        {props.blockData.user ? (
            <>
                <MultipleDevicesBlockBanner
                    blockedWallet={props.blockData as walletBlockDataWithUser}
                    forAgent={false}
                />
                <MultipleDevicesBlockBanner
                    blockedWallet={props.blockData as walletBlockDataWithUser}
                    forAgent={true}
                />
                <AgentDepositsBlockBanner
                    blockedWallet={props.blockData as walletBlockDataWithUser}
                />
                <QrCardBlockBanner
                    blockedWallet={props.blockData as walletBlockDataWithUser}
                />
                <DormancyBlockBanner
                    blockedWallet={props.blockData as walletBlockDataWithUser}
                />
                {(props.blockData.user.devices || []).map(
                    (device, deviceIndex) => (
                        <DeviceBlockBanner
                            key={deviceIndex}
                            deviceId={device.deviceId}
                            blockedWallet={
                                props.blockData as walletBlockDataWithUser
                            }
                        />
                    )
                )}
            </>
        ) : null}
    </div>
);

export const WalletUserBlocks = createFragmentContainer(_WalletUserBlocks, {
    blockData: graphql`
        fragment WalletUserBlocks_blockData on Wallet {
            ...BlockUnblockUser_wallet
            ...RequestToBlockUnblockUser_wallet
            ...RequestToUnblockFromUsingMultipleDevices_wallet
            ...RequestToUnblockFromAgentDeposits_wallet
            id
            mobile
            activeMobileBlock {
                id
                blockReason
                blockedBy
                whenBlocked
                blockSubject
                whenExpires
                requestToModifyBlockTickets {
                    status
                    whenOpened
                }
                ...RequestToModifyBlock_activeBlock
                ...UpdateBlock_activeBlock
            }
            activeLegalEntityBlock {
                id
                blockReason
                blockedBy
                whenBlocked
                blockSubject
                whenExpires
                requestToModifyBlockTickets {
                    status
                    whenOpened
                }
                ...RequestToModifyBlock_activeBlock
                ...UpdateBlock_activeBlock
            }
            tickets {
                whenOpened
                whenResolved
                status
                slackMessageUrl
                ticketType
                reference
            }
            blockHistory {
                whenBlocked
                blockedBy
                blockReason
                whenUnblocked
                unblockedBy
                unblockReason
                blockSubject
                whenExpires
            }
            user {
                ...UnblockFromUsingMultipleDevices_user
                ...UnblockFromUsingQrCards_user
                ...UnblockFromAgentDeposits_user
                ...UnblockUserFromDormancy_user
                ...BlockUnblockDevice_user
                id
                name
                mobile
                qrCardBlock {
                    id
                    blockReason
                    blockedBy
                    whenBlocked
                    whenExpires
                }
                dormancyBlock {
                    id
                    blockReason
                    blockedBy
                    whenBlocked
                    whenExpires
                }
                personalMultipleDevicesBlock {
                    id
                    blockReason
                    blockedBy
                    whenBlocked
                    whenExpires
                    lockedDevice {
                        deviceId
                        deviceName
                        deviceModel
                    }
                    requestToModifyBlockTickets {
                        status
                        whenOpened
                    }
                }
                agentMultipleDevicesBlock {
                    id
                    blockReason
                    blockedBy
                    whenBlocked
                    whenExpires
                    lockedDevice {
                        deviceId
                        deviceName
                        deviceModel
                    }
                    requestToModifyBlockTickets {
                        status
                        whenOpened
                    }
                }
                agentDepositsBlock {
                    id
                    blockReason
                    blockedBy
                    blockSubject
                    whenBlocked
                    whenExpires
                    requestToModifyBlockTickets {
                        status
                        whenOpened
                    }
                    ...UpdateBlock_activeBlock
                }
                devices {
                    deviceId
                    deviceName
                    deviceModel
                    activeBlock {
                        id
                        blockReason
                        blockedBy
                        whenBlocked
                        whenExpires
                        requestToModifyBlockTickets {
                            status
                            whenOpened
                        }
                        ...RequestToModifyBlock_activeBlock
                        ...UpdateBlock_activeBlock
                    }
                }
            }
            legalEntity {
                wallets(walletType: PERSONAL) {
                    mobile
                    user {
                        agentUser {
                            isActive
                        }
                        merchantUser {
                            isActive
                        }
                        promoter {
                            canKioskSignup
                        }
                    }
                }
            }
        }
    `,
});
