/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MerchantUsersList_merchant = {
    readonly principal: {
        readonly id: string;
        readonly name: string;
        readonly contactMobile: string;
    };
    readonly assistants: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly mobile: string;
    }>;
    readonly " $refType": "MerchantUsersList_merchant";
};
export type MerchantUsersList_merchant$data = MerchantUsersList_merchant;
export type MerchantUsersList_merchant$key = {
    readonly " $data"?: MerchantUsersList_merchant$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MerchantUsersList_merchant">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MerchantUsersList_merchant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "principal",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contactMobile",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MerchantAssistant",
      "kind": "LinkedField",
      "name": "assistants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mobile",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Merchant",
  "abstractKey": null
};
})();
(node as any).hash = 'fd503b81c62ebddc98b9b66e2fc5323b';
export default node;
