import { useTranslation } from "react-i18next";

import { TxTitle } from "@/components/TxTitle";

import { M } from "@/utils/currency";

import { AgentHistoryAtxEntry_tx } from "./__generated__/AgentHistoryAtxEntry_tx.graphql";

interface Props {
    tx: AgentHistoryAtxEntry_tx;
    hideId?: boolean;
}
export function AgentAtxEntryTitle({ tx }: Props) {
    const { t } = useTranslation();
    let title = tx.isDeposit
        ? t("agent-atx-entry--deposit")
        : t("agent-atx-entry--withdraw");

    if (tx.type === "REBALANCE") {
        const direction = M.fromSerialized(tx.amount).isPositive()
            ? t("agent-atx-entry--deposit")
            : t("agent-atx-entry--withdraw");
        title = `${t("agent-atx-entry--rebalance")} ${direction}`;
    } else if (tx.type == "BUSINESS") {
        title += " by wire transfer";
    } else if (tx.isBusinessWalletAtx) {
        title += " for merchant";
    }

    if (tx.agentUserName) {
        title += ` (${t("agent-atx-entry--by")} ${tx.agentUserName})`;
    }

    return <TxTitle>{title}</TxTitle>;
}
