/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ReportPaymentCardProblemMutationVariables = {
    mobile: string;
    comment: string;
};
export type ReportPaymentCardProblemMutationResponse = {
    readonly reportPaymentCardProblem: {
        readonly result: string | null;
    } | null;
};
export type ReportPaymentCardProblemMutation = {
    readonly response: ReportPaymentCardProblemMutationResponse;
    readonly variables: ReportPaymentCardProblemMutationVariables;
};



/*
mutation ReportPaymentCardProblemMutation(
  $mobile: String!
  $comment: String!
) {
  reportPaymentCardProblem(mobile: $mobile, comment: $comment) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mobile"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "mobile",
        "variableName": "mobile"
      }
    ],
    "concreteType": "ReportPaymentCardProblem",
    "kind": "LinkedField",
    "name": "reportPaymentCardProblem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportPaymentCardProblemMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportPaymentCardProblemMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a87236bd238c83fa8cd0ab0c539ae214",
    "id": null,
    "metadata": {},
    "name": "ReportPaymentCardProblemMutation",
    "operationKind": "mutation",
    "text": "mutation ReportPaymentCardProblemMutation(\n  $mobile: String!\n  $comment: String!\n) {\n  reportPaymentCardProblem(mobile: $mobile, comment: $comment) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '40864eb6487f8304fc2aceff43525ba4';
export default node;
