import * as React from "react";
import { useLocation, useRouteMatch } from "react-router";

import {
    DEVICE_ID_FILTER_QUERY_PARAM_NAME,
    OPAQUE_ID_QUERY_PARAM_NAME,
    TabName,
    WHEN_QUERY_PARAM_NAME,
    getAddressForProfileById,
    getAddressForProfileByMobile,
    getTabFromWindowLocation,
} from "@/utils/navigation";

import { useNavigation } from "./useNavigation";

const getDate = (params: URLSearchParams): Date | undefined => {
    const when = Number(params.get(WHEN_QUERY_PARAM_NAME));

    return !Number.isNaN(when) && when !== 0 ? new Date(when) : undefined;
};

export function useJumpToDate() {
    const { goToPage } = useNavigation();
    const match = useRouteMatch<{
        id: string;
        mobile: string;
        tab?: TabName;
    }>();
    const { search } = useLocation();
    const params = React.useMemo(() => new URLSearchParams(search), [search]);

    const initialDate = getDate(params);

    const [date, setDate] = React.useState(initialDate);

    React.useEffect(() => {
        setDate(getDate(params));
    }, [params]);

    const setWhen = React.useCallback(
        (date: Date) => {
            if (date) {
                params.set(WHEN_QUERY_PARAM_NAME, String(date.getTime()));
                params.delete(OPAQUE_ID_QUERY_PARAM_NAME);
                params.delete(DEVICE_ID_FILTER_QUERY_PARAM_NAME);

                const query = params.toString();
                const { id, mobile } = match.params;

                const tab = getTabFromWindowLocation();

                const address = mobile
                    ? getAddressForProfileByMobile(mobile, query, tab)
                    : getAddressForProfileById(id, query, tab);

                goToPage(address);
            }

            setDate(date);
        },
        [match.params, goToPage, params]
    );

    return { date, setWhen };
}
