import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QueryRenderer, graphql, useRelayEnvironment } from "react-relay";

import { ActionButton } from "../../components/ActionButton";
import { LoadingIndicator } from "@/components/Loading";

import { WrongBackendError } from "../../external/relay";

import { CreateSupportNoteMutator } from "@/pages/mobile.[mobile].notes/CreateSupportNote";

import { NotesTabQuery } from "./__generated__/NotesTabQuery.graphql";

import { NotesList } from "./NotesList";

const notesTabQuery = graphql`
    query NotesTabQuery($mobile: String!) {
        wallet(mobile: $mobile) {
            mobile
        }
        supportNotes(mobile: $mobile) {
            ...NotesList_supportNotes
        }
    }
`;

const FailedToFetch = () => {
    const { t } = useTranslation();

    return (
        <div>
            {t("notes-tab--error-network")}{" "}
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://frontplugin.wave-internal.com/backend_proxy/"
                className="text-blue-600"
            >
                https://frontplugin.wave-internal.com/backend_proxy/
            </a>
        </div>
    );
};

type NotesTabProps = {
    mobile: string;
};

export const NotesTab = ({ mobile }: NotesTabProps) => {
    const environment = useRelayEnvironment();
    const { t } = useTranslation();

    return (
        <QueryRenderer<NotesTabQuery>
            environment={environment}
            render={({ error, props }) => {
                if (error) {
                    if (error instanceof WrongBackendError) throw error;
                    if (error.message === "Failed to fetch") {
                        return <FailedToFetch />;
                    }

                    return (
                        <div>
                            {t("notes-tab--error")} {error.message}
                        </div>
                    );
                }

                if (!props) return <LoadingIndicator />;

                const { supportNotes } = props;

                if (!supportNotes) {
                    return <div>{t("notes-tab--error")}</div>;
                }

                return (
                    <div className="notes-tab">
                        <div className="bg-gray-50 border-b border-gray-200 py-2 pr-12 pl-2">
                            <ButtonToolbar>
                                <ButtonGroup>
                                    <ActionButton
                                        name={t("notes-tab--action-add")}
                                    >
                                        <CreateSupportNoteMutator
                                            mobile={mobile}
                                        />
                                    </ActionButton>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </div>
                        <NotesList
                            mobile={mobile}
                            supportNotes={supportNotes}
                        />
                    </div>
                );
            }}
            fetchPolicy="store-and-network"
            query={notesTabQuery}
            variables={{ mobile }}
        />
    );
};
