import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { AgentProfileLink } from "@/components/AgentProfileLink";
import { HistoryActionButton } from "@/components/HistoryActionButton";
import { TicketLink } from "@/components/TicketLink";
import { TxInfo } from "@/components/TxInfo";
import { TxTitle } from "@/components/TxTitle";

import { usePermissions } from "@/hooks/usePermissions";

import { M } from "@/utils/currency";

import { UserAtxEntry_tx } from "./__generated__/UserAtxEntry_tx.graphql";

import { CorrectAtx } from "../mobile.[mobile].agent/CorrectAtx";
import HistoryEntry, {
    HistoryEntryProps,
} from "../mobile.[mobile]/HistoryEntry";
import { ReportCustomerScamMutator } from "../mobile.[mobile]/ReportCustomerScam";

function _UserAtxEntry(
    props: {
        tx: UserAtxEntry_tx;
    } & HistoryEntryProps
) {
    const { t } = useTranslation();
    const { tx } = props;

    const canReportScam = usePermissions("supportapp_report_customer_scam");
    const canReportScamWithDetails = usePermissions(
        "supportapp_report_customer_scam_with_details"
    );
    const canCorrectAtx = usePermissions("supportapp_correct_atx");

    const historyActionButton =
        tx.maybeTerminatedCustomerMobile && !tx.isDeposit ? (
            <HistoryActionButton
                name={t("report-customer-scam--action")}
                disabled={!canReportScam}
            >
                <ReportCustomerScamMutator
                    transactionId={tx.agentTransactionId}
                    mobile={tx.maybeTerminatedCustomerMobile}
                    amountStolen={M.fromSerialized(tx.amount).negate()}
                    scamReports={tx.scamReports}
                    hideAdditionalInfoFields={!canReportScamWithDetails}
                />
            </HistoryActionButton>
        ) : null;

    const correctAtxActionButton = canCorrectAtx ? (
        <HistoryActionButton key="correct-atx" name={t("correct-atx--action")}>
            <CorrectAtx
                transactionId={tx.agentTransactionId}
                agentId={tx.agentUfid}
                agentName={tx.agentName}
                customerMobile={tx.maybeTerminatedCustomerMobile}
                customerName={tx.customerName}
                isDeposit={tx.isDeposit}
                amount={M.fromSerialized(tx.amount).abs()}
            />
        </HistoryActionButton>
    ) : null;

    const buttons = [historyActionButton, correctAtxActionButton].filter(
        Boolean
    );

    return (
        <HistoryEntry
            {...props}
            entry={tx}
            title={
                <Fragment>
                    <TxTitle>
                        {tx.isDeposit
                            ? t("user-atx-entry--deposit")
                            : t("user-atx-entry--withdraw")}
                        {tx.relatedTicket && (
                            <TicketLink
                                status={tx.relatedTicket.status}
                                slackMessageUrl={
                                    tx.relatedTicket.slackMessageUrl
                                }
                            />
                        )}
                    </TxTitle>
                    <TxInfo>
                        {tx.agentTransactionId} at{" "}
                        <AgentProfileLink
                            agentId={tx.agentId}
                            agentUfid={tx.agentUfid}
                            name={tx.agentName}
                            opaqueId={tx.agentTransactionId}
                        />
                    </TxInfo>
                </Fragment>
            }
            buttons={buttons}
        />
    );
}
export default createFragmentContainer(_UserAtxEntry, {
    tx: graphql`
        fragment UserAtxEntry_tx on AgentTransactionEntry {
            agentTransactionId
            isDeposit
            agentName
            agentId
            agentUfid
            customerMobile
            customerName
            maybeTerminatedCustomerMobile
            amount
            relatedTicket {
                whenOpened
                whenResolved
                status
                slackMessageUrl
                ticketType
                reference
            }
            scamReports {
                whenOpened
                whenResolved
                status
                transactionIds
            }
            ...HistoryEntry_entry
        }
    `,
});
