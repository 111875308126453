import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createFragmentContainer, graphql } from "react-relay";

import { Checkbox, useCheckbox } from "../../components/Checkbox";
import { DenseCheckbox } from "../../components/Checkbox";
import { Form } from "../../components/Form";
import { MutatorModal, useMutator } from "../../components/MutatorModal";
import { TextField, useTextField } from "../../components/TextField";
import { Timestamp } from "@/components/Timestamp";

import { Validation } from "@/types/FormValidation";
import { RelayModalProps } from "@/types/MutatorModal";
import { Action } from "@/types/actions";

import { hasError } from "@/utils/formValidation";

import { UnblockFromUsingMultipleDevicesMutationResponse } from "./__generated__/UnblockFromUsingMultipleDevicesMutation.graphql";
import { UnblockFromUsingMultipleDevices_user } from "./__generated__/UnblockFromUsingMultipleDevices_user.graphql";

import { DirectDepositBlockHistory } from "../mobile.[mobile]/DirectDepositBlockHistory";

const mutation = graphql`
    mutation UnblockFromUsingMultipleDevicesMutation(
        $userId: ID!
        $reason: String!
        $unlockOnce: Boolean!
        $forAgent: Boolean!
    ) {
        unblockFromUsingMultipleDevices(
            userId: $userId
            reason: $reason
            unlockOnce: $unlockOnce
            forAgent: $forAgent
        ) {
            user {
                ...UnblockFromUsingMultipleDevices_user
            }
        }
    }
`;

type UnblockFromUsingMultipleDevicesInputParams = {
    user: UnblockFromUsingMultipleDevices_user;
    forAgent: boolean;
};

const _UnblockFromUsingMultipleDevicesMutator = (
    props: UnblockFromUsingMultipleDevicesInputParams & RelayModalProps
) => {
    const { t } = useTranslation();

    const reasonField = useTextField();
    const { checked, setChecked } = useCheckbox(true);

    const onMutationSuccess = useCallback(
        (_response: UnblockFromUsingMultipleDevicesMutationResponse) => {
            return t("unblock-multiple-devices--success", {
                text: checked ? "until the next login" : "",
            });
        },
        [t, checked]
    );
    const mutator = useMutator({
        onMutationSuccess,
        trackActionInfo: {
            name: Action.UnblockFromUsingMultipleDevices,
            data: { userId: props.user.id },
        },
        ...props,
    });

    const reasonValidations: Validation[] = useMemo(() => {
        const error = {
            type: "error",
            message: t("unblock-multiple-devices--missing-reason"),
            display: "if-blurred",
        } as Validation;

        return reasonField.value.length === 0 ? [error] : [];
    }, [t, reasonField.value.length]);

    const handleSubmit = () => {
        mutator.submit(mutation, {
            userId: props.user?.id,
            reason: reasonField.value,
            unlockOnce: checked,
            forAgent: props.forAgent,
        });
    };

    const isValid = useMemo(
        () => !hasError([reasonValidations]),
        [reasonValidations]
    );

    const { blockedBy, whenBlocked, blockReason, lockedDevice } = props.forAgent
        ? props.user?.agentMultipleDevicesBlock || {}
        : props.user?.personalMultipleDevicesBlock || {};

    const title = t("unblock-multiple-devices--description-locked-device", {
        subject: t(
            `unblock-multiple-devices--description-subject--${
                props.forAgent ? "agent" : "user"
            }`
        ),
    });

    return (
        <MutatorModal
            {...mutator}
            size="lg"
            title={t("unblock-multiple-devices--title")}
        >
            <Form
                {...mutator}
                isValid={isValid}
                submitText={t("action-unlock")}
                onSubmit={handleSubmit}
                onDone={props.onHide}
            >
                <p>
                    {lockedDevice ? (
                        <span>
                            {title} {lockedDevice.deviceName} (
                            {lockedDevice.deviceModel}{" "}
                            <span className="font-mono">
                                {lockedDevice.deviceId}
                            </span>
                            ).
                        </span>
                    ) : (
                        <span>{title}</span>
                    )}
                </p>
                <p>
                    {t("unblock-multiple-devices--description-blocked-by", {
                        name: blockedBy,
                    })}
                    <i>
                        {" "}
                        <Timestamp value={new Date(whenBlocked || "")} />
                    </i>{" "}
                    {t("unblock-multiple-devices--description-reason")}
                    <b> {blockReason}</b>
                </p>
                <p>{t("unblock-multiple-devices--description-once")}</p>

                <DenseCheckbox>
                    <Checkbox
                        checked={checked}
                        setChecked={setChecked}
                        label={t("unblock-multiple-devices--label-once")}
                        name={"unblock_once"}
                        validations={[]}
                        disabled={props.forAgent}
                    />
                </DenseCheckbox>
                <TextField
                    {...reasonField}
                    as="textarea"
                    label={t("label-reason")}
                    name="reason"
                    validations={reasonValidations}
                />
            </Form>
            <DirectDepositBlockHistory user={props.user} />
        </MutatorModal>
    );
};

export const UnblockFromUsingMultipleDevicesMutator = createFragmentContainer(
    _UnblockFromUsingMultipleDevicesMutator,
    {
        user: graphql`
            fragment UnblockFromUsingMultipleDevices_user on User {
                id
                personalMultipleDevicesBlock {
                    blockReason
                    blockedBy
                    whenBlocked
                    whenExpires
                    lockedDevice {
                        deviceId
                        deviceName
                        deviceModel
                    }
                }
                agentMultipleDevicesBlock {
                    blockReason
                    blockedBy
                    whenBlocked
                    whenExpires
                    lockedDevice {
                        deviceId
                        deviceName
                        deviceModel
                    }
                }
                ...DirectDepositBlockHistory_user
            }
        `,
    }
);
