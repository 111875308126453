/** Generated by `money-srv/src/support/scripts/dump_typescript_countries.py`.
*/
import Decimal from "decimal.js";

export interface Currency {
    name: string;
    display: string;
    increment: Decimal;
    suffix?: string;
    prefix?: string;
}

export const CURRENCIES = {
    CAD: {
        name: "CAD",
        display: "CAD",
        increment: new Decimal(0.01),
        prefix: "$",
    },
    CFA: {
        name: "CFA",
        display: "CFA",
        increment: new Decimal(5),
        suffix: "F",
    },
    ETB: {
        name: "ETB",
        display: "birr",
        increment: new Decimal(1),
    },
    GBP: {
        name: "GBP",
        display: "GBP",
        increment: new Decimal(0.01),
        prefix: "£",
    },
    GHS: {
        name: "GHS",
        display: "GHS",
        increment: new Decimal(0.01),
    },
    GMD: {
        name: "GMD",
        display: "GMD",
        increment: new Decimal(5),
    },
    KES: {
        name: "KES",
        display: "Ksh",
        increment: new Decimal(1),
    },
    NGN: {
        name: "NGN",
        display: "NGN",
        increment: new Decimal(1),
    },
    TZS: {
        name: "TZS",
        display: "Tsh",
        increment: new Decimal(1),
    },
    UGX: {
        name: "UGX",
        display: "UGX",
        increment: new Decimal(50),
    },
    USD: {
        name: "USD",
        display: "USD",
        increment: new Decimal(0.01),
        prefix: "$",
    },
    XAF: {
        name: "XAF",
        display: "XAF",
        increment: new Decimal(5),
        suffix: "F",
    },
    QQD: {
        name: "QQD",
        display: "QQD",
        increment: new Decimal(5),
    },
    UNIT: {
        name: "UNIT",
        display: "UNIT",
        increment: new Decimal(1),
    },
} as const;

export const CURRENCY_NAMES = ["CAD","CFA","ETB","GBP","GHS","GMD","KES","NGN","TZS","UGX","USD","XAF","QQD","UNIT",] as const;

export interface Country {
    iso2: string;
    name: string;
    flag: string;
    localPrefix: string;
    currency: Currency;
    currencyCode: keyof typeof CURRENCIES;
    isActive: boolean;
    sampleLocalTelnum: string;
    countrySpecificHostname: string | null;
    mobileRegex: string;
    mobileNumberMaxLength: number;
}

export const COUNTRIES: { [iso2: string]: Country } = {
    ET: {
        name: "Ethiopia",
        iso2: "et",
        flag: "🇪🇹",
        localPrefix: "+251",
        currency: CURRENCIES["ETB"],
        currencyCode: "ETB",
        isActive: false,
        sampleLocalTelnum: "94 444 4444",
        countrySpecificHostname: "et.mmapp.wave.com",
        mobileRegex: "\\+251(9[0-9]{8}|555[0-9]{6})",
        mobileNumberMaxLength: 9
    },
    GH: {
        name: "Ghana",
        iso2: "gh",
        flag: "🇬🇭",
        localPrefix: "+233",
        currency: CURRENCIES["GHS"],
        currencyCode: "GHS",
        isActive: false,
        sampleLocalTelnum: "24 222 3344",
        countrySpecificHostname: "gh.mmapp.wave.com",
        mobileRegex: "\\+233([25][0-9]{8}|611[0-9]{6})",
        mobileNumberMaxLength: 9
    },
    NG: {
        name: "Nigeria",
        iso2: "ng",
        flag: "🇳🇬",
        localPrefix: "+234",
        currency: CURRENCIES["NGN"],
        currencyCode: "NGN",
        isActive: false,
        sampleLocalTelnum: "800 111 2222",
        countrySpecificHostname: "ng.mmapp.wave.com",
        mobileRegex: "\\+234((70|80|81|90)[0-9]{8}|555[0-9]{7})",
        mobileNumberMaxLength: 10
    },
    SN: {
        name: "Sénégal",
        iso2: "sn",
        flag: "🇸🇳",
        localPrefix: "+221",
        currency: CURRENCIES["CFA"],
        currencyCode: "CFA",
        isActive: true,
        sampleLocalTelnum: "77 888 9999",
        countrySpecificHostname: "sn.mmapp.wave.com",
        mobileRegex: "\\+221(7[0,5-8]{1}[0-9]{7}|555[0-9]{6})",
        mobileNumberMaxLength: 9
    },
    CI: {
        name: "Côte d'Ivoire",
        iso2: "ci",
        flag: "🇨🇮",
        localPrefix: "+225",
        currency: CURRENCIES["CFA"],
        currencyCode: "CFA",
        isActive: true,
        sampleLocalTelnum: "01 01 99 99 99",
        countrySpecificHostname: "ci.mmapp.wave.com",
        mobileRegex: "\\+225(0[1,5,7]{1}[0-9]{8}|555[0-9]{7})",
        mobileNumberMaxLength: 10
    },
    UG: {
        name: "Uganda",
        iso2: "ug",
        flag: "🇺🇬",
        localPrefix: "+256",
        currency: CURRENCIES["UGX"],
        currencyCode: "UGX",
        isActive: true,
        sampleLocalTelnum: "752 000 000",
        countrySpecificHostname: "ug.mmapp.wave.com",
        mobileRegex: "\\+256((7[0-9]{8}|3[0-9]{8}|4[0-9]{8}|20[0-9]{7})|555[0-9]{6})",
        mobileNumberMaxLength: 9
    },
    QQ: {
        name: "Quicquidlibya",
        iso2: "qq",
        flag: "🇶🇶",
        localPrefix: "+999",
        currency: CURRENCIES["QQD"],
        currencyCode: "QQD",
        isActive: false,
        sampleLocalTelnum: "77 888 9999",
        countrySpecificHostname: "qq.mmapp.wave.com",
        mobileRegex: "\\+999(7[0,5-8]{1}[0-9]{7}|555[0-9]{6})",
        mobileNumberMaxLength: 9
    },
    ML: {
        name: "Mali",
        iso2: "ml",
        flag: "🇲🇱",
        localPrefix: "+223",
        currency: CURRENCIES["CFA"],
        currencyCode: "CFA",
        isActive: true,
        sampleLocalTelnum: "70 00 00 00",
        countrySpecificHostname: "ml.mmapp.wave.com",
        mobileRegex: "\\+223((([5679][0-9])|(8[2349]))[0-9]{6}|333[0-9]{5})",
        mobileNumberMaxLength: 8
    },
    BF: {
        name: "Burkina Faso",
        iso2: "bf",
        flag: "🇧🇫",
        localPrefix: "+226",
        currency: CURRENCIES["CFA"],
        currencyCode: "CFA",
        isActive: true,
        sampleLocalTelnum: "64 81 88 36",
        countrySpecificHostname: "bf.mmapp.wave.com",
        mobileRegex: "\\+226((0[123567]|5[1-8]|6[0-9]|7[0-9])[0-9]{6}|333[0-9]{5})",
        mobileNumberMaxLength: 8
    },
    BJ: {
        name: "Benin",
        iso2: "bj",
        flag: "🇧🇯",
        localPrefix: "+229",
        currency: CURRENCIES["CFA"],
        currencyCode: "CFA",
        isActive: false,
        sampleLocalTelnum: "64 81 88 36",
        countrySpecificHostname: "bj.mmapp.wave.com",
        mobileRegex: "\\+229((0[1267]|5[12345678]|6[0-9]|7[0-9]|9[0-9])[0-9]{6}|333[0-9]{5})",
        mobileNumberMaxLength: 8
    },
    TG: {
        name: "Togo",
        iso2: "tg",
        flag: "🇹🇬",
        localPrefix: "+228",
        currency: CURRENCIES["CFA"],
        currencyCode: "CFA",
        isActive: false,
        sampleLocalTelnum: "64 81 88 36",
        countrySpecificHostname: "tg.mmapp.wave.com",
        mobileRegex: "\\+228((0[1267]|5[12345678]|6[0-9]|7[0-9])[0-9]{6}|333[0-9]{5})",
        mobileNumberMaxLength: 8
    },
    GM: {
        name: "Gambia",
        iso2: "gm",
        flag: "🇬🇲",
        localPrefix: "+220",
        currency: CURRENCIES["GMD"],
        currencyCode: "GMD",
        isActive: true,
        sampleLocalTelnum: "111 1111",
        countrySpecificHostname: "gm.mmapp.wave.com",
        mobileRegex: "\\+220([123456789][0-9]{6})",
        mobileNumberMaxLength: 7
    },
    NE: {
        name: "Niger",
        iso2: "ne",
        flag: "🇳🇪",
        localPrefix: "+227",
        currency: CURRENCIES["CFA"],
        currencyCode: "CFA",
        isActive: false,
        sampleLocalTelnum: "64 81 88 36",
        countrySpecificHostname: "ne.mmapp.wave.com",
        mobileRegex: "\\+227((0[1267]|5[12345678]|6[0-9]|7[0-9])[0-9]{6}|333[0-9]{5})",
        mobileNumberMaxLength: 8
    },
    CM: {
        name: "Cameroon",
        iso2: "cm",
        flag: "🇨🇲",
        localPrefix: "+237",
        currency: CURRENCIES["XAF"],
        currencyCode: "XAF",
        isActive: false,
        sampleLocalTelnum: "672 26 35 69",
        countrySpecificHostname: "cm.mmapp.wave.com",
        mobileRegex: "\\+2376[0-9]{8}",
        mobileNumberMaxLength: 9
    },
};
