import React from "react";
import { useTranslation } from "react-i18next";

import { formatTimestamp } from "../../utils/time";

export const WhitelistedBadge = ({ date }: { date: string | null }) => {
    const { t } = useTranslation();

    const displayDate = date && formatTimestamp(new Date(date));
    return (
        <span
            className="text-uppercase badge badge-success"
            title={t("whitelisted-badge--title")}
        >
            {t("whitelisted-badge--description")}
            {date && ` (${displayDate})`}
        </span>
    );
};
