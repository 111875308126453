import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

import i18n from "../locales/i18n";

import { COUNTRIES } from "../types/countries";

// use make s.countries to update the countries list (../types/countries.ts)

export const isDigit = (x: string) => "0123456789".includes(x);

// UK phone numbers used for Twilio and Zoom Phone
const ukMobileRegex = /^\+44[0-9]{10}$/;
const zoomPhoneRegex = /^000[0-9]+$/;

const countries = Object.values(COUNTRIES);

export function isValidMobile(mobile: string | null | undefined): boolean {
    if (!mobile) {
        return false;
    }

    const country = countries.find((c) => mobile.startsWith(c.localPrefix));

    if (!country) {
        return false;
    }

    return Boolean(mobile.match(`^${country.mobileRegex}$`));
}

export const isValidSupportRepMobile = (number: string | null | undefined) =>
    isValidMobile(number) ||
    (!!number && (number.match(ukMobileRegex) || number.match(zoomPhoneRegex)));

const activeCountries = countries
    .filter((c) => c.isActive)
    .map((c) => `${c.name} (${c.localPrefix})`)
    .reduce((acc, c) => `${acc}, ${c}`);
export const mobileValidationErrorMessage = {
    type: "error",
    display: "always",
    message: `${i18n.t("form-validation--invalid-mobile")} ${activeCountries}.`,
};

export const supportRepMobileValidationErrorMessage = {
    type: "error",
    display: "always",
    message: `${mobileValidationErrorMessage.message}${i18n.t(
        "form-validation--invalid-mobile--alt"
    )}`,
};

export function parseMobile(mobile: string) {
    return parsePhoneNumber(getMobileIfTerminated(mobile));
}
export function getMobileIfTerminated(mobile: string) {
    // when a mobile is terminated, we append some characters to the end. This function
    // checks for the pattern of those characters, and removes them to get the mobile
    // if necessary. Otherwise just returns what it was passed
    const matches = mobile.match("^(.*?)_terminated_[0-9]{6}");
    if (matches) {
        return matches[1];
    } else {
        return mobile;
    }
}
export function isValidMobileProfile(mobile: string) {
    const sanitizedMobile = getMobileIfTerminated(mobile);
    // NOTE(joseph) changing this to be a little lenient
    // because we use test numbers a lot in prod. So if
    // we can at least parse the mobile string, return true
    return parseMobile(sanitizedMobile) || isValidPhoneNumber(sanitizedMobile);
}

/** Get the `Country` corresponding to `mobile`.  */
export function countryForMobile(mobile: string | undefined) {
    if (mobile) {
        const phoneNumber = parseMobile(mobile);
        return countries.find(
            (c) => c.iso2 === phoneNumber.country?.toLowerCase()
        );
    }
    return undefined;
}
